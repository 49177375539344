import FleetLogoFullWhite from 'core-system/FleetLogos/FleetLogoFullWhite'
import FleetLogoShortWhite from 'core-system/FleetLogos/FleetLogoShortWhite'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import styled from 'styled-components'
import SidebarItem from './SidebarItem'
import {
  employeePageLinks,
  getSettingsSidebarLink,
  pageLinks,
  SidebarItem as SidebarItemType,
} from './SidebarShared'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { Locations } from 'shared/Router/Locations'

const MenuContainer = styled.div`
  background: ${(props) => props.theme.palette.primary.pink800};
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  z-index: ${(props) => props.theme.zIndex.navigation};
  position: absolute;
  justify-content: space-between;
`

const LogoContainer = styled.div<{ $isCollapsed: boolean }>`
  margin: 2rem 1.5rem ${(props) => props.theme.pxToRem(80)} 1.5rem;
  display: flex;
  justify-content: ${(props) => (props.$isCollapsed ? 'center' : 'flex-start')};
`

const LogoAndLinks = styled.div`
  display: flex;
  flex-direction: column;
`

const Body = styled.div`
  margin-bottom: 1.5rem;
  height: 70%;
  list-style: none;
  padding: 0;
`

interface SidebarMenuProps {
  platformType: 'employee' | 'employer'
  isCollapsed: boolean
}

const SidebarMenu = React.memo((props: SidebarMenuProps) => {
  const { platformType, isCollapsed } = props
  const location = useLocation()
  const products = new Set(
    useSelector((state: AppState) => state.employer?.profile?.products)
  )

  const { profileData, preTaxPrograms, programs, myLeaderboards, optIn } =
    useSelector((state: AppState) => state.employeePlatform)

  const links = platformType === 'employer' ? pageLinks : employeePageLinks

  const settingsLink = getSettingsSidebarLink(platformType)

  const visibleToEmployee = (link: SidebarItemType) => {
    if (link.metric === 'employee-qtf') {
      return (
        preTaxPrograms &&
        profileData?.finchEmploymentType !== 'contractor' &&
        Object.values(preTaxPrograms).some((program) => program !== null)
      )
    } else if (link.metric === 'employee-grh') {
      return programs?.allPrograms && programs.allPrograms['GRH']
    } else if (link.metric === 'employee-leaderboards') {
      return myLeaderboards && Object.keys(myLeaderboards).length > 0
    } else if (link.metric === 'employee-optin') {
      return optIn?.allOptIns && optIn.allOptIns.length > 0
    }

    return !link.productLabel ||
      profileData?.employerProducts?.includes(link.productLabel)
      ? true
      : false
  }

  const isOnboarding =
    location.pathname === Locations.EmployeeOnboarding ||
    location.pathname === Locations.GettingStarted ||
    location.pathname === Locations.QTF.ActivationOnboarding

  const getLogoClickLocation = () => {
    if (isOnboarding) {
      return '#'
    }

    return platformType === 'employer'
      ? Locations.Dashboard
      : Locations.EmployeeDashboard
  }

  return (
    <MenuContainer>
      <LogoAndLinks>
        <LogoContainer $isCollapsed={isCollapsed}>
          <Link
            to={getLogoClickLocation()}
            onClick={() =>
              SegmentService.track('sidebar-item-click', { item: 'logo' })
            }
          >
            {isCollapsed ? (
              <FleetLogoShortWhite height={32} width={136} />
            ) : (
              <FleetLogoFullWhite height={32} width={136} />
            )}
          </Link>
        </LogoContainer>
        <Body>
          {links
            ? links.map((link: SidebarItemType, idx: number) =>
                (platformType === 'employer' && !link.productLabel) ||
                products.has(link.productLabel) ||
                (platformType === 'employee' && visibleToEmployee(link)) ? (
                  <SidebarItem
                    key={idx}
                    label={link.label}
                    icon={link.icon}
                    path={location.pathname}
                    disabled={isOnboarding || link.disabled}
                    to={link.link}
                    metric={link.metric}
                    menu={link.menu && link.menu}
                    data-cy={`sidebar-${link.metric}`}
                    isCollapsed={isCollapsed}
                  />
                ) : null
              )
            : null}
        </Body>
      </LogoAndLinks>
      <SidebarItem
        label={settingsLink.label}
        icon={settingsLink.icon}
        path={location.pathname}
        disabled={isOnboarding}
        to={settingsLink.link}
        metric={settingsLink.metric}
        data-cy={`sidebar-${settingsLink.metric}`}
        isCollapsed={isCollapsed}
      />
    </MenuContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SidebarMenu.displayName = 'SidebarMenu'
}

export default SidebarMenu
