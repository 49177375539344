import { mapIntelligenceActions } from 'redux/mapIntelligence/mapIntelligenceSlice'
import RadialChart from 'core-system/charts/Radial'
import RadialChartUtils from 'core-system/charts/Radial/RadialChartUtils'
import FlexContainer from 'core-system/FlexContainer'
import Loading from 'core-system/Loading'
import MapStyles from 'core-system/Map/MapStyles'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { dashboardActions } from 'redux/dashboard/dashboardSlice'
import { DashboardRecentTripsServiceBreakdown } from 'redux/mapIntelligence/mapIntelligenceTypes'
import NumberUtils from 'shared/NumberUtils'
import RandomUtils from 'shared/RandomUtils'
import styled, { css } from 'styled-components'
import DashboardMap from './DashboardMap'

const chartOptions = {
  ...RadialChartUtils.defaultChartOptions,
  plugins: {
    ...RadialChartUtils.defaultChartOptions.plugins,
    legend: {
      display: false,
    },
  },
  cutout: '69%',
}

const DashboardCard = styled.div`
  ${(props) => props.theme.baseCard}
  padding: 0;
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => props.theme.pxToRem(455)};
`

const PanelContainer = styled.div`
  flex: 2;
  padding: 1.5rem;
  border-right: 1px solid ${(props) => props.theme.palette.grey.grey300};
`

const MapContainer = styled.div`
  flex: 3.5;
`

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const ChartContainer = styled.div`
  position: relative;
  width: ${(props) => props.theme.pxToRem(175)};
  height: ${(props) => props.theme.pxToRem(175)};
`

const TotalBudget = styled.div`
  ${(props) => props.theme.typography.h2}
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
`

const ChartDescription = styled(Text)`
  color: ${(props) => props.theme.palette.text.secondary};
  text-transform: uppercase;
  letter-spacing: 0.7px;
`

const ChartLegend = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1.5rem 0;
`

const Mode = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
`

const CustomLegendItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  margin: 0.125rem 0.375rem 0;
  border-radius: 0.25rem;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${(props) => props.theme.palette.primary.pink500};
    `}

  &:hover {
    background-color: ${(props) =>
      props.isActive
        ? props.theme.palette.primary.pink500
        : props.theme.palette.primary.pink600};
  }
`

const CustomLegendColor = styled.div<{ background: string }>`
  background-color: ${(props) => props.background};
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
`

const getChartData = (modeCount: DashboardRecentTripsServiceBreakdown[]) => {
  const extraData = modeCount.reduce(
    (agg, modeType) => {
      agg.totalLegs = agg.totalLegs + modeType.legs
      agg.modeCount = {
        ...agg.modeCount,
        [modeType.modetype]: modeType.legs,
      }
      return agg
    },
    {
      totalLegs: 0,
      modeCount: {},
    }
  )

  const labels = []
  const data = [...modeCount]
    .sort((a, b) => b.legs - a.legs)
    .map((modeType) => {
      labels.push(
        `${RandomUtils.capitalize(modeType.modetype)}, ${
          modeType.legs
        } Trip Legs`
      )
      return modeType.legs
    })

  return {
    datasets: [
      {
        data: data,
        backgroundColor: MapStyles.mapColors,
        hoverBackgroundColor: MapStyles.mapColors,
        borderWidth: 0,
      },
    ],
    labels: labels,
    ...extraData,
  }
}

const formatChartLabels = (labels: string[]) => {
  return labels.map((label) => ProgramUtils.formatElectricModeTypes(label))
}

const displayBarChartLegend = (
  colors: string[],
  labels: string[],
  data: number[],
  activeLayer: string,
  handleLayerClick: (layer: string) => void,
  totalLegs: number
) => {
  return labels.map((label: string, idx: number) => (
    <CustomLegendItem
      key={idx}
      isActive={label.toLowerCase() === activeLayer}
      onClick={() => handleLayerClick(label)}
    >
      <Mode>
        <CustomLegendColor background={colors[idx]} />
        <Text variant='body2'>{label.split(',')[0]}</Text>
      </Mode>
      <Text variant='body2' textColor={palette.text.secondary}>
        {Math.round((data[idx] / totalLegs) * 100)}% Trip legs
      </Text>
    </CustomLegendItem>
  ))
}

const DashboardTrips = () => {
  const dispatch = useDispatch()

  const { dashRecentTrips, worksiteGeojson } = useSelector(
    (state: AppState) => state.mapIntelligence
  )
  const { allEmployeeSegmentId } = useSelector(
    (state: AppState) => state.employer
  )

  const [activeLayer, setActiveLayer] = useState(null)

  useEffect(() => {
    dispatch(
      mapIntelligenceActions.getBaseCommuters({
        segmentId: allEmployeeSegmentId,
      })
    )
    dispatch(dashboardActions.getDashboardMapData())
  }, [dispatch, allEmployeeSegmentId])

  const handleLayerClick = useCallback(
    (layer: string) => {
      if (activeLayer === layer.toLowerCase()) {
        setActiveLayer(null)
      } else {
        setActiveLayer(layer.toLowerCase())
      }
    },
    [activeLayer]
  )

  if (!worksiteGeojson || !dashRecentTrips) {
    return <Loading height={pxToRem(421)} />
  }

  const { totalLegs, modeCount, ...chartData } = getChartData(
    dashRecentTrips.servicesBreakdown
  )

  return (
    <DashboardCard>
      <Content>
        <PanelContainer>
          <Text variant='h5' marginBottom='1.5rem'>
            Trips Summary
          </Text>
          {dashRecentTrips.geojson.features.length === 0 ? (
            <ErrorContainer>
              <Text variant='action4'>No Trips Recorded</Text>
            </ErrorContainer>
          ) : (
            <>
              <CenterContainer>
                <ChartContainer>
                  <TotalBudget>
                    {NumberUtils.formatNumber(dashRecentTrips.numTrips, 0)}
                    <ChartDescription variant='caption'>
                      trips logged
                    </ChartDescription>
                  </TotalBudget>
                  <RadialChart
                    data={{
                      ...chartData,
                      labels: formatChartLabels(chartData.labels),
                    }}
                    options={chartOptions}
                    width={pxToRem(175)}
                    height={pxToRem(175)}
                  />
                </ChartContainer>
              </CenterContainer>
              <ChartLegend>
                {displayBarChartLegend(
                  chartData.datasets[0].backgroundColor,
                  chartData.labels,
                  chartData.datasets[0].data,
                  activeLayer,
                  handleLayerClick,
                  totalLegs
                )}
              </ChartLegend>
            </>
          )}
        </PanelContainer>
        <MapContainer>
          {dashRecentTrips.geojson.features.length === 0 &&
          worksiteGeojson.features.length === 0 ? (
            <FlexContainer center height='100%'>
              <Text variant='action4'>No Data</Text>
            </FlexContainer>
          ) : (
            <DashboardMap
              recentTripGeojson={dashRecentTrips.geojson}
              worksiteGeojson={worksiteGeojson}
              activeLayer={activeLayer}
              modeCount={modeCount}
            />
          )}
        </MapContainer>
      </Content>
    </DashboardCard>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardTrips.displayName = 'DashboardTrips'
}

export default DashboardTrips
