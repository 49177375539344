import React from 'react'
import styled from 'styled-components'
import InfoIcon from 'core-system/Icons/Misc/Info'
import SvgTooltip from 'core-system/Tooltip/SvgTooltip'
import FormattingUtils from 'shared/FormattingUtils'
import { NumberFormatter, NumberInput } from 'core-system/Input/NumberInput'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import { space, SpaceProps } from 'styled-system'
import Divider from 'core-system/Divider'

const Container = styled.div<SpaceProps>`
  ${(props) => props.theme.baseCard}
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;

  ${space}
`

const BudgetContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
`

const RecBudgetContainer = styled.div<{ test: boolean }>`
  background: ${(props) => props.theme.palette.primary.pink300};
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  margin-left: 0.5rem;
  flex: 1;
`

const CaptionText = styled.div`
  ${(props) => props.theme.typography.caption}
  text-transform: uppercase;
  letter-spacing: 0.7px;
  line-height: 0.875rem;
`

const RecBudget = styled.div`
  ${(props) => props.theme.typography.h5};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  letter-spacing: 0;

  svg {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`

const StaticContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.palette.primary.pink300};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  border-radius: 0.5rem;
`

interface BudgetUsesSelectorProps extends SpaceProps {
  budget: number
  recBudget: number
  recUses: number
  uses: number
  onBudgetChange: (budget: number) => void
  onUsesChange: (uses: number) => void
  budgetIncrementVal?: number
  budgetError?: boolean
  usesError?: boolean
  canEdit?: boolean
}

const BudgetUsesSelector = (props: BudgetUsesSelectorProps) => {
  const {
    budget,
    onBudgetChange,
    recBudget,
    budgetIncrementVal = 5,
    budgetError,
    recUses,
    uses,
    onUsesChange,
    canEdit = true,
    usesError,
    ...rest
  } = props

  return (
    <Container {...rest}>
      <Text variant='h4' marginBottom='1rem'>
        How Much?
      </Text>
      <>
        <Text
          variant='body1'
          textColor={palette.text.secondary}
          marginBottom='0.25rem'
        >
          Max Transaction Limit
        </Text>
        <BudgetContainer>
          {canEdit ? (
            <NumberInput
              padding='1rem'
              onValueChange={onBudgetChange}
              value={budget / 100}
              iconSize={32}
              incrementVal={budgetIncrementVal}
            >
              <NumberFormatter decimalScale={0} fontVariant='h1' />
            </NumberInput>
          ) : (
            <StaticContainer>
              <Text variant='h1'>
                {FormattingUtils.formatDollar(budget, 0)}
              </Text>
            </StaticContainer>
          )}
          <RecBudgetContainer test={false}>
            <CaptionText>Recommended Minimum Amount:</CaptionText>
            <RecBudget>
              {FormattingUtils.formatDollar(recBudget, 0)}
              <SvgTooltip
                svg={<InfoIcon width={20} height={20} />}
                height={'1.5rem'}
                isMultiLine={true}
                description={
                  'Recommended amount is based on average cost of a one way ride'
                }
              />
            </RecBudget>
          </RecBudgetContainer>
        </BudgetContainer>
        {budgetError && (
          <Text
            variant='body1'
            textColor={palette.error.red500}
            marginTop='0.25rem'
          >
            Please enter a valid amount
          </Text>
        )}
      </>
      <Divider marginY={'1.5rem'} />
      <>
        <Text variant='h4' marginBottom='1rem'>
          How Often?
        </Text>
        <Text
          variant='body1'
          textColor={palette.text.secondary}
          marginBottom='0.25rem'
        >
          Annual Number of Transactions
        </Text>
        <BudgetContainer>
          {canEdit ? (
            <NumberInput
              padding='1rem'
              onValueChange={onUsesChange}
              value={uses}
              iconSize={32}
              incrementVal={1}
              placeholder={'0'}
            >
              <NumberFormatter
                decimalScale={0}
                fontVariant='h1'
                currencyFormat='count'
                placeholder={'0'}
              />
            </NumberInput>
          ) : (
            <StaticContainer>
              <Text variant='h1'>{uses}</Text>
            </StaticContainer>
          )}
          <RecBudgetContainer test={false}>
            <CaptionText>Recommended Minimum Transactions:</CaptionText>
            <RecBudget>{recUses}</RecBudget>
          </RecBudgetContainer>
        </BudgetContainer>
        {usesError && (
          <Text
            variant='body1'
            textColor={palette.error.red500}
            marginTop='0.25rem'
          >
            Please enter a valid number
          </Text>
        )}
      </>
    </Container>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  BudgetUsesSelector.displayName = 'BudgetUsesSelector'
}

export default BudgetUsesSelector
