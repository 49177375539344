import palette from 'core-system/Themes/palette'
import { Expression } from 'mapbox-gl'
import { LayerProps } from 'react-map-gl/mapbox'

const pointStyle = {
  pointOpacity: 0.8,
  pointStrokeWidth: 1,
  pointStrokeOpacity: 0.8,
  pointStrokeColor: palette.white,
}

const pointSize = {
  largeNormal: 7,
  largeHidden: 6,
  smallNormal: 4.5,
  smallHidden: 3.5,
}

const mapColors = [
  '#C30078',
  '#4EC7C4',
  palette.primary.pink800,
  palette.primary.pink500,
  '#5FCD6A',
  '#3A78FF',
  '#C19CFF',
  '#FF7076',
  '#7ADBF8',
]

const worksiteGeojsonStyle: LayerProps = {
  id: 'worksiteGeojsonStyle',
  type: 'fill',
  beforeId: 'settlement-major-label',
  paint: {
    'fill-color': palette.primary.pink600,
    'fill-opacity': 0.25,
  },
}

const employeeStyle: LayerProps = {
  id: 'employees',
  type: 'circle',
  beforeId: 'settlement-major-label',
  paint: {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10,
      pointSize.smallNormal,
      15,
      pointSize.largeNormal,
    ] as Expression,
    'circle-color': palette.primary.pink500,
    'circle-stroke-width': pointStyle.pointStrokeWidth,
    'circle-stroke-color': pointStyle.pointStrokeColor,
    'circle-opacity': pointStyle.pointOpacity,
    'circle-stroke-opacity': pointStyle.pointStrokeOpacity,
  },
}

export default {
  pointStyle,
  pointSize,
  mapColors,
  worksiteGeojsonStyle,
  employeeStyle,
}
