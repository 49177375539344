import React from 'react'
import styled from 'styled-components'
import { StaticAssetsUrl } from 'redux/config/services/Constants'

const Container = styled.div<{ platformType?: any }>`
  width: 52%;
  background-color: ${(props) => props.theme.palette.primary.pink800};
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    display: none;
  }
`

const Img = styled.img`
  align-self: center;
  display: contain;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    right: 0;
  }
`

export enum ImageLabel {
  SIGNUP = 'signUp',
  LOGIN = 'login',
  VERIFY_EMAIL = 'verify_email',
  EMPLOYEE_SIGNUP = 'employee_signup',
}
type ContainerImages = {
  [key in ImageLabel]: string
}

const containerImages: ContainerImages = {
  signUp: `${StaticAssetsUrl}/login/signup.png`,
  login: `${StaticAssetsUrl}/login/login_v2.png`,
  verify_email: `${StaticAssetsUrl}/login/verify_email.png`,
  employee_signup: `${StaticAssetsUrl}/login/signup.png`,
}

interface LoginSignUpGraphicProps {
  platformType?: any
  imageLabel?: ImageLabel
}

const LoginSignUpGraphicV2 = React.memo((props: LoginSignUpGraphicProps) => {
  const { platformType, imageLabel } = props

  return (
    <Container platformType={platformType}>
      {/* <PictureHeader> */}
      <Img alt={imageLabel} src={containerImages[imageLabel]} />
      {/* </PictureHeader> */}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LoginSignUpGraphicV2.displayName = 'LoginSignUpGraphicV2'
}

export default LoginSignUpGraphicV2
