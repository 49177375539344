import Button from 'core-system/Button'
import Chip from 'core-system/Chip/Chip'
import FlexContainer from 'core-system/FlexContainer'
import CloseIcon from 'core-system/Icons/Actions/Close'
import { Calendar } from 'core-system/Icons/Misc'
import { Incentives } from 'core-system/Icons/Sidebar'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import { IncentiveGroup } from 'redux/incentives/incentivesTypes'
import FormattingUtils from 'shared/FormattingUtils'
import NumberUtils from 'shared/NumberUtils'
import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
`

const IconContainer = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink500};
  }
`

const ProgramRow = styled.div`
  ${(props) => props.theme.baseCard}
  cursor: pointer;
  margin-bottom: 0.5rem;
  width: ${(props) => props.theme.pxToRem(368)};
  margin-right: 0.375rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const PurpleContainer = styled(FlexContainer)`
  padding: 0.5rem;
  border-radius: 0.3125rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1rem;
`

const ProgramContainer = styled.div`
  margin-bottom: 1rem;
`

const Footer = styled(FlexContainer)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: ${(props) => props.theme.dropShadows.top};
  min-width: ${(props) => props.theme.pxToRem(442)};
`

const Programs = styled.div`
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 100%;
  padding-bottom: 6.25rem;

  /* width */
  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 1rem;
    opacity: 0.8;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    opacity: 1;
  }
`

const getActiveRow = (programGroup: IncentiveGroup) => {
  if (programGroup.active) {
    return programGroup.active
  } else if (programGroup.nextMonth) {
    return programGroup.nextMonth
  } else {
    return null
  }
}

const renderProgramRows = (
  allSegments: Segment[],
  allIncentives: Dictionary<IncentiveGroup>,
  incentivesTrips: Dictionary<number>,
  handleOnClick: (segmentId: string) => void,
  handleOnClickStart?: (e: React.MouseEvent, segmentId: string) => void
) => {
  return allSegments.map((segment) => {
    const isActive = allIncentives[segment.id]
    const activeRow = isActive && getActiveRow(allIncentives[segment.id])
    return (
      <ProgramRow key={segment.id} onClick={() => handleOnClick(segment.id)}>
        <FlexContainer alignItems='center' marginBottom='1rem'>
          <FlexContainer
            width={pxToRem(300)}
            alignItems='center'
            justifyContent='space-between'
          >
            <FlexContainer alignItems='center'>
              <PurpleContainer center>
                {isActive ? (
                  <Incentives color={palette.text.secondary} />
                ) : (
                  <Calendar color={palette.text.secondary} />
                )}
              </PurpleContainer>
              <Text variant='action2'>{segment.name}</Text>
            </FlexContainer>
            <div>
              <Text variant='body2' textColor={palette.text.placeholder}>
                Commuters
              </Text>
              <Text variant='action2'>{segment.commuters}</Text>
            </div>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer width={pxToRem(309)} justifyContent='space-between'>
          {isActive ? (
            <>
              <div>
                <Text variant='body2' textColor={palette.text.placeholder}>
                  Incentives Rewarded
                </Text>
                <Text variant='action2' textColor={palette.text.primary}>
                  {FormattingUtils.formatDollar(activeRow.rewarded, 0)}
                </Text>
              </div>
              <div>
                <Text variant='body2' textColor={palette.text.placeholder}>
                  Trips Logged
                </Text>
                <Text variant='action2' textColor={palette.text.primary}>
                  {NumberUtils.formatNumber(incentivesTrips[segment.id])}
                </Text>
              </div>
            </>
          ) : (
            <Button
              size='small'
              onClick={(e) => handleOnClickStart(e, segment.id)}
            >
              Start Incentives
            </Button>
          )}
        </FlexContainer>
      </ProgramRow>
    )
  })
}

interface NavigationBarIncentivesProps {
  onClose: () => void
  toggleIncentivesModal: () => void
  toggleNoPaymentModal: () => void
  segmentId: string
}

const NavigationBarIncentives = React.memo(
  (props: NavigationBarIncentivesProps) => {
    const { onClose, toggleIncentivesModal, toggleNoPaymentModal, segmentId } =
      props

    const navigate = useNavigate()

    const { allIncentives, incentivesTrips } = useSelector(
      (state: AppState) => state.incentives
    )
    const { allSegments, segmentsMap, hasValidPaymentMethod, profile } =
      useSelector((state: AppState) => state.employer)

    const handleOnClick = useCallback(
      (nextSegmentId: string) => {
        navigate(`/trips/${nextSegmentId}`)
        onClose()
        SegmentService.track('segmentNav-action-click', {
          action: `segment-${segmentsMap[nextSegmentId].name}`,
          locationAt: `incentives-${segmentsMap[segmentId].name}`,
        })
      },
      [navigate, onClose, segmentsMap, segmentId]
    )

    const handleOnClickStart = useCallback(
      (e: React.MouseEvent, segmentId: string) => {
        e.stopPropagation()
        if (hasValidPaymentMethod || !profile?.openRegistration) {
          navigate(`/trips/${segmentId}/incentives/activation`, {
            state: { from: `/trips/${segmentId}` },
          })
          onClose()
        } else {
          toggleNoPaymentModal()
          onClose()
        }
      },
      [navigate, toggleNoPaymentModal, hasValidPaymentMethod, profile, onClose]
    )

    const handleStartNewProgram = useCallback(() => {
      if (hasValidPaymentMethod || !profile?.openRegistration) {
        toggleIncentivesModal()
        onClose()
        SegmentService.track('segmentNav-action-click', {
          action: 'add',
          locationAt: `incentives-${segmentsMap[segmentId].name}`,
        })
      } else {
        toggleNoPaymentModal()
        onClose()
      }
    }, [
      toggleIncentivesModal,
      toggleNoPaymentModal,
      hasValidPaymentMethod,
      profile,
      onClose,
      segmentsMap,
      segmentId,
    ])

    const sortedSegments = allSegments.reduce(
      (agg, segment) => {
        const isActive = allIncentives[segment.id]
        const activeRow = isActive && getActiveRow(allIncentives[segment.id])

        if (!isActive) {
          agg.off.push(segment)
        } else {
          agg[activeRow.status.toLowerCase()].push(segment)
        }

        return agg
      },
      { updating: [], active: [], pending: [], cancelling: [], off: [] }
    )

    if (!incentivesTrips) {
      return <Loading />
    }

    return (
      <Container>
        <FlexContainer
          alignItems='center'
          justifyContent='space-between'
          marginBottom='1.5rem'
        >
          <Text variant='h3'>All Incentives</Text>
          <IconContainer
            onClick={() => {
              onClose()
              SegmentService.track('segmentNav-action-click', {
                action: 'close',
                locationAt: `incentives-${segmentsMap[segmentId].name}`,
              })
            }}
          >
            <CloseIcon />
          </IconContainer>
        </FlexContainer>
        <Programs>
          {sortedSegments.updating.length > 0 && (
            <ProgramContainer>
              <Chip marginBottom='1rem' variant='blue'>
                Updating
              </Chip>
              {renderProgramRows(
                sortedSegments.updating,
                allIncentives,
                incentivesTrips,
                handleOnClick
              )}
            </ProgramContainer>
          )}
          {sortedSegments.active.length > 0 && (
            <ProgramContainer>
              <Chip marginBottom='1rem' variant='green'>
                Active
              </Chip>
              {renderProgramRows(
                sortedSegments.active,
                allIncentives,
                incentivesTrips,
                handleOnClick
              )}
            </ProgramContainer>
          )}
          {sortedSegments.pending.length > 0 && (
            <ProgramContainer>
              <Chip marginBottom='1rem' variant='purple'>
                Pending
              </Chip>
              {renderProgramRows(
                sortedSegments.pending,
                allIncentives,
                incentivesTrips,
                handleOnClick
              )}
            </ProgramContainer>
          )}
          {sortedSegments.cancelling.length > 0 && (
            <ProgramContainer>
              <Chip marginBottom='1rem' variant='red'>
                Cancelling
              </Chip>
              {renderProgramRows(
                sortedSegments.cancelling,
                allIncentives,
                incentivesTrips,
                handleOnClick
              )}
            </ProgramContainer>
          )}
          {sortedSegments.off.length > 0 && (
            <ProgramContainer>
              <Chip marginBottom='1rem' variant='grey'>
                off
              </Chip>
              {renderProgramRows(
                sortedSegments.off,
                allIncentives,
                incentivesTrips,
                handleOnClick,
                handleOnClickStart
              )}
            </ProgramContainer>
          )}
        </Programs>
        <Footer center>
          <Button width='83%' onClick={handleStartNewProgram}>
            Start New Incentives
          </Button>
        </Footer>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  NavigationBarIncentives.displayName = 'NavigationBarIncentives'
}

export default NavigationBarIncentives
