import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import FleetLogoFullColor from 'core-system/FleetLogos/FleetLogoFullColor'
import ButtonV2 from 'core-system/ButtonV2'
import LoginSignUpGraphicV2, {
  ImageLabel,
} from './components/LoginSignUpGraphicV2'

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
`
const Container = styled.div`
  display: flex;
  height: 100%;
`

const LeftContainer = styled.div`
  display: flex;
  width: 48%;
  background-color: ${palette.white};
  padding: 7rem 4rem 3rem;
  position: relative;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 100%;
  }
`

const LogoContainer = styled.div`
  position: absolute;
  top: 3rem;
  left: 3rem;
`

const HeaderContainer = styled.div`
  width: 100%;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
`

const EmployerEmailConfirmationView = React.memo(() => {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>Employer Sign Up | Fleet</title>
      </Helmet>
      <Container>
        <LeftContainer>
          <LogoContainer>
            <FleetLogoFullColor height='40' width='115' />
          </LogoContainer>
          <ContentContainer>
            <HeaderContainer>
              <Text
                variant='h1'
                marginBottom='1rem'
                textAlign='left'
                textColor={palette.black}
              >
                Check your inbox
              </Text>
            </HeaderContainer>
            <Text
              width={'100%'}
              variant='action2'
              textAlign='left'
              textColor={palette.black}
            >
              {
                "Your employees' world-class commuter benefits are just a click away. Verify your email now!"
              }
            </Text>
            <ButtonContainer>
              <ButtonV2
                size='large'
                variant='secondary'
                onClick={() => {
                  navigate('/login?from_signup=true')
                }}
              >
                Back to Login
              </ButtonV2>
            </ButtonContainer>
          </ContentContainer>
        </LeftContainer>
        <LoginSignUpGraphicV2 imageLabel={ImageLabel.VERIFY_EMAIL} />
      </Container>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployerEmailConfirmationView.displayName = 'EmployerEmailConfirmationView'
}

export default EmployerEmailConfirmationView
