import Banner from 'core-system/Banner'
import Chip from 'core-system/Chip'
import { statusMap } from 'core-system/Chip/Chip'
import FlexContainer from 'core-system/FlexContainer'
import { Calendar } from 'core-system/Icons/Misc'
import { Incentives } from 'core-system/Icons/Sidebar'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import IncentivesPreviewModal from 'features/Incentives/IncentivesPreviewModal'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import { IncentiveGroup } from 'redux/incentives/incentivesTypes'
import FormattingUtils from 'shared/FormattingUtils'
import NumberUtils from 'shared/NumberUtils'
import { Locations } from 'shared/Router/Locations'
import StatusUtils from 'shared/StatusUtils'
import NoPaymentModal from 'shared/components/NoPaymentModal'
import styled from 'styled-components'

const ProgramRow = styled.div`
  ${(props) => props.theme.baseCard}
  cursor: pointer;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const PurpleContainer = styled(FlexContainer)`
  padding: 0.5rem;
  border-radius: 0.3125rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  height: 2.5rem;
  width: 2.5rem;
`

const StatContainer = styled.div`
  margin-left: 2.4375rem;
`

const getActiveRow = (incentiveGroup: IncentiveGroup) => {
  //check if active incentive program is cancelling
  if (
    incentiveGroup.active &&
    incentiveGroup?.nextMonth?.status === 'CANCELLING'
  ) {
    return incentiveGroup.nextMonth
  } else if (incentiveGroup.active) {
    return incentiveGroup.active
  } else if (incentiveGroup.nextMonth) {
    return incentiveGroup.nextMonth
  } else {
    return null
  }
}

const renderIncentive = (
  segment: Segment,
  allIncentives: Dictionary<IncentiveGroup>,
  incentivesTrips: Dictionary<number>
) => {
  const isActive = allIncentives[segment.id]
  const activeRow = isActive && getActiveRow(allIncentives[segment.id])

  return (
    <FlexContainer alignItems='center'>
      <PurpleContainer center>
        {isActive ? (
          <Incentives color={palette.text.secondary} />
        ) : (
          <Calendar color={palette.text.secondary} />
        )}
      </PurpleContainer>
      <Text variant='action2' marginLeft='1rem'>
        {segment.name === 'All Employees' ? 'All Commuters' : segment.name}
      </Text>
      <FlexContainer alignItems='center' marginLeft='auto'>
        <Chip
          variant={isActive ? statusMap[activeRow.status].variant : 'disabled'}
        >
          {isActive ? statusMap[activeRow.status].text : 'off'}
        </Chip>
        <StatContainer>
          <Text variant='body2' textColor={palette.text.placeholder}>
            Rewarded This Month
          </Text>
          <Text
            variant='action2'
            textColor={isActive ? palette.text.primary : palette.text.disabled}
          >
            {isActive
              ? FormattingUtils.formatDollar(activeRow.rewarded, 0)
              : '-'}
          </Text>
        </StatContainer>
        <StatContainer>
          <Text variant='body2' textColor={palette.text.placeholder}>
            Trips Logged
          </Text>
          <Text
            variant='action2'
            textColor={isActive ? palette.text.primary : palette.text.disabled}
          >
            {isActive
              ? NumberUtils.formatNumber(incentivesTrips[segment.id])
              : '-'}
          </Text>
        </StatContainer>
        <StatContainer>
          <Text variant='body2' textColor={palette.text.placeholder}>
            Commuters
          </Text>
          <Text variant='action2'>{segment.employees.length}</Text>
        </StatContainer>
      </FlexContainer>
    </FlexContainer>
  )
}

const IncentivePrograms = React.memo(() => {
  const navigate = useNavigate()

  const { allIncentives, incentivesTrips } = useSelector(
    (state: AppState) => state.incentives
  )
  const { allSegments, segmentsMap, hasValidPaymentMethod, profile } =
    useSelector((state: AppState) => state.employer)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isNoPaymentModalOpen, setIsNoPaymentModalOpen] = useState(false)

  const handleOnClick = useCallback(
    (segmentId: string) => {
      navigate(`/trips/${segmentId}`, {
        state: { from: Locations.Micromobility.Programs },
      })
      SegmentService.track('segmentNav-action-click', {
        action: `segment-${segmentsMap[segmentId].name}`,
        locationAt: 'incentives',
      })
    },
    [navigate, segmentsMap]
  )

  const handleNewIncentivesClick = () => {
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      setIsModalOpen(true)
    } else {
      setIsNoPaymentModalOpen(true)
    }
  }

  if (!allIncentives || !allSegments || !incentivesTrips) {
    return <Loading fullPage />
  }

  const orderedSegments = [...allSegments].sort((a, b) => {
    const firstSegmentVal = allIncentives[a.id]
      ? StatusUtils.statusOrder[getActiveRow(allIncentives[a.id]).status]
      : 6
    const secondSegmentVal = allIncentives[b.id]
      ? StatusUtils.statusOrder[getActiveRow(allIncentives[b.id]).status]
      : 6
    return firstSegmentVal - secondSegmentVal
  })

  return (
    <>
      {Object.keys(allIncentives).length === 0 ? (
        <>
          <IncentivesPreviewModal
            open={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
          />
          <Banner
            variant='cta'
            title='Start Incentives today!'
            description='Reward smarter commute choices with flexible incentive programs.'
            cta='New Incentives'
            ctaCallback={() => handleNewIncentivesClick()}
            background='bannerGeneral2'
          />
        </>
      ) : (
        <Banner
          variant='info'
          title='Your Incentives'
          description='Reward smarter commute choices with flexible incentive programs.'
          background='bannerGeneral2'
        />
      )}
      <div>
        {orderedSegments.map((segment) => (
          <ProgramRow
            key={segment.id}
            onClick={() => handleOnClick(segment.id)}
          >
            {renderIncentive(segment, allIncentives, incentivesTrips)}
          </ProgramRow>
        ))}
      </div>
      <NoPaymentModal
        open={isNoPaymentModalOpen}
        closeModal={() => setIsNoPaymentModalOpen(false)}
        product='incentives'
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  IncentivePrograms.displayName = 'IncentivePrograms'
}

export default IncentivePrograms
