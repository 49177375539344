import Request from 'redux/config/services/Request'

export const openChatRoom = async (): Promise<object> => {
  const response = await Request.post('/ai_concierge/chatrooms/', {})
  return response
}

export const getChats = async (
  chatRoomId: string,
  threadId: string,
  noOfChats: number = 200
): Promise<object> => {
  const response = await Request.get(
    `/ai_concierge/chatrooms/${chatRoomId}/${threadId}/chats/?num_chats=${noOfChats}`
  )
  return response
}

export const postChatToDb = async (
  chatId: string,
  chatRoomId: string,
  message: string,
  threadId: string
): Promise<object> => {
  const response = await Request.post(
    `/ai_concierge/chatrooms/${chatRoomId}/${threadId}/chats/`,
    {
      message,
      id: chatId,
      threadId,
    }
  )
  return response
}

export const openRealtimeWebSocket = async (
  chatRoomId: string
): Promise<WebSocket> => {
  const websocket = await Request.openWebSocket(
    `ai_concierge/realtime/chatroom/${chatRoomId}/`
  )
  return websocket as WebSocket
}

export const closeWebSocket = async (websocket: WebSocket): Promise<void> => {
  await Request.closeWebSocket(websocket)
}

export const deleteChatRoom = async (): Promise<object> => {
  const response = await Request.post(`/ai_concierge/chatrooms/`, {
    delete: true,
  })
  return response
}
