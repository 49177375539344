import React, { useEffect, useState } from 'react'
import GridCard from 'employee-platform/shared/components/GridCard'
import styled from 'styled-components'
// import EditIcon from 'core-system/Icons/Actions/Edit'
import palette from 'core-system/Themes/palette'
import { CarpoolRelationType } from 'redux/employeePlatform/employeePlatformTypes'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import CarpoolMap from './CarpoolMap'
import Text from 'core-system/Text'
import MapIntelligenceService from 'redux/mapIntelligence/mapIntelligenceService'
import { convertTimeStringToAMPM } from 'employee-platform/shared/utils'
import { Employees as EmployeesIcon } from 'core-system/Icons/Misc'

// const ClickableIconContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 2rem;
//   height: 2rem;
//   border-radius: 0.5rem;
//   cursor: pointer;
//   user-select: none;

//   &:hover {
//     background-color: ${(props) => props.theme.palette.primary.pink600};
//   }
// `

const Container = styled.div<{ editing: boolean }>`
  display: grid;
  grid-template-columns: ${(props) =>
    !props.editing ? 'repeat(2, 1fr)' : '1fr'};
  grid-gap: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
  }
`

const DetailsContainer = styled.div<{ editing: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: ${(props) => props.theme.palette.white};
`

const Detail = styled.div`
  display: flex;
  flex-direction: column;
`

const SeatsRemainingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`

const renderDetail = (label: string, value: string) => {
  return (
    <Detail>
      <Text variant='action3' textColor={palette.text.secondary}>
        {label}
      </Text>
      <Text variant='action1' textColor={palette.primary.pink400}>
        {value}
      </Text>
    </Detail>
  )
}

// const getClickableIcon = (carpoolRelation: CarpoolRelationType) => {
//   if (carpoolRelation === 'OWNER') {
//     return (
//       <ClickableIconContainer>
//         <EditIcon color={palette.text.secondary} />
//       </ClickableIconContainer>
//     )
//   } else {
//     return null
//   }
// }

interface CarpoolDetailsCardProps {
  isMobile: boolean
  carpoolRelation: CarpoolRelationType
  carpoolId: string
  editing: boolean
  setEditing: (editing: boolean) => void
}

const CarpoolDetailsCard = React.memo((props: CarpoolDetailsCardProps) => {
  const { isMobile, carpoolRelation, carpoolId, editing } = props

  const { myCarpoolGroups, carpoolMemberships } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const [sourceAddr, setSourceAddr] = useState<string | null>(null)
  const [destAddr, setDestAddr] = useState<string | null>(null)

  const myDetails =
    carpoolRelation === 'OWNER'
      ? myCarpoolGroups[carpoolId]
      : carpoolMemberships[carpoolId]

  // Get the source and destination addresses
  useEffect(() => {
    if (!sourceAddr && myDetails.source?.coordinates) {
      MapIntelligenceService.getAddressAutocomplete(
        `${myDetails.source.coordinates[0]},${myDetails.source.coordinates[1]}`
      )
        .then((res) => {
          setSourceAddr(res.data.features[0].place_name)
        })
        .catch((err) => console.log(err))
    }

    if (!destAddr && myDetails.dest?.coordinates) {
      MapIntelligenceService.getAddressAutocomplete(
        `${myDetails.dest.coordinates[0]},${myDetails.dest.coordinates[1]}`
      )
        .then((res) => {
          setDestAddr(res.data.features[0].place_name)
        })
        .catch((err) => console.log(err))
    }
  }, [sourceAddr, setSourceAddr, destAddr, setDestAddr, myDetails])

  const contactInfo =
    carpoolRelation === 'MEMBER'
      ? carpoolMemberships[carpoolId]?.carpoolGroup.contactInfo
      : null
  const seatsLeft =
    carpoolRelation === 'MEMBER'
      ? carpoolMemberships[carpoolId]?.carpoolGroup.seatsLeft
      : myCarpoolGroups[carpoolId]?.seatsLeft
  const numSeats =
    carpoolRelation === 'MEMBER'
      ? carpoolMemberships[carpoolId]?.carpoolGroup.numSeats
      : myCarpoolGroups[carpoolId]?.numSeats

  // const clickableIcon = getClickableIcon(carpoolRelation)

  // const handleClick = () => {
  //   if (carpoolRelation === 'OWNER') {
  //     setEditing(true)
  //   }
  // }

  return (
    <div>
      <GridCard
        headerTitle={`Carpool${
          carpoolRelation === 'MEMBER' ? ' Membership' : ''
        } Overview`}
        // clickable={carpoolRelation !== 'MEMBER'}
        // clickableIcon={clickableIcon}
        // onClick={() => handleClick()}
      >
        <Container editing={editing}>
          <DetailsContainer editing={editing}>
            <SeatsRemainingContainer>
              <EmployeesIcon width='3rem' height='3rem' />
              <Text variant='action1'>{`${seatsLeft} / ${numSeats} Open Spots`}</Text>
            </SeatsRemainingContainer>
            {renderDetail('Departing From:', sourceAddr)}
            {renderDetail('Destination:', destAddr)}
            {renderDetail(
              'Destination Arrival Time:',
              convertTimeStringToAMPM(myDetails?.workArrivalTime)
            )}
            {renderDetail(
              'Destination Departure Time:',
              convertTimeStringToAMPM(myDetails?.workDepartureTime)
            )}
            {renderDetail('Role:', myDetails?.driver ? 'Driver' : 'Passenger')}
            {carpoolRelation === 'MEMBER'
              ? renderDetail('Carpool Owner Email:', contactInfo)
              : null}
          </DetailsContainer>
          {!editing ? (
            <CarpoolMap isMobile={isMobile} groupId={carpoolId} />
          ) : null}
        </Container>
      </GridCard>
    </div>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolDetailsCard.displayName = 'CarpoolDetailsCard'
}

export default CarpoolDetailsCard
