import Divider from 'core-system/Divider'
import * as FlexIcons from 'core-system/Icons/Flex'
import * as MicromobilityIcons from 'core-system/Icons/Micromobility'
import {
  Flex as FlexIcon,
  Micromobility as MicromobilityIcon,
} from 'core-system/Icons/Sidebar'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import {
  leasingProviders,
  oneTimeProviders,
} from 'redux/micromobility/micromobilitySlice'
import {
  FleetPartnerProviderInfo,
  Merchant,
} from 'redux/programs/programsTypes'
import ProgramChange from './ProgramChange'
import {
  Car as CarIcon,
  Rideshare as RideshareIcon,
} from 'core-system/Icons/modeTypes'

const optionsCopy = {
  shared: {
    title: 'Shared Micromobility',
    icon: (
      <MicromobilityIcons.SharedMicromobility color={palette.text.secondary} />
    ),
    description:
      'Allow commuters to rent shared micromobility vehicles on a per-trip basis.',
  },
  leasing: {
    title: 'Leasing',
    icon: <MicromobilityIcons.Leasing color={palette.text.secondary} />,
    description:
      'Allow commuters to lease micromobility vehicles through monthly memberships or subscriptions.',
  },
  oneTime: {
    title: 'One-time Purchase',
    icon: <MicromobilityIcons.OneTimePurchase color={palette.text.secondary} />,
    description:
      'Allow commuters to allocate remaining annual budget towards the purchase of a micromobility vehicle or an annual membership.',
  },
  flex: {
    title: 'Flex Providers',
    icon: <FlexIcon width={22} height={22} color={palette.text.secondary} />,
    description:
      'Allow commuters to use their budget on any selected service providers.',
  },
  transit: {
    title: 'Transit',
    icon: <FlexIcons.Transit color={palette.text.secondary} />,
    description:
      'Allow commuters to pay for public transportation, subway, train, and bus using their mobility budget.',
    line3: 'Maximum amount of $325 per month.',
    infoTitle: 'Key Transit Providers',
    infoDescription:
      'Commuters will be able to spend their budget on all local public transportation providers beyond only this list.',
  },
  gas: {
    title: 'Gas',
    icon: <FlexIcons.Gas color={palette.text.secondary} />,
    description: 'Allow commuters to use their budget to pay for gas.',
  },
  parking: {
    title: 'Parking',
    icon: <FlexIcons.Parking color={palette.text.secondary} />,
    description:
      'Allow commuters to pay for parking using their mobility budget.',
    line3: 'Maximum amount of $325 per month.',
  },
  transitQtf: {
    title: 'Transit',
    icon: <FlexIcons.Transit color={palette.text.secondary} />,
    description:
      'Allow commuters to pay for public transportation, subway, train, and bus using their mobility budget. Please set how you’d like to contribute:',
  },
  rideshare: {
    title: 'Rideshare',
    icon: <RideshareIcon color={palette.text.secondary} />,
    description:
      'Allow commuters to use their budget on any rideshare provider. Fleet automatically saves IRS qualified digital receipts.',
  },
  localTaxi: {
    title: 'Local Taxi',
    icon: <CarIcon color={palette.text.secondary} />,
    description:
      'Allow commuters to use their budget to pay for local taxis. Your commuters will be able to submit their receipts.',
  },
  micromobility: {
    title: 'Micromobility',
    icon: <MicromobilityIcon color={palette.text.secondary} />,
    description:
      'Allow commuters to use their budget to pay for micromobility services.',
  },
}

const modeTypes = [
  'MOPEDSHARE',
  'SCOOTER',
  'MOPED',
  'BIKESHARE',
  'BIKE',
  'SCOOTERSHARE',
  'SHUTTLE',
  'CARPOOL',
  'VANPOOL',
  'CAR',
  'TRANSIT',
  'WALK',
  'RIDESHARE',
  'EBIKE',
  'EBIKESHARE',
  'EMOPED',
  'EMOPEDSHARE',
  'ESCOOTER',
  'ESCOOTERSHARE',
  'CARSHARE',
]

const transitModeTypes = [
  'TRANSIT',
  'COMMRAIL',
  'HEAVYRAIL',
  'LITERAIL',
  'BUS',
  'FERRY',
  'AERIAL',
  'FUNICULAR',
  'MONORAIL',
]

const metroAreaStrings = {
  USSanFranciscoBayArea: 'San Francisco',
  sanFrancisco: 'San Francisco',
  USNewYorkMetropolitanArea: 'New York',
  newYork: 'New York',
  CAGreaterTorontoArea: 'Greater Toronto Area',
  USSeattleMetropolitanArea: 'Seattle',
  USAustinMetropolitanArea: 'Austin',
  USChicagoMetropolitanArea: 'Chicago',
  USBostonMetropolitanArea: 'Boston',
  USLosAngelesMetropolitanArea: 'Los Angeles',
  USMiamiMetropolitanArea: 'Miami',
  USDCMetroArea: 'Washington DC',
  USPhiladelphiaMetropolitanArea: 'Philadelphia',
  philadelphia: 'Philadelphia',
}

const countryCodeToNumber = {
  CA: 124,
  US: 840,
}

const countryNumberToName = {
  124: 'Canada',
  840: 'USA',
}

const getMetroAreaCountryNumber = (metroArea: string) =>
  countryCodeToNumber[metroArea.slice(0, 2)]

interface MerchantIdDifference {
  added: string[]
  removed: string[]
}

const getProviderDifference = (
  programState: any,
  options: string[]
): Dictionary<MerchantIdDifference> => {
  return options.reduce((grouped, option) => {
    const active = programState.active[option].activeMerchantIds
    const nextMonth = programState.nextMonth[option].activeMerchantIds

    const added = nextMonth.filter(
      (provider: string) => !active.includes(provider)
    )
    const removed = active.filter(
      (provider: string) => !nextMonth.includes(provider)
    )

    if (added.length > 0 || removed.length > 0) {
      grouped[option] = {
        added,
        removed,
      }
    }

    return grouped
  }, {})
}

const renderProviderChanges = (
  type: string,
  merchantIdChanges: any,
  title = true
) => {
  return (
    <>
      {title && (
        <>
          <Divider margin='1.5rem 0' />
          <Text
            variant='caption'
            captionTitle
            textColor={palette.text.secondary}
          >
            {optionsCopy[type].title}
          </Text>
        </>
      )}
      {merchantIdChanges.added.length > 0 && (
        <ProgramChange merchantIds={merchantIdChanges.added} value='On' />
      )}
      {merchantIdChanges.removed.length > 0 && (
        <ProgramChange merchantIds={merchantIdChanges.removed} value='Off' />
      )}
    </>
  )
}

const formatElectricModeTypes = (modeType: string) => {
  if (modeType[0].toLowerCase() === 'e') {
    return 'E-' + modeType[1].toUpperCase() + modeType.slice(2).toLowerCase()
  } else {
    return modeType
  }
}

// parse discount/partner url, code, and blurb from partnershipDescription field
const getFormattedPartnerInfo = (
  desc: string | null
): FleetPartnerProviderInfo => {
  if (!desc) return null

  const urlRx = /\[link\](.*?)\[link\]/
  const codeRx = /\[code\](.*?)\[code\]/

  const foundUrl = urlRx.exec(desc)
  const foundCode = codeRx.exec(desc)
  const formattedBlurb = desc.split('[')[0]

  if (!foundCode && !foundUrl && !formattedBlurb) return null
  return {
    partnerCode: foundCode ? foundCode[1] : null,
    partnerUrl: foundUrl ? foundUrl[1] : null,
    partnerBlurb: formattedBlurb === '' ? null : formattedBlurb,
  }
}

// merchants for micro programs return as a single array, this function
// sort the merchants into appropriate shared, leasing, & oneTime categories
const sortMicroMerchantIds = (
  activeMerchantIds: string[],
  merchantsMap: Dictionary<Merchant>
) => {
  //need to sort merchants by shared, leasing, & one time
  //to allow users to select specific modes per category
  return activeMerchantIds.reduce(
    (agg, id) => {
      const merchant = merchantsMap[id]
      if (merchant.modeTypes.some((modeType) => modeType.includes('SHARE'))) {
        agg.shared.push(merchant.id)
      }
      if (
        leasingProviders.includes(merchant.name) ||
        merchant.pricingTypes.includes('LEASING')
      ) {
        agg.leasing.push(merchant.id)
      }
      if (oneTimeProviders.includes(merchant.name)) {
        agg.oneTime.push(merchant.id)
      }
      return agg
    },
    {
      shared: [],
      leasing: [],
      oneTime: [],
    }
  )
}
const taxiProviderNames = ['yellow cab sf', 'nyc yellow cab']

export default {
  optionsCopy,
  transitModeTypes,
  modeTypes,
  metroAreaStrings,
  countryCodeToNumber,
  countryNumberToName,
  getMetroAreaCountryNumber,
  getProviderDifference,
  renderProviderChanges,
  formatElectricModeTypes,
  getFormattedPartnerInfo,
  sortMicroMerchantIds,
  taxiProviderNames,
}
