import React from 'react'
import styled from 'styled-components'
import { Feature } from 'geojson'
import MapPopupEmployee from 'core-system/Map/Popups/MapPopupEmployee'
import Text from 'core-system/Text'
import Divider from 'core-system/Divider'
import palette from 'core-system/Themes/palette'

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const LegendColorContainer = styled.div`
  width: 1.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LegendColor = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border: 1px solid rgba(0, 0, 0, 0.16);
  width: 0.6875rem;
  height: 0.6875rem;
  border-radius: 2rem;
`

const getLegendColor = (feature: Feature) => {
  if (feature.properties.bike900) {
    return {
      color: palette.primary.pink400,
      label: 'Micromobility Main Mile',
    }
  } else if (feature.properties.withinTransit) {
    return { color: '#C30078', label: 'Micromobility First/Last Mile' }
  } else {
    return { color: palette.primary.pink500, label: 'Other' }
  }
}

interface MicromobilityPopupEmployeeProps {
  feature: Feature
}

const MicromobilityPopupEmployee = React.memo(
  (props: MicromobilityPopupEmployeeProps) => {
    const { feature } = props
    const { color, label } = getLegendColor(feature)
    return (
      <MapPopupEmployee feature={feature}>
        <Divider margin='0.5rem 0' />
        <FlexContainer>
          <LegendColorContainer>
            <LegendColor color={color}></LegendColor>
          </LegendColorContainer>
          <Text variant='action4' margin='0.125rem 0 0 0.5rem'>
            {label}
          </Text>
        </FlexContainer>
      </MapPopupEmployee>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityPopupEmployee.displayName = 'MicromobilityPopupEmployee'
}

export default MicromobilityPopupEmployee
