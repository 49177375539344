import React, { useState, MouseEvent } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { CircularProgress, Divider, IconButton, Tooltip } from '@mui/material'
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import UserAvatarWithInfo from './avatar/UserAvatarWithInfo'
import { useSelector } from 'react-redux'
import palette from 'core-system/Themes/palette'
import AddIcon from '@mui/icons-material/Add'

const useStyles = makeStyles(() => ({
  mainContainer: {
    position: 'relative',
    zIndex: 99,
    background: palette.primary.pink800,
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 18px',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pointer: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  newThreadContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

interface ChatHeaderProps {
  displayName: string
  isOnline: boolean
  onCloseButtonClick?: () => void
  userDropDownContent?: React.ReactNode
  showDropDown?: boolean
  closeDropDownOnContentClick?: boolean
  onNewThreadButtonClick?: () => void
  chatState: string
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  displayName,
  isOnline,
  onCloseButtonClick = () => {},
  userDropDownContent = null,
  showDropDown = false,
  closeDropDownOnContentClick = false,
  onNewThreadButtonClick = () => {},
  chatState,
}) => {
  const classes = useStyles()
  const { profile } = useSelector((state: any) => state.employer)

  return (
    <div className={classes.mainContainer}>
      <div className={classes.innerContainer}>
        <div className={classes.avatarContainer}>
          <UserAvatarWithInfo
            name={displayName}
            isOnline={isOnline}
            imageSrc={profile?.clearbitUrl}
            showInfo
            showDropDown={showDropDown}
            dropDownContent={userDropDownContent}
            closeDropDownOnContentClick={closeDropDownOnContentClick}
          />
        </div>
        <div className={classes.newThreadContainer}>
          {chatState !== 'loading' ? (
            <Tooltip title='Start New Thread'>
              <IconButton color='default' onClick={onNewThreadButtonClick}>
                <AddIcon style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <CircularProgress
              sx={{
                color: palette.primary.pink100,
                marginRight: '10px',
              }}
              size={24}
            />
          )}
          <IconButton color='default' onClick={onCloseButtonClick}>
            <HighlightOffIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      </div>
      <Divider />
    </div>
  )
}

export default React.memo(ChatHeader)
