import Text from 'core-system/Text'
import React from 'react'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import styled from 'styled-components'

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76vh;
`

const Container = styled.div`
  width: 100%;
  padding: 2rem 3.25rem 3.25rem;
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.text.primary};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border-radius: 0.9375rem;
  max-width: ${(props) => props.theme.pxToRem(636)};
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`

const StyledImage = styled.img`
  position: absolute;
  top: ${(props) => props.theme.pxToRem(-85)};
  margin-left: 2rem;
`

// interface GlobalCrashProps {
//   error: Error
//   info: React.ErrorInfo
//   clearError: () => void
// }

const ErrorView = React.memo(() => {
  return (
    <Content>
      <Container>
        <StyledImage
          alt={'error'}
          src={`${StaticAssetsUrl}/shared/error.png`}
        />
        <Text
          variant='action3'
          width='70%'
          paddingTop='4rem'
          marginBottom='1rem'
        >
          Oops! Something doesn&apos;t seem right on this page. Please refresh
          your browser.
        </Text>
        <Text variant='body1' width='80%'>
          If you keep getting this message please contact us via chat at the
          bottom right corner of your screen or email us at{' '}
          <a href='mailto:help@movewithfleet.com'>help@movewithfleet.com</a>
        </Text>
      </Container>
    </Content>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ErrorView.displayName = 'ErrorView'
}

export default ErrorView
