import styled, { keyframes } from 'styled-components'

export const pulse = keyframes`
  0% { opacity: 1 };
  50% { opacity: 0.2 };
  100% { opacity: 1 };
`

export const Pulse = styled.div`
  animation: ${pulse} 3s infinite linear;
  animation-fill-mode: forwards;
`

export const LoadingBar = styled(Pulse)`
  background: ${(props) => props.theme.palette.grey.grey300};
  background-size: 200% auto;
  width: 100%;
  height: 1rem;
  margin: 0.5rem 0;
`

export default LoadingBar
