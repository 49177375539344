import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import CalendarIcon from 'core-system/Icons/Misc/Calendar'
import Text from 'core-system/Text'
import FlexPreviewModal from 'features/Flex/Overview/components/FlexPreviewModal'
import GRHPreviewModal from 'features/GRH/Overview/GRHPreviewModal'
import IncentivesPreviewModal from 'features/Incentives/IncentivesPreviewModal'
import LeaderboardModal from 'features/Leaderboards/components/LeaderboardModal'
import MicromobilityPreviewModal from 'features/Micromobility/Overview/components/MicromobilityPreviewModal'
import NavigationBar from 'features/NavigationBar/NavigationBar'
import QTFPreviewModal from 'features/QTF/Overview/QTFPreviewModal'
import SidebarMenu from 'features/Sidebar/SidebarMenu'
import moment from 'moment'
import React, { SyntheticEvent, useCallback, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { Link, Navigate, useParams, useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import useCurrentPath from 'shared/Hooks/useCurrentPath'
import useGlobalScroll from 'shared/Hooks/useGlobalScroll'
import useResizeListener from 'shared/Hooks/useResizeListener'
import { locationData, Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import ChevronIcon from '../Icons/Actions/Chevron'
import HamburgerIcon from '../Icons/Misc/Hamburger'
import Portal from '../Portal'
import PageHeaderIcon from './PageHeaderIcon'
import OTPPreviewModal from 'features/OTP/Overview/OTPPreviewModal'
import { screenSizes } from 'shared/RandomUtils'
import NoPaymentModal from 'shared/components/NoPaymentModal'

const Container = styled.div<{ shadow: boolean }>`
  height: ${(props) => props.theme.pxToRem(80)};
  background-color: ${(props) => props.theme.palette.white};
  padding: ${(props) => props.theme.pxToRem(22)} 2rem;
  box-shadow: ${(props) =>
    props.shadow ? props.theme.dropShadows.top : 'unset'};
  transition: all 0.2s ease;
  z-index: ${(props) => props.theme.zIndex.navigation};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.text.primary};
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey300};

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: ${(props) => props.theme.pxToRem(22)} 1.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 0 1.5rem;
  }
`

const IconContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink200};
  }
`

const TitleContainer = styled.div<{ hasHistory: boolean }>`
  display: flex;
  ${(props) =>
    props.hasHistory
      ? props.theme.typography.action4
      : props.theme.typography.h2};
  cursor: ${(props) => (props.hasHistory ? 'pointer' : 'unset')};
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 0.5rem;
  padding: ${(props) => (props.hasHistory ? '0 0.75rem 0 0.25rem' : 0)};

  &:hover {
    background-color: ${(props) =>
      props.hasHistory ? props.theme.palette.primary.pink500 : 'transparent'};
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    ${(props) => props.theme.typography.h3};
    height: ${(props) => props.theme.pxToRem(80)};
    justify-content: flex-start;
    width: 100%;
    border-radius: unset;

    &:hover {
      background-color: transparent;
    }
  }
`

const BackIconContainer = styled.div`
  transform: rotate(90deg);
  height: 1.5rem;
  width: 1.625rem;
`

const SidebarContainer = styled.div<{ showSidebar: boolean }>`
  position: absolute;
  top: 0;
  z-index: ${(props) => props.theme.zIndex.max};
  height: 100vh;
  width: ${(props) => (props.showSidebar ? props.theme.pxToRem(272) : 0)};
  transition: width 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  overflow: auto;
`

const NavigationBarContainer = styled.div<{ showSidebar: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${(props) => props.theme.zIndex.max};
  height: 100vh;
  width: ${(props) => (props.showSidebar ? props.theme.pxToRem(442) : 0)};
  transition: width 0.23s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.grey.grey100};
`

const DimmedBackground = styled.div<{ showSidebar: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(75, 85, 101, 0.5);
  transition: opacity 0.2s ease-in-out;
  opacity: ${(props) => (props.showSidebar ? 1 : 0)};
  display: ${(props) => (props.showSidebar ? 'block' : 'none')};
  z-index: ${(props) => props.theme.zIndex.max - 1};
`

const StyledCalendarIcon = styled(CalendarIcon)`
  margin-bottom: 0.125rem;
`

const StyledLink = styled(Link)`
  ${(props) => props.theme.typography.hyperlink};
  color: ${(props) => props.theme.palette.primary.pink600};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

interface PageHeaderProps {
  platformType: 'employee' | 'employer'
}

const PageHeader = (props: PageHeaderProps) => {
  const { platformType } = props

  const path = useCurrentPath()

  const { hasValidPaymentMethod, profile } = useSelector(
    (state: AppState) => state.employer
  )

  const { segmentId, carpoolGroupId, carpoolRequestId, questionId } =
    useParams()

  const [hasShadow, setHasShadow] = useState(0)
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= screenSizes.tablet
  )
  useResizeListener(
    () => setIsMobile(window.innerWidth <= screenSizes.mobile),
    []
  )
  useResizeListener(
    () => setIsTablet(window.innerWidth <= screenSizes.tablet),
    []
  )
  const [showMobileSidebar, setShowMobileSidebar] = useState(false)
  const [showNavigationBar, setShowNavigationBar] = useState(false)
  const [isNoPaymentModalOpen, setIsNoPaymentModalOpen] = useState(false)
  const [isFlexModalOpen, setIsFlexModalOpen] = useState(false)
  const [isIncentivesModalOpen, setIsIncentivesModalOpen] = useState(false)
  const [isMicromobilityModalOpen, setIsMicromobilityModalOpen] =
    useState(false)
  const [isLeaderboardsModalOpen, setIsLeaderboardsModalOpen] = useState(false)
  const [isQTFModalOpen, setIsQTFModalOpen] = useState(false)
  const [isGRHModalOpen, setIsGRHModalOpen] = useState(false)
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false)

  const { segmentsMap } = useSelector((state: AppState) => state.employer)
  const navigate = useNavigate()

  const handleScroll = useCallback(
    (e: SyntheticEvent) => {
      const target = e.target as HTMLElement
      if (target.id === 'page-main-body') {
        setHasShadow(target.scrollTop)
      }
    },
    [setHasShadow]
  )

  const handleToggle = () => {
    setShowMobileSidebar(!showMobileSidebar)
    SegmentService.track('sidebar-toggle-action', {
      action: showMobileSidebar ? 'close' : 'open',
      type: 'mobile',
    })
  }

  const getLocationTitle = (location: string) => {
    if (
      locationData[location].title !== ':id' &&
      locationData[location].title !== ':segmentId' &&
      locationData[location].title !== ':carpoolGroupId' &&
      locationData[location].title !== ':carpoolRequestId' &&
      locationData[location].title !== ':questionId'
    ) {
      return locationData[location].title
    } else {
      if (
        (locationData[location].title === ':segmentId' ||
          locationData[location].title === ':id') &&
        segmentId
      ) {
        return (
          segmentsMap &&
          (segmentsMap[segmentId].name === 'All Employees'
            ? 'All Commuters'
            : segmentsMap[segmentId].name)
        )
      } else if (
        locationData[location].title === ':carpoolGroupId' &&
        carpoolGroupId
      ) {
        return carpoolGroupId
      } else if (
        locationData[location].title === ':carpoolRequestId' &&
        carpoolRequestId
      ) {
        return carpoolRequestId
      } else if (locationData[location].title === ':questionId' && questionId) {
        return questionId
      } else {
        return location
      }
    }
  }

  const renderPathHistory = (path: string) => {
    const locationPath = locationData[path].path
    if (!locationPath) {
      if (locationData[path]) {
        return (
          <Text variant='h2' hideOverflow>
            {locationData[path].title === 'Guaranteed Ride Home' && isMobile
              ? 'GRH'
              : locationData[path].title}
          </Text>
        )
      } else {
        return <Navigate to={Locations.Error} replace />
      }
    } else {
      return (
        <FlexContainer alignItems='center'>
          <BackIconContainer>
            <ChevronIcon width={24} height={24} />
          </BackIconContainer>
          {locationPath.map((locationPart, idx) => {
            // REPLACE ALL ID PLACEHOLDERS WITH THEIR PROPER VALUES
            const linkto = (locationPart: string) => {
              let newLocationPart = locationPart
              if (locationPart.includes(':segmentId')) {
                newLocationPart = newLocationPart.replace(
                  ':segmentId',
                  segmentId
                )
              }
              if (locationPart.includes(':carpoolGroupId')) {
                newLocationPart = newLocationPart.replace(
                  ':carpoolGroupId',
                  carpoolGroupId
                )
              }
              if (locationPart.includes(':carpoolRequestId')) {
                newLocationPart = newLocationPart.replace(
                  ':carpoolRequestId',
                  carpoolRequestId
                )
              }
              if (locationPart.includes(':questionId')) {
                newLocationPart = newLocationPart.replace(
                  ':questionId',
                  questionId
                )
              }
              return newLocationPart
            }
            return locationPath.length === idx + 1 ? (
              <Text key={idx} variant='body1' hideOverflow>
                {getLocationTitle(locationPart)}
              </Text>
            ) : (
              <FlexContainer key={idx}>
                <StyledLink to={linkto(locationPart)} state={{ from: path }}>
                  {getLocationTitle(locationPart)}{' '}
                </StyledLink>
                <Text variant='body1' margin='0 0.25rem'>
                  /
                </Text>
              </FlexContainer>
            )
          })}
        </FlexContainer>
      )
    }
  }

  const determineCta = (path: string) => {
    if (!hasValidPaymentMethod && profile.openRegistration) {
      setIsNoPaymentModalOpen(true)
    } else if (path === Locations.Flex.Programs) {
      setIsFlexModalOpen(true)
    } else if (path === Locations.Micromobility.Programs) {
      setIsMicromobilityModalOpen(true)
    } else if (path === Locations.Incentives.Programs) {
      setIsIncentivesModalOpen(true)
    } else if (path === Locations.Leaderboards.Programs) {
      setIsLeaderboardsModalOpen(true)
    } else if (path === Locations.QTF.Programs) {
      navigate(Locations.QTF.ActivationDefault)
    } else if (path === Locations.GRH.Programs) {
      setIsGRHModalOpen(true)
    } else if (path === Locations.OTP.Programs) {
      setIsOTPModalOpen(true)
    }
    if (locationData[path].analyticsTitle) {
      SegmentService.track('nav-cta-click', {
        locationAt: locationData[path].analyticsTitle,
      })
    }
  }

  const getHeaderBtn = (type: 'sidebar' | 'cta', text: string) => {
    if (type === 'sidebar') {
      return (
        <Button
          variant='secondary'
          size='small'
          onClick={() => {
            setShowNavigationBar(true)
            SegmentService.track('segmentNav-action-click', {
              action: 'all',
              locationAt: `${locationData[path].analyticsTitle}-${segmentsMap[segmentId].name}`,
            })
          }}
        >
          {text}
        </Button>
      )
    } else if (type === 'cta') {
      return (
        <Button
          variant='primary'
          size='small'
          onClick={() => determineCta(path)}
        >
          {text}
        </Button>
      )
    } else {
      return null
    }
  }

  const getNoPaymentProduct = (path: string) => {
    if (path === Locations.Flex.Overview || path === Locations.Flex.Programs) {
      return 'flex'
    } else if (
      path === Locations.Micromobility.Overview ||
      path === Locations.Micromobility.Programs
    ) {
      return 'micromobility'
    } else if (
      path === Locations.Incentives.Programs ||
      path === Locations.Incentives.Trips
    ) {
      return 'incentives'
    } else if (
      path === Locations.Leaderboards.Programs ||
      path === Locations.Leaderboards.Leaderboards
    ) {
      return 'competition'
    } else if (
      path === Locations.QTF.Overview ||
      path === Locations.QTF.Programs
    ) {
      return 'qtf'
    } else if (
      path === Locations.GRH.Overview ||
      path === Locations.GRH.Programs
    ) {
      return 'grh'
    } else if (
      path === Locations.OTP.Overview ||
      path === Locations.OTP.Programs
    ) {
      return 'otp'
    } else {
      return 'null'
    }
  }

  useGlobalScroll((e) => handleScroll(e))

  if (
    segmentId &&
    segmentId != 'undefined' &&
    segmentsMap &&
    !segmentsMap[segmentId]
  ) {
    return <Navigate to={Locations.Error} replace />
  }

  const pathData = locationData[path]

  return (
    <>
      <Helmet>
        <title>{pathData.head ? pathData.head : pathData.title} | Fleet</title>
      </Helmet>
      <Container shadow={hasShadow > 30}>
        <TitleContainer hasHistory={false}>
          {isTablet && (
            <IconContainer onClick={() => handleToggle()}>
              <HamburgerIcon width={24} height={24} />
            </IconContainer>
          )}
          {renderPathHistory(path)}
        </TitleContainer>
        <FlexContainer alignItems='center'>
          {pathData.btnVariant &&
            getHeaderBtn(pathData.btnType, pathData.btnText)}
          {pathData.btnVariant && pathData.hasDate && (
            <Divider direction='vertical' margin='0 1rem' size='2rem' />
          )}
          {pathData.hasDate && (
            <FlexContainer alignItems='center'>
              <StyledCalendarIcon />
              <Text variant='action4' marginLeft='0.5rem' hideOverflow>
                {moment().format('MMM, YYYY')}
              </Text>
            </FlexContainer>
          )}
          {(pathData.btnVariant || pathData.hasDate) && (
            <Divider direction='vertical' margin='0 1rem' size='2rem' />
          )}
          <PageHeaderIcon platformType={platformType} />
        </FlexContainer>
      </Container>
      <Portal>
        <SidebarContainer showSidebar={showMobileSidebar}>
          <SidebarMenu isCollapsed={false} platformType={platformType} />
        </SidebarContainer>
        <DimmedBackground
          showSidebar={showMobileSidebar}
          onClick={handleToggle}
        />
      </Portal>
      <Portal>
        <NavigationBarContainer showSidebar={showNavigationBar}>
          <NavigationBar
            onClose={() => setShowNavigationBar(false)}
            toggleNoPaymentModal={() =>
              setIsNoPaymentModalOpen((prev) => !prev)
            }
            toggleFlexModal={() => setIsFlexModalOpen((prev) => !prev)}
            toggleMicromobilityModal={() =>
              setIsMicromobilityModalOpen((prev) => !prev)
            }
            toggleLeaderboardsModal={() =>
              setIsLeaderboardsModalOpen((prev) => !prev)
            }
            toggleIncentivesModal={() =>
              setIsIncentivesModalOpen((prev) => !prev)
            }
            toggleQTFModal={() => setIsQTFModalOpen((prev) => !prev)}
            toggleGRHModal={() => setIsGRHModalOpen((prev) => !prev)}
            toggleOTPModal={() => setIsOTPModalOpen((prev) => !prev)}
            segmentId={segmentId}
          />
        </NavigationBarContainer>
        <DimmedBackground
          showSidebar={showNavigationBar}
          onClick={() => {
            setShowNavigationBar(false)
            SegmentService.track('segmentNav-action-click', {
              action: 'outside-close',
              locationAt: `${locationData[path].analyticsTitle}-${segmentsMap[segmentId].name}`,
            })
          }}
        />
      </Portal>
      <FlexPreviewModal
        open={isFlexModalOpen}
        closeModal={() => setIsFlexModalOpen(false)}
      />
      <MicromobilityPreviewModal
        open={isMicromobilityModalOpen}
        closeModal={() => setIsMicromobilityModalOpen(false)}
      />
      <IncentivesPreviewModal
        open={isIncentivesModalOpen}
        closeModal={() => setIsIncentivesModalOpen(false)}
      />
      <LeaderboardModal
        open={isLeaderboardsModalOpen}
        closeModal={() => setIsLeaderboardsModalOpen(false)}
      />
      <QTFPreviewModal
        open={isQTFModalOpen}
        closeModal={() => setIsQTFModalOpen(false)}
      />
      <GRHPreviewModal
        open={isGRHModalOpen}
        closeModal={() => setIsGRHModalOpen(false)}
      />
      <OTPPreviewModal
        open={isOTPModalOpen}
        closeModal={() => setIsOTPModalOpen(false)}
      />
      <NoPaymentModal
        open={isNoPaymentModalOpen}
        closeModal={() => setIsNoPaymentModalOpen(false)}
        product={getNoPaymentProduct(path)}
      />
    </>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  PageHeader.displayName = 'PageHeader'
}

export default PageHeader
