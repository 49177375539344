import React from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import FlexContainer from 'core-system/FlexContainer'
import FormattingUtils from 'shared/FormattingUtils'
import Divider from 'core-system/Divider'
import palette from 'core-system/Themes/palette'
import Chip from 'core-system/Chip'
import ProgramUtils from '../ProgramUtils'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Button from 'core-system/Button'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const IconContainer = styled(FlexContainer)`
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border-radius: 50%;
  margin-right: 1rem;
`

interface ProgramOverviewOptionsProps {
  activeOptions: Dictionary<boolean>
  budget: number
}

const ProgramOverviewOptions = React.memo(
  (props: ProgramOverviewOptionsProps) => {
    const { activeOptions, budget } = props

    return (
      <Container>
        <Text variant='h5' marginBottom='1rem'>
          Per Commuter Budget
        </Text>
        <FlexContainer alignItems='center'>
          <PlatformSvg
            folder='metrics'
            variant='employeeCostPurple'
            width={40}
            height={40}
          />
          <Text variant='h2' marginLeft='0.5rem'>
            {FormattingUtils.formatDollar(budget, 0)}
          </Text>
        </FlexContainer>
        <Divider margin='1rem 0 1.25rem' />
        <Text
          variant='caption'
          captionTitle
          textColor={palette.text.secondary}
          marginBottom='0.5rem'
        >
          Program Options
        </Text>
        {Object.keys(activeOptions).map((option) => (
          <FlexContainer
            alignItems='center'
            marginBottom='0.25rem'
            padding='0.5rem'
            key={option}
          >
            <IconContainer center>
              {ProgramUtils.optionsCopy[option].icon}
            </IconContainer>
            <Text variant='action3'>
              {ProgramUtils.optionsCopy[option].title}
            </Text>
            <Chip
              marginLeft='auto'
              variant={activeOptions[option] ? 'green' : 'grey'}
            >
              {activeOptions[option] ? 'Active' : 'Disabled'}
            </Chip>
          </FlexContainer>
        ))}
        <FlexContainer flexDirection='column' marginTop='auto'>
          <Button
            size='small'
            variant='secondary'
            width='100%'
            onClick={() => null}
            disabled
          >
            View Reports
          </Button>
        </FlexContainer>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramOverviewOptions.displayName = 'ProgramOverviewOptions'
}

export default ProgramOverviewOptions
