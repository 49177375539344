import SelectItemV2 from 'core-system/Dropdown/SelectItemV2'
import TextFieldV2 from 'core-system/TextFieldV2'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import MapIntelligenceService from 'redux/mapIntelligence/mapIntelligenceService'
import styled, { css } from 'styled-components'

interface AutoCompleteResults {
  type: string
  query: string[]
  features: any[]
  attribution: string
}

export interface Result {
  address: string
  coordinates: number[]
}

const Container = styled.div`
  width: 100%;
  position: relative;
`

const StyledTextField = styled(TextFieldV2)<{ isOpen: boolean }>`
  text-align: start;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: ${pxToRem('4')};

  ${(props) =>
    props.isOpen &&
    css`
      border-radius: ${pxToRem('4')} ${pxToRem('4')} 0 0;
    `};
`

const StyledSelectItem = styled(SelectItemV2)`
  text-align: start;
`

const ResultsContainer = styled.div`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.dropdowns};
  box-shadow: ${(props) => props.theme.dropShadows.selected};
  padding: 0.5rem 0;
  border-radius: 0 0 ${pxToRem('4')} ${pxToRem('4')};
  background-color: ${(props) => props.theme.palette.white};
  width: 100%;
  top: ${(props) => props.theme.pxToRem(64)};
  border: 1px solid ${(props) => props.theme.palette.grey.grey10};
  > div {
    color: ${(props) => props.theme.palette.primary.pink500};
  }
`

const formatResults = (results: AutoCompleteResults) => {
  return results.features.map((location) => {
    return { address: location.place_name_en, coordinates: location.center }
  })
}

interface AddressAutocompleteProps {
  label: string
  hasError: boolean
  onActiveChange: (location: Result) => void
  customPlaceholder?: string
}

const AddressAutocompleteV2 = React.memo((props: AddressAutocompleteProps) => {
  const { label, hasError, onActiveChange, customPlaceholder } = props
  const [address, setAddress] = useState('')
  const [results, setResults] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const containerRef = useRef(null)

  const clickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        containerRef &&
        containerRef.current &&
        containerRef.current.contains(e.target)
      ) {
        return
      }
      setIsOpen(false)
    },
    [containerRef]
  )

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside, false)
    return () => {
      document.removeEventListener('mousedown', clickOutside, false)
    }
  }, [clickOutside])

  const handleOnClick = (result: Result) => {
    setAddress(result.address)
    setIsOpen(false)
    onActiveChange(result)
  }

  const handleOnInputClick = () => {
    if (!isOpen && results) {
      setIsOpen(true)
    }
  }

  const renderResults = (results: Result[]) => {
    return results.map((result, idx) => {
      return (
        <StyledSelectItem
          active={true}
          key={idx}
          onClick={() => handleOnClick(result)}
        >
          {result.address}
        </StyledSelectItem>
      )
    })
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value

    setAddress(newValue)

    if (newValue.length <= 4 && results) {
      setIsOpen(false)
      setResults(null)
    } else if (newValue.length > 4) {
      MapIntelligenceService.getAddressAutocomplete(newValue)
        .then((res) => {
          setResults(formatResults(res.data))
          setIsOpen(true)
        })
        .catch((err) => console.log(err))
    }

    if (newValue.trim().length === 0) {
      onActiveChange({ address: '', coordinates: [] })
    }
  }

  return (
    <Container ref={containerRef}>
      <StyledTextField
        label={label}
        onChange={(e) => handleOnChange(e)}
        onClick={handleOnInputClick}
        value={address}
        autoComplete='false'
        type='text'
        name='hidden'
        role='presentation'
        placeholder={customPlaceholder ? customPlaceholder : 'Street Address *'}
        isOpen={isOpen}
        invalid={hasError}
        helpText={'Please select a full address'}
      />
      {isOpen && <ResultsContainer>{renderResults(results)}</ResultsContainer>}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AddressAutocompleteV2.displayName = 'AddressAutocompleteV2'
}

export default AddressAutocompleteV2
