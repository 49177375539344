import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  variant,
} from 'styled-system'

import { buildComponent } from '../utils/buildComponent'

type ToggleSize = 'small' | 'large'
type ToggleVariant = 'dark' | 'light'

const Container = styled.div`
  ${layout}
  ${space}
  ${flexbox}
`

const Label = styled.p`
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.secondary};
  margin-bottom: 0.25rem;
`

const Option = styled.div<{
  active: boolean
  variant: ToggleVariant
  darkBg: boolean
}>`
  position: relative;
  height: calc(100% + 0.125rem);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  ${(props) =>
    variant({
      variants: {
        light: {
          color: props.active
            ? props.theme.palette.white
            : props.theme.palette.primary.pink800,
          backgroundColor: props.active
            ? props.theme.palette.primary.pink800
            : props.darkBg
              ? props.theme.palette.white
              : undefined,
        },
        dark: {
          color: props.active
            ? props.theme.palette.white
            : props.theme.palette.text.secondary,
          backgroundColor: props.active
            ? props.theme.palette.primary.pink800
            : undefined,
        },
      },
    })}
`

const Divider = styled.div<{ variant: ToggleVariant }>`
  width: 0.0625rem;
  height: 1.5625rem;
  background-color: ${(props) =>
    props.variant === 'light'
      ? props.theme.palette.grey.grey300
      : props.theme.palette.grey.grey200};
  align-self: center;
`

const ToggleContainer = styled.div<{
  width: string
  size: ToggleSize
  variant: ToggleVariant
  darkBg: boolean
}>`
  ${(props) =>
    props.size === 'small'
      ? props.theme.typography.action6
      : props.theme.typography.action1}
  width: ${(props) => props.width};
  height: ${(props) => (props.size === 'small' ? '2rem' : '2.5rem')};
  font-size: ${(props) => (props.size === 'small' ? '0.875rem' : '1.125rem')};
  font-weight: ${(props) => (props.size === 'small' ? '500' : '600')};
  border-radius: 0.5rem;
  border: 0.0625rem solid
    ${(props) =>
      props.variant === 'light'
        ? props.theme.palette.grey.grey300
        : props.theme.palette.grey.grey200};
  background-color: ${(props) =>
    props.variant === 'light'
      ? props.darkBg
        ? props.theme.palette.white
        : props.theme.palette.grey.grey100
      : props.theme.palette.grey.grey100};
  display: flex;
  align-items: center;
  cursor: pointer;

  & > ${Option} {
    padding: ${(props) =>
      props.size === 'small' ? '0.25rem 0' : '0.5rem 1rem'};
  }
`

export interface TextToggleProps extends SpaceProps, LayoutProps, FlexboxProps {
  toggled: number
  options: string[]
  onClick: (
    index: number,
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void
  optionWidth?: number
  fullWidth?: boolean
  variant?: ToggleVariant
  size?: ToggleSize
  label?: string
  darkBg?: boolean
}

const TextToggle = (props: TextToggleProps) => {
  const {
    toggled,
    options,
    onClick,
    optionWidth = props.size === 'small' ? 4.546875 : 9.21875,
    variant,
    size,
    label,
    fullWidth = false,
    darkBg = false,
    ...rest
  } = props

  return (
    <Container {...rest}>
      {label && <Label>{label}</Label>}
      <ToggleContainer
        size={size}
        variant={variant}
        width={fullWidth ? '100%' : `${optionWidth * options.length}rem`}
        darkBg={darkBg}
      >
        {options.map((option, index) => {
          const displayDivider = !(
            index === toggled - 1 ||
            index === toggled ||
            index === options.length - 1
          )
          return (
            <React.Fragment key={index}>
              <Option
                key={option}
                onClick={() => onClick(index)}
                active={toggled === index}
                variant={variant}
                darkBg={darkBg}
              >
                {option}
              </Option>
              {displayDivider && <Divider variant={variant} />}
            </React.Fragment>
          )
        })}
      </ToggleContainer>
    </Container>
  )
}

export default buildComponent(TextToggle, 'TextToggle')
