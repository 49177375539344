import Divider from 'core-system/Divider'
import Dropdown, { SelectItemProps } from 'core-system/Dropdown'
import FlexContainer from 'core-system/FlexContainer'
import Radio from 'core-system/Radio'
import SwitchToggle from 'core-system/SwitchToggle'
import Text from 'core-system/Text'
import React, { useState } from 'react'
import SegmentService from 'redux/config/services/SegmentService'
import { Segment } from 'redux/employer/employerTypes'
import DateUtils, { Month } from 'shared/DateUtils'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

const Container = styled.div<SpaceProps>`
  ${(props) => props.theme.baseCard}
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;

  ${space}
`

const OfficeContainer = styled.div`
  ${(props) => props.theme.typography.h4};

  span {
    color: ${(props) => props.theme.palette.text.placeholder};
  }
`

const RadioContainer = styled.div<{ hasDateSelector: boolean }>`
  display: flex;
  margin: ${(props) => (props.hasDateSelector ? '1.5rem 0' : '1.5rem 0 0 0')};
`

const DropdownContainer = styled.div`
  margin-top: 1.5rem;
`

const getEndDateInternal = (
  isActive: boolean,
  dates: Month[],
  endDate: string
) => {
  if (!isActive) {
    return '2'
  } else {
    const match = dates.find((date) => date.text === endDate)
    return match ? match.id : '2'
  }
}

interface WorksiteAndDateConfigProps extends SpaceProps {
  onDateChange?: (val: string, text: string, initial?: boolean) => void
  onEndDateChange?: (val: string, text: string, initial?: boolean) => void
  currentSegment: Segment
  dropdownItems?: SelectItemProps[]
  onSegmentChange?: (segmentId: string) => void
  startDate?: string
  isActive?: boolean
  canEdit?: boolean
  endDate?: string
  includeEndDate?: boolean
  startDateDescription?: string
  // Allows to start directly 2 months later
  isLateStart?: boolean
  showDates2MonthsLater?: boolean
  excludeContractors?: boolean
}

const WorksiteAndDateConfig = React.memo(
  (props: WorksiteAndDateConfigProps) => {
    const {
      onDateChange,
      onEndDateChange,
      currentSegment,
      dropdownItems,
      onSegmentChange,
      isActive,
      startDate,
      canEdit,
      endDate,
      includeEndDate,
      startDateDescription,
      isLateStart,
      showDates2MonthsLater,
      excludeContractors = false,
      ...rest
    } = props

    const dates = DateUtils.getNextXMonths(6, showDates2MonthsLater ? 2 : 1)

    const [dateSelector, setDateSelector] = useState(isLateStart ? 1 : 0)
    const [selectedDate, setSelectedDate] = useState(
      showDates2MonthsLater ? '2' : '1'
    )

    const [showEndDateSelector, setShowEndDateSelector] = useState(
      endDate ? true : false
    )
    const [selectedEndDate, setSelectedEndDate] = useState(
      getEndDateInternal(isActive, dates, endDate)
    )

    const handleDateSelectorChange = (num: number) => {
      const data =
        num === 0
          ? { val: null, text: null, initial: true }
          : { val: dates[0].iso, text: dates[0].text }

      if (num === 0) setSelectedDate('1')
      onDateChange(data.val, data.text, data.initial)
      setDateSelector(num)
      SegmentService.track('baseOptions-toggle-action', {
        actionType: num === 0 ? 'next-month' : 'schedule',
      })
    }

    const handleStartDateChange = (item: Month) => {
      onDateChange(item.iso, item.text)
      setSelectedDate(item.id)
      SegmentService.track('baseOptions-toggle-action', {
        actionType: 'starting-date',
      })
    }

    const handleEndDateChange = (item: Month) => {
      if (onEndDateChange) {
        onEndDateChange(item.iso, item.text)
      }
      setSelectedEndDate(item.id)
      SegmentService.track('baseOptions-toggle-action', {
        actionType: 'end-date',
      })
    }

    const handleEndDateToggle = () => {
      if (showEndDateSelector) {
        if (onEndDateChange) {
          onEndDateChange(null, null)
        }
      } else {
        const dateMatch = dates.find((month) => month.id === selectedEndDate)
        if (onEndDateChange) {
          onEndDateChange(dateMatch.iso, dateMatch.text)
        }
      }

      setShowEndDateSelector((prev) => !prev)
    }

    return (
      <Container {...rest}>
        <Text variant='h4' marginBottom='1rem'>
          Commuters
        </Text>
        {dropdownItems ? (
          <Dropdown
            items={dropdownItems}
            active={currentSegment.id}
            itemCallback={({ id }) => onSegmentChange(id)}
            width='100%'
          />
        ) : (
          <OfficeContainer>
            {currentSegment.name}{' '}
            <span>
              (
              {currentSegment.commuters -
                (excludeContractors
                  ? currentSegment.contractors.length
                  : 0)}{' '}
              {excludeContractors ? 'Eligible ' : ''}Commuters)
            </span>
          </OfficeContainer>
        )}
        <Divider marginY='1.5rem' />
        <Text variant='h4'>Start Date</Text>
        {startDateDescription && (
          <Text variant='body1' margin='1.5rem 0'>
            {startDateDescription}
          </Text>
        )}
        {isActive ? (
          <Text variant='action4' marginTop='1rem'>
            {startDate}
          </Text>
        ) : (
          <>
            {!isLateStart && (
              <RadioContainer hasDateSelector={dateSelector === 1}>
                <Radio
                  label='Next Month'
                  active={dateSelector === 0}
                  onClick={() => handleDateSelectorChange(0)}
                />
                <Radio
                  label='Schedule for a later date'
                  active={dateSelector === 1}
                  onClick={() => handleDateSelectorChange(1)}
                  marginLeft={'2.5rem'}
                />
              </RadioContainer>
            )}
            {dateSelector === 1 && (
              <Dropdown
                active={selectedDate}
                items={dates}
                label='Select Starting Date'
                itemCallback={handleStartDateChange}
                width='100%'
              />
            )}
          </>
        )}
        {includeEndDate ? (
          canEdit ? (
            <>
              <Divider marginY='1.5rem' />
              <FlexContainer alignItems='center' justifyContent='space-between'>
                <Text variant='h4'>End Date</Text>
                <SwitchToggle
                  active={showEndDateSelector}
                  onClick={handleEndDateToggle}
                />
              </FlexContainer>
              {showEndDateSelector && (
                <DropdownContainer>
                  <Dropdown
                    active={selectedEndDate}
                    items={dates}
                    label='Select End Date'
                    itemCallback={handleEndDateChange}
                    width='100%'
                  />
                </DropdownContainer>
              )}
            </>
          ) : (
            <>
              <Divider marginY='1.5rem' />
              <Text variant='h4'>End Date</Text>
              <Text variant='action4' marginTop='1rem'>
                {endDate}
              </Text>
            </>
          )
        ) : null}
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  WorksiteAndDateConfig.displayName = 'WorksiteAndDateConfig'
}

export default WorksiteAndDateConfig
