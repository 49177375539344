import Text from 'core-system/Text'
import React from 'react'
import styled, { css } from 'styled-components'
import palette from 'core-system/Themes/palette'
import { space, SpaceProps } from 'styled-system'
import moment, { Moment } from 'moment'

const daysOfWeek = ['SUN', 'MON', 'TUES', 'WED', 'THU', 'FRI', 'SAT']

const Container = styled.div`
  width: 100%;

  ${space}
`

const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.625rem;
  margin-bottom: 1rem;
`

const Calendar = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: ${(props) => props.theme.pxToRem(40)};
  gap: 0.5rem 0.625rem;
`

const DayText = styled.div`
  font-size: 0.625rem;
  font-weight: bold;
  color: ${(props) => props.theme.palette.text.placeholder};
`

const TripsText = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  color: ${(props) => props.theme.palette.black};
`

const Day = styled.div<{
  isDisabled: boolean
  isToday: boolean
  isActive: boolean
  isFuture: boolean
  hasTrips: boolean
}>`
  border: 1px solid ${(props) => props.theme.palette.grey.grey200};
  background-color: ${(props) => props.theme.palette.white};
  cursor: pointer;
  border-radius: 0.125rem;

  &:hover {
    border: 1px solid
      ${(props) =>
        props.isActive
          ? props.theme.palette.primary.pink400
          : props.theme.palette.primary.pink300};
    background-color: ${(props) =>
      props.isActive
        ? props.theme.palette.primary.pink400
        : props.theme.palette.primary.pink600};
  }

  &:nth-of-type(7n),
  &:nth-of-type(7n + 1) {
    background-color: ${(props) => props.theme.palette.grey.grey100};
  }

  ${(props) =>
    props.hasTrips &&
    css`
      background-color: ${(props) =>
        props.theme.palette.primary.pink600} !important;
    `};

  ${(props) =>
    props.isDisabled &&
    css`
      background-color: ${(props) => props.theme.palette.grey.grey100};
      pointer-events: none;
    `}

  ${(props) =>
    props.isFuture &&
    css`
      pointer-events: none;
    `}

  ${(props) =>
    props.isToday &&
    css`
      .dayText {
        color: ${(props) => props.theme.palette.primary.pink400};
      }
    `}
  
  ${(props) =>
    props.isActive &&
    css`
      border: 1px solid ${(props) => props.theme.palette.primary.pink400} !important;
      background-color: ${(props) =>
        props.theme.palette.primary.pink400} !important;
      .dayText,
      .tripText {
        color: ${(props) => props.theme.palette.white} !important;
      }
    `}
`

const Content = styled.div`
  border-radius: 0.25rem;
  padding: 0.25rem 0.375rem;
  width: 100%;
  height: 100%;
`

const Trips = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const getDays = (
  firstDayOfMonth: Moment,
  tripsByDay: Dictionary<number>,
  daySelected: number,
  currentMonth: string,
  setActiveTripStats: (dayNumber: number) => void
) => {
  const daysInMonth = firstDayOfMonth.daysInMonth()
  const firstDayNumber = firstDayOfMonth.weekday()

  const days = firstDayNumber + daysInMonth
  const totalDays = days + (days % 7 === 0 ? 0 : 7 - (days % 7))

  return [...Array(totalDays)].map((_day, idx) => {
    const dayNumber = idx - firstDayNumber + 1
    const isDisabled = idx < firstDayNumber || idx + 1 > days
    const recordedTrips = !isDisabled && tripsByDay[dayNumber]

    const day = `${currentMonth}-${
      dayNumber <= 9 ? `0${dayNumber}` : dayNumber
    }`
    const today = moment(moment().format('YYYY-MM-DD'))
    const isToday = !isDisabled && today.isSame(day)
    const isFuture = today.isBefore(day)
    const isActive = daySelected && daySelected === dayNumber

    return (
      <Day
        isDisabled={isDisabled}
        isActive={!isDisabled && isActive}
        isFuture={isFuture}
        isToday={isToday}
        key={idx}
        onClick={() =>
          !isDisabled && !isFuture && setActiveTripStats(dayNumber)
        }
        hasTrips={recordedTrips > 0}
      >
        {!isDisabled && (
          <Content className='dayContent'>
            <DayText className='dayText'>{dayNumber}</DayText>
            {!isFuture && (
              <Trips className='trips'>
                <TripsText className='tripText'>
                  {recordedTrips ? recordedTrips : 0}
                </TripsText>
              </Trips>
            )}
          </Content>
        )}
      </Day>
    )
  })
}

const HeaderItem = ({ text }) => (
  <Text
    variant='caption'
    textColor={palette.text.placeholder}
    textAlign='center'
  >
    {text}
  </Text>
)

interface MyCommuterCalendarProps
  extends SpaceProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  firstDayOfMonth: Moment
  currentMonth: string
  tripsByDay: Dictionary<number>
  daySelected: number
  setActiveTripStats: (dayNumber: number) => void
}

const MyCommuterCalendar = React.memo((props: MyCommuterCalendarProps) => {
  const {
    firstDayOfMonth,
    currentMonth,
    tripsByDay,
    daySelected,
    setActiveTripStats,
    ...rest
  } = props

  return (
    <Container {...rest}>
      <Header>
        {daysOfWeek.map((day) => (
          <HeaderItem text={day} key={day} />
        ))}
      </Header>
      <Calendar>
        {getDays(
          firstDayOfMonth,
          tripsByDay,
          daySelected,
          currentMonth,
          setActiveTripStats
        )}
      </Calendar>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommuterCalendar.displayName = 'MyCommuterCalendar'
}

export default MyCommuterCalendar
