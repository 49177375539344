import React from 'react'
import { styled } from '@mui/material/styles'
import palette from 'core-system/Themes/palette'
import { Button, ButtonProps } from '@mui/material'

const PrimaryButtonContainer = styled(Button)(() => ({
  backgroundColor: palette.primary.pink800,
  color: palette.white,
  borderRadius: '0.375rem',
  padding: '0.625rem 1rem',
  fontFamily: 'PolySans',
  textTransform: 'none',
  fontSize: '0.875rem',
  '&:hover': {
    backgroundColor: palette.primary.pink700,
  },
  '&:active': {
    backgroundColor: palette.primary.pink600,
  },
}))

interface PrimaryButtonProps extends ButtonProps {
  children: React.ReactNode
}

const PrimaryButton = React.memo((props: PrimaryButtonProps) => {
  const { children, ...rest } = props

  return (
    <PrimaryButtonContainer {...rest} variant='contained' color='primary'>
      {children}
    </PrimaryButtonContainer>
  )
})

export default PrimaryButton
