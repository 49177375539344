import React, { useState, useEffect } from 'react'
import Modal, { ModalBody, ModalHeader } from 'core-system/Modal'
import { useDispatch, useSelector } from 'react-redux'
import Text from 'core-system/Text'
import { AppState } from 'redux/config/store'
import Loading from 'core-system/Loading'
import SearchInput from 'core-system/SearchInput'
import Divider from 'core-system/Divider'
import styled from 'styled-components'
import { screenSizes } from 'shared/RandomUtils'
import { finchActions } from '../finchSlice'
import { PayrollProvider } from '../finchTypes'

const MinWidthModalBody = styled(ModalBody)`
  @media (min-width: ${screenSizes.mobile}px) {
    min-width: 30rem;
    text-align: center;
  }
`

const ProviderListContainer = styled.div`
  height: 30rem;
`

const ProviderListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0;
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
`
const ProviderListItemIcon = styled.img`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
`

interface SelectProvidersModalProps {
  open: boolean
  onClose: (e: React.MouseEvent<any>) => void
  selectProvider: (provider: PayrollProvider) => void
}

const ProvidersList = React.memo(
  (props: {
    providers: PayrollProvider[]
    selectProvider: (provider: PayrollProvider) => void
  }) => {
    const { providers, selectProvider } = props
    return (
      <ProviderListContainer>
        {providers.map((provider) => (
          <div key={provider.id}>
            <ProviderListItem onClick={() => selectProvider(provider)}>
              <ProviderListItemIcon
                src={provider.icon}
                alt={provider.displayName}
              />
              <div>{provider.displayName}</div>
            </ProviderListItem>
            <Divider />
          </div>
        ))}
      </ProviderListContainer>
    )
  }
)

ProvidersList.displayName = 'ProvidersList'

const SelectProvidersModal = React.memo((props: SelectProvidersModalProps) => {
  const { open, onClose, selectProvider } = props
  const [searchVal, setSearchVal] = useState('')
  const { payrollProviders, loading, error } = useSelector(
    (state: AppState) => state.finchProviders
  )
  const [providers, setProviders] = useState(payrollProviders)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!payrollProviders.length) {
      dispatch(finchActions.fetchRequest())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setProviders(payrollProviders)
  }, [payrollProviders])

  const handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(evt.target.value)
    setProviders(
      payrollProviders.filter((provider) => {
        const providerName = provider.displayName.toLowerCase()
        const searchTerm = evt.target.value.toLowerCase()
        return providerName.includes(searchTerm)
      })
    )
  }

  const handleSearchClear = () => {
    setSearchVal('')
    setProviders(payrollProviders)
  }

  if (error) {
    return (
      <Modal open={open} onClose={onClose}>
        <ModalHeader title='Select a Provider' />
        <MinWidthModalBody
          paddingBottom='1.5rem'
          paddingTop='1.5rem'
          paddingLeft='2rem'
          paddingRight='2rem'
        >
          <Text variant='action2'>Error fetching providers</Text>
          <Text variant='action2'>Please try again later!</Text>
        </MinWidthModalBody>
      </Modal>
    )
  }

  if (loading) {
    return (
      <Modal open={open} onClose={onClose}>
        <Loading />
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader title='Select a Provider' />
      <MinWidthModalBody
        paddingBottom='1.5rem'
        paddingTop='1.5rem'
        paddingLeft='2rem'
        paddingRight='2rem'
      >
        <SearchInput
          marginBottom='0.75rem'
          barBg='white'
          variant='large'
          placeholder='Search for a provider...'
          value={searchVal}
          onsearch={handleSearch}
          onclearSearch={handleSearchClear}
          onChange={handleSearch}
          flex={1}
        />
        <ProvidersList providers={providers} selectProvider={selectProvider} />
      </MinWidthModalBody>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SelectProvidersModal.displayName = 'SelectProvidersModal'
}

export default SelectProvidersModal
