import React from 'react'
import { makeStyles } from '@mui/styles'
import Badge from '@mui/material/Badge'
import { CircularProgress, Tooltip } from '@mui/material'
import palette from 'core-system/Themes/palette'

const stars = require('./ai_star.png')

const useStyles = makeStyles(() => ({
  mainContainer: {
    position: 'fixed',
    bottom: 32,
    right: 48,
    zIndex: 9995,
    width: 60,
    height: 60,
  },
  innerContainer: {
    width: 60,
    height: 60,
    borderRadius: '34px 8px 34px 34px',
    boxShadow: '0 5px 4px 0 rgb(0 0 0 / 26%)',
    cursor: 'pointer',
    transition: 'all 0.5s linear',
    backgroundColor: palette.primary.pink800,
    '&:hover': {
      boxShadow: '0 5px 4px 0 rgb(0 0 0 / 60%)',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  badge: {
    position: 'inherit',
  },
  starImage: {
    width: 42,
    height: 42,
    transition: 'transform 0.5s ease-in-out',
    '&:hover': {
      animation: '$rotate 0.5s ease-in-out 1',
    },
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}))

interface ConciergeFloatingIconProps {
  onChatIconClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  showChatIcon?: boolean
  isLoading?: boolean
  tooltip?: string
  numberOfUnseenMessages?: number
  anchorEl?: HTMLElement | null
}

const ConciergeFloatingIcon: React.FC<ConciergeFloatingIconProps> = ({
  onChatIconClick = () => {},
  showChatIcon = true,
  isLoading = false,
  tooltip = 'Chat with us',
  numberOfUnseenMessages = 0,
  anchorEl,
}) => {
  const classes = useStyles()

  if (!showChatIcon) {
    return null
  }

  return (
    <Tooltip title={tooltip}>
      <div
        onClick={isLoading ? () => {} : onChatIconClick}
        id='concierge-icon-button'
        className={classes.mainContainer}
        ref={
          anchorEl
            ? (el) => {
                if (el) el.appendChild(anchorEl)
              }
            : undefined
        }
      >
        <div className={classes.innerContainer}>
          <Badge
            badgeContent={numberOfUnseenMessages}
            max={9}
            color='error'
            className={classes.badge}
          />
          {!isLoading && (
            <img src={stars} alt='stars' className={classes.starImage} />
          )}
          {isLoading && (
            <CircularProgress size={24} style={{ color: 'white' }} />
          )}
        </div>
      </div>
    </Tooltip>
  )
}

export default React.memo(ConciergeFloatingIcon)
