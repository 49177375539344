import * as React from 'react'
import createSvgIcon from '../Icons/createSvgIcon'

export default createSvgIcon(
  <>
    <svg
      width='191'
      height='46'
      viewBox='0 0 191 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M83.695 0H93.4875V45.0454H83.695V0ZM190.636 20.5642H181.823V34.0289C181.823 37.089 181.945 37.2114 184.761 37.2114H190.636V45.0454H181.578C175.091 45.0454 172.03 42.9033 172.03 35.1305V20.5642H165.565V12.7302H172.03V3.91698H181.823V12.7302H190.636V20.5642ZM129.395 26.9294C129.395 27.8474 129.334 29.4387 129.211 30.3568H105.771C106.444 34.4574 109.198 37.2115 113.299 37.2115C116.359 37.2115 118.256 35.6814 119.052 33.2945H128.783C127.498 39.4148 122.479 45.0455 113.36 45.0455C102.098 45.0455 96.1006 37.4563 96.1006 27.9086C96.1006 17.8714 102.343 10.7718 113.176 10.7718C121.377 10.7718 129.395 15.7905 129.395 26.9294ZM113.36 18.361C108.708 18.361 106.75 20.9927 106.015 24.4813H119.786C119.419 20.5643 116.971 18.361 113.36 18.361ZM164.571 30.3568C164.693 29.4387 164.755 27.8474 164.755 26.9294C164.755 15.7905 156.737 10.7718 148.536 10.7718C137.703 10.7718 131.46 17.8714 131.46 27.9086C131.46 37.4563 137.458 45.0455 148.719 45.0455C157.839 45.0455 162.857 39.4148 164.143 33.2945H154.411C153.616 35.6814 151.718 37.2115 148.658 37.2115C144.558 37.2115 141.803 34.4574 141.13 30.3568H164.571ZM141.375 24.4813C142.109 20.9927 144.068 18.361 148.719 18.361C152.33 18.361 154.778 20.5643 155.146 24.4813H141.375ZM71.9317 11.0166C71.9317 7.95645 72.0541 7.83404 74.8694 7.83404H80.7449V5.36982e-05H71.6869C65.1994 5.36982e-05 62.1392 2.14216 62.1392 9.91494V12.7303H55.6963V20.5643H62.1392V45.0455H71.9317V20.5643H80.7449V12.7303H71.9317V11.0166Z'
        fill='#0C002F'
      />
      <path
        d='M-0.000181198 44.8638C7.41882 44.8638 13.4331 38.8495 13.4331 31.4305L13.4331 17.5495C6.01411 17.5495 -0.000180267 23.5638 -0.000180591 30.9828L-0.000181198 44.8638Z'
        fill='url(#paint0_linear_1_16633)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.0293 13.6159C36.5686 13.6159 37.0829 13.6145 37.5733 13.6095H42.93C46.6824 13.6095 49.7243 10.5676 49.7243 6.81525C49.7243 6.67731 49.7202 6.54033 49.7121 6.40443H49.7477V0.0210171H42.9319L42.93 0.0210168H36.7685L35.3771 0.0199739C34.3548 0.0108361 33.3314 0.015405 32.3079 0.0199739C31.5192 0.0199739 30.7305 0.0196885 29.9418 0.0194031C29.1531 0.0191176 28.3644 0.0188322 27.5757 0.0188322C26.8482 0.0169278 26.1207 0.0147695 25.3933 0.0126113L25.3933 0.0126112C23.9392 0.0082972 22.4853 0.00398367 21.0319 0.00169964C19.0444 -0.00172705 16.1592 -0.000584553 14.1718 0.0108377H14.167C14.1009 0.0108377 14.0347 0.0108377 13.9685 0.0119791C5.80266 0.117064 0.102801 6.61699 0 14.7828V14.8273C0 14.894 0.000284327 14.961 0.000569265 15.0281L0.000569286 15.0281C0.000855445 15.0955 0.00114221 15.163 0.00114221 15.2305V15.2568C0.0208958 16.3106 0.0138659 17.3459 0.00694504 18.3651C0.00346093 18.8782 4.46609e-06 19.3872 6.71443e-06 19.8925L0.00343336 19.9028C2.11884 15.4469 6.02868 13.6056 11.2966 13.6045H11.2996C12.7721 13.6045 14.2457 13.6045 15.7182 13.6068C16.6628 13.6152 17.6074 13.612 18.5521 13.6087L18.5525 13.6087L18.7885 13.6079C19.5714 13.6085 20.3543 13.6088 21.1371 13.6091H21.1375H21.138L21.1539 13.6091C21.9416 13.6094 22.729 13.6096 23.5162 13.6102L36.0293 13.6159ZM13.4333 17.1498C6.01429 17.1498 0 23.1641 0 30.5831H28.5613C35.9803 30.5831 41.9946 24.5688 41.9946 17.1498H13.4333Z'
        fill='url(#paint1_linear_1_16633)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1_16633'
          x1='6.71646'
          y1='27.9136'
          x2='-1.28356'
          y2='48.7764'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#7F42B3' />
          <stop offset='1' stopColor='#D97590' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1_16633'
          x1='24.8738'
          y1='0'
          x2='24.8738'
          y2='30.5831'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#7F42B3' />
          <stop offset='1' stopColor='#D97590' />
        </linearGradient>
      </defs>
    </svg>
  </>,
  'FleetLogoNew',
  '0 0 191 46'
)
