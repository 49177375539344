import ButtonV2 from 'core-system/ButtonV2'
import CheckboxV2 from 'core-system/CheckboxV2'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import TextFieldV2 from 'core-system/TextFieldV2'
import palette from 'core-system/Themes/palette'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { userActions } from 'redux/user/userSlice'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1.5rem;
`

export const StyledLink = styled(Link)`
  ${(props) => props.theme.typography.action6};
  color: ${(props) => props.theme.palette.primary.pink500};
  text-decoration: underline;
  height: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  text-align: right;
  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
  a {
    color: ${(props) => props.theme.palette.primary.pink500};
  }
`

export const StyledHelperContainer = styled.div`
  display: flex;
  flex-direction: row;
  a {
    color: ${(props) => props.theme.palette.primary.pink500};
    flex: 1;
  }
`

const StatusText = styled.p<{ error?: boolean }>`
  margin-top: 1rem;
  ${(props) => props.theme.typography.caption};
  font-size: ${(props) => (props.error ? '0.75rem' : '1rem')};
  color: ${(props) =>
    props.error
      ? props.theme.palette.error.red500
      : props.theme.palette.accent.teal500};
`

const FieldContainer = styled.div`
  margin-bottom: 1.5rem;
`

const StyledAndroidContainer = styled.a`
  margin-left: ${(props) => props.theme.pxToRem(-13)};
  width: unset !important;

  img {
    width: ${(props) => props.theme.pxToRem(168)};
  }
`

const StyledAppleContainer = styled.a`
  width: unset !important;
`

const StyledAppleImage = styled.img`
  border-radius: ${(props) => props.theme.pxToRem(13)};
  width: ${(props) => props.theme.pxToRem(151)};
  padding: 0.625rem;
`

interface LoginFormProps {
  userErr: any | null
  loginType: any
}

export const LoginForm = React.memo((props: LoginFormProps) => {
  const { loginType } = props

  const dispatch = useDispatch()
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [rememberPass, setRememberPass] = useState<boolean>(true)

  const onSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      dispatch(
        userActions.login({
          email,
          password,
          stayLoggedIn: rememberPass,
          platformType: loginType,
        })
      )

      SegmentService.track('fleet-login-click')
    },
    [dispatch, email, password, rememberPass, loginType]
  )

  const onChange = useCallback((val: string, key: string) => {
    if (key === 'email') {
      setEmail(val)
    }
    if (key === 'pwd') {
      setPassword(val)
    }
  }, [])

  const getSignUpLocation = () => {
    if (loginType === 'employer') {
      return Locations.EmployerSignUp
    } else {
      return Locations.EmployeeSignUp
    }
  }

  return (
    <form>
      <FieldContainer>
        <TextFieldV2
          label='Email*'
          placeholder='Email'
          type='email'
          onChange={(e) => onChange(e.currentTarget.value, 'email')}
        />
      </FieldContainer>
      <FieldContainer>
        <TextFieldV2
          label='Password*'
          placeholder='Password'
          type='password'
          onChange={(e) => onChange(e.currentTarget.value, 'pwd')}
        />
      </FieldContainer>
      <StyledHelperContainer>
        <CheckboxV2
          checked={rememberPass}
          onClick={() => setRememberPass(!rememberPass)}
          marginBottom='2rem'
          label='Remember me'
        />
        <StyledLink
          to={Locations.ForgotPassword}
          onClick={() => SegmentService.track('forgotPassword-link-click')}
        >
          Forgot your password?
        </StyledLink>
      </StyledHelperContainer>
      {props.userErr && props.userErr.status && (
        <StatusText error>
          {props.userErr?.info?.code === 429
            ? `${props.userErr?.info.statusText}. Please try again or contact support@movewithfleet.com`
            : 'Check your email and/or password. Please try again or contact support@movewithfleet.com.'}
        </StatusText>
      )}
      <ButtonsContainer>
        <ButtonV2 size='large' onClick={(e) => onSubmit(e)}>
          Login
        </ButtonV2>
      </ButtonsContainer>
      <Text variant='action6'>
        Don&apos;t have an account?{' '}
        <StyledLink
          to={getSignUpLocation()}
          onClick={() =>
            SegmentService.track('signUp-link-click', {
              platformType: loginType,
            })
          }
        >
          Sign up
        </StyledLink>
      </Text>

      <Text variant='body2' textColor={palette.text.secondary} marginTop='5rem'>
        Are you a commuter? Download our mobile app!
      </Text>
      <FlexContainer>
        <StyledAndroidContainer
          href='https://play.google.com/store/apps/details?id=com.fleet_mobile&hl=en_CA&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            alt='Get it on Google Play'
            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
          />
        </StyledAndroidContainer>
        <StyledAppleContainer
          href='https://apps.apple.com/us/app/move-with-fleet/id1575305413?itsct=apps_box_badge&amp;itscg=30200'
          target='_blank'
          rel='noopener noreferrer'
        >
          <StyledAppleImage
            src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1631232000&h=d5e83c1bb015236d57ef288b4486f8d7'
            alt='Download on the App Store'
          />
        </StyledAppleContainer>
      </FlexContainer>
    </form>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LoginForm.displayName = 'LoginForm'
}
