import React from 'react'
import styled from 'styled-components'
import { buildForwardingComponent } from '../utils/buildComponent'
import CoreInput, { InputProps } from '../Input'
import Show from '../Icons/Actions/Show'
import Hide from '../Icons/Actions/Hide'

const Label = styled.div<{ darkBg: boolean }>`
  ${(props) => props.theme.typography.body1};
  color: ${(props) =>
    props.darkBg
      ? props.theme.palette.white
      : props.theme.palette.text.secondary};
  margin-bottom: 0.25rem;
`
const Input = styled(CoreInput)<{
  invalid?: boolean
  success?: boolean
  isPwd?: boolean
}>`
  ${(props) => {
    if (props.invalid) {
      return `
        border-color: ${props.theme.palette.error.red500};
        background-color: ${props.theme.palette.error.red100};
      `
    } else if (props.success) {
      return `
        border-color: ${props.theme.palette.success.green500};
        background-color: ${props.theme.palette.success.green100};
      `
    } else {
      return `
        border-color: ${props.theme.palette.primary.pink200};
        background-color: ${props.theme.palette.primary.pink100};
      `
    }
  }}

  padding-right: ${(props) => (props.isPwd ? '3rem' : '')};
  border-radius: ${(props) => props.borderRadius};
`

const HelpText = styled.div<{ success?: boolean }>`
  ${(props) => props.theme.typography.body2};
  color: ${(props) =>
    props.success
      ? props.theme.palette.success.green500
      : props.theme.palette.error.red500};
  margin-top: 0.25rem;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: space-between;
  align-items: center;

  svg {
    color: ${(props) => props.theme.palette.text.placeholder};
    cursor: pointer;
    margin-left: -2.375rem;
  }
`
export interface TextFieldProps extends InputProps {
  helpText?: string
  invalid?: boolean
  success?: boolean
  label?: string
  darkBg?: boolean
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (props: TextFieldProps, ref: React.Ref<HTMLInputElement>) => {
    const {
      helpText,
      invalid,
      success,
      label,
      darkBg = false,
      ...inputProps
    } = props
    const isPwdField = inputProps.type === 'password'
    const [showPwd, setShowPwd] = React.useState<boolean>(
      isPwdField ? false : true
    )

    const onTogglePwd = React.useCallback(() => {
      setShowPwd(!showPwd)
    }, [showPwd])

    if (isPwdField) {
      return (
        <div>
          {label && label.length > 0 && <Label darkBg={darkBg}>{label}</Label>}
          <InputContainer>
            <Input
              {...inputProps}
              invalid={invalid}
              isPwd={true}
              type={showPwd ? 'text' : inputProps.type}
              ref={ref}
            />
            {showPwd ? (
              <Hide onClick={onTogglePwd} />
            ) : (
              <Show onClick={onTogglePwd} />
            )}
          </InputContainer>
          {invalid && helpText && <HelpText>{helpText}</HelpText>}
        </div>
      )
    }

    return (
      <div>
        {label && label.length > 0 && <Label darkBg={darkBg}>{label}</Label>}
        <Input {...inputProps} invalid={invalid} success={success} ref={ref} />
        {helpText && (invalid || success) && (
          <HelpText success={success}>{helpText}</HelpText>
        )}
      </div>
    )
  }
)

TextField.displayName = 'TextField'
export default buildForwardingComponent<HTMLInputElement, TextFieldProps>(
  TextField
)
