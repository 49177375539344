import EmployeeIcon from 'core-system/Icons/Misc/Employees'
import MapUtils from 'core-system/Map/MapUtils'
import { Feature, FeatureCollection, Point, Position } from 'geojson'
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import ReactMapGL, {
  AttributionControl,
  Layer,
  MapMouseEvent,
  MapRef,
  NavigationControl,
  Popup,
  Source,
  ViewState,
} from 'react-map-gl/mapbox'
import { useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import {
  MapIntelligenceStats,
  MapLayers,
} from 'redux/mapIntelligence/mapIntelligenceTypes'
import usePrevious from 'shared/Hooks/usePrevious'
import useResizeListener from 'shared/Hooks/useResizeListener'
import { mapboxConfig } from 'shared/MapboxConfig'
import styled from 'styled-components'
import MapIntelligenceMarker from './MapIntelligenceMarker'
import {
  agonyOutlineStyle,
  agonyStyle,
  defaultViewport,
  generateCurrentCommuteModesStyle,
  mapIntelligenceEmployeeStyles,
  metricLayerNames,
  micromobilityStyle,
} from './MapIntelligenceStyles'
import PopupAgony from './Popup/PopupAgony'
import PopupEmployees from './Popup/PopupEmployees'
import PopupMicromobility from './Popup/PopupMicromobility'
import PopupWorksites from './Popup/PopupWorksites'

const popupLayers = {
  employees: PopupEmployees,
  agony: PopupAgony,
  worksites: PopupWorksites,
  currentCommuteModes: PopupEmployees,
  micromobility: PopupMicromobility,
}

const bboxMap = {
  covidData: 'covidData',
  agony: 'employees',
  currentCommuteModes: 'employees',
  micromobility: 'micromobility',
}

const validGeoJSONPopupLayers = [
  'employees',
  'covidData',
  'agony',
  'worksites',
  'currentCommuteModes',
  'micromobility',
]

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;

  .mapboxgl-ctrl-bottom-right {
    display: flex;
    align-items: center;
    height: 2.5rem;
  }

  .mapboxgl-ctrl-attrib-button:focus {
    box-shadow: none;
  }
`

const NavContainer = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 0.125rem;
  padding: 0.625rem;

  div {
    position: relative !important;
  }

  & > .mapboxgl-ctrl {
    box-shadow: ${(props) => props.theme.dropShadows.normal};
  }
`

const EmployeeZoom = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: ${(props) => props.theme.pxToRem(136)};
  right: 0.75rem;
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.text.primary};
  width: ${(props) => props.theme.pxToRem(29)};
  height: ${(props) => props.theme.pxToRem(29)};
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const StyledPopup = styled(Popup)`
  z-index: ${(props) => props.theme.zIndex.tooltip};
  cursor: pointer;

  & > .mapboxgl-popup-content {
    border-radius: 0.9375rem;
    box-shadow: ${(props) => props.theme.dropShadows.normal};
    padding: 0.625rem;

    & > .mapboxgl-popup-close-button {
      top: 0.4375rem;
      right: 0.5rem;
      color: ${(props) => props.theme.palette.text.primary};
      font-size: 1.75rem;
      font-family: PolySans;
      line-height: 2rem;

      &:hover {
        background-color: transparent;
      }

      &:focus {
        outline: none;
      }
    }
  }
`

const getLayerStyles = (
  activeLayer: string,
  layerName: string,
  statistics: MapIntelligenceStats
) => {
  const layerStyles = []
  if (layerName === 'agony') {
    layerStyles.push(<Layer key={'agonyStyle'} {...agonyStyle} />)
    layerStyles.push(<Layer key={'agonyOutlineStyle'} {...agonyOutlineStyle} />)
  } else if (layerName === 'micromobility') {
    layerStyles.push(<Layer key={'micromobility'} {...micromobilityStyle} />)
  } else if (layerName === 'employees') {
    if (activeLayer === 'currentCommuteModes') {
      const currentCommuteModesStyle = generateCurrentCommuteModesStyle(
        statistics.modes
      )
      layerStyles.push(
        <Layer key={'currentCommuteModesStyle'} {...currentCommuteModesStyle} />
      )
    } else {
      const layerStyle = mapIntelligenceEmployeeStyles[activeLayer]
      layerStyles.push(<Layer key={'employeeStyle'} {...layerStyle} />)
    }
  }

  return layerStyles
}

const renderLayers = (
  mapLayers: MapLayers,
  activeLayer: string,
  statistics: MapIntelligenceStats
) => {
  const activeLayers = []
  Object.keys(mapLayers).forEach((layerName: string, idx: number) => {
    if (layerName === activeLayer || layerName === 'employees') {
      const layerData = mapLayers[layerName]
      if (Object.keys(layerData).length !== 0) {
        activeLayers.push(
          <Source type={'geojson'} id={layerName} data={layerData} key={idx}>
            {getLayerStyles(activeLayer, layerName, statistics)}
          </Source>
        )
      }
    }
  })
  return activeLayers
}

const createPopupPoint = (
  properties: any,
  location: Position,
  featureId: string
) => {
  return {
    type: 'Feature',
    properties: {
      ...properties,
      featureId,
    },
    geometry: {
      type: 'Point',
      coordinates: location,
    },
  }
}

interface MapIntelligenceProps {
  mapLayers: MapLayers
  activeLayer: string
  statistics: MapIntelligenceStats
}

const MapIntelligence = React.memo((props: MapIntelligenceProps) => {
  const { mapLayers, activeLayer, statistics } = props

  const { worksites } = useSelector((state: AppState) => state.employer)

  const [cursor, setCursor] = useState('auto')
  const [interactiveLayerIds, setInteractiveLayerIds] = useState(['employees'])
  const [popupInfo, setPopupInfo] = useState(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 578)
  const [viewport, setViewport] = useState<ViewState>({
    ...defaultViewport,
    latitude: 38.7577,
    longitude: -103.4376,
    zoom: 4,
    bearing: null,
    pitch: null,
    padding: null,
  })

  useResizeListener(() => setIsMobile(window.innerWidth <= 578), [])
  const mapRef = useRef() as MutableRefObject<MapRef>
  const prevActiveLayer = usePrevious(activeLayer) as string

  const onMarkerClick = useCallback(
    (feature: Feature) => {
      feature.properties = {
        ...feature.properties,
        featureId: 'worksites',
      }

      SegmentService.track('mapIntel-tooltip-click', {
        itemType: metricLayerNames[feature.properties.featureId],
      })

      setPopupInfo(feature)
    },
    [setPopupInfo]
  )

  const onMapClick = useCallback(
    (pointerEvent: MapMouseEvent) => {
      const featureId =
        pointerEvent.features.length > 0 && pointerEvent.features[0].layer.id
      const featureProperties = featureId && {
        ...pointerEvent.features[0].properties,
        id: pointerEvent.features[0].id && pointerEvent.features[0].id,
      }
      const featureLocation = featureId && pointerEvent.lngLat

      if (validGeoJSONPopupLayers.includes(featureId)) {
        SegmentService.track('mapIntel-tooltip-click', {
          itemType: metricLayerNames[featureId],
        })

        setPopupInfo(
          createPopupPoint(
            featureProperties,
            [featureLocation.lng, featureLocation.lat],
            featureId
          )
        )
      }
    },
    [setPopupInfo]
  )

  const renderPopup = useCallback(() => {
    const point = popupInfo && (popupInfo.geometry as Point)
    const PopupLayer = popupInfo && popupLayers[popupInfo.properties.featureId]
    return (
      popupInfo && (
        <StyledPopup
          anchor='top'
          longitude={point.coordinates[0]}
          latitude={point.coordinates[1]}
          closeOnClick={false}
          closeOnMove={true}
          onClose={() => setPopupInfo(null)}
        >
          <PopupLayer feature={popupInfo} allWorksites={worksites} />
        </StyledPopup>
      )
    )
  }, [popupInfo, worksites])

  const updateMapBbox = useCallback(
    (featureCollection: FeatureCollection) => {
      if (featureCollection?.features?.length > 0) {
        const [minLng, minLat, maxLng, maxLat] = MapUtils.getBbox({
          featureCollection,
        })
        mapRef.current?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          { padding: 50 }
        )
      }
    },
    [mapRef]
  )

  const onLoad = useCallback(() => {
    updateMapBbox(mapLayers[bboxMap[activeLayer]])
  }, [activeLayer, mapLayers, updateMapBbox])

  useEffect(() => {
    if (interactiveLayerIds.includes(activeLayer) === false) {
      setInteractiveLayerIds(
        activeLayer === 'currentCommuteModes' || activeLayer === 'micromobility'
          ? [activeLayer]
          : ['employees', activeLayer]
      )
      setPopupInfo(null)
      if (prevActiveLayer && prevActiveLayer !== activeLayer) {
        updateMapBbox(mapLayers[bboxMap[activeLayer]])
      }
    }
  }, [
    activeLayer,
    interactiveLayerIds,
    prevActiveLayer,
    mapLayers,
    updateMapBbox,
  ])

  const onMouseEnter = useCallback(() => setCursor('pointer'), [])
  const onMouseLeave = useCallback(() => setCursor('auto'), [])

  return (
    <Container>
      <ReactMapGL
        {...viewport}
        style={{
          height: '100%',
          width: '100%',
        }}
        mapStyle={mapboxConfig.baseMapPurple}
        ref={mapRef}
        onMove={(nextViewport) => setViewport(nextViewport.viewState)}
        mapboxAccessToken={mapboxConfig.apiKey}
        onClick={(pointerEvent) => onMapClick(pointerEvent)}
        onLoad={onLoad}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        cursor={cursor}
        interactiveLayerIds={interactiveLayerIds}
        attributionControl={false}
      >
        {renderPopup()}
        {renderLayers(mapLayers, activeLayer, statistics)}
        <MapIntelligenceMarker
          features={mapLayers.worksites}
          markerClick={onMarkerClick}
          layer={'worksites'}
        />
        <AttributionControl compact />
        <NavContainer>
          <NavigationControl />
        </NavContainer>
      </ReactMapGL>
      {/* <MapIntelligenceLegend activeLayer={activeLayer} mapData={mapData} /> */}
      <EmployeeZoom
        isVisible={!isMobile}
        onClick={() => updateMapBbox(mapLayers[bboxMap[activeLayer]])}
      >
        <EmployeeIcon width={24} height={24} />
      </EmployeeZoom>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MapIntelligence.displayName = 'Map Intelligence Map'
}

export default MapIntelligence
