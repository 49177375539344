import { incentivesActions } from 'redux/incentives/incentivesSlice'
import Button from 'core-system/Button'
import ColumnView, { Column } from 'core-system/ColumnView'
import { SelectItemProps } from 'core-system/Dropdown'
import Loading from 'core-system/Loading'
import { ProgramModal } from 'core-system/Program'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import {
  IncentiveManageState,
  IncentiveProgram,
  IncentivesManageState,
  ModeType,
} from 'redux/incentives/incentivesTypes'
import useQueryParam from 'shared/Hooks/useQueryParam'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import ActivateIncentiveRewards from '../Activation/components/ActivateIncentiveRewards'
import ActivateIncentiveSpending from '../Activation/components/ActivateIncentiveSpending'
import ActivateIncentiveSummary from '../Activation/components/ActivateIncentiveSummary'
import IncentivesUtils from '../Shared/IncentivesUtils'
import IncentivesManagePending from './IncentivesManagePending'

export const activeStatuses = [
  'PENDING',
  'UPDATING',
  'ACTIVE',
  'CANCELLING',
  'CANCELLED',
]

export interface IncentiveDate {
  val: string
  text: string
}

export interface RewardsCallbackItem {
  type: string
  modeType: ModeType
  idxRow: number
  max?: number
}

const getEmptyModeType = () => {
  return {
    perTrip: null,
    id: null,
    text: null,
  }
}

const StyledColumnView = styled(ColumnView)`
  margin-bottom: 10rem;
  grid-template-columns: 64.5% 34.5%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
    max-width: ${(props) => props.theme.pxToRem(700)};
    grid-gap: unset;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem ${(props) => props.theme.pxToRem(52)};
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 5rem);
  box-shadow: ${(props) => props.theme.dropShadows.bottom};
  z-index: ${(props) => props.theme.zIndex.max};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`

const incentiveNonModeKeys = [
  'spendType',
  'startDate',
  'status',
  'updatedAt',
  'createdAt',
  'worksiteId',
  'id',
  'maxVal',
  'segment',
  'rewarded',
  'bikeMaxVal',
  'bikeshareMaxVal',
  'carMaxVal',
  'carpoolMaxVal',
  'mopedMaxVal',
  'mopedshareMaxVal',
  'rideshareMaxVal',
  'scooterMaxVal',
  'scootershareMaxVal',
  'shuttleMaxVal',
  'transitMaxVal',
  'vanpoolMaxVal',
  'walkMaxVal',
  'endDate',
  'parentProduct',
  'familyId',
]

const getActiveModes = (currentIncentives: IncentiveProgram) => {
  return Object.keys(currentIncentives).reduce((modes, key) => {
    if (!incentiveNonModeKeys.includes(key) && currentIncentives[key] !== 0) {
      const maxValField = key.replace('Val', 'MaxVal')
      const m = {
        id: key,
        text: key.split('Val')[0],
        // These values will be stored in dollars for display purposes, converted back to cents on update
        perTrip: parseFloat(currentIncentives[key]) / 100,
        max: currentIncentives[maxValField]
          ? parseFloat(currentIncentives[maxValField]) / 100
          : null,
      }
      modes.push(m)
    }
    return modes
  }, [])
}

const formatUpdatedPrograms = (
  currentIncentives: IncentiveProgram,
  modeTypes: ModeType[],
  modesToDelete: ModeType[]
): IncentiveProgram => {
  const updatedModes = modeTypes.reduce((acc, mode) => {
    const formattedMaxValName = mode.id.replace('Val', 'MaxVal')

    return {
      ...acc,
      [mode.id]: (mode.perTrip * 100).toString(),
      [formattedMaxValName]: mode.max ? (mode.max * 100).toString() : null,
    }
  }, {})
  const turnOffModes =
    modesToDelete.length === 0
      ? {}
      : modesToDelete.reduce((acc, mode) => {
          if (!mode.id) return acc
          const formattedMaxValName = mode.id.replace('Val', 'MaxVal')

          return {
            ...acc,
            [mode.id]: '0',
            [formattedMaxValName]: null,
          }
        }, {})

  return {
    ...currentIncentives,
    ...updatedModes,
    ...turnOffModes,
    maxVal: currentIncentives.maxVal.toString(),
    requestUndoCancel: currentIncentives.status === 'CANCELLING',
  }
}

const IncentivesManageView = React.memo(() => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const segmentId = useQueryParam('segmentId')

  const { segmentsMap } = useSelector((state: AppState) => state.employer)
  const { allIncentives, incentivesLoaded, incentivesUpdated } = useSelector(
    (state: AppState) => state.incentives
  )

  const activeIncentives = allIncentives[segmentId]

  const [modesToDelete, setModesToDelete] = useState([])
  const [modeTypeErrors, setModeTypeErrors] = useState([])
  const [budgetError, setBudgetError] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  //used to see changes when there is only a next month program
  const [pendingIncentives, setPendingIncentives] =
    useState<IncentiveManageState>(null)
  const [incentivesState, setIncentivesState] = useState<IncentivesManageState>(
    {
      active: null,
      nextMonth: null,
    }
  )
  const [activeState, setActiveState] = useState<'active' | 'nextMonth'>(
    'active'
  )

  useEffect(() => {
    if (incentivesUpdated && incentivesLoaded) {
      dispatch(incentivesActions.toggleIncentivesUpdated())
      if (!incentivesState.active) {
        navigate(`/trips/${segmentId}`)
      } else {
        setIsEdit(false)
        setActiveState('active')
        setIsUpdateModalOpen(false)
        setModeTypeErrors([])
      }
    }
  }, [
    incentivesUpdated,
    dispatch,
    navigate,
    incentivesState,
    incentivesLoaded,
    segmentId,
  ])

  useEffect(() => {
    if (incentivesLoaded && activeIncentives) {
      if (!activeIncentives.active && activeIncentives.nextMonth) {
        setIncentivesState((prevState) => {
          return {
            ...prevState,
            nextMonth: {
              ...activeIncentives.nextMonth,
              activeModes: getActiveModes(activeIncentives.nextMonth),
            },
          }
        })
        setPendingIncentives({
          ...activeIncentives.nextMonth,
          activeModes: getActiveModes(activeIncentives.nextMonth),
        })
        setActiveState('nextMonth')
        setIsEdit(true)
      } else if (activeIncentives.active) {
        const activeState = {
          ...activeIncentives.active,
          activeModes: getActiveModes(activeIncentives.active),
        }
        setIncentivesState({
          active: activeState,
          nextMonth: activeIncentives.nextMonth
            ? {
                ...activeIncentives.nextMonth,
                activeModes: getActiveModes(activeIncentives.nextMonth),
              }
            : {
                ...activeState,
                startDate: moment()
                  .add(1, 'M')
                  .startOf('month')
                  .format('YYYY-MM-DDTHH:mm:ss'),
              },
        })
      }
    }
  }, [incentivesLoaded, activeIncentives])

  if (!activeIncentives) {
    return <Navigate to={Locations.Incentives.Programs} replace />
  }

  const handleModeTypeChange = (item: SelectItemProps) => {
    const updatedModeTypes = incentivesState[activeState].activeModes.map(
      (modeType, idx) =>
        modeType.id === item.currentModeType.id && idx === item.idxRow
          ? { ...modeType, id: item.id, text: item.text }
          : modeType
    )
    const wasActive = Object.keys(incentivesState[activeState]).some(
      (k) => k === item.id
    )

    if (wasActive) {
      const modeToDelete = incentivesState[activeState].activeModes[item.idxRow]
      setModesToDelete((prevState) => [
        ...prevState,
        {
          ...modeToDelete,
          perTrip: 0,
        },
      ])
      if (incentivesState[activeState].activeModes[item.idxRow].text) {
        SegmentService.track('mode-manage-action', {
          action: 'remove',
          mode: modeToDelete.text,
        })
      }
    }

    setIncentivesState((prevState) => {
      return {
        ...prevState,
        [activeState]: {
          ...prevState[activeState],
          activeModes: updatedModeTypes,
        },
      }
    })
    SegmentService.track('mode-manage-action', {
      action: 'add',
      mode: item.text,
    })
  }

  const handleModeStatChange = (
    value: number,
    callbackItem: RewardsCallbackItem
  ) => {
    const updatedModeTypes = incentivesState[activeState].activeModes.map(
      (modeType, idx) => {
        return modeType.id === callbackItem.modeType.id &&
          idx === callbackItem.idxRow
          ? { ...modeType, [callbackItem.type]: value }
          : modeType
      }
    )

    setIncentivesState((prevState) => {
      return {
        ...prevState,
        [activeState]: {
          ...prevState[activeState],
          activeModes: updatedModeTypes,
        },
      }
    })
  }

  const handleModeTypeDelete = (idx: number) => {
    const modeTypeToDelete = incentivesState[activeState].activeModes[idx]
    if (modeTypeToDelete.text) {
      SegmentService.track('mode-manage-action', {
        action: 'remove',
        mode: modeTypeToDelete.text,
      })
    }

    const modeTypesCopy = [...incentivesState[activeState].activeModes]
    const wasActive =
      parseFloat(incentivesState[activeState][modeTypesCopy[idx].id]) > 0

    if (incentivesState[activeState].activeModes.length > 1) {
      modeTypesCopy.splice(idx, 1)
    }

    if (wasActive) {
      setModesToDelete((prevState) => [
        ...prevState,
        { ...modeTypeToDelete, perTrip: 0 },
      ])
    }

    setIncentivesState((prevState) => {
      return {
        ...prevState,
        [activeState]: {
          ...prevState[activeState],
          activeModes:
            incentivesState[activeState].activeModes.length > 1
              ? modeTypesCopy
              : [],
        },
      }
    })
  }

  const handleModeTypeAdd = (addedModeTypes?: string[]) => {
    if (incentivesState[activeState].activeModes.length < 13) {
      if (!addedModeTypes) {
        setIncentivesState((prevState) => {
          return {
            ...prevState,
            [activeState]: {
              ...prevState[activeState],
              activeModes: [
                ...incentivesState[activeState].activeModes,
                getEmptyModeType(),
              ],
            },
          }
        })
      } else {
        const currentModeTypes = incentivesState[activeState].activeModes.map(
          (modeType) => modeType.id
        )
        const modesToAdd = addedModeTypes.reduce((acc, modeKey) => {
          if (!currentModeTypes.includes(modeKey)) {
            const newMode = {
              id: modeKey,
              text: modeKey.split('Val')[0],
              perTrip: null,
            }
            acc.push(newMode)
          }
          return acc
        }, [])

        const updatedModeTypes = incentivesState[
          activeState
        ].activeModes.filter((m) => m.id !== null)

        setIncentivesState((prevState) => {
          return {
            ...prevState,
            [activeState]: {
              ...prevState[activeState],
              activeModes: [...updatedModeTypes, ...modesToAdd],
            },
          }
        })
      }
    }
  }

  const handleUpdateIncentives = () => {
    const modeTypesWithErrors = incentivesState[activeState].activeModes.reduce(
      (errorModes, mode) => {
        if (
          !mode.id ||
          !mode.perTrip ||
          mode.max > incentivesState[activeState].maxVal ||
          (mode.max && mode.max < mode.perTrip)
        )
          errorModes.push(mode.id)
        return errorModes
      },
      []
    )
    const budgetError = !incentivesState[activeState].maxVal

    if (modeTypesWithErrors.length > 0 || budgetError) {
      setModeTypeErrors([...modeTypesWithErrors])
      if (budgetError) {
        setBudgetError(true)
      }
    } else {
      const updatedIncentiveProgram = formatUpdatedPrograms(
        incentivesState[activeState],
        incentivesState[activeState].activeModes,
        modesToDelete
      )
      dispatch(incentivesActions.updateIncentives(updatedIncentiveProgram))
      SegmentService.track('incentive-action-click', {
        action: 'update',
        segmentName: segmentsMap[updatedIncentiveProgram.segment].name,
        startDate: moment(updatedIncentiveProgram.startDate).format(
          'DD-MM-YYYY'
        ),
        budget: parseFloat(updatedIncentiveProgram.maxVal as string),
        walk: parseFloat(updatedIncentiveProgram.walkVal),
        bike: parseFloat(updatedIncentiveProgram.bikeVal),
        scooter: parseFloat(updatedIncentiveProgram.scooterVal),
        moped: parseFloat(updatedIncentiveProgram.mopedVal),
        car: parseFloat(updatedIncentiveProgram.carVal),
        rideshare: parseFloat(updatedIncentiveProgram.rideshareVal),
        carpool: parseFloat(updatedIncentiveProgram.carpoolVal),
        vanpool: parseFloat(updatedIncentiveProgram.vanpoolVal),
        shuttle: parseFloat(updatedIncentiveProgram.shuttleVal),
        bikeshare: parseFloat(updatedIncentiveProgram.bikeshareVal),
        scootershare: parseFloat(updatedIncentiveProgram.scootershareVal),
        mopedshare: parseFloat(updatedIncentiveProgram.mopedshareVal),
        transit: parseFloat(updatedIncentiveProgram.transitVal),
        bikeMaxVal: parseFloat(updatedIncentiveProgram.bikeMaxVal) || null,
        bikeshareMaxVal:
          parseFloat(updatedIncentiveProgram.bikeshareMaxVal) || null,
        carMaxVal: parseFloat(updatedIncentiveProgram.carMaxVal) || null,
        carpoolMaxVal:
          parseFloat(updatedIncentiveProgram.carpoolMaxVal) || null,
        mopedMaxVal: parseFloat(updatedIncentiveProgram.mopedMaxVal) || null,
        mopedshareMaxVal:
          parseFloat(updatedIncentiveProgram.mopedshareMaxVal) || null,
        rideshareMaxVal:
          parseFloat(updatedIncentiveProgram.rideshareMaxVal) || null,
        scooterMaxVal:
          parseFloat(updatedIncentiveProgram.scooterMaxVal) || null,
        scootershareMaxVal:
          parseFloat(updatedIncentiveProgram.scootershareMaxVal) || null,
        shuttleMaxVal:
          parseFloat(updatedIncentiveProgram.shuttleMaxVal) || null,
        transitMaxVal:
          parseFloat(updatedIncentiveProgram.transitMaxVal) || null,
        vanpoolMaxVal:
          parseFloat(updatedIncentiveProgram.vanpoolMaxVal) || null,
        walkMaxVal: parseFloat(updatedIncentiveProgram.walkMaxVal) || null,
      })
    }
  }

  const handlePauseIncentives = () => {
    dispatch(
      incentivesActions.pauseIncentives({
        id:
          activeIncentives.active && activeIncentives.nextMonth
            ? incentivesState.active.id
            : incentivesState[activeState].id,
        worksiteId: incentivesState[activeState].worksiteId,
      })
    )
    const activeStateAnalytics = activeIncentives.nextMonth
      ? 'nextMonth'
      : 'active'
    SegmentService.track('incentive-action-click', {
      action: 'cancel',
      segmentName:
        segmentsMap[activeIncentives[activeStateAnalytics].segment].name,
      startDate: moment(activeIncentives[activeStateAnalytics].startDate)
        .add(1, 'month')
        .startOf('month')
        .format('DD-MM-YYYY'),
      budget: parseFloat(
        activeIncentives[activeStateAnalytics].maxVal as string
      ),
      walk: parseFloat(activeIncentives[activeStateAnalytics].walkVal),
      bike: parseFloat(activeIncentives[activeStateAnalytics].bikeVal),
      scooter: parseFloat(activeIncentives[activeStateAnalytics].scooterVal),
      moped: parseFloat(activeIncentives[activeStateAnalytics].mopedVal),
      car: parseFloat(activeIncentives[activeStateAnalytics].carVal),
      rideshare: parseFloat(
        activeIncentives[activeStateAnalytics].rideshareVal
      ),
      carpool: parseFloat(activeIncentives[activeStateAnalytics].carpoolVal),
      vanpool: parseFloat(activeIncentives[activeStateAnalytics].vanpoolVal),
      shuttle: parseFloat(activeIncentives[activeStateAnalytics].shuttleVal),
      bikeshare: parseFloat(
        activeIncentives[activeStateAnalytics].bikeshareVal
      ),
      scootershare: parseFloat(
        activeIncentives[activeStateAnalytics].scootershareVal
      ),
      mopedshare: parseFloat(
        activeIncentives[activeStateAnalytics].mopedshareVal
      ),
      transit: parseFloat(activeIncentives[activeStateAnalytics].transitVal),
      bikeMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].bikeMaxVal) || null,
      bikeshareMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].bikeshareMaxVal) ||
        null,
      carMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].carMaxVal) || null,
      carpoolMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].carpoolMaxVal) ||
        null,
      mopedMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].mopedMaxVal) || null,
      mopedshareMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].mopedshareMaxVal) ||
        null,
      rideshareMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].rideshareMaxVal) ||
        null,
      scooterMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].scooterMaxVal) ||
        null,
      scootershareMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].scootershareMaxVal) ||
        null,
      shuttleMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].shuttleMaxVal) ||
        null,
      transitMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].transitMaxVal) ||
        null,
      vanpoolMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].vanpoolMaxVal) ||
        null,
      walkMaxVal:
        parseFloat(activeIncentives[activeStateAnalytics].walkMaxVal) || null,
    })
    setIsModalOpen(false)
  }

  const handleReactivateIncentives = () => {
    const hasChanges = IncentivesUtils.incentivesHasChanges(
      incentivesState.active
        ? incentivesState
        : { ...incentivesState, active: pendingIncentives }
    )

    if (!incentivesState.active || hasChanges) {
      handleUpdateIncentives()
    } else {
      dispatch(
        incentivesActions.pauseIncentives({
          id: activeIncentives[activeState].id,
          worksiteId: incentivesState[activeState].worksiteId,
        })
      )
      setIsUpdateModalOpen(false)
    }
  }

  const toggleEdit = () => {
    setIsEdit((prevState) => !prevState)
    setActiveState((prevState) => {
      return prevState === 'active' ? 'nextMonth' : 'active'
    })
  }

  const toggleCancelEdit = () => {
    setIsEdit(false)
    setIncentivesState({
      active: activeIncentives.active
        ? {
            ...activeIncentives.active,
            activeModes: getActiveModes(activeIncentives.active),
          }
        : null,
      nextMonth: activeIncentives.nextMonth
        ? {
            ...activeIncentives.nextMonth,
            activeModes: getActiveModes(activeIncentives.nextMonth),
          }
        : null,
    })
  }

  if (!incentivesState.active && !incentivesState.nextMonth) {
    return <Loading fullPage={true} />
  }

  return (
    <>
      <StyledColumnView>
        <Column>
          <ActivateIncentiveRewards
            modeTypes={incentivesState[activeState].activeModes}
            modeTypeErrors={modeTypeErrors}
            budgetError={budgetError}
            monthlyMax={incentivesState[activeState].maxVal as number}
            setMonthlyMax={(value) => {
              setIncentivesState((prevState) => {
                return {
                  ...prevState,
                  [activeState]: {
                    ...prevState[activeState],
                    maxVal: value * 100,
                  },
                }
              })
            }}
            handleModeTypeChange={handleModeTypeChange}
            handleModeStatChange={handleModeStatChange}
            handleModeTypeDelete={handleModeTypeDelete}
            handleModeTypeAdd={handleModeTypeAdd}
            canEdit={isEdit}
          />
          <ActivateIncentiveSpending />
        </Column>
        <Column>
          <ActivateIncentiveSummary
            modeTypes={incentivesState[activeState].activeModes}
            monthlyMax={incentivesState[activeState].maxVal as number}
            startDate={incentivesState[activeState].startDate}
            segment={segmentsMap[segmentId]}
            toggleEdit={toggleEdit}
            canEdit={isEdit}
          />
          <IncentivesManagePending
            isPending={!incentivesState.active && true}
            incentivesState={
              incentivesState.active
                ? incentivesState
                : { ...incentivesState, active: pendingIncentives }
            }
            canEdit={isEdit}
            openUpdateModal={() => setIsUpdateModalOpen(true)}
          />
        </Column>
      </StyledColumnView>
      {isEdit && (
        <Footer>
          {incentivesState.nextMonth.status !== 'CANCELLING' && (
            <Button
              variant='cancel'
              onClick={() => {
                setIsModalOpen(true)
                SegmentService.track('incentive-manage-action', {
                  action: 'cancel',
                  segmentName: segmentsMap[segmentId].name,
                })
              }}
            >
              Cancel Incentives
            </Button>
          )}
          <ButtonContainer>
            <Button
              variant='tertiary'
              marginRight='1rem'
              onClick={() => {
                if (incentivesState.active) {
                  toggleCancelEdit()
                } else {
                  navigate(`/trips/${segmentId}`)
                }
                SegmentService.track('incentive-manage-action', {
                  action: 'close',
                  segmentName: segmentsMap[segmentId].name,
                })
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                incentivesState.nextMonth.status === 'CANCELLING'
                  ? setIsUpdateModalOpen(true)
                  : handleUpdateIncentives()
              }
            >
              Update Incentives
            </Button>
          </ButtonContainer>
        </Footer>
      )}
      <ProgramModal
        title='Cancel Incentives'
        description='Are you sure you want to cancel these incentives? Your commuters will lose
        all access to these rewards.'
        buttonText='Cancel Incentives'
        open={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        buttonCallback={handlePauseIncentives}
      />
      <ProgramModal
        title='Reactivate Incentives'
        description='Do you want to reactive these incentives? All previous benefits will be available again next month.'
        buttonText='Reactivate Incentives'
        open={isUpdateModalOpen}
        closeModal={() => setIsUpdateModalOpen(false)}
        buttonCallback={handleReactivateIncentives}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  IncentivesManageView.displayName = 'IncentivesManageView'
}

export default IncentivesManageView
