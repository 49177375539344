import React, { useState } from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import {
  FINCH_CLIENT_ID,
  FINCH_SANDBOX_SETTING,
  StaticAssetsUrl,
} from 'redux/config/services/Constants'
import { OnboardingStep } from './GettingStartedView'
import { useDispatch, useSelector } from 'react-redux'
import { employerActions } from 'redux/employer/employerSlice'
import employerService from 'redux/employer/employerService'
import SegmentService from 'redux/config/services/SegmentService'
import { useFinchConnect } from '@tryfinch/react-connect'
import palette from 'core-system/Themes/palette'
import ButtonV2 from 'core-system/ButtonV2'
import { useGateValue } from '@statsig/react-bindings'
import { AppState } from 'redux/config/store'
import SelectProvidersModal from 'features/Finch/components/SelectProviders'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  gap: ${pxToRem(40)};
  .numbered-bullet {
    color: ${(props) => props.theme.palette.white};
    font-size: ${pxToRem(20)};
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
`

const Step = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`

const NumberedBullet = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.primary.pink800};
  margin-right: 0.5rem;
  padding-top: ${pxToRem(2)};
`

const OrSeparator = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem;
  font-size: ${pxToRem(32)};
  color: ${(props) => props.theme.palette.grey.grey700};
  font-weight: ${(props) => props.theme.typography.action5};
  &:before,
  &:after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey700};
    margin: 0 1rem;
  }
`

interface OnboardingUploadCsvStepsProps {
  setLoading: (loading: boolean) => void
  setOnboardingStep: (step: OnboardingStep) => void
}

const OnboardingUploadCsvSteps = React.memo(
  (props: OnboardingUploadCsvStepsProps) => {
    const { setLoading, setOnboardingStep } = props
    const [showSelectProvidersModal, setShowSelectProvidersModal] =
      useState(false)
    const { email } = useSelector((state: AppState) => state.user.profile)
    const isConnectedAccountEnabled = useGateValue('connected_account')

    const dispatch = useDispatch()

    const createConnectedAccount = async (email: string) => {
      await employerService.createConnectedAccount(email).then((res) => {
        dispatch(employerActions.createConnectedAccountSuccess(res.data))
      })
    }

    const onSuccess = ({ code }) => {
      setLoading(true)
      employerService
        .integrateHris(code)
        .then((res) => {
          dispatch(employerActions.integrateHrisSuccess(res.data))
        })
        .then(() => {
          if (isConnectedAccountEnabled) {
            createConnectedAccount(email)
              .then(() => setLoading(false))
              .then(() => setOnboardingStep('addPayment'))
          } else {
            setLoading(false)
            setOnboardingStep('addPayment')
          }
        })
    }

    const onError = ({ errorMessage }) => {
      console.error(errorMessage)
      SegmentService.track('hris-connect-error', {
        msg: errorMessage,
      })
    }

    const onClose = () => {
      SegmentService.track('hris-connect-modal-closed', {
        onboardingStep: 'csv-upload',
      })
    }

    const { open } = useFinchConnect({
      clientId: FINCH_CLIENT_ID,
      products: [],

      // If using any client ID other than the sandbox client ID, ensure that the sandbox parameter is set to false
      sandbox: FINCH_SANDBOX_SETTING, // ENSURE THIS IS SET TO FALSE FOR PROD / STAGING
      // If the sandbox parameter is set to anything other than false, then the manual parameter must be set to false
      manual: FINCH_SANDBOX_SETTING ? false : true, // ENSURE THIS IS SET TO TRUE FOR PROD

      onSuccess,
      onError,
      onClose,
    })

    const handleSelectProvidersModal = React.useCallback(() => {
      SegmentService.track('hris-connect-modal-opened', {
        onboardingStep: 'csv-upload',
      })
      setShowSelectProvidersModal(true)
    }, [])

    const handleCloseSelectProvidersModal = React.useCallback(() => {
      setShowSelectProvidersModal(false)
    }, [])

    const onProviderSelected = React.useCallback(
      (provider: any) => {
        setShowSelectProvidersModal(false)
        open({
          products: provider.products,
          payrollProvider: provider.id,
        })
      },
      [open]
    )

    return (
      <Container>
        <Steps>
          <Step>
            <strong>
              <NumberedBullet className='numbered-bullet'>{1}</NumberedBullet>
            </strong>
            <Text variant='h4' color={palette.black}>
              Download this{' '}
              <a
                href={`${StaticAssetsUrl}/onboarding/employee_data_template.csv`}
                style={{ color: palette.primary.pink500 }}
              >
                template
              </a>
            </Text>
          </Step>
          <Step>
            <strong>
              <NumberedBullet className='numbered-bullet'>{2}</NumberedBullet>
            </strong>
            <Text variant='h4' color={palette.black}>
              Fill it out and save it as a CSV file
            </Text>
          </Step>
          <Step>
            <strong>
              <NumberedBullet className='numbered-bullet'>{3}</NumberedBullet>
            </strong>
            <Text variant='h4' color={palette.black}>
              Upload it to the right!
            </Text>
          </Step>
        </Steps>
        <OrSeparator>OR</OrSeparator>
        <ButtonContainer>
          <ButtonV2
            onClick={handleSelectProvidersModal}
            variant='secondary'
            size='large'
            marginTop='1rem'
          >
            Integrate HRIS
          </ButtonV2>
          <SelectProvidersModal
            open={showSelectProvidersModal}
            onClose={handleCloseSelectProvidersModal}
            selectProvider={onProviderSelected}
          />
        </ButtonContainer>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OnboardingUploadCsvSteps.displayName = 'OnboardingUploadCsvSteps'
}

export default OnboardingUploadCsvSteps
