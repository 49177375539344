import React from 'react'
import styled from 'styled-components'
import FleetConciergeIcon from '../../../core-system/Icons/Concierge/FleetConcierge'
import { RawDraftContentState } from '../../../core-system/TextEditor'
import DOMPurify from 'dompurify'
import draftToHtml from 'draftjs-to-html'
import theme from '../../../core-system/Themes/Main'

const Container = styled.div`
  width: 100%;
  display: flex;
  color: ${(props) => props.theme.palette.black};
  font-family: PolySans, sans-serif;
`

const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: 0.5rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ContentHeader = styled.div`
  display: flex;
  margin-bottom: 0.375rem;
  align-items: center;
`

const Title = styled.div`
  font-weight: 900;
  font-size: 0.9375rem;
  line-height: 1.125rem;
`

const App = styled.div`
  margin: 0 0.5rem;
  color: #575757;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.875rem;
  border-radius: 0.125rem;
  background-color: #e2e2e2;
  padding: 0.0625rem 0.1875rem 0.0625rem 0.125rem;
  height: 1rem;
`

const Time = styled.div`
  color: #616061;
  line-height: 0.875rem;
  font-size: 0.75rem;
`

const ContentBody = styled.div`
  display: flex;
`

const LeftBorder = styled.div`
  background-color: ${(props) => props.theme.palette.primary.pink600};
  border-radius: 0.5rem;
  height: 100%;
  margin-right: 1rem;
  width: 0.25rem;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  max-width: ${(props) => props.theme.pxToRem(442)};
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const TextTitle = styled.div`
  font-size: 0.9375rem;
  line-height: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`

const TextDescription = styled.div`
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.25rem;

  p {
    margin: 0;
  }
`

const ContentSubHeader = styled(TextDescription)`
  margin-bottom: 0.5rem;
`

const rawDraftToString = (raw: RawDraftContentState) => {
  return draftToHtml(raw)
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/&nbsp;/g, ' ')
    .trim()
}

const sanitizeMarkup = (html: any) => {
  return {
    __html: DOMPurify.sanitize(html),
  }
}

interface SlackPreviewProps {
  title: string
  description: RawDraftContentState
}

const SlackPreview = React.memo((props: SlackPreviewProps) => {
  const { title, description } = props
  const isEmpty = rawDraftToString(description) === ''
  const markup = draftToHtml(description)
  return (
    <Container>
      <IconContainer>
        <FleetConciergeIcon
          width={36}
          height={36}
          color={theme.palette.primary.pink600}
        />
      </IconContainer>
      <Content>
        <ContentHeader>
          <Title>Fleet Concierge</Title>
          <App>App</App>
          <Time>8:30 AM</Time>
        </ContentHeader>
        <ContentSubHeader>
          Your employer has sent you a message regarding your mobility benefits:
        </ContentSubHeader>
        <ContentBody>
          <LeftBorder />
          <TextContainer>
            <TextTitle>{title ? title : 'Sample Announcement Title'}</TextTitle>
            {isEmpty ? (
              <TextDescription>Your description goes here...</TextDescription>
            ) : (
              <TextDescription
                dangerouslySetInnerHTML={sanitizeMarkup(markup)}
              />
            )}
          </TextContainer>
        </ContentBody>
      </Content>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SlackPreview.displayName = 'SlackPreview'
}

export default SlackPreview
