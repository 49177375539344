import React from 'react'
import styled from 'styled-components'
import { buildForwardingComponent } from '../utils/buildComponent'
import Button, { ButtonProps as _ButtonProps } from './Button'

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.text.primary};

  &:hover,
  &:active {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

export type TextButtonProps = _ButtonProps

const TextButton = React.forwardRef<HTMLButtonElement, TextButtonProps>(
  (props: TextButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const { as, children } = props

    return (
      <StyledButton
        as={as}
        ref={ref}
        size='small'
        variant='tertiary'
        {...props}
      >
        {children}
      </StyledButton>
    )
  }
)

TextButton.displayName = 'TextButton'
export default buildForwardingComponent<HTMLButtonElement, TextButtonProps>(
  TextButton
)
