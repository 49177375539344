import React from 'react'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import styled from 'styled-components'
import { PreTaxInfoCopy } from '../PreTaxUtils'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import * as FlexIcons from 'core-system/Icons/Flex'
import Divider from 'core-system/Divider'

const IconContainer = styled(FlexContainer)`
  width: 2rem;
  height: 2rem;
  background-color: ${(props) => props.theme.palette.primary.pink800};
  border-radius: 50%;
  margin-right: 0.5rem;
`

const infoSection = (
  header: string | null,
  content: React.ReactNode | string
) => {
  return (
    <FlexContainer flexDirection='column'>
      {header ? (
        <Text variant='action3' textColor={palette.text.primary}>
          {header}
        </Text>
      ) : null}
      <FlexContainer marginTop={header ? '0.5rem' : 0}>
        {typeof content === 'string' ? (
          <Text variant='action4' textColor={palette.text.secondary}>
            {content}
          </Text>
        ) : (
          content
        )}
      </FlexContainer>
    </FlexContainer>
  )
}

const whatQualifiesInfoSection = (
  type: 'Transit' | 'Parking',
  icon: React.ReactNode,
  info: string
) => {
  return (
    <FlexContainer flexDirection='row' alignItems='center'>
      <IconContainer center>{icon}</IconContainer>
      <FlexContainer flexDirection='column'>
        <Text variant='action4' textColor={palette.primary.pink400}>
          {type}
        </Text>
        <Text variant='action4' textColor={palette.text.secondary}>
          {info}
        </Text>
      </FlexContainer>
    </FlexContainer>
  )
}

const PreTaxInfo = React.memo(() => {
  const { preTaxPrograms } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const { irsTaxLimit } = useSelector(
    (state: AppState) => state.application.properties
  )

  return (
    <div>
      {infoSection(null, PreTaxInfoCopy(irsTaxLimit).general)}
      <Divider margin='1rem 0' />
      {infoSection(
        'What is a Pre-Tax Benefit?',
        PreTaxInfoCopy(irsTaxLimit).whatIsAPreTaxBenefit
      )}
      <Divider margin='1rem 0' />
      {infoSection(
        'What Expenses Qualify for Pre-Tax?',
        <FlexContainer flexDirection='column'>
          {preTaxPrograms.COMMUTER_TRANSIT
            ? whatQualifiesInfoSection(
                'Transit',
                <FlexIcons.Transit
                  width='2rem'
                  color={palette.text.secondary}
                />,
                PreTaxInfoCopy(irsTaxLimit).whatQualifiesForPreTax.transit
              )
            : null}
          {preTaxPrograms.COMMUTER_TRANSIT &&
          preTaxPrograms.COMMUTER_PARKING ? (
            <Divider size='0' margin='0.25rem 0' />
          ) : null}
          {preTaxPrograms.COMMUTER_PARKING
            ? whatQualifiesInfoSection(
                'Parking',
                <FlexIcons.Parking color={palette.text.secondary} />,
                PreTaxInfoCopy(irsTaxLimit).whatQualifiesForPreTax.parking
              )
            : null}
        </FlexContainer>
      )}
      <Divider margin='1rem 0' />
      {infoSection(
        'Does This Money Expire?',
        PreTaxInfoCopy(irsTaxLimit).doesThisExpire
      )}
      <Divider margin='1rem 0' />
      {infoSection(
        'Is There a Maximum Monthly Spend?',
        PreTaxInfoCopy(irsTaxLimit).isThereAMaxMonthlySpend
      )}
    </div>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  PreTaxInfo.displayName = 'PreTaxInfo'
}

export default PreTaxInfo
