import { css } from 'styled-components'
// Import the font file using a different method or module

export const Name = 'PolySans'

const font = css`
  @font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }
  @font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }
  @font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
  }
`

export default font
