import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'
import { buildForwardingComponent } from '../utils/buildComponent'
import LoadingBar from '../LoadingBar'

const HeaderItemContainer = styled.button<{
  active: boolean
  isLoading: boolean
}>`
  ${(props) => props.theme.typography.action4};
  display: flex;
  margin-right: 0.75rem;
  text-transform: capitalize;
  padding: 0.375rem 0.65rem;
  border-radius: 0.375rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  color: ${(props) =>
    props.active
      ? props.theme.palette.white
      : props.theme.palette.text.primary};
  background-color: ${(props) =>
    props.active ? props.theme.palette.secondary.purple600 : 'inherit'};
  cursor: ${(props) =>
    props.active || props.isLoading ? 'default' : 'pointer'};
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${(props) => props.theme.palette.text.disabled};
    pointer-events: none;
    border: 1px solid ${(props) => props.theme.palette.grey.grey200};
  }

  ${(props) =>
    !props.active &&
    !props.isLoading && {
      '&:hover:enabled': {
        backgroundColor: props.theme.palette.secondary.purple200,
      },
    }}

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    white-space: nowrap;
  }

  ${flexbox}
  ${space}
  ${layout}
`

const HeaderLoading = styled(LoadingBar)`
  height: 1.4375rem;
  width: ${(props) => props.theme.pxToRem(60)};
  margin: 0;
`

export interface TableHeaderItemProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  active?: boolean
  disabled?: boolean
  isLoading?: boolean
}

const TableHeaderItem = React.forwardRef<
  HTMLButtonElement,
  TableHeaderItemProps
>((props: TableHeaderItemProps, ref: React.Ref<HTMLButtonElement>) => {
  const { children, active = false, isLoading = false } = props
  return (
    <HeaderItemContainer
      ref={ref}
      active={active}
      isLoading={isLoading}
      {...props}
    >
      {isLoading ? <HeaderLoading /> : children}
    </HeaderItemContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TableHeaderItem.displayName = 'TableHeaderItem'
}

export default buildForwardingComponent(TableHeaderItem)
