import React from 'react'
import { QtfDashboardStats } from 'redux/qtf/qtfTypes'
import styled from 'styled-components'

import Divider from 'core-system/Divider'
import moment from 'moment'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import FormattingUtils from 'shared/FormattingUtils'
import ChipDifference from 'core-system/Chip/ChipDifference'
import CategoryBreakdown from 'core-system/CategoryBreakdown'
import palette from 'core-system/Themes/palette'
import LegendColor from 'core-system/Legend'
import RenewIcon from 'core-system/Icons/Actions/Renew'
import pxToRem from 'core-system/utils/pxToRem'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  padding: 1.5rem 0 1.5rem 1.5rem;
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: 1.5rem;
  }
`

const HeaderContainer = styled.div`
  width: 70%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    width: 85%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 100%;
  }
`

const HeaderImg = styled.div`
  width: 30%;
  overflow: hidden;
  margin-left: 1.1875rem;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    display: none;
  }
`

const StyledDivider = styled(Divider)`
  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    margin: 0 5%;
  }
`

const getCategoryBreakdown = (stats: QtfDashboardStats) => {
  const parking = {
    value: stats.spendStats.parkingSpend.current || 0,
    color: palette.primary.pink400,
    title: 'Parking',
  }
  const transit = {
    value: stats.spendStats.transitSpend.current || 0,
    color: palette.primary.pink400,
    title: 'Public Transportation',
  }

  const categories = []
  if (stats.spendStats.parkingSpend.current !== null) {
    categories.push(parking)
  }
  if (stats.spendStats.transitSpend.current !== null) {
    categories.push(transit)
  }
  return categories
}

interface QTFOverviewHeaderProps {
  stats: QtfDashboardStats
}

const QTFOverviewHeader = React.memo((props: QTFOverviewHeaderProps) => {
  const { stats } = props

  if (!stats) return <Loading height={pxToRem(287)} />

  const daysLeftInMonth = moment().daysInMonth() - moment().date() + 1
  const categoryBreakdown = getCategoryBreakdown(stats)
  const { balanceStats, contribStats, spendStats } = stats

  const parkingBalance = balanceStats.inCycleParkingBalance || 0
  const parkingSpend = spendStats.parkingSpend.current || 0

  const transitBalance = balanceStats.inCycleTransitBalance || 0
  const transitSpend = spendStats.transitSpend.current || 0

  // TODO, check why tax savings for current month are 0
  return (
    <Container>
      <HeaderContainer>
        <FlexContainer alignItems='center'>
          <Text variant='h5'>Total Spend</Text>
        </FlexContainer>
        <FlexContainer margin='1.5rem 0 0.5rem' alignItems='center'>
          <PlatformSvg
            folder='metrics'
            variant='money'
            width={40}
            height={40}
          />
          <Text variant='h1' marginLeft='1rem'>
            {FormattingUtils.formatDollar(parkingSpend + transitSpend, 0)}
          </Text>
          <Text
            variant='body2'
            textColor={palette.text.disabled}
            alignSelf='flex-end'
            margin='0 0 0.3125rem 0.3125rem'
          >
            / {FormattingUtils.formatDollar(parkingBalance + transitBalance, 0)}{' '}
            month
          </Text>
          <ChipDifference
            percentDifference={
              (spendStats.parkingSpend.difference +
                spendStats.transitSpend.difference) *
              100
            }
            marginLeft='3.25rem'
          />
        </FlexContainer>
        <CategoryBreakdown
          categories={categoryBreakdown}
          total={parkingBalance + transitBalance}
          isCurrency
        />
        <FlexContainer marginTop='0.6875rem'>
          {categoryBreakdown.map((category, idx) => (
            <FlexContainer key={idx} alignItems='center' marginRight='1.5rem'>
              <LegendColor color={category.color} marginRight='0.5rem' />
              <Text
                variant='caption'
                captionTitle
                textColor={palette.text.secondary}
              >
                {category.title}
              </Text>
            </FlexContainer>
          ))}
          <Divider
            direction='vertical'
            margin='0 2rem 0 0.5rem'
            size='1.5rem'
          />
          <FlexContainer alignItems='center'>
            <RenewIcon color={palette.text.secondary} />
            <Text
              variant='caption'
              captionTitle
              textColor={palette.text.secondary}
              marginLeft='0.5rem'
            >
              Program Resets in {daysLeftInMonth} Days
            </Text>
          </FlexContainer>
        </FlexContainer>
        <Divider margin='1rem 0 1.5rem' />
        <FlexContainer>
          <div>
            <FlexContainer marginBottom='0.5rem' alignItems='center'>
              <PlatformSvg
                folder='metrics'
                variant='co2'
                width={40}
                height={40}
              />
              <Text variant='h3' marginLeft='1rem'>
                {FormattingUtils.formatDollar(
                  contribStats.employerTaxSavings.current || 0
                )}
              </Text>
              <Text
                variant='body2'
                textColor={palette.text.disabled}
                alignSelf='flex-end'
                margin='0 0 0.3125rem 0.3125rem'
              >
                /month
              </Text>
              <ChipDifference
                percentDifference={
                  contribStats.employerTaxSavings.difference * 100
                }
                marginLeft='4rem'
              />
            </FlexContainer>
            <Text
              variant='caption'
              captionTitle
              textColor={palette.text.secondary}
            >
              Tax Savings For Employer
            </Text>
          </div>
          <StyledDivider
            direction='vertical'
            margin='0 3.25rem'
            size={pxToRem(49)}
          />
          <div>
            <FlexContainer marginBottom='0.5rem' alignItems='center'>
              <PlatformSvg
                folder='metrics'
                variant='employees'
                width={40}
                height={40}
              />
              <Text variant='h3' marginLeft='1rem'>
                {FormattingUtils.formatDollar(
                  contribStats.employeeTaxSavings.current || 0
                )}
              </Text>
              <Text
                variant='body2'
                textColor={palette.text.disabled}
                alignSelf='flex-end'
                margin='0 0 0.3125rem 0.3125rem'
              >
                /month
              </Text>
              <ChipDifference
                percentDifference={
                  contribStats.employeeTaxSavings.difference * 100
                }
                marginLeft='4rem'
              />
            </FlexContainer>
            <Text
              variant='caption'
              captionTitle
              textColor={palette.text.secondary}
            >
              Tax Savings For Commuter
            </Text>
          </div>
        </FlexContainer>
      </HeaderContainer>
      <HeaderImg>
        <PlatformSvg
          folder='banners'
          variant='banner4'
          width={431}
          height={208}
        />
      </HeaderImg>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFOverviewHeader.displayName = 'QTFOverviewHeader'
}

export default QTFOverviewHeader
