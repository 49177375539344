import React from 'react'
import styled from 'styled-components'
import { buildForwardingComponent } from '../utils/buildComponent'

const Header = styled.div`
  display: flex;
  background-color: 'white';
  padding: 1rem;
  align-items: center;
  overflow: scroll;
  scrollbar-width: none;

  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  border-radius: 0.625rem;

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export interface TableHeaderProps {
  children: React.ReactNode
}

const TableHeader = React.forwardRef<HTMLDivElement, TableHeaderProps>(
  (props: TableHeaderProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <Header ref={ref} {...props}>
        {props.children}
      </Header>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TableHeader.displayName = 'TableHeader'
}

export default buildForwardingComponent(TableHeader)
