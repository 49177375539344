export default {
  50: '#F3FAFB',
  100: '#E6F5F7',
  200: '#CFF0F2',
  300: '#A8E1E4',
  400: '#7BCBCC',
  500: '#4EAFB1',
  600: '#2C8C8C',
  700: '#1E6666',
  800: '#134040',
  900: '#0A2222',
}
