import React, { useMemo } from 'react'
import FlexContainer from 'core-system/FlexContainer'
import SuccessIcon from 'core-system/Icons/Misc/Success'
import CloseIcon from 'core-system/Icons/Actions/Close'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import {
  passwordNoUnderscoreCheck,
  passwordNumberCheck,
  passwordSpecialCharCheck,
} from '../shared/LoginSignUpUtils'

const renderPasswordRequirement = (reqMessage: string, reqMet: boolean) => {
  return (
    <FlexContainer
      flexDirection='row'
      alignItems='center'
      marginBottom='0.25rem'
    >
      {reqMet ? (
        <SuccessIcon
          height='1.15rem'
          width='1.15rem'
          color={palette.success.green500}
        />
      ) : (
        <CloseIcon
          height='1.15rem'
          width='1.15rem'
          color={palette.error.red500}
        />
      )}
      <Text
        variant='body2'
        textColor={palette.primary.pink500}
        lineHeight={0}
        marginLeft='0.25rem'
      >
        {reqMessage}
      </Text>
    </FlexContainer>
  )
}

interface PasswordRequirementCheckerProps {
  password: string
}

const PasswordRequirementChecker = React.memo(
  (props: PasswordRequirementCheckerProps) => {
    const { password } = props

    const passwordHasSpecialChar = useMemo(
      () => passwordSpecialCharCheck(password),
      [password]
    )

    const passwordHasNumber = useMemo(
      () => passwordNumberCheck(password),
      [password]
    )

    const passwordHasNoUnderscore = useMemo(
      () => passwordNoUnderscoreCheck(password),
      [password]
    )

    return (
      <>
        <Text
          variant='body2'
          textColor={palette.primary.pink500}
          margin='0.75rem 0 0.35rem'
          maxWidth={pxToRem(450)}
        >
          Passwords MUST contain:
        </Text>
        {renderPasswordRequirement(
          'At least 10 characters',
          password.length >= 10
        )}
        {renderPasswordRequirement(
          'At least 1 special character ( ! @ # $ % ^ & * )',
          passwordHasSpecialChar
        )}
        {renderPasswordRequirement('At least 1 number', passwordHasNumber)}
        {renderPasswordRequirement('NO underscores', passwordHasNoUnderscore)}
      </>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  PasswordRequirementChecker.displayName = 'PasswordRequirementChecker'
}

export default PasswordRequirementChecker
