import React from 'react'
import styled from 'styled-components'
import CloseIcon from '../Icons/Actions/Close'
import { color, ColorProps } from '../@styled-system/Color'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  border,
  BorderProps,
} from 'styled-system'

const Container = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps &
    BorderProps &
    ColorProps & { isDark: boolean; hasShadow: boolean }
>`
  padding: ${(props) =>
    `${props.theme.pxToRem(24)} ${props.theme.pxToRem(52)}`};
  background-color: ${(props) =>
    props.isDark
      ? props.theme.palette.primary.pink800
      : props.theme.palette.white};
  color: ${(props) =>
    props.isDark
      ? props.theme.palette.white
      : props.theme.palette.text.primary};
  border-bottom: ${(props) =>
    props.isDark ? 'unset' : `1px solid ${props.theme.palette.grey.grey300}`};
  box-shadow: ${(props) =>
    props.hasShadow ? props.theme.dropShadows.top : 'unset'};

  ${flexbox}
  ${space}
  ${layout}
  ${color}
  ${border}
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`

const Text = styled.div`
  ${(props) => props.theme.typography.h4}
  display: flex;
  align-items: center;
  padding-top: 0.125rem;
`

const Close = styled.div<{ isDark: boolean }>`
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${(props) =>
      props.isDark
        ? props.theme.palette.primary.pink500
        : props.theme.palette.primary.pink200};
  }
`

type variants = 'light' | 'dark'

interface ModalHeaderProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    ColorProps,
    BorderProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  title: string
  variant?: variants
  onClose?: (e: React.MouseEvent<any>) => void
  hasShadow?: boolean
}

const ModalHeader = React.memo((props: ModalHeaderProps) => {
  const { variant = 'light', title, onClose, hasShadow } = props
  return (
    <Container isDark={variant === 'dark'} hasShadow={hasShadow} {...props}>
      <Content>
        <Text data-cy='modal-header-title'>{title}</Text>
        <Close
          isDark={variant === 'dark'}
          onClick={onClose}
          data-cy='modal-header-close-btn'
        >
          <CloseIcon width={24} height={24} />
        </Close>
      </Content>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ModalHeader.displayName = 'ModalHeader'
}

export default ModalHeader
