import React from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import FlexContainer from 'core-system/FlexContainer'
import RadialChartCenterText from 'core-system/charts/Radial/RadialChartCenterText'
import { QtfServiceUsage } from 'redux/qtf/qtfTypes'
import pxToRem from 'core-system/utils/pxToRem'
import Loading from 'core-system/Loading'
import FormattingUtils from 'shared/FormattingUtils'
import HorizontalScroller from 'core-system/HorizontalScroller'
import palette from 'core-system/Themes/palette'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  overflow: hidden;
  display: flex;
  flex-direction: column;
`
const Service = styled.div`
  display: flex;
  height: 2.5rem;
  margin-right: 1.25rem;
  align-items: center;

  &:last-of-type {
    margin-right: unset;
    .serviceText {
      border-right: unset;
    }
  }
`

const AliasText = styled(Text)`
  white-space: nowrap;
`

const ServiceText = styled.div`
  padding: 0 1.5rem 0 1rem;
  border-right: 1px solid ${(props) => props.theme.palette.grey.grey300};
`

const getChartData = (servicesUsageData: QtfServiceUsage[]) => {
  return [...servicesUsageData]
    .sort((a, b) => b.numUses - a.numUses)
    .reduce(
      (agg, service) => {
        const spend = service.amountSpent || 0
        agg.totalSpend = agg.totalSpend + spend
        const trip = service.numUses || 0
        agg.totalTrips = agg.totalTrips + trip

        agg.data.push(trip)
        agg.labels.push(
          `${service.merchantName} (${service.numUses} ${
            service.numUses === 1 ? 'Trip' : 'Trips'
          })`
        )
        agg.sorted.push(service)
        return agg
      },
      {
        totalSpend: 0,
        totalTrips: 0,
        data: [],
        labels: [],
        sorted: [],
      }
    )
}

interface QTFBreakdownCardProps {
  servicesUsageData: QtfServiceUsage[]
}

const QTFBreakdownCard = React.memo((props: QTFBreakdownCardProps) => {
  const { servicesUsageData } = props

  if (!servicesUsageData) return <Loading height={pxToRem(417)} />

  const { data, labels, sorted } = getChartData(servicesUsageData)

  return (
    <Container>
      <Text variant='h5' marginBottom='1.5rem'>
        Services Breakdown
      </Text>
      <FlexContainer
        flexDirection='column'
        justifyContent='space-between'
        flex={1}
      >
        <FlexContainer center width='100%' marginBottom={'1.5rem'}>
          <RadialChartCenterText
            value={servicesUsageData.length}
            label={'Service Providers'}
            data={data.slice(0, 9)}
            labels={labels.slice(0, 9)}
            size={195}
            errorMsg='No Service Providers Used'
          />
        </FlexContainer>
        {servicesUsageData.length === 0 ? (
          <FlexContainer center>
            <Text variant='body1'>No service providers used yet</Text>
          </FlexContainer>
        ) : (
          <HorizontalScroller
            translationPerClick={100}
            opacityWidth={pxToRem(110)}
          >
            {sorted.map((service: QtfServiceUsage, idx: number) => (
              <Service key={idx}>
                <ServiceText className='serviceText'>
                  <AliasText variant='action4' hideOverflow>
                    {service.merchantName}
                  </AliasText>
                  <FlexContainer>
                    <Text variant='body2' textColor={palette.text.secondary}>
                      {service.numUses}{' '}
                      {service.numUses === 1 ? 'Trip' : 'Trips'}
                    </Text>
                    <Text
                      capitalize
                      hideOverflow
                      variant='body2'
                      textColor={palette.text.secondary}
                    >
                      &nbsp;(
                      {FormattingUtils.formatDollar(service.amountSpent, 2)})
                    </Text>
                  </FlexContainer>
                </ServiceText>
              </Service>
            ))}
          </HorizontalScroller>
        )}
      </FlexContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFBreakdownCard.displayName = 'QTFBreakdownCard'
}

export default QTFBreakdownCard
