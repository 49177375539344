import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { StyledComponent } from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import { employerActions } from 'redux/employer/employerSlice'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import { AppState } from 'redux/config/store'
import Text from 'core-system/Text'
import Button from 'core-system/Button'
import palette from 'core-system/Themes/palette'
import SegmentService from 'redux/config/services/SegmentService'
import refExists from 'shared/refExists'
import employerService from 'redux/employer/employerService'
import { Segment } from 'redux/employer/employerTypes'
import { Alert, Success } from 'core-system/Icons/Misc'
import ButtonV2 from 'core-system/ButtonV2'

const EXTENSION_ALLOW_LIST = '.csv'

const UploadInput = styled.input`
  display: none;
`

const NoMouseEvent = styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin-bottom: 0.5rem;
`

const ButtonNoMouseEvent = styled.div<{ mouseDrag: boolean }>`
  pointer-events: ${(props) => (props.mouseDrag ? 'none' : 'auto')};
`

const FileUploadedMsg = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.5rem;
  padding: 5rem 3rem;
  > * {
    margin-bottom: 0.5rem;
  }
`

interface FileUploadProps {
  segment?: Segment
  fileAdded: number
  setFileAdded: (value: number) => void
  response: string
  setResponse: (value: string) => void
  allowReupload?: boolean
  DropArea: StyledComponent<'div', any, { mouseOver: boolean }, never>
}

const UploadDataComponentV2 = React.memo((props: FileUploadProps) => {
  const {
    segment,
    fileAdded,
    setFileAdded,
    response,
    setResponse,
    allowReupload = true,
    DropArea,
  } = props

  const isFileLoading = useSelector(
    (state: AppState) => state.employer.isUploading
  )

  const [mouseOver, setMouseOver] = useState<boolean>(false)

  const fileInputRef = useRef(null)
  const dispatch = useDispatch()

  const dataDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setMouseOver(false)

    const file = e.dataTransfer.items[0]
    if (file.type.includes('csv')) {
      const formData = new FormData()
      formData.append('datafile', file.getAsFile())
      formData.append('datatype', 'employee_location_data')
      if (segment) {
        formData.append('segment', segment.id)
      }
      dispatch(employerActions.uploadFile())
      employerService
        .uploadFile(formData)
        .then((res) => dispatch(employerActions.uploadFileSuccess(res.data)))
        .catch((err) =>
          dispatch({ type: 'employer/uploadFileFailed', error: err })
        )
    } else {
      dispatch(
        notificationsActions.generalPageError(
          'Please upload a file with a supported format.'
        )
      )
    }
  }

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const file = e.target.files[0]
    if (file.type.includes('csv')) {
      const formData = new FormData()
      formData.append('datafile', file)
      formData.append('datatype', 'employee_location_data')
      if (segment) {
        formData.append('segment', segment.id)
      }
      dispatch(employerActions.uploadFile())
      employerService
        .uploadFile(formData)
        .then((res) => {
          dispatch(employerActions.uploadFileSuccess(res.data))
        })
        .catch((err) => {
          dispatch({ type: 'employer/uploadFileFailed', error: err })
          setResponse(JSON.parse(err.request.response).datafile[0])
        })
    } else {
      dispatch(
        notificationsActions.generalPageError(
          'Please upload a file with a supported format.'
        )
      )
    }
  }

  const onDropAreaClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (refExists(fileInputRef.current)) {
      fileInputRef.current.click()
    }
    SegmentService.track('dataIntegration-file-upload')
  }

  const onDragOverTarget = React.useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      setMouseOver(true)
    },
    []
  )

  const onDragExitTarget = React.useCallback(() => {
    setMouseOver(false)
  }, [])

  useEffect(() => {
    if (isFileLoading) {
      setFileAdded(isFileLoading)
    }
  }, [isFileLoading, setFileAdded])

  return (
    <>
      {fileAdded === 1 && (
        <FileUploadedMsg style={{ margin: '2rem 0' }}>
          <CircularProgress
            sx={{
              color: palette.primary.pink800,
            }}
            size={50}
          />
        </FileUploadedMsg>
      )}
      {fileAdded === 2 && (
        <FileUploadedMsg>
          <Success
            color={palette.primary.pink100}
            height={75}
            width={75}
            style={{ marginBottom: '1rem' }}
          />
          <Text
            variant='h2'
            textColor={palette.primary.pink100}
            textAlign='center'
          >
            Woohoo! Your employees have been successfully added!
          </Text>
          {allowReupload ? (
            <Button size='small' onClick={() => setFileAdded(0)}>
              Upload Another
            </Button>
          ) : (
            <Text variant='body1' textAlign='center'>
              Made a mistake? No worries! You can easily reupload in the
              &apos;My Commuters&apos; tab after onboarding is completed
            </Text>
          )}
        </FileUploadedMsg>
      )}
      {fileAdded === -1 && (
        <FileUploadedMsg>
          <Alert
            color={palette.primary.pink100}
            height={75}
            width={75}
            style={{ marginBottom: '1rem' }}
          />
          <Text
            variant='h2'
            textColor={palette.primary.pink100}
            textAlign='center'
          >
            {response && response !== ''
              ? response
              : 'Oops! Something went wrong'}
          </Text>
          <Text variant='body1' textColor={palette.text.primary}>
            Please try uploading the file again.
          </Text>
          <Button
            size='small'
            onClick={() => setFileAdded(0)}
            marginTop='0.25rem'
          >
            Upload Another
          </Button>
        </FileUploadedMsg>
      )}
      {fileAdded === 0 && (
        <DropArea
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          onDragEnter={onDragOverTarget}
          onDragOver={onDragOverTarget}
          onDragLeave={onDragExitTarget}
          onDrop={dataDrop}
          mouseOver={mouseOver}
        >
          <NoMouseEvent>
            <Text variant='h3' marginTop='1rem' color={'black'}>
              Drag and drop your CSV files
            </Text>
          </NoMouseEvent>
          <ButtonNoMouseEvent mouseDrag={mouseOver}>
            <label htmlFor='dataDrop'>
              <ButtonV2 marginTop={'1rem'} onClick={onDropAreaClick}>
                Upload file
              </ButtonV2>
              <UploadInput
                ref={fileInputRef}
                type='file'
                id='dataDrop'
                onChange={inputChange}
                accept={EXTENSION_ALLOW_LIST}
              />
            </label>
          </ButtonNoMouseEvent>
        </DropArea>
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  UploadDataComponentV2.displayName = 'UploadDataComponent'
}

export default UploadDataComponentV2
