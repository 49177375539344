import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <mask
      id='mask0'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='18'
      height='14'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 0H17.9998V13.8877H0V0Z'
        fill='white'
      />
    </mask>
    <g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.07379 5.079L4.34379 1.269H13.6568L14.9268 5.079H3.07379ZM13.6568 10.11C12.9538 10.11 12.3868 9.543 12.3868 8.84C12.3868 8.138 12.9538 7.57 13.6568 7.57C14.3588 7.57 14.9268 8.138 14.9268 8.84C14.9268 9.543 14.3588 10.11 13.6568 10.11V10.11ZM4.34379 10.11C3.64079 10.11 3.07379 9.543 3.07379 8.84C3.07379 8.138 3.64079 7.57 4.34379 7.57C5.04679 7.57 5.61379 8.138 5.61379 8.84C5.61379 9.543 5.04679 10.11 4.34379 10.11V10.11ZM17.1538 5.079H16.3598C16.3478 5.079 16.3388 5.086 16.3278 5.086L14.8588 0.854C14.6848 0.355 14.2108 0 13.6568 0H4.34379C3.78879 0 3.31479 0.355 3.14179 0.854L1.67279 5.086C1.66079 5.086 1.65179 5.079 1.63979 5.079H0.846786C0.380786 5.079 -0.000213623 5.461 -0.000213623 5.926C-0.000213623 6.391 0.380786 6.772 0.846786 6.772H1.38079V13.041C1.38079 13.507 1.76179 13.888 2.22679 13.888H3.07379C3.54379 13.888 3.92079 13.507 3.92079 13.041V12.194H14.0798V13.041C14.0798 13.507 14.4608 13.888 14.9268 13.888H15.7728C16.2428 13.888 16.6188 13.507 16.6188 13.041V6.772H17.1538C17.6188 6.772 17.9998 6.391 17.9998 5.926C17.9998 5.461 17.6188 5.079 17.1538 5.079V5.079Z'
        fill='#6200E0'
      />
    </g>
  </>,
  'Programs Car',
  '0 0 18 14'
)
