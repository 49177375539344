import Button from 'core-system/Button'
import FlexContainer from 'core-system/FlexContainer'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import FleetLogoShortWhite from 'core-system/FleetLogos/FleetLogoShortWhite'
import NormalAnnouncementIcon from 'core-system/Icons/Concierge/Message'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import AnnouncementDetailsModal from 'features/Concierge/Announcements/AnnouncementDetailsModal'
import { staticEmailTemplates } from 'features/Concierge/shared/utils'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { conciergeActions } from 'redux/concierge/conciergeSlice'
import { Announcement } from 'redux/concierge/conciergeTypes'
import { AppState } from 'redux/config/store'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  margin-bottom: 1.5rem;

  &:hover {
    .headerLink {
      opacity: 1;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

const TitleContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`

const HeaderLink = styled(Link)`
  padding: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  background-color: ${(props) => props.theme.palette.primary.pink200};
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  height: 2rem;
  width: 2rem;
  display: flex;
  transform: rotate(270deg);

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }

  &:active {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const Announcements = styled.div`
  display: flex;
  flex-direction: column;
`

const AnnouncementContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey300};
  margin-bottom: 0.5rem;

  &:last-of-type {
    border-bottom: unset;
    padding-bottom: unset;
    margin-bottom: unset;
  }
`

const HoverContainer = styled(FlexContainer)`
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const IconContainer = styled.div`
  margin-right: 1rem;
  height: 2.5rem;
  min-width: 2.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.primary.pink500};
  color: ${(props) => props.theme.palette.text.secondary};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  display: flex;
  justify-content: center;
  align-items: center;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Name = styled.div`
  ${(props) => props.theme.typography.action4}
  color: ${(props) => props.theme.palette.text.primary};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0.125rem;
`

const Description = styled.div`
  ${(props) => props.theme.typography.body2}
  color: ${(props) => props.theme.palette.text.placeholder};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => props.theme.pxToRem(113)};

  a {
    text-transform: capitalize;
    color: ${(props) => props.theme.palette.white};
    text-decoration: none;
  }
`

const StyledLink = styled(Link)`
  margin-bottom: 1.5rem;
  text-decoration: none;
`

const getAnnouncements = (
  allAnnouncements: Announcement[],
  handleClick: (announcement: Announcement) => void
) => {
  return [...allAnnouncements]
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    .slice(0, 2)
    .map((announcement) => {
      const scheduleOrCreated = announcement.scheduledAt
        ? announcement.scheduledAt
        : announcement.createdAt

      const date = moment(scheduleOrCreated).format('MMM Do, YYYY')
      const hour = moment(scheduleOrCreated).format('h:mm a')
      const now = moment()

      return (
        <AnnouncementContainer key={announcement.id}>
          <HoverContainer onClick={() => handleClick(announcement)}>
            <IconContainer>
              {announcement.automated ? (
                <FleetLogoShortWhite />
              ) : (
                <NormalAnnouncementIcon color={palette.white} />
              )}
            </IconContainer>
            <TextContainer>
              <Name>
                {announcement?.title
                  ? announcement.title
                  : staticEmailTemplates[announcement?.name]?.title}
              </Name>
              <Description>
                {now.isSameOrAfter(scheduleOrCreated)
                  ? 'Sent on '
                  : 'Scheduled for '}
                {date} at {hour}
              </Description>
            </TextContainer>
          </HoverContainer>
        </AnnouncementContainer>
      )
    })
}

const DashboardConcierge = React.memo(() => {
  const dispatch = useDispatch()

  const allAnnouncements = useSelector(
    (state: AppState) => state.concierge.announcements
  )

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)
  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<Announcement>()

  useEffect(() => {
    if (!allAnnouncements) {
      dispatch(conciergeActions.getAnnouncements())
    }
  }, [allAnnouncements, dispatch])

  const handleClick = (announcement: Announcement) => {
    setIsDetailModalOpen(true)
    setSelectedAnnouncement(announcement)
  }

  if (!allAnnouncements) {
    return <Loading height={pxToRem(205)} marginBottom='1.5rem' />
  }

  return (
    <>
      <Container>
        <Content>
          <TitleContainer>
            <Text variant='h5'>Announcements</Text>
            <HeaderLink
              to={Locations.Concierge.Concierge}
              className='headerLink'
            >
              <ChevronIcon />
            </HeaderLink>
          </TitleContainer>
          <Announcements>
            {allAnnouncements.length > 0 ? (
              getAnnouncements(allAnnouncements, handleClick)
            ) : (
              <ErrorContainer>
                <Text variant='action4' marginBottom='1.5rem'>
                  No Announcements Scheduled
                </Text>
                <StyledLink to={Locations.Concierge.Concierge}>
                  <Button size='small'>Schedule an Announcement</Button>
                </StyledLink>
              </ErrorContainer>
            )}
          </Announcements>
        </Content>
      </Container>
      <AnnouncementDetailsModal
        isModalOpen={isDetailModalOpen}
        setIsModalOpen={setIsDetailModalOpen}
        announcement={selectedAnnouncement}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardConcierge.displayName = 'DashboardConcierge'
}

export default DashboardConcierge
