import * as Colors from '../colors'
import fade from '../utils/fade'

interface Primary {
  pink50: string
  pink100: string
  pink200: string
  pink300: string
  pink400: string
  pink500: string
  pink600: string
  pink700: string
  pink800: string
  pink900: string
}

interface Text {
  primary: string
  secondary: string
  placeholder: string
  disabled: string
  caption: string
}

interface Grey {
  grey50: string
  grey100: string
  grey200: string
  grey300: string
  grey400: string
  grey500: string
  grey600: string
  grey700: string
  grey800: string
  grey900: string
  overlay: string
}

interface Secondary {
  purple50: string
  purple100: string
  purple200: string
  purple300: string
  purple400: string
  purple500: string
  purple600: string
  purple700: string
  purple800: string
  purple900: string
}

interface Success {
  green50: string
  green100: string
  green200: string
  green300: string
  green400: string
  green500: string
  green600: string
  green700: string
  green800: string
  green900: string
}

interface Error {
  red50: string
  red100: string
  red200: string
  red300: string
  red400: string
  red500: string
  red600: string
  red700: string
  red800: string
  red900: string
}

interface Warning {
  yellow50: string
  yellow100: string
  yellow200: string
  yellow300: string
  yellow400: string
  yellow500: string
  yellow600: string
  yellow700: string
  yellow800: string
  yellow900: string
}

interface Accent {
  teal50: string
  teal100: string
  teal200: string
  teal300: string
  teal400: string
  teal500: string
  teal600: string
  teal700: string
  teal800: string
  teal900: string
  blue50: string
  blue100: string
  blue200: string
  blue300: string
  blue400: string
  blue500: string
  blue600: string
  blue700: string
  blue800: string
  blue900: string
}

interface Leaderboards {
  bronzeBackground: string
  bronzeText: string
  silverBackground: string
  silverText: string
  goldBackground: string
  goldText: string
}

export interface Palette {
  fade(color: string, alpha?: number): string
  primary: Primary
  text: Text
  grey: Grey
  secondary: Secondary
  accent: Accent
  white: string
  black: string
  transparent: string
  success: Success
  error: Error
  warning: Warning
  leaderboards: Leaderboards
}

const palette: Palette = {
  fade,
  primary: {
    pink50: Colors.Pink[50],
    pink100: Colors.Pink[100],
    pink200: Colors.Pink[200],
    pink300: Colors.Pink[300],
    pink400: Colors.Pink[400],
    pink500: Colors.Pink[500],
    pink600: Colors.Pink[600],
    pink700: Colors.Pink[700],
    pink800: Colors.Pink[800],
    pink900: Colors.Pink[900],
  },
  text: {
    primary: Colors.Grey[900],
    secondary: Colors.Grey[700],
    placeholder: Colors.Grey[500],
    disabled: Colors.Grey[400],
    caption: Colors.Grey[400],
  },
  grey: {
    grey50: Colors.Grey[50],
    grey100: Colors.Grey[100],
    grey200: Colors.Grey[200],
    grey300: Colors.Grey[300],
    grey400: Colors.Grey[400],
    grey500: Colors.Grey[500],
    grey600: Colors.Grey[600],
    grey700: Colors.Grey[700],
    grey800: Colors.Grey[800],
    grey900: Colors.Grey[900],
    overlay: fade(Colors.Grey[700], 0.22),
  },
  success: {
    green50: Colors.Green[50],
    green100: Colors.Green[100],
    green200: Colors.Green[200],
    green300: Colors.Green[300],
    green400: Colors.Green[400],
    green500: Colors.Green[500],
    green600: Colors.Green[600],
    green700: Colors.Green[700],
    green800: Colors.Green[800],
    green900: Colors.Green[900],
  },
  error: {
    red50: Colors.Red[50],
    red100: Colors.Red[100],
    red200: Colors.Red[200],
    red300: Colors.Red[300],
    red400: Colors.Red[400],
    red500: Colors.Red[500],
    red600: Colors.Red[600],
    red700: Colors.Red[700],
    red800: Colors.Red[800],
    red900: Colors.Red[900],
  },
  white: '#ffffff',
  black: '#000000',
  transparent: 'transparent',
  secondary: {
    purple50: Colors.Purple[50],
    purple100: Colors.Purple[100],
    purple200: Colors.Purple[200],
    purple300: Colors.Purple[300],
    purple400: Colors.Purple[400],
    purple500: Colors.Purple[500],
    purple600: Colors.Purple[600],
    purple700: Colors.Purple[700],
    purple800: Colors.Purple[800],
    purple900: Colors.Purple[900],
  },
  accent: {
    teal50: Colors.Teal[50],
    teal100: Colors.Teal[100],
    teal200: Colors.Teal[200],
    teal300: Colors.Teal[300],
    teal400: Colors.Teal[400],
    teal500: Colors.Teal[500],
    teal600: Colors.Teal[600],
    teal700: Colors.Teal[700],
    teal800: Colors.Teal[800],
    teal900: Colors.Teal[900],
    blue50: Colors.Blue[50],
    blue100: Colors.Blue[100],
    blue200: Colors.Blue[200],
    blue300: Colors.Blue[300],
    blue400: Colors.Blue[400],
    blue500: Colors.Blue[500],
    blue600: Colors.Blue[600],
    blue700: Colors.Blue[700],
    blue800: Colors.Blue[800],
    blue900: Colors.Blue[900],
  },
  warning: {
    yellow50: Colors.Yellow[50],
    yellow100: Colors.Yellow[100],
    yellow200: Colors.Yellow[200],
    yellow300: Colors.Yellow[300],
    yellow400: Colors.Yellow[400],
    yellow500: Colors.Yellow[500],
    yellow600: Colors.Yellow[600],
    yellow700: Colors.Yellow[700],
    yellow800: Colors.Yellow[800],
    yellow900: Colors.Yellow[900],
  },
  leaderboards: {
    bronzeBackground: '#F6C892',
    bronzeText: Colors.Yellow[700],
    silverBackground: '#D9D9D9',
    silverText: Colors.Grey[400],
    goldBackground: '#FFD700',
    goldText: Colors.Yellow[100],
  },
}

export default palette
