import Button from 'core-system/Button'
import CloseIcon from 'core-system/Icons/Actions/Close'
import Text from 'core-system/Text'
import TextField from 'core-system/TextField'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Validators from 'shared/Validators'
import styled from 'styled-components'
import { employeeActions } from 'redux/employee/employeeSlice'

const Email = styled.div`
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
`

const InputContainer = styled.div`
  width: ${(props) => props.theme.pxToRem(600)};
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  align-items: center;
`

const StyledText = styled(Text)`
  text-decoration: underline;
  color: ${(props) => props.theme.palette.primary.pink400};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const Close = styled.div`
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.3125rem 0 0 0.5rem;
  color: ${(props) => props.theme.palette.text.secondary};
  opacity: 0;
  transition: all 0.1s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.primary.pink500};
    opacity: 1;
  }
`

interface OnboardingInviteProps {
  handleNextStep: () => void
}

const OnboardingInvite = React.memo((props: OnboardingInviteProps) => {
  const { handleNextStep } = props
  const [emails, setEmails] = useState([''])
  const [errors, setErrors] = useState([])
  const dispatch = useDispatch()

  const handleEmailChange = (value: string, idx: number) => {
    const emailsCopy = [...emails]
    emailsCopy[idx] = value
    setEmails(emailsCopy)
  }

  const handleAddEmail = () => {
    const emailsCopy = [...emails]
    emailsCopy.push('')
    setEmails(emailsCopy)
  }

  const handleDeleteEmail = (idx: number) => {
    const emailsCopy = [...emails]
    emailsCopy.splice(idx, 1)
    setEmails(emailsCopy)
  }

  const handleInvitations = () => {
    const emailsWithErrors = emails.flatMap((email, idx) => {
      if (email.trim() === '' || !Validators.checkEmail(email)) {
        return idx
      } else {
        return []
      }
    })

    if (emailsWithErrors.length > 0) {
      setErrors(emailsWithErrors)
    } else {
      dispatch(employeeActions.inviteEmployees(emails))
      handleNextStep()
    }
  }

  return (
    <>
      <Text
        variant='body1'
        marginBottom='0.25rem'
        textColor={palette.text.secondary}
      >
        Invite by email address
      </Text>
      {emails.map((_email, idx) => (
        <Email key={idx}>
          <InputContainer>
            <TextField
              key={idx}
              type='email'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEmailChange(e.currentTarget.value, idx)
              }
              invalid={errors.includes(idx)}
              helpText='Please enter a valid email'
            />
          </InputContainer>
          {emails.length > 1 && (
            <Close onClick={() => handleDeleteEmail(idx)}>
              <CloseIcon width={24} height={24} />
            </Close>
          )}
        </Email>
      ))}
      {emails.length < 5 && (
        <StyledText
          variant='hyperlink'
          onClick={handleAddEmail}
          width={pxToRem(115)}
        >
          Add another email
        </StyledText>
      )}
      <ButtonContainer>
        <Button size='large' marginRight='1.5rem' onClick={handleInvitations}>
          Send Invitations
        </Button>
        <Button size='large' variant='tertiary' onClick={handleNextStep}>
          Skip For Now
        </Button>
      </ButtonContainer>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OnboardingInvite.displayName = 'OnboardingInvite'
}

export default OnboardingInvite
