import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import Dropdown from 'core-system/Dropdown'
import FlexContainer from 'core-system/FlexContainer'
import * as FlexIcons from 'core-system/Icons/Flex'
import FlexIcon from 'core-system/Icons/Sidebar/Flex'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import SegmentDropdownUtils from 'core-system/SegmentDropdown/SegmentDropdownUtils'
import SwitchToggle from 'core-system/SwitchToggle'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'
import FormattingUtils from 'shared/FormattingUtils'

const programOptions = [
  {
    icon: <FlexIcon width={32} height={32} color={palette.text.secondary} />,
    title: 'Flex Service Providers',
    subtitle:
      'Allow commuters to use their budget on any selected service provider',
    type: 'flex',
  },
  {
    icon: (
      <FlexIcons.Gas width={36} height={36} color={palette.text.secondary} />
    ),
    title: 'Gas',
    subtitle: 'Allow commuters to use their budget to pay for gas',
    type: 'gas',
  },
]

const taxSavingOptions = (irsTaxLimit: number) => ({
  transit: {
    icon: (
      <FlexIcons.Transit
        width={36}
        height={36}
        color={palette.text.secondary}
      />
    ),
    title: 'Transit',
    subtitle:
      'Allow commuters to pay for public transportation, subway, train, and bus using their mobility budget.',
    line3: `Maximum amount is ${FormattingUtils.formatDollar(irsTaxLimit)}.`,
    type: 'transit',
  },
  parking: {
    icon: (
      <FlexIcons.Parking
        width={36}
        height={36}
        color={palette.text.secondary}
      />
    ),
    title: 'Parking',
    subtitle: 'Allow commuters to pay for parking using their mobility budget.',
    line3: `Maximum amount of ${FormattingUtils.formatDollar(irsTaxLimit)}.`,
    type: 'parking',
  },
})

const defaultActiveOptions = {
  flex: true,
  transit: true,
  gas: true,
  parking: true,
}

const OptionText = styled.div`
  margin-left: 1rem;
`

const IconContainer = styled.div`
  min-width: 4rem;
  height: 4rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border-radius: ${(props) => props.theme.pxToRem(13)};
  display: flex;
  justify-content: center;
  align-items: center;
`

interface FlexPreviewModalProps {
  open: boolean
  closeModal: () => void
  segmentId?: string
}

const FlexPreviewModal = React.memo((props: FlexPreviewModalProps) => {
  const { open, closeModal, segmentId } = props
  const navigate = useNavigate()
  const location = useLocation()

  const allSegments = useSelector(
    (state: AppState) => state.employer.allSegments
  )
  const allPrograms = useSelector((state: AppState) => state.flex.flexPrograms)
  const { irsTaxLimit } = useSelector(
    (state: AppState) => state.application.properties
  )

  const [currentSegment, setCurrentSegment] = useState(null)
  const [activeOptions, setActiveOptions] = useState(defaultActiveOptions)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setCurrentSegment(segmentId)
  }, [segmentId])

  const handleOptionChange = (option: string) => {
    setActiveOptions({
      ...activeOptions,
      [option]: !activeOptions[option],
    })
    SegmentService.track('program-option-toggle', {
      [option === 'flex' ? 'flexProviders' : option]: !activeOptions[option],
    })
  }

  const handleNext = () => {
    if (!currentSegment) {
      setHasError(true)
    } else {
      navigate(`/flex/${currentSegment}/activation`, {
        state: {
          from: location.pathname,
          activeOptions,
          currentSegment,
        },
      })
      closeModal()
    }
  }

  return (
    <Modal open={open} onClose={closeModal} width={pxToRem(826)}>
      <ModalHeader title='New Flex Program' />
      <ModalBody padding='1.5rem 3.25rem 3.25rem'>
        <Dropdown
          items={SegmentDropdownUtils.segmentDropdownItems(
            allSegments || [],
            allPrograms || []
          )}
          active={currentSegment}
          itemCallback={({ id }) => setCurrentSegment(id)}
          width='100%'
          label='Select a Group of Commuters:'
          placeholder={!currentSegment ? '--' : null}
          error={hasError && !currentSegment}
          errorMsg='Select a Valid Group'
        />
        <Divider margin='1.5rem 0' />
        <Text
          variant='body1'
          marginBottom='1rem'
          textColor={palette.text.secondary}
        >
          Select the options you would like to offer commuters as part of your
          Flex program:
        </Text>
        {programOptions.map((option, idx) => (
          <React.Fragment key={option.type}>
            {idx !== 0 && <Divider margin='1.5rem 0' />}
            <FlexContainer alignItems='center' width='100%'>
              <IconContainer>{option.icon}</IconContainer>
              <OptionText>
                <Text variant='action4' marginBottom='0.5rem'>
                  {option.title}
                </Text>
                <Text variant='body1' marginRight='1rem'>
                  {option.subtitle}
                </Text>
              </OptionText>
              <SwitchToggle
                marginLeft='auto'
                active={activeOptions[option.type]}
                onClick={() => handleOptionChange(option.type)}
              />
            </FlexContainer>
          </React.Fragment>
        ))}
        <Divider margin='1.5rem 0' />
        <Text variant='action3'>Tax Savings Options</Text>
        <Text variant='body1' mt={'0.5rem'} mb={'1.5rem'}>
          Employers save on payroll taxes as these benefits provided do no need
          to be added to commuters&apos;s taxable income. On average, save about
          $48 per participating commuter.
        </Text>
        <FlexContainer alignItems='center' width='100%'>
          <IconContainer>
            {taxSavingOptions(irsTaxLimit).transit.icon}
          </IconContainer>
          <OptionText>
            <Text variant='action4' marginBottom='0.5rem'>
              {taxSavingOptions(irsTaxLimit).transit.title}
            </Text>
            <Text variant='body1' marginRight='1rem'>
              {taxSavingOptions(irsTaxLimit).transit.subtitle}
            </Text>
            <Text variant='body1' marginRight='1rem'>
              {taxSavingOptions(irsTaxLimit).transit.line3}
            </Text>
          </OptionText>
          <SwitchToggle
            marginLeft='auto'
            active={activeOptions[taxSavingOptions(irsTaxLimit).transit.type]}
            onClick={() =>
              handleOptionChange(taxSavingOptions(irsTaxLimit).transit.type)
            }
          />
        </FlexContainer>
        <Divider margin='1.5rem 0' />
        <FlexContainer alignItems='center' width='100%'>
          <IconContainer>
            {taxSavingOptions(irsTaxLimit).parking.icon}
          </IconContainer>
          <OptionText>
            <Text variant='action4' marginBottom='0.5rem'>
              {taxSavingOptions(irsTaxLimit).parking.title}
            </Text>
            <Text variant='body1' marginRight='1rem'>
              {taxSavingOptions(irsTaxLimit).parking.subtitle}
            </Text>
            <Text variant='body1' marginRight='1rem'>
              {taxSavingOptions(irsTaxLimit).parking.line3}
            </Text>
          </OptionText>
          <SwitchToggle
            marginLeft='auto'
            active={activeOptions[taxSavingOptions(irsTaxLimit).parking.type]}
            onClick={() =>
              handleOptionChange(taxSavingOptions(irsTaxLimit).parking.type)
            }
          />
        </FlexContainer>
      </ModalBody>
      <ModalFooter>
        <Button variant='tertiary' onClick={closeModal}>
          Cancel
        </Button>
        <Button marginLeft='1rem' onClick={handleNext}>
          Next
        </Button>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FlexPreviewModal.displayName = 'FlexPreviewModal'
}

export default FlexPreviewModal
