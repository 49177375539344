import Button from 'core-system/Button'
import ColumnView, { Column } from 'core-system/ColumnView'
import FlexContainer from 'core-system/FlexContainer'
import { QTF } from 'core-system/Icons/Sidebar'
import Loading from 'core-system/Loading'
import * as Program from 'core-system/Program'
import ProgramOption from 'core-system/Program/ProgramOption'
import ProgramPriceCoverage from 'core-system/Program/ProgramPriceCoverage'
import SegmentDropdownUtils from 'core-system/SegmentDropdown/SegmentDropdownUtils'
import ServiceProviderSelectorModal from 'core-system/ServiceProvider/ServiceProviderSelectorModal'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { flexActions } from 'redux/flex/flexSlice'
import { FlexActiveOptions } from 'redux/flex/flexTypes'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import DateUtils from 'shared/DateUtils'
import useQueryParam from 'shared/Hooks/useQueryParam'
import styled from 'styled-components'
import FlexUtils from '../Shared/FlexUtils'
import FlexActivationSummary from './components/FlexActivationSummary'
import FormattingUtils from 'shared/FormattingUtils'

const defaultActiveOptions = {
  flex: true,
  transit: true,
  gas: true,
  parking: true,
}

const nextMonth = () => {
  return {
    val: 'next-month',
    text: DateUtils.getNextXMonths(1, 1)[0].text,
  }
}
const StyledLink = styled.a`
  ${(props) => props.theme.typography.body2};
  color: ${(props) => props.theme.palette.primary.pink400};
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

type LocationState = {
  from: string
  activeOptions: FlexActiveOptions
}

const FlexActivationView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const state = location.state as LocationState

  const segmentsMap = useSelector(
    (state: AppState) => state.employer.segmentsMap
  )
  const allSegments = useSelector(
    (state: AppState) => state.employer.allSegments
  )
  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )
  const merchantsWithTransit = useSelector(
    (state: AppState) => state.programs.merchantsWithTransit
  )
  const allPrograms = useSelector((state: AppState) => state.flex.flexPrograms)
  const availableMerchants = useSelector(
    (state: AppState) => state.flex.availableMerchants
  )

  const { irsTaxLimit } = useSelector(
    (state: AppState) => state.application.properties
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [startDate, setStartDate] = useState(nextMonth)
  const [budget, setBudget] = useState(FlexUtils.recBudget)
  const [errorState, setErrorState] = useState(FlexUtils.defaultErrorState)
  const [availableMerchantIds, setAvailableMerchantIds] = useState<string[]>([])
  const [activeMerchantIds, setActiveMerchantIds] = useState<string[]>([])
  const [availableTransitMerchantIds, setAvailableTransitMerchantIds] =
    useState<string[]>([])
  const [activeOptions, setActiveOptions] = useState(
    state && state.activeOptions ? state.activeOptions : defaultActiveOptions
  )

  useEffect(() => {
    dispatch(
      flexActions.getFlexRecommendations({
        segmentId,
      })
    )
  }, [dispatch, segmentId])

  useEffect(() => {
    if (availableMerchants && merchantsMap) {
      const flexMerchantIds = availableMerchants.flex.map((m) => m.id)
      const transitMerchantIds = availableMerchants.transit.map((m) => m.id)

      setAvailableMerchantIds(flexMerchantIds)
      setActiveMerchantIds(flexMerchantIds)
      setAvailableTransitMerchantIds(
        FlexUtils.getFlexTransitProviders(
          transitMerchantIds,
          merchantsWithTransit,
          segmentsMap[segmentId].metroAreas
        )
      )
    }
  }, [
    availableMerchants,
    merchantsMap,
    merchantsWithTransit,
    segmentsMap,
    segmentId,
  ])

  const handleOptionToggle = (type: string) => {
    setActiveOptions((prevState) => {
      return {
        ...prevState,
        [type]: !prevState[type],
      }
    })
  }

  const handleActivate = () => {
    const checkErrors = {
      budget: !budget && true,
      flex:
        availableMerchants.flex.length > 0 &&
        activeMerchantIds.length === 0 &&
        activeOptions.flex &&
        true,
    }

    const hasNoErrors = Object.keys(checkErrors).every(
      (key) => !checkErrors[key]
    )

    if (hasNoErrors) {
      const startDateFormatted = moment(startDate.text, 'MMMM Do YYYY').format()

      const merchants = []
      if (activeOptions.flex) merchants.push(...activeMerchantIds)
      if (activeOptions.transit) {
        const transitMerchantIds = availableMerchants.transit.map((m) => m.id)
        merchants.push(...transitMerchantIds)
      }

      dispatch(
        flexActions.createFlexProgram({
          startDate: startDateFormatted,
          endDate: null,
          segment: segmentId,
          name: 'flex',
          type: 'FLEX',
          budget: budget,
          gas: activeOptions.gas,
          parking: activeOptions.parking,
          transit: activeOptions.transit,
          merchants: merchants,
        })
      )
      SegmentService.track('programs-action-click', {
        action: 'activate',
        programType: 'flex',
        segmentName: segmentsMap[segmentId].name,
        startDate: moment(startDateFormatted).format('DD-MM-YYYY'),
        budget: budget,
        flexProviders:
          activeMerchantIds.length > 0 && activeOptions.flex
            ? activeMerchantIds.map((m) => merchantsMap[m]?.name)
            : false,
        gas: activeOptions.gas,
        parking: activeOptions.parking,
        transit: activeOptions.transit,
        oneTime: null,
        shared: null,
        leasing: null,
      })
    } else {
      setErrorState(checkErrors)
      dispatch(
        notificationsActions.generalPageError(
          'Make sure a valid budget exists and there is at least one service provider selected for each active option'
        )
      )
    }
  }

  const handleProviderChange = (newActiveMerchants: string[]) => {
    setActiveMerchantIds(newActiveMerchants)
  }

  const handleDateChange = (val: string, text: string, initial: boolean) => {
    setStartDate(initial ? nextMonth : { val, text })
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
    SegmentService.track('modes-modal-toggle', {
      action: 'open',
      state: 'edit',
      location: 'flex-providers',
    })
  }

  const handleSegmentChange = (newSegmentId: string) => {
    if (newSegmentId !== segmentId) {
      navigate(`/flex/${newSegmentId}/activation`)
      SegmentService.track('baseOptions-toggle-action', {
        actionType: 'segment-name',
      })
    }
  }

  if (!availableMerchants || !merchantsMap || !allPrograms || !segmentsMap) {
    return <Loading fullPage />
  }

  if (allPrograms[segmentId]) {
    return <Navigate to={`/flex/${segmentId}`} replace />
  }

  const noActiveOptions = Object.keys(activeOptions).every(
    (option) => !activeOptions[option]
  )

  const monthlyGas = Math.min(
    Math.round(((budget ? budget : 0) / FlexUtils.monthlyGas) * 100),
    100
  )
  const monthlyParking = Math.min(
    Math.round(((budget ? budget : 0) / FlexUtils.monthlyParking) * 100),
    100
  )
  const monthlyTransit = Math.min(
    Math.round(((budget ? budget : 0) / FlexUtils.monthlyTransit) * 100),
    100
  )

  const currentYear = new Date().getFullYear()

  return (
    <>
      <ColumnView defaultView>
        <Column>
          <Program.SegmentAndDateConfig
            currentSegment={segmentsMap[segmentId]}
            dropdownItems={SegmentDropdownUtils.segmentDropdownItems(
              allSegments,
              allPrograms
            )}
            onDateChange={handleDateChange}
            onSegmentChange={handleSegmentChange}
          />
          <Program.BudgetSelector
            budget={budget}
            recBudget={FlexUtils.recBudget}
            onChange={(newBudget) => setBudget(newBudget * 100)}
            error={errorState.budget}
            test={true}
          />
          <ProgramOption
            type='flex'
            active={activeOptions.flex}
            activeMerchantIds={activeMerchantIds}
            availableMerchantIds={availableMerchantIds}
            handleToggle={handleOptionToggle}
            handleOpenModal={handleOpenModal}
            error={errorState.flex}
          />
          <ProgramOption
            type='gas'
            active={activeOptions.gas}
            handleToggle={handleOptionToggle}
          >
            <ProgramPriceCoverage
              percentage={monthlyGas}
              title='Monthly Gas Price Covered'
              description={`Your budget will cover ${monthlyGas}% of the average monthly
                  gas spend (${FormattingUtils.formatDollar(
                    FlexUtils.monthlyGas
                  )}) for your commuters.`}
            />
          </ProgramOption>
          <ProgramOption
            type='transit'
            activeMerchantIds={availableTransitMerchantIds}
            availableMerchantIds={availableTransitMerchantIds}
            active={activeOptions.transit}
            handleToggle={handleOptionToggle}
            readOnly
          >
            <ProgramPriceCoverage
              percentage={monthlyTransit}
              title='Tax Savings'
              description={`The ${currentYear} IRS monthly limit for pre-tax transit spending is ${FormattingUtils.formatDollar(
                irsTaxLimit
              )}.&nbsp;`}
              icon={<QTF />}
            >
              <StyledLink
                href='https://www.irs.gov/newsroom/irs-provides-tax-inflation-adjustments-for-tax-year-2023'
                target='_blank'
                rel='noopener noreferrer'
              >
                Click here to learn more.
              </StyledLink>
            </ProgramPriceCoverage>
          </ProgramOption>
          <ProgramOption
            type='parking'
            active={activeOptions.parking}
            handleToggle={handleOptionToggle}
          >
            <ProgramPriceCoverage
              percentage={monthlyParking}
              title='Tax Savings'
              description={`The ${currentYear} IRS monthly limit for pre-tax transit spending is ${FormattingUtils.formatDollar(
                irsTaxLimit
              )}.&nbsp;`}
              icon={<QTF />}
            >
              <StyledLink
                href='https://www.irs.gov/newsroom/irs-provides-tax-inflation-adjustments-for-tax-year-2023'
                target='_blank'
                rel='noopener noreferrer'
              >
                Click here to learn more.
              </StyledLink>
            </ProgramPriceCoverage>
          </ProgramOption>
        </Column>
        <Column>
          <FlexActivationSummary
            startDate={startDate.text}
            currentSegment={segmentsMap[segmentId]}
            budget={budget}
            activeOptions={activeOptions}
            activeMerchantIds={activeMerchantIds}
            availableMerchantIds={availableMerchantIds}
            handleOpenModal={handleOpenModal}
          />
        </Column>
      </ColumnView>
      <Program.Footer>
        <FlexContainer marginLeft='auto'>
          <Button
            variant='tertiary'
            marginRight='1rem'
            onClick={() => navigate(`/flex/${segmentId}`)}
          >
            Cancel
          </Button>
          <Button disabled={noActiveOptions} onClick={handleActivate}>
            Activate
          </Button>
        </FlexContainer>
      </Program.Footer>
      <ServiceProviderSelectorModal
        open={isModalOpen}
        title='Available Service Providers'
        closeModal={() => {
          setIsModalOpen(false)
          SegmentService.track('modes-modal-toggle', {
            action: 'close',
            state: 'edit',
            location: 'flex-providers',
          })
        }}
        activeProviders={activeMerchantIds}
        availableProviders={availableMerchantIds}
        onSave={(newActiveProviders: string[]) =>
          handleProviderChange(newActiveProviders)
        }
        modeTypeFilters
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FlexActivationView.displayName = 'FlexActivationView'
}

export default FlexActivationView
