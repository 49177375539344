import {
  InteractionMode,
  ChartOptions,
  ChartEvent,
  LegendElement,
} from 'chart.js'
import palette from 'core-system/Themes/palette'

const defaultChartOptions: ChartOptions<'doughnut'> = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: '55%',
  animation: {
    duration: 0,
    animateRotate: false,
  },
  plugins: {
    legend: {
      display: true,
      position: 'right',
      align: 'center',
      labels: {
        usePointStyle: true,
        boxWidth: 10,
        color: palette.primary.pink500,
        font: {
          family: 'PolySans, sans-serif',
        },
      },
      onClick: function (this: LegendElement<'doughnut'>, e: ChartEvent) {
        if (e.native && 'stopPropagation' in e.native) {
          ;(e.native as MouseEvent).stopPropagation()
        }
      },
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          return `${context.dataset.label || ''}`
        },
        labelTextColor: () => '#fff',
      },
      titleSpacing: 3,
      backgroundColor: palette.primary.pink500,
      displayColors: false,
      caretSize: 0,
      mode: 'nearest' as InteractionMode,
    },
  },
}

const legendHiddenChartOptions: ChartOptions<'doughnut'> = {
  ...defaultChartOptions,
  plugins: {
    ...defaultChartOptions.plugins,
    legend: {
      ...defaultChartOptions.plugins?.legend,
      display: false,
    },
  },
}

export default {
  defaultChartOptions,
  legendHiddenChartOptions,
}
