import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <svg
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
  >
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' fill='#FDEDF0' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='#FFD9E4' />
    <g clipPath='url(#clip0)'>
      <path
        d='M18.5 22.25L12.25 16L18.5 9.75'
        stroke='#410002'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='20' height='20' fill='white' transform='translate(6 6)' />
      </clipPath>
    </defs>
  </svg>,
  'Collapse'
)
