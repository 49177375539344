import AccordionCard, { AccordionCardRow } from 'core-system/AccordionCard'
import Button from 'core-system/Button'
import EditIcon from 'core-system/Icons/Actions/Edit'
import DoubleOverflowIcon from 'core-system/Icons/Misc/DoubleOverflow'
import TextEditor, { RawDraftContentState } from 'core-system/TextEditor'
import TextField from 'core-system/TextField'
import Tooltip from 'core-system/Tooltip'
import pxToRem from 'core-system/utils/pxToRem'
import DOMPurify from 'dompurify'
import { ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { DraggableProps, DragHandleProps } from 'react-beautiful-dnd'
import { Faq } from 'redux/concierge/conciergeTypes'
import SegmentService from 'redux/config/services/SegmentService'
import styled from 'styled-components'

const QuestionText = styled.div`
  ${(props) => props.theme.typography.action3}
`

const AnswerText = styled.div`
  ${(props) => props.theme.typography.body1}
  padding: 0.5rem 4rem;
`

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 1rem;
`

const EditContainer = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease;
  margin-right: 1rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink500};
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const IconsContainer = styled.div`
  color: ${(props) => props.theme.palette.text.secondary};
  display: flex;
`

const OverflowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const EditingContainer = styled.div`
  display: flex;
`

const DeleteButton = styled.div`
  margin-right: 1.5rem;
  padding-right: 1.5rem;
  border-right: 1px solid ${(props) => props.theme.palette.grey.grey300};
`

const EditingContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem 2.5rem;
  width: 75%;
`

const sanitizeMarkup = (html: any) => {
  return {
    __html: DOMPurify.sanitize(html),
  }
}

const getRawDraftContent = (faq: Faq) => {
  const draftJs = faq.answer && htmlToDraft(faq.answer)
  const contentState = draftJs
    ? ContentState.createFromBlockArray(
        draftJs.contentBlocks,
        draftJs.entityMap
      )
    : ContentState.createFromText('')
  return convertToRaw(contentState)
}

const getQuestion = (isNew: boolean, isEdit: boolean, title: string) => {
  if (isNew) {
    return 'New Question & Answer'
  } else if (isEdit) {
    return 'Edit Question & Answer'
  } else {
    return title
  }
}

const rawDraftToString = (raw: RawDraftContentState) => {
  return draftToHtml(raw)
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/&nbsp;/g, ' ')
    .trim()
}

const defaultValidation = {
  question: true,
  answer: true,
}

interface FaqEditCardProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  faq: Faq
  idx: number
  draggableProps: DraggableProps
  dragHandleProps: DragHandleProps
  isDragging: boolean
  handleDelete: (idx: number) => void
  handleUpdate: (faq: Faq, idx: number) => void
  isOpen?: boolean
  isNew?: boolean
}

const FaqEditCard = React.forwardRef<HTMLDivElement, FaqEditCardProps>(
  (props: FaqEditCardProps, ref: React.Ref<HTMLElement>) => {
    const {
      faq,
      idx,
      draggableProps,
      dragHandleProps,
      handleDelete,
      handleUpdate,
      isOpen,
      isNew,
    } = props
    const raw = getRawDraftContent(faq)

    const [question, setQuestion] = useState(faq.question ? faq.question : '')
    const [answer, setAnswer] = useState<RawDraftContentState>(raw)
    const [isTooltipVisible, setIsTooltipVisible] = useState(false)
    const [isEdit, setIsEdit] = useState((isOpen || isNew) && true)
    const [isAccordionCardOpen, setIsAccordionCardOpen] = useState(
      (isOpen || isNew) && true
    )
    const [formValidation, setFormValidation] = useState(defaultValidation)

    const tooltipRef = useRef(null)
    const cardRef = useRef(null)

    useEffect(() => {
      if (isOpen || isNew) {
        cardRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, [isNew, isOpen])

    const handleButtonClick = useCallback(
      (e: SyntheticEvent, type: string) => {
        if (type === 'edit') {
          e.stopPropagation()
          SegmentService.track('faq-item-click', {
            action: 'edit',
          })
        } else if (type === 'cancel') {
          setQuestion(faq.question ? faq.question : '')
          setAnswer(getRawDraftContent(faq))

          if (isNew) {
            handleDelete(idx)
          }
          SegmentService.track('faq-item-click', {
            action: 'cancel',
          })
        } else if (type === 'delete') {
          handleDelete(idx)
          SegmentService.track('faq-item-click', {
            action: 'delete',
          })
        }

        setIsEdit((prevState) => !prevState)
        setIsAccordionCardOpen(true)
      },
      [faq, handleDelete, idx, isNew]
    )

    const handleAnswerChange = useCallback(
      (contentState: RawDraftContentState) => {
        setAnswer(contentState)
      },
      [setAnswer]
    )

    const handleDoneEditing = useCallback(() => {
      const isQuestionValid = question.trim() !== ''
      const isAnswerValid = rawDraftToString(answer) !== ''

      if (!isQuestionValid || !isAnswerValid) {
        setFormValidation({
          question: isQuestionValid,
          answer: isAnswerValid,
        })
      } else {
        setIsEdit((prevState) => !prevState)
        setIsAccordionCardOpen(true)

        SegmentService.track('faq-item-click', {
          action: 'update',
        })
        handleUpdate(
          {
            id: faq.id,
            question,
            answer: draftToHtml(answer),
          },
          idx
        )
      }
    }, [question, answer, handleUpdate, idx, faq.id])

    return (
      <div
        ref={cardRef}
        style={isOpen && isEdit ? { paddingTop: '0.25rem' } : {}}
      >
        <AccordionCard
          isDisabled={isEdit}
          isOpen={isAccordionCardOpen}
          setIsOpen={setIsAccordionCardOpen}
          ref={ref}
          {...draggableProps}
        >
          <AccordionCardRow minHeight={'4rem'} allowHoverEffect={false}>
            <AccordionHeader>
              <QuestionText>
                {getQuestion(isNew, isEdit, faq.question)}
              </QuestionText>
              {isEdit ? (
                <EditingContainer {...dragHandleProps} isDragDisabled={true}>
                  {!isNew && (
                    <DeleteButton>
                      <Button
                        size={'small'}
                        variant={'cancel'}
                        onClick={(e) => handleButtonClick(e, 'delete')}
                      >
                        Delete
                      </Button>
                    </DeleteButton>
                  )}
                  <Button
                    size={'small'}
                    variant={'tertiary'}
                    marginRight={'1rem'}
                    onClick={(e) => handleButtonClick(e, 'cancel')}
                  >
                    Cancel
                  </Button>
                  <Button size={'small'} onClick={handleDoneEditing}>
                    Done
                  </Button>
                </EditingContainer>
              ) : (
                <IconsContainer>
                  <EditContainer
                    ref={tooltipRef}
                    onMouseEnter={() => setIsTooltipVisible(true)}
                    onMouseLeave={() => setIsTooltipVisible(false)}
                    onClick={(e) => handleButtonClick(e, 'edit')}
                    id='faq-edit-icon'
                  >
                    <EditIcon />
                    <Tooltip
                      target={tooltipRef}
                      show={isTooltipVisible}
                      position={'bottom'}
                      marginTop={'0.25rem'}
                    >
                      Edit
                    </Tooltip>
                  </EditContainer>
                  <OverflowContainer
                    onClick={(e) => e.stopPropagation()}
                    {...dragHandleProps}
                  >
                    <DoubleOverflowIcon />
                  </OverflowContainer>
                </IconsContainer>
              )}
            </AccordionHeader>
          </AccordionCardRow>
          <AccordionCardRow allowHoverEffect={false}>
            {isEdit ? (
              <EditingContent>
                <TextField
                  label={'Question'}
                  value={question}
                  onChange={(e) =>
                    setQuestion(e.currentTarget.value.trimStart())
                  }
                  invalid={!formValidation.question && question.trim() === ''}
                  helpText={
                    !formValidation.question && question.trim() === ''
                      ? 'Please enter a question'
                      : ''
                  }
                  data-cy='faq-question-input'
                />
                <TextEditor
                  marginTop={'1.5rem'}
                  label={'Answer'}
                  contentState={answer}
                  maxWidth={pxToRem(772)}
                  oncontentStateChange={handleAnswerChange}
                  invalid={
                    !formValidation.answer && rawDraftToString(answer) === ''
                  }
                  helpText={
                    !formValidation.answer && rawDraftToString(answer) === ''
                      ? 'Please enter an answer'
                      : ''
                  }
                  data-cy='faq-description-input'
                />
              </EditingContent>
            ) : (
              <AnswerText
                dangerouslySetInnerHTML={sanitizeMarkup(faq.answer)}
              />
            )}
          </AccordionCardRow>
        </AccordionCard>
      </div>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FaqEditCard.displayName = 'FaqEditCard'
}

export default FaqEditCard
