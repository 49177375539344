import Theme, { ZIndex, DropShadows } from './Theme'
import pxToRem from '../utils/pxToRem'
import palette from './palette'
import typography from './typography'
import { css, CSSProp } from 'styled-components'
import { ScreenSize } from './Theme'

// ---- Z-Index ----
const zIndex: ZIndex = {
  body: 500,
  loading: 999,
  navigation: 1000,
  modal: 3000,
  dropdowns: 4000,
  tooltip: 5000,
  max: 9000,
}

const dropShadows: DropShadows = {
  bottom: '0px -4px 10px rgba(171, 171, 188, 0.12)',
  top: '0px 4px 8px rgba(171, 171, 188, 0.2)',
  normal: '0px 4px 8px rgba(171, 171, 188, 0.15)',
  selected:
    '0px 3px 5px rgba(171, 171, 188, 0.1), 0px 5px 8px rgba(171, 171, 188, 0.14), 0px 1px 10px rgba(171, 171, 188, 0.12)',
}

// values in rem
// 576px, 768px, 1000px, 1200px
const breakpoints = ['36rem', '48rem', '62.5rem', '75rem']

const screenSize: ScreenSize = {
  xs: '36rem',
  sm: '48rem',
  md: '62.5rem',
  lg: '75rem',
  xl: '90rem',
}

const baseCard = {
  width: '100%',
  padding: '1.5rem',
  backgroundColor: palette.white,
  color: palette.text.primary,
  border: `1px solid ${palette.grey.grey300}`,
  boxShadow: dropShadows.normal,
  borderRadius: '0.9375rem',
}

const scrollbars: Dictionary<CSSProp> = {
  none: css`
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  basic: css`
    overflow: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 1rem;
      opacity: 0.8;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      opacity: 1;
    }
  `,
}

// ---- Theme ----
const Main: Theme = {
  pxToRem,
  zIndex,
  breakpoints,
  dropShadows,
  typography,
  palette,
  baseCard,
  scrollbars,
  screenSize,
}

export default Main
