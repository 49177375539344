import React from 'react'
import { SnackbarProvider, SnackbarContentProps } from 'notistack'
import Notification from 'core-system/Notification'

const SnackbarComponent = React.forwardRef<
  HTMLDivElement,
  SnackbarContentProps
>((props, ref) => (
  <Notification ref={ref} id={props.id as string} message={props} />
))

// Add display names to avoid ESLint warnings
SnackbarComponent.displayName = 'Notification'

const NotificationsProvider = (props: any) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      dense={true}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      preventDuplicate={true}
      autoHideDuration={3000}
      Components={{
        default: SnackbarComponent,
        success: SnackbarComponent,
        error: SnackbarComponent,
        warning: SnackbarComponent,
        info: SnackbarComponent,
      }}
    >
      {props.children}
    </SnackbarProvider>
  )
}

export default NotificationsProvider

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  NotificationsProvider.displayName = 'NotificationsProvider'
}
