import Button from 'core-system/Button'
import { Alert } from 'core-system/Icons/Misc'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Employee } from 'redux/employee/employeeTypes'
import { employerActions } from 'redux/employer/employerSlice'
import styled from 'styled-components'

const ConfirmationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

interface MakeOwnerModalProps {
  closeModal: () => void
  open: boolean
  user: Employee
}

const MakeOwnerModal: React.FC<MakeOwnerModalProps> = (props) => {
  const dispatch = useDispatch()
  const { user, open, closeModal } = props

  const handleMakeOwner = () => {
    dispatch(employerActions.updatePrimaryAcctHolder(user.id))
    closeModal()
  }

  return (
    <Modal open={open} onClose={() => closeModal()} width={pxToRem(450)}>
      <ModalHeader title='Change Account Owner' />
      <ModalBody padding='1.5rem'>
        <ConfirmationContainer>
          <Alert
            color={palette.error.red500}
            height={50}
            width={50}
            style={{ marginBottom: '1rem' }}
          />
          <Text variant='action1'>
            Are you sure you want to make {user.firstName} {user.lastName} the
            account owner?
          </Text>
        </ConfirmationContainer>
      </ModalBody>
      <ModalFooter>
        <Button
          variant='secondary'
          onClick={() => closeModal()}
          width='50%'
          style={{ margin: '1rem' }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleMakeOwner}
          width='50%'
          style={{ margin: '1rem' }}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MakeOwnerModal.displayName = 'MakeOwnerModal'
}

export default MakeOwnerModal
