import Text from 'core-system/Text'
import React from 'react'
import { MapLayers } from 'redux/mapIntelligence/mapIntelligenceTypes'
import styled from 'styled-components'
import { controlPanelLayerName } from './MapIntelligenceStyles'

const availableLayers = ['currentCommuteModes', 'agony']

const Container = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey300};
`

const LayersContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Layers = styled.div`
  display: flex;
  padding: 1rem 2rem;
`

const Layer = styled.div<{ isActive: boolean }>`
  padding: 0.375rem 0.625rem;
  background-color: ${(props) =>
    props.isActive && props.theme.palette.primary.pink400};
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  cursor: pointer;
  color: ${(props) =>
    props.isActive
      ? props.theme.palette.white
      : props.theme.palette.text.primary};

  &:hover {
    background-color: ${(props) =>
      props.isActive
        ? props.theme.palette.primary.pink400
        : props.theme.palette.primary.pink500};
  }
`

const displayLayers = (
  mapLayers: MapLayers,
  activeLayer: string,
  updateActiveLayer: (layer: string) => void
) => {
  const mapLayersCopy = {
    // no geom but need as layer panel (styling is part of employee layer)
    currentCommuteModes: {},
    ...mapLayers,
  }

  return availableLayers.map((layerName: string, idx: number) => {
    if (mapLayersCopy[layerName]) {
      return (
        <Layer
          key={idx}
          isActive={layerName === activeLayer}
          onClick={() => updateActiveLayer(layerName)}
        >
          <Text variant='action4' textColor='unset'>
            {controlPanelLayerName[layerName].title}
          </Text>
        </Layer>
      )
    } else {
      return null
    }
  })
}

interface MapIntelligenceControlPanelProps {
  mapLayers: MapLayers
  activeLayer: string
  updateActiveLayer: (layer: string) => void
}

const MapIntelligenceControlPanel = React.memo(
  (props: MapIntelligenceControlPanelProps) => {
    const { mapLayers, activeLayer, updateActiveLayer } = props

    return (
      <Container>
        <LayersContainer>
          <Layers>
            {displayLayers(mapLayers, activeLayer, updateActiveLayer)}
          </Layers>
        </LayersContainer>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MapIntelligenceControlPanel.displayName = 'MapIntelligenceControlPanel'
}

export default MapIntelligenceControlPanel
