import { employerActions } from 'redux/employer/employerSlice'
import Button from 'core-system/Button'
import Checkbox from 'core-system/Checkbox'
import FlexContainer from 'core-system/FlexContainer'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import { ProgramModal } from 'core-system/Program'
import Table, {
  TableLabelRow,
  TableRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Commuter } from 'redux/employee/employeeTypes'
import { Worksite } from 'redux/employer/employerTypes'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'
import MyCommutersSearch from './MyCommutersSearch'
import { employeeActions } from 'redux/employee/employeeSlice'

const Body = styled.div`
  margin: 1.5rem 0 6rem;
`

const UserContainer = styled.div`
  display: flex;
`

const NameContainer = styled.div`
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`

const EmptyTableRow = styled(TableRow)`
  text-align: center;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem ${(props) => props.theme.pxToRem(52)};
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 22rem);
  box-shadow: ${(props) => props.theme.dropShadows.bottom};
  z-index: ${(props) => props.theme.zIndex.max};
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey300};

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    bottom: 5rem;
    width: calc(100% - 4rem);
    border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
  }
`

const getTableRows = (
  filteredCommuters: Commuter[],
  sortByFilter: string,
  selectedCommuters: string[],
  worksites: Worksite[],
  handleCommuterSelect: (
    e: React.MouseEvent<HTMLDivElement>,
    id: string,
    selectedCommuters: string[]
  ) => void
) => {
  return filteredCommuters
    .sort((a, b) =>
      sortByFilter === 'desc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name)
    )
    .map((pendingCommuter, idx) => {
      const relevantWorksite = worksites.find(
        (wrk) => wrk.id === pendingCommuter.worksiteId
      )
      const worksiteName = relevantWorksite ? relevantWorksite.alias : 'unknown'

      return (
        <TableRow key={idx} display='flex'>
          <TableRowContents isClickable={false} autoResize={false}>
            <Checkbox
              checked={selectedCommuters.includes(pendingCommuter.id)}
              onClick={(e) =>
                handleCommuterSelect(e, pendingCommuter.id, selectedCommuters)
              }
              marginRight='2%'
              data-cy='segments-pending-employees-checkbox'
            />
            <TableRowCell width='25%' equalSize={false}>
              <UserContainer>
                <PlatformSvg
                  folder='users'
                  variant='userBasic'
                  width={40}
                  height={40}
                />
                <NameContainer>
                  <Text variant='action4' hideOverflow>
                    {pendingCommuter.name}
                  </Text>
                  <Text variant='body2' textColor={palette.text.placeholder}>
                    {pendingCommuter.employeeCorporateId
                      ? `#${pendingCommuter.employeeCorporateId}`
                      : ''}
                  </Text>
                </NameContainer>
              </UserContainer>
            </TableRowCell>
            <TableRowCell width='30%' equalSize={false} icon='location'>
              {worksiteName}
            </TableRowCell>
            <TableRowCell width='30%' equalSize={false}>
              {pendingCommuter.email}
            </TableRowCell>
          </TableRowContents>
        </TableRow>
      )
    })
}

interface MyCommutersPending {
  activeSegment: string
  pendingCommuters: Commuter[]
  showPending: boolean
  setShowPending: (showPending: boolean) => void
}

const MyCommutersPending = React.memo((props: MyCommutersPending) => {
  const { activeSegment, pendingCommuters, setShowPending } = props

  const dispatch = useDispatch()

  const {
    segmentUpdateFlag,
    worksites,
    employeesDeclinedFlag,
    employeesApprovedFlag,
    allEmployeeSegmentId,
  } = useSelector((state: AppState) => state.employer)

  const [selectedCommuters, setSelectedCommuters] = useState([])
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false)
  const [filteredCommuters, setFilteredCommuters] = useState(pendingCommuters)
  const [sortByFilter, setSortByFilter] = useState('desc')

  useEffect(() => {
    if (employeesDeclinedFlag) {
      dispatch(employerActions.toggleDeclineInactiveEmployeesFlag())
      setIsDeclineModalOpen(false)
    }
  }, [dispatch, employeesDeclinedFlag, allEmployeeSegmentId])

  useEffect(() => {
    if (employeesApprovedFlag) {
      dispatch(
        employeeActions.getMyCommuters({ segment: allEmployeeSegmentId })
      )
      dispatch(employerActions.getSegments())
      dispatch(employerActions.toggleForceActivateInactiveEmployeesFlag())
      dispatch(employeeActions.getCommuters())
    }
  }, [dispatch, employeesApprovedFlag, allEmployeeSegmentId])

  useLayoutEffect(() => {
    setFilteredCommuters(pendingCommuters)
    setSelectedCommuters([])
  }, [pendingCommuters])

  const handleCancel = useCallback(() => {
    setSelectedCommuters([])
    setShowPending(false)
    SegmentService.track('commuters-group-manage', {
      action: 'cancel',
      numEmployees: null,
    })
  }, [setShowPending])

  useEffect(() => {
    if (segmentUpdateFlag) {
      handleCancel()
      dispatch(employerActions.toggleSegmentUpdateFlag())
    }
  }, [dispatch, handleCancel, segmentUpdateFlag])

  const selectAll = useCallback(
    (filteredCommuters: Commuter[], selectedCommuters: string[]) => {
      if (selectedCommuters.length === filteredCommuters.length) {
        setSelectedCommuters([])
      } else {
        const ids = filteredCommuters.map((commuters) => commuters.id)
        setSelectedCommuters(ids)
      }
      SegmentService.track('commuters-group-manage', {
        action: 'select-all',
        numEmployees: null,
      })
    },
    []
  )

  const handleCommuterSelect = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement>,
      id: string,
      selectedCommuters: string[]
    ) => {
      e.stopPropagation()
      if (selectedCommuters.includes(id)) {
        const commuterCopy = [...selectedCommuters]
        const idx = commuterCopy.indexOf(id)
        commuterCopy.splice(idx, 1)
        setSelectedCommuters(commuterCopy)
      } else {
        setSelectedCommuters((prev) => [...prev, id])
      }
    },
    []
  )

  const handleApprove = (
    selectedCommuters: string[],
    activeSegment: string
  ) => {
    dispatch(
      employerActions.forceActivateInactiveEmployees({
        segmentId: activeSegment,
        employees: selectedCommuters,
      })
    )
    SegmentService.track('commuters-group-manage', {
      action: 'activate',
      numEmployees: selectedCommuters.length,
    })
  }

  const handleDecline = (selectedCommuters: string[]) => {
    dispatch(
      employerActions.declineInactiveEmployees({
        segmentId: activeSegment,
        employees: selectedCommuters,
      })
    )
    SegmentService.track('commuters-group-manage', {
      action: 'decline',
      numEmployees: selectedCommuters.length,
    })
  }

  if (!pendingCommuters || !filteredCommuters || employeesDeclinedFlag) {
    return <Loading isCard={false} />
  }

  return (
    <>
      <MyCommutersSearch
        myCommuters={pendingCommuters}
        filteredCommuters={filteredCommuters}
        setFilteredPendingCommuters={setFilteredCommuters}
        sortByFilter={sortByFilter}
        setSortByFilter={setSortByFilter}
        isAdd={false}
        isPending={true}
      />
      <Body>
        <Table hasHeader={false} maxRows={10} loadMoreAnimation={false}>
          <TableLabelRow padding='1rem 1.5rem' overflow='hidden'>
            {filteredCommuters.length > 0 && (
              <Checkbox
                checked={filteredCommuters.length === selectedCommuters.length}
                onClick={() => selectAll(filteredCommuters, selectedCommuters)}
                marginRight='2%'
                disabled={filteredCommuters.length === 0}
                data-cy='select-all-commuters-checkbox'
              />
            )}
            <TableRowCell width='25%' equalSize={false}>
              Commuter
            </TableRowCell>
            <TableRowCell width='30%' equalSize={false}>
              Worksite
            </TableRowCell>
            <TableRowCell width='30%' equalSize={false}>
              Email
            </TableRowCell>
          </TableLabelRow>
          {filteredCommuters.length > 0 ? (
            getTableRows(
              filteredCommuters,
              sortByFilter,
              selectedCommuters,
              worksites,
              handleCommuterSelect
            )
          ) : (
            <EmptyTableRow>No Results Found</EmptyTableRow>
          )}
        </Table>
      </Body>
      <Footer>
        <FlexContainer
          alignItems='center'
          justifyContent='space-between'
          width='100%'
        >
          <Text variant='action1'>{selectedCommuters.length} Selected</Text>
          <FlexContainer>
            <Button
              variant='tertiary'
              marginRight='1rem'
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={selectedCommuters.length === 0}
              onClick={() => setIsDeclineModalOpen(true)}
              marginRight='0.5rem'
              variant='cancel'
            >
              Decline
            </Button>
            <Button
              disabled={selectedCommuters.length === 0}
              onClick={() => handleApprove(selectedCommuters, activeSegment)}
            >
              Approve
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Footer>
      <ProgramModal
        title='Decline Commuters'
        description={`Are you sure you want to decline ${
          selectedCommuters.length
        } commuter${RandomUtils.pluralCheck(
          selectedCommuters.length
        )}? This will remove them from the platform!`}
        buttonVariant='cancel'
        buttonText='Decline'
        open={isDeclineModalOpen}
        closeModal={() => setIsDeclineModalOpen(false)}
        buttonCallback={() => handleDecline(selectedCommuters)}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommutersPending.displayName = 'MyCommutersPending'
}

export default MyCommutersPending
