import React from 'react'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import thinkingAnimation from './thinking.lottie'
import styled from 'styled-components'

const Container = styled.div`
  width: 300px;
  height: 150px;
  background: transparent;
  position: absolute;
  top: -80px;
  left: -90px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const ThinkingAnimation = React.memo(() => {
  return (
    <Container>
      <DotLottieReact
        src={thinkingAnimation}
        loop
        autoplay
        renderConfig={{ autoResize: true }}
      />
    </Container>
  )
})

export default ThinkingAnimation
