import Arrow from 'core-system/Icons/MapIntelligence/ArrowPrimary'
import BasicClock from 'core-system/Icons/MapIntelligence/BasicClock'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import { Feature } from 'geojson'
import React from 'react'
import { Worksite } from 'redux/employer/employerTypes'
import styled from 'styled-components'

const commuteTypes = {
  mega_commuter: {
    icon: 'megaCommuterDriving',
    title: 'Mega Commuters',
    desc: 'Super + Long',
    bg: '#ffebf8',
  },
  long_distance_commuter: {
    icon: 'longDistancePurple',
    title: 'Long Distance',
    desc: '50 miles (65km) one way',
    bg: '#F0F0FF',
  },
  super_commuter: {
    icon: 'superCommuterTransit',
    title: 'Super Commuters',
    desc: '1.5hrs one way',
    bg: '#DCFCFA',
  },
}

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.3125rem 0.4375rem 0 0.4375rem;
  font-family: PolySans, sans-serif;
  color: ${(props) => props.theme.palette.text.primary};
  width: ${(props) => props.theme.pxToRem(293)};
`

const PopupTop = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.25rem;
`

const SVGBackground = styled.div<{ bg: string }>`
  width: ${(props) => props.theme.pxToRem(60)};
  height: ${(props) => props.theme.pxToRem(60)};
  background-color: ${(props) => props.bg};
  border-radius: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AgonyMainStats = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0.875rem;
`

const MainStatContainer = styled.div`
  display: flex;
`

const ArrowContainer = styled(Arrow)`
  margin: 0 0.375rem 0 0;
  transform: rotate(45deg);
`

const BasicClockContainer = styled(BasicClock)`
  margin: 0 0.3125rem 0 0.5rem;
`

const AgonyTotalCases = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
`

const AgonyDesc = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
`

const CountyLocationContainer = styled.div`
  display: flex;
  align-items: center;
`

const CountyLocation = styled.div`
  color: ${(props) => props.theme.palette.primary.pink400};
  font-size: 0.875rem;
  font-weight: 500;
  height: 1.1875rem;
`

interface PopupProps {
  feature: Feature
  allWorksites?: Worksite[]
}

const PopupAgony = React.memo((props: PopupProps) => {
  const { feature } = props
  const properties = commuteTypes[feature.properties.commuterType]
  const distance =
    !feature.properties.distance || feature.properties.distance === 'null'
      ? '-'
      : (feature.properties.distance / 1609).toFixed(0)
  const time =
    !feature.properties.duration || feature.properties.duration === 'null'
      ? '-'
      : (feature.properties.duration / 60).toFixed(0)

  return (
    <PopupContainer>
      <PopupTop>
        <SVGBackground bg={properties.bg}>
          <PlatformSvg
            folder='metrics'
            variant={properties.icon}
            width={40}
            height={40}
          />
        </SVGBackground>
        <AgonyMainStats>
          <MainStatContainer>
            <AgonyTotalCases>{properties.title}</AgonyTotalCases>
          </MainStatContainer>
          <AgonyDesc>{properties.desc}</AgonyDesc>
          <CountyLocationContainer>
            <ArrowContainer height={11} width={10} />
            <CountyLocation>{distance} miles</CountyLocation>
            <BasicClockContainer height={12} width={12} />
            <CountyLocation>{time} mins to work</CountyLocation>
          </CountyLocationContainer>
        </AgonyMainStats>
      </PopupTop>
    </PopupContainer>
  )
})

PopupAgony.displayName = 'PopupAgony'

export default PopupAgony
