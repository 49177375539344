import React from 'react'
import styled from 'styled-components'
import { buildForwardingComponent } from '../utils/buildComponent'
import { space, SpaceProps } from 'styled-system'

const Input = styled.input<{
  height?: string
  width?: string
  borderRadius?: string
}>`
  ${(props) => props.theme.typography.action6};
  color: ${(props) => props.theme.palette.grey.grey10};
  background-color: ${(props) => props.theme.palette.white};
  padding: ${(props) => props.theme.pxToRem(10)} 1rem;
  border: 0.0625rem solid ${(props) => props.theme.palette.grey.grey10};
  border-radius: ${(props) => props.borderRadius || '4px'};
  min-width: 2.5rem;
  outline: none;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};

  &::placeholder {
    color: ${(props) => props.theme.palette.grey.grey10};
  }

  &:hover {
    color: ${(props) => props.theme.palette.text.secondary};
    border-color: ${(props) => props.theme.palette.text.secondary};

    &::placeholder {
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }

  &:focus {
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.white};
    border-color: ${(props) => props.theme.palette.text.secondary};
  }

  &:disabled {
    color: ${(props) => props.theme.palette.text.disabled};
    background-color: ${(props) => props.theme.palette.grey.grey200};
    border-color: ${(props) => props.theme.palette.grey.grey300};
    pointer-events: none;
  }

  ${space}
`

export interface CoreInputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    SpaceProps {
  value?: string
  onenter?(evt: React.KeyboardEvent<HTMLInputElement>): void
  width?: string
  height?: string
  borderRadius?: string
}

const CoreInput = React.forwardRef<HTMLInputElement, CoreInputProps>(
  (props: CoreInputProps, parentRef: React.Ref<HTMLInputElement>) => {
    const { onKeyDown, onenter, width, height, borderRadius, ...rest } = props
    const ref = parentRef
    const fallbackRef = React.useRef(null)

    const _onKeyDown = React.useCallback(
      (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.keyCode === 13) {
          if (onenter) {
            onenter(evt)
          }
          return
        }
        if (onKeyDown) {
          onKeyDown(evt)
        }
      },
      [onKeyDown, onenter]
    )

    return (
      <Input
        ref={ref || fallbackRef}
        {...rest}
        width={width}
        height={height}
        borderRadius={borderRadius}
        onKeyDown={_onKeyDown}
      />
    )
  }
)

CoreInput.displayName = 'CoreInput'
export default buildForwardingComponent<HTMLInputElement, CoreInputProps>(
  CoreInput
)
