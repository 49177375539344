import { employerActions } from 'redux/employer/employerSlice'
import Button from 'core-system/Button'
import Checkbox from 'core-system/Checkbox'
import Chip, { ChipVariants } from 'core-system/Chip'
import FlexContainer from 'core-system/FlexContainer'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import Table, {
  TableLabelRow,
  TableRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { MyCommuter } from 'redux/employee/employeeTypes'
import { Segment } from 'redux/employer/employerTypes'
import FormattingUtils from 'shared/FormattingUtils'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'
import MyCommuterDetailsModal from './MyCommuterDetailsModal'
import MyCommutersSearch from './MyCommutersSearch'
import { employeeActions } from 'redux/employee/employeeSlice'
import {
  ORCA_PILOT_GROUP_NAME,
  SSA_COMPANY_ID,
} from 'redux/config/services/Constants'

const Body = styled.div`
  margin: 1.5rem 0 6rem;
`

const UserContainer = styled.div`
  display: flex;
`

const NameContainer = styled.div`
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`

const Programs = styled.div`
  display: flex;
`

const EmptyTableRow = styled(TableRow)`
  text-align: center;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem ${(props) => props.theme.pxToRem(52)};
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 22rem);
  box-shadow: ${(props) => props.theme.dropShadows.bottom};
  z-index: ${(props) => props.theme.zIndex.max};
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey300};

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    bottom: 5rem;
    width: calc(100% - 4rem);
    border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
  }
`

const SegmentsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  align-items: center;
`

const InputORCAStyled = styled.input`
  width: 90%;
`

const getSegments = (
  segmentsEnrolled: string[],
  segmentsMap: Dictionary<Segment>
) => {
  const filteredSegments = segmentsEnrolled.filter(
    (segmentId) =>
      segmentsMap[segmentId] &&
      segmentsMap[segmentId].type !== 'ALL' &&
      !segmentsMap[segmentId].archived
  )
  const segmentLen = filteredSegments.length

  if (segmentLen === 0) {
    return '-'
  } else if (segmentLen <= 2) {
    return filteredSegments.map((segmentId) => (
      <Chip variant='filter' isInteractive={false} key={segmentId}>
        {segmentsMap[segmentId].name === 'All Employees'
          ? 'All Commuters'
          : segmentsMap[segmentId].name}
      </Chip>
    ))
  } else {
    return (
      <>
        {filteredSegments.slice(0, 2).map((segmentId) => (
          <Chip variant='filter' isInteractive={false} key={segmentId}>
            {segmentsMap[segmentId].name === 'All Employees'
              ? 'All Commuters'
              : segmentsMap[segmentId].name}
          </Chip>
        ))}
        <Text
          variant='action4'
          marginLeft='0.5rem'
          textColor={palette.text.secondary}
        >
          +{segmentLen - 2}
        </Text>
      </>
    )
  }
}

const getCurrentBalance = (commuter: MyCommuter) => {
  const { remainingProgramBudget, currentIncentives, fleetRemaining } = commuter
  return (
    Number(remainingProgramBudget) +
    Number(currentIncentives) +
    Number(fleetRemaining)
  ) // where null == 0
}

export const getStatusChipAttributes = (commuter: MyCommuter) => {
  const { hasRegistered, hasInvite } = commuter
  if (hasRegistered) {
    return {
      text: 'REGISTERED',
      variant: 'grey',
    }
  } else if (hasInvite) {
    return {
      text: 'INVITED',
      variant: 'yellow',
    }
  } else {
    return {
      text: 'NEEDS INVITE',
      variant: 'red',
    }
  }
}

const getTableRows = (
  filteredCommuters: MyCommuter[],
  segmentsMap: Dictionary<Segment>,
  sortByFilter: string,
  editOrAdd: boolean,
  selectedCommuters: string[],
  handleCommuterSelect: (
    e: React.MouseEvent<HTMLDivElement>,
    id: string,
    selectedCommuters: string[]
  ) => void,
  activeCommuters: Dictionary<MyCommuter>,
  onRowClick: (id: string) => void,
  hasORCA: boolean,
  onChangeORCANumber: (newOrcaNumber: string, commuterId: string) => void,
  userOrcaList: any,
  isAdd: boolean
) => {
  return [...filteredCommuters]
    .sort((a, b) =>
      sortByFilter === 'desc'
        ? b.totalTrips - a.totalTrips
        : a.totalTrips - b.totalTrips
    )
    .map((commuter, idx) => {
      const isDisabled = activeCommuters[commuter.id] && true
      const statusChipAttributes = getStatusChipAttributes(commuter)
      const orcaNumber = userOrcaList[commuter.id] || ''
      const handleOnchangeORCAInput = (e) => {
        onChangeORCANumber(e.target.value, commuter.id)
      }

      return (
        <TableRow overflow={'hidden'} key={idx} display='flex'>
          <TableRowContents
            onClick={(e) => {
              const target = e.target as HTMLElement
              if (target.tagName.toLowerCase() === 'input') {
                e.stopPropagation()
                return
              }
              onRowClick(commuter.id)
            }}
            isClickable={true}
            autoResize={false}
          >
            {editOrAdd && (
              <Checkbox
                checked={selectedCommuters.includes(commuter.id)}
                onClick={(e) =>
                  !isDisabled &&
                  handleCommuterSelect(e, commuter.id, selectedCommuters)
                }
                marginRight='2%'
                disabled={isDisabled}
              />
            )}
            <TableRowCell width={hasORCA ? '20%' : '25%'} equalSize={false}>
              <UserContainer>
                <PlatformSvg
                  folder='users'
                  variant='userBasic'
                  width={40}
                  height={40}
                />
                <NameContainer>
                  <Text variant='action4' hideOverflow>
                    {commuter.name}
                  </Text>
                  <Text variant='body2' textColor={palette.text.placeholder}>
                    {commuter.employeeCorporateId
                      ? `#${commuter.employeeCorporateId}`
                      : ''}
                  </Text>
                </NameContainer>
              </UserContainer>
            </TableRowCell>
            <TableRowCell width={hasORCA ? '10%' : '12%'} equalSize={false}>
              <Chip variant={statusChipAttributes.variant as ChipVariants}>
                {statusChipAttributes.text}
              </Chip>
            </TableRowCell>
            <TableRowCell width={hasORCA ? '8%' : '12%'} equalSize={false}>
              {commuter.totalTrips} Trip
              {RandomUtils.pluralCheck(commuter.totalTrips)}
            </TableRowCell>
            <TableRowCell width={hasORCA ? '20%' : '28%'} equalSize={false}>
              <SegmentsGroup>
                {getSegments(commuter.segmentsEnrolled, segmentsMap)}
              </SegmentsGroup>
            </TableRowCell>
            <TableRowCell
              width={
                hasORCA
                  ? editOrAdd
                    ? '13%'
                    : '16%'
                  : editOrAdd
                    ? '21%'
                    : '24%'
              }
              equalSize={false}
            >
              <Programs>
                {FormattingUtils.formatDollar(getCurrentBalance(commuter))}
              </Programs>
            </TableRowCell>
            {hasORCA && (
              <TableRowCell width='15%' equalSize={false}>
                {isAdd ? (
                  <InputORCAStyled
                    disabled={isDisabled}
                    onChange={handleOnchangeORCAInput}
                    value={orcaNumber}
                    type='number'
                  />
                ) : (
                  commuter.orcaCardNumber
                )}
              </TableRowCell>
            )}
          </TableRowContents>
        </TableRow>
      )
    })
}

const getActiveCommutersMap = (commuters: MyCommuter[]) => {
  return commuters?.reduce((agg, commuter) => {
    agg[commuter.id] = commuter
    return agg
  }, {})
}

interface MyCommutersView {
  myCommuters: MyCommuter[]
  activeCommuters: MyCommuter[]
  activeSegment: Segment
  isEdit: boolean
  isAdd: boolean
  setIsEdit: (isEdit: boolean) => void
  setIsAdd: (isAdd: boolean) => void
}

const MyCommutersView = React.memo((props: MyCommutersView) => {
  const {
    myCommuters,
    activeCommuters,
    activeSegment,
    isEdit,
    isAdd,
    setIsEdit,
    setIsAdd,
  } = props

  const dispatch = useDispatch()

  const { segmentUpdateFlag, segmentsMap, profile } = useSelector(
    (state: AppState) => state.employer
  )

  const [sortByFilter, setSortByFilter] = useState('desc')
  const [filteredCommuters, setFilteredCommuters] = useState(myCommuters)
  const [selectedCommuters, setSelectedCommuters] = useState([])
  const [activeEmployeeId, setActiveEmployeeId] = useState(null)
  const [userOrcaList, setUserOrcaList] = useState({})

  useEffect(() => {
    setFilteredCommuters(myCommuters)
    setSelectedCommuters([])
    setUserOrcaList({})
  }, [myCommuters])

  const handleCancel = useCallback(() => {
    setSelectedCommuters([])
    setUserOrcaList({})
    if (isAdd) {
      setIsAdd(false)
    } else {
      setIsEdit(false)
    }
    SegmentService.track('commuters-group-manage', {
      action: 'cancel',
      numEmployees: null,
    })
  }, [isAdd, setIsAdd, setIsEdit])

  useEffect(() => {
    if (segmentUpdateFlag) {
      handleCancel()
      dispatch(employeeActions.getMyCommuters({ segment: segmentUpdateFlag }))
      dispatch(employerActions.toggleSegmentUpdateFlag())
    }
  }, [dispatch, handleCancel, segmentUpdateFlag, myCommuters])

  const selectAll = useCallback(
    (
      filteredCommuters: MyCommuter[],
      selectedCommuters: string[],
      activeCommuters: Dictionary<MyCommuter>
    ) => {
      const totalValidCommuters = activeCommuters
        ? filteredCommuters.filter((commuter) => !activeCommuters[commuter.id])
        : filteredCommuters

      if (selectedCommuters.length === totalValidCommuters.length) {
        setSelectedCommuters([])
      } else {
        const ids = totalValidCommuters.map((commuters) => commuters.id)
        setSelectedCommuters(ids)
      }
      SegmentService.track('commuters-group-manage', {
        action: 'select-all',
        numEmployees: null,
      })
    },
    []
  )

  const handleCommuterSelect = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement>,
      id: string,
      selectedCommuters: string[]
    ) => {
      e.stopPropagation()
      if (selectedCommuters.includes(id)) {
        const commuterCopy = [...selectedCommuters]
        const idx = commuterCopy.indexOf(id)
        commuterCopy.splice(idx, 1)
        setSelectedCommuters(commuterCopy)
      } else {
        setSelectedCommuters((prev) => [...prev, id])
      }
    },
    []
  )

  const handleAdd = (selectedCommuters: string[], activeSegment: Segment) => {
    if (hasORCA) {
      const selectedCommutersORCAList = selectedCommuters.reduce(
        (commuterORCACards, commuterId) => {
          if (userOrcaList[commuterId]) {
            commuterORCACards[commuterId] = Number(userOrcaList[commuterId])
          }
          return commuterORCACards
        },
        {}
      )
      setUserOrcaList({})

      dispatch(
        employerActions.addSegmentEmployeesWithORCA({
          segmentId: activeSegment.id,
          employees: [...activeSegment.upcomingAdditions, ...selectedCommuters],
          ORCA_card_numbers: selectedCommutersORCAList,
        })
      )
      SegmentService.track('commuters-group-manage', {
        action: 'add',
        numEmployees: [...activeSegment.upcomingAdditions, ...selectedCommuters]
          .length,
      })
    } else {
      dispatch(
        employerActions.addSegmentEmployees({
          segmentId: activeSegment.id,
          employees: [...activeSegment.upcomingAdditions, ...selectedCommuters],
        })
      )
      SegmentService.track('commuters-group-manage', {
        action: 'add',
        numEmployees: [...activeSegment.upcomingAdditions, ...selectedCommuters]
          .length,
      })
    }
  }

  const handleRemove = (
    selectedCommuters: string[],
    activeSegment: Segment
  ) => {
    dispatch(
      employerActions.removeSegmentEmployees({
        segmentId: activeSegment.id,
        employees: [...activeSegment.upcomingRemovals, ...selectedCommuters],
      })
    )
    SegmentService.track('commuters-group-manage', {
      action: 'remove',
      numEmployees: [...activeSegment.upcomingRemovals, ...selectedCommuters]
        .length,
    })
  }

  const onRowClick = (id: string) => {
    setActiveEmployeeId(id)
  }

  //checks which commuters are already part of the program when adding
  const activeCommutersMap = isAdd && getActiveCommutersMap(activeCommuters)
  const editOrAdd = isEdit || isAdd

  if (!myCommuters || !filteredCommuters || (!activeCommuters && isAdd)) {
    return <Loading isCard={false} />
  }

  const hasORCA =
    activeSegment.name.toLowerCase() === ORCA_PILOT_GROUP_NAME &&
    profile.id === SSA_COMPANY_ID

  const onChangeORCANumber = (newOrcaNumber: string, commuterId) => {
    setUserOrcaList((prevList) => ({
      ...prevList,
      [commuterId]: newOrcaNumber,
    }))
  }

  return (
    <>
      <MyCommutersSearch
        myCommuters={myCommuters}
        filteredCommuters={filteredCommuters}
        setFilteredCommuters={setFilteredCommuters}
        sortByFilter={sortByFilter}
        setSortByFilter={setSortByFilter}
        activeSegment={activeSegment?.id}
        isAdd={isAdd}
      />
      <Body>
        <Table hasHeader={false} maxRows={10} loadMoreAnimation={false}>
          <TableLabelRow padding='1rem 1.5rem' overflow='hidden'>
            {editOrAdd && (
              <Checkbox
                checked={
                  activeCommuters &&
                  filteredCommuters.length ===
                    selectedCommuters.length + activeCommuters.length
                }
                onClick={() =>
                  selectAll(
                    filteredCommuters,
                    selectedCommuters,
                    activeCommutersMap
                  )
                }
                marginRight='2%'
                disabled={filteredCommuters.length === 0}
                data-cy='select-all-commuters-checkbox'
              />
            )}
            <TableRowCell width={hasORCA ? '20%' : '25%'} equalSize={false}>
              Commuter
            </TableRowCell>
            <TableRowCell width={hasORCA ? '10%' : '12%'} equalSize={false}>
              Status
            </TableRowCell>
            <TableRowCell width={hasORCA ? '8%' : '12%'} equalSize={false}>
              Total Trips
            </TableRowCell>
            <TableRowCell width={hasORCA ? '20%' : '28%'} equalSize={false}>
              Groups
            </TableRowCell>
            <TableRowCell
              width={
                hasORCA
                  ? editOrAdd
                    ? '13%'
                    : '16%'
                  : editOrAdd
                    ? '21%'
                    : '24%'
              }
              equalSize={false}
            >
              Current Balance
            </TableRowCell>
            {hasORCA && (
              <TableRowCell width='15%' equalSize={false}>
                ORCA Card Number
              </TableRowCell>
            )}
          </TableLabelRow>
          {filteredCommuters.length > 0 ? (
            getTableRows(
              filteredCommuters,
              segmentsMap,
              sortByFilter,
              editOrAdd,
              selectedCommuters,
              handleCommuterSelect,
              activeCommutersMap,
              onRowClick,
              hasORCA,
              onChangeORCANumber,
              userOrcaList,
              isAdd
            )
          ) : (
            <EmptyTableRow>No Results Found</EmptyTableRow>
          )}
        </Table>
      </Body>
      {editOrAdd && (
        <Footer>
          <FlexContainer
            alignItems='center'
            justifyContent='space-between'
            width='100%'
          >
            <Text variant='action1'>{selectedCommuters.length} Selected</Text>
            <FlexContainer>
              <Button
                variant='tertiary'
                marginRight='1rem'
                onClick={handleCancel}
              >
                Cancel
              </Button>
              {isAdd && (
                <Button
                  disabled={selectedCommuters.length === 0}
                  onClick={() => handleAdd(selectedCommuters, activeSegment)}
                >
                  Add To Group
                </Button>
              )}
              {isEdit && (
                <Button
                  disabled={selectedCommuters.length === 0}
                  onClick={() => handleRemove(selectedCommuters, activeSegment)}
                  variant='cancel'
                >
                  Remove From Group
                </Button>
              )}
            </FlexContainer>
          </FlexContainer>
        </Footer>
      )}
      <MyCommuterDetailsModal
        employeeId={activeEmployeeId}
        open={activeEmployeeId && true}
        closeModal={() => setActiveEmployeeId(null)}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommutersView.displayName = 'MyCommutersView'
}

export default MyCommutersView
