import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import { ProgramModal } from 'core-system/Program'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React, { useCallback, useState } from 'react'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import styled from 'styled-components'
import ClearIcon from 'core-system/Icons/Actions/Clear'

const Container = styled.div<{ isMobile: boolean }>`
  ${(props) => props.theme.typography.action4};
  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) => props.theme.palette.primary.pink300};
  width: 100%;
  padding: ${(props) => (props.isMobile ? '0.5rem 0.5em 0.5rem 1rem' : '1rem')};
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
`

const PaymentTypeNumberContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  ${(props) => (!props.isMobile ? 'gap: 0.5rem;' : '')}
`

const PaymentLastFourExpiryContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
`

const PaymentDeleteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
`

const PaymentInfo = styled.div`
  display: flex;
  align-items: center;

  img {
    max-width: 2.5rem;
    width: 100%;
    margin-right: 1rem;
  }
`

const brandMap = {
  amex: 'amex',
  visa: 'visa',
  diners: 'diners_club',
  discover: 'discover',
  jcb: 'jcb',
  mastercard: 'mastercard',
  unionpay: 'bank_account',
  unknown: 'bank_account',
  us_bank_account: 'bank_account',
}

const renderDeleteButton = (
  isMobile: boolean,
  showModal: (show: boolean) => void
) => {
  return isMobile ? (
    <PaymentDeleteButtonContainer onClick={() => showModal(true)}>
      <ClearIcon color={palette.error.red500} />
    </PaymentDeleteButtonContainer>
  ) : (
    <Button variant='cancel' size='small' onClick={() => showModal(true)}>
      Delete
    </Button>
  )
}

interface PaymentMethodProps {
  id?: string
  type: 'card' | 'bank_account' | 'ach_debit' | 'us_bank_account'
  /** Card or Bank Account name */
  brand: string
  last4: number
  expMonth?: number
  expYear?: number
  bankName?: string
  /** Function to call on primary button click */
  onPrimaryAction?: () => void
  /** Function to call on delete button click, only for credit cards */
  onDeleteAction?: (id: string, type: string) => void
  customDeleteModalText?: string
  /** Method is only selectable when more than one payment option is available */
  methodSelectable?: boolean
  selected?: boolean
  onSelectMethod?: () => void
  /** Show update or change buttons */
  showActions?: boolean
  isMobile?: boolean
}

const PaymentMethod = (props: PaymentMethodProps) => {
  const {
    id,
    type,
    brand,
    last4,
    expMonth,
    expYear,
    onDeleteAction,
    customDeleteModalText,
    showActions,
    bankName,
    isMobile,
  } = props

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleDelete = useCallback(() => {
    onDeleteAction(id, type)
    setIsModalOpen(false)
  }, [onDeleteAction, setIsModalOpen, id, type])

  const brandImg =
    type === 'card' && brandMap[brand] ? brandMap[brand] : 'bank_account'

  return (
    <>
      <Container isMobile={isMobile}>
        <PaymentInfo>
          <img
            src={`${StaticAssetsUrl}/payments/${brandImg}.png`}
            alt={brand}
          />
          <div>
            <div style={{ textTransform: 'capitalize' }}>
              {type === 'card' ? brand : bankName}
            </div>
            <PaymentTypeNumberContainer isMobile={isMobile}>
              <Text variant='action4' textColor={palette.text.secondary}>
                {type === 'card' ? type : 'Bank Account'}
              </Text>
              <PaymentLastFourExpiryContainer isMobile={isMobile}>
                <Text variant='action4' textColor={palette.text.secondary}>
                  &bull;&bull;&bull;&bull; {last4}
                </Text>
                {type === 'card' ? (
                  <>
                    <Divider
                      direction='vertical'
                      size='1rem'
                      mx={isMobile ? '0.5rem' : '1rem'}
                    />
                    <Text variant='action4' textColor={palette.text.secondary}>
                      Exp. {expMonth}/{expYear}
                    </Text>
                  </>
                ) : null}
              </PaymentLastFourExpiryContainer>
            </PaymentTypeNumberContainer>
          </div>
        </PaymentInfo>
        {showActions ? renderDeleteButton(isMobile, setIsModalOpen) : null}
      </Container>
      <ProgramModal
        title='Delete Payment Method?'
        description={
          customDeleteModalText
            ? customDeleteModalText
            : 'Are you sure you want to delete your current payment method? Commuters will no longer be able to spend on their Fleet cards.'
        }
        buttonText='Delete'
        open={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        buttonCallback={handleDelete}
      />
    </>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  PaymentMethod.displayName = 'PaymentMethod'
}

export default PaymentMethod
