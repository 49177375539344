import Worksite from 'core-system/Icons/Accounts/Worksite'
import Home from 'core-system/Icons/Segments/Home'
import palette from 'core-system/Themes/palette'
import {
  MyCarpoolGroup,
  CarpoolMember,
  CarpoolRequest,
  CarpoolRelationType,
} from 'redux/employeePlatform/employeePlatformTypes'

export const getCarpoolRelation = (
  myCarpoolGroups: { [key: string]: MyCarpoolGroup } | null,
  carpoolMemberships: { [key: string]: CarpoolMember } | null,
  sentCarpoolRequests: { [key: string]: CarpoolRequest } | null,
  groupId: string
): CarpoolRelationType => {
  if (
    myCarpoolGroups &&
    Object.keys(myCarpoolGroups).length > 0 &&
    myCarpoolGroups[groupId]
  ) {
    return 'OWNER'
  } else if (
    carpoolMemberships &&
    Object.keys(carpoolMemberships).length > 0 &&
    carpoolMemberships[groupId]
  ) {
    return 'MEMBER'
  } else if (
    sentCarpoolRequests &&
    Object.keys(sentCarpoolRequests).length > 0 &&
    sentCarpoolRequests[groupId]
  ) {
    return 'REQUESTED'
  } else {
    return 'NONE'
  }
}

export const LocationDisclaimers = {
  ReceivedRequest:
    'The location of the requestor is approximate and has been anonymized within 250m for security purposes.',
  RequestCreation:
    'The addresses you provide will be anonymized within 250m for security reasons. The carpool owner will not be able to see your exact location.',
}

export const CarpoolMapLegendColors = {
  mySource: palette.accent.teal500,
  myDest: palette.primary.pink500,
  otherSource: palette.accent.blue500,
  otherDest: palette.primary.pink500,
}

export const CarpoolMapLegendIcons = {
  source: Home,
  dest: Worksite,
}

export const RoleDropDownItems = [
  { id: 'driver', text: 'Driver Only' },
  { id: 'passenger', text: 'Passenger Only' },
  { id: 'both', text: 'Driver or Passenger' },
]
