import React from 'react'
import { FeatureCollection } from 'geojson'
import MapNavigationControl from 'core-system/Map/MapNavigationControl'
import { Expression } from 'mapbox-gl'
import MapStyles from 'core-system/Map/MapStyles'
import { Source, Layer, LayerProps } from 'react-map-gl/mapbox'
import MapPopupWorksite from 'core-system/Map/Popups/MapPopupWorksite'
import Map from 'core-system/Map/Map'

const popupMap = {
  worksites: MapPopupWorksite,
}

const getTripStyles = (
  modes: Dictionary<number>,
  activeLayer: string,
  maxLegsatOrigin: number
): LayerProps => {
  let modeColors = []
  let modeStrokeColors: string | Expression =
    MapStyles.pointStyle.pointStrokeColor

  if (!activeLayer) {
    modeColors = Object.keys(modes).flatMap((mode: string, idx: number) => [
      ['==', ['get', 'mode'], mode.toUpperCase()],
      MapStyles.mapColors[idx] || '#000000',
    ])
  } else {
    const activeLayerFormatted = activeLayer.split(',')[0].toUpperCase()
    const indexOfLayer = Object.keys(modes)
      .sort((a, b) => modes[b] - modes[a])
      .indexOf(activeLayerFormatted)

    modeColors = [
      ['==', ['get', 'mode'], activeLayerFormatted],
      MapStyles.mapColors[indexOfLayer],
    ]

    modeStrokeColors = [
      'case',
      ['==', ['get', 'mode'], activeLayerFormatted],
      MapStyles.pointStyle.pointStrokeColor,
      'transparent',
    ]
  }

  modeColors.unshift('case')
  modeColors.push('transparent')

  return {
    id: 'tripOrigins',
    type: 'circle',
    beforeId: 'settlement-major-label',
    paint: {
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        [
          '+',
          4,
          [
            '*',
            [
              '/',
              ['-', ['number', ['get', 'numTrips'], 1], 1],
              maxLegsatOrigin,
            ],
            9 - 4,
          ],
        ],
        15,
        [
          '+',
          4,
          [
            '*',
            [
              '/',
              ['-', ['number', ['get', 'numTrips'], 1], 1],
              maxLegsatOrigin,
            ],
            14 - 4,
          ],
        ],
      ],
      'circle-color': modeColors as Expression,
      'circle-stroke-width': MapStyles.pointStyle.pointStrokeWidth,
      'circle-stroke-color': modeStrokeColors,
      'circle-opacity': 0.75,
      'circle-stroke-opacity': 0.3,
    },
  }
}

interface DashboardMapProps {
  worksiteGeojson: FeatureCollection
  recentTripGeojson: FeatureCollection
  activeLayer: string
  modeCount: Dictionary<number>
}

const DashboardMap = React.memo((props: DashboardMapProps) => {
  const { worksiteGeojson, recentTripGeojson, activeLayer, modeCount } = props

  const hasTrips = recentTripGeojson.features.length > 0
  const maxLegsatOrigin = hasTrips
    ? Math.max(
        ...recentTripGeojson.features.map(
          (feature) => feature.properties.numTrips
        )
      )
    : 1
  const tripStyles = getTripStyles(modeCount, activeLayer, maxLegsatOrigin)

  return (
    <Map
      style={{
        height: '100%',
        width: '100%',
      }}
      scrollZoom={false}
      popupMap={popupMap}
      worksites={worksiteGeojson}
      loadLayer={hasTrips ? recentTripGeojson : worksiteGeojson}
    >
      {(mapProps) => (
        <>
          {hasTrips && (
            <>
              <Source type='geojson' id='tripOrigins' data={recentTripGeojson}>
                <Layer {...tripStyles} />
              </Source>
            </>
          )}
          <MapNavigationControl
            setViewport={mapProps.setViewport}
            featureCollection={recentTripGeojson}
            updateBbox={mapProps.updateBbox}
          />
        </>
      )}
    </Map>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardMap.displayName = 'DashboardMap'
}

export default DashboardMap
