import Button from 'core-system/Button'
import FlexContainer from 'core-system/FlexContainer'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import CalendarIcon from 'core-system/Icons/Misc/Calendar'
import EmployeeIcon from 'core-system/Icons/Misc/Employees'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'

const Chevrons = styled.div`
  display: flex;
  margin-left: 1.5rem;
`

const StyledChevron = styled.div<{ isLeft?: boolean; isDisabled?: boolean }>`
  transform: rotate(${(props) => (props.isLeft ? '90deg' : '270deg')});
  color: ${(props) =>
    props.isDisabled
      ? props.theme.palette.text.disabled
      : props.theme.palette.text.primary};
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const StyledCalendarIcon = styled(CalendarIcon)`
  margin-bottom: 0.125rem;
`

interface ProgramHeaderProps {
  segmentId: string
  manageRoute?: string
  currentDate?: string
  onIncreaseClick?: () => void
  onDecreaseClick?: () => void
  isPreTax?: boolean
}

const ProgramHeader = React.memo((props: ProgramHeaderProps) => {
  const {
    segmentId,
    manageRoute,
    currentDate = null,
    onIncreaseClick = null,
    onDecreaseClick = null,
    isPreTax = false,
  } = props
  const { segmentsMap } = useSelector((state: AppState) => state.employer)
  const navigate = useNavigate()

  const today = moment()

  const displayCommuterCount =
    segmentsMap[segmentId].commuters -
    (isPreTax ? segmentsMap[segmentId].contractors.length : 0)

  return (
    <FlexContainer
      marginBottom='1rem'
      justifyContent='space-between'
      alignItems='center'
    >
      <FlexContainer>
        <Text variant='h3' dataCy='program-header-title'>
          {segmentsMap[segmentId].name === 'All Employees'
            ? 'All Commuters'
            : segmentsMap[segmentId].name}
        </Text>
        {currentDate && (
          <Chevrons>
            <StyledChevron
              isLeft={true}
              onClick={onDecreaseClick}
              data-cy='rank-month-decrease-button'
            >
              <ChevronIcon />
            </StyledChevron>
            <FlexContainer alignItems='center' margin='0 1rem'>
              <StyledCalendarIcon color={palette.text.primary} />
              <Text variant='action4' marginLeft='0.5rem' hideOverflow>
                {moment(currentDate).format('MMMM, YYYY')}
              </Text>
            </FlexContainer>
            <StyledChevron
              isDisabled={moment(currentDate).add(1, 'month').isAfter(today)}
              onClick={onIncreaseClick}
              data-cy='rank-month-increase-button'
            >
              <ChevronIcon />
            </StyledChevron>
          </Chevrons>
        )}
      </FlexContainer>
      <FlexContainer alignItems='center'>
        <EmployeeIcon color={palette.primary.pink800} />
        <Text variant='action4' marginLeft='0.5rem'>
          {`${displayCommuterCount}${isPreTax ? ' Eligible' : ''}`} Commuter
          {RandomUtils.pluralCheck(displayCommuterCount)}
        </Text>
        {manageRoute && (
          <Button
            size='small'
            marginLeft='1rem'
            onClick={() => {
              navigate(manageRoute)
              SegmentService.track('program-manage-action', {
                action: 'manage',
                segmentName: segmentsMap[segmentId].name,
              })
            }}
          >
            Manage Program
          </Button>
        )}
      </FlexContainer>
    </FlexContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramHeader.displayName = 'ProgramHeader'
}

export default ProgramHeader
