import { put, call, takeLatest } from 'redux-saga/effects'
import { applicationActions } from './applicationSlice'
import { getProperties } from './applicationService'
import { all } from 'redux-saga/effects'

export function* fetchPropertiesWorker() {
  try {
    const properties = yield call(getProperties)
    yield put(applicationActions.setProperties(properties))
  } catch (_error) {
    // Not doing anything, just keeping the default properties
  }
}

export function* watchFetchProperties() {
  yield takeLatest(
    applicationActions.fetchProperties.type,
    fetchPropertiesWorker
  )
}

export default function* rootSaga() {
  yield all([watchFetchProperties()])
}
