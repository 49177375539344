import Dropdown, { SelectItemProps } from 'core-system/Dropdown'
import CloseIcon from 'core-system/Icons/Actions/Close'
import { NumberFormatter, NumberInput } from 'core-system/Input/NumberInput'
import React from 'react'
import { ModeType } from 'redux/incentives/incentivesTypes'
import styled from 'styled-components'
import { RewardsCallbackItem } from '../ActivateIncentiveView'

const Mode = styled.div`
  flex: 3;
`

const DropdownContainer = styled.div`
  width: 80%;
`

const Reward = styled.div`
  flex: 2;
`

const Delete = styled.div<{ isVisible?: boolean }>`
  width: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  user-select: none;
`

const RewardRow = styled.div`
  display: flex;
  margin-top: 1rem;
`

const Close = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.text.secondary};

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.primary.pink500};
  }
`

const dropdownModes = [
  'walk',
  'bike',
  'scooter',
  'transit',
  'carpool',
  'rideshare',
  'bikeshare',
  'scootershare',
  'vanpool',
  'moped',
  'mopedshare',
  'shuttle',
  'car',
]

const getModeTypes = (
  currentModeType: ModeType,
  modeTypes: ModeType[],
  idxRow: number
) => {
  return dropdownModes.map((modeType) => {
    return {
      id: `${modeType}Val`,
      text: modeType,
      disabled: modeTypes.some((x) => x.text === modeType),
      currentModeType,
      idxRow,
    }
  })
}

interface ActivateIncentiveRewardRowProps {
  modeType: ModeType
  modeTypes: ModeType[]
  idx: number
  handleModeTypeChange: (item: SelectItemProps) => void
  handleModeTypeDelete: (idx: number) => void
  handleModeStatChange: (
    value: number,
    callbackItem: RewardsCallbackItem
  ) => void
  disableRemove?: boolean
}

const ActivateIncentiveRewardRow = React.memo(
  (props: ActivateIncentiveRewardRowProps) => {
    const {
      modeType,
      modeTypes,
      idx,
      handleModeTypeChange,
      handleModeTypeDelete,
      handleModeStatChange,
      disableRemove,
    } = props

    const modeTypeItems = getModeTypes(modeType, modeTypes, idx)
    const activeModeIndex = dropdownModes.indexOf(modeType.text)
    const hasActiveElement = activeModeIndex !== -1

    return (
      <RewardRow>
        <Mode>
          <DropdownContainer>
            <Dropdown
              active={
                hasActiveElement ? modeTypeItems[activeModeIndex].id : null
              }
              items={modeTypeItems}
              placeholder={!hasActiveElement ? 'Select Mode Type' : null}
              itemCallback={handleModeTypeChange}
              isModeSelector={true}
              width='100%'
            />
          </DropdownContainer>
        </Mode>
        <Reward>
          <NumberInput
            padding='0.625rem 0.5rem'
            maxWidth={'80%'}
            onValueChange={handleModeStatChange}
            callbackItem={{ type: 'perTrip', modeType, idxRow: idx }}
            value={modeType.perTrip}
            iconSize={16}
            incrementVal={0.5}
          >
            <NumberFormatter fixedDecimalScale showPlaceholderOnZero />
          </NumberInput>
        </Reward>
        <Reward>
          <NumberInput
            padding='0.625rem 0.5rem'
            maxWidth='80%'
            onValueChange={handleModeStatChange}
            callbackItem={{ type: 'max', modeType, idxRow: idx }}
            value={modeType.max}
            iconSize={16}
            incrementVal={0.5}
          >
            <NumberFormatter
              fixedDecimalScale
              placeholder='No max'
              showPlaceholderOnZero
            />
          </NumberInput>
        </Reward>
        <Delete isVisible={!disableRemove}>
          <Close onClick={() => handleModeTypeDelete(idx)}>
            <CloseIcon width={24} height={24} />
          </Close>
        </Delete>
      </RewardRow>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ActivateIncentiveRewardRow.displayName = 'ActivateIncentiveRewardRow'
}

export default ActivateIncentiveRewardRow
