import FlexContainer from 'core-system/FlexContainer'
import { NumberFormatter, NumberInput } from 'core-system/Input/NumberInput'
import Loading from 'core-system/Loading'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'
import * as FlexIcons from 'core-system/Icons/Flex'
import Button from 'core-system/Button'
import {
  Success as SuccessIcon,
  Alert as AlertIcon,
} from 'core-system/Icons/Misc'
import employeePlatformService from 'redux/employeePlatform/employeePlatformService'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'

const IconContainer = styled(FlexContainer)`
  width: 4rem;
  height: 3rem;
  background-color: ${(props) => props.theme.palette.primary.pink800};
  border-radius: 50%;
  margin-right: 0.75rem;
`

const allocationHasChanged = (
  originalAmount: number | undefined | null,
  currentAmount: number
) => {
  return (!originalAmount && currentAmount > 0) ||
    originalAmount !== currentAmount
    ? true
    : false
}

const renderEmployeeAllocation = (
  type: 'Transit' | 'Parking' | 'Fleet Card Transit' | 'ORCA Card Transit',
  icon: React.ReactNode,
  allocationAmount: number,
  onAllocationChange: (amount: number) => void,
  maxAllocation: number,
  isMobile: boolean,
  hasORCA?: boolean
) => {
  return (
    <FlexContainer
      flexDirection='row'
      alignItems={allocationAmount > maxAllocation ? 'center' : 'flex-end'}
      marginBottom='1.5rem'
    >
      {isMobile ? null : <IconContainer center>{icon}</IconContainer>}
      <FlexContainer flexDirection='column'>
        <Text variant='body1'>
          {hasORCA
            ? `${type} Amount`
            : `${type} Amount (max. ${FormattingUtils.formatDollar(
                maxAllocation,
                0
              )})`}
        </Text>
        <NumberInput
          padding='1rem'
          onValueChange={onAllocationChange}
          value={allocationAmount / 100}
          iconSize={24}
          incrementVal={5}
          maxWidth='460px'
          maxHeight='3rem'
        >
          <NumberFormatter decimalScale={0} fontVariant='h3' />
        </NumberInput>
        {allocationAmount > maxAllocation ? (
          <FlexContainer marginTop='0.5rem'>
            <Text variant='body2' textColor={palette.error.red500}>
              {`Please enter a value equal to or less than ${FormattingUtils.formatDollar(
                maxAllocation,
                0
              )}`}
            </Text>
          </FlexContainer>
        ) : null}
      </FlexContainer>
    </FlexContainer>
  )
}

const renderStatusMessage = (
  status: 'success' | 'error' | null,
  type: 'Transit' | 'Parking' | 'TransitORCA'
) => {
  if (!status) return null

  return (
    <FlexContainer flexDirection='row' alignItems='flex-start' marginY='1rem'>
      {status === 'success' ? (
        <SuccessIcon color={palette.success.green600} />
      ) : (
        <AlertIcon color={palette.error.red500} />
      )}
      <FlexContainer flexDirection='column' marginLeft='1rem'>
        <Text variant='action1'>{`${type} Allocation Update ${
          status === 'success' ? 'Successful' : 'Failed'
        }`}</Text>
      </FlexContainer>
    </FlexContainer>
  )
}

interface EditAllocationModalProps {
  closeModal: () => void
  open: boolean
  isMobile: boolean
  hasORCA?: boolean
  maxTransitAllocation: number
  maxParkingAllocation: number
  maxTransitAllocationORCA: number
}

const EditAllocationModal = React.memo((props: EditAllocationModalProps) => {
  const {
    closeModal,
    open,
    isMobile,
    hasORCA,
    maxTransitAllocation,
    maxParkingAllocation,
    maxTransitAllocationORCA,
  } = props

  const { preTaxPrograms } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const dispatch = useDispatch()

  const [transitAmountORCA, setTransitAmountORCA] = useState(
    preTaxPrograms.COMMUTER_ORCA?.employeeDeduction?.employeeDeduction || 0
  )

  const [transitAmount, setTransitAmount] = useState(
    preTaxPrograms.COMMUTER_TRANSIT?.employeeDeduction?.employeeDeduction || 0
  )
  const [parkingAmount, setParkingAmount] = useState(
    preTaxPrograms.COMMUTER_PARKING?.employeeDeduction?.employeeDeduction || 0
  )
  const [currentView, setCurrentView] = useState('edit')
  const [transitStatus, setTransitStatus] = useState<
    null | 'success' | 'error'
  >(null)
  const [parkingStatus, setParkingStatus] = useState<
    null | 'success' | 'error'
  >(null)
  const [transitOrcaStatus, setTransitOrcaStatus] = useState<
    null | 'success' | 'error'
  >(null)

  const originalAllocation = {
    transit:
      preTaxPrograms.COMMUTER_TRANSIT?.employeeDeduction?.employeeDeduction,
    parking:
      preTaxPrograms.COMMUTER_PARKING?.employeeDeduction?.employeeDeduction,
    orcaTransit:
      preTaxPrograms.COMMUTER_ORCA?.employeeDeduction?.employeeDeduction,
  }

  const handleTransitAllocationChange = (amount: number) => {
    setTransitAmount(amount * 100)
  }

  const handleTransitORCAAllocationChange = (amount: number) => {
    setTransitAmountORCA(amount * 100)
  }

  const handleParkingAllocationChange = (amount: number) => {
    setParkingAmount(amount * 100)
  }

  const createAllocation = async (
    amount: number,
    benefitId: string,
    setStatus: (status: null | 'success' | 'error') => void
  ) => {
    try {
      const result =
        await employeePlatformService.createPreTaxEmployeeAllocation({
          employeeDeduction: amount,
          benefit: benefitId,
        })
      setStatus('success')
      dispatch(
        employeePlatformActions.createPreTaxEmployeeAllocationSuccess(
          result.data
        )
      )
    } catch (_error) {
      setStatus('error')
    }
  }

  const updateAllocation = async (
    allocationId: string,
    amount: number,
    benefitId: string,
    setStatus: (status: null | 'success' | 'error') => void
  ) => {
    try {
      const result =
        await employeePlatformService.updatePreTaxEmployeeAllocation(
          allocationId,
          {
            employeeDeduction: amount,
            benefit: benefitId,
          }
        )
      setStatus('success')
      dispatch(
        employeePlatformActions.updatePreTaxEmployeeAllocationSuccess(
          result.data
        )
      )
    } catch (_error) {
      setStatus('error')
    }
  }

  const handleSubmit = async () => {
    setCurrentView('loading')
    if (preTaxPrograms.COMMUTER_TRANSIT) {
      if (allocationHasChanged(originalAllocation.transit, transitAmount)) {
        if (originalAllocation.transit || originalAllocation.transit === 0) {
          await updateAllocation(
            preTaxPrograms.COMMUTER_TRANSIT.employeeDeduction.id,
            transitAmount,
            preTaxPrograms.COMMUTER_TRANSIT.id,
            setTransitStatus
          )
        } else {
          await createAllocation(
            transitAmount,
            preTaxPrograms.COMMUTER_TRANSIT.id,
            setTransitStatus
          )
        }
      }
    }

    if (preTaxPrograms.COMMUTER_PARKING) {
      if (allocationHasChanged(originalAllocation.parking, parkingAmount)) {
        if (originalAllocation.parking || originalAllocation.parking === 0) {
          await updateAllocation(
            preTaxPrograms.COMMUTER_PARKING.employeeDeduction.id,
            parkingAmount,
            preTaxPrograms.COMMUTER_PARKING.id,
            setParkingStatus
          )
        } else {
          await createAllocation(
            parkingAmount,
            preTaxPrograms.COMMUTER_PARKING.id,
            setParkingStatus
          )
        }
      }
    }

    if (preTaxPrograms.COMMUTER_ORCA) {
      if (
        allocationHasChanged(originalAllocation.orcaTransit, transitAmountORCA)
      ) {
        if (
          originalAllocation.orcaTransit ||
          originalAllocation.orcaTransit === 0
        ) {
          await updateAllocation(
            preTaxPrograms.COMMUTER_ORCA.employeeDeduction.id,
            transitAmountORCA,
            preTaxPrograms.COMMUTER_ORCA.id,
            setTransitOrcaStatus
          )
        } else {
          await createAllocation(
            transitAmountORCA,
            preTaxPrograms.COMMUTER_ORCA.id,
            setTransitOrcaStatus
          )
        }
      }
    }

    setCurrentView('status')
  }

  const isDisableButtonUpdate = hasORCA
    ? (!allocationHasChanged(
        originalAllocation.orcaTransit,
        transitAmountORCA
      ) &&
        !allocationHasChanged(originalAllocation.transit, transitAmount) &&
        !allocationHasChanged(originalAllocation.parking, parkingAmount)) ||
      transitAmountORCA > maxTransitAllocationORCA ||
      transitAmount > maxTransitAllocation ||
      parkingAmount > maxParkingAllocation
    : (!allocationHasChanged(originalAllocation.transit, transitAmount) &&
        !allocationHasChanged(originalAllocation.parking, parkingAmount)) ||
      transitAmount > maxTransitAllocation ||
      parkingAmount > maxParkingAllocation

  return (
    <Modal open={open} onClose={() => closeModal()} width={pxToRem(450)}>
      {currentView === 'loading' ? (
        <Loading />
      ) : (
        <div>
          <ModalHeader
            title={
              currentView === 'status'
                ? 'Edit Summary'
                : 'Edit Upcoming Allocation'
            }
            onClose={() => closeModal()}
          />
          <ModalBody>
            {currentView === 'edit' ? (
              <FlexContainer flexDirection='column' marginTop='1rem'>
                {hasORCA &&
                  renderEmployeeAllocation(
                    'ORCA Card Transit',
                    <FlexIcons.Transit
                      color={palette.text.secondary}
                      width='2rem'
                      height='2rem'
                    />,
                    transitAmountORCA,
                    handleTransitORCAAllocationChange,
                    maxTransitAllocationORCA,
                    isMobile,
                    hasORCA
                  )}
                {preTaxPrograms.COMMUTER_TRANSIT
                  ? renderEmployeeAllocation(
                      hasORCA ? 'Fleet Card Transit' : 'Transit',
                      <FlexIcons.Transit
                        color={palette.text.secondary}
                        width='2rem'
                        height='2rem'
                      />,
                      transitAmount,
                      handleTransitAllocationChange,
                      maxTransitAllocation,
                      isMobile,
                      hasORCA
                    )
                  : null}
                {preTaxPrograms.COMMUTER_PARKING
                  ? renderEmployeeAllocation(
                      'Parking',
                      <FlexIcons.Parking
                        color={palette.text.secondary}
                        width='2rem'
                        height='2rem'
                      />,
                      parkingAmount,
                      handleParkingAllocationChange,
                      maxParkingAllocation,
                      isMobile
                    )
                  : null}
              </FlexContainer>
            ) : null}
            {currentView === 'status' ? (
              <FlexContainer flexDirection='column' marginTop='1rem'>
                {preTaxPrograms.COMMUTER_TRANSIT
                  ? renderStatusMessage(transitStatus, 'Transit')
                  : null}
                {preTaxPrograms.COMMUTER_PARKING
                  ? renderStatusMessage(parkingStatus, 'Parking')
                  : null}
                {preTaxPrograms.COMMUTER_ORCA &&
                  renderStatusMessage(transitOrcaStatus, 'TransitORCA')}
              </FlexContainer>
            ) : null}
          </ModalBody>
          <ModalFooter>
            {currentView === 'edit' ? (
              <FlexContainer flexDirection='row'>
                <Button
                  variant='tertiary'
                  marginRight='1rem'
                  onClick={() => closeModal()}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleSubmit()}
                  disabled={isDisableButtonUpdate}
                >
                  Update
                </Button>
              </FlexContainer>
            ) : (
              <Button onClick={() => closeModal()}>Done</Button>
            )}
          </ModalFooter>
        </div>
      )}
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EditAllocationModal.displayName = 'EditAllocationModal'
}

export default EditAllocationModal
