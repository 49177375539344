import React from 'react'
import styled from 'styled-components'
import { OptIn } from 'redux/optin/optInTypes'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { useLocation, useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'

const OptInQuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  background-color: ${(props) => props.theme.palette.white};
  padding: 1rem 2rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const ResponseCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
`

interface OptInQuestionProps {
  optIn: OptIn
}

const OptInQuestion = React.memo((props: OptInQuestionProps) => {
  const { optIn } = props

  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  const navigate = useNavigate()

  const location = useLocation()

  const handleOnQuestionClick = () => {
    navigate(`${location.pathname}/${optIn.id}`, {
      state: { from: location.pathname },
    })
    SegmentService.track('optIn-questionNav-action-click', {
      action: `segment-${segmentsMap[optIn.segment]?.name}-optInQuestion-${
        optIn.id
      }`,
      locationAt: location.pathname,
    })
  }

  return (
    <OptInQuestionContainer onClick={() => handleOnQuestionClick()}>
      <Text variant='h4'>{optIn.question}</Text>
      <ResponseCountContainer>
        <Text variant='body2' textColor={palette.text.placeholder}>
          Responses
        </Text>
        <Text variant='action2'>
          {`${optIn.employeeChoices.length} / ${
            segmentsMap[optIn.segment]?.commuters
          }`}
        </Text>
      </ResponseCountContainer>
    </OptInQuestionContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OptInQuestion.displayName = 'OptInQuestion'
}

export default OptInQuestion
