import * as React from 'react'
import createSvgIcon from '../Icons/createSvgIcon'

export default createSvgIcon(
  <>
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='25' cy='25' r='25' fill='white' />
      <path
        d='M9.00035 39.0833C13.6444 39.0833 17.4092 35.3185 17.4092 30.6744L17.4092 21.9853V21.9853C12.7651 21.9853 9.00035 25.7501 9.00035 30.3942L9.00035 39.0833V39.0833Z'
        fill='url(#paint0_linear_780_6349)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.5534 19.5232C31.8909 19.5232 32.2129 19.5223 32.5199 19.5191H35.873C38.2219 19.5191 40.126 17.615 40.126 15.2661C40.126 15.1798 40.1234 15.094 40.1184 15.009H40.1406V11.0132H35.8742L35.873 11.0132H32.0161L31.1451 11.0125C30.5052 11.0068 29.8646 11.0096 29.2239 11.0125C28.7302 11.0125 28.2365 11.0123 27.7428 11.0121C27.2491 11.012 26.7554 11.0118 26.2617 11.0118C25.8063 11.0106 25.3509 11.0092 24.8955 11.0079C23.9853 11.0052 23.0752 11.0025 22.1654 11.0011C20.9213 10.9989 19.1153 10.9996 17.8712 11.0068H17.8683C17.8268 11.0068 17.7854 11.0068 17.744 11.0075C12.6324 11.0733 9.06448 15.142 9.00013 20.2536V20.2815C9.00013 20.3232 9.00031 20.3651 9.00049 20.4071L9.00049 20.4071C9.00067 20.4493 9.00085 20.4916 9.00085 20.5339V20.5503C9.01321 21.2099 9.00881 21.858 9.00448 22.496C9.0023 22.8172 9.00014 23.1358 9.00014 23.4521L9.00228 23.4585C10.3265 20.6693 12.7739 19.5167 16.0715 19.516H16.0733H16.0752C16.9963 19.516 17.9181 19.516 18.8393 19.5174C19.4307 19.5227 20.022 19.5207 20.6134 19.5187L20.7612 19.5181C21.2519 19.5185 21.7426 19.5187 22.2332 19.5189H22.2335H22.2338L22.2419 19.5189C22.7349 19.519 23.2278 19.5192 23.7206 19.5196L31.5534 19.5232ZM17.4088 21.7348C12.7648 21.7348 9 25.4996 9 30.1437H26.8785C31.5226 30.1437 35.2873 26.3789 35.2873 21.7348H17.4088Z'
        fill='url(#paint1_linear_780_6349)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_780_6349'
          x1='13.2048'
          y1='28.473'
          x2='8.19699'
          y2='41.5325'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#7F42B3' />
          <stop offset='1' stopColor='#D97590' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_780_6349'
          x1='24.5703'
          y1='11'
          x2='24.5703'
          y2='30.1435'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#7F42B3' />
          <stop offset='1' stopColor='#D97590' />
        </linearGradient>
      </defs>
    </svg>
  </>,
  'FleetLogo',
  '0 0 50 50'
)
