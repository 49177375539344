import { loadStripe } from '@stripe/stripe-js'
import { Sandbox } from '@tryfinch/react-connect'

const getApiServerUrl = (): string => {
  if (process.env.REACT_APP_API_SERVER === 'staging') {
    return 'https://staging-api.movewithfleet.com'
  } else if (process.env.NODE_ENV === 'production') {
    return 'https://api.movewithfleet.com'
  }

  return 'http://localhost:8000'
}

const getWebSocketServerUrl = (): string => {
  if (process.env.REACT_APP_API_SERVER === 'staging') {
    return 'wss://staging-api.movewithfleet.com'
  } else if (process.env.NODE_ENV === 'production') {
    return 'wss://api.movewithfleet.com'
  }
  return 'ws://localhost:8000'
}

export const ApiServerUrl = `${getApiServerUrl()}`

export const WebSocketUrl = (url: string): string =>
  `${getWebSocketServerUrl()}/${url}`

export const getClientEnvironment = (): string => {
  if (process.env.REACT_APP_API_SERVER === 'staging') {
    return 'staging'
  } else if (process.env.NODE_ENV === 'production') {
    return 'production'
  }
  return 'development'
}

export const getClientUrl = (): string => {
  if (process.env.REACT_APP_API_SERVER === 'staging') {
    return 'https://staging.movewithfleet.com'
  } else if (process.env.NODE_ENV === 'production') {
    return 'https://app.movewithfleet.com'
  }
  return 'http://localhost:3000'
}

export const getAzureClientId = (): string => {
  if (process.env.REACT_APP_API_SERVER === 'staging') {
    return '96053d8b-f4d6-4e80-8ede-fe4e92c859b9'
  } else if (process.env.NODE_ENV === 'production') {
    return '77489b1b-ec8b-411f-b885-c403a86b97d3'
  }

  return '96053d8b-f4d6-4e80-8ede-fe4e92c859b9' //TODO:  replace with dev-only app id
}

export const MsftAuthUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${getAzureClientId()}&redirect_uri=${getClientUrl()}/login&state=6Y8IFtQfb8fS1Yzt8nDL5wVdvc8AGQEa&response_type=code&scope=User.Read`

export const GoogleAuthUrl = `https://accounts.google.com/o/oauth2/auth?client_id=749888498447-jpgi32533j7l42dlqhc0lslkn483b5gh.apps.googleusercontent.com&redirect_uri=${getClientUrl()}/login&state=AnsU2obTZ819bDz9ivJZhGPzlxIsixl2&response_type=code&scope=openid+email+profile+openid+email+profile`

export const GoogleSignupAuthUrl = `https://accounts.google.com/o/oauth2/auth?client_id=749888498447-jpgi32533j7l42dlqhc0lslkn483b5gh.apps.googleusercontent.com&redirect_uri=${getClientUrl()}/employer-sign-up&state=AnsU2obTZ819bDz9ivJZhGPzlxIsixl2&response_type=code&scope=openid+email+profile+openid+email+profile`

export const StaticAssetsUrl = 'https://static.movewithfleet.com/assets'

const STRIPE_PUBLISHABLE_TEST_KEY =
  'pk_test_51HwwlJGy54gK2TPl75FVUHkgL4sd4imRxnUTy4A95eZrJbRWkOfghUsxYFkTgSgdpvpoH2Tv63gL6zFl9pTTkMFu00DCZet4C4'

const STRIPE_PUBLISHABLE_LIVE_KEY =
  'pk_live_51HwwlJGy54gK2TPlaimYLwdfPjwAXsp0pWpgyiiyPHd0NRwMwQWGdFKG6tvWGmDAViO4wRVggIYTgMwyO8KVydpD00mcpLevni'

export const getStripePromise = () => {
  const key =
    process.env.REACT_APP_API_SERVER === 'staging' ||
    process.env.NODE_ENV === 'development'
      ? STRIPE_PUBLISHABLE_TEST_KEY
      : STRIPE_PUBLISHABLE_LIVE_KEY

  return loadStripe(key, {
    apiVersion: '2022-11-15',
  })
}

export const BUGSNAGKEY = 'ee88c112c2ff6cb4ee3f845bd912a87a'

export const STATSIGKEY =
  process.env.NODE_ENV === 'production'
    ? 'client-dyxeBp8rxYbLsm4uUzU1kJhsp3EQqhWGJODnox70lOY'
    : 'client-UhsIJT9O3f7M75ZxXyZnTKlSaLvW0jCGrOdW0KXixwP'

export const getReleaseEnviornment = () => {
  if (process.env.REACT_APP_API_SERVER === 'staging') {
    return 'staging'
  }
  return process.env.NODE_ENV === 'production' ? 'production' : 'development'
}

const getFinchClientId = () => {
  const clientEnv = getClientEnvironment()
  if (clientEnv === 'production') {
    // PRODUCTION CLIENT ID
    return '3a52c8c3-3437-40e8-9de9-dba338437a84'
  } else if (clientEnv === 'staging') {
    // DEVELOPMENT CLIENT ID
    return 'f3f35d4c-3d56-4f77-af0d-f462fb919e1a'
  } else {
    // SANDBOX CLIENT ID
    return 'b58f71a3-0ce6-4718-b590-68cde2069159'
    // return 'f3f35d4c-3d56-4f77-af0d-f462fb919e1a'
  }
}

export const FINCH_CLIENT_ID = `${getFinchClientId()}`

const getFinchSandboxSetting = (): Sandbox => {
  const clientEnv = getClientEnvironment()
  if (clientEnv === 'production' || clientEnv === 'staging') {
    return false
  }
  return 'provider'
}

export const FINCH_SANDBOX_SETTING = getFinchSandboxSetting()

export const ORCA_PILOT_GROUP_NAME = 'orca pilot'
export const SSA_COMPANY_ID = 'a9db6f71-352d-4a97-8bad-748f4b7ea659'
export const INVOICE_OCR_PILOT = 'Invoice OCR Pilot'
export const FLEET_COMPANY_ID = 'cdac7f9a-d775-4ef7-b522-0b79c6d63eae'
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBycPMY406ck89aQYp-bx-wnxafwzCjGk0'
