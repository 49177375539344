import React from 'react'
import AlertIcon from 'core-system/Icons/Misc/Alert'
import palette from 'core-system/Themes/palette'
import SuccessIcon from 'core-system/Icons/Misc/Success'

export const PaymentMethodCopy = {
  card: 'Use any major credit / debit card to fund your Fleet Card(s) and Wallet. A 3% fee will be charged for each transaction.',
  bankAccount:
    'No fees for funding your Fleet Card(s) and Wallet using your bank account. Fleet uses Stripe to instantly verify your bank account.',
  bankAcctMandateCopy: `If you use Fleet Labs' services or purchase additional
    products periodically pursuant to Fleet Labs' terms, you
    authorize Fleet Labs to debit your bank account periodically.
    Payments that fall outside of the regular debits authorized
    above will only be debited after your authorization is
    obtained.`,
  cardDisclaimer:
    'By paying with a credit card you are subject to 3% transaction fees. To avoid this, Fleet recommends connecting directly to a bank account.',
}

export const BankMandateModalCopy = {
  requires_payment_method: {
    icon: <AlertIcon color={palette.error.red500} width={50} height={50} />,
    title: 'Confirmation Failed',
    body: 'Please try again or attempt again with a different payment method. If this continues, please contact Support.',
  },
  verify_with_microdeposits: {
    icon: (
      <SuccessIcon color={palette.success.green600} height={50} width={50} />
    ),
    title: 'Almost There!',
    message:
      'This accounts needs to be verified via microdeposits. Please check your email to learn more and contact Support for questions.',
  },
  succeeded: {
    icon: (
      <SuccessIcon color={palette.success.green600} height={50} width={50} />
    ),
    title: 'Confirmation Succeeded',
    message: 'Your account has been connected.',
  },
}
