export default {
  50: '#FFFBF2',
  100: '#FFF7E0',
  200: '#FFEDB8',
  300: '#FFE08A',
  400: '#FFD15C',
  500: '#FFBF2E',
  600: '#E0A000',
  700: '#B88400',
  800: '#8A6600',
  900: '#523D00',
}
