import { MapStyles } from 'core-system/Map'
import { Expression } from 'mapbox-gl'
import palette from 'core-system/Themes/palette'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import { LayerProps } from 'react-map-gl/mapbox'

export const colorArray = [
  '#C30078',
  '#4EC7C4',
  palette.primary.pink400,
  palette.primary.pink800,
  '#5FCD6A',
  '#3A78FF',
  '#C19CFF',
  '#FF7076',
  '#7ADBF8',
]

const getRecentTripStyle = (
  modeTypeGroupings: { modeType: string; numTrips: number }[]
): LayerProps => {
  const activeModeTypes = modeTypeGroupings.map((group) => group.modeType)

  const queryBody = modeTypeGroupings.flatMap((group, idx) => {
    if (group.modeType === 'OTHER') {
      return [
        ...ProgramUtils.modeTypes,
        ...ProgramUtils.transitModeTypes,
      ].flatMap((modeType) => {
        if (!activeModeTypes.includes(modeType as string)) {
          return [['==', ['get', 'tripsTag'], modeType], colorArray[idx]]
        } else {
          return []
        }
      })
    } else {
      return [['==', ['get', 'tripsTag'], group.modeType], colorArray[idx]]
    }
  })

  return {
    id: 'trips',
    type: 'circle',
    beforeId: 'settlement-major-label',
    paint: {
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        MapStyles.pointSize.smallNormal,
        15,
        MapStyles.pointSize.largeNormal,
      ] as Expression,
      'circle-color': [
        'case',
        ...queryBody,
        palette.primary.pink500,
      ] as Expression,
      'circle-stroke-width': MapStyles.pointStyle.pointStrokeWidth,
      'circle-stroke-color': MapStyles.pointStyle.pointStrokeColor,
      'circle-opacity': MapStyles.pointStyle.pointOpacity,
      'circle-stroke-opacity': MapStyles.pointStyle.pointStrokeOpacity,
    },
  }
}

const recentTripStyle: LayerProps = {
  id: 'trips',
  type: 'circle',
  beforeId: 'settlement-major-label',
  paint: {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10,
      MapStyles.pointSize.smallNormal,
      15,
      MapStyles.pointSize.largeNormal,
    ] as Expression,
    'circle-color': [
      'case',
      ['==', ['get', 'tripsTag'], 'EBIKE'],
      '#C30078',
      ['==', ['get', 'tripsTag'], 'BIKE'],
      '#C30078',

      ['==', ['get', 'tripsTag'], 'BIKESHARE'],
      '#4EC7C4',

      ['==', ['get', 'tripsTag'], 'ESCOOTER'],
      palette.primary.pink400,
      ['==', ['get', 'tripsTag'], 'SCOOTER'],
      palette.primary.pink400,

      ['==', ['get', 'tripsTag'], 'SCOOTERSHARE'],
      palette.primary.pink800,

      ['==', ['get', 'tripsTag'], 'EMOPED'],
      '#5FCD6A',
      ['==', ['get', 'tripsTag'], 'MOPED'],
      '#5FCD6A',

      ['==', ['get', 'tripsTag'], 'MOPEDSHARE'],
      '#3A78FF',

      ['==', ['get', 'tripsTag'], 'SHUTTLE'],
      '#C19CFF',

      ['==', ['get', 'tripsTag'], 'CARPOOL'],
      '#FF7076',

      ['==', ['get', 'tripsTag'], 'VANPOOL'],
      '#7ADBF8',

      ['==', ['get', 'tripsTag'], 'CAR'],
      palette.primary.pink400,

      ['==', ['get', 'tripsTag'], 'WALK'],
      palette.primary.pink400,

      ['==', ['get', 'tripsTag'], 'RIDESHARE'],
      '#C30078',

      //transit
      ['==', ['get', 'tripsTag'], 'COMMRAIL'],
      palette.primary.pink400,
      ['==', ['get', 'tripsTag'], 'HEAVYRAIL'],
      palette.primary.pink400,
      ['==', ['get', 'tripsTag'], 'LITERAIL'],
      palette.primary.pink400,
      ['==', ['get', 'tripsTag'], 'BUS'],
      palette.primary.pink400,
      ['==', ['get', 'tripsTag'], 'FERRY'],
      palette.primary.pink400,

      palette.primary.pink500,
    ] as Expression,
    'circle-stroke-width': MapStyles.pointStyle.pointStrokeWidth,
    'circle-stroke-color': MapStyles.pointStyle.pointStrokeColor,
    'circle-opacity': MapStyles.pointStyle.pointOpacity,
    'circle-stroke-opacity': MapStyles.pointStyle.pointStrokeOpacity,
  },
}

const shapeOutlineSize = [
  'interpolate',
  ['linear'],
  ['zoom'],
  3,
  1,
  10,
  2,
  15,
  4,
] as Expression

const isochroneOutlineStyle: LayerProps = {
  id: 'detailedTrip',
  type: 'line',
  beforeId: 'settlement-minor-label',
  paint: {
    'line-color': palette.primary.pink400,
    'line-opacity': 0.7,
    'line-width': shapeOutlineSize,
  },
}

export default {
  recentTripStyle,
  getRecentTripStyle,
  isochroneOutlineStyle,
}
