import React from 'react'
import styled from 'styled-components'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import CalendarIcon from 'core-system/Icons/Misc/Calendar'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import moment from 'moment'

const StyledChevron = styled.div<{ isLeft?: boolean; isDisabled?: boolean }>`
  transform: rotate(${(props) => (props.isLeft ? '90deg' : '270deg')});
  margin-right: ${(props) => (props.isLeft ? '0.5rem' : 0)};
  color: ${(props) =>
    props.isDisabled
      ? props.theme.palette.text.disabled
      : props.theme.palette.text.primary};
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink500};
  }
`

const StyledCalendarIcon = styled(CalendarIcon)`
  margin-bottom: 0.125rem;
`

interface MonthToggleProps {
  currentDate: string
  onIncreaseClick: () => void
  onDecreaseClick: () => void
}

const MonthToggle = React.memo((props: MonthToggleProps) => {
  const { currentDate, onIncreaseClick, onDecreaseClick } = props

  const today = moment()

  return (
    <FlexContainer justifyContent='space-between' width='100%' height='2rem'>
      <FlexContainer alignItems='center'>
        <StyledCalendarIcon color={palette.text.primary} />
        <Text variant='h5' margin='0.125rem 0 0 0.5rem' hideOverflow>
          {moment(currentDate).format('MMMM, YYYY')}
        </Text>
      </FlexContainer>
      <FlexContainer>
        <StyledChevron isLeft={true} onClick={onDecreaseClick}>
          <ChevronIcon />
        </StyledChevron>
        <StyledChevron
          isDisabled={moment(currentDate).add(1, 'month').isAfter(today)}
          onClick={onIncreaseClick}
        >
          <ChevronIcon />
        </StyledChevron>
      </FlexContainer>
    </FlexContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MonthToggle.displayName = 'MonthToggle'
}

export default MonthToggle
