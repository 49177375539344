import React from 'react'
import { styled } from '@mui/material/styles'
import Tabs from 'core-system/Tabs/Tabs'
import AddIcon from 'core-system/Icons/Actions/Add'
import PrimaryButton from 'core-system/Button/PrimaryButton'

const Container = styled('div')(() => ({
  display: 'relative',
}))

const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '1.5rem',
}))

interface ConciergeHeaderProps {
  activeId: number
  handleClick: (id: number) => void
  addNewAnnouncement: () => void
  buttonText: string
}

const ConciergeHeader = React.memo((props: ConciergeHeaderProps) => {
  const { activeId, handleClick, addNewAnnouncement, buttonText } = props

  return (
    <Container>
      <ButtonContainer>
        <PrimaryButton startIcon={<AddIcon />} onClick={addNewAnnouncement}>
          {buttonText}
        </PrimaryButton>
      </ButtonContainer>
      <Tabs
        value={activeId}
        onChange={(_, value) => handleClick(value)}
        aria-label='concierge page tabs'
        tabs={[
          { label: 'Announcements', value: 1 },
          { label: 'FAQs', value: 2 },
        ]}
      />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ConciergeHeader.displayName = 'ConciergeHeader'
}

export default ConciergeHeader
