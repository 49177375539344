import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import InfoIcon from 'core-system/Icons/Misc/Info'
import SvgTooltip from 'core-system/Tooltip/SvgTooltip'
import FormattingUtils from 'shared/FormattingUtils'
import { NumberFormatter, NumberInput } from 'core-system/Input/NumberInput'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import { space, SpaceProps } from 'styled-system'
import FlexContainer from 'core-system/FlexContainer'
import Radio from 'core-system/Radio'

const Container = styled.div<SpaceProps>`
  ${(props) => props.theme.baseCard}
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;

  ${space}
`

const BudgetContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
`

const RecBudgetContainer = styled.div<{ test: boolean }>`
  background: ${(props) => props.theme.palette.primary.pink200};
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  margin-left: 0.5rem;
  flex: 1;

  /* ${(props) =>
    props.test &&
    css`
      width: 43%;
    `}; */
`

const CaptionText = styled.div`
  ${(props) => props.theme.typography.caption}
  text-transform: uppercase;
  letter-spacing: 0.7px;
  line-height: 0.875rem;
`

const RecBudget = styled.div`
  ${(props) => props.theme.typography.h5};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  letter-spacing: 0;

  svg {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`

const StaticContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.palette.primary.pink300};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  border-radius: 0.5rem;
`

interface BudgetSelectorProps extends SpaceProps {
  budget: number
  recBudget: number
  onChange: (budget: number) => void
  incrementVal?: number
  error?: boolean
  canEdit?: boolean
  test?: boolean
  customHeader?: string
}

const BudgetSelector = (props: BudgetSelectorProps) => {
  const {
    budget,
    onChange,
    recBudget,
    incrementVal = 5,
    error,
    canEdit = true,
    test = false,
    customHeader,
    ...rest
  } = props

  const [budgetType, setBudgetType] = useState(0)
  const [testBudget, setTestBudget] = useState(40)

  return (
    <Container {...rest}>
      <Text variant='h4' marginBottom='1rem'>
        How Much?
      </Text>
      {test ? (
        <>
          <Text
            variant='body1'
            textColor={palette.text.secondary}
            marginBottom='0.25rem'
          >
            Max Budget
          </Text>
          <BudgetContainer>
            <NumberInput
              padding='1rem'
              onValueChange={onChange}
              value={budget / 100}
              iconSize={32}
              incrementVal={incrementVal}
              maxWidth='460px'
            >
              <NumberFormatter decimalScale={0} fontVariant='h1' />
            </NumberInput>
            <FlexContainer flexDirection='column' marginLeft='0.625rem'>
              <Radio
                label='Monthly'
                active={budgetType === 0}
                onClick={() => setBudgetType(0)}
                marginBottom='0.75rem'
              />
              <Radio
                label='Annual'
                active={budgetType === 1}
                onClick={() => setBudgetType(1)}
              />
            </FlexContainer>
          </BudgetContainer>
          <Text
            variant='body1'
            textColor={palette.text.secondary}
            margin='1rem 0 0.25rem'
          >
            Max Transaction Amount
          </Text>
          <BudgetContainer>
            {canEdit ? (
              <NumberInput
                padding='1rem'
                onValueChange={(newValue) => setTestBudget(newValue)}
                value={testBudget}
                iconSize={32}
                incrementVal={incrementVal}
                maxWidth='460px'
              >
                <NumberFormatter decimalScale={0} fontVariant='h1' />
              </NumberInput>
            ) : (
              <StaticContainer>
                <Text variant='h1'>
                  {FormattingUtils.formatDollar(budget, 0)}
                </Text>
              </StaticContainer>
            )}
            <RecBudgetContainer test={test}>
              <CaptionText>Recommended Minimum Budget:</CaptionText>
              <RecBudget>
                {FormattingUtils.formatDollar(recBudget, 0)}
                <SvgTooltip
                  svg={<InfoIcon width={20} height={20} />}
                  height={'1.5rem'}
                  isMultiLine={true}
                  description={
                    'Recommended budget is based on hybrid work model where commuter are in office 3 days a week'
                  }
                />
              </RecBudget>
            </RecBudgetContainer>
          </BudgetContainer>
        </>
      ) : (
        <>
          <Text
            variant='body1'
            textColor={palette.text.secondary}
            marginBottom='0.25rem'
          >
            {customHeader ? customHeader : 'Monthly Budget Per Commuter'}
          </Text>
          <BudgetContainer>
            {canEdit ? (
              <NumberInput
                padding='1rem'
                onValueChange={onChange}
                value={budget / 100}
                iconSize={32}
                incrementVal={incrementVal}
              >
                <NumberFormatter decimalScale={0} fontVariant='h1' />
              </NumberInput>
            ) : (
              <StaticContainer>
                <Text variant='h1'>
                  {FormattingUtils.formatDollar(budget, 0)}
                </Text>
              </StaticContainer>
            )}
            <RecBudgetContainer test={test}>
              <CaptionText>Recommended Minimum Budget:</CaptionText>
              <RecBudget>
                {FormattingUtils.formatDollar(recBudget, 0)}
                <SvgTooltip
                  svg={<InfoIcon width={20} height={20} />}
                  height={'1.5rem'}
                  isMultiLine={true}
                  description={
                    'Recommended budget is based on hybrid work model where commuters are in office 3 days a week'
                  }
                />
              </RecBudget>
            </RecBudgetContainer>
          </BudgetContainer>
          {error && (
            <Text
              variant='body1'
              textColor={palette.error.red500}
              marginTop='0.25rem'
            >
              Please enter a valid budget
            </Text>
          )}
        </>
      )}
    </Container>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  BudgetSelector.displayName = 'BudgetSelector'
}

export default BudgetSelector
