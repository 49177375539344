import React from 'react'
import Calendar, { DatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// override default styles
import './datepicker.css'
import { buildComponent } from '../utils/buildComponent'

const datePickerHeight = 325

// This picker will always open downwards and stay in the same position on scroll
// Modifying the y position was the only way I could get it to work
const DatePicker = (props: DatePickerProps) => {
  return (
    <Calendar
      {...props}
      popperPlacement='bottom-start'
      popperModifiers={[
        {
          name: 'placement',
          fn: (state) => {
            const { placement, y } = state

            return {
              ...state,
              y: placement.startsWith('top') ? y + datePickerHeight : y,
            }
          },
        },
      ]}
    />
  )
}

export default buildComponent(DatePicker, 'DatePicker')
