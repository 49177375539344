import Chip from 'core-system/Chip'
import { statusMap } from 'core-system/Chip/Chip'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import { OneTimePurchase as OTPIcon } from 'core-system/Icons/Micromobility'
import EnterArrowIcon from 'core-system/Icons/Misc/EnterArrow'
import {
  Flex as FlexIcon,
  Micromobility as MicromobilityIcon,
} from 'core-system/Icons/Sidebar'
import { Car as GRHIcon } from 'core-system/Icons/modeTypes'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import { ProgramGroup } from 'redux/programs/programsTypes'
import FormattingUtils from 'shared/FormattingUtils'
import { Locations } from 'shared/Router/Locations'
import styled, { css } from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  margin-bottom: 1.5rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const IconContainer = styled(FlexContainer)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.primary.pink200};
`

const ArrowContainer = styled.div`
  min-width: ${(props) => props.theme.pxToRem(17)};
  height: ${(props) => props.theme.pxToRem(19)};
`

const ChevronContainer = styled(FlexContainer)<{ open: boolean }>`
  margin-left: 2.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  user-select: none;

  ${(props) =>
    props.open &&
    css`
      transform: rotate(180deg);
    `};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const RowContainer = styled.div<{ isOpen: boolean }>`
  height: ${(props) => (props.isOpen ? props.theme.pxToRem(55) : 0)};
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
`

const HyperLink = styled(Text)`
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.primary};
  margin-left: 1rem;

  &:hover {
    color: ${(props) => props.theme.palette.primary.pink700};
  }
`

const ProgramRow = styled(FlexContainer)`
  padding: 0.5rem 1.25rem;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;
  justify-content: space-between;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const TextContainer = styled.div`
  padding-left: 1.25rem;
  overflow: hidden;
`

const programData = {
  flex: {
    icon: <FlexIcon width={22} height={22} color={palette.text.secondary} />,
    location: Locations.Flex.Programs,
    urlSubpath: 'flex',
  },
  micromobility: {
    icon: (
      <MicromobilityIcon
        width={22}
        height={22}
        color={palette.text.secondary}
      />
    ),
    location: Locations.Micromobility.Programs,
    urlSubpath: 'micromobility',
  },
  otp: {
    icon: <OTPIcon width={22} height={22} color={palette.text.secondary} />,
    location: Locations.OTP.Programs,
    urlSubpath: 'one-time-purchase',
  },
  grh: {
    icon: <GRHIcon width={22} height={22} color={palette.text.secondary} />,
    location: Locations.GRH.Programs,
    urlSubpath: 'guaranteed-rides',
  },
}

const renderProgramSection = (
  programType: string,
  programName: string,
  programStatus: any,
  isOpen: boolean,
  setIsOpen: (status: boolean) => void,
  allPrograms: Dictionary<ProgramGroup>,
  segmentsMap: Dictionary<Segment>,
  navigate: NavigateFunction
) => {
  return (
    <>
      <FlexContainer alignItems='center' padding='0.5rem'>
        <IconContainer center>{programData[programType].icon}</IconContainer>
        <Link to={programData[programType].location} rel='noopener noreferrer'>
          <HyperLink variant='action3'>{`${programName} Programs`}</HyperLink>
        </Link>
        <FlexContainer marginLeft='auto' alignItems='center'>
          {programStatus.active > 0 && (
            <Text variant='action4' noWrap>
              {programStatus.active} Active
            </Text>
          )}
          {programStatus.active > 0 && programStatus.pending > 0 && (
            <Divider margin='0 1rem' direction='vertical' size='1rem' />
          )}
          {programStatus.pending > 0 && (
            <Text variant='action4' noWrap>
              {programStatus.pending} Pending
            </Text>
          )}
          <ChevronContainer
            center
            open={isOpen}
            onClick={() => setIsOpen(!isOpen)}
          >
            <ChevronIcon color={palette.text.secondary} />
          </ChevronContainer>
        </FlexContainer>
      </FlexContainer>
      <>{renderProgramRows(allPrograms, segmentsMap, isOpen, navigate)}</>
    </>
  )
}

const renderProgramRows = (
  allPrograms: Dictionary<ProgramGroup>,
  segmentsMap: Dictionary<Segment>,
  isOpen: boolean,
  navigate: NavigateFunction
) => {
  return Object.values(allPrograms).map((programGroup) => {
    const activeRowFilter = programGroup.active ? 'active' : 'nextMonth'
    const activeSegment = segmentsMap[programGroup[activeRowFilter].segment]
    const activeRow = programGroup[activeRowFilter]
    return activeSegment ? (
      <RowContainer key={activeSegment.id} isOpen={isOpen}>
        <Divider margin='0.5rem 0' />
        <ProgramRow
          onClick={() =>
            navigate(
              `/${programData[activeRow.type.toLowerCase()].urlSubpath}/${
                activeSegment.id
              }`
            )
          }
        >
          <FlexContainer alignItems='center' width='25%'>
            <ArrowContainer>
              <EnterArrowIcon
                width={17}
                height={17}
                color={palette.text.placeholder}
              />
            </ArrowContainer>
            <TextContainer>
              <Text variant='action3' hideOverflow>
                {activeSegment.name}
              </Text>
            </TextContainer>
          </FlexContainer>
          {activeRow.status === 'PENDING' ? (
            <Chip variant={statusMap[activeRow.status].variant}>
              {statusMap[activeRow.status].text}
            </Chip>
          ) : (
            <Text variant='action2'>
              {FormattingUtils.formatDollar(activeRow.spend, 0)}
            </Text>
          )}
        </ProgramRow>
      </RowContainer>
    ) : null
  })
}

const programStatus = (program: Dictionary<ProgramGroup>) => {
  return Object.values(program).reduce(
    (agg, program) => {
      const activeRow = program.active || program.nextMonth
      if (activeRow.status === 'ACTIVE' || activeRow.status === 'UPDATING') {
        agg.active += 1
      } else if (activeRow.status === 'PENDING') {
        agg.pending += 1
      }
      return agg
    },
    { active: 0, pending: 0 }
  )
}

interface DashboardProgramsProps {
  segmentsMap: Dictionary<Segment>
}

const DashboardPrograms = React.memo((props: DashboardProgramsProps) => {
  const { segmentsMap } = props

  const { flexPrograms } = useSelector((state: AppState) => state.flex)
  const { micromobilityPrograms } = useSelector(
    (state: AppState) => state.micromobility
  )
  const { otpPrograms } = useSelector((state: AppState) => state.otp)
  const { grhPrograms } = useSelector((state: AppState) => state.grh)
  const products = new Set(
    useSelector((state: AppState) => state.employer.profile.products)
  )

  const [isFlexOpen, setIsFlexOpen] = useState(false)
  const [isMicromobilityOpen, setIsMicromobilityOpen] = useState(false)
  const [isOtpOpen, setIsOtpOpen] = useState(false)
  const [isGrhOpen, setIsGrhOpen] = useState(false)

  const navigate = useNavigate()

  const programsStatus = {
    flex: flexPrograms ? programStatus(flexPrograms) : null,
    micromobility: micromobilityPrograms
      ? programStatus(micromobilityPrograms)
      : null,
    otp: otpPrograms ? programStatus(otpPrograms) : null,
    grh: grhPrograms ? programStatus(grhPrograms) : null,
  }

  return (
    <Container>
      <Content>
        <Text variant='h5' marginBottom='1.5rem'>
          My Programs
        </Text>
        {flexPrograms &&
          Object.keys(flexPrograms).length > 0 &&
          products.has('FLEX') &&
          renderProgramSection(
            'flex',
            'Flex',
            programsStatus.flex,
            isFlexOpen,
            setIsFlexOpen,
            flexPrograms,
            segmentsMap,
            navigate
          )}
        {micromobilityPrograms &&
          Object.keys(micromobilityPrograms).length > 0 &&
          products.has('MICROMOBILITY') &&
          renderProgramSection(
            'micromobility',
            'Micromobility',
            programsStatus.micromobility,
            isMicromobilityOpen,
            setIsMicromobilityOpen,
            micromobilityPrograms,
            segmentsMap,
            navigate
          )}
        {otpPrograms &&
          Object.keys(otpPrograms).length > 0 &&
          products.has('OTP') &&
          renderProgramSection(
            'otp',
            'One Time Purchase',
            programsStatus.otp,
            isOtpOpen,
            setIsOtpOpen,
            otpPrograms,
            segmentsMap,
            navigate
          )}
        {grhPrograms &&
          Object.keys(grhPrograms).length > 0 &&
          products.has('GRH') &&
          renderProgramSection(
            'grh',
            'Guaranteed Ride Home',
            programsStatus.grh,
            isGrhOpen,
            setIsGrhOpen,
            grhPrograms,
            segmentsMap,
            navigate
          )}
      </Content>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardPrograms.displayName = 'DashboardPrograms'
}

export default DashboardPrograms
