import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardNumberElementOptions } from '@stripe/stripe-js'
import { employerActions } from 'redux/employer/employerSlice'
import Button from 'core-system/Button'
import LoadingBar from 'core-system/LoadingBar'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import Text from 'core-system/Text'
import TextField from 'core-system/TextField'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import EmployerService from 'redux/employer/employerService'
import styled from 'styled-components'

const CardContainer = styled.div<{ focused: boolean }>`
  background-color: ${(props) => props.theme.palette.primary.pink700};
  padding: ${(props) => props.theme.pxToRem(10)} 1rem;
  border: 0.0625rem solid ${(props) => props.theme.palette.grey.grey300};
  border-radius: 0.5rem;
  min-width: 2.5rem;
  height: ${(props) => props.theme.pxToRem(41)};
  width: 100%;
  padding: ${(props) => props.theme.pxToRem(10)} 1rem;
  :hover {
    color: ${(props) => props.theme.palette.text.secondary};
    border-color: ${(props) => props.theme.palette.text.secondary};
  }

  ${(props) =>
    props.focused
      ? {
          backgroundColor: props.theme.palette.white,
          borderColor: props.theme.palette.text.secondary,
        }
      : null}
`

const HelpText = styled.div`
  ${(props) => props.theme.typography.body2};
  color: ${(props) => props.theme.palette.error.red500};
  margin-top: 0.25rem;
`

const cardElementOpts = {
  iconStyle: 'solid' as StripeCardNumberElementOptions['iconStyle'],
  hidePostalCode: true,
  style: {
    base: {
      color: palette.text.primary,
      '&::placeholder': {
        color: palette.text.placeholder,
      },
    },
    invalid: {
      iconColor: palette.error.red500,
      color: palette.error.red500,
    },
    complete: {
      iconColor: palette.success.green600,
    },
  },
}

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  > div {
    flex: 1 1 0;
    padding-right: 1rem;

    &:last-child {
      padding-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    display: block;

    > div {
      padding: 0;
    }
  }
`

const StyledText = styled(Text)`
  a {
    color: ${(props) => props.theme.palette.text.placeholder};
    text-decoration: none;
    font-style: italic;
    &:hover {
      color: ${(props) => props.theme.palette.text.primary};
      text-decoration: underline;
    }
  }
`

const isDisabled = (billingDetails, stripeError) => {
  const details =
    billingDetails &&
    Object.keys(billingDetails).reduce((vals, k) => {
      if (
        k !== 'line2' &&
        billingDetails[k] !== null &&
        billingDetails[k] !== ''
      ) {
        vals.push(billingDetails[k])
      }
      return vals
    }, [])

  if (stripeError.complete && details && details.length >= 5) {
    return false
  }
  return true
}

interface AddCreditCardModalProps {
  closeModal: (e: React.MouseEvent<any>) => void
  open: boolean
  isManage?: boolean
  moveToNextStep?: () => void
}

const AddCreditCardModal = (props: AddCreditCardModalProps) => {
  const { isManage, closeModal, open, moveToNextStep } = props
  const [cardElementFocus, setCardElementFocus] = useState(false)
  const [cardError, setCardError] = useState({
    complete: null,
    errorMsg: null,
    empty: null,
  })
  const [billingDetails, setBillingDetails] = useState(null)
  const [cardSetupError, setCardSetupError] = useState(null)
  const [processing, setProcessing] = useState(null)
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setProcessing(true)

    try {
      // get client id
      const code = await EmployerService.getClientPaymentId().then(
        (res) => res.data
      )
      // make stripe call to add a new card details
      const result = await stripe.confirmCardSetup(code, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: billingDetails.name,
            address: {
              country: 'US',
              city: billingDetails.city,
              line1: billingDetails.line1,
              line2: billingDetails.line2,
              postal_code: billingDetails.postalCode,
              state: billingDetails.state,
            },
          },
        },
      })
      if (result.error) {
        setCardSetupError(result.error.message)
        setProcessing(false)
        return
      }
      setTimeout(() => {
        dispatch(employerActions.getBillingInfo())
        if (moveToNextStep) {
          moveToNextStep()
        }
        closeModal(e)
      }, 5000)
    } catch (_e) {
      setCardSetupError('Oops, something went wrong, please try again.')
      setProcessing(false)
    }
  }

  const handleCardDetailsChange = (ev) => {
    setCardError({
      errorMsg: ev.error ? ev.error.message : null,
      complete: ev.complete,
      empty: ev.empty,
    })
  }

  const onBillingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setBillingDetails({
      ...billingDetails,
      [name]: value,
    })
  }

  const handleClose = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setProcessing(false)
      setCardSetupError(false)
      closeModal(e)
    },
    [closeModal, setProcessing]
  )

  return (
    <Modal open={open} onClose={handleClose} width={pxToRem(752)}>
      <ModalHeader
        title={isManage ? 'Update Information' : 'Add Payment Information'}
      />
      <ModalBody>
        <Text variant='body1' marginBottom='1rem'>
          By paying with a credit card you are subject to 3% transaction fees.
          To avoid this, Fleet recommends connecting directly to a bank account.
        </Text>
        <Text variant='action2' mb='1rem'>
          Credit Card
        </Text>
        <CardContainer
          focused={cardElementFocus}
          data-cy='accounts-billing-credit-card-card-container'
        >
          <CardElement
            options={cardElementOpts}
            onChange={handleCardDetailsChange}
            onFocus={() => setCardElementFocus(true)}
            onBlur={() => setCardElementFocus(false)}
          />
        </CardContainer>
        {cardError.errorMsg && <HelpText>{cardError.errorMsg}</HelpText>}
        <Text variant='action2' mt='1.5rem' mb='1rem'>
          Billing Address
        </Text>
        <div>
          <TextField
            label='Name'
            name='name'
            type='text'
            required
            onChange={onBillingChange}
            mb='1rem'
            data-cy='accounts-billing-credit-card-modal-name-input'
          />
          <TextField
            label='Address Line 1'
            name='line1'
            type='text'
            onChange={onBillingChange}
            required
            mb='1rem'
            data-cy='accounts-billing-credit-card-modal-address1-input'
          />
          <TextField
            label='Address Line 2'
            name='line2'
            type='text'
            onChange={onBillingChange}
            required
            mb='1rem'
          />
          <FlexContainer>
            <TextField
              label='City'
              name='city'
              type='text'
              onChange={onBillingChange}
              required
              data-cy='accounts-billing-credit-card-modal-city-input'
            />
            <TextField
              label='State'
              name='state'
              type='text'
              onChange={onBillingChange}
              required
              data-cy='accounts-billing-credit-card-modal-state-input'
            />
            <TextField
              label='Postal Code'
              name='postalCode'
              type='text'
              onChange={onBillingChange}
              required
              data-cy='accounts-billing-credit-card-modal-postal-input'
            />
          </FlexContainer>
        </div>
        {cardSetupError && (
          <Text
            variant='body1'
            mt='1rem'
            mb='0.5rem'
            textColor={palette.error.red500}
          >
            {cardSetupError}
          </Text>
        )}
        <StyledText
          variant='body2'
          mt='1rem'
          textColor={palette.text.placeholder}
        >
          You authorize Fleet to send instructions to the financial institution
          that issued my card to take payments from my card account in
          accordance with the terms of my agreement with you.{' '}
          <a
            href='https://www.movewithfleet.com/terms-of-use'
            target='_blank'
            rel='noopener noreferrer'
          >
            Full terms are available here.
          </a>
        </StyledText>
      </ModalBody>
      <ModalFooter hasShadow={true}>
        <Button variant='tertiary' onClick={closeModal} marginRight='1rem'>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isDisabled(billingDetails, cardError) || processing}
        >
          {processing ? (
            <LoadingBar style={{ width: '5rem' }} />
          ) : isManage ? (
            'Update'
          ) : (
            'Add'
          )}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AddCreditCardModal.displayName = 'AddCreditCardModal'
}

export default AddCreditCardModal
