import React, { useEffect, useState } from 'react'
import { sortBy, reverse, values } from 'lodash-es'
import makeStyles from '@mui/styles/makeStyles'
import ChatBubble from './bubbles/ChatBubble'
import MapBubble from './bubbles/MapBubble'
import { ChatTypes } from './types'
import ThinkingBubble from './bubbles/ThinkingBubble'
import { GOOGLE_MAPS_API_KEY } from 'redux/config/services/Constants'

const useStyles = makeStyles(() => ({
  mainContainer: {
    paddingTop: 100,
    paddingBottom: 100,
    width: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column-reverse',
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: 'white',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'lightgray',
      webkitBorderRadius: 10,
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'lightgray',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: 'lightgray',
  },
  partnerBubble: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 4,
    paddingLeft: 16,
  },
  ownBubble: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 4,
    paddingRight: 16,
  },
}))

interface ChatContainerProps {
  chats: Record<string, ChatTypes>
  chatIsInView: (chat: ChatTypes, index: number) => void
  className?: string
  isOwnChat: (chat: ChatTypes) => boolean
  showLike?: boolean
  onLikeClick?: (chat: ChatTypes) => void
  renderMarkdown?: boolean
  onTypingAnimationComplete: (chatId: string) => void
}

const ChatContainer: React.FC<ChatContainerProps> = ({
  chats = {},
  chatIsInView = () => {},
  className,
  isOwnChat,
  showLike = true,
  onLikeClick = () => {},
  renderMarkdown = false,
  onTypingAnimationComplete = () => {},
}) => {
  const classes = useStyles()
  const [mapApiLoaded, setMapApiLoaded] = useState(false)

  // Load map api if not already loaded
  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,marker`
      script.onload = () => {
        setMapApiLoaded(true)
      }
      document.head.appendChild(script)
    } else {
      setMapApiLoaded(true)
    }
  }, [])

  // whenever chat changes, scroll to bottom smoothly if not already at the top
  useEffect(() => {
    const container = document.querySelector(`.${classes.mainContainer}`)
    if (container) {
      const isAtTop = container.scrollTop === 0
      if (!isAtTop) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth',
        })
      }
    }
  }, [chats])

  if (!chats) {
    return null
  }

  const sortedChats = reverse(
    sortBy(values(chats), (chat) => new Date(chat.timeStamp))
  )

  const chatTypeDisplay = (chat: ChatTypes, index: number) => {
    if (chat.chatType === 'thinking') {
      return (
        <ThinkingBubble
          text={chat.message}
          chatId={chat.id}
          chatIsInView={() => chatIsInView(chat, index)}
          thinkingAnimation={chat.thinkingAnimation}
        />
      )
    }
    if (chat.chatType === 'map') {
      const coordinates = JSON.parse(chat.message)
      return (
        <MapBubble
          chatId={chat.id}
          chatIsInView={() => chatIsInView(chat, index)}
          showLike={showLike}
          onLikeClick={() => onLikeClick(chat)}
          isLiked={chat.liked}
          coordinates={coordinates}
          mapApiLoaded={mapApiLoaded}
        />
      )
    }
    return (
      <ChatBubble
        bubbleType={isOwnChat(chat) ? 'own' : 'partner'}
        text={chat.message}
        chatId={chat.id}
        chatIsInView={() => chatIsInView(chat, index)}
        renderMarkdown={renderMarkdown}
        showLike={showLike}
        onLikeClick={() => onLikeClick(chat)}
        isLiked={chat.liked}
        typingAnimation={chat.typingAnimation}
        onTypingAnimationComplete={() => onTypingAnimationComplete(chat.id)}
      />
    )
  }

  return (
    <div className={`${classes.mainContainer} ${className}`}>
      {sortedChats.map((chat, index) => (
        <div
          key={chat.id}
          className={
            isOwnChat(chat) ? classes.ownBubble : classes.partnerBubble
          }
        >
          {chatTypeDisplay(chat, index)}
        </div>
      ))}
    </div>
  )
}

export default React.memo(ChatContainer)
