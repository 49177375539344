import Banner from 'core-system/Banner'
import Chip, { statusMap } from 'core-system/Chip/Chip'
import FlexContainer from 'core-system/FlexContainer'
import MicromobilityIcon from 'core-system/Icons/Sidebar/Micromobility'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import { ProgramGroup } from 'redux/programs/programsTypes'
import FormattingUtils from 'shared/FormattingUtils'
import { Locations } from 'shared/Router/Locations'
import StatusUtils from 'shared/StatusUtils'
import styled from 'styled-components'
import MicromobilityPreviewModal from './Overview/components/MicromobilityPreviewModal'
import NoPaymentModal from 'shared/components/NoPaymentModal'

const ProgramRow = styled.div`
  ${(props) => props.theme.baseCard}
  cursor: pointer;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const PurpleContainer = styled(FlexContainer)`
  padding: 0.5rem;
  border-radius: 0.3125rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  height: 2.5rem;
  width: 2.5rem;
`

const StatContainer = styled.div`
  margin-left: 2.4375rem;
`

const SpendContainer = styled.div`
  min-width: ${(props) => props.theme.pxToRem(60)};
  margin-left: 2.75rem;
`

const getActiveRow = (programGroup: ProgramGroup) => {
  //check if active program is cancelling
  if (programGroup.active && programGroup?.nextMonth?.status === 'CANCELLING') {
    return programGroup.nextMonth
  } else if (programGroup.active) {
    return programGroup.active
  } else if (programGroup.nextMonth) {
    return programGroup.nextMonth
  } else {
    return null
  }
}

const renderProgramRows = (
  allSegments: Segment[],
  micromobilityPrograms: Dictionary<ProgramGroup>,
  handleOnClick: (segmentId: string) => void
) => {
  return allSegments.map((segment) => {
    const isActive = micromobilityPrograms[segment.id]
    const activeRow =
      isActive && getActiveRow(micromobilityPrograms[segment.id])

    return (
      <ProgramRow key={segment.id} onClick={() => handleOnClick(segment.id)}>
        <FlexContainer alignItems='center'>
          <PurpleContainer center>
            <MicromobilityIcon color={palette.text.secondary} />
          </PurpleContainer>
          <Text variant='action2' marginLeft='1rem'>
            {segment.name === 'All Employees' ? 'All Commuters' : segment.name}
          </Text>
          <FlexContainer alignItems='center' marginLeft='auto'>
            <Chip
              variant={
                isActive ? statusMap[activeRow.status].variant : 'disabled'
              }
            >
              {isActive ? statusMap[activeRow.status].text : 'off'}
            </Chip>
            <SpendContainer>
              {isActive && (
                <div>
                  <Text variant='body2' textColor={palette.text.placeholder}>
                    Spend
                  </Text>
                  <Text variant='action2'>
                    {FormattingUtils.formatDollar(activeRow.spend, 0)}
                  </Text>
                </div>
              )}
            </SpendContainer>
            <StatContainer>
              <Text variant='body2' textColor={palette.text.placeholder}>
                Commuter Mobility Budget (Monthly)
              </Text>
              <Text
                variant='action2'
                textColor={
                  isActive ? palette.text.primary : palette.text.disabled
                }
              >
                {isActive
                  ? FormattingUtils.formatDollar(activeRow.budget, 0)
                  : '-'}
              </Text>
            </StatContainer>
            <StatContainer>
              <Text variant='body2' textColor={palette.text.placeholder}>
                Commuters
              </Text>
              <Text variant='action2'>{segment.commuters}</Text>
            </StatContainer>
          </FlexContainer>
        </FlexContainer>
      </ProgramRow>
    )
  })
}

const MicromobilityPrograms = React.memo(() => {
  const navigate = useNavigate()

  const micromobilityPrograms = useSelector(
    (state: AppState) => state.micromobility.micromobilityPrograms
  )
  const { allSegments, segmentsMap, hasValidPaymentMethod, profile } =
    useSelector((state: AppState) => state.employer)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isNoPaymentModalOpen, setIsNoPaymentModalOpen] = useState(false)

  const handleOnClick = useCallback(
    (segmentId: string) => {
      navigate(`/micromobility/${segmentId}`, {
        state: { from: Locations.Micromobility.Programs },
      })
      SegmentService.track('segmentNav-action-click', {
        action: `segment-${segmentsMap[segmentId].name}`,
        locationAt: 'micromobility',
      })
    },
    [navigate, segmentsMap]
  )

  const handleNewProgramClick = () => {
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      SegmentService.track('programs-cta-click', {
        location: 'micromobility',
      })
      setIsModalOpen(true)
    } else {
      setIsNoPaymentModalOpen(true)
    }
  }

  if (!micromobilityPrograms || !allSegments) {
    return <Loading fullPage />
  }

  const orderedSegments = [...allSegments].sort((a, b) => {
    const firstSegmentVal = micromobilityPrograms[a.id]
      ? StatusUtils.statusOrder[
          getActiveRow(micromobilityPrograms[a.id]).status
        ]
      : 6
    const secondSegmentVal = micromobilityPrograms[b.id]
      ? StatusUtils.statusOrder[
          getActiveRow(micromobilityPrograms[b.id]).status
        ]
      : 6
    return firstSegmentVal - secondSegmentVal
  })

  return (
    <>
      {Object.keys(micromobilityPrograms).length === 0 ? (
        <>
          <MicromobilityPreviewModal
            open={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
          />
          <Banner
            variant='cta'
            title='Start a Micromobility Program today!'
            description='Give commuters safe, sustainable, and fun commuting options.'
            cta='New Program'
            ctaCallback={() => handleNewProgramClick()}
            background='bannerGeneral'
          />
        </>
      ) : (
        <Banner
          variant='info'
          title='Your Micromobility Programs'
          description='Safe, sustainable, and fun commuting options for your commuters.'
          background='bannerGeneral'
        />
      )}
      <div>
        {renderProgramRows(
          orderedSegments,
          micromobilityPrograms,
          handleOnClick
        )}
      </div>
      <NoPaymentModal
        open={isNoPaymentModalOpen}
        closeModal={() => setIsNoPaymentModalOpen(false)}
        product='micromobility'
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityPrograms.displayName = 'MicromobilityPrograms'
}

export default MicromobilityPrograms
