import Text from 'core-system/Text'
import SvgTooltip from 'core-system/Tooltip/SvgTooltip'
import TransitLogo from 'core-system/TransitLogo'
import React from 'react'
import ServiceProviderUtils, { ModeTypeCost } from 'shared/ServiceProviderUtils'
import styled from 'styled-components'
import InfoIcon from 'core-system/Icons/Misc/Info'
import palette from 'core-system/Themes/palette'

const modeTypeMap = {
  scootershare: 'SCOOTER',
  bikeshare: 'BIKE',
  mopedshare: 'MOPED',
}

const Container = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  width: 100%;
`

const TextHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey400};
`

const ModesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: 0.5rem;
`

const Mode = styled.div`
  display: flex;
  align-items: center;
`

const ModeCopy = styled.div`
  white-space: nowrap;
`

const CustomLogo = styled(TransitLogo)`
  border: none;
  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) => props.theme.palette.primary.pink600};
`

const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.palette.text.placeholder};
  margin-left: 0.25rem;
  ${(props) => props.theme.typography.caption};
  font-size: 0.875;
  text-transform: uppercase;
`

const renderLeasingTrips = (modes: ModeTypeCost[], budget: number) => {
  return modes.map((mode, idx) => {
    const trips = ServiceProviderUtils.getTripsByCost(mode, budget)
    return (
      <React.Fragment key={idx}>
        <Mode>
          <CustomLogo modeType={modeTypeMap[mode]} />
          <ModeCopy>
            <Text variant='caption' captionTitle marginBottom='0.125rem'>
              {mode}
            </Text>
            <Text variant='body2'>
              ~ {trips} {trips === 1 ? 'Trip' : 'Trips'}
            </Text>
          </ModeCopy>
        </Mode>
        {idx < modes.length - 1 && <Separator>or</Separator>}
      </React.Fragment>
    )
  })
}

interface MicromobilityLeasingTripsProps {
  budget: number
}

const MicromobilityLeasingTrips = React.memo(
  (props: MicromobilityLeasingTripsProps) => {
    return (
      <Container>
        <TextHeader>
          <Text variant='action4'>Estimated Number of Trips Covered</Text>
          <SvgTooltip
            svg={
              <InfoIcon color={palette.text.secondary} width={20} height={20} />
            }
            height={'1.5rem'}
            isMultiLine={true}
            description={
              'Estimated number of trips each commuter can take per month based on current budget'
            }
          />
        </TextHeader>
        <ModesContainer>
          {renderLeasingTrips(
            ['mopedshare', 'bikeshare', 'scootershare'],
            props.budget
          )}
        </ModesContainer>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityLeasingTrips.displayName = 'MicromobilityLeasingTrips'
}

export default MicromobilityLeasingTrips
