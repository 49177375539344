import React, { useState, MouseEvent } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography, IconButton, Popover } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import UserAvatar from './UserAvatar'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  usersAvatar: {
    marginRight: 6,
  },
  infoContainer: {
    marginRight: 12,
  },
  online: {
    fontWeight: 600,
    paddingRight: 8,
    color: palette.success.green400,
  },
  popoverInnerContainer: {},
  name: {
    color: 'white',
  },
}))

interface UserAvatarWithInfoProps {
  name?: string
  isOnline?: boolean
  imageSrc?: string
  className?: string
  showInfo?: boolean
  showDropDown?: boolean
  dropDownContent?: React.ReactNode
  closeDropDownOnContentClick?: boolean
}

const UserAvatarWithInfo: React.FC<UserAvatarWithInfoProps> = ({
  name = 'John Doe',
  isOnline = true,
  imageSrc = '',
  className,
  showInfo = false,
  showDropDown = false,
  dropDownContent = null,
  closeDropDownOnContentClick = false,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={`${classes.mainContainer} ${className}`}>
      <UserAvatar
        className={classes.usersAvatar}
        displayName={name}
        imageSrc={imageSrc}
        showName={!showInfo}
      />
      {showInfo && (
        <div className={classes.infoContainer}>
          <Text variant='h5' className={classes.name}>
            {name}
          </Text>
          {isOnline && (
            <Typography variant='body2'>
              <span className={classes.online}>Online</span>
            </Typography>
          )}
        </div>
      )}
      {showDropDown && (
        <div>
          <IconButton onClick={handleClick}>
            <KeyboardArrowDownIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div
              className={classes.popoverInnerContainer}
              onClick={closeDropDownOnContentClick ? handleClose : undefined}
            >
              {dropDownContent}
            </div>
          </Popover>
        </div>
      )}
    </div>
  )
}

export default React.memo(UserAvatarWithInfo)
