import Error from 'core-system/Error'
import FlexContainer from 'core-system/FlexContainer'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Question from 'core-system/Icons/Sidebar/Question'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import { AppState } from 'redux/config/store'
import { Commuter } from 'redux/employee/employeeTypes'
import {
  Leaderboard,
  LeaderboardEmployeeRanking,
} from 'redux/leaderboards/leaderboardsTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled, { css } from 'styled-components'
import { variant } from 'styled-system'

export const metricMap = {
  ENV: {
    metric: 'lbs',
    divider: 1,
    decimals: 0,
  },
  WALK: {
    metric: 'mi',
    divider: 1609,
    decimals: 1,
  },
  BIKE: {
    metric: 'mi',
    divider: 1609,
    decimals: 1,
  },
  ALTERNATIVE: {
    metric: 'trips',
    divider: 1,
    decimals: 0,
  },
  MICROMOBILITY: {
    metric: 'trips',
    divider: 1,
    decimals: 0,
  },
  CARPOOL: {
    metric: 'trips',
    divider: 1,
    decimals: 0,
  },
  TRANSIT: {
    metric: 'trips',
    divider: 1,
    decimals: 0,
  },
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Leaders = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2rem;
  max-width: ${(props) => props.theme.pxToRem(454)};
  justify-content: space-between;
`

const Leader = styled.div<{ hasHover: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;

  ${(props) =>
    props.hasHover &&
    css`
      &:hover {
        background-color: ${(props) => props.theme.palette.primary.pink400};
        cursor: pointer;
      }
    `};
`

const MiniLeader = styled(Leader)<{ hasHover: boolean }>`
  margin-top: ${(props) => props.theme.pxToRem(42)};
  padding: 1rem;
  border-radius: 0.5rem;

  ${(props) =>
    props.hasHover &&
    css`
      &:hover {
        background-color: ${(props) => props.theme.palette.primary.pink400};
        cursor: pointer;
      }
    `};
`

const IconContainer = styled.div`
  position: relative;
`

const Medal = styled.div<{ variant: number; size: string }>`
  ${(props) => props.theme.typography.action4};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  position: absolute;
  bottom: ${(props) => props.theme.pxToRem(-9)};
  right: ${(props) => props.theme.pxToRem(props.size === 'sm' ? 20 : 31)};
  border: 6px solid ${(props) => props.theme.palette.white};

  ${(props) =>
    variant({
      variants: {
        0: {
          backgroundColor: '#FFE58B',
          color: props.theme.palette.warning.yellow500,
        },
        1: {
          backgroundColor: props.theme.palette.grey.grey300,
          color: props.theme.palette.text.secondary,
        },
        2: {
          backgroundColor: '#F6C892',
          color: '#733E00',
        },
      },
    })}
`

const Employees = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.pxToRem(40)};
  width: 100%;
`

const Employee = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink400};
    cursor: pointer;
  }
`

const EmployeeRow = styled.div`
  padding: 0.5rem 0;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey300};

  &:last-of-type {
    border-bottom: none;
  }
`

const getMetric = (value: number, type: string) => {
  return `${FormattingUtils.formatNumber(value / metricMap[type].divider, 1)} ${
    metricMap[type].metric
  }`
}

const getEmployeeId = (
  allCommuters: Commuter[],
  corporateId: string
): string => {
  return allCommuters.find(
    (commuter: Commuter) => commuter.employeeCorporateId === corporateId
  ).id
}
interface LeaderboardListProps {
  date: string
  leaderboard: Leaderboard
  rankings: LeaderboardEmployeeRanking[]
  onEmployeeClick: (id: string) => void
}

const LeaderboardList = React.memo((props: LeaderboardListProps) => {
  const { date, leaderboard, rankings, onEmployeeClick } = props
  const { allCommuters } = useSelector((state: AppState) => state.employee)
  return (
    <Container>
      {leaderboard.status === 'CANCELLING' && (
        <Text variant='h5'>
          This Competition has been set to cancelling before ever becoming
          active. Someone from our team will be in contact shortly.
        </Text>
      )}
      {leaderboard.status === 'PENDING' && leaderboard.startDate && (
        <Text variant='h5'>
          This Competition is scheduled to start on{' '}
          {moment(leaderboard.startDate).format('MMMM Do, YYYY')}
        </Text>
      )}
      {!['PENDING', 'CANCELLING'].includes(leaderboard.status) && (
        <>
          <Text variant='h4' textAlign='center'>
            {moment(date).format('MMMM, YYYY')}
          </Text>
          {rankings.length > 0 ? (
            <>
              <Leaders>
                <MiniLeader
                  hasHover={rankings.length >= 2}
                  onClick={() =>
                    rankings.length >= 2 &&
                    onEmployeeClick(
                      getEmployeeId(
                        allCommuters,
                        rankings[1].employeeCorporateId
                      )
                    )
                  }
                >
                  <IconContainer>
                    {rankings.length >= 2 ? (
                      <PlatformSvg
                        folder='users'
                        variant='userBasic'
                        width={80}
                        height={80}
                      />
                    ) : (
                      <Question
                        width={80}
                        height={80}
                        color={palette.primary.pink300}
                      />
                    )}
                    <Medal size='sm' variant={1}>
                      {2}
                    </Medal>
                  </IconContainer>
                  <FlexContainer width={pxToRem(120)} justifyContent='center'>
                    <Text variant='action3' marginTop='0.5rem' hideOverflow>
                      {rankings.length >= 2
                        ? rankings[1].name.trim() !== ''
                          ? rankings[1].name
                          : rankings[1].employeeCorporateId
                        : ''}
                    </Text>
                  </FlexContainer>
                  <Text variant='action4'>
                    {rankings.length >= 2
                      ? getMetric(rankings[1].value, leaderboard.type)
                      : ''}
                  </Text>
                </MiniLeader>
                <Leader
                  hasHover={rankings.length >= 1}
                  onClick={() =>
                    rankings.length >= 1 &&
                    onEmployeeClick(
                      getEmployeeId(
                        allCommuters,
                        rankings[0].employeeCorporateId
                      )
                    )
                  }
                >
                  <IconContainer>
                    {rankings.length >= 1 ? (
                      <PlatformSvg
                        folder='users'
                        variant='userBasic'
                        width={104}
                        height={104}
                      />
                    ) : (
                      <Question
                        width={104}
                        height={104}
                        color={palette.primary.pink300}
                      />
                    )}
                    <Medal size='lg' variant={0}>
                      {1}
                    </Medal>
                  </IconContainer>
                  <FlexContainer width={pxToRem(120)} justifyContent='center'>
                    <Text variant='action3' marginTop='0.5rem' hideOverflow>
                      {rankings.length >= 1
                        ? rankings[0].name.trim() !== ''
                          ? rankings[0].name
                          : rankings[0].employeeCorporateId
                        : ''}
                    </Text>
                  </FlexContainer>
                  <Text variant='action4'>
                    {rankings.length >= 1
                      ? getMetric(rankings[0].value, leaderboard.type)
                      : ''}
                  </Text>
                </Leader>
                <MiniLeader
                  hasHover={rankings.length >= 3}
                  onClick={() =>
                    rankings.length >= 3 &&
                    onEmployeeClick(
                      getEmployeeId(
                        allCommuters,
                        rankings[2].employeeCorporateId
                      )
                    )
                  }
                >
                  <IconContainer>
                    {rankings.length >= 3 ? (
                      <PlatformSvg
                        folder='users'
                        variant='userBasic'
                        width={80}
                        height={80}
                      />
                    ) : (
                      <Question
                        width={80}
                        height={80}
                        color={palette.primary.pink300}
                      />
                    )}
                    <Medal size='sm' variant={2}>
                      {3}
                    </Medal>
                  </IconContainer>
                  <FlexContainer width={pxToRem(120)} justifyContent='center'>
                    <Text variant='action3' marginTop='0.5rem' hideOverflow>
                      {rankings.length >= 3
                        ? rankings[2].name.trim() !== ''
                          ? rankings[2].name
                          : rankings[2].employeeCorporateId
                        : ''}
                    </Text>
                  </FlexContainer>
                  <Text variant='action4'>
                    {rankings.length >= 3
                      ? getMetric(rankings[2].value, leaderboard.type)
                      : ''}
                  </Text>
                </MiniLeader>
              </Leaders>
              {rankings.length >= 4 && (
                <Employees>
                  {rankings
                    .slice(3)
                    .map(
                      (employee: LeaderboardEmployeeRanking, idx: number) => (
                        <EmployeeRow key={idx}>
                          <Employee
                            onClick={() =>
                              onEmployeeClick(
                                getEmployeeId(
                                  allCommuters,
                                  employee.employeeCorporateId
                                )
                              )
                            }
                          >
                            <Text
                              variant='action3'
                              textColor={palette.text.secondary}
                              width={pxToRem(20)}
                            >
                              {idx + 4}
                            </Text>
                            <img
                              alt='commuter'
                              src={`${StaticAssetsUrl}/accounts/default-user-32.png`}
                            />
                            <Text variant='action3' marginLeft='1rem'>
                              {employee.name.trim() !== ''
                                ? employee.name
                                : employee.employeeCorporateId}
                            </Text>
                            <Text variant='action4' marginLeft='auto'>
                              {getMetric(employee.value, leaderboard.type)}
                            </Text>
                          </Employee>
                        </EmployeeRow>
                      )
                    )}
                </Employees>
              )}
            </>
          ) : (
            <Error
              margin='3rem 0 0 2rem'
              description='No commuter engagement yet'
            />
          )}
        </>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LeaderboardList.displayName = 'LeaderboardList'
}

export default LeaderboardList
