import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Text from 'core-system/Text'
import CloseIcon from 'core-system/Icons/Actions/Close'
import palette from 'core-system/Themes/palette'
import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { TripMonth } from 'redux/employeePlatform/employeePlatformTypes'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import TripCard from './TripCard'
import { useNavigate } from 'react-router-dom'
import { Locations } from 'shared/Router/Locations'

const Container = styled.div<{ isMobile: boolean; show: boolean }>`
  position: absolute;
  right: 0;
  top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${(props) => props.theme.palette.white};
  width: ${(props) => (props.isMobile ? '100%' : props.theme.pxToRem(400))};
  height: calc(100% - 5rem);
  border-left: 1px solid ${(props) => props.theme.palette.grey.grey300};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  padding: 2rem 1.5rem;
  margin-right: ${(props) => (props.show ? 0 : `-${props.theme.pxToRem(400)}`)};
  transition: margin-right 0.4s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  border-radius: 0.5rem;
  margin-right: 1rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink500};
  }
`

const TripsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const getDayTrips = (
  currentMonthTrips: { [month: number]: TripMonth } | null,
  selectedMonth: string,
  selectedDay: number
) => {
  const monthKey = Number(moment(selectedMonth).format('YYYYMM'))
  const dayKey = Number(
    moment(`${selectedDay} ${selectedMonth}`).format('YYYYMMDD')
  )
  return currentMonthTrips
    ? currentMonthTrips[monthKey]?.trips[dayKey] || []
    : []
}

interface DayTripsOverviewProps {
  selectedDay: number
  setSelectedDay: (day: number | null) => void
  selectedMonth: string
  isMobile: boolean
}

const DayTripsOverview = React.memo((props: DayTripsOverviewProps) => {
  const { selectedDay, setSelectedDay, selectedMonth, isMobile } = props

  const { currentMonthTrips } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const navigate = useNavigate()

  const dayTrips = getDayTrips(currentMonthTrips, selectedMonth, selectedDay)

  const formattedDate = moment(`${selectedDay} ${selectedMonth}`).format(
    'MMM D, YYYY'
  )

  return (
    <Container isMobile={isMobile} show={selectedDay !== null}>
      <Header>
        <CloseIconContainer onClick={() => setSelectedDay(null)}>
          <CloseIcon color={palette.text.primary} />
        </CloseIconContainer>
        <Text variant='h3'>{formattedDate}</Text>
      </Header>
      <Button
        onClick={() =>
          navigate(Locations.EmployeeTrips.AddTrip, {
            state: {
              day: selectedDay,
              month: moment(selectedMonth).month(),
              year: moment(selectedMonth).year(),
            },
          })
        }
      >
        + Add Trip
      </Button>
      <Divider direction='horizontal' />
      <TripsContainer>
        <Text variant='h4'>Trips</Text>
        {dayTrips.length == 0 ? <NoDataText text='NO TRIPS LOGGED' /> : null}
        {dayTrips.map((trip) => {
          return <TripCard key={trip.id} trip={trip} />
        })}
      </TripsContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DayTripsOverview.displayName = 'DayTripsOverview'
}

export default DayTripsOverview
