import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Avatar from '@mui/material/Avatar'
import { Badge, Tooltip, Typography } from '@mui/material'
import palette from 'core-system/Themes/palette'
const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  avatarInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  colorPrimary: {
    backgroundColor: palette.primary.pink100,
  },
  name: {
    paddingLeft: 10,
    color: palette.primary.pink100,
  },
}))

const getName = (name: string): string => {
  const maxChars = 20
  const nameHere = name.charAt(0).toUpperCase() + name.slice(1)
  return nameHere.length > maxChars
    ? `${nameHere.slice(0, maxChars)}..`
    : nameHere
}

interface UserAvatarProps {
  displayName?: string
  className?: string
  imageSrc?: string
  numberOfUnseenMessages?: number
  showName?: boolean
  height?: number
  width?: number
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  displayName = 'Anonymous',
  className,
  imageSrc,
  numberOfUnseenMessages = 0,
  showName = true,
  height = 48,
  width = 48,
}) => {
  const classes = useStyles()

  return (
    <div className={`${classes.mainContainer} ${className}`}>
      <Badge
        overlap='circular'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        color='primary'
        badgeContent={numberOfUnseenMessages}
        max={9}
        classes={{
          colorPrimary: classes.colorPrimary,
        }}
      >
        <Avatar
          sx={{
            width,
            height,
          }}
          src={imageSrc}
        />
      </Badge>
      {showName && (
        <Tooltip title={displayName} placement='bottom'>
          <Typography className={classes.name} variant='body2'>
            {getName(displayName)}
          </Typography>
        </Tooltip>
      )}
    </div>
  )
}

export default React.memo(UserAvatar)
