import palette from 'core-system/Themes/palette'
import { computeRoute } from './googleMapsService'
import { sortBy } from 'lodash'

export interface Route {
  polyline: google.maps.Polyline
  distance: number
  duration: number
  transitSteps: any[]
}

export const fetchRoute = async (
  coordinates: { latitude: number; longitude: number }[]
): Promise<Route[]> => {
  try {
    const response = await computeRoute(coordinates)

    const data = await response.json()

    if (data.routes && data.routes.length > 0) {
      // sort the routes by duration
      // only return the first 4 routes
      const routes = sortBy(data.routes, (route) => {
        const minutes = getDuration(route.duration)
        return minutes
      }).slice(0, 4)

      return routes.map((route) => ({
        polyline: route.polyline,
        distance: getDistance(route.distanceMeters),
        duration: getDuration(route.duration),
        transitSteps: extractTransitSteps(route.legs),
      }))
    }
    return []
  } catch (error) {
    console.error('Error fetching route:', error)
    return []
  }
}

const getDurationSeconds = (data: string) => {
  const durationSec = parseInt(data.replace('s', ''), 10)
  return durationSec
}

export const getDuration = (data: string) => {
  const durationSec = getDurationSeconds(data)
  const durationMin = Math.floor(durationSec / 60)

  return durationMin
}

export const getDistance = (distance: number) => {
  const distanceKm = Math.floor(distance / 1000)

  if (distanceKm < 1) {
    return (distance / 1000).toFixed(2)
  }

  return distanceKm
}

export const createMap = (
  mapRef: React.RefObject<HTMLDivElement>,
  chatId: string,
  mapType: 'route' | 'marker',
  coordinates: { latitude: number; longitude: number }[]
): google.maps.Map => {
  const map = new window.google.maps.Map(mapRef.current, {
    mapId: chatId,
    zoom: 15,
    center:
      mapType === 'route'
        ? {
            lat: (coordinates[0].latitude + coordinates[1].latitude) / 2,
            lng: (coordinates[0].longitude + coordinates[1].longitude) / 2,
          }
        : { lat: coordinates[0].latitude, lng: coordinates[0].longitude },
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  })

  return map
}

export const createMarker = (
  map: google.maps.Map,
  coordinate: { latitude: number; longitude: number }
) => {
  new window.google.maps.marker.AdvancedMarkerElement({
    position: {
      lat: coordinate.latitude,
      lng: coordinate.longitude,
    },
    map,
  })
}

export const createCircularMarkersForLegs = (
  map: google.maps.Map,
  startLocation: { latLng: { latitude: number; longitude: number } }
) => {
  new window.google.maps.Marker({
    position: {
      lat: startLocation.latLng.latitude,
      lng: startLocation.latLng.longitude,
    },
    map,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 6,
      fillColor: palette.secondary.purple100,
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: palette.secondary.purple400,
    },
  })
}

export const createInfoWindow = (distance: number, duration: number) => {
  const infoWindow = new google.maps.InfoWindow({
    content: `
      <div class="info-window-content">
        <p><b>Distance:</b> ${distance} km</p>
      <p><b>Duration:</b> ${duration} min</p>
      <style>
        .info-window-content {
          font-size: 16px;
          color: ${palette.text.primary};
        }
        .info-window-content p {
          margin: 0;
          margin-bottom: 4px;
        }
        .gm-style-iw button.gm-ui-hover-effect {
          height: 36px !important;
        }
      </style>
    </div>
  `,
  })

  return infoWindow
}

const extractTransitSteps = (legs: any) => {
  const transitSteps = []

  legs.forEach((leg) => {
    leg.steps.forEach((step) => {
      transitSteps.push(step)
    })
  })

  return transitSteps
}

export const polylineColor = (
  travelMode: 'TRANSIT' | 'WALK',
  isActive: boolean,
  transitLineColor?: string
) => {
  if (!isActive) {
    return palette.grey.grey600
  }
  if (transitLineColor) {
    return transitLineColor
  }
  if (travelMode === 'WALK') {
    return palette.accent.blue500
  }
  return palette.primary.pink700
}

export const polylineOpacity = (
  travelMode: 'TRANSIT' | 'WALK',
  isActive: boolean
) => {
  if (!isActive) {
    return 0.4
  }
  if (travelMode === 'WALK') {
    return 0
  }
  return 1
}

const polylineIcon = (travelMode: 'TRANSIT' | 'WALK') => {
  if (travelMode === 'WALK') {
    return [
      {
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 3,
          fillOpacity: 1,
          strokeOpacity: 1,
        },
        offset: '0',
        repeat: '12px',
      },
    ]
  }
  return undefined
}

export const createPolyline = (
  map: google.maps.Map,
  decodedPath: google.maps.LatLng[],
  isActive: boolean,
  onPolylineClick: (event: google.maps.KmlMouseEvent) => void,
  travelMode: 'TRANSIT' | 'WALK',
  transitLineColor?: string
) => {
  const polyline = new window.google.maps.Polyline({
    path: decodedPath,
    strokeColor: polylineColor(travelMode, isActive, transitLineColor),
    strokeWeight: 7,
    map,
    strokeOpacity: polylineOpacity(travelMode, isActive),
    icons: polylineIcon(travelMode),
    zIndex: isActive ? 1000 : undefined,
  })
  // Add event listeners to show the InfoWindow on mouseover
  google.maps.event.addListener(polyline, 'click', function (event) {
    onPolylineClick(event)
  })

  return polyline
}

export const getLineColor = (transitDetails: any): string | undefined => {
  if (transitDetails?.transitLine?.color) {
    return transitDetails.transitLine.color
  }
  return undefined
}

export const getTransitIcon = (transitDetails: any) => {
  if (transitDetails?.transitLine?.vehicle?.localIconUri) {
    return transitDetails.transitLine.vehicle.localIconUri
  }
  if (transitDetails?.transitLine?.vehicle?.iconUri) {
    return transitDetails.transitLine.vehicle.iconUri
  }
  return undefined
}

export const getTransitShortName = (transitDetails: any) => {
  if (transitDetails?.transitLine?.nameShort) {
    return transitDetails.transitLine.nameShort
  }
  if (transitDetails?.transitLine?.name) {
    return transitDetails.transitLine.name.slice(0, 2).toUpperCase()
  }
  return 'T'
}

export const combineAllWalkSteps = (steps: any[]) => {
  const combinedSteps = []
  steps.forEach((step) => {
    if (step.travelMode === 'WALK') {
      // If the last step is walk combine the distance and duration
      if (
        combinedSteps.length > 0 &&
        combinedSteps[combinedSteps.length - 1].travelMode === 'WALK'
      ) {
        combinedSteps[combinedSteps.length - 1].distance += step.distanceMeters
        combinedSteps[combinedSteps.length - 1].duration += getDurationSeconds(
          step.staticDuration
        )
      } else {
        combinedSteps.push({
          distance: step.distanceMeters,
          duration: getDurationSeconds(step.staticDuration),
          travelMode: 'WALK',
        })
      }
    }
    if (step.travelMode === 'TRANSIT') {
      combinedSteps.push({
        distance: step.distanceMeters,
        duration: getDurationSeconds(step.staticDuration),
        travelMode: 'TRANSIT',
        transitLineColor: getLineColor(step.transitDetails),
        transitLineNameShort: getTransitShortName(step.transitDetails),
        icon: getTransitIcon(step.transitDetails),
      })
    }
  })
  return combinedSteps
}

export const getDepartureArrivalTime = (transitDetails: any) => {
  const times = {
    arrivalTime: 0,
    departureTime: 0,
  }
  if (transitDetails?.localizedValues?.arrivalTime?.time?.text) {
    times.arrivalTime = transitDetails.localizedValues.arrivalTime.time.text
  }
  if (transitDetails?.localizedValues?.departureTime?.time?.text) {
    times.departureTime = transitDetails.localizedValues.departureTime.time.text
  }
  return times
}

export const getStopDetails = (transitDetails: any) => {
  const stops = {
    arrival: '',
    departure: '',
  }
  if (transitDetails?.stopDetails?.arrivalStop?.name) {
    stops.arrival = transitDetails.stopDetails.arrivalStop.name
  }
  if (transitDetails?.stopDetails?.departureStop?.name) {
    stops.departure = transitDetails.stopDetails.departureStop.name
  }
  return stops
}
