import React from 'react'
import styled, { css } from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import palette from '../Themes/palette'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const FullPageContainer = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps & { withoutNav: boolean; hasOpacity: boolean }
>`
  height: calc(100vh - 5rem);
  width: calc(100vw - 5rem);
  top: 5rem;
  left: 5rem;
  display: flex;
  z-index: ${(props) => props.theme.zIndex.loading};
  align-items: center;
  justify-content: center;
  align-content: center;
  margin: 0;
  background: ${(props) => props.theme.palette.grey.grey100};
  position: absolute;
  pointer-events: none;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100vw;
    left: 0;
  }

  ${(props) =>
    props.withoutNav &&
    css`
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    `}

  ${(props) =>
    props.hasOpacity &&
    css`
      background: rgba(244, 244, 248, 0.75);
      z-index: ${(props) => props.theme.zIndex.max};
      pointer-events: all;
    `}

  ${flexbox}
  ${space}
  ${layout}
`

const Container = styled.div<
  FlexboxProps & SpaceProps & LayoutProps & { isCard: boolean }
>`
  height: ${(props) => props.theme.pxToRem(400)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background: ${(props) =>
    props.isCard ? props.theme.palette.white : 'unset'};
  pointer-events: none;
  border-radius: 0.9375rem;
  box-shadow: ${(props) =>
    props.isCard ? props.theme.dropShadows.normal : 'unset'};
  border: 1px solid
    ${(props) =>
      props.isCard ? props.theme.palette.grey.grey300 : 'transparent'};

  ${flexbox}
  ${space}
  ${layout}
`

interface LoadingProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  spinnerSize?: number
  fullPage?: boolean
  isCard?: boolean
  withoutNav?: boolean
  hasOpacity?: boolean
}

const Loading = React.memo((props: LoadingProps) => {
  const {
    spinnerSize = 70,
    fullPage,
    isCard = true,
    withoutNav = false,
    hasOpacity = false,
  } = props
  return (
    <>
      {fullPage ? (
        <FullPageContainer
          withoutNav={withoutNav}
          hasOpacity={hasOpacity}
          {...props}
        >
          <CircularProgress
            sx={{
              color: palette.primary.pink800,
            }}
            size={spinnerSize}
          />
        </FullPageContainer>
      ) : (
        <Container isCard={isCard} {...props}>
          <CircularProgress
            sx={{
              color: palette.primary.pink800,
            }}
            size={spinnerSize}
          />
        </Container>
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  Loading.displayName = 'App Loading Overlay'
}

export default Loading
