import Button from 'core-system/Button'
import ColumnView, { Column } from 'core-system/ColumnView'
import { QTF } from 'core-system/Icons/Sidebar'
import Loading from 'core-system/Loading'
import * as Program from 'core-system/Program'
import Footer from 'core-system/Program/Footer'
import ProgramModal from 'core-system/Program/ProgramModal'
import ProgramOption from 'core-system/Program/ProgramOption'
import ProgramPriceCoverage from 'core-system/Program/ProgramPriceCoverage'
import ServiceProviderSelectorModal from 'core-system/ServiceProvider/ServiceProviderSelectorModal'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { flexActions } from 'redux/flex/flexSlice'
import { FlexActiveOptions, FlexManageState } from 'redux/flex/flexTypes'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import {
  CardProgram as ProgramType,
  Merchant,
  OptionState,
} from 'redux/programs/programsTypes'
import useQueryParam from 'shared/Hooks/useQueryParam'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import FlexUtils from '../Shared/FlexUtils'
import FlexManagePending from './components.tsx/FlexManagePending'
import FlexManageSummary from './components.tsx/FlexManageSummary'
import FormattingUtils from 'shared/FormattingUtils'

const StyledColumnView = styled(ColumnView)`
  margin-bottom: 10rem;
  grid-template-columns: 64.5% 34.5%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
    max-width: ${(props) => props.theme.pxToRem(700)};
    grid-gap: unset;
  }
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`

const StyledLink = styled.a`
  ${(props) => props.theme.typography.body2};
  color: ${(props) => props.theme.palette.primary.pink400};
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const getDefaultOptions = (program: ProgramType) => {
  return {
    flex: program.merchants.length > 0,
    gas: program.gas,
    parking: program.parking,
    transit: program.transit,
  }
}

const formatMerchantIds = (
  activeMerchantIds: string[],
  availableMerchants: Merchant[],
  availableTransitMerchantIds: string[]
): OptionState => {
  return {
    //need to filter out active transit ids so users aren't able to select
    //individual transit merchants
    activeMerchantIds: activeMerchantIds.filter(
      (id) => !availableTransitMerchantIds.includes(id)
    ),
    availableMerchantIds: availableMerchants.map((m) => m.id),
  }
}

const FlexManageView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const segmentsMap = useSelector(
    (state: AppState) => state.employer.segmentsMap
  )
  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )
  const merchantsWithTransit = useSelector(
    (state: AppState) => state.programs.merchantsWithTransit
  )
  const flexProgramsLoaded = useSelector(
    (state: AppState) => state.flex.flexProgramsLoaded
  )
  const flexProgramUpdated = useSelector(
    (state: AppState) => state.flex.flexProgramUpdated
  )
  const availableMerchants = useSelector(
    (state: AppState) => state.flex.availableMerchants
  )
  const allPrograms = useSelector((state: AppState) => state.flex.flexPrograms)

  const [isEdit, setIsEdit] = useState(false)
  const [errorState, setErrorState] = useState(FlexUtils.defaultErrorState)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [availableTransitMerchantIds, setAvailableTransitMerchantIds] =
    useState<string[]>([])
  const [programState, setProgramState] = useState<FlexManageState>({
    active: null,
    nextMonth: null,
  })
  const [pendingProgram, setPendingProgram] = useState({
    program: null,
    activeOptions: null,
  })
  const [activeState, setActiveState] = useState('active')
  const [activeOptions, setActiveOptions] =
    useState<Dictionary<FlexActiveOptions>>(null)

  const { irsTaxLimit } = useSelector(
    (state: AppState) => state.application.properties
  )

  useEffect(() => {
    //ensure all providers are loaded so proper options groupings can be done
    if (segmentId && merchantsMap && !availableMerchants) {
      dispatch(
        flexActions.getFlexRecommendations({
          segmentId,
        })
      )
    }
  }, [segmentId, merchantsMap, availableMerchants, dispatch])

  useEffect(() => {
    if (merchantsMap && availableMerchants && allPrograms) {
      const activeProgramGroup = allPrograms[segmentId]
      const transitMerchantIds = availableMerchants.transit.map((m) => m.id)

      if (!activeProgramGroup.active && activeProgramGroup.nextMonth) {
        const nextMonthState = {
          ...activeProgramGroup.nextMonth,
          flex: formatMerchantIds(
            activeProgramGroup.nextMonth.merchants,
            availableMerchants.flex,
            transitMerchantIds
          ),
        }
        const nextMonthOptions = getDefaultOptions(activeProgramGroup.nextMonth)
        setProgramState((prevState) => {
          return {
            ...prevState,
            nextMonth: nextMonthState,
          }
        })
        setActiveOptions({
          active: null,
          nextMonth: nextMonthOptions,
        })
        setIsEdit(true)
        setActiveState('nextMonth')
        setPendingProgram({
          program: nextMonthState,
          activeOptions: nextMonthOptions,
        })
      } else if (activeProgramGroup.active) {
        const activeProgramState = {
          ...activeProgramGroup.active,
          flex: formatMerchantIds(
            activeProgramGroup.active.merchants,
            availableMerchants.flex,
            transitMerchantIds
          ),
        }
        setProgramState({
          active: activeProgramState,
          nextMonth: activeProgramGroup.nextMonth
            ? {
                ...activeProgramGroup.nextMonth,
                flex: formatMerchantIds(
                  activeProgramGroup.nextMonth.merchants,
                  availableMerchants.flex,
                  transitMerchantIds
                ),
              }
            : {
                ...activeProgramState,
                startDate: moment()
                  .add(1, 'M')
                  .startOf('month')
                  .format('YYYY-MM-DDTHH:mm:ss'),
              },
        })
        const defaultActiveOptions = getDefaultOptions(
          activeProgramGroup.active
        )
        setActiveOptions({
          active: defaultActiveOptions,
          nextMonth: activeProgramGroup.nextMonth
            ? getDefaultOptions(activeProgramGroup.nextMonth)
            : defaultActiveOptions,
        })
      }

      setAvailableTransitMerchantIds(
        FlexUtils.getFlexTransitProviders(
          transitMerchantIds,
          merchantsWithTransit,
          segmentsMap[segmentId].metroAreas
        )
      )
    }
  }, [
    allPrograms,
    merchantsMap,
    merchantsWithTransit,
    availableMerchants,
    segmentId,
    segmentsMap,
  ])

  useEffect(() => {
    if (flexProgramUpdated && flexProgramsLoaded) {
      dispatch(flexActions.toggleFlexProgramUpdated())
      if (!allPrograms[segmentId].active) {
        navigate(`/flex/${segmentId}`)
      } else {
        setIsEdit(false)
        setActiveState('active')
        setIsUpdateModalOpen(false)
      }
    }
  }, [
    navigate,
    flexProgramUpdated,
    dispatch,
    flexProgramsLoaded,
    segmentId,
    allPrograms,
  ])

  const toggleEdit = () => {
    if (isEdit) {
      const activeProgramGroup = allPrograms[segmentId]
      const transitMerchantIds = availableMerchants.transit.map((m) => m.id)

      setProgramState((prevState) => {
        return {
          ...prevState,
          nextMonth: activeProgramGroup.nextMonth
            ? {
                ...activeProgramGroup.nextMonth,
                flex: formatMerchantIds(
                  activeProgramGroup.nextMonth.merchants,
                  availableMerchants.flex,
                  transitMerchantIds
                ),
              }
            : prevState.active,
        }
      })
    }

    setIsEdit((prevState) => !prevState)
    setActiveState((prevState) => {
      return prevState === 'active' ? 'nextMonth' : 'active'
    })

    SegmentService.track('program-manage-action', {
      action: isEdit ? 'close' : 'edit',
      programType: 'flex',
      segmentName: segmentsMap[segmentId].name,
    })
  }

  const handleOptionToggle = (type: string) => {
    //activate all providers when turning on a blank option
    if (
      type === 'flex' &&
      !activeOptions.nextMonth[type] &&
      programState.nextMonth[type].activeMerchantIds.length === 0
    ) {
      setProgramState((prevState) => {
        return {
          ...prevState,
          nextMonth: {
            ...prevState.nextMonth,
            [type]: {
              ...prevState.nextMonth[type],
              activeMerchantIds: prevState.nextMonth[type].availableMerchantIds,
            },
          },
        }
      })
    }

    setActiveOptions((prevState) => {
      return {
        ...prevState,
        nextMonth: {
          ...prevState.nextMonth,
          [type]: !prevState.nextMonth[type],
        },
      }
    })
  }

  const handleProviderChange = (
    type: string,
    newActiveMerchantIds: string[]
  ) => {
    setProgramState((prevState) => {
      return {
        ...prevState,
        nextMonth: {
          ...prevState.nextMonth,
          [type]: {
            ...prevState.nextMonth[type],
            activeMerchantIds: newActiveMerchantIds,
          },
        },
      }
    })
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
    SegmentService.track('modes-modal-toggle', {
      action: 'open',
      state: isEdit ? 'edit' : 'view-all',
      location: 'flex-providers',
    })
  }

  const handleUpdate = () => {
    const nextMonthProgram = programState.nextMonth
    const checkErrors = {
      budget: !budget && true,
      flex:
        nextMonthProgram.flex.activeMerchantIds.length === 0 &&
        activeOptions.nextMonth.flex &&
        true,
    }

    const hasNoErrors = Object.keys(checkErrors).every(
      (key) => !checkErrors[key]
    )

    if (hasNoErrors) {
      dispatch(
        flexActions.updateFlexProgram({
          programId: nextMonthProgram.id,
          budget: nextMonthProgram.budget,
          type: nextMonthProgram.type,
          name: nextMonthProgram.name,
          segment: segmentId,
          startDate: nextMonthProgram.startDate,
          endDate: nextMonthProgram.endDate,
          merchants: activeOptions.nextMonth.flex
            ? activeOptions.nextMonth.transit
              ? [
                  ...nextMonthProgram.flex.activeMerchantIds,
                  ...availableMerchants.transit.map((m) => m.id),
                ]
              : nextMonthProgram.flex.activeMerchantIds
            : [],
          gas: activeOptions.nextMonth.gas,
          parking: activeOptions.nextMonth.parking,
          transit: activeOptions.nextMonth.transit,
        })
      )

      SegmentService.track('programs-action-click', {
        action: 'update',
        programType: 'flex',
        segmentName: segmentsMap[segmentId].name,
        startDate: moment(nextMonthProgram.startDate).format('DD-MM-YYYY'),
        budget: nextMonthProgram.budget,
        flexProviders:
          nextMonthProgram.flex.activeMerchantIds.length > 0 &&
          activeOptions.nextMonth.flex
            ? nextMonthProgram.flex.activeMerchantIds.map(
                (id) => merchantsMap[id]?.name
              )
            : false,
        gas: activeOptions.nextMonth.gas,
        parking: activeOptions.nextMonth.parking,
        transit: activeOptions.nextMonth.transit,
        oneTime: null,
        shared: null,
        leasing: null,
      })
    } else {
      setErrorState(checkErrors)
      dispatch(
        notificationsActions.generalPageError(
          'Make sure a valid budget exists and there is at least one service provider selected for each active option'
        )
      )
    }
  }

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false)
  }

  const handleUpdateModalToggle = () => {
    setIsUpdateModalOpen((prevState) => !prevState)
  }

  const handleProgramCancel = (programGroup: 'active' | 'nextMonth') => {
    const nextMonthProgram = programState.nextMonth
    dispatch(flexActions.cancelFlexProgram(programState[programGroup].id))
    SegmentService.track('programs-action-click', {
      action: programGroup === 'active' ? 'cancel' : 'reactivate',
      programType: 'flex',
      segmentName: segmentsMap[segmentId].name,
      startDate: moment(nextMonthProgram.startDate).format('DD-MM-YYYY'),
      budget: nextMonthProgram.budget,
      flexProviders:
        nextMonthProgram.flex.activeMerchantIds.length > 0 &&
        activeOptions.nextMonth.flex
          ? nextMonthProgram.flex.activeMerchantIds.map(
              (id) => merchantsMap[id]?.name
            )
          : false,
      gas: activeOptions.nextMonth.gas,
      parking: activeOptions.nextMonth.parking,
      transit: activeOptions.nextMonth.transit,
      oneTime: null,
      shared: null,
      leasing: null,
    })
    setIsCancelModalOpen(false)
  }

  const handleReActivate = () => {
    const hasChanges = FlexUtils.programHasChanges(
      programState.active
        ? programState
        : { ...programState, active: pendingProgram.program },
      programState.active
        ? activeOptions
        : { ...activeOptions, active: pendingProgram.activeOptions }
    )

    if (!programState.active || hasChanges) {
      handleUpdate()
    } else {
      handleProgramCancel('nextMonth')
    }
  }

  if (flexProgramsLoaded && !allPrograms[segmentId]) {
    return <Navigate to={Locations.Flex.Programs} replace />
  }

  if (
    !flexProgramsLoaded ||
    (!programState.active && !programState.nextMonth) ||
    !merchantsMap ||
    !availableMerchants
  ) {
    return <Loading fullPage />
  }

  const noActiveOptions = Object.keys(activeOptions.nextMonth).every(
    (option) => !activeOptions.nextMonth[option]
  )
  const budget = programState[activeState].budget
  const monthlyGas = Math.min(
    Math.round(((budget ? budget : 0) / FlexUtils.monthlyGas) * 100),
    100
  )
  const monthlyParking = Math.min(
    Math.round(((budget ? budget : 0) / FlexUtils.monthlyParking) * 100),
    100
  )
  const monthlyTransit = Math.min(
    Math.round(((budget ? budget : 0) / FlexUtils.monthlyTransit) * 100),
    100
  )

  const currentYear = new Date().getFullYear()

  return (
    <>
      <StyledColumnView>
        <Column>
          <Program.BudgetSelector
            canEdit={isEdit}
            budget={budget}
            recBudget={FlexUtils.recBudget}
            onChange={(newBudget) =>
              setProgramState((prevState) => {
                const newBudgetInCents = newBudget ? newBudget * 100 : 0
                return {
                  ...prevState,
                  nextMonth: {
                    ...prevState.nextMonth,
                    budget: newBudgetInCents,
                  },
                }
              })
            }
          />
          <ProgramOption
            type='flex'
            active={activeOptions[activeState].flex}
            activeMerchantIds={programState[activeState].flex.activeMerchantIds}
            availableMerchantIds={
              programState[activeState].flex.availableMerchantIds
            }
            handleToggle={handleOptionToggle}
            handleOpenModal={handleOpenModal}
            error={errorState.flex}
            canEdit={isEdit}
          />
          <ProgramOption
            type='gas'
            active={activeOptions[activeState].gas}
            handleToggle={handleOptionToggle}
            canEdit={isEdit}
          >
            <ProgramPriceCoverage
              percentage={monthlyGas}
              title='Monthly Gas Price Covered'
              description={`Your budget will cover ${monthlyGas}% of the average monthly
                  gas spend (${FormattingUtils.formatDollar(
                    FlexUtils.monthlyGas,
                    0
                  )}) for your commuters.`}
            />
          </ProgramOption>
          <ProgramOption
            type='transit'
            active={activeOptions[activeState].transit}
            activeMerchantIds={availableTransitMerchantIds}
            availableMerchantIds={availableTransitMerchantIds}
            handleToggle={handleOptionToggle}
            canEdit={isEdit}
            readOnly
          >
            <ProgramPriceCoverage
              percentage={monthlyTransit}
              title='Tax Savings'
              description={`The ${currentYear} IRS monthly limit for pre-tax transit spending is ${FormattingUtils.formatDollar(
                irsTaxLimit,
                0
              )}.`}
              icon={<QTF />}
            >
              <StyledLink
                href='https://www.irs.gov/newsroom/irs-provides-tax-inflation-adjustments-for-tax-year-2023'
                target='_blank'
                rel='noopener noreferrer'
              >
                Click here to learn more.
              </StyledLink>
            </ProgramPriceCoverage>
          </ProgramOption>
          <ProgramOption
            type='parking'
            active={activeOptions[activeState].parking}
            handleToggle={handleOptionToggle}
            canEdit={isEdit}
          >
            <ProgramPriceCoverage
              percentage={monthlyParking}
              title='Tax Savings'
              description={`The ${currentYear} IRS monthly limit for pre-tax parking spending is ${FormattingUtils.formatDollar(
                irsTaxLimit,
                0
              )}.`}
              icon={<QTF />}
            >
              <StyledLink
                href='https://www.irs.gov/newsroom/irs-provides-tax-inflation-adjustments-for-tax-year-2023'
                target='_blank'
                rel='noopener noreferrer'
              >
                Click here to learn more.
              </StyledLink>
            </ProgramPriceCoverage>
          </ProgramOption>
        </Column>
        <Column>
          <FlexManageSummary
            currentSegment={segmentsMap[segmentId]}
            activeOptions={activeOptions[activeState]}
            program={programState[activeState]}
            handleOpenModal={handleOpenModal}
            canEdit={isEdit}
            toggleEdit={toggleEdit}
          />
          <FlexManagePending
            isPending={!programState.active && true}
            programState={
              programState.active
                ? programState
                : { ...programState, active: pendingProgram.program }
            }
            activeOptions={
              programState.active
                ? activeOptions
                : { ...activeOptions, active: pendingProgram.activeOptions }
            }
            canEdit={isEdit}
            openUpdateModal={handleUpdateModalToggle}
          />
        </Column>
      </StyledColumnView>
      {isEdit && (
        <Footer>
          <FooterContainer>
            {programState.nextMonth.status !== 'CANCELLING' && (
              <Button
                variant='cancel'
                onClick={() => {
                  setIsCancelModalOpen(true)
                  SegmentService.track('program-manage-action', {
                    action: 'cancel',
                    programType: 'flex',
                    segmentName: segmentsMap[segmentId].name,
                  })
                }}
              >
                End Program
              </Button>
            )}
            <ButtonContainer>
              <Button
                variant='tertiary'
                marginRight='1rem'
                onClick={() =>
                  programState.active
                    ? toggleEdit()
                    : navigate(`/flex/${segmentId}`)
                }
              >
                Cancel
              </Button>
              <Button
                disabled={noActiveOptions}
                onClick={() =>
                  programState.nextMonth.status === 'CANCELLING'
                    ? handleUpdateModalToggle()
                    : handleUpdate()
                }
              >
                Update Program
              </Button>
            </ButtonContainer>
          </FooterContainer>
        </Footer>
      )}
      <ServiceProviderSelectorModal
        open={isModalOpen}
        title='Available Service Providers'
        closeModal={() => {
          setIsModalOpen(false)
          SegmentService.track('modes-modal-toggle', {
            action: 'close',
            state: isEdit ? 'edit' : 'view-all',
            location: 'flex-providers',
          })
        }}
        canEdit={isEdit}
        activeProviders={programState[activeState].flex.activeMerchantIds}
        availableProviders={programState[activeState].flex.availableMerchantIds}
        onSave={(newActiveMerchantIds: string[]) =>
          handleProviderChange('flex', newActiveMerchantIds)
        }
        modeTypeFilters
      />
      <ProgramModal
        title='Cancel Program'
        description='Are you sure you want to cancel this program? Your commuters will lose
        all access to this programs benefits.'
        buttonText='Cancel Program'
        open={isCancelModalOpen}
        closeModal={handleCancelModalClose}
        buttonCallback={() =>
          handleProgramCancel(programState.active ? 'active' : 'nextMonth')
        }
      />
      <ProgramModal
        title='Reactivate Program'
        description='Do you want to reactive this program? All previous benefits will be available again next month.'
        buttonText='Reactivate Program'
        open={isUpdateModalOpen}
        closeModal={handleUpdateModalToggle}
        buttonCallback={handleReActivate}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FlexManageView.displayName = 'FlexManageView'
}

export default FlexManageView
