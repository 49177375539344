import Arrow from 'core-system/Icons/MapIntelligence/ArrowPrimary'
import BasicClock from 'core-system/Icons/MapIntelligence/BasicClock'
import Bike from 'core-system/Icons/MapIntelligence/Modes/ProgramsBike'
import Car from 'core-system/Icons/MapIntelligence/Modes/ProgramsCar'
import Moped from 'core-system/Icons/MapIntelligence/Modes/ProgramsMoped'
import Van from 'core-system/Icons/MapIntelligence/Modes/ProgramsVan'
import Walk from 'core-system/Icons/MapIntelligence/Modes/ProgramsWalk'
import Train from 'core-system/Icons/MapIntelligence/Modes/TrainBasic'
import Rideshare from 'core-system/Icons/MapIntelligence/ProgramsRideshare'
import Bus from 'core-system/Icons/modeTypes/Bus'
import Carpool from 'core-system/Icons/modeTypes/Carpool'
import CarRental from 'core-system/Icons/modeTypes/CarRental'
import Scooter from 'core-system/Icons/modeTypes/Scooter'
import Vanpool from 'core-system/Icons/modeTypes/Vanpool'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Text from 'core-system/Text'
import { Feature } from 'geojson'
import React from 'react'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import { Worksite } from 'redux/employer/employerTypes'
import FormattingUtils from 'shared/FormattingUtils'
import NumberUtils from 'shared/NumberUtils'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'

const commuteModes = {
  walk: Walk,
  bike: Bike,
  scooter: Scooter,
  moped: Moped,
  car: Car,
  carshare: CarRental,
  rideshare: Rideshare,
  carpool: Carpool,
  bus: Bus,
  vanpool: Vanpool,
  shuttle: Van,
  bikeshare: Bike,
  scootershare: Scooter,
  mopedshare: Moped,
  transit: Train,
}

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.3125rem 0.4375rem 0 0.4375rem;
  font-family: PolySans, sans-serif;
  color: ${(props) => props.theme.palette.text.primary};
  width: ${(props) => props.theme.pxToRem(293)};
`

const PopupTop = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 0.0625rem solid ${(props) => props.theme.palette.grey.grey300};
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
`

const EmployeeMainStats = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0.875rem;
`

const MainStatContainer = styled.div`
  display: flex;
`

const ArrowContainer = styled(Arrow)`
  margin: 0 0.375rem 0 0;
  transform: rotate(45deg);
`

const BasicClockContainer = styled(BasicClock)`
  margin: 0 0.3125rem 0 0.5rem;
`

const EmployeeTotalCases = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
`

const EmployeeDesc = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 0.8125rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding-bottom: 0.375rem;
`

const CommuteStatContainer = styled.div`
  display: flex;
  align-items: center;
`

const CommuteStat = styled.div`
  color: ${(props) => props.theme.palette.primary.pink400};
  font-size: 0.875rem;
  font-weight: 500;
  height: 1.1875rem;
`

const StatParentContainer = styled.div`
  display: flex;
  margin: 0 0.75rem 0.75rem 0;
`

const PopupBottom = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 0.325rem;
`

const StatsTop = styled.div`
  display: flex;
  justify-content: space-between;
`

const EmployeeStatContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.625rem;
`

const EmployeeStat = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
`

const StatDesc = styled.div`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 400;
`

const Span = styled.span`
  font-weight: 400;
`

const StyledImg = styled.img`
  min-width: 2.5rem;
  height: 2.5rem;
`

interface PopupProps {
  feature: Feature
  allWorksites?: Worksite[]
}

const PopupEmployees = React.memo((props: PopupProps) => {
  const { feature } = props

  const distance =
    !feature.properties.distance || feature.properties.distance === 'null'
      ? ' - '
      : (feature.properties.distance / 1609).toFixed(0)

  const time =
    !feature.properties.duration || feature.properties.duration === 'null'
      ? ' - '
      : (feature.properties.duration / 60).toFixed(0)

  const env =
    !feature.properties.env || feature.properties.env === 'null'
      ? ' - '
      : (feature.properties.env / 454).toFixed(2)
  const cost =
    !feature.properties.cost || feature.properties.cost === 'null'
      ? '2.25'
      : feature.properties.cost / 100
  const mode =
    !feature.properties.modeType || feature.properties.modeType === 'null'
      ? ' - '
      : feature.properties.modeType.split('')[0] === 'E'
        ? feature.properties.modeType.slice(1, 0).toLowerCase()
        : feature.properties.modeType.toLowerCase()
  const ModeSVG = commuteModes[mode] ? commuteModes[mode] : commuteModes['car']

  return (
    <PopupContainer>
      <PopupTop>
        <StyledImg
          alt={'commuter'}
          src={`${StaticAssetsUrl}/accounts/default-user-40.png`}
        />
        <EmployeeMainStats>
          <MainStatContainer>
            <EmployeeTotalCases>{feature.properties.id}</EmployeeTotalCases>
          </MainStatContainer>
          <EmployeeDesc>
            <ModeSVG width={14} height={14} />
            <Text variant='h5' marginLeft='0.25rem'>
              {mode}
            </Text>
          </EmployeeDesc>
          <CommuteStatContainer>
            <ArrowContainer height={11} width={10} />
            <CommuteStat>{distance} miles</CommuteStat>
            <BasicClockContainer height={12} width={12} />
            <CommuteStat>{time} mins to work</CommuteStat>
          </CommuteStatContainer>
        </EmployeeMainStats>
      </PopupTop>
      <PopupBottom>
        <StatsTop>
          <StatParentContainer>
            <PlatformSvg
              folder='metrics'
              variant='co2'
              width={24}
              height={24}
            />
            <EmployeeStatContainer>
              <EmployeeStat>
                {NumberUtils.formatNumber(env)}{' '}
                <Span>lb{RandomUtils.pluralCheck(parseFloat(env))}</Span>
              </EmployeeStat>
              <StatDesc>Env Impact (CO2)</StatDesc>
            </EmployeeStatContainer>
          </StatParentContainer>
          <StatParentContainer>
            <PlatformSvg
              folder='metrics'
              variant='money'
              width={24}
              height={24}
            />
            <EmployeeStatContainer>
              <EmployeeStat>{FormattingUtils.formatDollar(cost)}</EmployeeStat>
              <StatDesc>Cost</StatDesc>
            </EmployeeStatContainer>
          </StatParentContainer>
        </StatsTop>
      </PopupBottom>
    </PopupContainer>
  )
})

PopupEmployees.displayName = 'PopupEmployees'

export default PopupEmployees
