import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import Divider from 'core-system/Divider'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import CarpoolGroupCard from 'employee-platform/features/Carpool/components/CarpoolGroupCard'
import GridCard from 'employee-platform/shared/components/GridCard'
import CarpoolIcon from 'core-system/Icons/modeTypes/Carpool'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const CarpoolCountsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`

const CarpoolItem = styled.div<{ center?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${(props) => (props.center ? 'align-items: center; text-align: center;' : '')}
`

interface CarpoolDashboardCardProps {
  isMobile: boolean
}

const CarpoolDashboardCard = React.memo((props: CarpoolDashboardCardProps) => {
  const { isMobile } = props

  const { myCarpoolGroups, carpoolMemberships, sentCarpoolRequests } =
    useSelector((state: AppState) => state.employeePlatform)

  const navigate = useNavigate()

  const myCarpoolGroupDetails = myCarpoolGroups
    ? Object.values(myCarpoolGroups)
    : null
  const myCarpoolMembershipDetails = carpoolMemberships
    ? Object.values(carpoolMemberships)
    : null
  const mySentCarpoolRequests = sentCarpoolRequests
    ? Object.values(sentCarpoolRequests)
    : null

  const onCarpoolDetailsClick = useCallback(
    (groupId: string) => {
      navigate(`${Locations.EmployeeCarpool.Overview}/${groupId}`)
      SegmentService.track('carpool-group-click', {
        action: `carpool-group-${groupId}`,
        locationAt: 'employee-platform-dashboard',
      })
    },
    [navigate]
  )

  if (!myCarpoolGroups || !carpoolMemberships || !sentCarpoolRequests) {
    return <Loading />
  }

  return (
    <GridCard
      headerTitle='Carpool'
      headerIcon={<CarpoolIcon color={palette.primary.pink800} />}
      clickable={true}
      onClick={() => navigate(Locations.EmployeeCarpool.Overview)}
      tooltipText='Go to Carpool Overview'
    >
      <Container>
        {myCarpoolGroupDetails.length > 0 ? (
          <CarpoolItem>
            <Text variant='h5' style={{ marginLeft: '0.5rem' }}>
              My Carpool
            </Text>
            <CarpoolGroupCard
              carpoolRelation='OWNER'
              groupData={myCarpoolGroupDetails[0]}
              isMobile={isMobile}
              onViewDetailsClick={() =>
                onCarpoolDetailsClick(myCarpoolGroupDetails[0].id)
              }
            />
          </CarpoolItem>
        ) : null}
        <Divider direction='horizontal' marginY='0.5rem' />
        <CarpoolCountsContainer>
          <CarpoolItem center>
            <Text variant='h2' textColor={palette.primary.pink400}>
              {myCarpoolMembershipDetails.length}
            </Text>
            <Text variant='action3'>{`Carpool${
              myCarpoolMembershipDetails.length !== 1 ? 's' : ''
            } Joined`}</Text>
          </CarpoolItem>
          <Divider direction='vertical' />
          <CarpoolItem center>
            <Text variant='h2' textColor={palette.primary.pink400}>
              {mySentCarpoolRequests.length}
            </Text>
            <Text variant='action3'>{`Request${
              mySentCarpoolRequests.length !== 1 ? 's' : ''
            } Sent`}</Text>
          </CarpoolItem>
        </CarpoolCountsContainer>
      </Container>
    </GridCard>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolDashboardCard.displayName = 'CarpoolDashboardCard'
}

export default CarpoolDashboardCard
