import Banner from 'core-system/Banner'
import Chip from 'core-system/Chip/Chip'
import FlexContainer from 'core-system/FlexContainer'
import BonusMedal from 'core-system/Icons/Leaderboards/BonusMedal'
import RewardsIcons from 'core-system/Icons/Sidebar/Leaderboards'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import LeaderboardModal from 'features/Leaderboards/components/LeaderboardModal'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import {
  Leaderboard,
  LeaderboardsGroup,
} from 'redux/leaderboards/leaderboardsTypes'
import { Locations } from 'shared/Router/Locations'
import styled, { css } from 'styled-components'
import LeaderboardUtils from './LeaderboardUtils'
import NoPaymentModal from 'shared/components/NoPaymentModal'

const ProgramRow = styled.div`
  ${(props) => props.theme.baseCard}
  cursor: pointer;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const PurpleContainer = styled(FlexContainer)`
  padding: 0.5rem;
  border-radius: 0.3125rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1rem;
`

const StatContainer = styled.div`
  margin-left: 2.4375rem;
`

const IconContainer = styled.div<{ isPending: boolean }>`
  position: relative;
  padding: 0.25rem;
  display: flex;

  ${(props) =>
    props.isPending &&
    css`
      opacity: 0.65;
    `};
`

const BonusContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const renderLeaderboardIcons = (leaderboards: Leaderboard[]) => {
  return leaderboards.map((leaderboard) => {
    return (
      <IconContainer
        key={leaderboard.type}
        isPending={leaderboard.status === 'PENDING'}
      >
        {LeaderboardUtils.leaderboardData[leaderboard.type].icon}
        {leaderboard.bonuses.some((bonus) => bonus) && (
          <BonusContainer>
            <BonusMedal width={16} height={16} />
          </BonusContainer>
        )}
      </IconContainer>
    )
  })
}

const renderProgramRows = (
  allSegments: Segment[],
  leaderboardsMap: Dictionary<LeaderboardsGroup>,
  handleOnClick: (segmentId: string) => void
) => {
  return [...allSegments]
    .sort(
      (a, b) =>
        (leaderboardsMap[b.id] ? leaderboardsMap[b.id].active : 0) -
        (leaderboardsMap[a.id] ? leaderboardsMap[a.id].active : 0)
    )
    .map((segment) => {
      const isActive = leaderboardsMap[segment.id]
      const activeLeaderboards =
        isActive &&
        LeaderboardUtils.getActiveLeaderboards(
          leaderboardsMap[segment.id].programGroups
        )
      return (
        <ProgramRow key={segment.id} onClick={() => handleOnClick(segment.id)}>
          <FlexContainer justifyContent='space-between'>
            <FlexContainer alignItems='center'>
              <PurpleContainer center>
                <RewardsIcons color={palette.text.secondary} />
              </PurpleContainer>
              <div>
                <Text variant='action2'>
                  {segment.name === 'All Employees'
                    ? 'All Commuters'
                    : segment.name}
                </Text>
                <Text variant='body2' textColor={palette.text.placeholder}>
                  {isActive ? leaderboardsMap[segment.id].active : 0} Active
                  Competitions
                </Text>
              </div>
            </FlexContainer>
            <FlexContainer alignItems='center' justifyContent='space-between'>
              {isActive ? (
                <FlexContainer>
                  {renderLeaderboardIcons(activeLeaderboards)}
                </FlexContainer>
              ) : (
                <Chip variant={'disabled'}>off</Chip>
              )}
              <StatContainer>
                <Text variant='body2' textColor={palette.text.placeholder}>
                  Commuters
                </Text>
                <Text variant='action2'>{segment.commuters}</Text>
              </StatContainer>
            </FlexContainer>
          </FlexContainer>
        </ProgramRow>
      )
    })
}

const LeaderboardsPrograms = React.memo(() => {
  const navigate = useNavigate()

  const { leaderboardsMap } = useSelector(
    (state: AppState) => state.leaderboards
  )
  const { allSegments, segmentsMap, hasValidPaymentMethod, profile } =
    useSelector((state: AppState) => state.employer)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isNoPaymentModalOpen, setIsNoPaymentModalOpen] = useState(false)

  const handleOnClick = useCallback(
    (segmentId: string) => {
      navigate(`/leaderboards/${segmentId}`, {
        state: { from: Locations.Micromobility.Programs },
      })
      SegmentService.track('segmentNav-action-click', {
        action: `segment-${segmentsMap[segmentId].name}`,
        locationAt: 'leaderboards',
      })
    },
    [navigate, segmentsMap]
  )

  const handleAddCompetitionClick = () => {
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      setIsModalOpen(true)
    } else {
      setIsNoPaymentModalOpen(true)
    }
  }

  if (!leaderboardsMap || !allSegments) {
    return <Loading fullPage />
  }

  return (
    <>
      {Object.keys(leaderboardsMap).length === 0 ? (
        <>
          <LeaderboardModal
            open={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
          />
          <Banner
            variant='cta'
            title='Set Up Leaderboards Today!'
            description='Empower commuters to compete for monthly prizes while incentivizing smart commuter choices.'
            cta='Add a Competition'
            ctaCallback={() => handleAddCompetitionClick()}
            background='bannerLeaderboards'
          />
        </>
      ) : (
        <Banner
          variant='info'
          title='Your Leaderboards'
          description='Empowering commuters to compete for monthly prizes while incentivizing smart commuter choices.'
          background='bannerLeaderboards'
        />
      )}
      <div>
        {renderProgramRows(allSegments, leaderboardsMap, handleOnClick)}
      </div>
      <NoPaymentModal
        open={isNoPaymentModalOpen}
        closeModal={() => setIsNoPaymentModalOpen(false)}
        product='competition'
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LeaderboardsPrograms.displayName = 'LeaderboardsPrograms'
}

export default LeaderboardsPrograms
