export default {
  50: '#F3FBF5',
  100: '#E6F7EB',
  200: '#CFF0D7',
  300: '#A8E3B7',
  400: '#7BCF95',
  500: '#4EB974',
  600: '#2C9955',
  700: '#1E6F3E',
  800: '#134428',
  900: '#0A2214',
}
