import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import CheckIcon from '@mui/icons-material/Check'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import palette from 'core-system/Themes/palette'

const useStyles = makeStyles(() => ({
  checkMark: {
    fill: palette.text.primary,
  },
  checkMarkBlue: {
    fill: palette.primary.pink800,
  },
}))

interface TickProps {
  className?: string
  isMessageSent?: boolean
  isMessageRead?: boolean
  isMessageDelivered?: boolean
}

const Tick: React.FC<TickProps> = ({
  className,
  isMessageSent = false,
  isMessageRead = false,
  isMessageDelivered = false,
}) => {
  const classes = useStyles()

  const displayCheckMark = () => {
    if (isMessageRead) {
      return <DoneAllIcon className={classes.checkMarkBlue} />
    }
    if (isMessageDelivered) {
      return <DoneAllIcon className={classes.checkMark} />
    }
    if (isMessageSent) {
      return <CheckIcon className={classes.checkMark} />
    }
    return null
  }

  return <div className={className}>{displayCheckMark()}</div>
}

export default React.memo(Tick)
