import React, { useEffect, useState } from 'react'
import { Droppable, DroppableProps } from 'react-beautiful-dnd'

//added bcs react-beautiful-dnd stopped working with react-18 with strict mode turned on
//https://github.com/atlassian/react-beautiful-dnd/issues/2399
const StrictModeDroppable = ({
  children,
  isDropDisabled = false,
  isCombineEnabled = false,
  ignoreContainerClipping = false,
  ...props
}: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))

    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])

  if (!enabled) {
    return null
  }

  return (
    <Droppable
      {...props}
      isDropDisabled={Boolean(isDropDisabled)}
      isCombineEnabled={Boolean(isCombineEnabled)}
      ignoreContainerClipping={Boolean(ignoreContainerClipping)}
    >
      {children}
    </Droppable>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  StrictModeDroppable.displayName = 'StrictModeDroppable'
}

export default StrictModeDroppable
