import React, { useEffect, useRef, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import BubbleWrapper from './BubbleWrapper'
import palette from 'core-system/Themes/palette'
import { CircularProgress } from '@mui/material'
import {
  fetchRoute,
  createMarker,
  createMap,
  createPolyline,
  createInfoWindow,
  polylineColor,
  polylineOpacity,
  createCircularMarkersForLegs,
  getLineColor,
} from '../google-maps/googleMapsFunctions'
import JourneyPlanner from '../google-maps/JourneyPlanner'

const useStyles = makeStyles(() => ({
  mapContainer: {
    padding: '12px',
  },
  mapInnerContainer: {
    borderRadius: '8px',
    overflow: 'hidden',
  },
  map: {
    width: '100%',
    height: '450px',
    '& iframe': {
      // This was preventing the map from being clickable
      display: 'none',
    },
    '& .gm-style-mtc-bbw': {
      // This was preventing the map from being clickable
      zIndex: '1000000 !important',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 400,
  },
}))

const MapBubble = ({
  chatId = '',
  chatIsInView = () => {},
  showLike = false,
  onLikeClick = () => {},
  isLiked = false,
  coordinates = [{ latitude: 37.7749, longitude: -122.4194 }],
  mapApiLoaded = false,
}) => {
  const classes = useStyles()
  const mapRef = useRef(null)
  const [infoWindows, setInfoWindows] = useState<google.maps.InfoWindow[]>([])
  const [stepsPolyLines, setStepsPolyLines] = useState<google.maps.Polyline[]>(
    []
  )
  const [journeyPlannerSteps, setJourneyPlannerSteps] = useState<any[]>([])
  const mapType = coordinates.length === 2 ? 'route' : 'marker'

  useEffect(() => {
    if (!mapApiLoaded || !mapRef.current) return
    const map = createMap(mapRef, chatId, mapType, coordinates)

    if (mapType === 'marker') {
      createMarker(map, coordinates[0])
    }

    if (mapType === 'route') {
      const bounds = new window.google.maps.LatLngBounds()
      coordinates.forEach((coord) => {
        bounds.extend(
          new window.google.maps.LatLng(coord.latitude, coord.longitude)
        )
      })
      map.fitBounds(bounds)
      displayRoutes(map)
    }
  }, [coordinates, mapApiLoaded])

  const displayRoutes = async (map: google.maps.Map) => {
    const routes = await fetchRoute(coordinates)
    const allPolyLines = []
    const allInfoWindows = []
    const allStepsPolyLines = []
    routes.forEach((route, routesIndex) => {
      const { transitSteps, polyline } = route

      const decodedPathPolyline =
        window.google.maps.geometry.encoding.decodePath(
          polyline.encodedPolyline
        )

      // create info window
      const infoWindow = createInfoWindow(route.distance, route.duration)

      // create markers
      createMarker(map, {
        latitude: decodedPathPolyline[0].lat(),
        longitude: decodedPathPolyline[0].lng(),
      })
      createMarker(map, {
        latitude: decodedPathPolyline[decodedPathPolyline.length - 1].lat(),
        longitude: decodedPathPolyline[decodedPathPolyline.length - 1].lng(),
      })

      // open info window on the first route
      if (routesIndex === 0) {
        infoWindow.setPosition(
          decodedPathPolyline[Math.floor(decodedPathPolyline.length / 2)]
        )
        infoWindow.open(map)
        // adding the index 0 into current polylines
        setJourneyPlannerSteps(transitSteps)
      }

      allInfoWindows.push(infoWindow)
      allPolyLines.push(polyline)

      if (transitSteps && transitSteps.length > 0) {
        transitSteps.forEach((step) => {
          const decodedPath = window.google.maps.geometry.encoding.decodePath(
            step.polyline.encodedPolyline
          )

          // create circular markers for the legs
          createCircularMarkersForLegs(map, step.startLocation)

          // create polyline
          const stepPolyline = createPolyline(
            map,
            decodedPath,
            routesIndex === 0,
            (event: google.maps.KmlMouseEvent) =>
              onPolylineClick(event, infoWindow, map, routesIndex),
            step.travelMode,
            getLineColor(step.transitDetails)
          )
          allStepsPolyLines.push({
            ...step,
            polyline: stepPolyline,
            routeIndex: routesIndex,
            travelMode: step.travelMode,
            transitLineColor: getLineColor(step.transitDetails),
          })
        })
      }
    })
    setInfoWindows(allInfoWindows)
    setStepsPolyLines(allStepsPolyLines)
  }

  const onPolylineClick = (
    event: google.maps.KmlMouseEvent,
    infoWindow: google.maps.InfoWindow,
    map: google.maps.Map,
    routeIndex: number
  ) => {
    infoWindow.setPosition(event.latLng)
    infoWindow.open(map)

    const allActiveSteps = []

    // Use functional updates to ensure the latest state is used
    // change every other polyline to grey
    // Getting all the steps polyline for this route
    setStepsPolyLines((stepsPolyLines) => {
      stepsPolyLines.forEach((stepPolyline) => {
        if (stepPolyline.routeIndex !== routeIndex) {
          stepPolyline.polyline.setOptions({
            strokeColor: polylineColor(
              stepPolyline.travelMode,
              false,
              stepPolyline.transitLineColor
            ),
            strokeOpacity: polylineOpacity(stepPolyline.travelMode, false),
          })
        } else {
          allActiveSteps.push(stepPolyline)
          stepPolyline.polyline.setOptions({
            strokeColor: polylineColor(
              stepPolyline.travelMode,
              true,
              stepPolyline.transitLineColor
            ),
            strokeOpacity: polylineOpacity(stepPolyline.travelMode, true),
            zIndex: 1000,
          })
        }
      })
      return stepsPolyLines
    })

    // close every other info window
    setInfoWindows((currentInfoWindows) => {
      currentInfoWindows.forEach((infoWindow, thisIndex) => {
        if (thisIndex !== routeIndex) {
          infoWindow.close()
        }
      })
      return currentInfoWindows
    })

    setJourneyPlannerSteps(allActiveSteps)
  }

  if (!mapApiLoaded) {
    return (
      <BubbleWrapper
        bubbleType='partner'
        chatId={chatId}
        chatIsInView={chatIsInView}
        showLike={showLike}
        onLikeClick={onLikeClick}
        isLiked={isLiked}
        width='full'
      >
        <div className={classes.loadingContainer}>
          <CircularProgress sx={{ color: palette.primary.pink800 }} />
        </div>
      </BubbleWrapper>
    )
  }

  return (
    <BubbleWrapper
      bubbleType='partner'
      chatId={chatId}
      chatIsInView={chatIsInView}
      showLike={showLike}
      onLikeClick={onLikeClick}
      isLiked={isLiked}
      width='full'
    >
      <div className={classes.mapContainer}>
        <div className={classes.mapInnerContainer}>
          <div ref={mapRef} className={classes.map} />
        </div>
      </div>
      {mapType === 'route' && <JourneyPlanner steps={journeyPlannerSteps} />}
    </BubbleWrapper>
  )
}

export default React.memo(MapBubble)
