import { takeLatest, call, put } from 'redux-saga/effects'
import { finchActions } from './finchSlice'
import { getPayrollProviders } from './finchService'
import { PayrollProvider } from './finchTypes'
import { all } from 'redux-saga/effects'

// Worker Saga: Fetch Finch Providers
function* fetchFinchProvidersWorker() {
  try {
    const providers: PayrollProvider[] = yield call(getPayrollProviders)
    yield put(finchActions.fetchSuccess(providers))
  } catch (error: any) {
    yield put(finchActions.fetchFailure(error.message))
  }
}

// Watcher Saga
export function* finchProviderSaga() {
  yield takeLatest(finchActions.fetchRequest.type, fetchFinchProvidersWorker)
}

export default function* rootSaga() {
  yield all([finchProviderSaga()])
}
