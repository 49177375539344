import React from 'react'
import styled from 'styled-components'
import Modal, { ModalHeader, ModalFooter, ModalBody } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import Button from 'core-system/Button'
import Text from 'core-system/Text'
import { Employee } from 'redux/employee/employeeTypes'
import Checkbox from 'core-system/Checkbox'
import FlexContainer from 'core-system/FlexContainer'
import palette from 'core-system/Themes/palette'

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

const UsersHeader = styled.div`
  ${(props) => props.theme.typography.body2}
  color: ${(props) => props.theme.palette.text.placeholder};
  display: flex;
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey300};
`
const Check = styled.div`
  width: 3rem;
`
const Name = styled.div`
  flex 1;
`
const Email = styled.div`
  flex: 2;
  align-self: center;
`

interface QTFAddUsersProps {
  allUsers: Employee[]
  selectedUserIds: string[] | null
  open: boolean
  closeModal: () => void
  buttonCallback: (id: string[]) => void
}

const QTFAddUsersModal = React.memo((props: QTFAddUsersProps) => {
  const { open, closeModal, buttonCallback, allUsers, selectedUserIds } = props
  const [currentUserIds, setCurrentUserIds] = React.useState([])
  const [showError, setShowError] = React.useState(false)

  React.useEffect(() => {
    setCurrentUserIds(selectedUserIds)
  }, [selectedUserIds])

  const onCheckboxClick = (id: string, isDelete: boolean) => {
    if (showError) {
      setShowError(false)
    }
    const updated = isDelete
      ? currentUserIds.filter((userId) => userId !== id)
      : [...currentUserIds, id]
    setCurrentUserIds(updated)
  }

  const onAddClick = () => {
    if (currentUserIds.length < 1) {
      setShowError(true)
    } else {
      buttonCallback(currentUserIds)
      closeModal()
    }
  }

  return (
    <Modal open={open} onClose={closeModal} width={pxToRem(550)}>
      <ModalHeader title={'Select Users'} />
      <ModalBody>
        <UsersHeader>
          <Check />
          <Name>Name</Name>
          <Email>Email</Email>
        </UsersHeader>
        {allUsers.map((user) => {
          const isActive = currentUserIds?.some((id) => id === user.id)
          return (
            <FlexContainer key={user.id} paddingY='0.5rem'>
              <Check>
                <Checkbox
                  checked={isActive}
                  onClick={() => onCheckboxClick(user.id, isActive)}
                />
              </Check>
              <Name>
                <Text variant='action4' marginRight={'0.5rem'}>
                  {user.firstName + ' ' + user.lastName}
                </Text>
              </Name>
              <Email>
                <Text variant='action4'>{user.email}</Text>
              </Email>
            </FlexContainer>
          )
        })}
        {showError && (
          <Text
            variant='body1'
            paddingTop={'1rem'}
            textColor={palette.error.red500}
          >
            Please select at least 1 user.
          </Text>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonContainer>
          <Button variant='tertiary' marginRight='1rem' onClick={closeModal}>
            Cancel
          </Button>
          <Button variant={'primary'} onClick={onAddClick}>
            Add
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFAddUsersModal.displayName = 'QTFAddUsersModal'
}

export default QTFAddUsersModal
