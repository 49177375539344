import React, { useEffect, useRef } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Tick from './Tick'
import palette from 'core-system/Themes/palette'
import { IconButton, Tooltip } from '@mui/material'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'

const useStyles = makeStyles(() => ({
  bubble: {
    margin: '1em',
    display: 'inline-block',
    position: 'relative',
    height: 'auto',
    borderRadius: 12,
    webkitBorderRadius: 30,
    mozBorderRadius: 30,
    minHeight: 50,
  },
  partnerColor: {
    backgroundColor: palette.primary.pink200,
  },
  ownColor: {
    backgroundColor: palette.grey.grey100,
  },
  leftBubble: {
    content: ' ',
    position: 'absolute',
    width: 0,
    height: 0,
    left: -20,
    right: 'auto',
    top: 12,
    bottom: 'auto',
    border: '12px solid',
    borderColor: `${palette.primary.pink200} ${palette.primary.pink200} transparent transparent`,
  },
  rightBubble: {
    content: ' ',
    position: 'absolute',
    width: 0,
    height: 0,
    left: 'auto',
    right: -20,
    top: 12,
    bottom: 'auto',
    border: '12px solid',
    borderColor: `${palette.grey.grey100} transparent transparent ${palette.grey.grey100}`,
  },
  checkMark: {
    position: 'absolute',
    right: 10,
    bottom: 6,
  },
  likeIcon: {
    position: 'absolute',
    right: 10,
    bottom: 6,
    margin: '0 !important',
    padding: '0 !important',
  },
}))

interface BubbleWrapperProps {
  className?: string
  bubbleType?: 'own' | 'partner'
  showTick?: boolean
  chatId?: string
  isMessageDelivered?: boolean
  isMessageRead?: boolean
  isMessageSent?: boolean
  chatIsInView?: () => void
  children?: React.ReactNode
  indicatorColor?: string
  showIndicator?: boolean
  showLike?: boolean
  onLikeClick?: () => void
  isLiked?: boolean
  width?: 'full' | 'half'
}

const BubbleWrapper: React.FC<BubbleWrapperProps> = ({
  className,
  bubbleType = 'own',
  showTick = true,
  chatId = '',
  isMessageDelivered = false,
  isMessageRead = false,
  isMessageSent = false,
  chatIsInView = () => {},
  children,
  indicatorColor,
  showIndicator = true,
  showLike = false,
  onLikeClick = () => {},
  isLiked = false,
  width = 'half',
}) => {
  const classes = useStyles()
  const bubbleRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    bubbleObserverFunction()
  }, [])

  const bubbleObserverFunction = async () => {
    let isBubbleInView = false
    if (bubbleRef.current) {
      const observer = new IntersectionObserver(async (entries) => {
        const [entry] = entries
        if (entry.isIntersecting && !isBubbleInView) {
          chatIsInView()
          isBubbleInView = true
        }
      })
      observer.observe(bubbleRef.current)
    }
  }

  const bubbleClass = () => {
    if (bubbleType === 'partner') {
      return classes.leftBubble
    }
    if (bubbleType === 'own') {
      return classes.rightBubble
    }
    return null
  }

  const bubbleColorClass = () => {
    if (bubbleType === 'partner') {
      return classes.partnerColor
    }
    if (bubbleType === 'own') {
      return classes.ownColor
    }
    return null
  }

  const getIndicatorColor = () => {
    if (!indicatorColor) {
      return null
    }
    if (bubbleType === 'partner') {
      return `${indicatorColor} ${indicatorColor} transparent transparent`
    }
    if (bubbleType === 'own') {
      return `${indicatorColor} transparent transparent ${indicatorColor}`
    }
    return null
  }

  return (
    <div
      ref={bubbleRef}
      id={chatId}
      className={`${classes.bubble} ${bubbleColorClass()} ${className}`}
      style={{ width: width === 'full' ? 500 : 300 }}
    >
      {children}
      {showIndicator && (
        <div
          className={bubbleClass()}
          style={{
            borderColor: getIndicatorColor(),
          }}
        />
      )}
      {showTick && (
        <Tick
          isMessageSent={isMessageSent}
          isMessageRead={isMessageRead}
          isMessageDelivered={isMessageDelivered}
          className={classes.checkMark}
        />
      )}
      {/* {showLike && (
        <Tooltip title='Like the response' placement='top'>
          <IconButton className={classes.likeIcon} onClick={onLikeClick}>
            <ThumbUpOffAltIcon color={isLiked ? 'primary' : 'disabled'} />
          </IconButton>
        </Tooltip>
      )} */}
    </div>
  )
}

export default React.memo(BubbleWrapper)
