import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import BubbleWrapper from './BubbleWrapper'

const useStyles = makeStyles(() => ({
  text: {
    padding: '1em',
    textAlign: 'left',
    lineHeight: '1.5em',
    wordWrap: 'break-word',
    fontWeight: 500,
  },
  animatedText: {
    background: 'linear-gradient(270deg, #808080, #e0e0e0, #000000)',
    backgroundSize: '400% 400%',
    animation: '$gradientAnimation 4s ease infinite',
    // These properties clip the gradient to the text
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  '@keyframes gradientAnimation': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
}))

interface ThinkingBubbleProps {
  text?: string
  chatId?: string
  chatIsInView?: () => void
  thinkingAnimation?: boolean
}

const ThinkingBubble: React.FC<ThinkingBubbleProps> = ({
  text = '',
  chatId = '',
  chatIsInView = () => {},
  thinkingAnimation = false,
}) => {
  const classes = useStyles()

  return (
    <BubbleWrapper
      bubbleType='partner'
      chatId={chatId}
      chatIsInView={chatIsInView}
    >
      <div className={classes.text}>
        <Typography
          component='div'
          variant='body1'
          className={thinkingAnimation ? classes.animatedText : undefined}
        >
          {text}
        </Typography>
      </div>
    </BubbleWrapper>
  )
}

export default React.memo(ThinkingBubble)
