import Card from 'core-system/Card'
import FlexContainer from 'core-system/FlexContainer'
import Partner from 'core-system/Icons/Misc/Partner'
import Loading from 'core-system/Loading'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import TransitLogo from 'core-system/TransitLogo'
import React from 'react'
import { Merchant, ServiceModeType } from 'redux/programs/programsTypes'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'

const GridContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.theme.pxToRem(263)}, 1fr)
  );
  gap: 1.5rem 1rem;
`

const ProviderCard = styled(Card)`
  padding: 1.5rem;
  margin: 0;
  position: relative;
`

const PartnerContainer = styled.div`
  position: absolute;
  top: -2px;
  right: 15%;

  svg {
    fill: none;
  }
`

interface ProviderGridCellProps {
  merchant: Merchant
  onClick?: any
}

const ProviderGridCell = React.memo((props: ProviderGridCellProps) => {
  const { merchant, onClick } = props

  return (
    <ProviderCard useMinWidth={true} onClick={onClick} clickable={onClick}>
      <FlexContainer centerApart>
        <TransitLogo
          modeType={
            merchant.iconUrl
              ? 'custom'
              : (merchant.modeTypes[0] as ServiceModeType)
          }
          size='large'
          src={merchant.iconUrl || merchant.modeTypes[0]}
        />
        {merchant.fleetPartner && (
          <PartnerContainer>
            <Partner height={55} width={54} />
          </PartnerContainer>
        )}
      </FlexContainer>
      <Text
        variant='h5'
        marginTop='1.5rem'
        fontWeight='bold'
        fontSize='1.125rem'
        textColor={palette.primary.pink800}
      >
        {merchant.name}
      </Text>
      <Text
        variant='action4'
        marginTop='0.125rem'
        textColor={palette.text.secondary}
      >
        {merchant.modeTypes
          .map((modeType) =>
            ProgramUtils.formatElectricModeTypes(RandomUtils.title(modeType))
          )
          .join(', ')}
      </Text>
    </ProviderCard>
  )
})
ProviderGridCell.displayName = 'ProviderGridCell'

interface ProviderGridProps {
  filteredMerchants: Merchant[]
  isLoading?: boolean
  onCellClick: (...args) => void
}

const ProviderGrid = React.memo((props: ProviderGridProps) => {
  const { filteredMerchants, isLoading = false, onCellClick } = props

  if (isLoading) return <Loading isCard={false} />
  if (filteredMerchants.length === 0)
    return (
      <FlexContainer center marginTop='4rem'>
        <Text variant='action2'>No service providers found.</Text>
      </FlexContainer>
    )

  return (
    <GridContainer data-cy='merchant-catalog-merchants-container'>
      {filteredMerchants.map((merchant, idx) => (
        <ProviderGridCell
          merchant={merchant}
          key={idx}
          onClick={() => {
            onCellClick(merchant)
          }}
        />
      ))}
    </GridContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProviderGrid.displayName = 'Provider Catalog Grid'
}

export default ProviderGrid
