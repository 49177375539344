import React from 'react'
import GridCard from 'employee-platform/shared/components/GridCard'
import CreditCardIcon from 'core-system/Icons/Accounts/CreditCard'
import palette from 'core-system/Themes/palette'
import { useNavigate } from 'react-router-dom'
import { Locations } from 'shared/Router/Locations'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'
import Text from 'core-system/Text'
import FormattingUtils from 'shared/FormattingUtils'
import Divider from 'core-system/Divider'
import { Payment } from 'redux/employeePlatform/employeePlatformTypes'

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  justify-content: space-between;
  width: 100%;
  height: 100%;
`

const SectionContainer = styled.div<{ isMobile: boolean; spread?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'row' : 'column')};
  ${(props) => (props.spread ? 'justify-content: space-evenly;' : '')}
  height: 100%;
  width: 100%;
`

const SectionStatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem;
`

const getPaymentMethodCounts = (paymentMethods: Payment[]) => {
  const counts = {
    card: 0,
    bankAccount: 0,
  }
  paymentMethods.forEach((paymentMethod) => {
    if (paymentMethod.type === 'us_bank_account') {
      counts.bankAccount++
    } else {
      counts.card++
    }
  })
  return counts
}

const sectionStat = (label: string, value: number, isMoney?: boolean) => {
  const stat = isMoney ? FormattingUtils.formatDollar(value) : value
  return (
    <SectionStatContainer>
      <Text variant='h2' textColor={palette.primary.pink400}>
        {stat}
      </Text>
      <Text variant='action3' textAlign='center'>
        {label}
      </Text>
    </SectionStatContainer>
  )
}

interface WalletDashboardCardProps {
  isMobile: boolean
}

const WalletDashboardCard = React.memo((props: WalletDashboardCardProps) => {
  const { isMobile } = props

  const { wallet } = useSelector((state: AppState) => state.employeePlatform)

  const paymentMethodCounts = getPaymentMethodCounts(wallet.paymentMethods)

  const navigate = useNavigate()

  return (
    <GridCard
      headerTitle='Wallet'
      headerIcon={<CreditCardIcon color={palette.primary.pink800} />}
      clickable={true}
      onClick={() => navigate(Locations.EmployeeSettings)}
      tooltipText='Go to Wallet Details'
    >
      <Container isMobile={isMobile}>
        <SectionContainer isMobile={isMobile} spread>
          {sectionStat(
            'Available Balance',
            wallet.currentBalance + wallet.rewards.balance,
            true
          )}
          <Divider
            direction={isMobile ? 'vertical' : 'horizontal'}
            size={isMobile ? '80%' : '100%'}
          />
          {sectionStat('Pending Balance', wallet.pendingBalance, true)}
        </SectionContainer>
        <Divider
          direction={isMobile ? 'horizontal' : 'vertical'}
          marginY={isMobile ? '1rem' : 0}
        />
        <SectionContainer isMobile={isMobile} spread>
          {sectionStat('Cards', paymentMethodCounts.card)}
          <Divider
            direction={isMobile ? 'vertical' : 'horizontal'}
            size={isMobile ? '80%' : '100%'}
          />
          {sectionStat('Bank Accounts', paymentMethodCounts.bankAccount)}
        </SectionContainer>
      </Container>
    </GridCard>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  WalletDashboardCard.displayName = 'WalletDashboardCard'
}

export default WalletDashboardCard
