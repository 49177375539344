import React, { useState } from 'react'
import styled from 'styled-components'
import {
  FINCH_CLIENT_ID,
  FINCH_SANDBOX_SETTING,
} from 'redux/config/services/Constants'
import { useFinchConnect } from '@tryfinch/react-connect'
import SegmentService from 'redux/config/services/SegmentService'
import Chevron from 'core-system/Icons/Actions/Chevron'
import { OnboardingStep } from './GettingStartedView'
import { useDispatch, useSelector } from 'react-redux'
import { employerActions } from 'redux/employer/employerSlice'
import employerService from 'redux/employer/employerService'
import { extraOnboardingCopy } from './OnboardingUtils'
import HeaderTextButton from 'core-system/ButtonV2/HeaderTextButton'
import { AppState } from 'redux/config/store'
import { useGateValue } from '@statsig/react-bindings'
import SelectProvidersModal from 'features/Finch/components/SelectProviders'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SetupOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

interface OnboardingWorkforceSetupProps {
  setLoading: (loading: boolean) => void
  setOnboardingStep: (step: OnboardingStep) => void
}

const OnboardingWorkforceSetup = React.memo(
  (props: OnboardingWorkforceSetupProps) => {
    const { setLoading, setOnboardingStep } = props
    const [showSelectProvidersModal, setShowSelectProvidersModal] =
      useState(false)
    const { email } = useSelector((state: AppState) => state.user.profile)
    const isConnectedAccountEnabled = useGateValue('connected_account')

    const dispatch = useDispatch()

    const createConnectedAccount = async (email: string) => {
      await employerService.createConnectedAccount(email).then((res) => {
        dispatch(employerActions.createConnectedAccountSuccess(res.data))
      })
    }

    const onSuccess = ({ code }) => {
      setLoading(true)
      employerService
        .integrateHris(code)
        .then((res) => {
          dispatch(employerActions.integrateHrisSuccess(res.data))
        })
        .then(() => {
          if (isConnectedAccountEnabled) {
            createConnectedAccount(email)
              .then(() => setLoading(false))
              .then(() => setOnboardingStep('addPayment'))
          } else {
            setLoading(false)
            setOnboardingStep('addPayment')
          }
        })
    }

    const onError = ({ errorMessage }) => {
      console.error(errorMessage)
      SegmentService.track('hris-connect-error', {
        msg: errorMessage,
      })
    }

    const onClose = () => {
      SegmentService.track('hris-connect-modal-closed', {
        onboardingStep: 'workforceSetup',
      })
    }

    const { open } = useFinchConnect({
      clientId: FINCH_CLIENT_ID,
      products: [],

      // If using any client ID other than the sandbox client ID, ensure that the sandbox parameter is set to false
      sandbox: FINCH_SANDBOX_SETTING, // ENSURE THIS IS SET TO FALSE FOR PROD / STAGING
      // If the sandbox parameter is set to anything other than false, then the manual parameter must be set to false
      manual: FINCH_SANDBOX_SETTING ? false : true, // ENSURE THIS IS SET TO TRUE FOR PROD

      onSuccess,
      onError,
      onClose,
    })

    const handleSelectProvidersModal = React.useCallback(() => {
      SegmentService.track('hris-connect-modal-opened', {
        onboardingStep: 'workforceSetup',
      })
      setShowSelectProvidersModal(true)
    }, [])

    const handleCloseSelectProvidersModal = React.useCallback(() => {
      setShowSelectProvidersModal(false)
    }, [])

    const onProviderSelected = React.useCallback(
      (provider: any) => {
        setShowSelectProvidersModal(false)
        open({
          products: provider.products,
          payrollProvider: provider.id,
        })
      },
      [open]
    )

    return (
      <Container>
        <SetupOptions>
          <HeaderTextButton
            header={extraOnboardingCopy.setupWorkforce.automated.header}
            text={extraOnboardingCopy.setupWorkforce.automated.text}
            iconRight={<Chevron style={{ transform: 'rotate(-90deg)' }} />}
            onClick={handleSelectProvidersModal}
          />
          <HeaderTextButton
            header={extraOnboardingCopy.setupWorkforce.manual.header}
            text={extraOnboardingCopy.setupWorkforce.manual.text}
            iconRight={<Chevron style={{ transform: 'rotate(-90deg)' }} />}
            onClick={() => setOnboardingStep('csvUpload')}
          />
          <SelectProvidersModal
            open={showSelectProvidersModal}
            onClose={handleCloseSelectProvidersModal}
            selectProvider={onProviderSelected}
          />
        </SetupOptions>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OnboardingWorkforceSetup.displayName = 'OnboardingWorkforceSetup'
}

export default OnboardingWorkforceSetup
