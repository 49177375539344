import React from 'react'
import { Feature } from 'geojson'
import styled from 'styled-components'
import { convertMetresToMiles } from 'employee-platform/shared/utils'
import {
  InfoContainer,
  InfoItem,
} from 'employee-platform/shared/styles/CardList'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0.4375rem 0 0.4375rem;
  min-width: ${(props) => props.theme.pxToRem(200)};
  max-width: ${(props) => props.theme.pxToRem(300)};
`

const popupDetail = (label: string, value: string, isMobile: boolean) => {
  return (
    <InfoItem isMobile={isMobile}>
      <Text variant='action3'>{`${label}:`}</Text>
      <Text variant='action3' textColor={palette.text.secondary} hideOverflow>
        {value}
      </Text>
    </InfoItem>
  )
}

interface CarpoolMapPopupProps {
  isMobile: boolean
  feature: Feature
}

const CarpoolMapPopup = React.memo((props: CarpoolMapPopupProps) => {
  const { isMobile, feature } = props

  const properties = feature.properties

  const distFromMe =
    properties.distFromMe || properties.distFromMe === 0
      ? convertMetresToMiles(properties.distFromMe)
      : null

  return (
    <PopupContainer>
      <InfoContainer>
        {properties.myFeatures ? (
          <Text
            variant='action3'
            textColor={palette.primary.pink400}
            hideOverflow
          >
            {properties.pointType === 'source'
              ? 'My Departure Location'
              : 'My Destination'}
          </Text>
        ) : (
          <>
            <Text
              variant='action3'
              textColor={palette.primary.pink400}
              hideOverflow
            >
              {properties.name}
            </Text>
            {popupDetail('Email', properties.email, isMobile)}
            {distFromMe || distFromMe === 0
              ? popupDetail(
                  `Dist. from ${
                    properties.pointType === 'source' ? 'Me' : 'Destination'
                  }`,
                  `${distFromMe} mi`,
                  isMobile
                )
              : null}
            {popupDetail(
              'Role',
              properties.passenger ? 'Passenger' : 'Driver',
              isMobile
            )}
          </>
        )}
      </InfoContainer>
    </PopupContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolMapPopup.displayName = 'CarpoolMapPopup'
}

export default CarpoolMapPopup
