import React from 'react'
import styled from 'styled-components'
import LoadingBar from 'core-system/LoadingBar'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Container = styled.div`
  height: calc(100vh - ${(props) => props.theme.pxToRem(80)});
  position: sticky;
  top: 0;
  max-width: ${(props) => props.theme.pxToRem(272)};
  min-width: ${(props) => props.theme.pxToRem(272)};
  background-color: ${(props) => props.theme.palette.white};
  padding: ${(props) => props.theme.pxToRem(30)} 2rem;
  border-right: 0.0625rem solid ${(props) => props.theme.palette.grey.grey300};

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    position: fixed;
    top: unset;
    bottom: 0;
    padding: 0 1.5rem;
    height: ${(props) => props.theme.pxToRem(81)};
    max-width: 100vw;
    min-width: calc(100vw - 5rem);
    box-shadow: ${(props) => props.theme.dropShadows.bottom};
    border-right: unset;
    border-top: 0.0625rem solid ${(props) => props.theme.palette.grey.grey300};
    z-index: ${(props) => props.theme.zIndex.max};
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    min-width: 100vw;
  }

  ${flexbox}
  ${space}
  ${layout}
`

const Title = styled.div`
  ${(props) => props.theme.typography.h2}
  font-weight: 800;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    display: none;
  }
`

const SidebarItems = styled.div<{ hasTitle: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.hasTitle ? '2rem' : 0)};
  height: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    margin-top: unset;
    flex-direction: row;
  }
`

const LoadingTitle = styled(LoadingBar)`
  height: ${(props) => props.theme.pxToRem(56)};
  width: 100%;
  margin: 0;
`

const renderTitle = (hasTitle: boolean, title: string) => {
  if (hasTitle) {
    return title ? <Title>{title}</Title> : <LoadingTitle />
  }
  return null
}

interface SecondarySidebarViewProps
  extends React.HtmlHTMLAttributes<HTMLDivElement>,
    FlexboxProps,
    SpaceProps,
    LayoutProps {
  title?: string
  hasTitle?: boolean
}

const SecondarySidebarView = React.memo((props: SecondarySidebarViewProps) => {
  const { children, title, hasTitle = true } = props
  const ref = React.useRef(null)
  return (
    <Container ref={ref}>
      {renderTitle(hasTitle, title)}
      <SidebarItems hasTitle={hasTitle}>{children}</SidebarItems>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SecondarySidebarView.displayName = 'SecondarySidebarView'
}

export default SecondarySidebarView
