import { createSlice } from '@reduxjs/toolkit'
import { ChatTypes } from '../Chat/types'
import {
  arrangeChatsById,
  countNumberOfUnseenMessages,
} from './helperFunctions'

export interface conciergeAIState {
  chatRoom: string | null
  threadId: string | null
  chats: {
    [key: string]: ChatTypes
  }
  state: string
  numberOfUnseenMessages: number
  isGettingResponse: boolean
}

const INITIAL_STATE: conciergeAIState = {
  chatRoom: null,
  threadId: null,
  chats: {},
  state: 'initial',
  numberOfUnseenMessages: 0,
  isGettingResponse: false,
}

const conciergeAISlice = createSlice({
  name: 'conciergeAI',
  initialState: INITIAL_STATE,
  reducers: {
    changeTutorChatState: (state, action) => {
      state.state = action.payload
    },
    pushTutorChatMessages: (state, action) => {
      state.chats = {
        ...state.chats,
        ...arrangeChatsById(action.payload),
      }
      state.numberOfUnseenMessages = countNumberOfUnseenMessages(action.payload)
    },
    pushTutorChatMessage: (state, action) => {
      state.chats = {
        ...state.chats,
        [action.payload.id]: action.payload,
      }
      state.numberOfUnseenMessages = countNumberOfUnseenMessages(state.chats)
    },
    setChatRoom: (state, action) => {
      state.chatRoom = action.payload.chatRoom
      state.threadId = action.payload.threadId
    },
    setIsGettingResponse: (state, action) => {
      state.isGettingResponse = action.payload
    },
    setTypingAnimationComplete: (state, action) => {
      state.chats[action.payload].typingAnimation = false
    },
    setAllThinkingAnimationComplete: (state) => {
      state.chats = Object.keys(state.chats).reduce((acc, key) => {
        const chat = state.chats[key]
        if (chat.thinkingAnimation) {
          chat.thinkingAnimation = false
        }
        acc[key] = chat
        return acc
      }, {})
    },
    clearChats: (state) => {
      state.chats = {}
    },
  },
})

export const {
  changeTutorChatState,
  pushTutorChatMessages,
  pushTutorChatMessage,
  setChatRoom,
  setIsGettingResponse,
  setTypingAnimationComplete,
  setAllThinkingAnimationComplete,
  clearChats,
} = conciergeAISlice.actions
export const conciergeAIReducer = conciergeAISlice.reducer
