import React, { useState } from 'react'
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'core-system/Modal'
import Button from 'core-system/Button'
import { useDispatch } from 'react-redux'
import { optInActions } from 'redux/optin/optInSlice'
import styled from 'styled-components'
import TextField from 'core-system/TextField'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import CloseIcon from 'core-system/Icons/Actions/Close'
import AddIcon from 'core-system/Icons/Actions/Add'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import Dropdown from 'core-system/Dropdown'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import DayMonthYearInput from 'employee-platform/shared/components/DayMonthYearInput'

const DEFAULT_ERROR_STATE = {
  question: false,
  choices: false,
  currentChoice: false,
  startDate: false,
  endDate: false,
  segmentId: false,
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const SegmentDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const ChoicesFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`

const Choice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0;
  border-bottom: 1px solid ${palette.grey.grey300};
`

const CurrentChoice = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-start;
  width: 100%;
`

const getDefaultOptInDetails = (segmentId: string) => {
  return {
    startDate: '',
    endDate: {
      day: null,
      month: null,
      year: null,
    },
    question: '',
    choices: [],
    segment: segmentId,
  }
}

const convertDayMonthYearToDate = (dayMonthYear: {
  day: string | null
  month: string | null
  year: string | null
}) => {
  return dayMonthYear.day && dayMonthYear.month && dayMonthYear.year
    ? moment(
        `${dayMonthYear.year}-${dayMonthYear.month}-${dayMonthYear.day}`
      ).format()
    : null
}

interface AddOptInModalProps {
  open: boolean
  closeModal: () => void
  segmentId: string | null
  isMobile: boolean
}

const AddOptInModal = React.memo((props: AddOptInModalProps) => {
  const { open, closeModal, segmentId, isMobile } = props

  const today = moment()

  const dispatch = useDispatch()

  const [optInDetails, setOptInDetails] = useState(
    getDefaultOptInDetails(segmentId)
  )
  const [currentChoice, setCurrentChoice] = useState('')
  const [hasError, setHasError] = useState(DEFAULT_ERROR_STATE)

  const { allSegments } = useSelector((state: AppState) => state.employer)

  const segmentDropdownItems = allSegments
    ? allSegments.reduce((segments, segment) => {
        segments.push({
          id: segment.id,
          text: segment.name,
        })
        return segments
      }, [])
    : []

  const deleteChoice = (index: number) => {
    const newChoices = [...optInDetails.choices]
    newChoices.splice(index, 1)
    setOptInDetails({
      ...optInDetails,
      choices: newChoices,
    })
  }

  const addChoice = () => {
    if (currentChoice && currentChoice !== '') {
      setOptInDetails({
        ...optInDetails,
        choices: [...optInDetails.choices, currentChoice],
      })
      setCurrentChoice('')
      setHasError({
        ...hasError,
        choices: false,
        currentChoice: false,
      })
    } else {
      setHasError({
        ...hasError,
        currentChoice: true,
      })
    }
  }

  const handleCloseModal = () => {
    setOptInDetails(getDefaultOptInDetails(segmentId))
    setCurrentChoice('')
    setHasError(DEFAULT_ERROR_STATE)
    closeModal()
  }

  const handleAddOptIn = () => {
    if (
      optInDetails.question === '' ||
      optInDetails.choices.length === 0 ||
      optInDetails.segment === null ||
      moment(convertDayMonthYearToDate(optInDetails.endDate)).isBefore(today)
    ) {
      setHasError({
        ...hasError,
        question: optInDetails.question === '',
        choices: optInDetails.choices.length === 0,
        segmentId: optInDetails.segment === null,
        endDate: moment(
          convertDayMonthYearToDate(optInDetails.endDate)
        ).isBefore(today),
      })
    } else {
      dispatch(
        optInActions.createOptIn({
          ...optInDetails,
          startDate:
            optInDetails.startDate && optInDetails.startDate !== ''
              ? moment(optInDetails.startDate).format()
              : today.format(),
          endDate:
            optInDetails.endDate &&
            !convertDayMonthYearToDate(optInDetails.endDate)
              ? convertDayMonthYearToDate(optInDetails.endDate)
              : null,
        })
      )
      handleCloseModal()
    }
  }

  return (
    <Modal open={open} onClose={() => handleCloseModal()} width={pxToRem(450)}>
      <ModalHeader title='Add Opt-In' />
      <ModalBody>
        <FormContainer>
          <DayMonthYearInput
            date={optInDetails.endDate}
            setDate={(date) =>
              setOptInDetails({
                ...optInDetails,
                endDate: date,
              })
            }
            label='End Date'
            isMobile={isMobile}
            errorMessage={
              hasError.endDate
                ? 'Please enter a date that is not in the past'
                : null
            }
            futureDates
          />
          {!segmentId ? (
            <SegmentDropdownContainer>
              <Text variant='body1' textColor={palette.text.secondary}>
                Segment*
              </Text>
              <Dropdown
                placeholder='Select a segment...'
                active={optInDetails.segment}
                items={segmentDropdownItems}
                error={hasError.segmentId}
                itemCallback={(item) =>
                  setOptInDetails({
                    ...optInDetails,
                    segment: item.id,
                  })
                }
                width='100%'
              />
            </SegmentDropdownContainer>
          ) : null}
          <TextField
            label='Question*'
            type='text'
            value={optInDetails.question}
            placeholder='Enter a question...'
            onChange={(e) =>
              setOptInDetails({
                ...optInDetails,
                question: e.currentTarget.value,
              })
            }
            invalid={hasError.question}
            helpText='Please enter a question'
            data-cy='optin-question-input'
          />
          <ChoicesFieldContainer>
            <Text variant='body1' textColor={palette.text.secondary}>
              Options*
            </Text>
            <ChoicesContainer>
              {optInDetails.choices.map((choice, index) => {
                return (
                  <Choice key={index}>
                    <Button
                      variant='tertiary'
                      size='medium'
                      iconLeft={<CloseIcon />}
                      onClick={() => deleteChoice(index)}
                    />
                    <Text variant='action2'>{choice}</Text>
                  </Choice>
                )
              })}
              <CurrentChoice
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && currentChoice !== '') {
                    addChoice()
                  }
                }}
              >
                <TextField
                  type='text'
                  value={currentChoice}
                  placeholder='Enter an option...'
                  onChange={(e) => setCurrentChoice(e.currentTarget.value)}
                  invalid={hasError.currentChoice}
                  helpText='Please enter a valid option'
                  data-cy='optin-choice-input'
                  width={isMobile ? '12.5rem' : '19rem'}
                />
                <Button
                  variant='tertiary'
                  iconLeft={<AddIcon />}
                  onClick={() => addChoice()}
                />
              </CurrentChoice>
            </ChoicesContainer>
            {hasError.choices ? (
              <Text variant='body2' textColor={palette.error.red500}>
                Please add at least one option
              </Text>
            ) : null}
          </ChoicesFieldContainer>
        </FormContainer>
      </ModalBody>
      <ModalFooter>
        <Button variant='tertiary' onClick={() => handleCloseModal()}>
          Cancel
        </Button>
        <Button onClick={() => handleAddOptIn()} style={{ marginLeft: '1rem' }}>
          Add Opt-In
        </Button>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AddOptInModal.displayName = 'AddOptInModal'
}

export default AddOptInModal
