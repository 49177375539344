import Card, { CardRow, CardStatus } from 'core-system/Card'
import Chip from 'core-system/Chip'
import Dropdown, { SelectItemProps } from 'core-system/Dropdown'
import OverflowIcon from 'core-system/Icons/Misc/Overflow'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Loading from 'core-system/Loading'
import Table, {
  TableHeader,
  TableHeaderItem,
  TableLabelRow,
  TableRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Employee } from 'redux/employee/employeeTypes'
import { Worksite } from 'redux/employer/employerTypes'
import useResizeListener from 'shared/Hooks/useResizeListener'
import PlatformSvgUtils from 'shared/PlatformSvgUtils'
import styled from 'styled-components'
import UsersInfoModal from './UsersInfoModal'
import { employeeActions } from 'redux/employee/employeeSlice'
import MakeOwnerModal from './MakeOwnerModal'
import { getRoleCardConfig, getRoleChipConfig } from '../AccountsUtils'

const ProfileIcon = styled.div<{ src: string }>`
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  background-color: ${(props) => props.theme.palette.grey.grey200};
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
`

const HeaderSpan = styled.span<{ textColor: string }>`
  color: ${(props) => props.textColor};
  margin-left: 0.5rem;
`

const UserContainer = styled.div`
  display: flex;
  align-items: center;
`

const UserSvg = styled.div`
  margin-right: 1.5rem;
  height: ${(props) => props.theme.pxToRem(40)};
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const Alias = styled.div`
  ${(props) => props.theme.typography.action4}
  color: ${(props) => props.theme.palette.text.primary};
`

const UserAddress = styled.div`
  ${(props) => props.theme.typography.body2}
  color: ${(props) => props.theme.palette.text.placeholder};
`

const UserRowCell = styled(TableRowCell)`
  min-width: ${(props) => props.theme.pxToRem(235)};
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-bottom: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey300};
    margin-right: 0;
  }
`

const RoleRowCell = styled(TableRowCell)`
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    position: absolute;
    right: 0;
    top: ${(props) => props.theme.pxToRem(35)};
    margin-right: 0.5rem;
  }
`

const LocationRowCell = styled(TableRowCell)`
  min-width: ${(props) => props.theme.pxToRem(150)};
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    min-width: unset;
  }
`

const getHeaderColor = (isDisabled: boolean, isActive: boolean) => {
  if (isDisabled) {
    return palette.text.placeholder
  } else if (isActive) {
    return palette.white
  } else {
    return palette.primary.pink400
  }
}

const getTableHeaders = (
  allWorksites: Worksite[],
  allEmployees: Employee[],
  activeHeader: string,
  updateActiveHeader: (id: string) => void
) => {
  const combinedWorksites = {
    id: 'all',
    alias: 'all',
    address: '',
    employeeCount: allEmployees.length,
    formattedAddress: '',
    city: '',
    iconId: null,
  }

  const usersWithoutWorksite = allEmployees.find(
    (emp: Employee) => !emp.worksiteId || emp.worksiteId === 'no-worksite'
  )

  let worksiteHeaders = []
  if (!usersWithoutWorksite) {
    const filteredHeaders = allWorksites.filter(
      (worksite: Worksite) => worksite.id !== 'no-worksite'
    )
    worksiteHeaders = [combinedWorksites, ...filteredHeaders]
  } else {
    worksiteHeaders = [combinedWorksites, ...allWorksites]
  }

  return worksiteHeaders.map((worksite: Worksite, idx: number) => {
    const employeeCount = allEmployees.filter(
      (emp: Employee) => emp.worksiteId === worksite.id
    ).length
    const isDisabled = employeeCount === 0 && worksite.id !== 'all'
    return (
      <TableHeaderItem
        onClick={() => updateActiveHeader(worksite.id)}
        key={idx}
        active={activeHeader === worksite.id}
        disabled={isDisabled}
      >
        {worksite.alias}
        <HeaderSpan
          textColor={getHeaderColor(isDisabled, activeHeader === worksite.id)}
        >
          {worksite.id === 'all' ? worksite.employeeCount : employeeCount}
        </HeaderSpan>
      </TableHeaderItem>
    )
  })
}

const getTableRows = (
  allEmployees: Employee[],
  allWorksites: Worksite[],
  activeHeader: string,
  menuCallback: (item: SelectItemProps) => void,
  isTablet: boolean,
  primaryAccountHolderId: string
) => {
  return allEmployees
    .filter((a) => activeHeader === 'all' || a.worksiteId === activeHeader)
    .map((employee: Employee, idx: number) => {
      const empCopy = { ...employee }
      if (!empCopy.worksiteId) {
        empCopy.worksiteId = 'no-worksite'
      }
      const isAdmin = empCopy.role === 'HRadmins'
      const isPrimaryAccountHolder = empCopy.id === primaryAccountHolderId

      const { roleChipVariant, roleChipLabel } = getRoleChipConfig(
        isAdmin,
        isPrimaryAccountHolder
      )

      const worksiteName = allWorksites.filter(
        (wrk: Worksite) => wrk.id === empCopy.worksiteId
      )[0].alias
      const selectItems = [
        {
          id: '0',
          label: 'editInfo',
          text: 'Edit Commuter Info',
          employee: empCopy,
        },
        {
          id: '1',
          label: 'resendInvite',
          text: 'Resend Invite',
          employee: empCopy,
          disabled: empCopy.hasRegistered !== false, // ID is undefined before client reloads
        },
        {
          id: '2',
          label: 'makeAccountOwner',
          text: 'Make Account Owner',
          employee: empCopy,
          disabled: isPrimaryAccountHolder,
        },
      ]

      return (
        <TableRow key={idx} data-cy='accounts-user-table-row'>
          <TableRowContents
            gridTemplateColumns={'1fr 1fr 3rem'}
            gridTemplateRows={'1fr 3rem'}
            data-cy={`accounts-user-table-row-${empCopy.firstName}${empCopy.lastName}`}
          >
            <UserRowCell gridColumn={'1/4'} label={isTablet && 'Employee'}>
              <UserContainer>
                <UserSvg>
                  <PlatformSvg
                    folder='users'
                    variant='userBasic'
                    width={40}
                    height={40}
                  />
                </UserSvg>
                <UserInfo>
                  <Alias>{`${empCopy.firstName} ${empCopy.lastName}`}</Alias>
                  <UserAddress>{empCopy.email}</UserAddress>
                </UserInfo>
              </UserContainer>
            </UserRowCell>
            <RoleRowCell flex={'0.5'} minWidth={pxToRem(80)}>
              <Chip variant={roleChipVariant}>{roleChipLabel}</Chip>
            </RoleRowCell>
            <LocationRowCell
              icon={worksiteName ? 'location' : null}
              label={isTablet && worksiteName && 'Worksite'}
            >
              {worksiteName}
            </LocationRowCell>
            <TableRowCell
              gridColumn={'3/4'}
              equalSize={false}
              marginRight={'0.5rem'}
            >
              <Dropdown
                variant={'textual'}
                svgDropdown={true}
                icon={<OverflowIcon />}
                active={null}
                items={selectItems}
                itemCallback={menuCallback}
                width={pxToRem(180)}
                top={'2.75rem'}
                right={0}
                data-cy='accounts-user-table-dropdown'
              />
            </TableRowCell>
          </TableRowContents>
        </TableRow>
      )
    })
}

const getCards = (
  allEmployees: Employee[],
  allWorksites: Worksite[],
  activeHeader: string,
  menuCallback: (item: SelectItemProps) => void,
  primaryAccountHolderId: string
) => {
  return allEmployees
    .filter((a) => activeHeader === 'all' || a.worksiteId === activeHeader)
    .map((employee: Employee, idx: number) => {
      const isAdmin = employee.role === 'HRadmins'
      const isPrimaryAccountHolder = employee.id === primaryAccountHolderId
      const worksiteName =
        employee.worksiteId &&
        allWorksites.filter(
          (wrk: Worksite) => wrk.id === employee.worksiteId
        )[0].alias
      const callbackItem = {
        id: '0',
        text: 'activeEmployee',
        employee: employee,
      }

      const { roleCardStatusVariant, roleCardLabel } = getRoleCardConfig(
        isAdmin,
        isPrimaryAccountHolder
      )

      return (
        <Card key={idx} onClick={() => menuCallback(callbackItem)}>
          <CardRow label={'Commuter'}>
            <UserContainer>
              <UserSvg>
                {employee.avatarUrl ? (
                  <ProfileIcon src={employee.avatarUrl} />
                ) : (
                  PlatformSvgUtils.getRandomUserSvg(40)
                )}
              </UserSvg>
              <UserInfo>
                <Alias>{`${employee.firstName} ${employee.lastName}`}</Alias>
                <UserAddress>{employee.email}</UserAddress>
              </UserInfo>
            </UserContainer>
          </CardRow>
          <CardRow icon={'location'} label={'Worksite'}>
            {worksiteName}
          </CardRow>
          <CardStatus status={roleCardStatusVariant}>
            {roleCardLabel}
          </CardStatus>
        </Card>
      )
    })
}

interface UsersTableProps {
  allWorksites: Worksite[] | null
  allEmployees: Employee[] | null
  isAdmin: boolean
}

const UsersTable = React.memo((props: UsersTableProps) => {
  const dispatch = useDispatch()
  const { allEmployees, allWorksites, isAdmin } = props

  const { profile } = useSelector((state: AppState) => state.user)
  const primaryAccountHolderId = useSelector(
    (state: AppState) => state.employer.profile.primaryAccountHolderId
  )

  const [modalOpen, setModalOpen] = useState(false)
  const [makeOwnerModalOpen, setMakeOwnerModalOpen] = useState(false)
  const [clickedEmployee, setClickedEmployee] = useState<Employee>(null)
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 768)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576)
  const [activeHeader, setActiveHeader] = useState('all')

  const handleResize = useCallback(
    (width: number) => {
      setIsTablet(width <= 768)
      setIsMobile(width <= 576)
    },
    [setIsTablet, setIsMobile]
  )

  useResizeListener(() => handleResize(window.innerWidth), [])

  const handleHeaderClick = (filter: string) => {
    if (filter !== activeHeader) {
      setActiveHeader(filter)
      const worksiteAlias =
        filter === 'all'
          ? 'all'
          : allWorksites.filter((wrk: Worksite) => wrk.id === filter)[0].alias
      SegmentService.track('table-view-filter', {
        tableTable: 'employees',
        location: worksiteAlias,
      })
    }
  }

  const menuCallback = (item: SelectItemProps) => {
    const emp: Employee = item.employee
    setClickedEmployee(emp)

    if (item.label === 'editInfo') {
      setModalOpen(true)
    } else if (item.label === 'resendInvite') {
      dispatch(employeeActions.resendEmployeeInvite(emp.id))
    } else if (item.label === 'makeAccountOwner') {
      setMakeOwnerModalOpen(true)
    }

    if (item.employee.email === profile.email) {
      SegmentService.track('userInfo-modal-click', {
        action: 'open',
      })
    } else {
      SegmentService.track('table-item-click', {
        tableType: 'employees',
        action: 'open',
        role: item.employee && item.employee.role,
        name:
          item.employee &&
          `${item.employee.firstName} ${item.employee.lastName}`,
      })
    }
  }

  const closeUserModal = useCallback(() => {
    setModalOpen(false)

    if (clickedEmployee.email === profile.email) {
      SegmentService.track('userInfo-modal-click', {
        action: 'close',
      })
    } else {
      SegmentService.track('table-item-click', {
        tableType: 'employees',
        action: 'close',
      })
    }

    setClickedEmployee(null)
  }, [clickedEmployee, profile])

  const closeMakeOwnerModal = useCallback(() => {
    setMakeOwnerModalOpen(false)

    if (clickedEmployee.email === profile.email) {
      SegmentService.track('makeOwner-modal-click', {
        action: 'close',
      })
    } else {
      SegmentService.track('table-item-click', {
        tableType: 'employees',
        action: 'close',
      })
    }

    setClickedEmployee(null)
  }, [clickedEmployee, profile])

  if (!allWorksites || !allEmployees) {
    return <Loading marginTop={'0.75rem'} />
  }

  return (
    <>
      {!isMobile ? (
        <Table>
          <TableHeader>
            {getTableHeaders(
              allWorksites,
              allEmployees,
              activeHeader,
              handleHeaderClick
            )}
          </TableHeader>
          <TableLabelRow>
            <TableRowCell minWidth={pxToRem(235)} fontType={'body2'}>
              Commuter
            </TableRowCell>
            <TableRowCell
              flex={'0.5'}
              minWidth={pxToRem(80)}
              fontType={'body2'}
            >
              Role
            </TableRowCell>
            <TableRowCell minWidth={pxToRem(150)} fontType={'body2'}>
              Worksite
            </TableRowCell>
            <TableRowCell
              equalSize={false}
              width={'2.5rem'}
              marginRight={'0.5rem'}
            />
          </TableLabelRow>
          {getTableRows(
            allEmployees,
            allWorksites,
            activeHeader,
            menuCallback,
            isTablet,
            primaryAccountHolderId
          )}
        </Table>
      ) : (
        <>
          <TableHeader>
            {getTableHeaders(
              allWorksites,
              allEmployees,
              activeHeader,
              handleHeaderClick
            )}
          </TableHeader>
          {getCards(
            allEmployees,
            allWorksites,
            activeHeader,
            menuCallback,
            primaryAccountHolderId
          )}
        </>
      )}
      {clickedEmployee && allWorksites && (
        <UsersInfoModal
          closeModal={closeUserModal}
          open={modalOpen}
          user={clickedEmployee}
          allWorksites={allWorksites}
          isAdmin={isAdmin}
          isAuthUser={profile.email === clickedEmployee.email}
        />
      )}
      {clickedEmployee && (
        <MakeOwnerModal
          closeModal={closeMakeOwnerModal}
          open={makeOwnerModalOpen}
          user={clickedEmployee}
        />
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  UsersTable.displayName = 'UsersTable'
}

export default UsersTable
