import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import styled, { css } from 'styled-components'
import Loading from 'core-system/Loading'
import { daysOfWeek } from 'employee-platform/shared/utils'
import { Trip } from 'redux/employeePlatform/employeePlatformTypes'
import moment from 'moment'
import palette from 'core-system/Themes/palette'
import Text from 'core-system/Text'

const DATE_FORMAT = {
  month: 'YYYY-MM',
  date: 'YYYY-MM-DD',
}

const CalendarBody = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: ${(props) => props.theme.pxToRem(props.isMobile ? 80 : 119)};
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey300};
  border-left: 1px solid ${(props) => props.theme.palette.grey.grey300};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border-bottom-right-radius: 0.9375rem;
  border-bottom-left-radius: 0.9375rem;
`

const CalendarHeader = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: ${(props) => (props.isMobile ? '2.5rem' : '4rem')};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 0.9375rem;
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey300};
  border-left: 1px solid ${(props) => props.theme.palette.grey.grey300};
`

const CalendarHeaderDay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.primary.pink600};
  border-right: 1px solid ${(props) => props.theme.palette.grey.grey300};
  overflow: hidden;

  &:first-of-type {
    border-top-left-radius: 0.9375rem;
  }

  &:last-of-type {
    border-top-right-radius: 0.9375rem;
  }
`

const CalendarDay = styled.div<{
  isMobile: boolean
  isDisabled: boolean
  isToday: boolean
  isActive: boolean
  isFuture: boolean
}>`
  padding: ${(props) => (props.isMobile ? '0.2rem' : '0.375rem 0.5rem')};
  border-right: 1px solid ${(props) => props.theme.palette.grey.grey300};
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey300};
  background-color: ${(props) => props.theme.palette.white};
  cursor: pointer;

  &:hover {
    .dayContent {
      border: 1px solid ${(props) => props.theme.palette.primary.pink300};
      background-color: ${(props) => props.theme.palette.primary.pink400};
    }
  }

  &:nth-of-type(7n),
  &:nth-of-type(7n + 1) {
    background-color: ${(props) => props.theme.palette.grey.grey100};
    /* pointer-events: none;

        .dayText {
            color: ${(props) => props.theme.palette.text.disabled};
        }

        .trips {
            visibility: hidden;
        } */
  }

  &:last-of-type {
    border-bottom-right-radius: 0.9375rem;
  }

  &:nth-last-child(7) {
    border-bottom-left-radius: 0.9375rem;
  }

  ${(props) =>
    props.isDisabled &&
    css`
      background-color: ${(props) => props.theme.palette.grey.grey100};
      pointer-events: none;
    `}

  ${(props) =>
    props.isFuture &&
    css`
      pointer-events: none;
    `}

    ${(props) =>
    props.isToday &&
    css`
      .dayText {
        color: ${(props) => props.theme.palette.primary.pink400};
      }
    `}
    
    ${(props) =>
    props.isActive &&
    css`
      .dayContent {
        border: 1px solid ${(props) => props.theme.palette.primary.pink300};
        background-color: ${(props) => props.theme.palette.primary.pink400};
      }
    `}
`

const Content = styled.div<{ isMobile: boolean }>`
  border-radius: 0.25rem;
  padding: ${(props) => (props.isMobile ? '0.2rem' : '0.375rem 0.5rem')};
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
`

const TripsCountContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.isMobile ? '2rem' : '3.75rem')};
`

const renderCalendarBody = (
  isMobile: boolean,
  month: string,
  selectedDay: number,
  setSelectedDay: (day: number) => void,
  trips: { [key: string]: Trip[] }
) => {
  const firstDayOfMonth = moment(month).startOf('month')
  const daysInMonth = firstDayOfMonth.daysInMonth()
  const firstDayNumber = firstDayOfMonth.weekday()
  const days = firstDayNumber + daysInMonth
  const totalDays = days + (days % 7 === 0 ? 0 : 7 - (days % 7))

  return [...Array(totalDays)].map((_day, idx) => {
    const dayNumber = idx - firstDayNumber + 1
    const isDisabled = idx < firstDayNumber || idx + 1 > days
    const recordedTrips = trips
      ? trips[Number(moment(month).date(dayNumber).format('YYYYMMDD'))] || []
      : []

    const day = `${month}-${dayNumber < 10 ? `0${dayNumber}` : dayNumber}`
    const today = moment(moment().format(DATE_FORMAT.date))
    const isToday = !isDisabled && today.isSame(day)
    const isFuture = today.isBefore(day)
    const isActive = selectedDay && selectedDay === dayNumber

    return (
      <CalendarDay
        isMobile={isMobile}
        isDisabled={isDisabled}
        isToday={isToday}
        isActive={!isDisabled && isActive}
        isFuture={isFuture}
        key={idx}
        onClick={() => setSelectedDay(dayNumber)}
      >
        {!isDisabled ? (
          <Content isMobile={isMobile} className='dayContent'>
            <Text
              variant={isMobile ? 'body2' : 'action3'}
              textColor={palette.text.secondary}
              className='dayText'
            >
              {dayNumber}
            </Text>
            {!isFuture ? (
              <TripsCountContainer isMobile={isMobile}>
                <Text variant={isMobile ? 'action1' : 'h3'}>
                  {recordedTrips ? recordedTrips.length : 0}
                </Text>
                <Text
                  variant={isMobile ? 'body2' : 'body1'}
                  textColor={palette.text.placeholder}
                >
                  {`TRIP${
                    recordedTrips && recordedTrips.length !== 1 ? 'S' : ''
                  }`}
                </Text>
              </TripsCountContainer>
            ) : null}
          </Content>
        ) : null}
      </CalendarDay>
    )
  })
}

interface EmployeeTripsCalendarProps {
  isMobile: boolean
  month: string
  selectedDay: number
  setSelectedDay: (day: number) => void
}

const EmployeeTripsCalendar = React.memo(
  (props: EmployeeTripsCalendarProps) => {
    const { isMobile, month, selectedDay, setSelectedDay } = props

    const { currentMonthTrips } = useSelector(
      (state: AppState) => state.employeePlatform
    )

    if (!currentMonthTrips) {
      return <Loading />
    }

    return (
      <>
        <CalendarHeader isMobile={isMobile}>
          {daysOfWeek.map((day: string) => (
            <CalendarHeaderDay key={day}>
              {isMobile ? day[0] : day}
            </CalendarHeaderDay>
          ))}
        </CalendarHeader>
        <CalendarBody isMobile={isMobile}>
          {renderCalendarBody(
            isMobile,
            month,
            selectedDay,
            setSelectedDay,
            currentMonthTrips[Number(moment(month).format('YYYYMM'))]?.trips
          )}
        </CalendarBody>
      </>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployeeTripsCalendar.displayName = 'EmployeeTripsCalendar'
}

export default EmployeeTripsCalendar
