import pxToRem from 'core-system/utils/pxToRem'
import React, { useState, useEffect } from 'react'
import { Segment } from 'redux/employer/employerTypes'
import styled from 'styled-components'
import UploadDataComponentV2 from 'features/Segments/components/UploadDataComponentV2'
import { OnboardingStep } from './GettingStartedView'
import palette from 'core-system/Themes/palette'
import Divider from 'core-system/Divider'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 10rem;
`

const DropArea = styled.div<{ mouseOver: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-flow: column;
  width: 100%;
  height: 70%;
  border-radius: 1.5rem;
  border: ${pxToRem(1)} dashed ${palette.grey.grey400};
  padding: 2.5rem 1.5rem;
`

const CsvUploadContainer = styled.div`
  margin: ${pxToRem(150)} 0 ${pxToRem(112)};
  padding: 0 ${pxToRem(60)};
`

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
`

const BulletPointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`

const BulletPointsTitle = styled.div`
  color: ${(props) => props.theme.palette.black};
  font-size: ${pxToRem(20)};
  margin-bottom: 1rem;
  font-family: 'PolySans';
  font-weight: 500;
`

const BulletPointsSubtitle = styled.div`
  color: ${(props) => props.theme.palette.black};
  font-size: ${pxToRem(16)};
  font-family: 'PolySans';
`

const BulletPoint = styled.div`
  background-color: ${palette.primary.pink800};
  color: ${palette.primary.pink500};
  font-family: 'PolySans';
  font-weight: 500;
  padding: ${pxToRem(4)} ${pxToRem(12)};
  font-size: ${pxToRem(16)};
  border-radius: ${pxToRem(4)};
`
const BulletPointsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
  gap: ${pxToRem(16)};
`

interface OnboardingUploadCsvProps {
  segment?: Segment
  setOnboardingStep: (step: OnboardingStep) => void
}

const OnboardingUploadCsv = React.memo((props: OnboardingUploadCsvProps) => {
  const { segment, setOnboardingStep } = props

  // 0 ready to upload, 1=loading, 2=Success -1=error
  const [fileAdded, setFileAdded] = useState<number>(0)
  const [response, setResponse] = useState<string>('')

  useEffect(() => {
    setFileAdded(0)
  }, [])

  useEffect(() => {
    if (fileAdded === 2) {
      setTimeout(() => {
        setOnboardingStep('addPayment')
        setFileAdded(0)
      }, 1000)
    }
  }, [fileAdded, setFileAdded, setOnboardingStep])

  return (
    <Container>
      <CsvUploadContainer>
        <UploadDataComponentV2
          segment={segment}
          fileAdded={fileAdded}
          setFileAdded={setFileAdded}
          response={response}
          setResponse={setResponse}
          allowReupload={false}
          DropArea={DropArea}
        />
        <NotesContainer>
          <BulletPointsTitle>
            Confused by the template? Don&apos;t sweat it! Here&apos;s what you
            need to know:
          </BulletPointsTitle>
          <BulletPointsContainer>
            <BulletPointsSubtitle>
              These fields are required to kick things off:
            </BulletPointsSubtitle>
            <BulletPointsRow>
              <BulletPoint>Worksite Address</BulletPoint>
              <BulletPoint>First Name</BulletPoint>
              <BulletPoint>Last Name</BulletPoint>
              <BulletPoint>Email</BulletPoint>
            </BulletPointsRow>
          </BulletPointsContainer>
          <Divider />
          <BulletPointsContainer>
            <BulletPointsSubtitle>
              These fields are optional:
            </BulletPointsSubtitle>
            <BulletPointsRow>
              <BulletPoint>Home Address</BulletPoint>
              <BulletPoint>ID</BulletPoint>
            </BulletPointsRow>
          </BulletPointsContainer>
        </NotesContainer>
      </CsvUploadContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OnboardingUploadCsv.displayName = 'OnboardingUploadCsv'
}

export default OnboardingUploadCsv
