import { GOOGLE_MAPS_API_KEY } from 'redux/config/services/Constants'

const BASE_ROUTES_URL = 'https://routes.googleapis.com'

export const computeRoute = (
  coordinates: { latitude: number; longitude: number }[]
): Promise<Response> => {
  const url = `${BASE_ROUTES_URL}/directions/v2:computeRoutes`

  const requestBody = {
    origin: {
      location: {
        latLng: {
          latitude: coordinates[0].latitude,
          longitude: coordinates[0].longitude,
        },
      },
    },
    destination: {
      location: {
        latLng: {
          latitude: coordinates[1].latitude,
          longitude: coordinates[1].longitude,
        },
      },
    },
    travelMode: 'TRANSIT',
    computeAlternativeRoutes: true,
  }

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': GOOGLE_MAPS_API_KEY,
      'X-Goog-FieldMask':
        'routes.duration,routes.polyline,routes.routeLabels,routes.distanceMeters,routes.legs',
    },
    body: JSON.stringify(requestBody),
  })
}
