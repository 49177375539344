import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PayrollProvider } from './finchTypes'

export interface FinchState {
  payrollProviders: PayrollProvider[]
  loading: boolean
  error: string | null
}

const initialState: FinchState = {
  payrollProviders: [],
  loading: false,
  error: null,
}

const finchSlice = createSlice({
  name: 'finchProviders',
  initialState,
  reducers: {
    fetchRequest: (state) => {
      state.loading = true
      state.error = null
    },
    fetchSuccess: (state, action: PayloadAction<PayrollProvider[]>) => {
      state.loading = false
      state.payrollProviders = action.payload
    },
    fetchFailure: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const finchProviders = finchSlice.reducer
export const finchActions = {
  ...finchSlice.actions,
}
