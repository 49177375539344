import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { conciergeActions } from 'redux/concierge/conciergeSlice'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Worksite } from 'redux/employer/employerTypes'
import styled from 'styled-components'
import AnnouncementsTable from './Announcements/AnnouncementsTable'
import ConciergeHeader from './ConciergeHeader'
import FaqTable from './Faq/FaqTable'
import { useNavigate } from 'react-router-dom'

const locationMap = {
  announcement: 1,
  faq: 2,
  '/concierge/faq': 2,
  '/concierge/announcement': 1,
}

const metricsList = ['announcement', 'faq']

const ContentWrapper = styled.div`
  margin-top: 2rem;
`

const Container = styled.div`
  margin: 2rem;
`

const renderContent = (
  activeId: number,
  allWorksites: Worksite[],
  allFaqs: any
) => {
  switch (activeId) {
    case 1:
      return <AnnouncementsTable />
    case 2:
      return <FaqTable allFaqs={allFaqs} allWorksites={allWorksites} />
    default:
      return null
  }
}

type LocationState = {
  location: string
  from: string
}

const ConciergeView = React.memo(() => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const state = location.state as LocationState

  const allWorksites = useSelector(
    (state: AppState) => state.employer.worksites
  )
  const allAnnouncements = useSelector(
    (state: AppState) => state.concierge.announcements
  )
  const allFaqs = useSelector((state: AppState) => state.concierge.faqs)

  const [activeId, setActiveId] = useState(
    state && state.from ? locationMap[state.from] : 1
  )

  useEffect(() => {
    if (!allAnnouncements) {
      dispatch(conciergeActions.getAnnouncements())
    }
  }, [allAnnouncements])

  useEffect(() => {
    if (!allFaqs) {
      dispatch(conciergeActions.getFaqs())
    }
  }, [allFaqs])

  const handleAnnouncementClick = useCallback(() => {
    navigate(`/concierge/announcement`, {
      state: { from: location.pathname },
    })
    SegmentService.track('concierge-actionBtn-click', {
      feature: 'announcements',
    })
  }, [navigate, location.pathname])

  const handleHeaderButtonClick = useCallback(
    (id: number) => {
      if (activeId !== id) {
        setActiveId(id)
      }

      SegmentService.track('concierge-view-select', {
        view: metricsList[id - 1],
      })
    },
    [activeId]
  )

  return (
    <Container>
      <ConciergeHeader
        activeId={activeId}
        handleClick={handleHeaderButtonClick}
        addNewAnnouncement={handleAnnouncementClick}
        buttonText={activeId === 1 ? 'New Announcement' : 'New FAQ'}
      />
      <ContentWrapper>
        {renderContent(activeId, allWorksites, allFaqs)}
      </ContentWrapper>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ConciergeView.displayName = 'ConciergeView'
}

export default ConciergeView
