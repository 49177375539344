import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import Dropdown from 'core-system/Dropdown'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import UserService from 'redux/user/userService'
import styled from 'styled-components'
import { signUpCopy } from './shared/LoginSignUpUtils'
import PasswordRequirementChecker from './components/PasswordRequirementChecker'
import FleetLogoFullColor from 'core-system/FleetLogos/FleetLogoFullColor'
import TextFieldV2 from 'core-system/TextFieldV2'
import AddressAutocompleteV2, {
  Result,
} from 'core-system/Map/AddressAutocompleteV2/AddressAutocomplete'
import DropdownV2 from 'core-system/Dropdown/DropdownV2'
import LoginSignUpGraphicV2, {
  ImageLabel,
} from './components/LoginSignUpGraphicV2'

const modes = [
  { id: '1', text: 'Car' },
  { id: '2', text: 'Transit' },
  { id: '3', text: 'Bike' },
  { id: '4', text: 'Scooter' },
  { id: '5', text: 'Moped' },
  { id: '6', text: 'Carpool' },
  { id: '7', text: 'Multimodal' },
  { id: '8', text: 'Other' },
]

const modesMap = {
  1: 'CAR',
  2: 'TRANSIT',
  3: 'BIKE',
  4: 'SCOOTER',
  5: 'MOPED',
  6: 'CARPOOL',
  7: 'MULTIMODAL',
  8: 'OTHER',
}

const Container = styled.div`
  display: flex;
  height: 100%;
  color: ${palette.black} a {
    text-decoration: none;
    &:hover {
      color: ${(props) => props.theme.palette.text.primary};
      text-decoration: underline;
    }
  }
`

const LogoContainer = styled.div`
  position: absolute;
  top: 3rem;
`

const FormContainer = styled.div`
  width: 48%;
  padding: 7rem 4rem 3rem;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 100%;
  }
`

const HelpText = styled.div<{ error?: boolean }>`
  max-width: ${pxToRem(425)};
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  transition: all 0.5s ease-in-out;
  background-color: ${(props) =>
    !props.error
      ? props.theme.palette.success.green700
      : props.theme.palette.error.red600};

  > div {
    color: ${(props) =>
      !props.error
        ? props.theme.palette.success.green500
        : props.theme.palette.error.red500};
  }
`

const InputContainer = styled.div<{ inline?: boolean }>`
  margin-bottom: 0.5rem;
  max-width: ${pxToRem(425)};
  border-radius: ${pxToRem('4')};
  ${(props) =>
    props.inline
      ? {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '1rem',
          '> div': {
            flex: 1,
          },
        }
      : null}
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    display: block;
    > div {
      max-width: 100%;
      margin-bottom: 1.5rem;
    }
  }
`

const StyledAndroidContainer = styled.a`
  margin-left: ${(props) => props.theme.pxToRem(-13)};
  width: unset !important;

  img {
    width: ${(props) => props.theme.pxToRem(168)};
  }
`

const StyledAppleContainer = styled.a`
  width: unset !important;
`

const StyledAppleImage = styled.img`
  border-radius: ${(props) => props.theme.pxToRem(13)};
  width: ${(props) => props.theme.pxToRem(151)};
  padding: 0.625rem;
`

const getDefaultState = (inviteId: string) => {
  return {
    inviteCode: inviteId || '',
    firstName: '',
    lastName: '',
    email: '',
    password1: '',
    password2: '',
    address: '',
    employeeCorporateId: '',
    unitNumber: '',
    referredBy: '',
  }
}

const getDropdownWorksites = (worksiteName: string) => {
  return [
    { id: '1', text: worksiteName.replaceAll('%', ' ') },
    { id: '2', text: 'Other' },
  ]
}

const EmployeeSignUpView = () => {
  const search = useLocation().search
  const worksiteName = new URLSearchParams(search).get('name')
  const inviteId = new URLSearchParams(search).get('id')

  // Required Fields
  const [requiredFieldsMissing, setRequiredFieldsMissing] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password1: false,
    password2: false,
    inviteCode: false,
    employeeCorporateId: false,
    address: false,
  })

  const [employeeDetails, setEmployeeDetails] = useState(
    getDefaultState(inviteId)
  )
  const [selectedMode, setSelectedMode] = useState('1')
  const [selectedWorksite, setSelectedWorksite] = useState('1')
  const [showHelpText, setShowHelpText] = useState({
    error: false,
    msg: null,
  })
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  // const [isTablet, setIsTablet] = React.useState(window.innerWidth <= 768)

  // useResizeListener(() => setIsTablet(window.innerWidth <= 768), [])

  const onValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setEmployeeDetails({
      ...employeeDetails,
      [name]: value,
    })
  }

  const onAddressChange = (location: Result) => {
    setEmployeeDetails({
      ...employeeDetails,
      address: location.address,
    })
  }

  const generateEmployeeId = useCallback(() => {
    if (employeeDetails?.email) {
      const generatedId = employeeDetails.email.split('@')[0]
      setEmployeeDetails({
        ...employeeDetails,
        employeeCorporateId: generatedId,
      })
    }
  }, [employeeDetails])

  const checkMissingFields = useCallback(() => {
    setRequiredFieldsMissing({
      firstName: !employeeDetails.firstName ? true : false,
      lastName: !employeeDetails.lastName ? true : false,
      email: !employeeDetails.email ? true : false,
      password1: !employeeDetails.password1 ? true : false,
      password2: !employeeDetails.password2 ? true : false,
      inviteCode: !employeeDetails.inviteCode ? true : false,
      employeeCorporateId: !employeeDetails.employeeCorporateId ? true : false,
      address: !employeeDetails.address ? true : false,
    })
  }, [employeeDetails])

  const handleSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      const validEmployeeInfo =
        employeeDetails &&
        Object.keys(employeeDetails).every(
          (k) =>
            (employeeDetails[k].trim() !== '' && employeeDetails[k] !== null) ||
            k === 'unitNumber' ||
            k === 'referredBy'
        )

      if (!validEmployeeInfo) {
        checkMissingFields()

        setShowHelpText({
          error: true,
          msg: 'Please fill in missing fields.',
        })
      } else if (selectedWorksite === '2') {
        setShowHelpText({
          error: true,
          msg: 'Thanks for registering. Looks like your worksite is not yet active on Fleet. We will get back in touch with you when more worksites are added soon.',
        })
        SegmentService.track('employee-signup-action', {
          inviteCode: employeeDetails.inviteCode,
          firstName: employeeDetails.firstName,
          lastName: employeeDetails.lastName,
          email: employeeDetails.email,
          employeeCorporateId: employeeDetails.employeeCorporateId,
          action: 'otherWorksite',
          referredBy: employeeDetails.referredBy,
        })
      } else if (validEmployeeInfo) {
        checkMissingFields()
        setIsButtonDisabled(true)
        UserService.employeeSignUp({
          ...employeeDetails,
          address:
            employeeDetails.address.trim() !== ''
              ? employeeDetails.address
              : null,
          unitNumber:
            employeeDetails.unitNumber.trim() !== ''
              ? employeeDetails.unitNumber
              : null,
          currentTransport: modesMap[selectedMode],
        })
          .then(() => {
            SegmentService.track('employee-signup-action', {
              inviteCode: employeeDetails.inviteCode,
              firstName: employeeDetails.firstName,
              lastName: employeeDetails.lastName,
              email: employeeDetails.email,
              employeeCorporateId: employeeDetails.employeeCorporateId,
              action: 'success',
              referredBy: employeeDetails.referredBy,
            })
            setShowHelpText({
              error: false,
              msg: 'Your Fleet account was successfully created! Download the app to login!',
            })
          })
          .catch((err) => {
            SegmentService.track('employee-signup-action', {
              inviteCode: employeeDetails.inviteCode,
              firstName: employeeDetails.firstName,
              lastName: employeeDetails.lastName,
              email: employeeDetails.email,
              employeeCorporateId: employeeDetails.employeeCorporateId,
              referredBy: employeeDetails.referredBy,
              action: 'failed',
            })
            const errorMsg = Object.keys(err.response.data).reduce(
              (agg, key) => {
                agg = agg.concat(err.response.data[key])
                return agg
              },
              []
            )

            setShowHelpText({
              error: true,
              msg: errorMsg.join(' '),
            })
          })
          .finally(() => {
            setIsButtonDisabled(false)
          })
      }
    },
    [employeeDetails, selectedMode, selectedWorksite, checkMissingFields]
  )

  return (
    <>
      <Helmet>
        <title>Commuter Sign Up | Fleet</title>
      </Helmet>
      <Container>
        <FormContainer>
          <LogoContainer>
            <FleetLogoFullColor height='40' width='115' />
          </LogoContainer>
          <Text variant='h1' marginBottom='1rem' color='black'>
            Commuter Sign Up
          </Text>
          <Text
            variant='action2'
            marginBottom='1rem'
            maxWidth={'25rem'}
            color='black'
          >
            {signUpCopy.employee}
          </Text>
          {/* {showHelpText.msg && (
            <HelpText error={showHelpText.error}>
              <Text variant='action2'>{showHelpText.msg}</Text>
            </HelpText>
          )} */}
          <form>
            <InputContainer inline>
              <TextFieldV2
                label='First Name *'
                placeholder='First Name'
                name='firstName'
                type='text'
                onChange={onValChange}
                value={employeeDetails?.firstName}
                required
                invalid={requiredFieldsMissing.firstName}
              />
              <TextFieldV2
                label='Last Name *'
                placeholder='Last Name'
                name='lastName'
                type='text'
                onChange={onValChange}
                value={employeeDetails?.lastName}
                required
                invalid={requiredFieldsMissing.lastName}
              />
            </InputContainer>
            <InputContainer>
              <TextFieldV2
                label='Email *'
                placeholder='Email'
                name='email'
                type='email'
                value={employeeDetails?.email}
                onChange={onValChange}
                required
                invalid={requiredFieldsMissing.email}
                onBlur={generateEmployeeId}
              />
            </InputContainer>
            <Text
              variant='body2'
              textColor={palette.primary.pink500}
              margin='0.5rem 0 1rem'
              maxWidth={pxToRem(450)}
            >
              A usable email address is required to receive important
              notifications. Does not have to be your work email.
            </Text>
            <InputContainer inline>
              <TextFieldV2
                label='Password *'
                placeholder='Password'
                name='password1'
                type='password'
                value={employeeDetails?.password1}
                onChange={onValChange}
                required
                invalid={requiredFieldsMissing.password1}
              />
              <TextFieldV2
                label='Confirm Password *'
                placeholder='Confirm Password'
                name='password2'
                type='password'
                onChange={onValChange}
                value={employeeDetails?.password2}
                required
                invalid={requiredFieldsMissing.password2}
              />
            </InputContainer>
            <PasswordRequirementChecker password={employeeDetails?.password1} />
            <Divider margin='0.75rem 0' size={pxToRem(425)} />
            <InputContainer>
              <TextFieldV2
                label='Referred By'
                placeholder='Email/Name'
                name='referredBy'
                type='email'
                value={employeeDetails?.referredBy}
                onChange={onValChange}
              />
            </InputContainer>
            <InputContainer>
              {worksiteName && inviteId ? (
                <Dropdown
                  active={selectedWorksite}
                  items={getDropdownWorksites(worksiteName)}
                  label='Worksite *'
                  itemCallback={(item) => setSelectedWorksite(item.id)}
                  width='100%'
                />
              ) : (
                <TextFieldV2
                  label='Employer Invite Code *'
                  placeholder='Invite Code'
                  name='inviteCode'
                  type='text'
                  value={employeeDetails?.inviteCode}
                  onChange={onValChange}
                  required
                  invalid={requiredFieldsMissing.inviteCode}
                />
              )}
            </InputContainer>
            <InputContainer>
              <DropdownV2
                active={selectedMode}
                items={modes}
                label='What mode do you normally use to commute?'
                itemCallback={(item) => setSelectedMode(item.id)}
                width='100%'
              />
            </InputContainer>
            <InputContainer>
              <TextFieldV2
                label='Employee ID *'
                placeholder='Employee ID'
                name='employeeCorporateId'
                type='text'
                value={employeeDetails?.employeeCorporateId}
                onChange={onValChange}
                required
                invalid={requiredFieldsMissing.employeeCorporateId}
              />
            </InputContainer>
            <Divider margin='1.5rem 0' size={pxToRem(425)} />
            <InputContainer>
              <AddressAutocompleteV2
                label='Home Address *'
                onActiveChange={onAddressChange}
                hasError={requiredFieldsMissing.address}
              />
            </InputContainer>
            <InputContainer>
              <TextFieldV2
                placeholder='Unit/Apt Number'
                name='unitNumber'
                type='text'
                value={employeeDetails?.unitNumber}
                onChange={onValChange}
                maxLength={20}
              />
            </InputContainer>
            <Text
              variant='body2'
              textColor={palette.primary.pink500}
              marginTop='1rem'
              maxWidth={pxToRem(450)}
            >
              <Text
                variant='body2'
                textColor={palette.primary.pink500}
                margin='0.5rem 0 1rem'
                maxWidth={pxToRem(450)}
              >
                Enter the address you commute from, such as your home.
                Program-related mail may be sent to this address.
              </Text>
            </Text>
            <Text variant='body2' textColor={palette.primary.pink500}>
              * Required Fields
            </Text>
            <Text
              variant='body2'
              textColor={palette.primary.pink500}
              margin='1rem 0'
              maxWidth={pxToRem(450)}
            >
              By setting up an account, I acknowledge that I will participate in
              this pilot program in good faith, including logging my daily
              commute trips and completing occasional surveys. I understand the
              pilot program may be discontinued. I understand my personal
              information will only be used for the purpose of administering the
              pilot program. I also accept Fleet&apos;s{' '}
              <a
                href='https://www.movewithfleet.com/terms-of-use'
                target='_blank'
                rel='noopener noreferrer'
              >
                terms and conditions.
              </a>
            </Text>
            {showHelpText.msg && (
              <HelpText error={showHelpText.error}>
                <Text variant='action2'>{showHelpText.msg}</Text>
              </HelpText>
            )}
            <Button onClick={handleSubmit} disabled={isButtonDisabled}>
              Sign Up
            </Button>
            <Text
              variant='body2'
              textColor={palette.text.secondary}
              marginTop='1rem'
            >
              Already signed up? Download our mobile app!
            </Text>
            <FlexContainer>
              <StyledAndroidContainer
                href='https://play.google.com/store/apps/details?id=com.fleet_mobile&hl=en_CA&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  alt='Get it on Google Play'
                  src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                />
              </StyledAndroidContainer>
              <StyledAppleContainer
                href='https://apps.apple.com/us/app/move-with-fleet/id1575305413?itsct=apps_box_badge&amp;itscg=30200'
                target='_blank'
                rel='noopener noreferrer'
              >
                <StyledAppleImage
                  src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1631232000&h=d5e83c1bb015236d57ef288b4486f8d7'
                  alt='Download on the App Store'
                />
              </StyledAppleContainer>
            </FlexContainer>
          </form>
        </FormContainer>
        <LoginSignUpGraphicV2 imageLabel={ImageLabel.EMPLOYEE_SIGNUP} />
      </Container>
    </>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployeeSignUpView.displayName = 'EmployeeSignUpView'
}

export default EmployeeSignUpView
