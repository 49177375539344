import { Feature, FeatureCollection, Point } from 'geojson'
import mapboxgl from 'mapbox-gl'
import React, { MutableRefObject, useCallback, useRef, useState } from 'react'
import ReactMapGL, {
  AttributionControl,
  MapMouseEvent,
  MapProps,
  MapRef,
  ViewState,
  ViewStateChangeEvent,
} from 'react-map-gl/mapbox'
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { mapboxConfig } from 'shared/MapboxConfig'
import GeocoderControl from './GeocoderControl'
import MapPopup from './MapPopup'
import MapSvg from './MapSvg'
import MapUtils from './MapUtils'
import localStyles from './local-styles.json'

// global styles are found in global.styles.ts

/* eslint-disable */
// eslint-disable-next-line import/no-webpack-loader-syntax
// @ts-ignore
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

interface Props extends MapProps {
  children?: ({ viewport, setViewport, updateBbox }) => React.ReactElement
  validInteractiveGeoJSONLayers?: string[]
  popupMap?: Dictionary<any>
  worksites?: FeatureCollection
  loadLayer?: FeatureCollection
  geocoderCallback?: (data) => void
  onMapClickCallback?: (
    feature: Feature,
    updateBbox: (featureCollection: FeatureCollection) => void
  ) => void
}

const Map = React.memo((props: Props) => {
  const {
    children,
    worksites,
    validInteractiveGeoJSONLayers = [],
    popupMap,
    loadLayer,
    geocoderCallback,
    onMapClickCallback,
    ...rest
  } = props

  const { worksiteGeojson } = useSelector(
    (state: AppState) => state.mapIntelligence
  )

  const [cursor, setCursor] = useState('auto')
  const [popupInfo, setPopupInfo] = useState(null)
  const [viewport, setViewport] = useState<ViewState>({
    ...MapUtils.defaultViewport,
    latitude: 38.7577,
    longitude: -103.4376,
    zoom: 4,
    bearing: null,
    pitch: null,
    padding: null,
  })

  const mapRef = useRef() as MutableRefObject<MapRef>

  const updateBbox = useCallback(
    (featureCollection: FeatureCollection) => {
      if (featureCollection?.features?.length > 0) {
        const [minLng, minLat, maxLng, maxLat] = MapUtils.getBbox({
          featureCollection,
        })
        mapRef.current?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          { padding: 50 }
        )
      }
    },
    [mapRef]
  )

  const onMapClick = useCallback(
    (pointerEvent: MapMouseEvent) => {
      const featureId =
        pointerEvent.features?.length > 0 && pointerEvent.features[0].layer.id
      const featureProperties = featureId && {
        ...pointerEvent.features[0].properties,
        id: pointerEvent.features[0].id && pointerEvent.features[0].id,
      }
      const featureLocation = featureId && pointerEvent.lngLat

      if (validInteractiveGeoJSONLayers.includes(featureId)) {
        const feature = {
          type: 'Feature',
          properties: {
            ...featureProperties,
            featureId,
          },
          geometry: {
            type: 'Point',
            coordinates: [featureLocation.lng, featureLocation.lat],
          },
        } as Feature
        if (popupMap[featureId]) {
          setPopupInfo(feature)
        }
        if (onMapClickCallback) {
          onMapClickCallback(feature, updateBbox)
        }
      }
    },
    [
      setPopupInfo,
      validInteractiveGeoJSONLayers,
      onMapClickCallback,
      updateBbox,
      popupMap,
    ]
  )

  const onWorksiteClick = useCallback((feature: Feature) => {
    setPopupInfo({
      ...feature,
      properties: {
        ...feature.properties,
        featureId: 'worksites',
      },
    })
  }, [])

  const onClosePopup = () => {
    setPopupInfo(null)
  }

  const renderPopup = useCallback(() => {
    const point = popupInfo && (popupInfo.geometry as Point)
    const PopupLayer = popupInfo && popupMap[popupInfo.properties.featureId]

    return (
      popupInfo && (
        <MapPopup point={point} onCloseCallback={onClosePopup}>
          <PopupLayer feature={popupInfo} />
        </MapPopup>
      )
    )
  }, [popupInfo, popupMap])

  const onLoad = () => {
    if (loadLayer && loadLayer.features.length > 0) {
      updateBbox(loadLayer)
    }
  }

  const handleViewportChange = useCallback(
    (evt: ViewStateChangeEvent) => setViewport(evt.viewState),
    []
  )

  const handleGeocoderResult = useCallback(
    (data) => geocoderCallback(data),
    [geocoderCallback]
  )

  const onMouseEnter = useCallback(() => setCursor('pointer'), [])
  const onMouseLeave = useCallback(() => setCursor('auto'), [])

  return (
    <ReactMapGL
      {...viewport}
      ref={mapRef}
      mapStyle={localStyles}
      mapboxAccessToken={mapboxConfig.apiKey}
      onMove={handleViewportChange}
      attributionControl={false}
      onClick={(pointerEvent) => onMapClick(pointerEvent)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      cursor={cursor}
      interactiveLayerIds={validInteractiveGeoJSONLayers}
      onLoad={onLoad}
      {...rest}
    >
      {renderPopup()}
      {children && children({ viewport, setViewport, updateBbox })}
      {worksites && (
        <MapSvg
          features={worksiteGeojson}
          svg={<MapUtils.WorksiteSvg height={28} width={28} />}
          onSvgClick={onWorksiteClick}
        />
      )}
      <AttributionControl compact />
      {geocoderCallback && (
        <>
          <GeocoderControl
            mapboxAccessToken={mapboxConfig.apiKey}
            position='top-left'
            onResult={handleGeocoderResult}
          />
        </>
      )}
    </ReactMapGL>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  Map.displayName = 'Map'
}

export default Map
