import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { FAQ } from 'redux/employeePlatform/employeePlatformTypes'
import styled from 'styled-components'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import Text from 'core-system/Text'
import FaqCard from 'features/Concierge/Faq/FaqCard'
import Loading from 'core-system/Loading'

const EmptyContainer = styled.div`
  padding: ${(props) => props.theme.pxToRem(40)};
  height: ${(props) => props.theme.pxToRem(348)};
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 0.9375rem;
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const IconContainer = styled.div`
  margin-bottom: 2.25rem;
`

const renderFAQs = (faqs: FAQ[]) => {
  if (faqs.length === 0) {
    return (
      <EmptyContainer>
        <IconContainer>
          <img alt='faq' src={`${StaticAssetsUrl}/concierge/faq.png`} />
        </IconContainer>
        <Text variant='h3'>Your Employer Has Not Set Up FAQs Yet</Text>
      </EmptyContainer>
    )
  } else {
    return faqs.map((faq: FAQ, idx: number) => {
      return <FaqCard key={idx} faq={faq} idx={idx} editable={false} />
    })
  }
}

const FAQList = React.memo(() => {
  const { faqs } = useSelector((state: AppState) => state.employeePlatform)

  if (!faqs) {
    return <Loading />
  }

  return (
    <>
      <Text variant='h3'>FAQs</Text>
      {renderFAQs(faqs)}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FAQList.displayName = 'FAQList'
}

export default FAQList
