export default {
  50: '#FEFBFC',
  100: '#FEF8F8',
  200: '#FCE9ED',
  300: '#F9C8D8',
  400: '#F5A7BB',
  500: '#F06F98',
  600: '#D23873',
  700: '#94254F',
  800: '#57112C',
  900: '#1D0208',
}
