import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import { Payment } from 'redux/employeePlatform/employeePlatformTypes'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import PaymentMethod from 'features/Accounts/Payments/components/PaymentMethod'
import { useDispatch } from 'react-redux'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import AddIcon from 'core-system/Icons/Actions/Add'
import Button from 'core-system/Button'
import AddPaymentMethodModal from './AddPaymentMethodModal'
// import AddCreditCardModal from 'features/Accounts/Payments/components/AddCreditCardModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`

const AddButtonIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.1rem;
  background-color: ${(props) => props.theme.palette.primary.pink500};
`

const PaymentMethodsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

interface PaymentMethodsProps {
  isMobile: boolean
}

const PaymentMethods = React.memo((props: PaymentMethodsProps) => {
  const { isMobile } = props

  const { paymentMethods } = useSelector(
    (state: AppState) => state.employeePlatform.wallet
  )

  const dispatch = useDispatch()

  const [showAddMethodModal, setShowAddMethodModal] = useState(false)

  const handleDeletePaymentMethod = useCallback(
    (id: string) => {
      dispatch(employeePlatformActions.deletePaymentMethod(id))
    },
    [dispatch]
  )

  if (!paymentMethods) {
    return <Loading />
  }

  return (
    <Container>
      <Header>
        <Text variant='action1'>Payment Methods</Text>
        {isMobile ? (
          <AddButtonIconContainer onClick={() => setShowAddMethodModal(true)}>
            <AddIcon />
          </AddButtonIconContainer>
        ) : (
          <Button size='small' onClick={() => setShowAddMethodModal(true)}>
            Add Payment Method
          </Button>
        )}
      </Header>
      <PaymentMethodsContainer>
        {paymentMethods.length > 0 ? (
          paymentMethods.map((paymentMethod: Payment) => {
            return (
              <PaymentMethod
                key={paymentMethod.internalId}
                id={paymentMethod.internalId}
                type={paymentMethod.type}
                bankName={paymentMethod.bankName}
                brand={paymentMethod.brand}
                last4={parseInt(paymentMethod.last4)}
                expMonth={paymentMethod.expMonth}
                expYear={paymentMethod.expYear}
                showActions={true}
                onDeleteAction={handleDeletePaymentMethod}
                customDeleteModalText='Are you sure you want to delete this payment method?'
                isMobile={isMobile}
              />
            )
          })
        ) : (
          <NoDataText text='NO PAYMENT METHODS' />
        )}
      </PaymentMethodsContainer>
      <AddPaymentMethodModal
        open={showAddMethodModal}
        closeModal={() => setShowAddMethodModal(false)}
        isMobile={isMobile}
      />
      {/* <AddCreditCardModal
                open={showAddMethodModal}
                closeModal={() => setShowAddMethodModal(false)}
            /> */}
    </Container>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  PaymentMethods.displayName = 'PaymentMethods'
}

export default PaymentMethods
