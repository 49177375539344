import Divider from 'core-system/Divider'
import Text from 'core-system/Text'
import React, { useState, useEffect } from 'react'
import SegmentService from 'redux/config/services/SegmentService'
import DateUtils, { Month } from 'shared/DateUtils'
import { SpaceProps } from 'styled-system'
import { Worksite } from 'redux/employer/employerTypes'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import palette from 'core-system/Themes/palette'
import DropdownV2 from 'core-system/Dropdown/DropdownV2'

interface QTFWorksiteDateSelectProps extends SpaceProps {
  onDateChange?: (val: string, text: string, initial?: boolean) => void
  showDates2MonthsLater?: boolean
  setNumberOfEmployees: (num: number) => void
  setSelectedWorksiteSegments: (worksite: string[]) => void
  allWorksites: Worksite[]
}

const QTFWorksiteDateSelect = React.memo(
  (props: QTFWorksiteDateSelectProps) => {
    const {
      onDateChange,
      showDates2MonthsLater,
      setNumberOfEmployees,
      setSelectedWorksiteSegments,
      allWorksites,
    } = props

    const { segmentsMap } = useSelector((state: AppState) => state.employer)
    const { qtfPrograms } = useSelector((state: AppState) => state.qtf)

    const items = [
      { id: 'all', text: 'Select All' },
      ...allWorksites.map((worksite) => ({
        id: worksite.segmentId,
        text:
          worksite.alias +
          ` (${
            worksite.employeeCount - worksite.contractorCount
          } eligible employees)`,
      })),
    ]

    const dates = DateUtils.getNextXMonths(6, showDates2MonthsLater ? 2 : 1)

    // TODO: Make activeItems and activeWorksites sets rather than lists since we're checking for items often
    const [activeItems, setActiveItems] = useState([])
    const [activeWorksites, setActiveWorksites] = useState([])
    const [selectedDate, setSelectedDate] = useState(
      showDates2MonthsLater ? '2' : '1'
    )

    useEffect(() => {
      let numEmployees = 0
      const segmentIds = []

      activeItems.forEach((id) => {
        const segment = segmentsMap[id]
        if (segment) {
          numEmployees += segment.commuters - segment.contractors.length
          segmentIds.push(id)
        }
      })

      setNumberOfEmployees(numEmployees)
      setSelectedWorksiteSegments(segmentIds)
    }, [
      activeItems,
      allWorksites,
      setNumberOfEmployees,
      setSelectedWorksiteSegments,
      segmentsMap,
    ])

    const unselectableSegmentIds = new Set(Object.keys(qtfPrograms))

    const handleItemSelect = (id: string) => {
      // We don't want to allow an admin to activate a QTF program for a segment that already has one active
      if (unselectableSegmentIds.has(id)) {
        return
      }

      if (id === 'all') {
        if (activeItems.includes(id)) {
          setActiveItems([])
          setActiveWorksites([])
        }
        const allSelectableSegments = items.filter(
          (item) => !unselectableSegmentIds.has(item.id)
        )
        setActiveItems(allSelectableSegments.map((item) => item.id))
        setActiveWorksites(
          allSelectableSegments
            .filter((item) => item.id !== 'all')
            .map((item) => item.text)
        )
      } else {
        const newActiveItemIds = activeItems.includes(id)
          ? activeItems.filter((itemId) => itemId !== id)
          : [...activeItems, id]
        setActiveItems(newActiveItemIds)
        setActiveWorksites(
          allWorksites
            .filter((worksite) => newActiveItemIds.includes(worksite.segmentId))
            .map((worksite) => worksite.alias)
        )
      }
    }

    const handleStartDateChange = (item: Month) => {
      onDateChange(item.iso, item.text)
      setSelectedDate(item.id)
      SegmentService.track('baseOptions-toggle-action', {
        actionType: 'starting-date',
      })
    }

    return (
      <>
        <Text variant='h4' color={palette.black}>
          When should your team start spending?
        </Text>
        <Text variant='body1' margin='1.5rem 0' color={palette.grey.grey700}>
          Pick the day they can begin using their funds. Don&apos;t worry, you
          can get everyone onboarded and the program rolling before this date.
        </Text>
        <DropdownV2
          active={selectedDate}
          items={dates}
          itemCallback={handleStartDateChange}
          width='100%'
        />
        <Divider margin='1.5rem 0' />
        <Text variant='h4' marginBottom='1rem' color={palette.black}>
          Which work sites will you add?
        </Text>
        <DropdownV2
          active={selectedDate}
          items={items}
          activeItems={activeItems}
          onItemCallback={handleItemSelect}
          width='100%'
          multiDropdown={true}
          placeholder={
            activeWorksites.length === 0
              ? 'Select all that apply'
              : activeWorksites.join('\u0000')
          }
          disabledItemIds={unselectableSegmentIds}
        />
      </>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFWorksiteDateSelect.displayName = 'QTFWorksiteDateSelect'
}

export default QTFWorksiteDateSelect
