import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Text from 'core-system/Text'
import Markdown from 'react-markdown'
import BubbleWrapper from './BubbleWrapper'
import { Typography } from '@mui/material'

const useStyles = makeStyles(() => ({
  text: {
    padding: '1em',
    fontFamily: 'PolySans',
    textAlign: 'left',
    lineHeight: '1.5em',
    wordWrap: 'break-word', // This is important for long words with no spaces going out of the chat box
    // Markdown is rendered in p tag and the margin is too much
    '& p': {
      margin: '6px 0',
    },
  },
}))

interface ChatBubbleProps {
  bubbleType?: 'own' | 'partner'
  text?: string
  isMessageSent?: boolean
  isMessageRead?: boolean
  isMessageDelivered?: boolean
  chatId?: string
  chatIsInView?: () => void
  renderMarkdown?: boolean
  showLike?: boolean
  onLikeClick?: () => void
  isLiked?: boolean
  typingAnimation?: boolean
  onTypingAnimationComplete?: () => void
}

const ChatBubble: React.FC<ChatBubbleProps> = ({
  bubbleType = 'own',
  text = '',
  isMessageSent = false,
  isMessageRead = false,
  isMessageDelivered = false,
  chatId = '',
  chatIsInView = () => {},
  typingAnimation = false,
  renderMarkdown = false,
  showLike = false,
  onLikeClick = () => {},
  isLiked = false,
  onTypingAnimationComplete = () => {},
}) => {
  const classes = useStyles()
  const [currentText, setCurrentText] = useState('')

  useEffect(() => {
    if (typingAnimation) {
      typingAnimationFunction()
    }
  }, [])

  const typingAnimationFunction = () => {
    if (!typingAnimation) {
      setCurrentText(text)
      onTypingAnimationComplete()
      return
    }
    if (bubbleType === 'own') {
      setCurrentText(text)
      onTypingAnimationComplete()
      return
    }
    let i = 0

    const typingInterval = setInterval(() => {
      if (i < text.length && !isMessageRead) {
        setCurrentText((prevText) => {
          // ! do not change the order and implementation of the following line
          // Changing this somehow skips the second character of the text
          const newText = prevText + text.charAt(i)
          i++
          return newText
        })
      } else {
        clearInterval(typingInterval)
        onTypingAnimationComplete()
      }
    }, 10)
  }

  const canShowLike = () => {
    if (bubbleType === 'partner') {
      return showLike
    }
    return false
  }

  if (renderMarkdown) {
    return (
      <BubbleWrapper
        bubbleType={bubbleType}
        isMessageSent={isMessageSent}
        isMessageRead={isMessageRead}
        isMessageDelivered={isMessageDelivered}
        showTick={bubbleType === 'own'}
        chatId={chatId}
        chatIsInView={chatIsInView}
        showLike={canShowLike()}
        onLikeClick={onLikeClick}
        isLiked={isLiked}
      >
        {typingAnimation && (
          <Markdown className={classes.text} children={currentText} />
        )}
        {!typingAnimation && (
          <Markdown className={classes.text}>{text}</Markdown>
        )}
      </BubbleWrapper>
    )
  }
  return (
    <BubbleWrapper
      bubbleType={bubbleType}
      isMessageSent={isMessageSent}
      isMessageRead={isMessageRead}
      isMessageDelivered={isMessageDelivered}
      showTick={bubbleType === 'own'}
      chatId={chatId}
      chatIsInView={chatIsInView}
      showLike={canShowLike()}
      onLikeClick={onLikeClick}
      isLiked={isLiked}
    >
      <div className={classes.text}>
        {typingAnimation && <Text variant='body1'>{currentText}</Text>}
        {!typingAnimation && <Text variant='body1'>{text}</Text>}
      </div>
    </BubbleWrapper>
  )
}

export default React.memo(ChatBubble)
