import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Container = styled.div<FlexboxProps & SpaceProps & LayoutProps>`
  ${(props) => props.theme.typography.caption};
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.pink700};
  background-color: ${(props) => props.theme.palette.primary.pink400};
  border-radius: 0.25rem;
  border: ${(props) =>
    `0.0625rem solid ${props.theme.palette.primary.pink300}`};
  padding: 0.375rem 0.5rem;

  letter-spacing: 0.7px;

  ${flexbox}
  ${space}
  ${layout}
`
interface InfoTagProps {
  children?: React.ReactNode
}
const InfoTag = (props: InfoTagProps) => {
  const { children } = props
  return <Container>{children}</Container>
}

export default InfoTag
