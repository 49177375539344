import React from 'react'
import styled from 'styled-components'
import {
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  variant,
} from 'styled-system'
import { ServiceModeType } from '../../redux/programs/programsTypes'
import { color, ColorProps } from '../@styled-system/Color'
import * as TransitLogos from '../Icons/modeTypes'

const DefaultLogoContainer = styled.div<
  FlexboxProps & SpaceProps & BorderProps & ColorProps
>`
  border-radius: 0.25rem;
  border-color: ${(props) => props.theme.palette.grey.grey300};
  border-style: solid;
  background-color: ${(props) => props.theme.palette.primary.pink700};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.palette.text.secondary};
  ${variant({
    prop: 'size',
    variants: {
      small: {
        height: '2rem',
        width: '2rem',
        borderWidth: '2px',
        '& svg': {
          height: '1.25rem',
          width: '1.25rem',
        },
      },
      large: {
        height: '3rem',
        width: '3rem',
        borderWidth: '1px',
        '& svg': {
          height: '2rem',
          width: '2rem',
        },
      },
      xl: {
        height: '4.5rem',
        width: '4.5rem',
        borderWidth: '1px',
        '& svg': {
          height: '3.5rem',
          width: '3.5rem',
        },
      },
      xxl: {
        height: '4.875rem',
        width: '4.875rem',
        borderWidth: '1px',
        '& svg': {
          height: '4.875rem',
          width: '4.875rem',
        },
      },
    },
  })}

  ${flexbox}
  ${space}
  ${border}
  ${color}
`

const CustomContainer = styled.div`
  ${variant({
    prop: 'size',
    variants: {
      small: {
        maxWidth: '2rem',
        maxHeight: '2rem',
        '& img, svg': {
          height: '2rem',
          width: '2rem',
          borderRadius: '0.25rem',
        },
      },
      large: {
        maxWidth: '3rem',
        maxHeight: '3rem',
        '& img, svg': {
          height: '3rem',
          width: '3rem',
          borderRadius: '0.25rem',
        },
      },
      xl: {
        maxWidth: '4.5rem',
        maxHeight: '4.5rem',
        '& img, svg': {
          height: '3.5rem',
          width: '3.5rem',
          borderRadius: '0.5rem',
        },
      },
      xxl: {
        height: '4.875rem',
        width: '4.875rem',
        borderWidth: '1px',
        '& img, svg': {
          height: '4.875rem',
          width: '4.875rem',
          borderRadius: '0.8125rem',
        },
      },
    },
  })}

  ${flexbox}
  ${space}
  ${border}
  ${color}
`

const getDefaultModeLogo = (modeType: ServiceModeType | 'custom') => {
  switch (modeType.toLowerCase()) {
    case 'transit':
    case 'commrail':
    case 'heavyrail':
    case 'literail':
    case 'monorail':
      return <TransitLogos.Transit />
    case 'bike':
    case 'ebike':
    case 'bikeshare':
    case 'ebikeshare':
      return <TransitLogos.ElectricBike />
    case 'scooter':
    case 'escooter':
    case 'scootershare':
    case 'escootershare':
      return <TransitLogos.ElectricScooter />
    case 'moped':
    case 'emoped':
    case 'mopedshare':
    case 'emopedshare':
      return <TransitLogos.ElectricMoped />
    case 'aerial':
    case 'funicular':
      return <TransitLogos.Aerial />
    case 'car':
      return <TransitLogos.Car />
    case 'rideshare':
      return <TransitLogos.Rideshare />
    case 'carpool':
      return <TransitLogos.Carpool />
    case 'carshare':
      return <TransitLogos.CarRental />
    case 'vanpool':
      return <TransitLogos.Vanpool />
    case 'shuttle':
      return <TransitLogos.Van />
    case 'bus':
      return <TransitLogos.Bus />
    case 'ferry':
      return <TransitLogos.Ferry />
    case 'walk':
      return <TransitLogos.Walking />
    default:
      return <TransitLogos.Bike />
  }
}

export type TransitLogoModeTypes = ServiceModeType | 'custom'

interface TransitLogoProps
  extends FlexboxProps,
    SpaceProps,
    BorderProps,
    ColorProps,
    React.HTMLAttributes<HTMLDivElement> {
  modeType: TransitLogoModeTypes
  size?: 'small' | 'large' | 'xl' | 'xxl'
  src?: string
}

const TransitLogo = (props: TransitLogoProps) => {
  const { src, modeType, size = 'large' } = props

  if (src && modeType === 'custom') {
    return (
      <CustomContainer size={size} {...props}>
        <img src={src} alt={modeType} />
      </CustomContainer>
    )
  }

  return (
    <DefaultLogoContainer size={size} {...props}>
      {getDefaultModeLogo(modeType)}
    </DefaultLogoContainer>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TransitLogo.displayName = 'TransitLogo'
}

export default TransitLogo
