import { employerActions } from 'redux/employer/employerSlice'
import Button from 'core-system/Button'
import Alert from 'core-system/Icons/Misc/Alert'
import React, { useEffect } from 'react'
import { useFinchConnect } from '@tryfinch/react-connect'
import { useDispatch, useSelector } from 'react-redux'
import {
  FINCH_CLIENT_ID,
  FINCH_SANDBOX_SETTING,
  StaticAssetsUrl,
} from 'redux/config/services/Constants'
import styled from 'styled-components'
import ManageHrisModal from './ManageHrisModal'
import { AppState } from 'redux/config/store'
import SegmentService from 'redux/config/services/SegmentService'
import { useLocation, useSearchParams } from 'react-router-dom'
import UrlParser from 'shared/UrlParser'
import RandomUtils from 'shared/RandomUtils'
import SelectProvidersModal from 'features/Finch/components/SelectProviders'

const ProviderImg = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
`

interface ConnectHrisProps {
  providerName: string
  reAuth: boolean
  btnSize?: 'small' | 'medium' | 'large'
  active?: boolean
  width?: string
  margin?: string
}

const ConnectHris = (props: ConnectHrisProps) => {
  const location = useLocation()
  const qp = UrlParser.getQueryParams(location.search)
  const [searchParams, setSearchParams] = useSearchParams(qp)
  const { providerName, reAuth, btnSize, active, width, margin } = props
  const [code, setCode] = React.useState(null)
  const [manageHrisModal, setManageHrisModal] = React.useState(false)
  const [showSelectProvidersModal, setShowSelectProvidersModal] =
    React.useState(false)
  const [providerNameChanged, setProviderNameChanged] = React.useState(false)
  // Will this be an issue in the future if the token exchange fails on Finch's end?
  const onSuccess = ({ code }) => {
    setCode(code)
    setProviderNameChanged(true)
  }

  const onError = ({ errorMessage }) => {
    console.error(errorMessage)
    SegmentService.track('hris-connect-error', {
      msg: errorMessage,
    })
  }
  const onClose = () => SegmentService.track('hris-connect-modal-closed')

  const dispatch = useDispatch()
  const { payrollProviderId, provider } = useSelector(
    (state: AppState) => state.employer.profile
  )

  // Finch Connect Setup Documentation: https://developer.tryfinch.com/implementation-guide/Connect/Set-Up-Finch-Connect
  const { open } = useFinchConnect({
    clientId: FINCH_CLIENT_ID,
    products: provider ? provider.products : [],
    // If using any client ID other than the sandbox client ID, ensure that the sandbox parameter is set to false
    sandbox: FINCH_SANDBOX_SETTING, // ENSURE THIS IS SET TO FALSE FOR PROD / STAGING
    // If the sandbox parameter is set to anything other than false, then the manual parameter must be set to false
    manual: FINCH_SANDBOX_SETTING ? false : true, // ENSURE THIS IS SET TO TRUE FOR PROD
    payrollProvider: providerName,
    onSuccess,
    onError,
    onClose,
  })

  React.useEffect(() => {
    if (code !== null) {
      dispatch(employerActions.integrateHris(code))
    }
  }, [code, dispatch])

  // After initial Finch integration, open manage modal
  React.useEffect(() => {
    if (payrollProviderId && providerNameChanged && !reAuth) {
      setManageHrisModal((prevManageHrisModal) => !prevManageHrisModal)
    }
  }, [payrollProviderId, providerNameChanged, reAuth])

  const toggleManageModal = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement | any>) => {
      e.stopPropagation()
      setManageHrisModal(!manageHrisModal)
      // segment analytics
    },
    [manageHrisModal]
  )

  const disconnect = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      toggleManageModal(e)
      dispatch(employerActions.disconnectHris())
    },
    [dispatch, toggleManageModal]
  )

  const handleSelectProvidersModal = React.useCallback(() => {
    setShowSelectProvidersModal(true)
  }, [])

  const handleCloseSelectProvidersModal = React.useCallback(() => {
    setShowSelectProvidersModal(false)
  }, [])

  const onProviderSelected = React.useCallback(
    (provider: any) => {
      setShowSelectProvidersModal(false)
      open({
        products: provider.products,
        payrollProvider: provider.id,
      })
    },
    [open]
  )

  useEffect(() => {
    if (searchParams.get('show_finch_connect') === 'true') {
      open()
      setSearchParams({})
    }
    // eslint-disable-next-line
  }, [])

  // No HRIS connected
  if (!providerName) {
    return (
      <div>
        <Button
          size={btnSize || 'small'}
          onClick={handleSelectProvidersModal}
          width={width || null}
          margin={margin || null}
        >
          Connect to HRIS Integration
        </Button>
        <SelectProvidersModal
          open={showSelectProvidersModal}
          onClose={handleCloseSelectProvidersModal}
          selectProvider={onProviderSelected}
        />
      </div>
    )
  }

  // HRIS connected but needs reauth
  if (providerName && reAuth) {
    return (
      <>
        <Button
          size={btnSize || 'small'}
          variant='secondary'
          iconLeft={<Alert />}
          onClick={toggleManageModal}
          width={width || null}
          margin={margin || null}
        >
          Reconnect
        </Button>
        <ManageHrisModal
          providerName={providerName}
          reAuth={reAuth}
          closeModal={toggleManageModal}
          open={manageHrisModal}
          openFinchConnect={open}
          disconnectHris={disconnect}
        />
      </>
    )
  }

  // Connected and Authenticated
  return (
    <>
      <Button
        size={btnSize || 'small'}
        variant='secondary'
        style={{ textTransform: 'capitalize' }}
        active={active}
        iconLeft={
          <ProviderImg
            src={`${StaticAssetsUrl}/hris_provider_logos/${providerName}.png`}
            alt={providerName}
          />
        }
        onClick={toggleManageModal}
        width={width || null}
        margin={margin || null}
      >
        Manage {RandomUtils.formatFinchProviderName(providerName)}
      </Button>
      <ManageHrisModal
        providerName={providerName}
        reAuth={reAuth}
        closeModal={toggleManageModal}
        open={manageHrisModal}
        openFinchConnect={open}
        disconnectHris={disconnect}
      />
    </>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ConnectHris.displayName = 'ConnectHris'
}

export default ConnectHris
