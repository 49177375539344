import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Container = styled.div<
  FlexboxProps & SpaceProps & LayoutProps & { hasHeader: boolean }
>`
  background-color: ${(props) => props.theme.palette.secondary.purple200};
  padding: 0.75rem 1.5rem;
  color: ${(props) => props.theme.palette.text.secondary};
  width: 100%;
  display: flex;
  border-radius: 0.9375rem 0.9375rem 0 0;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    display: none;
  }

  ${flexbox}
  ${space}
  ${layout}
`

interface TableLabelRowProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  hasHeader?: boolean
}

const TableLabelRow = React.memo((props: TableLabelRowProps) => {
  const { children, hasHeader = true } = props
  return (
    <Container hasHeader={hasHeader} {...props}>
      {children}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TableLabelRow.displayName = 'TableLabelRow'
}

export default TableLabelRow
