import { userActions } from 'redux/user/userSlice'
import Button from 'core-system/Button'
// import Dropdown from 'core-system/Dropdown'
import { SelectItemProps } from 'core-system/Dropdown/Select'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import TextField from 'core-system/TextField'
import TextToggle from 'core-system/TextToggle'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { Employee } from 'redux/employee/employeeTypes'
import { Worksite } from 'redux/employer/employerTypes'
import UserService from 'redux/user/userService'
import { User, UserNonGroup } from 'redux/user/userTypes'
import LocalStorage from 'shared/LocalStorage'
import styled from 'styled-components'
import { employeeActions } from 'redux/employee/employeeSlice'
import { notificationsActions } from 'redux/notifications/notificationsSlice'

const Container = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    flex-direction: column;
    align-items: center;
  }
`

const ProfileInfoSection = styled.div`
  height: 100%;
  width: 100%;
`

const StatusText = styled.p<{ error?: boolean }>`
  margin-top: 1rem;
  ${(props) => props.theme.typography.caption};
  font-size: ${(props) => (props.error ? '0.75rem' : '1rem')};
  color: ${(props) =>
    props.error
      ? props.theme.palette.error.red500
      : props.theme.palette.accent.teal500};
`

const renderChangePwdBtn = (status: boolean | undefined, onClick: any) => {
  if (status !== undefined) {
    const msg = status
      ? 'Check your email for instructions.'
      : 'Something went wrong. Try again.'
    return (
      <StatusText style={{ marginRight: '0.5rem' }} error={!status}>
        {msg}
      </StatusText>
    )
  }

  return (
    <Button onClick={onClick} style={{ marginRight: '0.5rem' }}>
      Change Password
    </Button>
  )
}

const getUserWorksite = (
  allWorksites: SelectItemProps[],
  userWorksiteId: string
) => {
  const site = allWorksites.filter((wrks) => wrks.id === userWorksiteId)[0]
  return site ? site.id : null
}

interface UsersInfoModalProps {
  closeModal: () => void
  open: boolean
  user: User | Employee
  allWorksites: Worksite[]
  isAuthUser?: boolean
  isAdmin?: boolean
}

const UsersInfoModal: React.FC<UsersInfoModalProps> = (props) => {
  const dispatch = useDispatch()
  const { user, allWorksites, isAuthUser, isAdmin, open, closeModal } = props

  if (!user.worksiteId) {
    user.worksiteId = 'no-worksite'
  }

  const selectableLocations = useMemo(
    () => [
      ...allWorksites.map((wrk) => {
        return {
          id: wrk.id,
          text: wrk.alias,
        }
      }),
    ],
    [allWorksites]
  )

  const [name, setName] = useState<string>(`${user.firstName} ${user.lastName}`)
  const [admin, setAdmin] = useState(user.role === 'HRadmins' ? 0 : 1)
  // const [userWorksite, setUserWorksite] = useState<string>(
  //   getUserWorksite(selectableLocations, user.worksiteId)
  // )
  const userWorksite = getUserWorksite(selectableLocations, user.worksiteId)

  const [pwdChange, setPwdChange] = useState<boolean>(undefined)
  const isSocialLogin = LocalStorage.getItem('isSocialLogin')

  const handleIsAdmin = useCallback(
    (id: number) => {
      setAdmin(id)
    },
    [setAdmin]
  )

  const updateUser = useCallback(() => {
    const splitName = name.split(' ')
    const updatedUser: UserNonGroup = {
      id: user.id,
      email: user.email,
      firstName: splitName[0],
      lastName: splitName.slice(1).join(' '),
      role: admin === 0 ? 'HRadmins' : 'HRusers',
      worksiteId: userWorksite === 'no-worksite' ? null : userWorksite,
      avatarUrl: null,
      hasRegistered: user.hasRegistered,
    }
    const e = user as Employee
    const updatedEmp: Employee = {
      ...updatedUser,
      employer: e.employer,
      iconId: e.iconId,
    }
    closeModal()

    const worksiteAlias = selectableLocations
      .filter((s) => s.id === userWorksite)[0]
      .text.toLowerCase()

    // analytics
    if (isAuthUser) {
      dispatch(
        userActions.updateUser({
          id: updatedUser.id,
          email: updatedUser.email,
          groups: [updatedUser.role],
          firstName: updatedUser.firstName,
          lastName: updatedUser.lastName,
          worksiteId: updatedUser.worksiteId,
          avatar: updatedUser.avatarUrl,
          isAuthUser: isAuthUser,
          hasRegistered: updatedUser.hasRegistered,
        })
      )
      SegmentService.track('userInfo-update-action', {
        worksiteName: worksiteAlias,
        role: updatedEmp.role,
        name: `${updatedEmp.firstName} ${updatedEmp.lastName}`,
      })
    } else {
      //currently if user is updated right after invitation the app crashes because the user id doesn't save yet
      if (!updatedEmp.id) {
        dispatch(
          notificationsActions.generalPageError(
            'Please refresh page before updating newly added user'
          )
        )
      } else {
        dispatch(
          employeeActions.updateEmployee({
            id: updatedEmp.id,
            email: updatedEmp.email,
            groups: [updatedEmp.role],
            firstName: updatedEmp.firstName,
            lastName: updatedEmp.lastName,
            worksiteId: updatedEmp.worksiteId,
            avatar: updatedEmp.avatarUrl,
            employer: updatedEmp.employer,
            hasRegistered: updatedEmp.hasRegistered,
          })
        )
        SegmentService.track('table-item-click', {
          tableType: 'employees',
          action: 'update',
          worksiteName: worksiteAlias,
          role: updatedEmp.role,
          name: `${updatedEmp.firstName} ${updatedEmp.lastName}`,
        })
      }
    }
  }, [
    closeModal,
    user,
    isAuthUser,
    userWorksite,
    name,
    dispatch,
    selectableLocations,
    admin,
  ])

  // const locationValueChange = ({ id }) => {
  //   setUserWorksite(id)
  // }

  const sendChangePwdEmail = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      //send email
      UserService.resetPassword(user.email)
        .then((res) => dispatch(userActions.resetPasswordSuccess(res.data)))
        .catch((err) =>
          dispatch({ type: 'user/resetPasswordSuccessFailed', error: err })
        )
      SegmentService.track('user-changePassword-send')
    },
    [user, dispatch]
  )

  useEffect(() => {
    setTimeout(() => {
      if (pwdChange || pwdChange === false) {
        setPwdChange(undefined)
      }
    }, 5000)
  }, [pwdChange])

  const onDeleteUser = useCallback(() => {
    dispatch(employeeActions.deleteEmployee(user.id))
    closeModal()

    const worksiteAlias = selectableLocations
      .filter((s) => s.id === userWorksite)[0]
      .text.toLowerCase()

    SegmentService.track('table-item-click', {
      tableType: 'employees',
      action: 'delete',
      worksiteName: worksiteAlias,
      role: user.role,
      name: `${user.firstName} ${user.lastName}`,
    })
  }, [user, closeModal, dispatch, userWorksite, selectableLocations])

  return (
    <Modal open={open} onClose={() => closeModal()} width={pxToRem(450)}>
      <ModalHeader title={isAuthUser ? 'Edit My Info' : 'Edit User'} />
      <ModalBody>
        <Container>
          <ProfileInfoSection>
            <TextField
              label='Name'
              defaultValue={`${user.firstName} ${user.lastName}`}
              onChange={(e) => setName(e.currentTarget.value.trimStart())}
              disabled={!isAuthUser}
              marginBottom='1rem'
              data-cy='user-name-input'
            />
            <TextField
              label='Email'
              defaultValue={user.email}
              disabled={true}
              marginBottom={!isAdmin ? '1rem' : '0'}
            />
            {isAdmin && (
              <TextToggle
                label='Role'
                options={['Admin Only', 'Admin & User']}
                toggled={admin}
                onClick={handleIsAdmin}
                fullWidth={true}
                variant='dark'
                size='large'
                marginBottom='1rem'
              />
            )}
            {/** This dropdown selection is being hidden as a band-aid fix because currently 
             * a bug exists where updating a user's worksite through this modal does 
             * not actually change the user's worksite i.e it is not being properly updated
             * in the My Commuters page. https://app.clickup.com/t/8483150/FLE-1295
              <Dropdown
                data-cy='user-worksite-dropdown'
                active={userWorksite}
                items={selectableLocations}
                label={'Worksite'}
                itemCallback={locationValueChange}
                usePortal={true}
                width={'100%'}
                marginBottom={'0.75rem'}
              />
              */}
          </ProfileInfoSection>
        </Container>
      </ModalBody>
      <ModalFooter>
        {isAdmin && !isAuthUser && (
          <Button
            variant={'cancel'}
            marginRight={pxToRem(29)}
            onClick={onDeleteUser}
          >
            Remove User
          </Button>
        )}
        {isAuthUser &&
          isSocialLogin === false &&
          renderChangePwdBtn(pwdChange, sendChangePwdEmail)}
        <Button onClick={() => updateUser()}>Update</Button>
      </ModalFooter>
    </Modal>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  UsersInfoModal.displayName = 'UsersInfoModal'
}

export default UsersInfoModal
