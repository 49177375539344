import styled from 'styled-components'

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75rem;
  width: 100%;
`

export const CardListContainer = styled.div<{
  maxHeight?: string
  horizontalScroll?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: scroll;
  ${(props) => (props.horizontalScroll ? 'overflow-x: scroll;' : '')}
  ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight};` : '')}
`

export const CardListCard = styled.div<{ clickable?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey200};
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  gap: 0.5rem;
  ${(props) =>
    props.clickable
      ? `&:hover {
            cursor: pointer;
            background-color: ${(props) => props.theme.palette.primary.pink700};
        }`
      : ''}
`

export const CardListCardTop = styled.div<{ customGridColumns?: string }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.customGridColumns ? props.customGridColumns : '60% 40%'};
  grid-gap: 0.5rem;
`

export const DistancesContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: ${(props) => (props.isMobile ? 'center' : 'flex-end')};
  justify-content: center;
`

export const DistanceItem = styled.div<{ maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '7rem')};
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const InfoItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  ${(props) => (props.isMobile ? '' : 'gap: 0.5rem;')}
`
