export default {
  50: '#FCFCFC',
  100: '#F8F8F8',
  200: '#EFEFEF',
  300: '#DBDBDB',
  400: '#C2C0C0',
  500: '#A3A0A0',
  600: '#7E7C7C',
  700: '#585656',
  800: '#313030',
  900: '#0D0C0C',
}
