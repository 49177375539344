import Chip from 'core-system/Chip'
import { ColumnCard } from 'core-system/ColumnView'
import FlexContainer from 'core-system/FlexContainer'
import * as LeaderboardIcons from 'core-system/Icons/Leaderboards'
import { NumberFormatter, NumberInput } from 'core-system/Input/NumberInput'
import SwitchToggle from 'core-system/SwitchToggle'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import LeaderboardUtils from 'features/Leaderboards/LeaderboardUtils'
import React, { useState } from 'react'
import SegmentService from 'redux/config/services/SegmentService'
import { Leaderboard } from 'redux/leaderboards/leaderboardsTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'
import { height, HeightProps } from 'styled-system'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Bonuses = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  align-items: end;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`

const BonusBody = styled.div<HeightProps>`
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: ${(props) => props.theme.palette.primary.pink300};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  ${height}
`

const StaticInput = styled(FlexContainer)`
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink400};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  width: 100%;
`

interface ManageLeaderboardBonusProps {
  bonuses: number[]
  setBonuses: (bonuses: number[]) => void
  currentLeaderboard: Leaderboard
  isActive: boolean
  noBonuses: boolean
  canEdit?: boolean
}

const ManageLeaderboardBonus = React.memo(
  (props: ManageLeaderboardBonusProps) => {
    const {
      bonuses,
      setBonuses,
      currentLeaderboard,
      isActive,
      noBonuses,
      canEdit,
    } = props
    const [showBonus, setShowBonus] = useState(
      (isActive && currentLeaderboard && !noBonuses) || !isActive
    )

    const handleShowBonusChange = () => {
      if (!showBonus) {
        setBonuses(
          currentLeaderboard && !noBonuses
            ? currentLeaderboard.bonuses
            : LeaderboardUtils.defaultBonuses
        )
      } else {
        setBonuses([0, 0, 0])
      }
      setShowBonus(!showBonus)
      SegmentService.track('leaderboardOptions-toggle-action', {
        bonusActive: !showBonus,
      })
    }

    const handleBonusChange = (value: number, idx: number) => {
      const bonusesCopy = [...bonuses]
      bonusesCopy[idx] = value
      setBonuses(bonusesCopy)
    }

    const showEditUi = canEdit || !isActive

    return (
      <ColumnCard>
        <HeaderContainer>
          <Text variant='h4'>Bonuses</Text>
          {showEditUi ? (
            <SwitchToggle
              active={showBonus}
              onClick={handleShowBonusChange}
              data-cy='leaderboard-bonus-switch'
            />
          ) : (
            <Chip variant={noBonuses ? 'grey' : 'green'}>
              {noBonuses ? 'Disabled' : 'Active'}
            </Chip>
          )}
        </HeaderContainer>
        {showBonus && (
          <div>
            <Text variant='body1' margin='1.5rem 0'>
              Reward commuters who go above and beyond with additional bonuses.
              Set bonus prize amounts for 1st, 2nd, and 3rd places.
            </Text>
            <Bonuses>
              <div>
                <IconContainer>
                  <LeaderboardIcons.SilverMedal width={41} height={52} />
                </IconContainer>
                <BonusBody height={pxToRem(160)}>
                  <Text
                    variant='h2'
                    textColor={palette.text.secondary}
                    marginBottom='1rem'
                  >
                    2nd
                  </Text>
                  {showEditUi ? (
                    <NumberInput
                      padding='0.5rem'
                      alignSelf='flex-end'
                      value={bonuses[1] / 100}
                      onValueChange={(value) =>
                        handleBonusChange(value * 100, 1)
                      }
                      iconSize={16}
                      incrementVal={5}
                      analyticsParentName='leaderboard'
                      analyticsName='bonus2'
                    >
                      <NumberFormatter
                        decimalScale={0}
                        fontVariant='h4'
                        maxLength={5}
                      />
                    </NumberInput>
                  ) : (
                    <StaticInput>
                      <Text variant='h4'>
                        {FormattingUtils.formatDollar(bonuses[1], 0)}
                      </Text>
                    </StaticInput>
                  )}
                </BonusBody>
              </div>
              <div>
                <IconContainer>
                  <LeaderboardIcons.GoldMedal width={47} height={52} />
                </IconContainer>
                <BonusBody height={pxToRem(213)}>
                  <Text
                    variant='h1'
                    textColor={palette.text.secondary}
                    marginBottom={pxToRem(55)}
                  >
                    1st
                  </Text>
                  {showEditUi ? (
                    <NumberInput
                      padding='0.5rem'
                      alignSelf='flex-end'
                      value={bonuses[0] / 100}
                      onValueChange={(value) =>
                        handleBonusChange(value * 100, 0)
                      }
                      iconSize={16}
                      incrementVal={5}
                      analyticsParentName='leaderboard'
                      analyticsName='bonus2'
                    >
                      <NumberFormatter
                        decimalScale={0}
                        fontVariant='h4'
                        maxLength={5}
                      />
                    </NumberInput>
                  ) : (
                    <StaticInput>
                      <Text variant='h4'>
                        {FormattingUtils.formatDollar(bonuses[0], 0)}
                      </Text>
                    </StaticInput>
                  )}
                </BonusBody>
              </div>
              <div>
                <IconContainer>
                  <LeaderboardIcons.BronzeMedal width={41} height={52} />
                </IconContainer>
                <BonusBody height={pxToRem(124)}>
                  <Text
                    variant='h2'
                    textColor={palette.text.secondary}
                    marginBottom='1rem'
                  >
                    3rd
                  </Text>
                  {showEditUi ? (
                    <NumberInput
                      padding='0.5rem'
                      alignSelf='flex-end'
                      value={bonuses[2] / 100}
                      onValueChange={(value) =>
                        handleBonusChange(value * 100, 2)
                      }
                      iconSize={16}
                      incrementVal={5}
                      analyticsParentName='leaderboard'
                      analyticsName='bonus2'
                    >
                      <NumberFormatter
                        decimalScale={0}
                        fontVariant='h4'
                        maxLength={5}
                      />
                    </NumberInput>
                  ) : (
                    <StaticInput>
                      <Text variant='h4'>
                        {FormattingUtils.formatDollar(bonuses[2], 0)}
                      </Text>
                    </StaticInput>
                  )}
                </BonusBody>
              </div>
            </Bonuses>
          </div>
        )}
      </ColumnCard>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ManageLeaderboardBonus.displayName = 'ManageLeaderboardBonus'
}

export default ManageLeaderboardBonus
