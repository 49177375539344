import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  variant,
} from 'styled-system'
import MultiSelectItem from './MultiSelectItem'

const Container = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps &
    PositionProps & {
      open: boolean
      coords: any
    }
>`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.dropdowns};
  box-shadow: ${(props) => props.theme.dropShadows.selected};
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.white};
  display: ${(props) => (props.open ? 'block' : 'none')};

  ${(props) =>
    variant({
      variants: {
        textual: {
          border: `1px solid ${props.theme.palette.grey.grey300}`,
          borderRadius: '0.5rem',
          top: props.theme.pxToRem(36),
        },
        header: {
          border: `1px solid ${props.theme.palette.grey.grey300}`,
          borderRadius: '0.5rem',
          top: props.theme.pxToRem(46),
        },
        contained: {
          border: `1px solid ${props.theme.palette.text.secondary}`,
          borderRadius: '0 0 0.5rem 0.5rem',
          top: props.theme.pxToRem(41),
        },
      },
    })}

  // if portal is used
  ${(props) => props.coords}

  ${flexbox}
  ${space}
  ${layout}
  ${position}
`

const handleClick = (
  item: MultiSelectItemProps,
  itemCallback: (item: MultiSelectItemProps) => void
) => {
  if (!item.disabled) {
    itemCallback(item)
  }
}

interface CoordsProps {
  top?: string
  left?: string
  right?: string
  bottom?: string
  width?: string
  height?: string
}

export interface MultiSelectItemProps {
  id: string
  text: string
  disabled?: boolean
  [key: string]: any
}

export interface MultiSelectProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    PositionProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  items: MultiSelectItemProps[]
  activeItems: MultiSelectItemProps[]
  open: boolean
  itemCallback: (item: MultiSelectItemProps) => void
  setIsOpen: (open: boolean) => void
  coords?: CoordsProps
  ref?: React.MutableRefObject<any>
}

const MultiSelect = React.memo((props: MultiSelectProps) => {
  const { items, activeItems, open, itemCallback, coords } = props

  return (
    <Container open={open} coords={coords} {...props}>
      {items.map((item: MultiSelectItemProps, idx: number) => (
        <MultiSelectItem
          key={idx}
          active={activeItems.findIndex((aItem) => aItem.id === item.id) !== -1}
          disabled={item.disabled}
          onClick={() => {
            handleClick(item, itemCallback)
          }}
          label={item.text}
        />
      ))}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MultiSelect.displayName = 'Multi Select'
}

export default MultiSelect
