import React from 'react'
import styled from 'styled-components'
import RadialChart from 'core-system/charts/Radial'
import { colorArray } from '../MapIntelligenceStyles'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import RadialChartUtils from 'core-system/charts/Radial/RadialChartUtils'

const chartOptions = {
  ...RadialChartUtils.defaultChartOptions,
  plugins: {
    ...RadialChartUtils.defaultChartOptions.plugins,
    legend: {
      display: false,
    },
  },
  cutout: '60%',
}

const MetricsContainer = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.text.primary};
  height: 100%;
  width: 100%;
  max-width: ${(props) => props.theme.pxToRem(550)};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2.5rem;
  justify-content: center;
`

const ChartLegend = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    ${(props) => props.theme.pxToRem(150)}
  );
  column-gap: 2rem;
  row-gap: 0.5rem;
`

const CustomLegendItem = styled.div`
  display: flex;
  align-items: center;
`

const CustomLegendColor = styled.div<{ background: string }>`
  background-color: ${(props) => props.background};
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin-right: 1rem;
`

const ModeText = styled(Text)`
  margin: 0.125rem 3rem 0 0;
  text-transform: capitalize;
`

const displayBarChartLegend = (
  colors: string[],
  labels: string[],
  data: number[]
) => {
  return labels.map((label: string, idx: number) => (
    <CustomLegendItem key={idx}>
      <CustomLegendColor background={colors[idx]} />
      <ModeText variant='action4'>{label}</ModeText>
      <Text variant='action4' marginLeft='auto'>
        {data[idx]}%
      </Text>
    </CustomLegendItem>
  ))
}

interface KeyMetricsModesProps {
  data: number[]
  labels: string[]
}

const KeyMetricsModes = React.memo((props: KeyMetricsModesProps) => {
  const { data, labels } = props
  const chartData = {
    datasets: [
      {
        data: data,
        backgroundColor: colorArray,
        borderWidth: 0,
        hoverBackgroundColor: colorArray,
      },
    ],
    labels: labels,
  }

  return (
    <>
      {data.length > 0 ? (
        <MetricsContainer>
          <RadialChart
            width={pxToRem(140)}
            height={pxToRem(140)}
            data={chartData}
            options={chartOptions}
          />
          <Content>
            <Text variant='h4' marginBottom='0.5rem'>
              Commute Modes
            </Text>
            <Text variant='body1' marginBottom='1.5rem'>
              Here&apos;s how your commuters are currently getting to work.
            </Text>
            <ChartLegend>
              {displayBarChartLegend(
                chartData.datasets[0].backgroundColor,
                chartData.labels,
                chartData.datasets[0].data
              )}
            </ChartLegend>
          </Content>
        </MetricsContainer>
      ) : (
        <Text variant='h4' textAlign='center' flex='1'>
          No Active Commuters
        </Text>
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  KeyMetricsModes.displayName = 'KeyMetricsModes'
}

export default KeyMetricsModes
