import React, { useState, cloneElement, Children } from 'react'
import { buildForwardingComponent } from '../utils/buildComponent'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'
import Text from '../Text'
const Content = styled.div<FlexboxProps & SpaceProps & LayoutProps>`
  width: 100%;
  border-radius: 0.9375rem;
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  color: ${(props) => props.theme.palette.text.secondary};
  margin-top: 0.75rem;
  font-family: PolySans;
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    box-shadow: unset;
    background-color: unset;
    border-radius: unset;
    border: none;
  }

  & > div:last-child {
    border-radius: 0 0 0.9375rem 0.9375rem;
  }

  ${flexbox}
  ${space}
  ${layout}
`

const LoadContainer = styled.div`
  height: ${(props) => props.theme.pxToRem(48)};
  background-color: ${(props) => props.theme.palette.primary.pink800};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.white};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink700};
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    border-radius: 0.9375rem !important;
  }
`

export interface TableProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  maxRows?: number
  hasHeader?: boolean
  hasLabels?: boolean
  loadMoreAnimation?: boolean
}

const Table = React.forwardRef<HTMLDivElement, TableProps>(
  (props: TableProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      children,
      maxRows = 5,
      hasHeader = true,
      hasLabels = true,
      loadMoreAnimation = true,
    } = props
    const [maxRowsState, setMaxRowsState] = useState(maxRows)

    const renderRows = () => {
      let activeChildCount = 0
      let tableRows
      let childrenOutput
      const childrenArr = Children.toArray(children)

      if (hasHeader && hasLabels) {
        tableRows = childrenArr.slice(2)
        childrenOutput = [...childrenArr.slice(0, 2)]
      } else if (hasHeader || hasLabels) {
        tableRows = childrenArr.slice(1)
        const headerOrlabel = cloneElement(children[0], {
          hasHeader,
          hasLabels,
          key: 'headerOrLabel',
        })
        childrenOutput = [headerOrlabel]
      } else {
        tableRows = childrenArr
        childrenOutput = []
      }

      if (tableRows && Array.isArray(tableRows)) {
        const maxRowsToDisplay =
          tableRows.length > maxRowsState ? maxRowsState : tableRows.length
        tableRows.forEach((child: any, idx: number) => {
          const animate = idx < maxRowsToDisplay ? true : false
          if (idx < maxRowsToDisplay) activeChildCount++
          if (idx < maxRowsToDisplay || loadMoreAnimation) {
            childrenOutput.push(cloneElement(child, { animate }))
          }
        })
      } else {
        childrenOutput.push(cloneElement(children[1], { animate: true }))
      }

      if (tableRows && activeChildCount < tableRows.length) {
        childrenOutput.push(
          <LoadContainer
            key={'loadMore'}
            onClick={() => setMaxRowsState(maxRowsState + maxRows)}
          >
            <Text variant='h6' color='white'>
              Load More
            </Text>
          </LoadContainer>
        )
      }

      return childrenOutput
    }

    return (
      <Content ref={ref} {...props}>
        {maxRows ? renderRows() : children}
      </Content>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  Table.displayName = 'Table'
}

export default buildForwardingComponent(Table)
