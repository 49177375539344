import Bugsnag from '@bugsnag/js'
import moment from 'moment'
import { AnyAction, MiddlewareAPI } from 'redux'
import SegmentService from 'redux/config/services/SegmentService'
import { employerActions } from 'redux/employer/employerSlice'
import { Worksite } from 'redux/employer/employerTypes'
import { userActions } from 'redux/user/userSlice'
import LocalStorage from './LocalStorage'

// Middleware
const segment =
  (store: MiddlewareAPI<any, any>) => (next: any) => (action: AnyAction) => {
    const { type, payload } = action
    try {
      switch (type) {
        case userActions.loginSuccess.type:
          SegmentService.track('user-login-success', {
            email: action.meta.email,
          })
          break
        case userActions.getUserSuccess.type: {
          const stayloggedIn = store.getState().user.stayLoggedIn
          const socialType = LocalStorage.getItem('socialLoginType')
          const isSocial = LocalStorage.getItem('isSocialLogin')

          SegmentService.identify(payload.id, {
            email: payload.email,
            employerDomain: payload.email.split('@')[1],
            role: payload.groups,
            stayloggedIn: stayloggedIn === null ? true : stayloggedIn,
            loginType: isSocial ? socialType : 'fleet',
          })

          Bugsnag.setUser(
            payload.id,
            payload.email,
            `${payload.firstName} ${payload.LastName}`
          )

          break
        }
        case employerActions.getWorksitesSuccess.type: {
          const user = store.getState().user.profile
          const userWorksite: Worksite = payload.filter(
            (worksite: Worksite) => worksite.id === user.id
          )

          break
        }
        case employerActions.getEmployerSuccess.type:
          SegmentService.group(payload.id, {
            id: payload.id,
            name: payload.name,
            website: payload.domain,
          })
      }
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        console.log(err)
      }
    }
    return next(action)
  }

export default segment
