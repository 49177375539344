import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import MedalsIcon from 'core-system/Icons/Leaderboards/AllMedals'
import BonusMedal from 'core-system/Icons/Leaderboards/BonusMedal'
import EnterArrowIcon from 'core-system/Icons/Misc/EnterArrow'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import LeaderboardUtils from 'features/Leaderboards/LeaderboardUtils'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import {
  Leaderboard,
  LeaderboardsGroup,
} from 'redux/leaderboards/leaderboardsTypes'
import RandomUtils from 'shared/RandomUtils'
import { Locations } from 'shared/Router/Locations'
import styled, { css } from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  margin-bottom: 1.5rem;
  position: relative;

  &:hover {
    .headerLink {
      opacity: 1;
    }
  }
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeaderLink = styled(Link)`
  padding: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  background-color: ${(props) => props.theme.palette.primary.pink200};
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  height: 2rem;
  width: 2rem;
  display: flex;
  transform: rotate(270deg);
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }

  &:active {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const ProgramRow = styled(FlexContainer)`
  padding: 0.5rem;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;
  justify-content: space-between;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const TextContainer = styled.div`
  margin-left: 1.75rem;
`

const IconContainer = styled.div<{ isPending: boolean }>`
  position: relative;
  padding: 0.25rem;
  display: flex;

  ${(props) =>
    props.isPending &&
    css`
      opacity: 0.65;
    `};
`

const BonusContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const renderLeaderboardIcons = (leaderboards: Leaderboard[]) => {
  return leaderboards.map((leaderboard) => {
    return (
      <IconContainer
        key={leaderboard.type}
        isPending={leaderboard.status === 'PENDING'}
      >
        {LeaderboardUtils.leaderboardData[leaderboard.type].icon}
        {leaderboard.bonuses.some((bonus) => bonus) && (
          <BonusContainer>
            <BonusMedal width={16} height={16} />
          </BonusContainer>
        )}
      </IconContainer>
    )
  })
}

const renderProgramRows = (
  leaderboardsMap: Dictionary<LeaderboardsGroup>,
  segmentsMap: Dictionary<Segment>,
  handleOnClick: (segmentId: string) => void
) => {
  return Object.values(leaderboardsMap).map((leaderboard) => {
    const activeSegment = segmentsMap[leaderboard.programs[0].segment]
    const activeLeaderboards = LeaderboardUtils.getActiveLeaderboards(
      leaderboard.programGroups
    )
    return (
      <React.Fragment key={activeSegment?.id}>
        <Divider margin='0.5rem 0' />
        <ProgramRow
          key={activeSegment?.id}
          onClick={() => handleOnClick(activeSegment?.id)}
        >
          <FlexContainer justifyContent='space-between' width='100%'>
            <FlexContainer alignItems='center'>
              <EnterArrowIcon
                width={17}
                height={17}
                color={palette.text.placeholder}
              />
              <TextContainer>
                <Text variant='action3'>{activeSegment?.name}</Text>
                <Text variant='body2' textColor={palette.text.placeholder}>
                  {leaderboard.active} Active Competition
                  {RandomUtils.pluralCheck(leaderboard.active)}
                </Text>
              </TextContainer>
            </FlexContainer>
            <FlexContainer>
              {renderLeaderboardIcons(activeLeaderboards)}
            </FlexContainer>
          </FlexContainer>
        </ProgramRow>
      </React.Fragment>
    )
  })
}

const DashboardIncentives = React.memo(() => {
  const navigate = useNavigate()

  const { leaderboardsMap, leaderboards } = useSelector(
    (state: AppState) => state.leaderboards
  )
  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  const activeLeaderboards = leaderboards?.filter(
    (leaderboard) => leaderboard.status === 'ACTIVE'
  ).length

  const handleOnClick = useCallback(
    (segmentId: string) => {
      navigate(`/leaderboards/${segmentId}`, {
        state: { from: Locations.Dashboard },
      })
    },
    [navigate]
  )

  return (
    <Container>
      <FlexContainer justifyContent='space-between' alignItems='center'>
        <div>
          <TitleContainer>
            <Text variant='h5' marginBottom='1rem'>
              Leaderboards
            </Text>
            <HeaderLink
              to={Locations.Leaderboards.Programs}
              className='headerLink'
            >
              <ChevronIcon />
            </HeaderLink>
          </TitleContainer>
          <Text variant='h3' marginBottom='0.5rem'>
            {activeLeaderboards} Competition
            {RandomUtils.pluralCheck(activeLeaderboards)}
          </Text>
          <Text
            variant='caption'
            textColor={palette.text.secondary}
            captionTitle
            paddingBottom='0.5rem'
          >
            actively running this month
          </Text>
        </div>
        <MedalsIcon width={136} height={76} />
      </FlexContainer>
      {leaderboardsMap && Object.keys(leaderboardsMap).length > 0 ? (
        renderProgramRows(leaderboardsMap, segmentsMap, handleOnClick)
      ) : (
        <>
          <Divider margin='1rem 0' />
          <FlexContainer center>
            <Button
              width='60%'
              size='small'
              marginTop='0.25rem'
              onClick={() => navigate('/leaderboards')}
            >
              Activate a Leaderboard
            </Button>
          </FlexContainer>
        </>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardIncentives.displayName = 'DashboardIncentives'
}

export default DashboardIncentives
