import React, { useState } from 'react'
import styled from 'styled-components'
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'core-system/Modal'
import Button from 'core-system/Button'
import Text from 'core-system/Text'
import Radio from 'core-system/Radio'
import { useDispatch } from 'react-redux'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import AlertIcon from 'core-system/Icons/Misc/Alert'
import pxToRem from 'core-system/utils/pxToRem'
import palette from 'core-system/Themes/palette'
import { GRHCopy, GRHReasons } from '../EmployeeGRHUtils'
import { ContentState, RawDraftContentState, convertToRaw } from 'draft-js'
import TextEditor from 'core-system/TextEditor'
import { rawDraftToString } from 'employee-platform/shared/utils'

const GRHDisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink500};
`

const GRHDisclaimerHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`

const ReasonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
`

interface GRHRequestModalProps {
  open: boolean
  closeModal: () => void
  programId: string | null
}

const GRHRequestModal = React.memo((props: GRHRequestModalProps) => {
  const { open, closeModal, programId } = props

  const dispatch = useDispatch()

  const [selectedReason, setSelectedReason] = useState(null)
  const [otherReason, setOtherReason] = useState<RawDraftContentState>(
    convertToRaw(ContentState.createFromText(''))
  )

  const handleCloseModal = () => {
    closeModal()
  }

  const handleRequestGRH = () => {
    dispatch(
      employeePlatformActions.createProgramRequest({
        programId: programId,
        reason:
          selectedReason === 'Other'
            ? `${selectedReason} - ${rawDraftToString(otherReason)}`
            : selectedReason,
      })
    )
    handleCloseModal()
  }

  return (
    <Modal open={open} onClose={() => handleCloseModal()}>
      <ModalHeader title='Request GRH' />
      <ModalBody>
        <GRHDisclaimerContainer>
          <GRHDisclaimerHeader>
            <AlertIcon
              height={pxToRem(20)}
              width={pxToRem(20)}
              color={palette.primary.pink400}
            />
            <Text variant='action3' textColor={palette.primary.pink400}>
              REMINDER
            </Text>
          </GRHDisclaimerHeader>
          <Text variant='action4'>{GRHCopy.reminder}</Text>
        </GRHDisclaimerContainer>
        <Text variant='action3' textColor={palette.text.secondary}>
          Select a Reason:
        </Text>
        {GRHReasons.map((reason) => {
          return (
            <ReasonContainer key={reason}>
              <Radio
                active={selectedReason === reason}
                onClick={() => setSelectedReason(reason)}
              />
              <Text variant='action4'>{reason}</Text>
            </ReasonContainer>
          )
        })}
        {selectedReason === 'Other' ? (
          <TextEditor
            label='Please specify:'
            contentState={otherReason}
            oncontentStateChange={(contentState) =>
              setOtherReason(contentState)
            }
            hideToolbar
            marginTop='0.5rem'
          />
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button variant='tertiary' onClick={() => handleCloseModal()}>
          Cancel
        </Button>
        <Button
          disabled={
            !selectedReason ||
            !programId ||
            (selectedReason === 'Other' && rawDraftToString(otherReason) === '')
          }
          onClick={() => handleRequestGRH()}
          marginLeft='1rem'
        >
          Submit Request
        </Button>
      </ModalFooter>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHRequestModal.displayName = 'GRHRequestModal'
}

export default GRHRequestModal
