import React from 'react'
import { tripModeTypes } from '../TripsUtils'
import styled, { css } from 'styled-components'
import Text from 'core-system/Text'
import { CardListContainer } from 'employee-platform/shared/styles/CardList'
import SearchIcon from 'core-system/Icons/Actions/Search'
import AddIcon from 'core-system/Icons/Actions/Add'
import CustomMuiTooltip from 'employee-platform/shared/components/CustomMuiTooltip'

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'row' : 'column')};
  gap: 0.5rem;
`

const ModeOption = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  padding: 0.5rem;
  ${(props) =>
    props.isMobile
      ? ''
      : css`
          width: 100%;
          justify-content: space-between;
        `}
`

const ModeOptionIconText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`

const ActionIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border-radius: 0.5rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink500};
  }
`

interface ModeTypeOptionsListProps {
  isMobile: boolean
  quickAddModeType: (modeType: string) => void
  onSelectServiceProvidersClick: (modeType: string) => void
}

const ModeTypeOptionsList = React.memo((props: ModeTypeOptionsListProps) => {
  const { isMobile, quickAddModeType, onSelectServiceProvidersClick } = props

  return (
    <CardListContainer maxHeight='15rem' horizontalScroll={isMobile}>
      <Container isMobile={isMobile}>
        {Object.values(tripModeTypes).map((modeType) => {
          return (
            <ModeOption
              key={modeType.baseId}
              isMobile={isMobile}
              onClick={() =>
                isMobile ? onSelectServiceProvidersClick(modeType.baseId) : null
              }
            >
              <ModeOptionIconText>
                {modeType.icon}
                <Text variant='action3'>{modeType.name}</Text>
              </ModeOptionIconText>
              {!isMobile ? (
                <ActionIcons>
                  {modeType.hasProviders ? (
                    <CustomMuiTooltip title='Select Service Providers' arrow>
                      <IconContainer
                        onClick={() =>
                          onSelectServiceProvidersClick(modeType.baseId)
                        }
                      >
                        <SearchIcon />
                      </IconContainer>
                    </CustomMuiTooltip>
                  ) : null}

                  <CustomMuiTooltip title='Quick Add' arrow>
                    <IconContainer
                      onClick={() => quickAddModeType(modeType.baseId)}
                    >
                      <AddIcon />
                    </IconContainer>
                  </CustomMuiTooltip>
                </ActionIcons>
              ) : null}
            </ModeOption>
          )
        })}
      </Container>
    </CardListContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ModeTypeOptionsList.displayName = 'ModeTypeOptionsList'
}

export default ModeTypeOptionsList
