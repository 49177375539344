import Button, { TextButton } from 'core-system/Button'
import Checkbox from 'core-system/Checkbox'
import Chip from 'core-system/Chip'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import SearchInput from 'core-system/SearchInput'
import Text from 'core-system/Text'
import TransitLogo from 'core-system/TransitLogo'
import pxToRem from 'core-system/utils/pxToRem'
import ModeTypesSection from 'features/ProviderCatalog/ProviderCatalogDetailModal/ModeTypesSection'
import ProviderPartnerBenefits from 'features/ProviderCatalog/ProviderCatalogDetailModal/ProviderPartnerBenefits'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { Merchant, ServiceModeType } from 'redux/programs/programsTypes'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import ServiceProviderCard from './ServiceProviderCard'

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

const FilterContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    pointer-events: none;
  }
`

const ServiceProviders = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.theme.pxToRem(200)}, 1fr)
  );
  gap: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  grid-auto-flow: row;
  height: ${(props) => props.theme.pxToRem(300)};
  overflow: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.25rem;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 1rem;
    opacity: 0.8;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    opacity: 1;
  }
`

const StyledLink = styled.a`
  cursor: pointer;
  font-weight: normal;
  line-height: normal;
  color: ${(props) => props.theme.palette.primary.pink200};

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const ProviderDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  max-height: ${(props) => props.theme.pxToRem(200)};
  overflow-y: auto;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const getUniqueModeTypes = (
  merchantIds: string[],
  merchantsMap: Dictionary<Merchant>
) => {
  const modeTypes = merchantIds.flatMap(
    (id) => merchantsMap[id]?.modeTypes || []
  )
  return [...Array.from(new Set(modeTypes))]
}

interface ServiceProviderSelectorModalProps {
  open: boolean
  title: string
  activeProviders: string[]
  availableProviders: string[]
  closeModal: () => void
  onSave?: (newActiveProviders: string[]) => void
  description?: string
  canEdit?: boolean
  modeTypeFilters?: boolean
  customData?: Dictionary
  serviceCardHeight?: number
}

const ServiceProviderSelectorModal = React.memo(
  (props: ServiceProviderSelectorModalProps) => {
    const {
      open,
      title,
      activeProviders,
      availableProviders,
      closeModal,
      onSave,
      description,
      canEdit = true,
      modeTypeFilters,
      customData,
      serviceCardHeight,
    } = props

    const merchantsMap = useSelector(
      (state: AppState) => state.programs.merchantsMap
    )

    const [selectAll, setSelectAll] = useState(true)
    const [searchVal, setSearchVal] = useState('')
    const [activeModeTypeFilters, setActiveModeTypeFilters] = useState([])
    const [internalActiveProviders, setInternalActiveProviders] =
      useState(activeProviders)
    const [selectedMerchant, setSelectedMerchant] = useState(null)

    useEffect(() => {
      if (open) {
        setInternalActiveProviders(activeProviders)
        setSelectAll(activeProviders.length === availableProviders.length)
      }
    }, [open, activeProviders, availableProviders])

    const handleSearchValueChange = (
      evt: React.ChangeEvent<HTMLInputElement>
    ) => {
      setSearchVal(evt.target.value)
    }

    const handleActiveToggle = (provider: string, isActive: boolean) => {
      const providerCopy = [...internalActiveProviders]
      if (!isActive) {
        const idx = providerCopy.indexOf(provider)
        providerCopy.splice(idx, 1)
      } else {
        providerCopy.push(provider)
      }
      setInternalActiveProviders(providerCopy)

      if (providerCopy.length === availableProviders.length) {
        setSelectAll(true)
      } else if (
        providerCopy.length !== availableProviders.length &&
        selectAll
      ) {
        setSelectAll(false)
      }
    }

    const getServiceProviders = (
      activeProviders: string[],
      availableProviders: string[],
      customData: Dictionary,
      serviceCardHeight: number
    ) => {
      return availableProviders
        .sort((a, b) => {
          if (customData) {
            if (customData[a].spend < customData[b].spend) return 1
            if (customData[a].spend > customData[b].spend) return -1
            if (customData[a].trips < customData[b].trips) return 1
            if (customData[a].trips > customData[b].trips) return -1
          }
          return 0
        })
        .filter((merchantId) => {
          const merchant = merchantsMap[merchantId]
          const filter = searchVal.toLowerCase()
          const isEmpty = filter.trim() === ''

          const hasMatchingModes = merchant?.modeTypes.some((modeType) =>
            modeType.toLowerCase().includes(filter)
          )
          const allEmpty = isEmpty && activeModeTypeFilters.length === 0 && true
          const hasFilterText =
            filter.trim() !== '' &&
            (hasMatchingModes ||
              merchant.name.toLowerCase().replace(/\s+/g, '').includes(filter))
          const hasModeTypeFilters =
            activeModeTypeFilters.length > 0 &&
            merchant.modeTypes.some((modeType) =>
              activeModeTypeFilters.includes(modeType)
            )

          return (
            allEmpty ||
            (hasModeTypeFilters && isEmpty) ||
            (hasFilterText && activeModeTypeFilters.length === 0) ||
            (hasFilterText && hasModeTypeFilters)
          )
        })
        .map((merchantId, idx) => {
          const merchant = merchantsMap[merchantId]
          return (
            <ServiceProviderCard
              key={idx}
              active={activeProviders.includes(merchantId)}
              merchant={merchant}
              handleActiveToggle={handleActiveToggle}
              canEdit={canEdit}
              height={serviceCardHeight && serviceCardHeight}
              selectedMerchant={selectedMerchant}
              setSelectedMerchant={setSelectedMerchant}
            >
              {customData && customData[merchantId] && (
                <React.Fragment>
                  <Divider margin='1rem 0' />
                  {Object.keys(customData[merchantId]).map((key, idx) => (
                    <FlexContainer
                      key={idx}
                      marginTop='0.25rem'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Text variant='caption' captionTitle>
                        {key}
                      </Text>
                      <Text variant='action4'>
                        {customData[merchantId][key]}
                      </Text>
                    </FlexContainer>
                  ))}
                </React.Fragment>
              )}
            </ServiceProviderCard>
          )
        })
    }

    const handleSave = () => {
      onSave(internalActiveProviders)
      setSearchVal('')
      setSelectedMerchant('')
      closeModal()
    }

    const handleClose = () => {
      setSearchVal('')
      setSelectedMerchant('')
      closeModal()
    }

    const handleSelectAll = () => {
      setSelectAll(!selectAll)
      if (selectAll) {
        setInternalActiveProviders([])
      } else {
        setInternalActiveProviders(availableProviders)
      }
    }

    const handleModeTypeFilterClick = (modeType: string) => {
      const idx = activeModeTypeFilters.indexOf(modeType)
      const stateCopy = [...activeModeTypeFilters]
      if (idx === -1) {
        setActiveModeTypeFilters([...stateCopy, modeType])
      } else {
        stateCopy.splice(idx, 1)
        setActiveModeTypeFilters(stateCopy)
      }
    }

    const getSelectedProviderSummarySection = () => {
      if (!selectedMerchant) return null

      return (
        <FlexContainer flexDirection='column' style={{ gap: '1rem' }}>
          <FlexContainer
            alignItems='center'
            paddingRight='2rem'
            style={{ gap: '1rem' }}
          >
            <TransitLogo
              modeType={
                selectedMerchant.iconUrl
                  ? 'custom'
                  : (selectedMerchant.modeTypes[0] as ServiceModeType)
              }
              src={selectedMerchant.iconUrl || selectedMerchant.modeTypes[0]}
              size='large'
              style={{ minWidth: '3rem' }}
            />
            <FlexContainer
              flexDirection='column'
              justifyContent='center'
              overflow='hidden'
            >
              <Text
                variant='h5'
                marginBottom='0.1875rem'
                capitalize
                hideOverflow
              >
                {selectedMerchant.name.toLowerCase()}
              </Text>
              <Text variant='body1'>
                <StyledLink
                  href={`${Locations.ProviderCatalog}?id=${selectedMerchant.id}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  View Full Details
                </StyledLink>
              </Text>
            </FlexContainer>
          </FlexContainer>
          {(selectedMerchant.description ||
            selectedMerchant.partnershipDescription) && (
            <ProviderDescriptionSection>
              {selectedMerchant.description && (
                <Text variant='body1'>{selectedMerchant.description}</Text>
              )}
              <ProviderPartnerBenefits merchant={selectedMerchant} />
            </ProviderDescriptionSection>
          )}
        </FlexContainer>
      )
    }

    const getSelectedProviderModeTypesSection = () => {
      if (!selectedMerchant) return null

      return <ModeTypesSection merchant={selectedMerchant} variant='column' />
    }

    return (
      <Modal
        open={open}
        onClose={handleClose}
        width={pxToRem(944)}
        sidebarPrimaryChildren={getSelectedProviderSummarySection()}
        sidebarSecondaryChildren={getSelectedProviderModeTypesSection()}
        sidebarClose={() => setSelectedMerchant(null)}
      >
        <ModalHeader title={title} />
        <ModalBody>
          {description && <Text variant='action4'>{description}</Text>}
          <SearchInput
            barBg='white'
            variant='small'
            placeholder='Search...'
            value={searchVal}
            onsearch={() => null}
            onclearSearch={() => setSearchVal('')}
            onChange={handleSearchValueChange}
          />
          {modeTypeFilters && (
            <FlexContainer margin='0.625rem 0 1rem' flexWrap='wrap'>
              {getUniqueModeTypes(availableProviders, merchantsMap).map(
                (modeType) => {
                  return (
                    <Chip
                      variant='filter'
                      key={modeType}
                      margin='0.375rem 0.375rem 0 0'
                      onClick={() => handleModeTypeFilterClick(modeType)}
                      selected={activeModeTypeFilters.includes(modeType)}
                    >
                      {ProgramUtils.formatElectricModeTypes(
                        modeType
                      ).toLowerCase()}
                    </Chip>
                  )
                }
              )}
            </FlexContainer>
          )}
          {canEdit && (
            <FilterContainer>
              <Checkbox
                checked={selectAll}
                onClick={handleSelectAll}
                label='Select All'
              />
              <TextButton active>
                {internalActiveProviders.length} of {availableProviders.length}{' '}
                Services Selected
              </TextButton>
            </FilterContainer>
          )}
          <Divider marginY='1.5rem' />
          <ServiceProviders data-cy='service-modal-providers'>
            {getServiceProviders(
              internalActiveProviders,
              availableProviders,
              customData,
              serviceCardHeight
            )}
          </ServiceProviders>
        </ModalBody>
        <ModalFooter>
          <ButtonContainer>
            <Button variant='tertiary' onClick={handleClose}>
              Cancel
            </Button>
            {canEdit && (
              <Button marginLeft='1rem' onClick={handleSave}>
                Save
              </Button>
            )}
          </ButtonContainer>
        </ModalFooter>
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ServiceProviderSelectorModal.displayName = 'ServiceProviderSelectorModal'
}

export default ServiceProviderSelectorModal
