import { notificationsActions } from 'redux/notifications/notificationsSlice'
import Card, { CardRow } from 'core-system/Card'
import Dropdown, { SelectItemProps } from 'core-system/Dropdown'
import LinkIcon from 'core-system/Icons/Actions/Link'
import OverflowIcon from 'core-system/Icons/Misc/Overflow'
import Table, {
  TableHeader,
  TableHeaderItem,
  TableLabelRow,
  TableRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import ToggleWorksiteInviteCodeModal from 'features/Accounts/Users/ToggleWorksiteInviteCodeModal'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SegmentService from 'redux/config/services/SegmentService'
import { Worksite } from 'redux/employer/employerTypes'
import useResizeListener from 'shared/Hooks/useResizeListener'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'
import { worksiteIcons } from '../AccountsUtils'
import ArchiveWorksiteModal from './ArchiveWorksiteModal'
import UnarchiveWorksiteModal from './UnarchiveWorksiteModal'
import EditWorksiteModal from './EditWorksiteModal'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import moment from 'moment'

const WorksiteContainer = styled.div`
  display: flex;
  align-items: center;
`

const WorksiteSvg = styled.div`
  margin-right: 1.5rem;
  height: ${(props) => props.theme.pxToRem(40)};

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-top: 0.25rem;
  }
`

const WorksiteInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Alias = styled.div`
  ${(props) => props.theme.typography.action4}
  color: ${(props) => props.theme.palette.text.primary};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const WorksiteAddress = styled.div`
  ${(props) => props.theme.typography.body2}
  color: ${(props) => props.theme.palette.text.placeholder};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const WorksiteRowCell = styled(TableRowCell)`
  min-width: ${(props) => props.theme.pxToRem(200)};
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-bottom: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey300};
    margin-right: 0;
  }
`

const HeaderSpan = styled.span<{ active: boolean }>`
  color: ${(props) =>
    props.active
      ? props.theme.palette.white
      : props.theme.palette.primary.pink400};
  margin-left: 0.5rem;
`

const InviteContainer = styled.div`
  height: 2.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0 0.4375rem 0 0.25rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink500};
  }
`

const IconContainer = styled.div`
  min-width: 1.5rem;
  display: flex;
  align-items: center;
`

type CityCountType = {
  [key: string]: number
}

const getTableHeaders = (
  allWorksites: Worksite[],
  activeHeader: string,
  updateActiveHeader: (city: string) => void
) => {
  const cityCount: CityCountType = allWorksites.reduce(
    (obj, worksite: Worksite) => {
      if (worksite.archived) {
        obj['archived'] = ++obj['archived'] || 1
      } else {
        obj[worksite.city] = ++obj[worksite.city] || 1
      }
      return obj
    },
    {}
  )

  const sortedCity = Object.keys(cityCount)
    .filter((key) => key !== 'archived')
    .sort((a, b) => cityCount[b] - cityCount[a])
  cityCount['all'] = allWorksites.filter((wrk) => !wrk.archived).length
  sortedCity.unshift('all')

  //check if archived worksites exist
  if (cityCount['archived'] && cityCount['archived'] > 0) {
    sortedCity.push('archived')
  }

  return sortedCity.map((city: string, idx: number) => {
    return (
      <TableHeaderItem
        onClick={() => updateActiveHeader(city)}
        key={idx}
        active={activeHeader === city}
        disabled={cityCount[city] === 0}
      >
        {city}
        <HeaderSpan active={activeHeader === city}>
          {cityCount[city]}
        </HeaderSpan>
      </TableHeaderItem>
    )
  })
}

const handleLinkClick = (
  e: React.MouseEvent<HTMLDivElement>,
  dispatch: any,
  worksite: Worksite,
  setSelectedWorksite: (worksite: Worksite) => void,
  setIsInviteModalOpen: (open: boolean) => void
) => {
  e.stopPropagation()
  if (worksite.inviteCode) {
    RandomUtils.copyInviteCode(worksite.address, worksite.inviteCode)
    dispatch(
      notificationsActions.generalPageInfo({
        title: 'Copied!',
        msg: 'Successfully coped to clipboard',
      })
    )
    SegmentService.track('openInvite-link-copy', {
      worksiteName: worksite.alias,
    })
  } else {
    setSelectedWorksite(worksite)
    setIsInviteModalOpen(true)
  }
}

const getTableRows = (
  allWorksites: Worksite[],
  activeHeader: string,
  menuCallback: (item: SelectItemProps) => void,
  isTablet: boolean,
  dispatch: any,
  setSelectedWorksite: (worksite: Worksite) => void,
  setIsInviteModalOpen: (open: boolean) => void,
  worksitesWithFinchEmployees: Set<string>
) => {
  return allWorksites
    .filter(
      (a) =>
        (activeHeader === 'all' && !a.archived) ||
        (a.city === activeHeader && !a.archived) ||
        (a.archived && activeHeader === 'archived')
    )
    .map((worksite: Worksite, idx: number) => {
      const selectItems =
        activeHeader !== 'archived'
          ? [
              { id: '0', text: 'Edit Worksite Info', worksite: worksite },
              {
                id: '1',
                text: 'Disable Invite Code',
                worksite: worksite,
                disabled: !worksite.inviteCode && true,
              },
              {
                id: '2',
                text: 'Archive Worksite',
                worksite,
                disabled:
                  worksite.finchWorksite !== null ||
                  worksitesWithFinchEmployees.has(worksite.id),
              },
            ]
          : [
              {
                id: '3',
                text: 'Unarchive Worksite',
                worksite,
                disabled: false,
              },
            ]
      return (
        <TableRow key={idx}>
          <TableRowContents
            gridTemplateColumns={'1fr 1fr 3rem'}
            gridTemplateRows={'1fr 3rem'}
            data-cy={`accounts-worksite-table-row-${worksite.alias.replace(
              ' ',
              ''
            )}`}
          >
            <WorksiteRowCell gridColumn={'1/4'} label={isTablet && 'Worksite'}>
              <WorksiteContainer>
                <WorksiteSvg>{worksiteIcons[worksite.iconId]}</WorksiteSvg>
                <WorksiteInfo>
                  <Alias>{worksite.alias}</Alias>
                  <WorksiteAddress>
                    {worksite.formattedAddress.split(',')[0]}
                  </WorksiteAddress>
                </WorksiteInfo>
              </WorksiteContainer>
            </WorksiteRowCell>
            <TableRowCell
              minWidth={pxToRem(125)}
              icon={'location'}
              label={isTablet && 'City'}
              flex={'0.75'}
            >
              {worksite.city}
            </TableRowCell>
            <TableRowCell
              minWidth={pxToRem(75)}
              icon={'employee'}
              label={isTablet && 'Commuters'}
              flex={'0.5'}
            >
              {worksite.archived
                ? // This is a band-aid fix: when archiving a worksite with employees in staging, the archived employee count isn't getting updated
                  // likely being caused by the get request happening before the bulk_archive_employees task is finished
                  worksite.employeeArchivedCount + worksite.employeeCount
                : worksite.employeeCount}
            </TableRowCell>
            <TableRowCell
              minWidth={pxToRem(32)}
              label={isTablet && 'Invite'}
              flex={'0.5'}
              icon={activeHeader === 'archived' ? 'time' : null}
            >
              {activeHeader === 'archived' ? (
                <> {moment(worksite.archiveDate).format('MMMM Do, YYYY')} </>
              ) : (
                <>
                  <InviteContainer
                    onClick={(e) =>
                      handleLinkClick(
                        e,
                        dispatch,
                        worksite,
                        setSelectedWorksite,
                        setIsInviteModalOpen
                      )
                    }
                  >
                    <IconContainer>
                      <LinkIcon color={palette.text.secondary} />
                    </IconContainer>
                    <Text
                      variant='action4'
                      textColor={palette.text.secondary}
                      marginLeft='0.375rem'
                      hideOverflow
                      data-cy='accounts-worksite-table-invite-code'
                    >
                      {worksite.inviteCode ? 'Copy Link' : 'Setup Invite Code'}
                    </Text>
                  </InviteContainer>
                </>
              )}
            </TableRowCell>
            <TableRowCell equalSize={false} marginRight={'0.5rem'}>
              <Dropdown
                variant={'textual'}
                svgDropdown={true}
                icon={<OverflowIcon />}
                active={null}
                items={selectItems}
                itemCallback={menuCallback}
                width={pxToRem(180)}
                top={'2.75rem'}
                right={0}
                data-cy='accounts-worksite-table-dropdown'
              />
            </TableRowCell>
          </TableRowContents>
        </TableRow>
      )
    })
}

const getCards = (
  allWorksites: Worksite[],
  activeHeader: string,
  menuCallback: (item: SelectItemProps) => void
) => {
  return allWorksites
    .filter((a) => activeHeader === 'all' || a.city === activeHeader)
    .map((worksite: Worksite, idx: number) => {
      const callbackItem = {
        id: '0',
        text: 'activeWorksite',
        worksite: worksite,
      }
      return (
        <Card
          padding={'0.5rem 0.25rem 1.5rem'}
          key={idx}
          onClick={() => menuCallback(callbackItem)}
        >
          <CardRow label={'Worksite'}>
            <WorksiteContainer>
              <WorksiteSvg>{worksiteIcons[worksite.iconId]}</WorksiteSvg>
              <WorksiteInfo>
                <Alias>{worksite.alias}</Alias>
                <WorksiteAddress>
                  {worksite.formattedAddress.split(',')[0]}
                </WorksiteAddress>
              </WorksiteInfo>
            </WorksiteContainer>
          </CardRow>
          <CardRow label={'City'} icon={'location'}>
            {worksite.city}
          </CardRow>
          <CardRow label={'Commuters'} icon={'employee'}>
            {worksite.archived
              ? // This is a band-aid fix: when archiving a worksite with employees in staging, the archived employee count isn't getting updated
                // likely being caused by the get request happening before the bulk_archive_employees task is finished
                worksite.employeeArchivedCount + worksite.employeeCount
              : worksite.employeeCount}
          </CardRow>
        </Card>
      )
    })
}

interface WorksiteTableProps {
  allWorksites: Worksite[] | null
}

const WorksiteTable = React.memo((props: WorksiteTableProps) => {
  const { allWorksites } = props

  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [selectedWorksite, setSelectedWorksite] = React.useState<Worksite>(null)
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 768)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576)
  const [activeHeader, setActiveHeader] = useState('all')
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const [isArchiveWorksiteModalOpen, setIsArchiveWorksiteModalOpen] =
    useState(false)
  const [isUnarchiveWorksiteModalOpen, setIsUnarchiveWorksiteModalOpen] =
    useState(false)

  const worksitesWithFinchEmployees = new Set(
    useSelector((state: AppState) => state.employee.allCommuters)
      .filter((commuter) => commuter.finchIndividualId !== null)
      .map((commuter) => commuter.worksiteId)
  )

  const handleResize = useCallback(
    (width: number) => {
      setIsTablet(width <= 768)
      setIsMobile(width <= 576)
    },
    [setIsTablet, setIsMobile]
  )

  useResizeListener(() => handleResize(window.innerWidth), [])

  const handleHeaderClick = (filter: string) => {
    if (filter !== activeHeader) {
      setActiveHeader(filter)

      SegmentService.track('table-view-filter', {
        tableType: 'worksites',
        location: filter,
      })
    }
  }

  const closeModal = useCallback(() => {
    setModalOpen(false)
    SegmentService.track('table-item-click', {
      tableType: 'worksites',
      action: 'close',
    })
  }, [])

  const menuCallback = (item: SelectItemProps) => {
    setSelectedWorksite(item.worksite)
    if (item.id === '0') {
      setModalOpen(true)
      SegmentService.track('table-item-click', {
        tableType: 'worksites',
        action: 'open',
        worksiteName: item.worksite && item.worksite.alias,
      })
    } else if (item.id === '1' && !item.disabled) {
      setIsInviteModalOpen(true)
    } else if (item.id === '2') {
      setIsArchiveWorksiteModalOpen(true)
    } else if (item.id === '3') {
      setIsUnarchiveWorksiteModalOpen(true)
    }
  }

  // If the user unarchives a worksite and it results in there being no more archived worksites, we switch the active header
  // to 'all' to avoid an empty table
  useEffect(() => {
    if (
      activeHeader === 'archived' &&
      allWorksites.filter((worksite) => worksite.archived).length === 0
    ) {
      setActiveHeader('all')
    }
  }, [activeHeader, allWorksites])

  return (
    <>
      {!isMobile ? (
        <Table>
          <TableHeader>
            {getTableHeaders(allWorksites, activeHeader, handleHeaderClick)}
          </TableHeader>
          <TableLabelRow>
            <TableRowCell minWidth={pxToRem(200)} fontType={'body2'}>
              Worksite
            </TableRowCell>
            <TableRowCell
              minWidth={pxToRem(125)}
              flex={'0.75'}
              fontType={'body2'}
            >
              City
            </TableRowCell>
            <TableRowCell
              minWidth={pxToRem(75)}
              flex={'0.5'}
              fontType={'body2'}
            >
              Signed Up Commuters
            </TableRowCell>
            <TableRowCell
              minWidth={pxToRem(75)}
              flex={'0.5'}
              fontType={'body2'}
            >
              {activeHeader === 'archived'
                ? 'Archive Date'
                : 'Invite Commuters'}
            </TableRowCell>
            <TableRowCell
              equalSize={false}
              width={'2.5rem'}
              marginRight={'0.5rem'}
            />
          </TableLabelRow>
          {getTableRows(
            allWorksites,
            activeHeader,
            menuCallback,
            isTablet,
            dispatch,
            setSelectedWorksite,
            setIsInviteModalOpen,
            worksitesWithFinchEmployees
          )}
        </Table>
      ) : (
        <>
          <TableHeader>
            {getTableHeaders(allWorksites, activeHeader, handleHeaderClick)}
          </TableHeader>
          {getCards(allWorksites, activeHeader, menuCallback)}
        </>
      )}
      {selectedWorksite && (
        <>
          <EditWorksiteModal
            worksite={selectedWorksite}
            open={modalOpen}
            closeModal={closeModal}
          />
          <ToggleWorksiteInviteCodeModal
            worksite={selectedWorksite}
            open={isInviteModalOpen}
            closeModal={() => setIsInviteModalOpen(false)}
          />
          <ArchiveWorksiteModal
            worksite={selectedWorksite}
            open={isArchiveWorksiteModalOpen}
            closeModal={() => setIsArchiveWorksiteModalOpen(false)}
          />
          <UnarchiveWorksiteModal
            worksite={selectedWorksite}
            open={isUnarchiveWorksiteModalOpen}
            closeModal={() => setIsUnarchiveWorksiteModalOpen(false)}
          />
        </>
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  WorksiteTable.displayName = 'WorksiteTable'
}

export default WorksiteTable
