import Card, { CardRow } from 'core-system/Card'
import Loading from 'core-system/Loading'
import Table, {
  TableLabelRow,
  TableRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { FileUploadHistory } from 'redux/employer/employerTypes'
import useResizeListener from 'shared/Hooks/useResizeListener'
import styled from 'styled-components'

const UploaderRowCell = styled(TableRowCell)`
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-bottom: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey300};
    margin-right: 0;
  }
`

const SectionTitle = styled.div`
  ${(props) => props.theme.typography.h4}
  color: ${(props) => props.theme.palette.text.primary};
  padding-bottom: 0.75rem;
`

const EmptyTableRow = styled(TableRow)`
  text-align: center;
`

const getTableRows = (
  uploadHistory: FileUploadHistory[],
  isTablet: boolean
) => {
  return uploadHistory.map((upload: FileUploadHistory, idx: number) => {
    const date = moment(upload.createdAt).format('MMM Do, YYYY hh:mm a')
    return (
      <TableRow key={idx}>
        <TableRowContents padding={'0.75rem 0.5rem'}>
          <UploaderRowCell
            textColor={palette.text.primary}
            gridColumn={'1/4'}
            minWidth={pxToRem(230)}
            label={isTablet && 'Uploader'}
          >
            {upload.uploader}
          </UploaderRowCell>
          <TableRowCell
            minWidth={pxToRem(140)}
            label={isTablet && 'Date Uploaded'}
          >
            {date}
          </TableRowCell>
          <TableRowCell label={isTablet && 'File Name'}>
            {upload.filename}
          </TableRowCell>
        </TableRowContents>
      </TableRow>
    )
  })
}

const getCards = (uploadHistory: FileUploadHistory[]) => {
  return uploadHistory.map((upload: FileUploadHistory, idx: number) => {
    const date = moment(upload.createdAt).format('MMM Do, YYYY hh:mm a')
    return (
      <Card key={idx} padding={'0 0.25rem 1rem'}>
        <CardRow textColor={palette.text.primary} label={'Uploader'}>
          {upload.uploader}
        </CardRow>
        <CardRow label={'Date Uploaded'}>{date}</CardRow>
        <CardRow label={'File Name'}>{upload.filename}</CardRow>
      </Card>
    )
  })
}

const UploadHistoryView = React.memo(() => {
  const fileUploadHistory = useSelector(
    (state: AppState) => state.employer.fileUploadHistory
  )

  const [isTablet, setIsTablet] = useState(window.innerWidth <= 768)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576)

  const handleResize = useCallback(
    (width: number) => {
      setIsTablet(width <= 768)
      setIsMobile(width <= 576)
    },
    [setIsTablet, setIsMobile]
  )

  useResizeListener(() => handleResize(window.innerWidth), [])

  if (!fileUploadHistory) {
    return <Loading />
  }

  return (
    <>
      <SectionTitle>Upload History</SectionTitle>
      {!isMobile ? (
        <Table hasHeader={false}>
          <TableLabelRow padding={`${pxToRem(22)} 1.5rem`}>
            <TableRowCell fontType={'action4'} minWidth={pxToRem(230)}>
              Uploader
            </TableRowCell>
            <TableRowCell fontType={'action4'} minWidth={pxToRem(140)}>
              Date Uploaded
            </TableRowCell>
            <TableRowCell fontType={'action4'}>File Name</TableRowCell>
          </TableLabelRow>
          {fileUploadHistory.length > 0 ? (
            getTableRows(fileUploadHistory, isTablet)
          ) : (
            <EmptyTableRow>No Files Uploaded</EmptyTableRow>
          )}
        </Table>
      ) : (
        getCards(fileUploadHistory)
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  UploadHistoryView.displayName = 'UploadHistoryView'
}

export default UploadHistoryView
