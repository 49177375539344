import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

import { buildComponent } from '../utils/buildComponent'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${layout}
  ${space}
  ${flexbox}
`

const RadioIndicator = styled.div`
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.primary.pink500};
`

const RadioButton = styled.div<{ active: boolean; disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  border: 0.0625rem solid
    ${(props) =>
      props.active
        ? props.theme.palette.primary.pink500
        : props.theme.palette.text.primary};

  & > ${RadioIndicator} {
    display: ${(props) => (props.active ? 'block' : 'none')};
  }

  &:hover {
    border-color: ${(props) =>
      props.disabled ? 'none' : props.theme.palette.primary.pink500};
    cursor: ${(props) => (props.disabled ? 'cursor' : 'pointer')};

    & > ${RadioIndicator} {
      display: ${(props) =>
        props.disabled && props.active
          ? 'block'
          : props.disabled
            ? 'none'
            : 'block'};
      opacity: ${(props) => (props.active ? 1 : 0.3)};
    }
  }
`

const Label = styled.p`
  margin: 0.125rem 0 0 0.625rem;
  font-size: 0.875rem;
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.primary.pink500};
`

const LabelContainer = styled.div`
  margin: 0 0 0 0.625rem;
`

interface RadioProps extends SpaceProps, LayoutProps, FlexboxProps {
  active: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  label?: string | React.ReactNode
  id?: string
  disabled?: boolean
}

const RadioV2 = (props: RadioProps) => {
  const { active, onClick, id, label, disabled, ...rest } = props
  return (
    <Container {...rest}>
      <RadioButton
        id={id}
        active={active}
        disabled={disabled}
        onClick={onClick}
      >
        <RadioIndicator id='radioIndicator' />
      </RadioButton>
      {label && typeof label === 'string' && <Label>{label}</Label>}
      {label && typeof label !== 'string' && (
        <LabelContainer>{label}</LabelContainer>
      )}
    </Container>
  )
}

export default buildComponent(RadioV2, 'RadioV2')
