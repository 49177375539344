import CSSProperties from '../@types/CSSProperties'
import pxToRem from '../utils/pxToRem'
import { Fonts } from '../Fonts'

export interface Font extends CSSProperties {
  fontFamily: string
  fontWeight: number
  fontSize: string
  lineHeight: number | string
  textDecoration?: string
  cursor?: string
}

export interface Typography {
  BaseFont: Font
  h1: Font
  h2: Font
  h3: Font
  h4: Font
  h5: Font
  body1: Font
  body2: Font
  action1: Font
  action2: Font
  action3: Font
  action4: Font
  action5: Font
  action6: Font
  caption: Font
  hyperlink: Font
}

const typography: Typography = {
  BaseFont: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Regular,
    fontSize: '100%',
    lineHeight: 1.5,
  },
  h1: {
    fontFamily: Fonts.PolySans.Name,
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: Fonts.PolySans.Weights.Bold,
    lineHeight: '2.5rem',
    letterSpacing: '0.02rem',
  },
  h2: {
    fontFamily: Fonts.PolySans.Name,
    fontSize: '1.75rem',
    fontStyle: 'normal',
    fontWeight: Fonts.PolySans.Weights.Bold,
    lineHeight: '2.25rem',
    letterSpacing: '0.0175rem',
  },
  h3: {
    fontFamily: Fonts.PolySans.Name,
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: Fonts.PolySans.Weights.Bold,
    lineHeight: '2rem',
    letterSpacing: '0.015rem',
  },
  h4: {
    fontFamily: Fonts.PolySans.Name,
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: Fonts.PolySans.Weights.Bold,
    lineHeight: '1.5rem',
    letterSpacing: '0.0125rem',
  },
  h5: {
    fontFamily: Fonts.PolySans.Name,
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: Fonts.PolySans.Weights.Bold,
    lineHeight: '1.25rem',
    letterSpacing: '0.01rem',
  },
  body1: {
    fontFamily: Fonts.PolySans.Name,
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: Fonts.PolySans.Weights.Regular,
    lineHeight: '1.125rem',
  },
  body2: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Regular,
    fontSize: '0.75rem',
    fontStyle: 'normal',
    lineHeight: '1rem',
  },
  action1: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Semibold,
    fontSize: pxToRem(18),
    lineHeight: pxToRem(25.2),
    fontStyle: 'normal',
  },
  action2: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Medium,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(22.4),
    fontStyle: 'normal',
  },
  action3: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Semibold,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19.6),
    fontStyle: 'normal',
  },
  action4: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Medium,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19.6),
    fontStyle: 'normal',
  },
  action5: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Semibold,
    fontSize: pxToRem(18),
    lineHeight: pxToRem('auto'),
    fontStyle: 'normal',
  },
  action6: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Regular,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(17),
    fontStyle: 'normal',
  },
  caption: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Bold,
    fontSize: pxToRem(10),
    lineHeight: pxToRem(10),
  },
  hyperlink: {
    fontFamily: Fonts.PolySans.Name,
    fontWeight: Fonts.PolySans.Weights.Regular,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}

export default typography
