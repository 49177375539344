import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'
import { buildComponent } from '../utils/buildComponent'

type SwitchSize = 'large' | 'small'

const Container = styled.div<LayoutProps | SpaceProps | FlexboxProps>`
  ${layout}
  ${space}
  ${flexbox}
`

const SwitchContainer = styled.label<{ switchSize: SwitchSize }>`
  position: relative;
  display: inline-block;
  width: ${(props) =>
    props.switchSize === 'small' ? '1.625rem' : '2.3125rem'};
  height: ${(props) =>
    props.switchSize === 'small' ? '0.875rem' : '1.3125rem'};
`

const Slider = styled.span<{ disabled: boolean; switchSize: SwitchSize }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.palette.grey.grey2
      : props.theme.palette.text.placeholder};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 0.625rem;
  user-select: none;

  &::before {
    position: absolute;
    content: '';
    height: ${(props) =>
      props.switchSize === 'small' ? '0.625rem' : '0.9375rem'};
    width: ${(props) =>
      props.switchSize === 'small' ? '0.625rem' : '0.9375rem'};
    left: ${(props) =>
      props.switchSize === 'small' ? '0.125rem' : '0.1875rem'};
    bottom: ${(props) =>
      props.switchSize === 'small' ? '0.125rem' : '0.1875rem'};
    background-color: ${(props) =>
      props.disabled
        ? props.theme.palette.grey.grey400
        : props.theme.palette.white};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`

const Switch = styled.input<{ switchSize: SwitchSize }>`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: ${(props) => props.theme.palette.primary.pink800};
  }
  &:checked + ${Slider}:before {
    transform: translateX(
      ${(props) => (props.switchSize === 'small' ? '0.75rem' : '1rem')}
    );
  }
`

interface SwitchToggleProps extends SpaceProps, LayoutProps, FlexboxProps {
  active: boolean
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  isDisabled?: boolean
  size?: SwitchSize
}

const SwitchToggle = (props: SwitchToggleProps) => {
  const { active, onClick, isDisabled = false, size, ...rest } = props
  const handleClick = isDisabled ? undefined : onClick
  return (
    <Container {...rest}>
      <SwitchContainer switchSize={size}>
        <Switch
          type='checkbox'
          readOnly
          checked={!props.isDisabled && active}
          switchSize={size}
        />
        <Slider disabled={isDisabled} onClick={handleClick} switchSize={size} />
      </SwitchContainer>
    </Container>
  )
}

export default buildComponent(SwitchToggle, 'SwitchToggle')
