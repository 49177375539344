import React from 'react'
import { Box, Stack, Divider } from '@mui/material'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import styled from 'styled-components'
import TrainIcon from '@mui/icons-material/Train'
import JourneyPlannerContainer from './JourneyPlannerContainer'
import Text from 'core-system/Text'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {
  getLineColor,
  getTransitIcon,
  getTransitShortName,
  getDepartureArrivalTime,
  getStopDetails,
} from './googleMapsFunctions'
import palette from 'core-system/Themes/palette'

const InnerContainer = styled.div`
  margin-top: 28px;
`

const SectionContainer = styled.div`
  margin-top: 18px;
  padding: 0px 8px;
`

const ShortName = styled(Text)<{ color: string }>`
  font-weight: bold;
  background-color: ${({ color }) => color};
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: white;
`

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 8px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 6px;
`

const TransitIcon = ({ icon }) => {
  return icon ? (
    <img
      src={icon}
      alt='transit'
      style={{ height: 24, width: 24, objectFit: 'contain' }}
    />
  ) : (
    <TrainIcon color='inherit' />
  )
}

const Icon = ({ travelMode, transitIcon }) => {
  if (travelMode === 'TRANSIT') {
    return <TransitIcon icon={transitIcon} />
  }
  return <DirectionsWalkIcon color='secondary' />
}

const Section = ({ children }) => {
  return (
    <SectionContainer>
      {children}
      <Divider style={{ marginTop: 22, marginBottom: 22 }} />
    </SectionContainer>
  )
}

const TransitDetails = ({ transitDetails }) => {
  const times = getDepartureArrivalTime(transitDetails)
  const stops = getStopDetails(transitDetails)

  return (
    <>
      <Text variant='body1'>
        <strong>Arrive:</strong> {times.departureTime} @ {stops.departure}
      </Text>
      <Text variant='body1'>
        <strong>Depart:</strong> {times.arrivalTime} @ {stops.arrival}
      </Text>
      <Text variant='body1'>
        {transitDetails.headsign ? transitDetails.headsign : 'TRANSIT'}
      </Text>
    </>
  )
}

const Time = ({ durationText }) => {
  return (
    <TimeContainer>
      <AccessTimeIcon
        color='success'
        style={{ fontSize: 18, marginRight: 2 }}
      />
      <Text variant='body1' fontWeight='bold'>
        {durationText}
      </Text>
    </TimeContainer>
  )
}

function JourneyPlanner({ steps }) {
  // Utility function to format ISO date string to readable time
  const formatTime = (isoString) => {
    if (!isoString) return 'N/A'
    const dateObj = new Date(isoString)
    return dateObj.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  return (
    <JourneyPlannerContainer steps={steps}>
      <Divider
        style={{
          marginTop: 18,
          marginBottom: 24,
          borderColor: palette.primary.pink600,
        }}
      />
      <Text variant='body1' style={{ fontWeight: 'bold' }}>
        Directions
      </Text>
      <InnerContainer>
        {steps.map((step, index) => {
          const {
            travelMode,
            navigationInstruction,
            localizedValues,
            transitDetails,
          } = step

          const transitIcon = getTransitIcon(transitDetails)

          const instructions =
            navigationInstruction?.instructions || 'No instructions available'
          const distanceText =
            localizedValues?.distance?.text || 'Unknown distance'
          const durationText =
            localizedValues?.staticDuration?.text || 'Unknown duration'

          // Choose an icon & label based on travel mode
          let stepLabel = `Walk - ${distanceText}`

          if (travelMode === 'TRANSIT') {
            const routeName =
              transitDetails?.transitLine?.nameShort ||
              transitDetails?.transitLine?.name ||
              'Transit'
            stepLabel = `${routeName} - ${distanceText}`
          }

          // If transit, gather extra info safely
          let transitInfo = null
          let chip = null
          if (travelMode === 'TRANSIT' && transitDetails) {
            const lineColor = getLineColor(transitDetails) || '#888'
            const transitLineNameShort = getTransitShortName(transitDetails)
            transitInfo = <TransitDetails transitDetails={transitDetails} />
            chip = (
              <ShortName variant='body1' color={lineColor}>
                {transitLineNameShort}
              </ShortName>
            )
          }

          return (
            <Section key={index}>
              <Stack direction='row' spacing={2} alignItems='flex-start'>
                <Box
                  sx={{
                    mt: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Icon travelMode={travelMode} transitIcon={transitIcon} />
                  {chip}
                </Box>
                <Box flexGrow={1}>
                  <Title>
                    <Text variant='body1' fontWeight='bold'>
                      {stepLabel}
                    </Text>
                    <Time durationText={durationText} />
                  </Title>
                  <Text variant='body1'>{instructions}</Text>
                  {transitInfo}
                </Box>
              </Stack>
            </Section>
          )
        })}
      </InnerContainer>
    </JourneyPlannerContainer>
  )
}

export default React.memo(JourneyPlanner)
