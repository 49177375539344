export default {
  50: '#EAF5FF',
  100: '#D6ECFF',
  200: '#BCDFFF',
  300: '#8FC7FF',
  400: '#5FAAFF',
  500: '#2F8DFF',
  600: '#0070E0',
  700: '#005FB8',
  800: '#00478A',
  900: '#002A52',
}
