import React from 'react'
import styled, { css } from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  variant,
} from 'styled-system'
import { buildComponent } from '../utils/buildComponent'

export const statusMap: Dictionary<{
  variant: ChipVariants
  text: string
  btn: string
}> = {
  ACTIVE: {
    variant: 'green' as ChipVariants,
    text: 'active',
    btn: 'Manage Incentives',
  },
  UPDATING: {
    variant: 'blue',
    text: 'updating',
    btn: 'Manage Incentives',
  },
  PENDING: {
    variant: 'purple',
    text: 'pending',
    btn: 'Manage Incentives',
  },
  CANCELLING: {
    variant: 'red',
    text: 'cancelling',
    btn: 'Manage Incentives',
  },
  CANCELLED: {
    variant: 'grey',
    text: 'cancelled',
    btn: 'New Incentives',
  },
  DISABLED: {
    variant: 'disabled',
    text: 'off',
    btn: 'New Incentives',
  },
  PAUSED: {
    variant: 'disabled',
    text: 'paused',
    btn: 'Manage Incentives',
  },
}

const Chip = styled.div<FlexboxProps & SpaceProps & LayoutProps>`
  ${(props) => props.theme.typography.caption};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => `${props.theme.pxToRem(6)} 0`};
  border-radius: 6.25rem;
  width: ${(props) => props.theme.pxToRem(80)};
  text-transform: uppercase;
  user-select: none;

  ${(props) =>
    variant({
      variants: {
        green: {
          backgroundColor: props.theme.palette.accent.teal200,
          color: props.theme.palette.accent.teal500,
        },
        red: {
          backgroundColor: props.theme.palette.primary.pink200,
          color: props.theme.palette.primary.pink500,
        },
        purple: {
          backgroundColor: props.theme.palette.primary.pink200,
          color: props.theme.palette.primary.pink500,
        },
        grey: {
          backgroundColor: props.theme.palette.grey.grey200,
          color: props.theme.palette.grey.grey500,
        },
        disabled: {
          backgroundColor: props.theme.palette.grey.grey100,
          color: props.theme.palette.text.disabled,
        },
        yellow: {
          backgroundColor: props.theme.palette.warning.yellow200,
          color: props.theme.palette.warning.yellow500,
        },
        blue: {
          backgroundColor: props.theme.palette.accent.blue200,
          color: props.theme.palette.accent.blue500,
        },
        regular: {
          backgroundColor: props.theme.palette.primary.pink200,
          color: props.theme.palette.text.secondary,
        },
        transparent: {
          backgroundColor: props.theme.palette.transparent,
          color: props.theme.palette.text.placeholder,
        },
      },
    })}

  ${flexbox}
  ${space}
  ${layout}
`

const FilterChip = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps & { isSelected?: boolean; isInteractive: boolean }
>`
  ${(props) => props.theme.typography.action4};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => `${props.theme.pxToRem(6)} 1rem`};
  border-radius: 6.25rem;
  border: ${(props) => `1px solid ${props.theme.palette.grey.grey300}`};
  background-color: ${(props) => props.theme.palette.white};
  text-transform: capitalize;
  color: ${(props) => props.theme.palette.text.secondary};
  min-width: ${(props) => props.theme.pxToRem(65)};
  width: fit-content;
  user-select: none;

  ${(props) =>
    props.isInteractive &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${(props) => props.theme.palette.primary.pink300};
        border-color: ${(props) => props.theme.palette.text.secondary};
      }
    `}

  ${(props) =>
    props.isSelected
      ? {
          backgroundColor: props.theme.palette.primary.pink300,
          borderColor: props.theme.palette.primary.pink300,
          color: props.theme.palette.white,
          '&:hover': {
            backgroundColor: props.theme.palette.primary.pink700,
            borderColor: props.theme.palette.primary.pink700,
            color: props.theme.palette.white,
          },
        }
      : {}}

  ${flexbox}
  ${space}
  ${layout}
`

export type ChipVariants =
  | 'regular'
  | 'purple'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'red'
  | 'grey'
  | 'disabled'
  | 'transparent'
  | 'filter'

export interface ChipProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  variant?: ChipVariants
  selected?: boolean
  isInteractive?: boolean
}

export default buildComponent((props: ChipProps) => {
  const { variant = 'green', selected, isInteractive = true, children } = props

  if (variant === 'filter') {
    return (
      <FilterChip
        isSelected={selected}
        isInteractive={isInteractive}
        {...props}
      >
        {children}
      </FilterChip>
    )
  }

  return (
    <Chip variant={variant} {...props}>
      {children}
    </Chip>
  )
}, 'Chip')
