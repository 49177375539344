import RolloutIcon from 'core-system/Icons/Actions/Rollout'
import SummaryOverview from 'core-system/Program/Summary/SummaryOverview'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import { Segment } from 'redux/employer/employerTypes'
import styled from 'styled-components'
import moment from 'moment'
import Button from 'core-system/Button'
import DateUtils from 'shared/DateUtils'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import QTFUtils from 'features/QTF/Shared/QTFUtils'
import FormattingUtils from 'shared/FormattingUtils'
import { StaticAssetsUrl } from 'redux/config/services/Constants'

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  color: #16014a;
  border: 1px solid #e3e3ec;
  box-shadow: 0px 4px 8px rgba(171, 171, 188, 0.15);
  border-radius: 0.9375rem;
  margin-bottom: 1rem;
  position: unset;
  top: unset;
`

const SummaryContainer = styled.div`
  padding: 2rem ${(props) => props.theme.pxToRem(12)}
    ${(props) => props.theme.pxToRem(12)};
`

const PurpleContainer = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink300};
`

const PinkContainer = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
`

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 0.9375rem 0.9375rem 0 0;
`

const CaptionText = styled.div`
  ${(props) => props.theme.typography.action3};
  color: ${(props) => props.theme.palette.primary.pink500};
  text-transform: uppercase;
  letter-spacing: 0.7px;
  white-space: nowrap;
  font-family: 'PolySans';
`

interface QTFActivationSummaryProps {
  startDate: string
  currentSegment: Segment
  transitActive: boolean
  parkingActive: boolean
  employerTransitContribution: number
  employerParkingContribution: number
  contributionDays: number
  setupMode: 'hris' | 'manual'
  hrisConfig: { closingDate: Date; payrollSchedule: string }
  commUserIds?: string[]
  isActivationFlow?: boolean
  toggleEdit?: () => void
  toggleUserCommEdit?: () => void
  userCommEdit?: boolean
  canEdit?: boolean
  activationView?: boolean
  estimatedMonthlySpend?: number
  isOnboarding?: boolean
}

const QTFActivationSummary = React.memo((props: QTFActivationSummaryProps) => {
  const {
    startDate,
    currentSegment,
    transitActive,
    parkingActive,
    employerTransitContribution,
    employerParkingContribution,
    contributionDays,
    commUserIds,
    isActivationFlow = false,
    toggleEdit,
    toggleUserCommEdit,
    canEdit = true,
    userCommEdit = true,
    setupMode,
    hrisConfig,
    activationView = false,
    estimatedMonthlySpend = 0,
    isOnboarding = false,
  } = props

  const allUsers = useSelector((state: AppState) => state.employee.list)
  const currentUser = useSelector((state: AppState) => state.user.profile)
  const groupedUsers = [...allUsers, ...[currentUser]].reduce((agg, curr) => {
    agg[curr.id] = agg[curr.id] || {}
    agg[curr.id] = curr
    return agg
  }, {})
  const { irsTaxLimit } = useSelector(
    (state: AppState) => state.application.properties
  )

  return (
    <Container>
      <Img src={`${StaticAssetsUrl}/onboarding/summary_train.png`} />
      <SummaryContainer>
        <Text variant='h5' marginBottom='1rem' color={palette.black}>
          Here’s your estimated monthly spend:
        </Text>
        <Text
          variant='h1'
          marginBottom='1rem'
          fontSize='48px'
          color={palette.black}
        >
          ${(estimatedMonthlySpend / 100).toFixed(2)}
        </Text>
        <Text variant='body1' marginBottom='2.5rem' color={palette.grey.grey10}>
          This includes the amount you contribute for your employees’ programs,
          along with a small service fee. This amount will change month to month
          as you add and remove employees, and modify your contribution amounts.
        </Text>
        <Text variant='h4' marginBottom='1.5rem' color={palette.black}>
          Program Summary
        </Text>
        <SummaryOverview
          startDate={
            isActivationFlow
              ? startDate
              : moment(startDate).format('MMMM Do, YYYY')
          }
          currentSegment={currentSegment}
          marginBottom='1rem'
          qtf
        />
        <PinkContainer>
          <HeaderContainer>
            <Text variant='h4' color={palette.black}>
              Program Configurations
            </Text>
          </HeaderContainer>
          <CaptionText>Mode</CaptionText>
          <Text
            variant='action4'
            margin='0.75rem 0 0.5rem'
            color={palette.black}
          >
            {setupMode === 'hris' ? 'Automatic' : 'Manual'}
          </Text>
          {setupMode === 'hris' && (
            <>
              <CaptionText>Payroll Schedule</CaptionText>
              <Text
                variant='action4'
                margin='0.75rem 0 0.5rem'
                color={palette.black}
              >
                {QTFUtils.payrollScheduleOptions[hrisConfig.payrollSchedule]}
              </Text>
              {activationView && (
                <>
                  <CaptionText>Next Closing Date</CaptionText>
                  <Text
                    variant='action4'
                    margin='0.75rem 0 0.5rem'
                    color={palette.black}
                  >
                    {moment(hrisConfig.closingDate).format('MMMM DD, yyyy')}
                  </Text>
                </>
              )}
            </>
          )}
          <CaptionText>Employer Contribution</CaptionText>
          <Text
            variant='action4'
            margin='0.75rem 0 0.75rem'
            color={palette.black}
          >
            {transitActive
              ? `${FormattingUtils.formatDollar(
                  employerTransitContribution || 0,
                  0
                )} for Transit`
              : 'Transit Disabled'}
          </Text>
          <Text
            variant='action4'
            margin='0.75rem 0 0.75rem'
            color={palette.black}
          >
            {parkingActive
              ? `${FormattingUtils.formatDollar(
                  employerParkingContribution || 0,
                  0
                )} for Parking`
              : 'Parking Disabled'}
          </Text>
          <CaptionText>Commuter Allocation</CaptionText>
          <Text
            variant='action4'
            margin='0.75rem 0 0.75rem'
            color={palette.black}
          >
            {transitActive
              ? ` Up to ${FormattingUtils.formatDollar(
                  irsTaxLimit - (employerTransitContribution || 0),
                  0
                )} for Transit`
              : 'Transit Disabled'}
          </Text>
          <Text
            variant='action4'
            margin='0.75rem 0 0.75rem'
            color={palette.black}
          >
            {parkingActive
              ? ` Up to ${FormattingUtils.formatDollar(
                  irsTaxLimit - (employerParkingContribution || 0),
                  0
                )} for Parking`
              : 'Parking Disabled'}
          </Text>
          <CaptionText>Allocation Deadline</CaptionText>
          <Text
            variant='action4'
            margin='0.75rem 0 0.5rem'
            color={palette.black}
          >
            {DateUtils.getDayOfTheMonth(contributionDays)} of every month
          </Text>
        </PinkContainer>
        {!canEdit && !userCommEdit && (
          <Button marginTop={'1rem'} width='100%' onClick={toggleEdit}>
            Edit Next Month&apos;s Program
          </Button>
        )}
        {!isOnboarding && (
          <>
            <PurpleContainer style={{ marginTop: '1rem' }}>
              <HeaderContainer>
                <RolloutIcon color={palette.primary.pink400} />
                <Text variant='action3' marginLeft='0.5rem'>
                  Emails & Reminders
                </Text>
              </HeaderContainer>
              <CaptionText>Users</CaptionText>
              {commUserIds &&
                commUserIds.map((id) => {
                  const user = groupedUsers[id]
                  return user ? (
                    <Text variant='action4' margin='0.75rem 0 0.5rem' key={id}>
                      {user.firstName + ' ' + user.lastName}
                    </Text>
                  ) : null
                })}
            </PurpleContainer>
          </>
        )}
        {!userCommEdit && !canEdit && (
          <Button marginTop={'1rem'} width='100%' onClick={toggleUserCommEdit}>
            Edit Emails & Reminders
          </Button>
        )}
      </SummaryContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFActivationSummary.displayName = 'QTFActivationSummary'
}

export default QTFActivationSummary
