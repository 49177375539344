import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { conciergeActions } from 'redux/concierge/conciergeSlice'
import { employeeActions } from 'redux/employee/employeeSlice'
import { employerActions } from 'redux/employer/employerSlice'
import { flexActions } from 'redux/flex/flexSlice'
import { incentivesActions } from 'redux/incentives/incentivesSlice'
import { leaderboardsActions } from 'redux/leaderboards/leaderboardsSlice'
import { micromobilityActions } from 'redux/micromobility/micromobilitySlice'

export interface ApplicationState {
  employeeAddressesExist: Dictionary<boolean> | string
  apiLoading: boolean
  isEmployeePlatform: boolean
  isFetchingProperties: boolean
  properties: {
    irsTaxLimit: number
  }
}

const initialState: ApplicationState = {
  employeeAddressesExist: null,
  apiLoading: false,
  isEmployeePlatform: false,
  isFetchingProperties: false,
  properties: {
    irsTaxLimit: 32500,
  },
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    toggleApiLoading(state) {
      state.apiLoading = !state.apiLoading
    },
    toggleEmployeePlatform(state) {
      state.isEmployeePlatform = !state.isEmployeePlatform
    },
    fetchProperties(state) {
      state.isFetchingProperties = true
    },
    setProperties(state, action: PayloadAction<object>) {
      state.properties = {
        ...state.properties,
        ...action.payload,
      }
      state.isFetchingProperties = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          /*create actions */
          flexActions.createFlexProgram,
          micromobilityActions.createMicromobilityProgram,
          incentivesActions.createIncentives,
          leaderboardsActions.createLeaderboard,
          employerActions.createWorksite,
          employeeActions.createEmployee,
          conciergeActions.createAnnouncement,
          employerActions.createSegment,
          incentivesActions.createOneTimeReward,
          incentivesActions.bulkCreateOneTimeRewards,

          /*update actions */
          flexActions.updateFlexProgram,
          micromobilityActions.updateMicromobilityProgram,
          incentivesActions.updateIncentives,
          leaderboardsActions.updateLeaderboard,
          employerActions.updateWorksite,
          employeeActions.updateEmployee,
          conciergeActions.updateAnnouncement,
          employerActions.updateSegment,
          employerActions.addSegmentEmployees,
          employerActions.addSegmentEmployeesWithORCA,
          employerActions.removeSegmentEmployees,
          employerActions.activateSegmentUpcomingAdditions,
          employerActions.removeSegmentUpcomingRemovals,
          employerActions.forceActivateInactiveEmployees,
          conciergeActions.updateFaq,

          /*delete actions */
          conciergeActions.deleteAnnouncement,
          employeeActions.deleteEmployee,
          employerActions.deleteSegment,
          leaderboardsActions.cancelLeaderboard,
          incentivesActions.pauseIncentives,
          employerActions.declineInactiveEmployees
        ),
        (state) => {
          state.apiLoading = true
        }
      )
      .addMatcher(
        isAnyOf(
          /*create success actions */
          flexActions.createFlexProgramSuccess,
          micromobilityActions.createMicromobilityProgramSuccess,
          incentivesActions.createIncentivesSuccess,
          leaderboardsActions.createLeaderboardSuccess,
          employerActions.createWorksiteSuccess,
          employeeActions.createEmployeeSuccess,
          conciergeActions.createAnnouncementSuccess,
          employerActions.createSegmentSuccess,
          incentivesActions.createOneTimeRewardSuccess,
          incentivesActions.bulkCreateOneTimeRewardsSuccess,

          /*update success actions */
          flexActions.updateFlexProgramSuccess,
          micromobilityActions.updateMicromobilityProgramSuccess,
          incentivesActions.updateIncentivesSuccess,
          leaderboardsActions.updateLeaderboardSuccess,
          employerActions.updateWorksiteSuccess,
          employeeActions.updateEmployeeSuccess,
          conciergeActions.updateAnnouncementSuccess,
          employerActions.updateSegmentSuccess,
          employerActions.addSegmentEmployeesSuccess,
          employerActions.addSegmentEmployeesWithORCASuccess,
          employerActions.removeSegmentEmployeesSuccess,
          employerActions.activateSegmentUpcomingAdditionsSuccess,
          employerActions.removeSegmentUpcomingRemovalsSuccess,
          employerActions.forceActivateInactiveEmployeesSuccess,
          conciergeActions.updateFaqSuccess,

          /*delete success actions */
          conciergeActions.deleteAnnouncementSuccess,
          employeeActions.deleteEmployeeSuccess,
          employerActions.deleteSegmentSuccess,
          leaderboardsActions.cancelLeaderboardSuccess,
          incentivesActions.pauseIncentivesSuccess,
          employerActions.declineInactiveEmployeesSuccess
        ),
        (state) => {
          state.apiLoading = false
        }
      )
      .addMatcher(
        (action) =>
          [
            /*create failed actions */
            'flex/createFlexProgramFailed',
            'micromobility/createMicromobilityProgramFailed',
            'incentives/createIncentivesFailed',
            'leaderboards/createLeaderboardFailed',
            'employer/createWorksiteFailed',
            'employee/createEmployeeFailed',
            'concierge/createAnnouncementFailed',
            'employer/createSegmentFailed',
            'incentives/createOneTimeRewardFailed',
            'incentives/bulkCreateOneTimeRewardsFailed',

            /*update failed actions */
            'flex/updateFlexProgramFailed',
            'micromobility/updateMicromobilityProgramFailed',
            'incentives/updateIncentivesFailed',
            'leaderboards/updateLeaderboardsFailed',
            'employer/updateWorksiteFailed',
            'employer/updateEmployeeFailed',
            'concierge/updateAnnouncementFailed',
            'employer/updateSegmentFailed',
            'employer/addSegmentEmployeesFailed',
            'employer/addSegmentEmployeesWithORCAFailed',
            'employer/removeSegmentEmployeesFailed',
            'employer/activateSegmentUpcomingAdditionsFailed',
            'employer/removeSegmentUpcomingRemovalsFailed',
            'employer/forceActivateInactiveEmployeesFailed',
            'concierge/updateFaqFailed',

            /*delete failed actions */
            'concierge/deleteAnnouncementFailed',
            'employee/deleteEmployeeFailed',
            'employer/deleteSegmentFailed',
            'leaderboards/cancelLeaderboardFailed',
            'incentives/pauseIncentivesFailed',
            'employer/declineInactiveEmployeesFailed',
          ].includes(action.payload),
        (state) => {
          state.apiLoading = false
        }
      )
  },
})

export const applicationReducer = applicationSlice.reducer
export const applicationActions = { ...applicationSlice.actions }
