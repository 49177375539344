import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js'
import palette from '../../Themes/palette'
import { LayoutProps, layout, SpaceProps, space } from 'styled-system'
import styled from 'styled-components'

ChartJS.register(ArcElement, Tooltip, Legend)

interface Props extends LayoutProps, SpaceProps {
  data?: ChartData<'doughnut'>
  options?: ChartOptions<'doughnut'>
  width?: string
  height?: string
}

const ChartContainer = styled.div<LayoutProps & SpaceProps>`
  display: flex;
  align-items: center;
  position: relative;
  ${layout}
  ${space}
`

const RadialChart: React.FC<Props> = ({
  data,
  options,
  width = '200px',
  height = '200px',
  ...props
}) => {
  const defaultData: ChartData<'doughnut'> = data ?? {
    labels: ['Label1', 'Label2', 'Label3'],
    datasets: [
      {
        data: [10, 20, 30],
        backgroundColor: [
          palette.primary.pink500,
          palette.primary.pink800,
          palette.success.green500,
        ],
        borderWidth: 0,
        hoverBorderWidth: 5,
        hoverBorderColor: palette.primary.pink700,
      },
    ],
  }

  const defaultOptions: ChartOptions<'doughnut'> = options ?? {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          color: palette.primary.pink500,
          font: {
            family: 'PolySans, sans-serif',
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => context.label || '',
          labelTextColor: () => '#fff',
        },
        backgroundColor: palette.primary.pink500,
        displayColors: false,
        caretSize: 0,
        mode: 'nearest',
      },
    },
    cutout: '55%',
    animation: {
      animateRotate: false,
    },
  }

  return (
    <ChartContainer {...props} style={{ width, height }}>
      <Doughnut data={defaultData} options={defaultOptions} />
    </ChartContainer>
  )
}

export default RadialChart
