import React, { useState } from 'react'
import palette from 'core-system/Themes/palette'
import { Mode, Trip } from 'redux/employeePlatform/employeePlatformTypes'
import styled from 'styled-components'
import Text from 'core-system/Text'
import {
  noTripReasons,
  timeRanges,
  tripTypes,
} from 'employee-platform/shared/utils'
import Loading from 'core-system/Loading'
import mapIntelligenceService from 'redux/mapIntelligence/mapIntelligenceService'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import NoDataText from 'employee-platform/shared/components/NoDataText'
import { tripModeTypes } from '../TripsUtils'
import CustomMuiTooltip from 'employee-platform/shared/components/CustomMuiTooltip'
import typography from 'core-system/Themes/typography'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: ${(props) => props.theme.dropShadows.normal};
`

const TripField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const TripSourceDestField = styled.div`
  display: grid;
  grid-template-columns: 40% 10% 40%;
  justify-content: space-between;
  align-items: center;
`

const TripModesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  overflow-x: auto;
`

const ModeIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink600};
`

const renderTripField = (fieldLabel: string, fieldValue: string | null) => {
  return (
    <TripField>
      <Text variant='body2' textColor={palette.text.secondary}>
        {fieldLabel}
      </Text>
      <Text variant='action3' hideOverflow>
        {fieldValue}
      </Text>
    </TripField>
  )
}

const renderTripLocationField = (type: 'source' | 'dest', address: string) => {
  return (
    <TripField>
      <Text variant='body2' textColor={palette.text.secondary}>
        {type === 'source' ? 'Source' : 'Dest'}
      </Text>
      <Text variant='action3'>{address}</Text>
    </TripField>
  )
}

const renderTripModes = (
  modeIds: string[],
  allModes: { [modeId: string]: Mode } | null
) => {
  if (modeIds.length === 0 || !allModes) {
    return <NoDataText text='NO MODES SELECTED' />
  }

  return modeIds.map((modeId) => {
    return (
      <CustomMuiTooltip
        key={modeId}
        title={allModes[modeId]?.name}
        textVariant={typography.body2}
        arrow
      >
        <ModeIconContainer>
          {allModes[modeId]?.name === allModes[modeId]?.modeType ||
          !allModes[modeId]?.iconUrl ? (
            tripModeTypes[allModes[modeId]?.modeType]?.icon
          ) : (
            <img
              src={allModes[modeId]?.iconUrl}
              alt={allModes[modeId]?.name}
              height='80%'
              width='80%'
            />
          )}
        </ModeIconContainer>
      </CustomMuiTooltip>
    )
  })
}

const getTripLocation = (
  address: string | null,
  worksite: boolean | null,
  home: boolean | null
) => {
  if (address) {
    return address
  }
  if (worksite) {
    return 'Work'
  }
  if (home) {
    return 'Home'
  }
  return 'Other'
}

interface TripCardProps {
  trip: Trip
}

const TripCard = React.memo((props: TripCardProps) => {
  const { trip } = props

  const { allModes } = useSelector((state: AppState) => state.employeePlatform)

  const [sourceAddr, setSourceAddr] = useState<string | null>(null)
  const [destAddr, setDestAddr] = useState<string | null>(null)

  if (trip.tripType !== 'NOTRIP' && !sourceAddr) {
    mapIntelligenceService
      .getAddressAutocomplete(`${trip.source.lng},${trip.source.lat}`)
      .then((res) => {
        setSourceAddr(
          res?.data?.features?.length > 0
            ? res.data.features[0].place_name_en
            : null
        )
      })
  }

  if (trip.tripType !== 'NOTRIP' && !destAddr) {
    mapIntelligenceService
      .getAddressAutocomplete(`${trip.dest.lng},${trip.dest.lat}`)
      .then((res) => {
        setDestAddr(
          res?.data?.features?.length > 0
            ? res.data.features[0].place_name_en
            : null
        )
      })
  }

  if (trip.tripType === 'OTHER' && (!sourceAddr || !destAddr)) {
    return <Loading />
  }

  return (
    <Container>
      {renderTripField('Trip Type', tripTypes[trip.tripType])}
      {trip.tripType === 'NOTRIP'
        ? renderTripField('Reason', noTripReasons[trip.noTripReason] || 'Other')
        : null}
      {trip.tripType !== 'NOTRIP'
        ? renderTripField('Time Range', timeRanges.get(trip.timeRange))
        : null}
      {trip.tripType !== 'NOTRIP' ? (
        <TripSourceDestField>
          {renderTripLocationField(
            'source',
            getTripLocation(
              sourceAddr,
              trip.source?.home,
              trip.source?.worksite
            )
          )}
          <Text variant='action2'>→</Text>
          {renderTripLocationField(
            'dest',
            getTripLocation(destAddr, trip.dest?.home, trip.dest?.worksite)
          )}
        </TripSourceDestField>
      ) : null}
      {trip.tripType !== 'NOTRIP' && trip.modeIds ? (
        <TripField>
          <Text variant='body2' textColor={palette.text.secondary}>
            Transportation Modes
          </Text>
          <TripModesContainer>
            {!trip.modeIds || trip.modeIds.length === 0 ? (
              <NoDataText text='NO MODES SELECTED' />
            ) : (
              renderTripModes(trip.modeIds, allModes)
            )}
          </TripModesContainer>
        </TripField>
      ) : null}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TripCard.displayName = 'TripCard'
}

export default TripCard
