import FlexContainer from 'core-system/FlexContainer'
import CheckmarkIcon from 'core-system/Icons/Actions/Checkmark'
import FlexIcon from 'core-system/Icons/Sidebar/Flex'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import { GrhActiveOptions } from 'redux/grh/grhTypes'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'

const PurpleContainer = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`

const Options = styled.div`
  margin-top: 1.5rem;
`

const Option = styled.div`
  margin-top: 1.25rem;
  padding-top: 1.5rem;
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey400};

  &:first-of-type {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0.5rem;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

const HyperLink = styled(Text)`
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.pink400};

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const OptionProviders = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
`

interface GRHSummaryOptionsProps {
  activeOptions: GrhActiveOptions
  activeMerchantIds: string[]
  availableMerchantIds: string[]
  handleOpenModal: () => void
  canEdit?: boolean
}

const GRHSummaryOptions = React.memo((props: GRHSummaryOptionsProps) => {
  const {
    activeOptions,
    activeMerchantIds,
    availableMerchantIds,
    handleOpenModal,
    canEdit,
  } = props

  const noActiveOptions = Object.keys(activeOptions).every(
    (option) => !activeOptions[option]
  )

  return (
    <PurpleContainer>
      <HeaderContainer>
        <FlexIcon color={palette.primary.pink400} />
        <Text variant='action3' marginLeft='0.5rem'>
          Program Options
        </Text>
      </HeaderContainer>
      {noActiveOptions ? (
        <Text
          variant='body2'
          marginTop='0.5rem'
          textColor={palette.text.secondary}
        >
          No options are selected for this program
        </Text>
      ) : (
        <Options>
          {Object.keys(activeOptions).flatMap((option) => {
            if (activeOptions[option]) {
              return (
                <Option key={option}>
                  <FlexContainer
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Text
                      variant='caption'
                      textColor={palette.text.secondary}
                      captionTitle
                    >
                      {ProgramUtils.optionsCopy[option].title}
                    </Text>
                    <CheckmarkIcon
                      width={13}
                      height={13}
                      color={palette.primary.pink400}
                    />
                  </FlexContainer>
                  {option === 'rideshare' && (
                    <OptionProviders>
                      <Text variant='action4'>
                        {activeMerchantIds.length} Service Provider
                        {RandomUtils.pluralCheck(activeMerchantIds.length)}
                      </Text>
                      {availableMerchantIds.length > 0 && (
                        <HyperLink
                          variant='hyperlink'
                          onClick={handleOpenModal}
                        >
                          {canEdit ? 'Edit' : 'View All'}
                        </HyperLink>
                      )}
                    </OptionProviders>
                  )}
                </Option>
              )
            } else {
              return []
            }
          })}
        </Options>
      )}
    </PurpleContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHSummaryOptions.displayName = 'GRHSummaryOptions'
}

export default GRHSummaryOptions
