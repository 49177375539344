import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Text from 'core-system/Text'
import Button from 'core-system/Button'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import 'react-circular-progressbar/dist/styles.css'
import moment from 'moment'
import { Locations } from 'shared/Router/Locations'
import LocalStorage from 'shared/LocalStorage'
import MonthRangePicker from 'core-system/MonthRangePicker'
import { YearMonth } from 'core-system/MonthRangePicker/DropdownCalendar'
import Dropdown from 'core-system/Dropdown'
import palette from 'core-system/Themes/palette'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import { useDispatch } from 'react-redux'
import { dashboardActions } from 'redux/dashboard/dashboardSlice'
import SegmentService from 'redux/config/services/SegmentService'

const dropdownItems = [
  {
    id: 'usage',
    text: 'Overall Usage Report',
    productLabel: null,
  },
  {
    id: 'otr',
    text: 'One-Time Rewards',
    productLabel: 'OTR',
  },
  {
    id: 'competitions',
    text: 'Competition Winners',
    productLabel: 'COMPETITIONS',
  },
  {
    id: 'trips',
    text: 'Trips',
    productLabel: null,
  },
  {
    id: 'incentives',
    text: 'Incentives Rewarded',
    productLabel: 'INCENTIVES',
  },
  {
    id: 'transactions',
    text: 'Transactions',
    productLabel: null,
  },
  {
    id: 'taxSavings',
    text: 'All Tax Savings Qualified Transactions',
    productLabel: 'QTF',
  },
]

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  margin-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  gap: 1.5rem;
`

const TitleContainer = styled.div`
  margin-bottom: 1rem;
  justify-content: space-between;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SvgWrapper = styled.div`
  @media (min-width: ${(props) =>
      props.theme.breakpoints[2]}) and (max-width: 87.5rem) {
    display: none;
  }
`

const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  margin-bottom: 0.5rem;
`

const getSegmentDropdownItems = (
  segments: Segment[],
  selectedReport: string
) => {
  if (selectedReport === 'taxSavings') {
    return segments.reduce((segs, s) => {
      if (s.commuters > 0 && (s.type === 'ALL' || s.type === 'WORKSITE')) {
        segs.push({ id: s.id, text: s.name })
      }
      return segs
    }, [])
  }
  return segments
    .filter((s) => s.commuters > 0)
    .map((s) => {
      return {
        id: s.id,
        text: s.name,
      }
    })
}

const getMoment = (yearString: string, monthNo: number) => {
  return moment(
    yearString +
      monthNo.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
    'YYYYMM'
  )
}

const handleSubmit = (
  startDate: YearMonth,
  endDate: YearMonth,
  selectedSegmentId: string
) => {
  const startMoment = getMoment(startDate.year, startDate.month).startOf(
    'month'
  )
  const endMoment = endDate
    ? getMoment(endDate.year, endDate.month).add(1, 'month')
    : getMoment(startDate.year, startDate.month).add(1, 'month')
  LocalStorage.setItem('reportStartDate', startMoment.format('YYYY-MM-DD'))
  LocalStorage.setItem('reportEndDate', endMoment.format('YYYY-MM-DD'))
  LocalStorage.setItem('segmentId', selectedSegmentId)

  SegmentService.track('generate-overall-usage-report', {
    segmentId: selectedSegmentId,
    startDate: startMoment.format('YYYY-MM-DD'),
    endDate: endMoment.format('YYYY-MM-DD'),
  })
}

const getReportName = (reportId: string) => {
  return dropdownItems.filter((item) => item.id === reportId)[0].text
}

interface DashboardReportingProps {
  isReportGenerating: boolean
}

const DashboardReporting = React.memo((props: DashboardReportingProps) => {
  const { isReportGenerating } = props

  const dispatch = useDispatch()

  const [selectedMonthsError, setSelectedMonthsError] = useState(false)
  const [selectedStartDate, setSelectedStartDate] = useState<YearMonth>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<YearMonth>(null)
  const [selectedReportType, setSelectedReportType] = useState<string>('usage')

  const { allEmployeeSegmentId, allSegments, profile } = useSelector(
    (state: AppState) => state.employer
  )

  const products = new Set(profile.products)

  const [selectedSegmentId, setSelectedSegmentId] =
    useState(allEmployeeSegmentId)

  const GenerateButton = React.memo(() => (
    <Button
      size='small'
      disabled={isReportGenerating}
      variant='primary'
      width='100%'
      onClick={() => {
        if (!selectedStartDate) {
          setSelectedMonthsError(true)
        } else if (selectedReportType !== 'usage') {
          const startMoment = getMoment(
            selectedStartDate.year,
            selectedStartDate.month
          )
            .startOf('month')
            .format()
          const endMoment = selectedEndDate
            ? getMoment(selectedEndDate.year, selectedEndDate.month)
                .add(1, 'month')
                .format()
            : getMoment(selectedStartDate.year, selectedStartDate.month)
                .add(1, 'month')
                .format()

          dispatch(
            dashboardActions.getDashboardCsvReport({
              startDate: startMoment,
              endDate: endMoment,
              segmentId:
                selectedSegmentId === allEmployeeSegmentId
                  ? null
                  : selectedSegmentId,
              reportType: selectedReportType,
            })
          )

          SegmentService.track('download-csv-report', {
            reportType: getReportName(selectedReportType),
            segmentId: selectedSegmentId,
            startDate: moment(startMoment).format('YYYY-MM-DD'),
            endDate: moment(endMoment).format('YYYY-MM-DD'),
          })
        }
      }}
    >
      {isReportGenerating ? 'Generating...' : 'Generate Report'}
    </Button>
  ))
  GenerateButton.displayName = 'GenerateButton'
  return (
    <Container>
      <Content>
        <TitleContainer>
          <Text variant='h5'>Reports</Text>
        </TitleContainer>
        <Dropdown
          items={dropdownItems.filter(
            (item) =>
              (!item.productLabel && item.id !== 'trips') ||
              products.has(item.productLabel) ||
              (item.id === 'trips' &&
                (products.has('COMPETITIONS') || products.has('INCENTIVES')))
          )}
          active={selectedReportType}
          itemCallback={({ id }) => setSelectedReportType(id)}
          width='100%'
          label='Report Type'
          marginBottom='0.5rem'
        ></Dropdown>
        <FormFieldContainer>
          <Text variant='body1' textColor={palette.text.secondary}>
            Time Period
          </Text>
          <MonthRangePicker
            error={selectedMonthsError}
            errorMessage='Select a Valid Date Range'
            errorSetter={setSelectedMonthsError}
            placeholder='--'
            selectedStartDate={selectedStartDate}
            startDateSetter={setSelectedStartDate}
            selectedEndDate={selectedEndDate}
            endDateSetter={setSelectedEndDate}
          />
        </FormFieldContainer>
        <Dropdown
          items={getSegmentDropdownItems(allSegments, selectedReportType)}
          active={selectedSegmentId}
          itemCallback={({ id }) => setSelectedSegmentId(id)}
          width='100%'
          label='Group'
          marginBottom='1.5rem'
        ></Dropdown>
        {selectedReportType === 'usage' && selectedStartDate ? (
          <Link
            to={Locations.Report}
            target='_blank'
            onClick={() =>
              handleSubmit(
                selectedStartDate,
                selectedEndDate,
                selectedSegmentId
              )
            }
            rel='noopener noreferrer'
            style={{
              textDecoration: 'none',
            }}
          >
            <GenerateButton />
          </Link>
        ) : (
          <GenerateButton />
        )}
      </Content>
      <SvgWrapper>
        <PlatformSvg
          folder='dashboard'
          variant='dashboardReport'
          width={127}
          height={121}
        />
      </SvgWrapper>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardReporting.displayName = 'DashboardReporting'
}

export default DashboardReporting
