import MonthToggle from 'core-system/DatePicker/MonthToggle'
import Divider from 'core-system/Divider'
import FleetError from 'core-system/Error/FleetError'
import FlexContainer from 'core-system/FlexContainer'
import MoneyIcon from 'core-system/Icons/Misc/Money'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React from 'react'
import { MyCommuterTransaction } from 'redux/employee/employeeTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'

const MetricRow = styled(FlexContainer)`
  justify-content: space-between;
  margin-top: 0.75rem;
`

const TransactionsContainer = styled.div`
  height: ${(props) => props.theme.pxToRem(452)};
  ${(props) => props.theme.scrollbars.basic};
  padding: 0 1.5rem 0 1rem;
  margin-top: 0.5rem;
`

const IconContainer = styled.div<{ hasBackground: boolean }>`
  width: ${(props) => props.theme.pxToRem(50)};
  height: ${(props) => props.theme.pxToRem(50)};
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.hasBackground ? props.theme.palette.primary.pink400 : 'transparent'};
`

const Img = styled.img`
  align-self: center;
  width: 100%;
`

const renderTransactions = (transactions: MyCommuterTransaction[]) => {
  if (transactions.length === 0) {
    return (
      <FleetError description={'No Transactions Logged'} padding='4rem 0' />
    )
  }

  return (
    <TransactionsContainer>
      {transactions.map((t, i: number) => (
        <div key={i}>
          <FlexContainer alignItems='center' justifyContent='space-between'>
            <FlexContainer alignItems='center'>
              <IconContainer hasBackground={!t.iconUrl && true}>
                {t.iconUrl ? (
                  <Img alt='' src={t.iconUrl} />
                ) : (
                  <MoneyIcon color={palette.text.secondary} />
                )}
              </IconContainer>
              <FlexContainer flexDirection='column' marginLeft='1rem'>
                <Text variant='action3' textColor={palette.text.primary}>
                  {t.name}
                </Text>
                <Text variant='body2' textColor={palette.text.placeholder}>
                  {moment(t.created).format('MMMM DD, YYYY')}
                </Text>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <Text variant='action1' textColor={palette.text.primary}>
                {FormattingUtils.formatDollar(t.spend)}
              </Text>
            </FlexContainer>
          </FlexContainer>
          {i + 1 < transactions.length && (
            <Divider direction='horizontal' my='1rem' />
          )}
        </div>
      ))}
    </TransactionsContainer>
  )
}

interface MyCommuterDetailsTransactionsProps {
  currentDate: string
  onIncreaseClick: () => void
  onDecreaseClick: () => void
  commuterTransactions: MyCommuterTransaction[]
}

const MyCommuterDetailsTransactions = React.memo(
  (props: MyCommuterDetailsTransactionsProps) => {
    const {
      currentDate,
      commuterTransactions,
      onIncreaseClick,
      onDecreaseClick,
    } = props

    const rewardSpend = commuterTransactions.reduce(
      (acc, { rewardTransactionSpend }) => acc + rewardTransactionSpend,
      0
    )
    const walletSpend = commuterTransactions.reduce(
      (acc, { walletTransactionSpend }) => acc + walletTransactionSpend,
      0
    )
    const totalSpend = commuterTransactions.reduce(
      (acc, { spend }) => acc + spend,
      0
    )
    const programSpend = totalSpend - (rewardSpend + walletSpend)

    return (
      <FlexContainer>
        <FlexContainer width='35%' flexDirection='column'>
          <MonthToggle
            currentDate={currentDate}
            onIncreaseClick={onIncreaseClick}
            onDecreaseClick={onDecreaseClick}
          />
          <MetricRow marginTop='1.5rem'>
            <Text variant='action4'>Transactions</Text>
            <Text variant='action4'>
              {FormattingUtils.formatNumber(commuterTransactions.length)}
            </Text>
          </MetricRow>
          <MetricRow>
            <Text variant='action4'>Total Spend</Text>
            <Text variant='action4'>
              {FormattingUtils.formatDollar(totalSpend)}
            </Text>
          </MetricRow>
          <MetricRow>
            <Text variant='action4'>Program Spend</Text>
            <Text variant='action4'>
              {FormattingUtils.formatDollar(programSpend)}
            </Text>
          </MetricRow>
          <MetricRow>
            <Text variant='action4'>Reward Spend</Text>
            <Text variant='action4'>
              {FormattingUtils.formatDollar(rewardSpend)}
            </Text>
          </MetricRow>
          <MetricRow>
            <Text variant='action4'>Wallet Spend</Text>
            <Text variant='action4'>
              {FormattingUtils.formatDollar(walletSpend)}
            </Text>
          </MetricRow>
          <Divider margin='1.5rem 0' />
        </FlexContainer>
        <Divider
          direction='vertical'
          size={pxToRem(452)}
          margin='0.5rem 1.5rem 0.5rem 2rem'
        />
        <FlexContainer width='65%' flexDirection='column'>
          {renderTransactions(commuterTransactions)}
        </FlexContainer>
      </FlexContainer>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommuterDetailsTransactions.displayName = 'MyCommuterDetailsTransactions'
}

export default MyCommuterDetailsTransactions
