import {
  OPEN_CHAT_ROOM_FETCH_CHATS,
  POST_CHAT,
  ADD_AI_CONCIERGE_CHAT,
  SET_TYPING_ANIMATION_COMPLETE,
  START_NEW_THREAD,
  ADD_ANY_NEW_CHATS,
} from './conciergeAITypes'
import { ActionCreator } from '@reduxjs/toolkit'

export const openChatRoomFetchChats: ActionCreator<{ type: string }> = () => ({
  type: OPEN_CHAT_ROOM_FETCH_CHATS,
})

export const postChat: ActionCreator<{
  type: string
  payload: { chatRoomId: string; message: string; threadId: string }
}> = (chatRoomId: string, message: string, threadId: string) => ({
  type: POST_CHAT,
  payload: { chatRoomId, message, threadId },
})

export const addAIConciergeChat: ActionCreator<{
  type: string
  payload: { data: string }
}> = (data: string) => ({
  type: ADD_AI_CONCIERGE_CHAT,
  payload: { data },
})

export const setTypingAnimationComplete: ActionCreator<{
  type: string
  payload: { chatId: string }
}> = (chatId: string) => ({
  type: SET_TYPING_ANIMATION_COMPLETE,
  payload: { chatId },
})

export const startNewThread: ActionCreator<{ type: string }> = () => ({
  type: START_NEW_THREAD,
})

export const addAnyNewChats: ActionCreator<{
  type: string
  payload: { allChats: any; chatRoom: string; threadId: string }
}> = (allChats: any, chatRoom: string, threadId: string) => ({
  type: ADD_ANY_NEW_CHATS,
  payload: { allChats, chatRoom, threadId },
})
