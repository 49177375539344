import Button from 'core-system/Button'
import Chip from 'core-system/Chip'
import Divider from 'core-system/Divider'
import Dropdown from 'core-system/Dropdown'
import AddIcon from 'core-system/Icons/Actions/Add'
import Modal, { ModalBody, ModalHeader } from 'core-system/Modal'
import SegmentDropdownUtils from 'core-system/SegmentDropdown/SegmentDropdownUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import {
  LeaderboardsGroup,
  LeaderboardType,
} from 'redux/leaderboards/leaderboardsTypes'
import styled from 'styled-components'
import LeaderboardUtils from '../LeaderboardUtils'

const LeaderboardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey300};
  align-items: center;

  &:first-child {
    padding-top: 0;
    border-top: none;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const TextContainer = styled.div`
  margin: 0 1rem;
`

const ModeTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  align-self: flex-start;
  pointer-events: none;
`

const IconContainer = styled.div`
  max-width: 4rem;
`

const renderLeaderboardOptions = (
  leaderboards: Dictionary<LeaderboardsGroup>,
  handleClick: (leaderboardType: string, currentSegment: string) => void,
  currentSegment: string
) => {
  return Object.keys(LeaderboardUtils.leaderboardData).map(
    (leaderboardType: string) => {
      const { iconLg, title, description, modeTypes } =
        LeaderboardUtils.leaderboardData[leaderboardType]
      const activeLeaderboard =
        leaderboards && currentSegment ? leaderboards[currentSegment] : null
      return (
        <LeaderboardContainer key={leaderboardType}>
          <Container>
            <IconContainer>{iconLg}</IconContainer>
            <TextContainer>
              <Text variant='action4' marginBottom='0.5rem'>
                {title}
              </Text>
              <Text variant='body1'>{description}</Text>
            </TextContainer>
            {activeLeaderboard &&
            activeLeaderboard.programs.some(
              (leaderboard) =>
                leaderboard.type === (leaderboardType as LeaderboardType)
            ) ? (
              <Button
                height='2.5rem'
                disabled={true}
                alignSelf='center'
                marginLeft='auto'
              >
                Added
              </Button>
            ) : (
              <Button
                height='2.5rem'
                iconLeft={<AddIcon />}
                alignSelf='center'
                marginLeft='auto'
                onClick={() => handleClick(leaderboardType, currentSegment)}
              >
                Add
              </Button>
            )}
          </Container>
          <ModeTypes>
            {modeTypes.map((modeType: string) => (
              <Chip
                variant={'filter'}
                key={modeType}
                margin='0.5rem 0.5rem 0 0'
              >
                {modeType.toLowerCase()}
              </Chip>
            ))}
          </ModeTypes>
        </LeaderboardContainer>
      )
    }
  )
}

interface LeaderboardListProps {
  segmentId?: string
  open: boolean
  closeModal: () => void
}

const LeaderboardList = React.memo((props: LeaderboardListProps) => {
  const { segmentId, open, closeModal } = props
  const navigate = useNavigate()

  const { allSegments, segmentsMap } = useSelector(
    (state: AppState) => state.employer
  )
  const { leaderboardsMap } = useSelector(
    (state: AppState) => state.leaderboards
  )

  const [currentSegment, setCurrentSegment] = useState(segmentId)
  const [hasError, setHasError] = useState(false)

  const handleClick = useCallback(
    (leaderboardType: string, currentSegment: string) => {
      if (!currentSegment) {
        setHasError(true)
      } else {
        navigate(
          `/leaderboards/${currentSegment}/${leaderboardType.toLowerCase()}/activation`,
          {
            state: {
              from: '/leaderboards',
            },
          }
        )
        SegmentService.track('leaderboard-manage-action', {
          action: 'add',
          boardType: leaderboardType.toLowerCase(),
          locationAt: `leaderboards-${segmentsMap[currentSegment].name}`,
        })
        closeModal()
      }
    },
    [navigate, closeModal, segmentsMap]
  )

  return (
    <Modal open={open} onClose={() => closeModal()} width={pxToRem(862)}>
      <ModalHeader title='Add New Competition' />
      <ModalBody paddingBottom='2rem'>
        <Dropdown
          items={SegmentDropdownUtils.segmentDropdownItems(allSegments || [])}
          active={currentSegment}
          itemCallback={({ id }) => setCurrentSegment(id)}
          width='100%'
          label='Select a Group of Commuters:'
          placeholder={!currentSegment ? '--' : null}
          error={hasError && !currentSegment}
          errorMsg='Select a Valid Group'
        />
        <Divider margin='1.5rem 0' />
        <Text
          marginTop='0.5rem'
          variant='body1'
          textColor={palette.text.secondary}
        >
          Select which type of competition you’d like to add:
        </Text>
        <div>
          {renderLeaderboardOptions(
            leaderboardsMap,
            handleClick,
            currentSegment
          )}
        </div>
      </ModalBody>
    </Modal>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LeaderboardList.displayName = 'LeaderboardList'
}

export default LeaderboardList
