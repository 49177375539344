import Divider from 'core-system/Divider'
import Dropdown from 'core-system/Dropdown'
import FlexContainer from 'core-system/FlexContainer'
import Loading from 'core-system/Loading'
import Radio from 'core-system/Radio'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { MyCommuterDetails } from 'redux/employee/employeeTypes'
import { Worksite } from 'redux/employer/employerTypes'
import styled from 'styled-components'
import RandomUtils from 'shared/RandomUtils'

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ContentWrapper = styled(FlexContainer)`
  flex-direction: column;
  padding-bottom: 0.5rem;
  width: 100%;
`

const CardOptionsContainer = styled.div`
  margin-top: 0.5rem;
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CardOption = styled.div`
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.palette.text.primary};
  ${(props) => props.theme.typography.action3};
`

const UnderlinedText = styled.span`
  text-decoration: underline;
  font-weight: 700;
`

const getWorksiteDropdownItems = (
  worksites: Worksite[],
  commuterDetails: MyCommuterDetails
) => {
  return worksites.map((w) => {
    return {
      id: w.id,
      text: w.alias,
      disabled: w.id === commuterDetails.worksiteId,
    }
  })
}

interface MyCommuterChangeWorksiteProps {
  commuterDetails: MyCommuterDetails
  selectedWorksiteId: string
  forceChangeWorksite: boolean
  onWorksiteIdChange: (worksiteId: string) => void
  onForceChangeWorksiteChange: (forceWorksite: boolean) => void
}

const MyCommuterChangeWorksite = React.memo(
  (props: MyCommuterChangeWorksiteProps) => {
    const {
      commuterDetails,
      selectedWorksiteId,
      forceChangeWorksite,
      onWorksiteIdChange,
      onForceChangeWorksiteChange,
    } = props

    const { worksites, payrollProviderId } = useSelector((state: AppState) => {
      return {
        worksites: state.employer.worksites,
        payrollProviderId: state.employer.profile.payrollProviderId,
      }
    })

    const currentWorksite = worksites?.find(
      (worksite) => worksite.id === commuterDetails.worksiteId
    )

    const selectableWorksites = worksites?.filter(
      (worksite) =>
        !worksite.finchWorksite &&
        !worksite.archived &&
        worksite.id !== currentWorksite?.id
    )

    const employeeWorksiteChangeFlag = useSelector(
      (state: AppState) => state.employee.employeeWorksiteChangeFlag
    )

    return (
      <Container>
        {employeeWorksiteChangeFlag === 'loading' ? (
          <Loading height={pxToRem(698)} isCard={false} />
        ) : (
          <ContentWrapper maxWidth={pxToRem(550)}>
            <Text variant='h3'>
              Current Worksite:{' '}
              {currentWorksite ? currentWorksite.alias : 'None'}
            </Text>
            <Divider margin='1rem 0' />
            <Text variant='h4' style={{ marginBottom: '0.5rem' }}>
              Select a New Worksite:
            </Text>
            <Dropdown
              items={getWorksiteDropdownItems(
                selectableWorksites,
                commuterDetails
              )}
              active={selectedWorksiteId}
              itemCallback={({ id }) => onWorksiteIdChange(id)}
              width='100%'
              placeholder={
                selectableWorksites.length > 0
                  ? 'Select a Worksite'
                  : 'No Worksites Available'
              }
              marginBottom='0.5rem'
            />
            <Text
              variant='action4'
              capitalize
              style={{ color: palette.accent.teal500, marginBottom: '1rem' }}
            >
              Note: Commuters may not be moved to worksites imported through
              {!payrollProviderId || payrollProviderId === ''
                ? ' a payroll provider'
                : ` ${RandomUtils.formatFinchProviderName(payrollProviderId)}`}
            </Text>
            <CardOptionsContainer>
              <FlexContainer alignItems='center' marginBottom='1.5rem'>
                <Radio
                  active={forceChangeWorksite}
                  onClick={() => onForceChangeWorksiteChange(true)}
                  marginRight='1rem'
                  data-cy='accounts-billing-virtual-card-radio'
                />
                <div>
                  <CardOption>Instant Change</CardOption>
                  The commuter will <UnderlinedText>
                    instantly
                  </UnderlinedText>{' '}
                  {commuterDetails.worksiteId && (
                    <>lose access to the current worksite&apos;s products and</>
                  )}{' '}
                  gain access to the new worksite&apos;s products.
                </div>
              </FlexContainer>
              <FlexContainer alignItems='center'>
                <Radio
                  active={!forceChangeWorksite}
                  onClick={() => onForceChangeWorksiteChange(false)}
                  marginRight='1rem'
                  data-cy='accounts-billing-physical-card-radio'
                />
                <div>
                  <CardOption>Scheduled Change</CardOption>
                  The commuter will{' '}
                  {commuterDetails.worksiteId && (
                    <>lose access to the current worksite&apos;s products and</>
                  )}{' '}
                  gain access to the new worksite&apos;s products at the{' '}
                  <UnderlinedText>start of next month</UnderlinedText>.
                </div>
              </FlexContainer>
            </CardOptionsContainer>
          </ContentWrapper>
        )}
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommuterChangeWorksite.displayName = 'MyCommuterChangeWorksite'
}

export default MyCommuterChangeWorksite
