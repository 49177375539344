import FlexContainer from 'core-system/FlexContainer'
import HorizontalScroller from 'core-system/HorizontalScroller'
import HorizontalScrollerUtils from 'core-system/HorizontalScroller/HorizontalScrollerUtils'
import Loading from 'core-system/Loading'
import ServiceProviderSelectorModal from 'core-system/ServiceProvider/ServiceProviderSelectorModal'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  margin-top: 1rem;
`

const HyperLink = styled(Text)`
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.pink400};

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const DashboardServices = React.memo(() => {
  const merchants = useSelector((state: AppState) => state.programs.merchants)
  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )

  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!merchants) {
    return <Loading height={pxToRem(140)} marginTop='1rem' />
  }

  const merchantIds = merchants.map((m) => m.id)

  return (
    <>
      <Container>
        <FlexContainer marginBottom='2rem' justifyContent='space-between'>
          <Text variant='h5'>Available Service Providers</Text>
          <HyperLink variant='hyperlink' onClick={() => setIsModalOpen(true)}>
            View All
          </HyperLink>
        </FlexContainer>
        <HorizontalScroller translationPerClick={160}>
          {HorizontalScrollerUtils.getProviders(merchantIds, merchantsMap)}
        </HorizontalScroller>
      </Container>
      <ServiceProviderSelectorModal
        open={isModalOpen}
        title='Available Service Providers'
        closeModal={() => setIsModalOpen(false)}
        activeProviders={merchantIds}
        availableProviders={merchantIds}
        canEdit={false}
        modeTypeFilters
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardServices.displayName = 'DashboardServices'
}

export default DashboardServices
