import React from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import NumberUtils from 'shared/NumberUtils'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import palette from 'core-system/Themes/palette'
import FleetIcon from 'core-system/FleetLogos/FleetLogoCircleColor'
import Loading from 'core-system/Loading'
import pxToRem from 'core-system/utils/pxToRem'
import ChipDifference from 'core-system/Chip/ChipDifference'

const getProgressStyles = () => {
  return buildStyles({
    strokeLinecap: 'round',
    pathColor: palette.primary.pink300,
    trailColor: palette.primary.pink200,
  })
}

const Container = styled.div`
  ${(props) => props.theme.baseCard}
`

const MetricContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
`

const Value = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

const OverflowContainer = styled.div`
  display: flex;
  align-items: flex-end;
  overflow: hidden;
`

const MetricTitle = styled(Text)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.text.secondary};
  letter-spacing: 0.7px;
`

const CircularProgressContainer = styled.div`
  height: ${(props) => props.theme.pxToRem(52)};
  width: ${(props) => props.theme.pxToRem(52)};
  color: ${(props) => props.theme.palette.primary.pink100};
  margin-right: 1rem;
`

const UtilizationContainer = styled.div`
  display: flex;
`

interface TripsLoggedProps {
  totalJourneys: number
  fleetDetected: number
  tripsPercentDiff: number
  isLoading: boolean
  // temp
}

const TripsLogged = React.memo((props: TripsLoggedProps) => {
  const { totalJourneys, fleetDetected, tripsPercentDiff, isLoading } = props

  if (isLoading) {
    return <Loading height={pxToRem(224)} spinnerSize={45} />
  }

  const percentDetected = Math.round((fleetDetected / totalJourneys) * 100)

  return (
    <Container>
      <Text variant='h5' marginBottom='1.5rem'>
        Trips
      </Text>
      <MetricContainer>
        <Value>
          <OverflowContainer>
            {totalJourneys ? (
              <>
                <Text variant='h3'>
                  {NumberUtils.formatNumber(totalJourneys)}
                </Text>
              </>
            ) : (
              <Text variant='h3'>0</Text>
            )}
          </OverflowContainer>
        </Value>
        <ChipDifference percentDifference={tripsPercentDiff * 100} />
      </MetricContainer>
      <MetricTitle variant='caption' marginBottom='1.75rem'>
        Logged This Month
      </MetricTitle>
      <UtilizationContainer>
        <CircularProgressContainer>
          <CircularProgressbarWithChildren
            styles={getProgressStyles()}
            strokeWidth={10}
            counterClockwise={true}
            value={percentDetected}
          >
            <FleetIcon />
          </CircularProgressbarWithChildren>
        </CircularProgressContainer>
        <div>
          <>
            {fleetDetected ? (
              <>
                <Text variant='h3'>
                  {NumberUtils.formatNumber(percentDetected, 0)}%
                </Text>
              </>
            ) : (
              <Text variant='h3'>-</Text>
            )}
            <MetricTitle variant='caption' marginTop='0.5rem'>
              Fleet Detected Trips
            </MetricTitle>
          </>
        </div>
      </UtilizationContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TripsLogged.displayName = 'TripsLogged'
}

export default TripsLogged
