import Button from 'core-system/Button'
import Chip from 'core-system/Chip'
import { statusMap } from 'core-system/Chip/Chip'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import EnterArrowIcon from 'core-system/Icons/Misc/EnterArrow'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React from 'react'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom'
import { Segment } from 'redux/employer/employerTypes'
import { IncentiveGroup } from 'redux/incentives/incentivesTypes'
import FormattingUtils from 'shared/FormattingUtils'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'

const Container = styled.div`
  ${(props) => props.theme.baseCard}
  margin-bottom: 1.5rem;

  &:hover {
    .headerLink {
      opacity: 1;
    }
  }
`

const TitleContainer = styled.div`
  margin-bottom: 0.375rem;
  display: flex;
  justify-content: space-between;
`

const HeaderLink = styled(Link)`
  padding: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  background-color: ${(props) => props.theme.palette.primary.pink200};
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  height: 2rem;
  width: 2rem;
  display: flex;
  transform: rotate(270deg);

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }

  &:active {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const ProgramRow = styled(FlexContainer)`
  padding: 0.5rem;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;
  justify-content: space-between;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const TextContainer = styled.div`
  margin-left: 1.75rem;
`

const renderIncentiveRows = (
  allIncentives: Dictionary<IncentiveGroup>,
  segmentsMap: Dictionary<Segment>,
  navigate: NavigateFunction
) => {
  return Object.values(allIncentives).map((incentiveGroup) => {
    const activeRowFilter = incentiveGroup.active ? 'active' : 'nextMonth'
    const activeSegment = segmentsMap[incentiveGroup[activeRowFilter].segment]
    const activeRow = incentiveGroup[activeRowFilter]
    return (
      <React.Fragment key={activeSegment?.id}>
        <Divider margin='0.5rem 0' />
        <ProgramRow onClick={() => navigate(`/trips/${activeSegment?.id}`)}>
          <FlexContainer alignItems='center'>
            <EnterArrowIcon
              width={17}
              height={17}
              color={palette.text.placeholder}
            />
            <TextContainer>
              <Text variant='action3'>{activeSegment?.name}</Text>
            </TextContainer>
          </FlexContainer>
          {activeRow.status === 'PENDING' ? (
            <Chip variant={statusMap[activeRow.status].variant}>
              {statusMap[activeRow.status].text}
            </Chip>
          ) : (
            <Text variant='action2'>
              {FormattingUtils.formatDollar(activeRow.rewarded, 0)}
            </Text>
          )}
        </ProgramRow>
      </React.Fragment>
    )
  })
}

interface DashboardIncentivesProps {
  allIncentives: Dictionary<IncentiveGroup>
  segmentsMap: Dictionary<Segment>
}

const DashboardIncentives = React.memo((props: DashboardIncentivesProps) => {
  const { allIncentives, segmentsMap } = props
  const navigate = useNavigate()

  const totalRewarded = Object.values(allIncentives).reduce(
    (total, incentiveProgram) => {
      return (total +=
        parseInt(
          incentiveProgram[incentiveProgram.active ? 'active' : 'nextMonth']
            .rewarded
        ) || 0)
    },
    0
  )

  return (
    <Container>
      <TitleContainer>
        <Text variant='h5'>Incentives</Text>
        <HeaderLink to={Locations.Incentives.Programs} className='headerLink'>
          <ChevronIcon />
        </HeaderLink>
      </TitleContainer>
      <Text variant='h3' marginBottom='0.5rem'>
        {FormattingUtils.formatDollar(totalRewarded, 0)}
      </Text>
      <Text
        variant='caption'
        textColor={palette.text.secondary}
        captionTitle
        paddingBottom='0.5rem'
      >
        Rewarded this month
      </Text>
      {Object.keys(allIncentives).length > 0 ? (
        renderIncentiveRows(allIncentives, segmentsMap, navigate)
      ) : (
        <div>
          <Divider margin='1rem 0' />
          <FlexContainer center>
            <Button
              width='60%'
              size='small'
              marginTop='0.25rem'
              onClick={() => navigate(Locations.Incentives.Programs)}
            >
              Active Incentives
            </Button>
          </FlexContainer>
        </div>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardIncentives.displayName = 'DashboardIncentives'
}

export default DashboardIncentives
