import { call, put, takeLatest } from 'redux-saga/effects'
import {
  changeTutorChatState,
  pushTutorChatMessages,
  pushTutorChatMessage,
  setChatRoom,
  setIsGettingResponse,
  setTypingAnimationComplete,
  setAllThinkingAnimationComplete,
  clearChats,
} from './conciergeAISlice'
import {
  openChatRoom,
  getChats,
  postChatToDb,
  deleteChatRoom,
} from './conciergeAIService'
import {
  OPEN_CHAT_ROOM_FETCH_CHATS,
  POST_CHAT,
  ADD_AI_CONCIERGE_CHAT,
  SET_TYPING_ANIMATION_COMPLETE,
  START_NEW_THREAD,
  ADD_ANY_NEW_CHATS,
} from './conciergeAITypes'
import { v4 as uuidv4 } from 'uuid'
import camelize from 'camelize'
import { map } from 'lodash-es'

function* openChatRoomFetchChatsSaga() {
  try {
    // Opening a chat room, if its already open setting the id
    yield put(changeTutorChatState('loading'))
    const chatRoomResponse = yield call(openChatRoom)
    if (!chatRoomResponse.data.id) {
      yield put(changeTutorChatState('error'))
      return
    }
    yield put(
      setChatRoom({
        chatRoom: chatRoomResponse.data.id,
        threadId: chatRoomResponse.data.threadId,
      })
    )

    // Getting the chats from the room
    const chatsResponse = yield call(
      getChats,
      chatRoomResponse.data.id,
      chatRoomResponse.data.threadId
    )
    yield put(pushTutorChatMessages(chatsResponse.data))
    yield put(changeTutorChatState('got_chats'))
  } catch (error) {
    yield put(changeTutorChatState('error'))
  }
}

function* postChatSaga(action: any) {
  try {
    yield put(setIsGettingResponse(true))
    const chatId = uuidv4()
    yield put(
      pushTutorChatMessage({
        message: action.payload.message,
        id: chatId,
        sender: 'user',
        timeStamp: new Date().toISOString(),
        isAiResponse: false,
        threadId: action.payload.threadId,
      })
    )
    const postChatResponse = yield call(
      postChatToDb,
      chatId,
      action.payload.chatRoomId,
      action.payload.message,
      action.payload.threadId
    )
    if (postChatResponse.status !== 201) {
      // toast or error mark on chat
      return
    }
  } catch (error) {
    yield put(setIsGettingResponse(false))
    // toast or error mark on chat
  }
}

function* addAnyNewChatsSaga(action: any) {
  try {
    const chatsResponse = yield call(
      getChats,
      action.payload.chatRoom,
      action.payload.threadId,
      5
    )
    const allChats = action.payload.allChats
    const newChats = chatsResponse.data
    const newChatsToAdd: Record<string, any> = {}

    // Comparing newChats with allChats
    map(newChats, (chatObject) => {
      const chatId = chatObject.id
      let hasChat = false
      map(allChats, (allChatObject) => {
        if (allChatObject.id === chatId) {
          hasChat = true
        }
      })
      if (!hasChat) {
        newChatsToAdd[chatId] = chatObject
      }
    })

    for (const chatId in newChatsToAdd) {
      const chat = newChatsToAdd[chatId]
      if (chat.isAiResponse) {
        // If it's a thinking chat, show the thinking animation
        if (chat.chatType === 'thinking') {
          yield put(
            pushTutorChatMessage({
              ...chat,
              thinkingAnimation: true,
            })
          )
          yield put(setIsGettingResponse(false))
        } else {
          // Else it's an AI response, show the typing animation
          yield put(
            pushTutorChatMessage({
              ...chat,
              typingAnimation: true,
            })
          )
          yield put(setAllThinkingAnimationComplete())
          yield put(setIsGettingResponse(false))
        }
      }
    }
  } catch (error) {
    // Handle errors (e.g., show a toast or error mark on chat)
  }
}

function* addAIConciergeChatSaga(action: any) {
  try {
    const chat = camelize(JSON.parse(action.payload.data))
    if (chat.isAiResponse) {
      // If its a thinking chat, showing the thinking animation
      if (chat.chatType === 'thinking') {
        yield put(
          pushTutorChatMessage({
            ...chat,
            thinkingAnimation: true,
          })
        )
        // else its an AI response, showing the typing animation
      } else {
        yield put(
          pushTutorChatMessage({
            ...chat,
            typingAnimation: true,
          })
        )
        yield put(setAllThinkingAnimationComplete())
      }
    }
    yield put(setIsGettingResponse(false))
  } catch (error) {
    // Handle errors (e.g., show a toast or error mark on chat)
  }
}

function* setTypingAnimationCompleteSaga(action: any) {
  yield put(setTypingAnimationComplete(action.payload.chatId))
}

function* startNewThreadSaga() {
  try {
    yield put(changeTutorChatState('loading'))
    yield call(deleteChatRoom)
    const chatRoomResponse = yield call(openChatRoom)
    yield put(
      setChatRoom({
        chatRoom: chatRoomResponse.data.id,
        threadId: chatRoomResponse.data.threadId,
      })
    )
    yield put(clearChats())
    yield put(changeTutorChatState('got_chats'))
  } catch (error) {
    yield put(changeTutorChatState('error'))
  }
}

// Watcher Saga
export function* conciergeAISaga() {
  yield takeLatest(OPEN_CHAT_ROOM_FETCH_CHATS, openChatRoomFetchChatsSaga)
  yield takeLatest(POST_CHAT, postChatSaga)
  yield takeLatest(ADD_AI_CONCIERGE_CHAT, addAIConciergeChatSaga)
  yield takeLatest(
    SET_TYPING_ANIMATION_COMPLETE,
    setTypingAnimationCompleteSaga
  )
  yield takeLatest(START_NEW_THREAD, startNewThreadSaga)
  yield takeLatest(ADD_ANY_NEW_CHATS, addAnyNewChatsSaga)
}

export default conciergeAISaga
