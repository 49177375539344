import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import styled, { css } from 'styled-components'
import { Locations } from 'shared/Router/Locations'
import Loading from 'core-system/Loading'
import Banner from 'core-system/Banner'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'
import SurveyIcon from 'core-system/Icons/Concierge/Survey'
import palette from 'core-system/Themes/palette'
import Text from 'core-system/Text'
import AddOptInModal from './Overview/components/AddOptInModal'
import moment from 'moment'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const ProgramRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const ProgramRow = styled.div<{ isMobile: boolean }>`
  ${(props) =>
    props.isMobile
      ? css`
          padding: 1rem;
          border-radius: 0.5rem;
          border: 1px solid ${(props) => props.theme.palette.grey.grey300};
          background-color: ${(props) => props.theme.palette.white};
        `
      : props.theme.baseCard}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const ProgramRowSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
`

const IconContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
`

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
`

const renderProgramStat = (label: string, value: string | number) => {
  return (
    <StatContainer>
      <Text variant='body2' textColor={palette.text.placeholder}>
        {label}
      </Text>
      <Text variant='action2'>{value}</Text>
    </StatContainer>
  )
}

const OptInPrograms = React.memo(() => {
  const navigate = useNavigate()
  const today = moment()

  const { optIns } = useSelector((state: AppState) => state.optIn)

  const { allSegments, segmentsMap } = useSelector(
    (state: AppState) => state.employer
  )

  const [addOptInModalOpen, setAddOptInModalOpen] = useState(false)

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  const handleOnSegmentClick = useCallback(
    (segmentId: string) => {
      navigate(`/opt-in/${segmentId}`, {
        state: { from: Locations.OptIn.Programs },
      })
      SegmentService.track('optIn-segmentNav-action-click', {
        action: `segment-${segmentsMap[segmentId].name}`,
        locationAt: 'optIn',
      })
    },
    [navigate, segmentsMap]
  )

  if (!optIns || !allSegments) {
    return <Loading fullPage />
  }

  return (
    <Container>
      {Object.keys(optIns).length === 0 ? (
        <>
          <Banner
            variant='cta'
            title='Create an Opt-In Program Today!'
            description='Gather feedback from your commuters on the benefits they want most.'
            cta='New Opt-In'
            ctaCallback={() => {
              SegmentService.track('optIn-cta-click', {
                location: 'optIn',
              })
              setAddOptInModalOpen(true)
            }}
            background='bannerGeneral'
          />
        </>
      ) : (
        <Banner
          variant='info'
          title='Your Opt-In Programs'
          description='Gather feedback from your commuters on the benefits they want most.'
          background='bannerGeneral'
        />
      )}
      <ProgramRowsContainer>
        {allSegments.map((segment) => {
          return (
            <ProgramRow
              onClick={() => handleOnSegmentClick(segment.id)}
              isMobile={isMobile}
              key={segment.id}
            >
              <ProgramRowSection>
                {!isMobile ? (
                  <IconContainer>
                    <SurveyIcon color={palette.text.secondary} />
                  </IconContainer>
                ) : null}
                <Text variant='action2'>
                  {segment.name === 'All Employees'
                    ? 'All Commuters'
                    : segment.name}
                </Text>
              </ProgramRowSection>
              <ProgramRowSection>
                {renderProgramStat('Commuters', segment.commuters)}
                {renderProgramStat(
                  'Questions',
                  optIns && optIns[segment.id]
                    ? Object.values(optIns[segment.id]).filter(
                        (optIn) =>
                          (!optIn.endDate ||
                            !moment(optIn.endDate).isBefore(today)) &&
                          (!optIn.startDate ||
                            !moment(optIn.startDate).isAfter(today))
                      ).length
                    : 0
                )}
              </ProgramRowSection>
            </ProgramRow>
          )
        })}
      </ProgramRowsContainer>
      <AddOptInModal
        open={addOptInModalOpen}
        closeModal={() => setAddOptInModalOpen(false)}
        segmentId={null}
        isMobile={isMobile}
      />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OptInPrograms.displayName = 'OptInPrograms'
}

export default OptInPrograms
