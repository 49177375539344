import createSagaMiddleware from '@redux-saga/core'
import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { applicationReducer } from 'redux/application/applicationSlice'
import { conciergeReducer } from 'redux/concierge/conciergeSlice'
import rootSaga from 'redux/config/saga/sagas'
import { dashboardReducer } from 'redux/dashboard/dashboardSlice'
import { employeeReducer } from 'redux/employee/employeeSlice'
import { employerReducer } from 'redux/employer/employerSlice'
import { flexReducer } from 'redux/flex/flexSlice'
import { qtfReducer } from 'redux/qtf/qtfSlice'
import { incentivesReducer } from 'redux/incentives/incentivesSlice'
import { leaderboardsReducer } from 'redux/leaderboards/leaderboardsSlice'
import { mapIntelligenceReducer } from 'redux/mapIntelligence/mapIntelligenceSlice'
import { micromobilityReducer } from 'redux/micromobility/micromobilitySlice'
import { notificationsReducer } from 'redux/notifications/notificationsSlice'
import { programsReducer } from 'redux/programs/programsSlice'
import { userReducer } from 'redux/user/userSlice'
import Analytics from 'shared/Analytics'
import { grhReducer } from 'redux/grh/grhSlice'
import { otpReducer } from 'redux/otp/otpSlice'
import { employeePlatformReducer } from 'redux/employeePlatform/employeePlatformSlice'
import { optInReducer } from 'redux/optin/optInSlice'
import { finchProviders } from 'features/Finch/finchSlice'
import { conciergeAIReducer } from 'features/Concierge-AI/conciergeAISlice'

const SagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: {
    user: userReducer,
    application: applicationReducer,
    employer: employerReducer,
    employee: employeeReducer,
    mapIntelligence: mapIntelligenceReducer,
    programs: programsReducer,
    notifications: notificationsReducer,
    concierge: conciergeReducer,
    dashboard: dashboardReducer,
    incentives: incentivesReducer,
    leaderboards: leaderboardsReducer,
    micromobility: micromobilityReducer,
    flex: flexReducer,
    qtf: qtfReducer,
    grh: grhReducer,
    optIn: optInReducer,
    otp: otpReducer,
    employeePlatform: employeePlatformReducer,
    finchProviders,
    conciergeAI: conciergeAIReducer,
  },
  middleware: (getDefaultMiddleware) => {
    //add redux logger if on develop
    if (process.env.NODE_ENV === 'development') {
      return getDefaultMiddleware()
        .prepend(SagaMiddleware)
        .concat(logger, Analytics)
    }

    return getDefaultMiddleware().prepend(SagaMiddleware).concat(Analytics)
  },
})

export type AppState = ReturnType<typeof store.getState>

SagaMiddleware.run(rootSaga)

export default store
