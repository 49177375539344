// @ts-nocheck
import distance from '@turf/distance'
import Arrow from 'core-system/Icons/MapIntelligence/ArrowPrimary'
import BasicClock from 'core-system/Icons/MapIntelligence/BasicClock'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import { Feature, Point } from 'geojson'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { Worksite } from 'redux/employer/employerTypes'
import styled from 'styled-components'

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.3125rem 0.4375rem 0 0.4375rem;
  font-family: PolySans, sans-serif;
  color: ${(props) => props.theme.palette.text.primary};
  width: ${(props) => props.theme.pxToRem(293)};
`

const PopupTop = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 0.25rem;
`

const EmployeeMainStats = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0.875rem;
`

const MainStatContainer = styled.div`
  display: flex;
`

const ArrowContainer = styled(Arrow)`
  margin: 0 0.375rem 0 0;
  transform: rotate(45deg);
`

const BasicClockContainer = styled(BasicClock)`
  margin: 0 0.3125rem 0 0.5rem;
`

const EmployeeTotalCases = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
`

const EmployeeDesc = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 0.8125rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding-bottom: 0.375rem;
`

const WorksiteAddr = styled(EmployeeDesc)`
  padding: 0;
`

const DescText = styled.div``

const CommuteStatContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.125rem;
`

const CommuteStat = styled.div`
  color: ${(props) => props.theme.palette.primary.pink400};
  font-size: 0.875rem;
  font-weight: 500;
  height: 1.1875rem;
`

const getDescription = (properties: any) => {
  if (properties.withinWorksite) {
    return 'Micromobility main mile'
  } else if (properties.withinTransit) {
    return 'Micromobility first/last mile'
  } else {
    return 'Other'
  }
}

interface PopupProps {
  feature: Feature
  allWorksites: Worksite[]
}

const PopupMicromobility = React.memo((props: PopupProps) => {
  const { feature, allWorksites } = props

  const worksitesGeom = useSelector(
    (state: AppState) => state.mapIntelligence.mapLayers.worksites
  )

  const worksite = allWorksites.filter(
    (s) => s.id === feature.properties.worksiteId
  )[0]

  const apart = distance(
    // @ts-ignore
    feature.geometry.coordinates as Point,
    // @ts-ignore
    worksitesGeom.features[0].geometry.coordinates as any,
    { units: 'miles' }
  ).toFixed(2)

  const description = getDescription(feature.properties)
  const worksiteAddress = worksite.formattedAddress.split(',')[0]
  const time = (parseInt(apart) * 3.2).toFixed(0)

  return (
    <PopupContainer>
      <PopupTop>
        <PlatformSvg
          folder='users'
          variant='userBasic'
          width={45}
          height={45}
        />
        <EmployeeMainStats>
          <MainStatContainer>
            <EmployeeTotalCases>{feature.properties.id}</EmployeeTotalCases>
          </MainStatContainer>
          <EmployeeDesc>
            <DescText>{description}</DescText>
          </EmployeeDesc>
          <WorksiteAddr>{worksiteAddress}</WorksiteAddr>
          <CommuteStatContainer>
            <ArrowContainer height={11} width={10} />
            <CommuteStat>{apart} miles</CommuteStat>
            <BasicClockContainer height={12} width={12} />
            <CommuteStat>{time} mins to work</CommuteStat>
          </CommuteStatContainer>
        </EmployeeMainStats>
      </PopupTop>
    </PopupContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  PopupMicromobility.displayName = 'PopupMicromobility'
}

export default PopupMicromobility
