import React, { useState, MouseEvent, useEffect, useRef } from 'react'
import Popover from '@mui/material/Popover'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import ConciergeFloatingIcon from './components/ConciergeFloatingIcon'
import ChatsContainer from '../Chat/ChatsContainer'
import MessageBar from '../Chat/MessageBar'
import ChatHeader from '../Chat/ChatHeader'
import { Theme } from '@mui/material/styles'
import { AppState } from 'redux/config/store'
import { ChatTypes } from '../Chat/types'
import {
  openChatRoomFetchChats,
  postChat,
  setTypingAnimationComplete,
  startNewThread,
  addAnyNewChats,
} from './conciergeAIActions'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: 12,
    width: '45vw',
    height: '95vh',
    '@media (max-width: 1200px)': {
      // lg
      width: '55vw',
    },
    '@media (max-width: 960px)': {
      // md
      width: '75vw',
    },
    '@media (max-width: 600px)': {
      // sm
      width: '100vw',
    },
    '@media (max-width: 360px)': {
      // xs
      width: '100vw',
    },
  },
  mainChatContainer: {
    position: 'relative',
    height: '100%',
  },
  header: {
    position: 'absolute',
    zIndex: 99,
    width: '100%',
  },
  chat: {
    maxHeight: '95vh !important',
  },
  messageBar: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
}))

const ConciergeChat: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [showChatIcon, setShowChatIcon] = useState(true)
  const {
    chats,
    numberOfUnseenMessages,
    state,
    chatRoom,
    isGettingResponse,
    threadId,
  } = useSelector((state: AppState) => state.conciergeAI)
  const pollingIntervalRef = useRef<NodeJS.Timeout[]>([])
  const isChatOpen = useRef<boolean>(false)

  const open = Boolean(anchorEl)
  const id = open ? 'tutor-chat' : undefined

  const clearAllIntervals = () => {
    pollingIntervalRef.current.forEach((interval) => {
      if (interval) {
        clearInterval(interval)
      }
    })
    pollingIntervalRef.current = []
  }

  useEffect(() => {
    // Initial fetch
    if (chatRoom === null) {
      dispatch(openChatRoomFetchChats())
    }
  }, [chatRoom])

  const polling = () => {
    clearAllIntervals()
    if (chatRoom) {
      // Set up polling every 1 seconds
      pollingIntervalRef.current.push(
        setInterval(() => {
          dispatch(addAnyNewChats(chats, chatRoom, threadId))
        }, 2000)
      )
    }
  }

  // Polling effect for fetching chats instead of using websockets
  useEffect(() => {
    if (isChatOpen.current) {
      polling()
    }
    return () => {
      clearAllIntervals()
    }
  }, [chatRoom, threadId, chats])

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    isChatOpen.current = true
    setAnchorEl(event.currentTarget)
    setShowChatIcon(false)
    polling()
  }

  const handleClose = () => {
    isChatOpen.current = false
    setAnchorEl(null)
    setShowChatIcon(true)
    clearAllIntervals()
  }

  const isOwnChat = (chat: ChatTypes) => {
    return !chat.isAiResponse
  }

  const startNewThreadHere = () => {
    clearAllIntervals()
    dispatch(startNewThread())
  }

  return (
    <div>
      <ConciergeFloatingIcon
        onChatIconClick={handleClick}
        showChatIcon={showChatIcon}
        tooltip='Chat with AI'
        numberOfUnseenMessages={numberOfUnseenMessages}
        anchorEl={anchorEl}
        isLoading={state === 'loading'}
      />
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{ paper: classes.card }}
      >
        <div className={classes.mainChatContainer}>
          <div className={classes.header}>
            <ChatHeader
              displayName='AI Concierge'
              onCloseButtonClick={handleClose}
              onNewThreadButtonClick={() => startNewThreadHere()}
              chatState={state}
            />
          </div>
          <ChatsContainer
            chats={chats}
            isOwnChat={isOwnChat}
            className={classes.chat}
            renderMarkdown={true}
            onTypingAnimationComplete={(chatId: string) => {
              dispatch(setTypingAnimationComplete(chatId))
            }}
          />
          <div className={classes.messageBar}>
            {state !== 'loading' && (
              <MessageBar
                onMessageSend={(message: string) => {
                  dispatch(postChat(chatRoom, message, threadId))
                }}
                showAttachment={false}
                showEmoji={false}
                isGettingResponse={isGettingResponse}
              />
            )}
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default React.memo(ConciergeChat)
