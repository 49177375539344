import { all, fork } from 'redux-saga/effects'
import conciergeSaga from 'redux/concierge/conciergeSaga'
import dashboardSaga from 'redux/dashboard/dashboardSaga'
import employeeSaga from 'redux/employee/employeeSaga'
import employerSaga from 'redux/employer/employerSaga'
import flexSaga from 'redux/flex/flexSaga'
import incentivesSaga from 'redux/incentives/incentivesSaga'
import leaderboardsSaga from 'redux/leaderboards/leaderboardsSaga'
import mapIntelligenceSaga from 'redux/mapIntelligence/mapIntelligenceSaga'
import micromobilitySaga from 'redux/micromobility/micromobilitySaga'
import programsSaga from 'redux/programs/programsSaga'
import userSaga from 'redux/user/userSaga'
import qtfSaga from 'redux/qtf/qtfSaga'
import grhSaga from 'redux/grh/grhSaga'
import optInSaga from 'redux/optin/optInSaga'
import otpSaga from 'redux/otp/otpSaga'
import employeePlatformSaga from 'redux/employeePlatform/employeePlatformSaga'
import finchProviderSaga from 'features/Finch/finchSaga'
import applicationSaga from 'redux/application/applicationSaga'
import conciergeAISaga from 'features/Concierge-AI/conciergeAISaga'

export default function* root() {
  yield all([
    fork(employerSaga),
    fork(employeeSaga),
    fork(mapIntelligenceSaga),
    fork(programsSaga),
    fork(conciergeSaga),
    fork(dashboardSaga),
    fork(incentivesSaga),
    fork(leaderboardsSaga),
    fork(micromobilitySaga),
    fork(flexSaga),
    fork(userSaga),
    fork(qtfSaga),
    fork(grhSaga),
    fork(optInSaga),
    fork(otpSaga),
    fork(employeePlatformSaga),
    fork(finchProviderSaga),
    fork(applicationSaga),
    fork(conciergeAISaga),
  ])
}
