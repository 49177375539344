import React, {
  SyntheticEvent,
  useState,
  useCallback,
  Children,
  cloneElement,
} from 'react'
import { buildForwardingComponent } from '../utils/buildComponent'
import useGlobalScroll from '../../shared/Hooks/useGlobalScroll'
import ChevronIcon from '../Icons/Actions/Chevron'
import Portal from '../Portal/Portal'
import styled, { createGlobalStyle } from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import {
  SpaceProps,
  space,
  LayoutProps,
  layout,
  FlexboxProps,
  flexbox,
} from 'styled-system'
import {
  FadeOutKeyFrame,
  FadeInDownCloseKeyFrame,
  FadeInKeyFrame,
  FadeInDownOpenKeyFrame,
} from './ModalAnimation'

const Background = styled.div.attrs({ className: 'background' })`
  background-color: ${(props) => props.theme.palette.grey.overlay};
  z-index: ${(props) => props.theme.zIndex.modal};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
`

const ModalContainer = styled.div.attrs({ className: 'modalContainer' })<
  SpaceProps & LayoutProps & FlexboxProps
>`
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: ${(props) => props.theme.dropShadows.selected};
  border-radius: 0.9375rem;
  overflow: hidden;
  min-width: ${(props) => props.theme.pxToRem(375)};
  max-width: ${(props) => props.theme.pxToRem(900)};
  max-height: 90%;
  margin: 0 1rem;
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    width: 100%;
    min-width: auto;
    /* max-height: unset; */
  }

  ${space}
  ${layout}
  ${flexbox}
`

const ModalStyle = createGlobalStyle`
.modal-enter {
  opacity: 0;
}
.modal-enter-active {
  opacity: 1;
  &.background {
    animation: ${FadeInKeyFrame} 0.25s linear forwards;
    .modalContainer {
      animation: ${FadeInDownOpenKeyFrame} 0.25s linear forwards;
    }
  }
}

.modal-exit {
  opacity: 1;
}
.modal-exit-active {
  opacity: 0;
  &.background {
    animation: ${FadeOutKeyFrame} 0.25s linear forwards;
    .modalContainer {
      animation: ${FadeInDownCloseKeyFrame} 0.25s linear forwards;
    }
  }
}
`

const ModalMainContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  box-shadow:
    0px 3px 5px rgba(0, 0, 0, 0.1),
    0px 5px 8px rgba(0, 0, 0, 0.14),
    0px 1px 10px rgba(0, 0, 0, 0.12);
  z-index: 3001;
`

const ModalSidebar = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.theme.pxToRem(props.height)};
`
const ModalSidebarPrimary = styled.div`
  position: relative;
  padding: 1.5rem;
  width: ${(props) => props.theme.pxToRem(264)};
  background-color: ${(props) => props.theme.palette.primary.pink500};
`
const ModalSidebarSecondary = styled.div`
  padding: 1.5rem;
  width: ${(props) => props.theme.pxToRem(264)};
  overflow-y: auto;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledChevronIcon = styled(ChevronIcon)`
  position: absolute;
  right: 1.5rem;

  transform: rotate(90deg);
  border-radius: 0.4375rem;
  padding: 0.25rem;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.palette.primary.pink700};
  }
`

const passModalProps = (
  children: React.ReactNode,
  onClose: (e: React.MouseEvent<any>) => void,
  hasShadow: boolean
) => {
  return Children.toArray(children).map((ele: any) => {
    return cloneElement(ele, {
      onClose,
      hasShadow,
    })
  })
}

export interface ModalProps extends SpaceProps, LayoutProps, FlexboxProps {
  open: boolean
  onClose: (e: React.MouseEvent<any>) => void
  children: React.ReactNode
  disableEsc?: boolean
  sidebarPrimaryChildren?: React.ReactNode
  sidebarSecondaryChildren?: React.ReactNode
  sidebarClose?: (e: React.MouseEvent<any>) => void
}

const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  (props: ModalProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      open = false,
      onClose,
      disableEsc,
      sidebarPrimaryChildren,
      sidebarSecondaryChildren,
      sidebarClose,
      children,
    } = props
    const [hasShadow, setHasShadow] = useState(0)
    const escapeKeyRef = React.useRef(null)
    const transitionRef = React.useRef(null)
    const mainAreaRef = React.useRef(null)

    const handleScroll = useCallback(
      (e: SyntheticEvent) => {
        const target = e.target as HTMLElement
        if (target.id === 'modal-body') {
          setHasShadow(target.scrollTop)
        }
      },
      [setHasShadow]
    )

    useGlobalScroll((e) => handleScroll(e))

    const onEscapeKey = React.useCallback(
      (evt) => {
        if (!open || disableEsc) return
        // esc keycode
        if (evt.keyCode === 27) {
          onClose(evt)
        }
      },
      [open, onClose, disableEsc]
    )

    React.useEffect(() => {
      escapeKeyRef.current = onEscapeKey
      document.addEventListener('keydown', escapeKeyRef.current, false)
      return () => {
        document.removeEventListener('keydown', escapeKeyRef.current, false)
      }
    }, [open, onEscapeKey])

    return (
      <Portal>
        <ModalStyle />
        <CSSTransition
          in={open}
          timeout={300}
          unmountOnExit
          classNames='modal'
          nodeRef={transitionRef}
        >
          <Background ref={transitionRef}>
            <ModalContainer {...props} ref={ref}>
              <ModalMainContent ref={mainAreaRef}>
                {passModalProps(children, onClose, hasShadow > 30)}
              </ModalMainContent>
              {(sidebarPrimaryChildren || sidebarSecondaryChildren) && (
                <ModalSidebar
                  height={mainAreaRef.current?.getBoundingClientRect()?.height}
                >
                  {sidebarPrimaryChildren && (
                    <ModalSidebarPrimary>
                      {sidebarClose && (
                        <StyledChevronIcon
                          height={32}
                          width={32}
                          onClick={sidebarClose}
                        />
                      )}
                      {sidebarPrimaryChildren}
                    </ModalSidebarPrimary>
                  )}
                  {sidebarSecondaryChildren && (
                    <ModalSidebarSecondary>
                      {sidebarSecondaryChildren}
                    </ModalSidebarSecondary>
                  )}
                </ModalSidebar>
              )}
            </ModalContainer>
          </Background>
        </CSSTransition>
      </Portal>
    )
  }
)

Modal.displayName = 'Modal'
export default buildForwardingComponent<HTMLDivElement, ModalProps>(Modal)
