import Add from 'core-system/Icons/Actions/Add'
import Minus from 'core-system/Icons/Actions/Minus'
import palette from 'core-system/Themes/palette'
import React, { Children, cloneElement, useState } from 'react'
import SegmentService from 'redux/config/services/SegmentService'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'

const Container = styled.div<
  FlexboxProps & SpaceProps & LayoutProps & { $active?: boolean }
>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 1rem;
  color: ${(props) => props.theme.palette.grey.grey400};
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border: 1px solid ${(props) => props.theme.palette.grey.grey700};
  border-radius: 0.5rem;
  outline: none;
  cursor: pointer;

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.pink400};
    background-color: ${(props) => props.theme.palette.white};
  }

  ${(props) =>
    props.$active
      ? {
          border: `1px solid ${props.theme.palette.text.secondary}`,
          backgroundColor: `${props.theme.palette.white}`,
          color: `${props.theme.palette.black}`,
        }
      : ''}

  ${flexbox}
  ${space}
  ${layout}
`

const Increments = styled.div`
  padding-left: 0.25rem;
  display: flex;
  align-items: center;

  svg {
    border-radius: 50%;
    margin: 0 0.25rem;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: ${(props) => props.theme.palette.primary.pink200};
    }
  }
`

const passNumberInputProps = (
  children: React.ReactNode,
  setIsHover: (isHover: boolean) => void,
  onValueChange: (value: number, callbackItem?: any) => void,
  value: number,
  callbackItem: any
) => {
  return Children.toArray(children).map((ele: any) => {
    return cloneElement(ele, {
      setIsHover,
      onInputValueChange: onValueChange,
      value,
      callbackItem,
    })
  })
}

export interface NumberInputProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  onValueChange: (value: number, callbackItem?: any) => void
  value: number
  callbackItem?: any
  iconSize?: number
  incrementVal?: number
  hasIncrements?: boolean
  analyticsParentName?: string
  analyticsName?: string
}

const NumberInput = React.memo((props: NumberInputProps) => {
  const {
    children,
    onValueChange,
    value,
    callbackItem = null,
    iconSize = 24,
    incrementVal = 5,
    hasIncrements = true,
    analyticsParentName = 'base',
    analyticsName = 'budget',
    ...rest
  } = props
  const [isHover, setIsHover] = useState(false)

  const handleIncrement = (val: number | undefined, increment: boolean) => {
    let newVal =
      increment && (val || val === 0) ? val + incrementVal : val - incrementVal
    if (!newVal && increment === true) {
      newVal = incrementVal
    } else if (newVal <= 0 && increment === false) {
      newVal = 0
    }

    SegmentService.track(`${analyticsParentName}Options-toggle-action`, {
      actionType: `${analyticsName}-${increment ? 'increment' : 'decrement'}`,
      newVal: newVal,
    })

    return callbackItem || callbackItem === 0
      ? onValueChange(newVal, callbackItem)
      : onValueChange(newVal)
  }

  return (
    <Container $active={isHover} {...rest}>
      {passNumberInputProps(
        children,
        setIsHover,
        onValueChange,
        value,
        callbackItem
      )}
      {hasIncrements && (
        <Increments className='number-input-increment'>
          <Add
            width={iconSize}
            height={iconSize}
            onClick={() => handleIncrement(value, true)}
            data-cy='number-input-add'
            color={palette.primary.pink700}
          />
          <Minus
            width={iconSize}
            height={iconSize}
            onClick={() => handleIncrement(value, false)}
            data-cy='number-input-subtract'
            color={palette.primary.pink700}
          />
        </Increments>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  NumberInput.displayName = 'NumberInput'
}

export default NumberInput
