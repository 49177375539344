import Button from 'core-system/Button'
import FlexContainer from 'core-system/FlexContainer'
import EditIcon from 'core-system/Icons/Actions/Edit'
import AlertIcon from 'core-system/Icons/Misc/Alert'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import Tooltip from 'core-system/Tooltip'
import pxToRem from 'core-system/utils/pxToRem'
import UploadDataModal from 'features/Segments/components/UploadDataModal'
import MyCommutersPending from 'features/Segments/MyCommuters/MyCommutersPending'
import MyCommutersView from 'features/Segments/MyCommuters/MyCommutersView'
import SecondarySidebarView from 'features/Sidebar/SecondarySidebar/SecondarySidebarView'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'
import SegmentEditModal from './components/SegmentEditModal'
import SegmentsUpcomingChanges from './components/SegmentsUpcomingChanges'
import ArchiveSegmentModal from './MyCommuters/ArchiveSegmentModal'
import MyCommutersArchived from './MyCommuters/MyCommutersArchived'
import MyCommutersDeclined from './MyCommuters/MyCommutersDeclined'
import SegmentsSidebar from './SegmentsSidebar'
import { employeeActions } from 'redux/employee/employeeSlice'
import InviteUsers from './components/InviteUsers'
import { employerActions } from 'redux/employer/employerSlice'

const Container = styled.div`
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    flex-direction: column-reverse;
  }
`

const MainView = styled.div`
  width: 100%;
  overflow: auto;
  padding: 2rem 4rem;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  max-width: 75rem;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    padding: 1.875rem 1rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    margin: 0 0 5rem 0;
    padding: 2rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 1rem 1.5rem 2rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    padding: 1rem 0.5rem 2rem;
  }
`

const StyledButton = styled(Button)`
  span {
    margin-bottom: 0.125rem;
  }
`

const CustomButton = styled.div`
  padding: 0.25rem;
  border-radius: 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  height: 2rem;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.placeholder};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink500};
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const specialSegments = ['archived', 'declined']

const SegmentsView = React.memo(() => {
  const dispatch = useDispatch()
  const ref = useRef(null)

  const {
    myCommuters,
    allCommuters,
    archivedEmployees,
    declinedEmployees,
    allCommutersMap,
  } = useSelector((state: AppState) => state.employee)
  const {
    segmentsType,
    segmentsMap,
    segmentUpdateFlag,
    allEmployeeSegmentId,
    employeesDeclinedFlag,
    finchConnectLoading,
  } = useSelector((state: AppState) => state.employer)

  const [isEdit, setIsEdit] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [activeSegment, setActiveSegment] = useState(allEmployeeSegmentId)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isSegmentEditModalOpen, setIsSegmentEditModalOpen] = useState(false)
  const [upcomingChangesModal, setUpcomingChangesModal] = useState(false)
  const [uploadSegment, setUploadSegment] = useState(null)
  const [showPending, setShowPending] = useState(false)
  const [isSegmentEditTooltipVisible, setIsSegmentEditTooltipVisible] =
    useState(false)

  //get archived employees
  useEffect(() => {
    dispatch(employeeActions.getArchivedEmployees())
  }, [dispatch])

  useEffect(() => {
    if (
      (!myCommuters ||
        (!myCommuters[activeSegment] && Object.keys(myCommuters).length > 0)) &&
      !specialSegments.includes(activeSegment)
    ) {
      dispatch(
        employeeActions.getMyCommuters({
          segment: activeSegment,
        })
      )
    }
  }, [myCommuters, activeSegment, dispatch])

  //get all commuters when segments get updated
  useEffect(() => {
    if (segmentUpdateFlag && Object.keys(myCommuters).length === 0) {
      dispatch(
        employeeActions.getMyCommuters({
          segment: segmentsType.all[0].id,
        })
      )
    }
  }, [dispatch, segmentUpdateFlag, segmentsType, myCommuters])

  // Refetch commuters after declining an employee
  useEffect(() => {
    if (employeesDeclinedFlag) {
      dispatch(employeeActions.getCommuters())
      dispatch(employerActions.toggleDeclineInactiveEmployeesFlag())
      setShowPending(false)
    }
  }, [employeesDeclinedFlag, dispatch])

  const handleSegmentChange = useCallback(
    (params: { segmentId: string; creationType?: number }) => {
      setActiveSegment(params.segmentId)
      setIsEdit(false)
      setShowPending(false)
      setIsAdd(params.creationType === 0 ? true : false)
      if (params.creationType === 1) {
        setUploadSegment(segmentsMap[params.segmentId])
        setIsUploadModalOpen(true)
      }
    },
    [segmentsMap]
  )

  const handleUploadModalOpen = useCallback(
    (segment?: string) => {
      if (segment) {
        setUploadSegment(segmentsMap[segment])
      }
      setIsUploadModalOpen(true)
    },
    [segmentsMap]
  )

  const handleUploadModalClose = useCallback((status?: number) => {
    if (status >= 1) {
      setIsAdd(false)
    }
    setIsUploadModalOpen(false)
  }, [])

  if (
    !myCommuters ||
    !allCommuters ||
    !allCommutersMap ||
    !archivedEmployees ||
    !declinedEmployees ||
    finchConnectLoading
  ) {
    return <Loading fullPage />
  }

  const isArchived =
    activeSegment === 'archived' ||
    (segmentsMap && segmentsMap[activeSegment]?.archived)

  const isDeclined = activeSegment === 'declined'

  const isNormalView = !isArchived && !showPending && !isDeclined

  const hasManagementOptions =
    isNormalView && segmentsMap && segmentsMap[activeSegment]?.type === 'CUSTOM'

  const pendingEmployees = allCommuters.filter(
    // employees that have not yet been activated
    (commuter) => !commuter.approved
  )

  const showPendingBtn =
    !isArchived &&
    !isDeclined &&
    segmentsMap &&
    segmentsMap[activeSegment]?.type === 'ALL' &&
    pendingEmployees.length > 0

  const activeUpcomingAdditions =
    !isArchived &&
    !isDeclined &&
    segmentsMap &&
    segmentsMap[activeSegment]?.upcomingAdditions

  const upcomingChanges = !segmentsMap
    ? 0
    : isNormalView &&
      (activeUpcomingAdditions?.length > 0 ||
        segmentsMap[activeSegment]?.upcomingRemovals?.length > 0) &&
      activeUpcomingAdditions?.length +
        (segmentsMap[activeSegment]?.upcomingRemovals?.length || 0)

  return (
    <>
      <Container>
        <SecondarySidebarView hasTitle={false}>
          <SegmentsSidebar
            segmentsType={segmentsType}
            activeSegment={activeSegment}
            handleSegmentChange={handleSegmentChange}
            handleOpenModal={handleUploadModalOpen}
          />
        </SecondarySidebarView>
        <MainView>
          {/* UPCOMING ADDITIONS, ARCHIVED EMPS, PENDING SECTION */}
          {isArchived && (
            <MyCommutersArchived
              archivedEmployees={archivedEmployees}
              activeSegment={activeSegment}
            />
          )}
          {isDeclined && (
            <MyCommutersDeclined declinedEmployees={declinedEmployees} />
          )}

          {/* PENDING COMMUTERS VIEW */}
          {showPending && (
            <>
              <FlexContainer
                justifyContent='space-between'
                alignItems='center'
                marginBottom='1.5rem'
                height={pxToRem(35)}
              >
                <Text variant='h3' marginRight='0.5rem'>
                  {pendingEmployees.length} Pending Commuter
                  {RandomUtils.pluralCheck(pendingEmployees.length)}
                </Text>
              </FlexContainer>
              <FlexContainer
                justifyContent='space-between'
                alignItems='center'
                marginBottom='1.5rem'
              >
                <Text variant='body1'>
                  These commuters have signed up but aren’t part of any groups
                  yet. Select and approve the commuters you authorize to be part
                  of the platform. Once approved they will be part of{' '}
                  <strong>{segmentsMap[activeSegment].name}</strong>. Any
                  declined commuters will be removed.
                </Text>
              </FlexContainer>
              <MyCommutersPending
                pendingCommuters={pendingEmployees}
                showPending={showPending}
                setShowPending={setShowPending}
                activeSegment={activeSegment}
              />
            </>
          )}
          {/* PENDING COMMUTERS VIEW END */}

          {/* NORMAL VIEW */}
          {isNormalView ? (
            <FlexContainer flexDirection='column' marginBottom='1.5rem'>
              {/* SEGMENT NAME + BUTTONS */}
              <FlexContainer
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
              >
                {/* SEGMENT NAME + EDIT NAME BUTTON FOR CUSTOM SEGMENTS + UPCOMING CHANGES */}
                <FlexContainer flexDirection='row' alignItems='center'>
                  <Text variant='h3' dataCy='custom-segment-title'>
                    {segmentsMap
                      ? segmentsMap[activeSegment]?.name === 'All Employees'
                        ? 'All Commuters'
                        : segmentsMap[activeSegment]?.name
                      : null}
                  </Text>
                  {hasManagementOptions ? (
                    <CustomButton
                      onClick={() => setIsSegmentEditModalOpen(true)}
                      ref={ref}
                      onMouseEnter={() => setIsSegmentEditTooltipVisible(true)}
                      onMouseLeave={() => setIsSegmentEditTooltipVisible(false)}
                    >
                      <EditIcon width='1.5rem' height='1.5rem' />
                      <Tooltip
                        target={ref}
                        show={isSegmentEditTooltipVisible}
                        position={'bottom'}
                        marginTop={'0.5rem'}
                      >
                        Edit name
                      </Tooltip>
                    </CustomButton>
                  ) : null}
                  {upcomingChanges && (
                    <StyledButton
                      variant='tertiary'
                      size='small'
                      iconLeft={<AlertIcon height={22} width={22} />}
                      marginX='1rem'
                      onClick={() => setUpcomingChangesModal(true)}
                      data-cy='segments-upcoming-changes-btn'
                    >
                      {upcomingChanges} Upcoming Changes
                    </StyledButton>
                  )}
                  {!isEdit && !isAdd && showPendingBtn && (
                    <FlexContainer>
                      <StyledButton
                        variant='tertiary'
                        size='small'
                        iconLeft={<AlertIcon height={22} width={22} />}
                        onClick={() => setShowPending(true)}
                        marginLeft='1rem'
                      >
                        {pendingEmployees.length} Pending Commuter
                        {RandomUtils.pluralCheck(pendingEmployees.length)}
                      </StyledButton>
                    </FlexContainer>
                  )}
                </FlexContainer>
                {/* HEADER BUTTONS */}
                <FlexContainer flexDirection='row' alignItems='center'>
                  {!isEdit && !isAdd ? (
                    <InviteUsers segmentId={activeSegment} />
                  ) : null}
                  {hasManagementOptions && !isAdd && !isEdit ? (
                    <FlexContainer>
                      <Button
                        size='small'
                        marginX='1rem'
                        onClick={() => setIsAdd((prev) => !prev)}
                        minWidth='8rem'
                      >
                        Add Commuters
                      </Button>
                      <Button
                        size='small'
                        onClick={() => setIsEdit((prev) => !prev)}
                      >
                        Manage
                      </Button>
                    </FlexContainer>
                  ) : null}
                  {hasManagementOptions && isAdd ? (
                    <Button
                      size='small'
                      onClick={() => handleUploadModalOpen(activeSegment)}
                      marginLeft='0.5rem'
                      minWidth='11rem'
                    >
                      Import Commuter Data
                    </Button>
                  ) : null}
                  {hasManagementOptions && isEdit ? (
                    <Button
                      variant='cancel'
                      onClick={() => setIsDeleteModalOpen(true)}
                      size='small'
                      marginLeft='0.5rem'
                      minWidth='8rem'
                    >
                      Archive Group
                    </Button>
                  ) : null}
                </FlexContainer>
              </FlexContainer>
              {/* EDIT HELPER COPY FOR CUSTOM SEGMENTS */}
              {isAdd ? (
                <FlexContainer marginTop='1rem'>
                  <Text variant='body1'>
                    Select commuters you&apos;d like to add from{' '}
                    <strong>All Commuters</strong> to{' '}
                    <strong>
                      {segmentsMap ? segmentsMap[activeSegment]?.name : null}
                    </strong>
                    :
                  </Text>
                </FlexContainer>
              ) : null}
            </FlexContainer>
          ) : null}

          {/* COMMUTER TABLE SECTION */}
          {isNormalView && segmentsMap ? (
            <MyCommutersView
              myCommuters={
                isAdd
                  ? myCommuters[segmentsType.all[0].id]
                  : myCommuters[activeSegment]
              }
              activeCommuters={isAdd ? myCommuters[activeSegment] : []}
              isAdd={isAdd}
              isEdit={isEdit}
              setIsAdd={setIsAdd}
              setIsEdit={setIsEdit}
              activeSegment={segmentsMap[activeSegment]}
            />
          ) : null}
          {/* NORMAL VIEW END */}
        </MainView>
      </Container>

      {/* MODALS */}
      <UploadDataModal
        open={isUploadModalOpen}
        closeModal={handleUploadModalClose}
        segment={uploadSegment}
      />
      {isNormalView && (
        <>
          <ArchiveSegmentModal
            open={isDeleteModalOpen}
            closeModal={() => setIsDeleteModalOpen(false)}
            segment={segmentsMap ? segmentsMap[activeSegment] || null : null}
          />
          <SegmentsUpcomingChanges
            open={upcomingChangesModal}
            custom={
              segmentsMap
                ? segmentsMap[activeSegment]?.type === 'CUSTOM'
                : false
            }
            closeModal={() => setUpcomingChangesModal(false)}
            activeSegmentId={activeSegment}
            upcomingAdditions={activeUpcomingAdditions}
            upcomingRemovals={
              segmentsMap ? segmentsMap[activeSegment]?.upcomingRemovals : []
            }
          />
          <SegmentEditModal
            open={isSegmentEditModalOpen}
            closeModal={() => {
              setIsSegmentEditModalOpen(false)
            }}
            segment={segmentsMap ? segmentsMap[activeSegment] : null}
          />
        </>
      )}
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SegmentsView.displayName = 'SegmentsView'
}

export default SegmentsView
