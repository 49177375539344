import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'
import { Tooltip } from '@mui/material'
import Text from 'core-system/Text'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TrainIcon from '@mui/icons-material/Train'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { combineAllWalkSteps, getDistance } from './googleMapsFunctions'

interface JourneyPlannerProps {
  children: React.ReactNode
  steps: any[]
}

const commonContainerStyles = css`
  position: absolute;
  background: white;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0px -7px 5px -5px ${({ theme }) => theme.palette.primary.pink600};
  width: 100%;
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.palette.primary.pink300};

  &:hover {
    cursor: pointer;
    box-shadow: 0px -8px 5px -5px
      ${({ theme }) => theme.palette.primary.pink700};
  }

  transition:
    height 0.3s ease-in-out,
    top 0.3s ease-in-out;
`

const AnimatedContainer = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  position: absolute;
  top: ${({ isExpanded }) => (isExpanded ? '75px' : '400px')};
  height: ${({ isExpanded }) => (isExpanded ? '400px' : '80px')};
  transition:
    height 0.3s ease-in-out,
    top 0.3s ease-in-out;
  ${commonContainerStyles}
`

const TopBarContainer = styled.div`
  border-radius: 12px;
  height: 3px;
  width: 60px;
  background-color: ${({ theme }) => theme.palette.primary.pink600};
  position: absolute;
  left: 50%;
  top: 8px;
  transform: translate(-50%, -50%);
  box-shadow: 1px 1px 2px 1px ${({ theme }) => theme.palette.primary.pink400};
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
`

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`

const ClosingHiddenContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 80px;
  z-index: 1;
  cursor: pointer;
`

const HeaderContainer = styled.div`
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-x: auto;
`

const HeaderInnerContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

const InnerItem = styled.div`
  margin-right: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
`

const ShortName = styled(Text)<{ color: string }>`
  font-weight: bold;
  background-color: ${({ color }) => color};
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: white;
`

const TopBar = () => <TopBarContainer />

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <Tooltip title='Close' placement='top'>
    <CloseButtonContainer onClick={onClick}>
      <CloseIcon sx={{ fontSize: 24 }} />
    </CloseButtonContainer>
  </Tooltip>
)

const Header = ({ combinedSteps }: { combinedSteps: any[] }) => {
  return (
    <HeaderContainer>
      <Text variant='body1' style={{ fontWeight: 'bold' }}>
        Journey Planner
      </Text>
      <HeaderInnerContainer>
        {combinedSteps.map((step, index) => {
          const arrayToRender = []
          if (step.travelMode === 'WALK') {
            arrayToRender.push(
              <ItemContainer>
                <InnerItem>
                  <DirectionsWalkIcon color='secondary' />
                </InnerItem>
                <InnerItem>
                  <Text variant='body1'>
                    <b>{getDistance(step.distance)}</b>km
                  </Text>
                </InnerItem>
                <InnerItem>
                  <AccessTimeIcon
                    color='success'
                    style={{ fontSize: 18, marginRight: 2 }}
                  />
                  <Text variant='body1'>
                    <b>{Math.floor(step.duration / 60)}</b>m
                  </Text>
                </InnerItem>
              </ItemContainer>
            )
          }
          if (step.travelMode === 'TRANSIT') {
            arrayToRender.push(
              <ItemContainer>
                <InnerItem style={{ height: 24, width: 24 }}>
                  {step.icon ? (
                    <img
                      src={step.icon}
                      alt='transit'
                      style={{ height: 24, width: 24, objectFit: 'contain' }}
                    />
                  ) : (
                    <TrainIcon color='inherit' />
                  )}
                </InnerItem>
                <InnerItem>
                  <ShortName variant='body1' color={step.transitLineColor}>
                    {step.transitLineNameShort}
                  </ShortName>
                </InnerItem>
                <InnerItem>
                  <Text variant='body1'>
                    <b>{getDistance(step.distance)}</b>km
                  </Text>
                </InnerItem>
                <InnerItem>
                  <AccessTimeIcon
                    color='success'
                    style={{ fontSize: 18, marginRight: 2 }}
                  />
                  <Text variant='body1'>
                    <b>{Math.floor(step.duration / 60)}</b>m
                  </Text>
                </InnerItem>
              </ItemContainer>
            )
          }
          // Adding an carrot if not the last step
          if (index !== combinedSteps.length - 1) {
            arrayToRender.push(
              <ItemContainer key={index}>
                <ChevronRightIcon color='inherit' />
              </ItemContainer>
            )
          }

          return arrayToRender
        })}
      </HeaderInnerContainer>
    </HeaderContainer>
  )
}

const JourneyPlannerContainer = ({ children, steps }: JourneyPlannerProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const combinedSteps = combineAllWalkSteps(steps)

  if (!combinedSteps || combinedSteps.length === 0) {
    return null
  }

  return (
    <div>
      {isExpanded && (
        // This is a hidden container that is used to close the journey planner
        <ClosingHiddenContainer onClick={() => setIsExpanded(false)} />
      )}
      <Tooltip title={isExpanded ? '' : 'Expand'} placement='top'>
        <AnimatedContainer
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <TopBar />
          {isExpanded ? (
            <>
              <CloseButton onClick={() => setIsExpanded(false)} />
              <Header combinedSteps={combinedSteps} />
              {children}
            </>
          ) : (
            <Header combinedSteps={combinedSteps} />
          )}
        </AnimatedContainer>
      </Tooltip>
    </div>
  )
}

export default React.memo(JourneyPlannerContainer)
