import Tooltip from 'core-system/Tooltip'
import React, { useRef, useState } from 'react'
import FormattingUtils from 'shared/FormattingUtils'
import NumberUtils from 'shared/NumberUtils'
import styled from 'styled-components'
import Category from './Category'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import LegendColor from 'core-system/Legend'

interface CategoryProp {
  title?: string
  value: number
  color: string
}

const Container = styled.div`
  display: flex;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.grey.grey200};
  width: 100%;
  overflow: hidden;
`

interface CategoryBreakdownProps {
  categories: CategoryProp[]
  total: number
  isCurrency?: boolean
  isBalanceBreakdown?: boolean
}

const CategoryBreakdown = React.memo((props: CategoryBreakdownProps) => {
  const {
    categories,
    total,
    isCurrency = false,
    isBalanceBreakdown = false,
  } = props

  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const ref = useRef(null)

  return (
    <Container
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
      ref={ref}
    >
      {categories.map((category, idx) => (
        <Category
          key={idx}
          width={category.value ? (category.value / total) * 100 : 0}
          color={category.color}
          value={
            isCurrency
              ? FormattingUtils.formatDollar(category.value)
              : NumberUtils.formatNumber(category.value)
          }
          disableToolTip={isBalanceBreakdown}
        />
      ))}
      {isBalanceBreakdown && (
        <Tooltip
          target={ref}
          show={isTooltipVisible}
          position='bottom'
          marginTop='0.25rem'
        >
          <FlexContainer
            flexDirection='column'
            minWidth='12rem'
            padding='0.1rem'
          >
            <FlexContainer alignItems='center'>
              <Text
                variant='action4'
                marginBottom='0.75rem'
                textColor={palette.white}
              >
                Balance Breakdown
              </Text>
            </FlexContainer>
            <FlexContainer
              flexDirection='column'
              minHeight={categories.length > 2 ? '5rem' : '3rem'}
              justifyContent='space-between'
            >
              {categories.map((category, idx) => (
                <FlexContainer
                  key={idx}
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <FlexContainer alignItems='center'>
                    <LegendColor
                      color={category.color}
                      marginRight='0.5rem'
                      marginBottom='0.125rem'
                    />
                    <Text variant='body2' textColor={palette.white}>
                      {category.title}
                    </Text>
                  </FlexContainer>
                  <Text variant='body2' textColor={palette.white}>
                    {FormattingUtils.formatDollar(category.value)}
                  </Text>
                </FlexContainer>
              ))}
            </FlexContainer>
          </FlexContainer>
        </Tooltip>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CategoryBreakdown.displayName = 'CategoryBreakdown'
}

export default CategoryBreakdown
