import React from 'react'
import { buildForwardingComponent } from '../utils/buildComponent'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  variant,
  BorderProps,
  border,
} from 'styled-system'
import { size, SizeProps } from '../@styled-system/Size'
import pxToRem from '../utils/pxToRem'

const Button = styled.button<
  SpaceProps &
    FlexboxProps &
    BorderProps &
    SizeProps & { active?: boolean; darkBg?: boolean; iconOnly?: boolean }
>`
  ${(props) => props.theme.typography.action5}
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1;
  outline: none;
  position: relative;

  &:disabled {
    pointer-events: none;
    color: ${(props) => props.theme.palette.text.disabled};
  }

  ${(props) =>
    variant({
      prop: 'size',
      variants: {
        small: {
          ...props.theme.typography.action4,
          padding: `${pxToRem(6)} ${pxToRem(props.iconOnly ? 6 : 10)}`,
          borderRadius: '0.5rem',
        },
        medium: {
          padding: `${pxToRem(7.5)} ${pxToRem(props.iconOnly ? 7.5 : 16)}`,
          borderRadius: '0.5rem',
        },
        large: {
          padding: `${pxToRem(11.5)} ${pxToRem(props.iconOnly ? 11.5 : 24)}`,
          borderRadius: pxToRem(8),
        },
      },
    })}

  ${(props) =>
    variant({
      variants: {
        primary: {
          backgroundColor: props.active
            ? props.darkBg
              ? props.theme.palette.grey.grey300
              : props.theme.palette.primary.pink200
            : props.darkBg
              ? props.theme.palette.white
              : props.theme.palette.primary.pink200,
          border: `2px solid ${
            props.darkBg
              ? props.theme.palette.grey.grey300
              : props.theme.palette.primary.pink800
          }`,
          color: props.darkBg
            ? props.theme.palette.white
            : props.theme.palette.primary.pink800,
          '&:hover, &:active': {
            backgroundColor: props.darkBg
              ? props.theme.palette.grey.grey300
              : props.theme.palette.white,
            border: `2px solid ${
              props.darkBg
                ? props.theme.palette.grey.grey300
                : props.theme.palette.primary.pink800
            }`,
            color: props.darkBg
              ? props.theme.palette.white
              : props.theme.palette.primary.pink800,
          },
          '&:disabled': {
            backgroundColor: props.theme.palette.grey.grey200,
            border: `2px solid ${props.theme.palette.grey.grey200}`,
          },
        },
        secondary: {
          backgroundColor: props.active
            ? props.darkBg
              ? props.theme.palette.grey.grey400
              : props.theme.palette.primary.pink200
            : props.theme.palette.transparent,
          color: props.darkBg
            ? props.active
              ? props.theme.palette.primary.pink800
              : props.theme.palette.white
            : props.theme.palette.primary.pink800,
          border: `2px solid ${
            props.darkBg
              ? props.theme.palette.white
              : props.theme.palette.primary.pink800
          }`,
          '&:hover, &:active': {
            backgroundColor: props.theme.palette.primary.pink200,
            color: props.darkBg
              ? props.theme.palette.primary.pink600
              : props.theme.palette.primary.pink800,
          },
          '&:disabled': {
            borderColor: props.theme.palette.grey.grey200,
          },
        },
        tertiary: {
          backgroundColor: props.active
            ? props.theme.palette.primary.pink500
            : props.theme.palette.transparent,
          color: props.theme.palette.text.secondary,
          border: 'none',
          '&:hover, &:active': {
            backgroundColor: props.theme.palette.primary.pink500,
            color: props.theme.palette.primary.pink500,
          },
          '&:disabled': {
            backgroundColor: props.theme.palette.transparent,
          },
        },
        cancel: {
          backgroundColor: props.active
            ? props.theme.palette.error.red600
            : props.theme.palette.transparent,
          color: props.theme.palette.error.red500,
          border: 'none',
          '&:hover, &:active': {
            backgroundColor: props.theme.palette.error.red600,
            color: props.theme.palette.error.red500,
          },
          '&:disabled': {
            backgroundColor: props.theme.palette.transparent,
          },
        },
        green: {
          backgroundColor: props.active
            ? props.theme.palette.success.green700
            : props.theme.palette.success.green500,
          color: props.theme.palette.white,
          border: 'none',
          '&:hover, &:active': {
            backgroundColor: props.theme.palette.success.green700,
            color: props.theme.palette.success.green500,
          },
          '&:disabled': {
            backgroundColor: props.theme.palette.grey.grey200,
          },
        },
        red: {
          backgroundColor: props.active
            ? props.theme.palette.error.red600
            : props.theme.palette.error.red500,
          color: props.theme.palette.white,
          border: 'none',
          '&:hover, &:active': {
            backgroundColor: props.theme.palette.error.red600,
            color: props.theme.palette.error.red500,
          },
          '&:disabled': {
            backgroundColor: props.theme.palette.grey.grey2,
          },
        },
      },
    })}

  ${flexbox}
  ${space}
  ${border}
  ${size}
`

const IconContainer = styled.span`
  display: inherit;
`

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'cancel'
  | 'red'
  | 'green'
type size = 'small' | 'medium' | 'large'

export interface ButtonProps
  extends FlexboxProps,
    SpaceProps,
    BorderProps,
    SizeProps,
    React.HtmlHTMLAttributes<HTMLButtonElement> {
  as?: 'button' | 'a' | 'div'
  variant?: ButtonVariants
  size?: size
  disabled?: boolean
  active?: boolean
  iconRight?: React.ReactNode
  iconLeft?: React.ReactNode
  dataCy?: string
  darkBg?: boolean
}

const CustomButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const {
      as,
      size = 'medium',
      variant = 'primary',
      children,
      iconRight,
      iconLeft,
      active,
      dataCy,
      darkBg = false,
    } = props
    return (
      <Button
        as={as}
        ref={ref}
        size={size}
        variant={variant}
        active={active}
        data-cy={dataCy}
        darkBg={darkBg}
        iconOnly={(iconLeft || iconRight) && !children}
        {...props}
      >
        {iconLeft && (
          <IconContainer
            style={
              children
                ? {
                    marginRight: '0.5rem',
                    marginLeft: size === 'large' ? '-0.5rem' : '-0.25rem',
                  }
                : {}
            }
          >
            {iconLeft}
          </IconContainer>
        )}
        {children}
        {iconRight && (
          <IconContainer
            style={
              children
                ? {
                    marginRight: size === 'large' ? '-0.5rem' : '-0.25rem',
                    marginLeft: '0.5rem',
                  }
                : {}
            }
          >
            {iconRight}
          </IconContainer>
        )}
      </Button>
    )
  }
)

CustomButton.displayName = 'Button'
export default buildForwardingComponent<HTMLButtonElement, ButtonProps>(
  CustomButton
)
