import * as React from 'react'
import createSvgIcon from '../../createSvgIcon'

export default createSvgIcon(
  <>
    <mask
      id='mask0'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='14'
      height='17'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 0H13.355V16.058H0V0Z'
        fill='white'
      />
    </mask>
    <g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.3069 6.13L10.3899 6.222L7.54791 4.022C7.99491 3.596 8.27591 2.999 8.27591 2.334C8.27591 1.045 7.23091 0 5.94191 0C4.65391 0 3.60891 1.045 3.60891 2.334C3.60891 3.116 3.99691 3.806 4.58791 4.229L0.337912 7.977C-0.0760885 8.342 -0.115088 8.974 0.249912 9.388C0.447912 9.612 0.722912 9.727 0.999912 9.727C1.23491 9.727 1.47091 9.645 1.66091 9.477L4.24291 7.2L4.37691 9.379L3.74291 11.973L1.87391 13.526C1.34291 13.966 1.26991 14.754 1.71091 15.285C1.95791 15.583 2.31491 15.736 2.67291 15.736C2.95491 15.736 3.23791 15.642 3.47191 15.447L5.97491 13.367L6.61591 10.749L8.17991 12.379L8.85591 15.109C8.99691 15.678 9.50791 16.058 10.0689 16.058C10.1679 16.058 10.2689 16.046 10.3699 16.021C11.0399 15.855 11.4489 15.177 11.2829 14.507L10.4449 11.129L8.28391 8.875L8.00191 6.902L9.74891 8.255L12.4029 8.128C12.9539 8.102 13.3799 7.633 13.3539 7.081C13.3269 6.529 12.8519 6.082 12.3069 6.13'
        fill='#6200E0'
      />
    </g>
  </>,
  'Programs Walk',
  '0 0 18 18'
)
