import * as React from 'react'
import createSvgIcon from '../Icons/createSvgIcon'

export default createSvgIcon(
  <>
    <svg
      width='36'
      height='32'
      viewBox='0 0 36 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.86984e-05 31.8711C5.27046 31.8711 9.54297 27.5986 9.54297 22.3282L9.54297 12.4672V12.4672C4.27257 12.4672 5.93599e-05 16.7397 5.91295e-05 22.0101L5.86984e-05 31.8711V31.8711Z'
        fill='#E5E5E5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.595 9.67265C25.978 9.67265 26.3434 9.67163 26.6918 9.66808H30.4972C33.1628 9.66808 35.3238 7.50715 35.3238 4.84151C35.3238 4.74351 35.3208 4.6462 35.3151 4.54966H35.3404V0.0149304H30.4985L30.4972 0.0149302H26.1201L25.1316 0.0141893C24.4054 0.00769788 23.6784 0.0109436 22.9513 0.0141893C22.391 0.0141893 21.8307 0.0139866 21.2704 0.0137838C20.7102 0.013581 20.1499 0.0133782 19.5896 0.0133782C19.0728 0.0120254 18.556 0.0104921 18.0392 0.00895892C17.0062 0.00589427 15.9734 0.00282997 14.9409 0.00120741C13.529 -0.00122688 11.4794 -0.000415262 10.0675 0.00769905H10.0642C10.0171 0.00769905 9.97011 0.00769905 9.92309 0.00850986C4.12217 0.0831615 0.0730288 4.70066 0 10.5016V10.5332C0 10.5806 0.000201994 10.6282 0.000404417 10.6758L0.000404429 10.6758C0.00060771 10.7237 0.000811421 10.7717 0.000811421 10.8197V10.8383C0.0148442 11.5869 0.00985022 12.3224 0.00493371 13.0464C0.00245862 13.4109 3.17268e-06 13.7725 4.76988e-06 14.1315L0.00243903 14.1388C1.50521 10.9734 4.28273 9.66535 8.02505 9.66454H8.02714H8.02899C9.07442 9.66454 10.1207 9.66454 11.1661 9.66616C11.8373 9.67215 12.5084 9.66985 13.1796 9.66754L13.3472 9.66697C13.9019 9.66737 14.4565 9.66757 15.0111 9.66778H15.0114H15.0117L15.0276 9.66778C15.5871 9.66798 16.1465 9.66819 16.7057 9.66859L25.595 9.67265ZM9.54291 12.183C4.27251 12.183 0 16.4555 0 21.7259H20.2897C25.5601 21.7259 29.8326 17.4534 29.8326 12.183H9.54291Z'
        fill='white'
      />
    </svg>
  </>,
  'FleetLogo',
  '0 0 36 32'
)
