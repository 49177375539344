import React, { useCallback, useState } from 'react'
import { Point } from 'redux/employeePlatform/employeePlatformTypes'
import styled from 'styled-components'
import ReactMapGL, { Popup } from 'react-map-gl/mapbox'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { mapboxConfig } from 'shared/MapboxConfig'
import { Feature, FeatureCollection } from 'geojson'
import MapIntelligenceMarker from 'features/MapIntelligence/MapIntelligenceMarker'
import SegmentService from 'redux/config/services/SegmentService'
import CarpoolMapPopup from './CarpoolMapPopup'
import CarpoolMapLegend from './CarpoolMapLegend'
import { CarpoolMapLegendColors } from '../CarpoolUtils'

const MapContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;

  .mapboxgl-ctrl-bottom-right {
    display: flex;
    align-items: center;
    height: 2.5rem;
  }

  .mapboxgl-ctrl-attrib-button:focus {
    box-shadow: none;
  }
`

const StyledPopup = styled(Popup)`
  z-index: ${(props) => props.theme.zIndex.tooltip};
  cursor: pointer;

  & > .mapboxgl-popup-content {
    border-radius: 0.9375rem;
    box-shadow: ${(props) => props.theme.dropShadows.normal};
    padding: 0.625rem;

    & > .mapboxgl-popup-close-button {
      top: 0.4375rem;
      right: 0.5rem;
      color: ${(props) => props.theme.palette.text.primary};
      font-size: 1.75rem;
      font-family: PolySans;
      line-height: 2rem;

      &:hover {
        background-color: transparent;
      }

      &:focus {
        outline: none;
      }
    }
  }
`

const getFeatureCollection = (
  details: any[],
  myFeatures: boolean,
  isRequest: boolean
): FeatureCollection => {
  const features = details.reduce((feats, detail) => {
    feats.push(
      // Add Source Coordinates
      {
        geometry: detail.source,
        id: `${detail.id}-SOURCE`,
        properties: {
          pointType: 'source',
          myFeatures: myFeatures,
          driver: detail.driver,
          passenger: detail.passenger,
          markerColor: myFeatures
            ? CarpoolMapLegendColors.mySource
            : CarpoolMapLegendColors.otherSource,
          distFromMe: myFeatures ? null : detail.distanceSource,
          email: detail.contactInfo,
          isRequest: isRequest,
          name: isRequest ? 'Requestor' : detail.firstName,
        },
      },
      // Add Dest Coordinates
      {
        geometry: detail.dest,
        id: `${detail.id}-DEST`,
        properties: {
          pointType: 'dest',
          myFeatures: myFeatures,
          driver: detail.driver,
          passenger: detail.passenger,
          markerColor: myFeatures
            ? CarpoolMapLegendColors.myDest
            : CarpoolMapLegendColors.otherDest,
          distFromMe: myFeatures ? null : detail.distanceDest,
          email: detail.contactInfo,
          isRequest: isRequest,
          name: isRequest ? 'Requestor' : detail.firstName,
        },
      }
    )
    return feats
  }, [])
  return {
    features: features,
    type: 'FeatureCollection',
  }
}

const getMiddleCoords = (coords: Point[]) => {
  const maxLng = Math.max(...coords.map((coord) => coord.coordinates[0]))
  const minLng = Math.min(...coords.map((coord) => coord.coordinates[0]))
  const maxLat = Math.max(...coords.map((coord) => coord.coordinates[1]))
  const minLat = Math.min(...coords.map((coord) => coord.coordinates[1]))

  return {
    lng: (maxLng + minLng) / 2,
    lat: (maxLat + minLat) / 2,
  }
}

interface CarpoolMapProps {
  isMobile: boolean
  groupId: string
  requestId?: string
}

const CarpoolMap = React.memo((props: CarpoolMapProps) => {
  const { isMobile, groupId, requestId } = props

  const {
    allCarpoolGroups,
    myCarpoolGroups,
    carpoolMemberships,
    receivedCarpoolRequests,
  } = useSelector((state: AppState) => state.employeePlatform)

  const carpoolDetails = myCarpoolGroups[groupId]
    ? myCarpoolGroups[groupId]
    : allCarpoolGroups[groupId]
  const isMyCarpool = myCarpoolGroups[groupId] ? true : false

  // Get the midpoint of all coordinates to center the map
  const midpointCoords = getMiddleCoords(
    isMyCarpool && !requestId
      ? Object.keys(myCarpoolGroups[groupId].members)
          .reduce((coords, member) => {
            const memberDetails = myCarpoolGroups[groupId].members[member]
            coords.push(memberDetails.source, memberDetails.dest)
            return coords
          }, [])
          .concat([carpoolDetails.source, carpoolDetails.dest])
      : [carpoolDetails.source, carpoolDetails.dest].concat(
          requestId
            ? [
                receivedCarpoolRequests[groupId][requestId].source,
                receivedCarpoolRequests[groupId][requestId].dest,
              ]
            : [
                carpoolMemberships[groupId].source,
                carpoolMemberships[groupId].dest,
              ]
        )
  )

  const [popupInfo, setPopupInfo] = useState<Feature | null>(null)
  const [viewport, setViewport] = useState({
    // latitude: carpoolDetails.source.coordinates[1],
    // longitude: carpoolDetails.source.coordinates[0],
    latitude: midpointCoords.lat,
    longitude: midpointCoords.lng,
    zoom: 10.25,
    bearing: null,
    pitch: null,
    padding: null,
  })

  const myFeatures: FeatureCollection = getFeatureCollection(
    isMyCarpool ? [myCarpoolGroups[groupId]] : [carpoolMemberships[groupId]],
    true,
    false
  )

  const otherUserFeatures: FeatureCollection = requestId
    ? getFeatureCollection(
        [receivedCarpoolRequests[groupId][requestId]],
        false,
        true
      )
    : getFeatureCollection(
        isMyCarpool
          ? Object.keys(myCarpoolGroups[groupId].members).map((memberId) => {
              return myCarpoolGroups[groupId].members[memberId]
            })
          : [allCarpoolGroups[groupId]],
        false,
        false
      )

  const onMarkerClick = useCallback(
    (feature: Feature) => {
      SegmentService.track('carpool-map-tooltip-click', {
        feature: feature,
      })

      setPopupInfo(feature)
    },
    [setPopupInfo]
  )

  const renderPopup = useCallback(() => {
    const point = popupInfo && (popupInfo.geometry as Point)
    return popupInfo ? (
      <StyledPopup
        longitude={point.coordinates[0]}
        latitude={point.coordinates[1]}
        closeOnClick={false}
        closeOnMove={true}
        onClose={() => setPopupInfo(null)}
      >
        <CarpoolMapPopup isMobile={isMobile} feature={popupInfo} />
      </StyledPopup>
    ) : null
  }, [popupInfo, isMobile])

  return (
    <MapContainer>
      <ReactMapGL
        {...viewport}
        style={{
          width: 650,
          height: 400,
          borderRadius: '0.5rem',
        }}
        mapStyle={mapboxConfig.baseMapPurple}
        mapboxAccessToken={mapboxConfig.apiKey}
        onMove={(nextViewport) => setViewport(nextViewport.viewState)}
      >
        {renderPopup()}
        <MapIntelligenceMarker
          features={myFeatures}
          markerClick={onMarkerClick}
          layer={'myCarpoolFeatures'}
        />
        <MapIntelligenceMarker
          features={otherUserFeatures}
          markerClick={onMarkerClick}
          layer={'otherUserCarpoolFeatures'}
        />
      </ReactMapGL>
      <CarpoolMapLegend
        isMyCarpool={isMyCarpool}
        isRequest={requestId ? true : false}
        hasOtherUserData={
          otherUserFeatures && otherUserFeatures.features.length > 0
            ? true
            : false
        }
      />
    </MapContainer>
  )
})

// Helps to identify the component in React error logs
if (process.env.NODE_ENV !== 'production') {
  CarpoolMap.displayName = 'CarpoolMap'
}

export default CarpoolMap
