import Button from 'core-system/Button'
import Divider from 'core-system/Divider'
import FleetLogoFullColor from 'core-system/FleetLogos/FleetLogoFullColor'
import TextField from 'core-system/TextField'
import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import UserService from 'redux/user/userService'
import styled from 'styled-components'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.primary.pink700};
`

const FormContainer = styled.div`
  ${(props) => props.theme.typography.body1};
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border-color: ${(props) => props.theme.palette.primary.pink500};
  border-radius: 15px;
  max-width: calc(100% - 32px);
  width: 650px;
  padding: 1rem 4.5rem;
`

const Title = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
  ${(props) => props.theme.typography.h2};
  margin: 1rem 0;
  text-align: center;
`

const InfoText = styled.h4`
  color: ${(props) => props.theme.palette.text.primary};
  ${(props) => props.theme.typography.h4};
  margin-bottom: 2rem;
  text-align: center;
`

const LogoContainer = styled.div`
  padding: 1rem 0;
  text-align: center;
`

const StatusText = styled.p<{ error?: boolean }>`
  margin-top: 1rem;
  ${(props) => props.theme.typography.caption};
  font-size: ${(props) => (props.error ? '0.75rem' : '1rem')};
  color: ${(props) =>
    props.error
      ? props.theme.palette.error.red500
      : props.theme.palette.primary.pink800};
`

const Text = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  ${(props) => props.theme.typography.body1};
  margin-top: 2rem;
`

const StyledLink = styled(Link)`
  margin: 0;
  color: ${(props) => props.theme.palette.primary.pink400};
`

const ForgotPasswordView = React.memo(() => {
  const [email, setEmail] = useState<string>('')
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false)
  const [errorMsg, setShowErrorMsg] = useState<string>('')

  const onSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()

      if (email.trim() !== '') {
        UserService.resetPassword(email.toLowerCase())
          .then(() => {
            setEmail('')
            setShowSuccessMsg(true)
          })
          .catch((err) => {
            setShowErrorMsg(
              err.response.data.email
                ? err.response.data.email[0]
                : 'Oops, something went wrong. Please try again or contact support@movewithfleet.com'
            )
          })

        SegmentService.track('email-resetLink-action', { email: email })
      }
    },
    [email, setEmail, setShowSuccessMsg, setShowErrorMsg]
  )

  const onChange = useCallback(
    (val: string) => {
      setEmail(val)
      if (errorMsg !== '') {
        setShowErrorMsg('')
      }
    },
    [errorMsg]
  )
  return (
    <>
      <Helmet>
        <title>Fleet-Forgot Password</title>
      </Helmet>
      <Container>
        <FormContainer>
          <Title>Forgot Your Password?</Title>
          <InfoText>
            No problem. We will email you a link you can use to reset your
            password.
          </InfoText>
          <form>
            <TextField
              type='email'
              label='Email*'
              defaultValue={email}
              placeholder='email'
              onChange={(e) => onChange(e.currentTarget.value)}
              invalid={errorMsg !== ''}
              helpText={errorMsg}
            />
            <Button marginTop='1rem' onClick={onSubmit}>
              Email Password Reset Link
            </Button>
          </form>
          {showSuccessMsg && (
            <StatusText>Please check your email for next steps.</StatusText>
          )}
          <Text>
            Have an account? <StyledLink to={'/login'}>Login</StyledLink>
          </Text>
          <Divider mt={3} mb={4} />
          <LogoContainer>
            <FleetLogoFullColor height='40' width='115' />
          </LogoContainer>
        </FormContainer>
      </Container>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ForgotPasswordView.displayName = 'ForgotPasswordView'
}

export default ForgotPasswordView
