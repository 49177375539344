import React from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  variant,
} from 'styled-system'
import { buildForwardingComponent } from '../utils/buildComponent'

const Content = styled.div<FlexboxProps & SpaceProps & LayoutProps>`
  width: 100%;
  height: ${(props) => props.theme.pxToRem(40)};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  font-weight: 900;
  font-size: 0.6875rem;
  margin-top: 1.5rem;

  ${(props) =>
    variant({
      prop: 'status',
      variants: {
        green: {
          backgroundColor: props.theme.palette.accent.teal200,
          color: props.theme.palette.accent.teal100,
        },
        red: {
          backgroundColor: props.theme.palette.primary.pink200,
          color: props.theme.palette.primary.pink100,
        },
        purple: {
          backgroundColor: props.theme.palette.primary.pink200,
          color: props.theme.palette.primary.pink100,
        },
        grey: {
          backgroundColor: props.theme.palette.grey.grey200,
          color: props.theme.palette.grey.grey100,
        },
        yellow: {
          backgroundColor: props.theme.palette.warning.yellow200,
          color: props.theme.palette.warning.yellow100,
        },
        blue: {
          backgroundColor: props.theme.palette.accent.blue200,
          color: props.theme.palette.accent.blue100,
        },
        regular: {
          backgroundColor: props.theme.palette.primary.pink200,
          color: props.theme.palette.text.secondary,
        },
        transparent: {
          backgroundColor: props.theme.palette.transparent,
          color: props.theme.palette.text.placeholder,
        },
      },
    })}

  ${flexbox}
  ${space}
  ${layout}
`

export type StatusVariant =
  | 'regular'
  | 'purple'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'red'
  | 'grey'
  | 'transparent'

export interface CardStatusProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  status?: StatusVariant
}

const CardStatus = React.forwardRef<HTMLDivElement, CardStatusProps>(
  (props: CardStatusProps, ref: React.Ref<HTMLDivElement>) => {
    const { children, status = 'active' } = props

    return (
      <Content ref={ref} status={status} {...props}>
        {children}
      </Content>
    )
  }
)

if (process.env.NODE_ENV !== 'production') {
  CardStatus.displayName = 'CardStatus'
}

export default buildForwardingComponent(CardStatus)
