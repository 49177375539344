export default {
  50: '#FDFBFD',
  100: '#FBF7FB',
  200: '#F5EBF5',
  300: '#EAD2EA',
  400: '#DBB0DB',
  500: '#C785C9',
  600: '#9F64A0',
  700: '#6F4470',
  800: '#402540',
  900: '#130813',
}
