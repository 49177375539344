import ProgramUtils from 'core-system/Program/ProgramUtils'
import Text from 'core-system/Text'
import TransitLogo from 'core-system/TransitLogo'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { Merchant, ServiceModeType } from 'redux/programs/programsTypes'
import styled from 'styled-components'

const Service = styled.div`
  display: flex;
  height: 2.5rem;
  margin-right: 1.5rem;
  align-items: center;

  &:last-of-type {
    margin-right: unset;
    .serviceText {
      border-right: unset;
    }
  }
`

const AliasText = styled(Text)`
  white-space: nowrap;
`

const ServiceText = styled.div`
  padding: 0 1.5rem 0 1rem;
  border-right: 1px solid ${(props) => props.theme.palette.grey.grey300};
`

const getProviders = (
  merchantIds: string[],
  merchantsMap: Dictionary<Merchant>,
  customData?: Dictionary,
  sortByPartner?: boolean
) => {
  return [...merchantIds]
    .sort((a, b) => {
      if (sortByPartner) {
        const firstProvider = merchantsMap[a]?.fleetPartner ? 1 : 0
        const secondProvider = merchantsMap[b]?.fleetPartner ? 1 : 0

        if (firstProvider < secondProvider) return 1
        if (firstProvider > secondProvider) return -1
      } else if (customData) {
        if (customData[a].spend < customData[b].spend) return 1
        if (customData[a].spend > customData[b].spend) return -1
        if (customData[a].trips < customData[b].trips) return 1
        if (customData[a].trips > customData[b].trips) return -1
      }
      return 0
    })
    .map((merchantId, idx) => {
      const merchant = merchantsMap[merchantId]
      if (!merchant || merchant.modeTypes.length === 0) {
        return null
      }
      const modeTypes = merchant.modeTypes
        .map((modeType) =>
          ProgramUtils.formatElectricModeTypes(
            modeType || 'unknown'
          ).toLowerCase()
        )
        .join(', ')

      return (
        <Service key={idx}>
          <TransitLogo
            modeType={
              merchant.iconUrl
                ? 'custom'
                : (merchant.modeTypes[0] as ServiceModeType)
            }
            src={merchant.iconUrl || merchant.modeTypes[0]}
            size='small'
          />
          <ServiceText className='serviceText'>
            <AliasText variant='action4' maxWidth={pxToRem(100)} hideOverflow>
              {merchant.name}
            </AliasText>
            <Text capitalize hideOverflow variant='body2'>
              {modeTypes}
            </Text>
          </ServiceText>
        </Service>
      )
    })
}

export default {
  getProviders,
}
