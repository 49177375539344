import { AxiosResponse } from 'axios'
import Request from 'redux/config/services/Request'
import {
  AddTripPayload,
  CardholderDetailsUpdatePayload,
  CarpoolGroupCreatePayload,
  CarpoolRequestToJoinRequestData,
  CarpoolSearchRequestPayload,
  CreateProgramRequestPayload,
  EmployeeProfile,
  PreTaxEmployeeAllocationUpdatePayload,
  SetOptInChoiceData,
  TopUpPayload,
} from './employeePlatformTypes'
import moment from 'moment'

//=================================
//
// PROFILE REQUESTS
//
//=================================
const getProfileData = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/profile/me/')
}

const getCoworkers = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/profile/my_coworkers/')
}

const updateProfileData = (data: EmployeeProfile): Promise<AxiosResponse> => {
  return Request.put('/mobile/profile/me/', data)
}

const updateCardholderDetails = (
  data: CardholderDetailsUpdatePayload
): Promise<AxiosResponse> => {
  return Request.post('/mobile/core/cards/cardholder_details/', data)
}

const getWalletBalance = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/profile/V2/my_wallet/wallet_balance/')
}

const getAllPayouts = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/all_payouts/')
}

const getAllCharges = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/profile/charges/')
}

const getAllRewards = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/rewards/available_rewards/')
}

const getDOB = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/cards/get_dob/')
}

const getDashboardStats = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/profile/dashboard/')
}

const getUploadReceiptUrl = (): Promise<AxiosResponse> => {
  return Request.get('/upload_receipt_url/')
}

//=================================
//
// PAYMENT METHODS REQUESTS
//
//=================================
const getAllPaymentMethods = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/profile/V2/my_wallet/')
}

const deletePaymentMethod = (id: string): Promise<AxiosResponse> => {
  return Request.del(`/mobile/profile/V2/my_wallet/${id}/`)
}

const setDefaultPaymentMethod = (
  paymentMethodId: string
): Promise<AxiosResponse> => {
  return Request.post(
    `/mobile/profile/V2/my_wallet/${paymentMethodId}/set_default_payment_source/`,
    {}
  )
}
const getStripeClientSecret = (): Promise<AxiosResponse> => {
  return Request.post('/mobile/profile/V2/my_wallet/', null, null, true)
}

//=================================
//
// CARDS REQUESTS
//
//=================================
const getCards = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/cards/')
}

const getCardDetails = (cardInternalId: string): Promise<AxiosResponse> => {
  return Request.get(`/mobile/core/cards/${cardInternalId}/`)
}

const lockCard = (cardInternalId: string): Promise<AxiosResponse> => {
  return Request.post(`/mobile/core/cards/${cardInternalId}/deactivate/`, null)
}

const unlockCard = (cardInternalId: string): Promise<AxiosResponse> => {
  return Request.post(`/mobile/core/cards/${cardInternalId}/activate/`, null)
}

const topUpCard = (payload: TopUpPayload): Promise<AxiosResponse> => {
  return Request.post(
    `/mobile/profile/V2/my_wallet/${payload.paymentMethodId}/top_up/`,
    { topUpAmount: payload.topUpAmount }
  )
}

//=================================
//
// PROGRAMS REQUESTS
//
//=================================
const getAllPrograms = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/programs/')
}

const getAllProgramRequests = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/program_requests/')
}

const createProgramRequest = (
  data: CreateProgramRequestPayload
): Promise<AxiosResponse> => {
  return Request.post('/mobile/core/program_requests/', data)
}

const cancelProgramRequest = (requestId: string): Promise<AxiosResponse> => {
  return Request.del(`/mobile/core/program_requests/${requestId}/`)
}

//=================================
//
// PRETAX REQUESTS
//
//=================================
const getPreTaxEmployeeDeduction = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/tax_benefits/')
}

const getPreTaxPrograms = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/tax_benefits/get_available/')
}

const getPreTaxBalance = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/tax_benefits/get_benefit_balance/')
}

const getPreTaxBenefitHistory = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/tax_benefits/get_benefit_history/')
}

const createPreTaxEmployeeAllocation = (
  data: PreTaxEmployeeAllocationUpdatePayload
): Promise<AxiosResponse> => {
  return Request.post('/mobile/core/tax_benefits/', data)
}

const updatePreTaxEmployeeAllocation = (
  deductionId: string,
  data: PreTaxEmployeeAllocationUpdatePayload
): Promise<AxiosResponse> => {
  return Request.put(`/mobile/core/tax_benefits/${deductionId}/`, data)
}

//=================================
//
// CARPOOL REQUESTS
//
//=================================
const getAllCarpoolGroups = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/carpool/search/')
}

const getMyCarpoolGroups = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/carpool/groups/')
}

const searchCarpoolGroups = (
  data: CarpoolSearchRequestPayload
): Promise<AxiosResponse> => {
  return Request.post('/mobile/carpool/search/search/', data)
}

const createCarpoolGroup = (
  data: CarpoolGroupCreatePayload
): Promise<AxiosResponse> => {
  return Request.post('/mobile/carpool/groups/', data)
}

const updateCarpoolGroup = (
  groupId: string,
  data: CarpoolGroupCreatePayload
): Promise<AxiosResponse> => {
  return Request.put(`/mobile/carpool/groups/${groupId}/`, data)
}

const deleteCarpoolGroup = (groupId: string): Promise<AxiosResponse> => {
  return Request.del(`/mobile/carpool/groups/${groupId}/`)
}

const getCarpoolGroupMemberships = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/carpool/memberships/')
}

const getReceivedCarpoolRequests = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/carpool/received_requests/')
}

const getSentCarpoolRequests = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/carpool/sent_requests/')
}

const requestToJoinCarpool = (
  data: CarpoolRequestToJoinRequestData
): Promise<AxiosResponse> => {
  return Request.post(
    `/mobile/carpool/search/${data.carpoolGroupId}/send_request/`,
    data.payload
  )
}

const acceptCarpoolRequest = (requestId: string): Promise<AxiosResponse> => {
  return Request.post(
    `/mobile/carpool/received_requests/${requestId}/approve_request/`,
    {}
  )
}

const declineCarpoolRequest = (requestId: string): Promise<AxiosResponse> => {
  return Request.post(
    `/mobile/carpool/received_requests/${requestId}/decline_request/`,
    {}
  )
}

//=================================
//
// TRIPS REQUESTS
//
//=================================
const getCurrentMonthTrips = (currentMonth: string): Promise<AxiosResponse> => {
  const startDate = moment.utc(currentMonth).clone().startOf('month')
  const endDate = startDate.clone().add(1, 'month')
  return Request.get(
    `/mobile/core/trips/?date__gte=${startDate.toJSON()}&date__lt=${endDate.toJSON()}&offset=0`
  )
}

const addTrip = (tripData: AddTripPayload): Promise<AxiosResponse> => {
  return Request.post('/mobile/core/trips/', tripData)
}

const getAllMerchants = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/merchants/')
}

const getAllModes = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/V2/modes/')
}

//=================================
//
// LEADERBOARDS REQUESTS
//
//=================================
const getMyLeaderboards = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/rewards/competitions/')
}

const getLeaderboardRankings = (
  leaderboardId: string
): Promise<AxiosResponse> => {
  const startOfCurrentMonth = moment().startOf('month')
  return Request.get(
    `/mobile/core/rewards/competitions/${leaderboardId}/?start_date=${startOfCurrentMonth.format(
      'YYYY-MM-DD'
    )}`
  )
}

//=================================
//
// OPT-IN REQUESTS
//
//=================================
const getAllOptIns = (): Promise<AxiosResponse> => {
  return Request.get('/mobile/core/opt_ins/')
}

const setOptInChoice = (data: SetOptInChoiceData): Promise<AxiosResponse> => {
  return Request.post(`/mobile/core/opt_ins/${data.optInId}/set_choice/`, {
    choice: data.choice,
  })
}

//=================================
//
// CONCIERGE REQUESTS
//
//=================================
const getFAQs = (): Promise<AxiosResponse> => {
  return Request.get('/hr/concierge/faqs/')
}

export default {
  // PROFILE
  getProfileData,
  getCoworkers,
  updateProfileData,
  updateCardholderDetails,
  getWalletBalance,
  getAllPayouts,
  getAllRewards,
  getAllCharges,
  getDOB,
  getDashboardStats,
  getUploadReceiptUrl,

  // PAYMENT METHODS
  getAllPaymentMethods,
  deletePaymentMethod,
  setDefaultPaymentMethod,
  getStripeClientSecret,

  // CARDS
  getCards,
  getCardDetails,
  lockCard,
  unlockCard,
  topUpCard,

  // PROGRAMS
  getAllPrograms,
  getAllProgramRequests,
  createProgramRequest,
  cancelProgramRequest,

  // PRETAX
  getPreTaxEmployeeDeduction,
  getPreTaxPrograms,
  getPreTaxBalance,
  getPreTaxBenefitHistory,
  createPreTaxEmployeeAllocation,
  updatePreTaxEmployeeAllocation,

  // CARPOOL
  getAllCarpoolGroups,
  getMyCarpoolGroups,
  searchCarpoolGroups,
  createCarpoolGroup,
  updateCarpoolGroup,
  deleteCarpoolGroup,
  getCarpoolGroupMemberships,
  getReceivedCarpoolRequests,
  getSentCarpoolRequests,
  requestToJoinCarpool,
  acceptCarpoolRequest,
  declineCarpoolRequest,

  // TRIPS
  getCurrentMonthTrips,
  addTrip,
  getAllMerchants,
  getAllModes,

  // LEADERBOARDS
  getMyLeaderboards,
  getLeaderboardRankings,

  // OPT-IN
  getAllOptIns,
  setOptInChoice,

  // CONCIERGE
  getFAQs,
}
