import Loading from 'core-system/Loading'
import PageHeader from 'core-system/PageHeader'
import Notifier from 'features/Notifications/Notifier'
import SidebarView from 'features/Sidebar/SidebarView'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AppState } from 'redux/config/store'
import { userActions } from 'redux/user/userSlice'
import useCurrentPath from 'shared/Hooks/useCurrentPath'
import refExists from 'shared/refExists'
import styled from 'styled-components'
import isTokenExpired from '../isTokenExpired'
import { locationData, Locations } from './Locations'
import RandomUtils from 'shared/RandomUtils'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import LocalStorage from 'shared/LocalStorage'

const Page = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: 100%;
  background-color: ${(props) => props.theme.palette.grey.grey100};
`

const Body = styled.div<{
  noPadding?: boolean
  showVerticalScroll?: boolean
}>`
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => (props.noPadding ? 0 : '2.5rem 4rem')};
  margin: 0 auto;
  z-index: ${(props) => props.theme.zIndex.body};
  overflow-y: ${(props) => (props.showVerticalScroll ? 'scroll' : 'auto')};

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: ${(props) => (props.noPadding ? 0 : '2.5rem 1.5rem')};
  }
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    max-width: 100%;
  }
`

const Inner = styled.div<{ noMax: boolean }>`
  max-width: ${(props) => (props.noMax ? 'unset' : '75rem')};
  width: 100%;
  height: 100%;
`

export interface DefaultLayoutProps {
  showSidebar?: boolean
  showHeaderNav?: boolean
}

const DefaultLayoutV2 = (
  props: DefaultLayoutProps = { showSidebar: true, showHeaderNav: true }
) => {
  const isExpired = isTokenExpired()
  const dispatch = useDispatch()
  const location = useLocation()
  const scrollRef = useRef<HTMLDivElement>(null)
  const path = useCurrentPath()
  const navigate = useNavigate()

  const platformType = RandomUtils.getPlatformType(path)

  const authUserFail = useSelector((state: AppState) => state.user.authUserFail)
  const userId = useSelector((state: AppState) => state.user.profile.id)
  const { profileData } = useSelector(
    (state: AppState) => state.employeePlatform
  )
  const allSegments = useSelector(
    (state: AppState) => state.employer.allSegments
  )
  const worksites = useSelector((state: AppState) => state.employer.worksites)
  const programsLoaded = useSelector(
    (state: AppState) => state.programs.programsLoaded
  )
  const merchants = useSelector((state: AppState) => state.programs.merchants)
  const allIncentives = useSelector(
    (state: AppState) => state.incentives.allIncentives
  )
  const apiLoading = useSelector(
    (state: AppState) => state.application.apiLoading
  )
  const leaderboardsMap = useSelector(
    (state: AppState) => state.leaderboards.leaderboardsMap
  )
  const allCommuters = useSelector(
    (state: AppState) => state.employee.allCommuters
  )

  useEffect(() => {
    if (isExpired || authUserFail) {
      dispatch(userActions.logout())
      return
    }
    // once authenticated, get user info
    if (authUserFail === null && userId === '0') {
      // Fetch employee profile data if the user logs into the employee platform
      if (platformType === 'employee') {
        dispatch(employeePlatformActions.getProfileData())
        // dispatch(employeePlatformActions.getDOB())
        // Fetch HR user data if the user logs into the admin platform
      } else {
        dispatch(userActions.getUser())
      }
    }
  }, [isExpired, dispatch, authUserFail, navigate, userId, platformType])

  // Scroll to top of page
  useEffect(() => {
    if (refExists(scrollRef.current)) {
      scrollRef.current.scrollTop = 0
    }
  }, [location.pathname])

  if (isExpired || authUserFail) {
    return <Navigate to={Locations.Login} replace />
  }

  if (location?.pathname === '/') {
    if (LocalStorage.getItem('platformType') === 'employee') {
      return <Navigate replace to={Locations.EmployeeDashboard} />
    } else {
      return <Navigate replace to={Locations.Dashboard} />
    }
  }

  if (
    ((!allSegments ||
      !programsLoaded ||
      !allIncentives ||
      !leaderboardsMap ||
      !worksites ||
      !merchants ||
      !allCommuters) &&
      platformType === 'employer') ||
    (platformType === 'employee' && !profileData)
  ) {
    return <Loading fullPage withoutNav />
  }

  // if (worksites && worksites.length === 0) {
  //   return <Navigate to={Locations.Onboarding} replace />
  // }

  const { noPadding, showVerticalScroll, ignoreMaxWidth } = locationData[path]

  return (
    <>
      {apiLoading && <Loading fullPage withoutNav hasOpacity />}
      <Notifier />
      <Page id='page'>
        {props.showSidebar && <SidebarView platformType={platformType} />}
        <Container>
          {props.showHeaderNav && <PageHeader platformType={platformType} />}
          <Body
            id='page-main-body'
            ref={scrollRef}
            noPadding={noPadding}
            showVerticalScroll={showVerticalScroll}
          >
            <Inner noMax={ignoreMaxWidth}>
              <Outlet />
            </Inner>
          </Body>
        </Container>
      </Page>
    </>
  )
}

DefaultLayoutV2.displayName = 'DefaultLayoutV2'

export default DefaultLayoutV2
