import { AxiosResponse } from 'axios'
import {
  ChangeWorksitePayload,
  CreateEmployeeApiType,
  EmployeeIdParams,
  EmployeeSuccessPayload,
  MyCommuterDetailsParams,
  MyCommutersParams,
  MyCommuterTransactionsParams,
} from 'redux/employee/employeeTypes'
import Request from '../config/services/Request'

const getAllEmployees = (): Promise<AxiosResponse> => {
  return Request.get('/hr/profile/users/')
}

type updateEmployeeParams = EmployeeSuccessPayload

const updateEmployee = (data: updateEmployeeParams): Promise<AxiosResponse> => {
  return Request.put(`/hr/profile/users/${data.id}/`, data)
}

const deleteEmployee = (id: string): Promise<AxiosResponse> => {
  return Request.del(`/hr/profile/users/${id}/`)
}

const createEmployee = (
  data: CreateEmployeeApiType
): Promise<AxiosResponse> => {
  return Request.post('/hr/profile/users/', data)
}

const inviteEmployees = (emails: string[]): Promise<AxiosResponse>[] => {
  const invitedEmployees: Promise<AxiosResponse>[] = emails.map((email) => {
    return Request.post('/hr/profile/users/', {
      email,
      groups: ['HRusers'],
      worksiteId: null,
    })
  })
  return invitedEmployees
}

const resendEmployeeInvite = (employeeId: string): Promise<AxiosResponse> => {
  return Request.post(`/hr/profile/users/${employeeId}/resend_invite/`, {
    id: employeeId,
  })
}

const getCommuters = (commuterId?: string): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/profile/my_employees/${
      commuterId && typeof commuterId === 'string' ? commuterId : ''
    }`
  )
}

const getMyCommuters = (params: MyCommutersParams): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/profile/my_commuters/?archived=false${
      params.segment ? `&segments=${params.segment}` : ''
    }${params.segment && params.csv ? '&' : ''}${
      params.csv ? `&format=csv` : ''
    }`,
    params.csv ? true : false
  )
}

const getMyCommuterDetails = (
  params: MyCommuterDetailsParams
): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/profile/my_commuters/${params.id}/?date__gte=${params.startDate}&date__lt=${params.endDate}`
  )
}

const getMyCommuterTransactions = (
  params: MyCommuterTransactionsParams
): Promise<AxiosResponse> => {
  return Request.get(
    `/hr/profile/my_commuters/${params.id}/transactions/?date__gte=${
      params.startDate || ''
    }&date__lte=${params.endDate || ''}`
  )
}

const getArchivedEmployees = (): Promise<AxiosResponse> => {
  return Request.get(`/hr/profile/my_employees/?archived=true`)
}

const archiveEmployee = (params: EmployeeIdParams): Promise<AxiosResponse> => {
  return Request.del(`/hr/profile/my_employees/${params.employeeId}/`)
}

const reactivateEmployee = (
  params: EmployeeIdParams
): Promise<AxiosResponse> => {
  return Request.post(
    `/hr/profile/my_employees/${params.employeeId}/reactivate/`,
    {}
  )
}

const getDeclinedEmployees = (): Promise<AxiosResponse> => {
  return Request.get(`/hr/profile/my_declined_employees/`)
}

const changeEmployeeWorksite = (
  params: ChangeWorksitePayload
): Promise<AxiosResponse> => {
  return Request.post(
    `/hr/profile/my_employees/${params.employeeId}/change_worksite/`,
    {
      forceChange: params.forceChange,
      worksiteId: params.worksiteId,
    }
  )
}

export default {
  getAllEmployees,
  updateEmployee,
  deleteEmployee,
  createEmployee,
  inviteEmployees,
  getCommuters,
  getMyCommuters,
  getMyCommuterDetails,
  getMyCommuterTransactions,
  resendEmployeeInvite,
  getArchivedEmployees,
  archiveEmployee,
  reactivateEmployee,
  getDeclinedEmployees,
  changeEmployeeWorksite,
}
