import React from 'react'
import styled from 'styled-components'
import Checkbox from 'core-system/Checkbox'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import { variants } from 'core-system/Dropdown/Dropdown'
import {
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  PositionProps,
  variant,
} from 'styled-system'
import palette from 'core-system/Themes/palette'
import { SelectItemProps } from 'core-system/Dropdown'

const Container = styled.div<
  FlexboxProps &
    SpaceProps &
    LayoutProps &
    PositionProps & {
      open: boolean
      selectorMaxHeight: string
    }
>`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.dropdowns};
  box-shadow: ${(props) => props.theme.dropShadows.selected};
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.white};
  width: 100%;
  display: ${(props) => (props.open ? 'block' : 'none')};
  max-height: ${(props) => props.selectorMaxHeight};
  overflow-y: scroll;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0.75rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: ${palette.grey.grey300};
    background-clip: content-box;
    border: 3px solid transparent;
  }

  ${(props) =>
    variant({
      variants: {
        textual: {
          border: `1px solid ${props.theme.palette.grey.grey300}`,
          borderRadius: '0.5rem',
          top: props.theme.pxToRem(36),
        },
        header: {
          border: `1px solid ${props.theme.palette.grey.grey300}`,
          borderRadius: '0.5rem',
          top: props.theme.pxToRem(46),
        },
        contained: {
          border: `1px solid ${props.theme.palette.text.secondary}`,
          borderRadius: '0 0 0.5rem 0.5rem',
          top: props.theme.pxToRem(41),
        },
      },
    })}
`

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  padding-left: 1rem;
  padding-right: 0.5rem;
  margin-bottom: ${pxToRem(3)};
`

interface MultiSelectListProps {
  items: SelectItemProps[]
  activeItems: string[]
  onItemCallback: (id: string) => void
  open: boolean
  variant?: variants
  selectorMaxHeight?: string
  disabledItemIds?: Set<string>
}

const MultiSelectList = (props: MultiSelectListProps) => {
  const {
    items,
    activeItems = [],
    onItemCallback,
    open,
    variant = 'contained',
    selectorMaxHeight,
    disabledItemIds,
  } = props

  return (
    <Container
      open={open}
      variant={variant}
      selectorMaxHeight={selectorMaxHeight}
      {...props}
    >
      {items.map((item) => (
        <ItemContainer key={item.id}>
          <Text
            variant='body1'
            textColor={
              disabledItemIds.has(item.id)
                ? palette.text.disabled
                : palette.text.primary
            }
          >
            {item.text}
          </Text>
          <Checkbox
            checked={activeItems.includes(item.id)}
            onClick={() => onItemCallback(item.id)}
            disabled={disabledItemIds.has(item.id)}
          />
        </ItemContainer>
      ))}
    </Container>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MultiSelectList.displayName = 'MultiSelectList'
}

export default MultiSelectList
