import React from 'react'
import pxToRem from 'core-system/utils/pxToRem'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import { Card } from 'redux/employeePlatform/employeePlatformTypes'
import styled from 'styled-components'
import palette from 'core-system/Themes/palette'
import Text from 'core-system/Text'
import LockIcon from 'core-system/Icons/Misc/Lock'

const CARD_SIZING = {
  small: {
    width: pxToRem(160),
    height: pxToRem(100),
    cardNumberPos: {
      bottom: pxToRem(22),
      left: pxToRem(22),
    },
    lockShadow: {
      width: pxToRem(125),
      height: pxToRem(80),
      top: pxToRem(4),
      right: pxToRem(6),
      borderRadius: '1rem',
    },
    lockPos: {
      top: pxToRem(8),
      right: pxToRem(12),
    },
  },
  large: {
    width: pxToRem(222),
    height: pxToRem(140),
    cardNumberPos: {
      bottom: pxToRem(23),
      left: pxToRem(30),
    },
    lockShadow: {
      width: pxToRem(200),
      height: pxToRem(125),
      top: pxToRem(4),
      right: pxToRem(10),
      borderRadius: '2rem',
    },
    lockPos: {
      top: pxToRem(15),
      right: pxToRem(30),
    },
  },
}

const CardContainer = styled.div<{
  size: 'small' | 'large'
  pressable: boolean
}>`
  position: relative;
  width: ${({ size }) => CARD_SIZING[size].width};
  height: ${({ size }) => CARD_SIZING[size].height};
  cursor: ${({ pressable }) => (pressable ? 'pointer' : 'default')};
`

const CardImageContainer = styled.div`
  z-index: 2;
`

const CardNumberContainer = styled.div<{ size: 'small' | 'large' }>`
  position: absolute;
  z-index: 3;
  bottom: ${({ size }) => CARD_SIZING[size].cardNumberPos.bottom};
  left: ${({ size }) => CARD_SIZING[size].cardNumberPos.left};
`

const CardLockContainer = styled.div<{ size: 'small' | 'large' }>`
  position: absolute;
  z-index: 4;
  top: ${({ size }) => CARD_SIZING[size].lockPos.top};
  right: ${({ size }) => CARD_SIZING[size].lockPos.right};
`

const CardLockShadow = styled.div<{ size: 'small' | 'large' }>`
  position: absolute;
  z-index: 3;
  background-color: rgba(32, 0, 109, 0.65);
  border-radius: ${({ size }) => CARD_SIZING[size].lockShadow.borderRadius};
  width: ${({ size }) => CARD_SIZING[size].lockShadow.width};
  height: ${({ size }) => CARD_SIZING[size].lockShadow.height};
  top: ${({ size }) => CARD_SIZING[size].lockShadow.top};
  right: ${({ size }) => CARD_SIZING[size].lockShadow.right};
`

interface FleetCardProps {
  card: Card
  size: 'small' | 'large'
  onPress?: (card: Card) => void
}

const FleetCard = React.memo((props: FleetCardProps) => {
  const { card, size, onPress } = props

  return (
    <CardContainer
      size={size}
      onClick={() => (onPress ? onPress(card) : null)}
      pressable={onPress ? true : false}
    >
      <CardImageContainer>
        {card ? (
          <img
            src={`${StaticAssetsUrl}/misc/${card?.type}_card.png`}
            width='100%'
            height='100%'
          />
        ) : null}
      </CardImageContainer>
      <CardNumberContainer size={size}>
        <Text
          variant={size === 'small' ? 'action3' : 'h3'}
          textColor={
            card?.type === 'virtual' ? palette.primary.pink800 : palette.white
          }
        >
          {card?.last4}
        </Text>
      </CardNumberContainer>
      {card?.status !== 'active' ? (
        <>
          <CardLockShadow size={size} />
          <CardLockContainer size={size}>
            <LockIcon color={palette.white} />
          </CardLockContainer>
        </>
      ) : null}
    </CardContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  FleetCard.displayName = 'FleetCard'
}

export default FleetCard
