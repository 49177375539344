import React, { useState, KeyboardEvent, ChangeEvent } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import OutlinedInput from '@mui/material/OutlinedInput'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send'
import palette from 'core-system/Themes/palette'
import ThinkingAnimation from './ThinkingAnimation'

const useStyles = makeStyles(() => ({
  mainContainer: {
    margin: 16,
    marginBottom: 24,
    background: 'white',
  },
  button: {
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
  messageInput: {
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: 'white',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'lightgray',
      WebkitBorderRadius: 10,
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'lightgray',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: 'lightgray',
  },
  sendContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  popoverInnerContainer: {
    boxShadow: 'none',
  },
}))

interface MessageBarProps {
  onMessageSend?: (message: string, type: string) => void
  placeholder?: string
  isGettingResponse?: boolean
}

const MessageBar: React.FC<MessageBarProps> = ({
  onMessageSend = () => {},
  placeholder = 'Message',
  isGettingResponse = false,
}) => {
  const classes = useStyles()
  const [message, setMessage] = useState<string>('')

  const messageBarOnKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.shiftKey && e.key === 'Enter') {
      return
    }
    if (e.key === 'Enter') {
      e.preventDefault()
      sendMessageHere()
    }
  }

  const sendMessageHere = () => {
    const trimmedMessage = message.trim()
    if (trimmedMessage === '') {
      return
    }
    onMessageSend(trimmedMessage, 'chat')
    setMessage('')
  }

  return (
    <div className={classes.mainContainer}>
      {isGettingResponse && <ThinkingAnimation />}
      <OutlinedInput
        id='message-bar'
        fullWidth
        onKeyDown={messageBarOnKeyDown}
        value={message}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setMessage(e.target.value)
        }
        multiline
        classes={{ input: classes.messageInput }}
        maxRows={3}
        placeholder={placeholder}
        sx={{
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary.pink800,
          },
        }}
        endAdornment={
          <div className={classes.sendContainer}>
            <IconButton
              onClick={sendMessageHere}
              edge='end'
              className={classes.button}
            >
              <SendIcon style={{ color: palette.primary.pink800 }} />
            </IconButton>
          </div>
        }
      />
    </div>
  )
}

export default React.memo(MessageBar)
