import { userActions } from 'redux/user/userSlice'
import Divider from 'core-system/Divider'
import GoogleIcon from 'core-system/Icons/Login/Google'
import MicrosoftIcon from 'core-system/Icons/Login/Microsoft'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { GoogleAuthUrl, MsftAuthUrl } from 'redux/config/services/Constants'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import isTokenExpired from 'shared/isTokenExpired'
import { Locations } from 'shared/Router/Locations'
import UrlParser from 'shared/UrlParser'
import styled from 'styled-components'
import { LoginForm } from './components/LoginForm'
import TextToggle from 'core-system/TextToggle'
import { applicationActions } from 'redux/application/applicationSlice'
import LocalStorage from 'shared/LocalStorage'
import FleetLogoFullColor from 'core-system/FleetLogos/FleetLogoFullColor'
import ButtonV2 from 'core-system/ButtonV2'
import LoginSignUpGraphicV2, {
  ImageLabel,
} from './components/LoginSignUpGraphicV2'

const Container = styled.div`
  display: flex;
  height: 100%;
`

const LogoContainer = styled.div`
  position: absolute;
  top: 3rem;
  left: 4rem;
`

const LeftContainer = styled.div<{ loginType: any }>`
  width: 50%;
  background-color: ${(props) => props.theme.palette.white};
  padding: 9rem 3rem;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 100%;
  }
`

const FormContainer = styled.div`
  a {
    width: 100%;
    height: 100%;
  }
`

const AuthBtnContainer = styled.div`
  display: flex;
  column-gap: 32px;
  row-gap: 1rem;
  display-direct: row;
  justify-content: center;

  a {
    text-decoration: none;
    white-space: nowrap;
  }
`

const ButtonContainer = styled.a`
  ${(props) => props.theme.typography.action1}
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.primary.pink500};
  svg {
    margin-right: 0.75rem;
  }
`

const SuccessMsgContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.success.green700};
  > div {
    color: ${(props) => props.theme.palette.success.green500};
  }
`

const LoginView = React.memo(() => {
  const dispatch = useDispatch()
  const location = useLocation()

  const hasUserId: boolean = useSelector((state: AppState) =>
    state.user.profile.id !== '0' ? true : false
  )
  const profile = useSelector((state: AppState) => state.employer.profile)

  const isExpired = isTokenExpired()
  const qp = UrlParser.getQueryParams(location.search)
  const userErr = useSelector((state: AppState) => state.user.error)
  const navigate = useNavigate()

  const [loginType, setLoginType] = useState(
    LocalStorage.getItem('platformType') || 'employer'
  )

  const toggleLoginType = (currLoginType) => {
    setLoginType(currLoginType === 'employer' ? 'employee' : 'employer')
    applicationActions.toggleEmployeePlatform()
    LocalStorage.setItem(
      'platformType',
      currLoginType === 'employer' ? 'employee' : 'employer'
    )
  }

  // redirect on successful login
  useEffect(() => {
    if (userErr?.status === false) {
      if (loginType === 'employer') {
        if (!profile) {
          return
        }

        if (
          (profile.lastOnboardingStep &&
            profile.lastOnboardingStep !== 'success') ||
          qp.new_signup === 'true' ||
          qp.from_signup === 'true'
        ) {
          navigate(Locations.GettingStarted)
        } else {
          navigate(Locations.Dashboard)
        }
      } else {
        navigate(Locations.EmployeeOnboarding)
      }
    }
  }, [userErr, navigate, loginType, qp, profile])

  // Social Auth login success redirect back to app
  useEffect(() => {
    if (
      qp.code &&
      qp.state &&
      (userErr === null || userErr?.status === false)
    ) {
      const isMsft = !qp.scope ? true : false
      dispatch(
        userActions.socialLogin({
          code: qp.code,
          isMsft,
          platformType: loginType,
        })
      )
    }
  }, [qp, dispatch, userErr, loginType])

  if (
    (!isExpired || hasUserId) &&
    (loginType !== 'employer' || (loginType === 'employer' && profile))
  ) {
    return (
      <Navigate
        to={
          loginType === 'employer'
            ? Locations.Dashboard
            : Locations.EmployeeOnboarding
        }
        replace
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>Login | Fleet</title>
      </Helmet>
      <Container>
        <LeftContainer loginType={loginType}>
          <LogoContainer>
            <FleetLogoFullColor height='45' width='190' />
          </LogoContainer>
          <FormContainer>
            {qp.new_signup === 'true' ? (
              <SuccessMsgContainer>
                <Text variant='action2' textAlign='center'>
                  {`You've successfully verified your email! Login to complete onboarding.`}
                </Text>
              </SuccessMsgContainer>
            ) : null}
            <Text variant='h1' marginBottom='1.5rem' textColor={palette.black}>
              Login to Fleet
            </Text>
            <Text
              variant='action5'
              marginBottom='1rem'
              textColor={palette.black}
            >
              Welcome back, who are you logging in as?
            </Text>
            <TextToggle
              toggled={loginType === 'employer' ? 0 : 1}
              options={['Admin', 'Commuter']}
              onClick={() => toggleLoginType(loginType)}
              variant='light'
              fullWidth
            />
            <Divider margin='1.5rem 0' />
            <AuthBtnContainer>
              <ButtonV2 variant='secondary' size='medium'>
                <ButtonContainer
                  href={GoogleAuthUrl}
                  onClick={() =>
                    SegmentService.track('social-login-click', {
                      provider: 'google',
                    })
                  }
                >
                  <GoogleIcon />
                  Login with Google
                </ButtonContainer>
              </ButtonV2>
              <ButtonV2 variant='secondary' size='medium'>
                <ButtonContainer
                  href={MsftAuthUrl}
                  onClick={() =>
                    SegmentService.track('social-login-click', {
                      provider: 'microsoft',
                    })
                  }
                >
                  <MicrosoftIcon />
                  Login with Microsoft
                </ButtonContainer>
              </ButtonV2>
            </AuthBtnContainer>
            <Divider margin='1.5rem 0' />
            <LoginForm userErr={userErr} loginType={loginType} />
          </FormContainer>
        </LeftContainer>
        <LoginSignUpGraphicV2
          platformType={loginType}
          imageLabel={ImageLabel.LOGIN}
        />
      </Container>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  LoginView.displayName = 'Login View'
}

export default LoginView
