import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, LinkProps } from 'react-router-dom'
import { Dispatch } from 'redux'
import styled, { css } from 'styled-components'
import { userActions } from 'redux/user/userSlice'
import Tooltip from '../../core-system/Tooltip'
import SegmentService from '../../redux/config/services/SegmentService'
import { Locations } from '../../shared/Router/Locations'
import { Action } from '../../shared/Types/Actions'
import { checkActive, SubMenu } from './SidebarShared'
import Text from '../../core-system/Text/Text'

const Container = styled.div`
  margin-bottom: 1.25rem;
  padding: 0 ${(props) => props.theme.pxToRem(20)};
  position: relative;
  z-index: ${(props) => props.theme.zIndex.max};
`

const LinkContainer = styled(Link)<{
  disabled?: boolean
  $active?: boolean
  $isCollapsed: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$isCollapsed ? 'center' : 'flex-start')};
  transition: width 0.5s ease;
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 100%;
  border: none;
  text-decoration: none;

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.$active
      ? {
          backgroundColor: props.theme.palette.primary.pink200,
          color: activeGradient(props.theme),
        }
      : ''}

  &:hover {
    ${(props) =>
      props.$active
        ? ''
        : `
          color: ${activeGradient(props.theme)};
          background: ${activeGradient(props.theme)};
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          svg {
            color: ${props.theme.palette.primary.pink500};
          }
        `}
  }
`

const IconContainer = styled.div<{ $active: boolean; $disabled: boolean }>`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => getSvgColor(props.$active, props.$disabled, props)};
  }
`

const getSvgColor = (active: boolean, disabled: boolean, props: any) => {
  if (active) {
    return props.theme.palette.primary.pink500
  }
  if (disabled) {
    return props.theme.palette.text.disabled
  }
  return props.theme.palette.white
}

const SubMenuContainer = styled.div<{
  isVisible: boolean
  position: PositionVariants
}>`
  position: absolute;
  left: ${(props) => props.theme.pxToRem(80)};
  padding-left: 0.5rem;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  z-index: ${(props) => props.theme.zIndex.tooltip};

  ${(props) =>
    props.position === 'bottom'
      ? css`
          bottom: 0;
        `
      : css`
          top: 0;
        `}
`

const SubMenuItems = styled.div`
  ${(props) => props.theme.typography.action4};
  display: flex;
  flex-direction: column;
  border: 0.0625rem solid ${(props) => props.theme.palette.grey.grey300};
  width: ${(props) => props.theme.pxToRem(180)};
  box-shadow: ${(props) => props.theme.dropShadows.selected};
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.25rem;
  padding: 0.5rem 0;
`

const SubMenuItem = styled(Link)<{ $active: boolean }>`
  text-decoration: none;
  padding: 0.8125rem 1rem;
  color: ${(props) =>
    props.$active
      ? activeGradient(props.theme)
      : props.theme.palette.text.primary};
  font-weight: 500;
  line-height: 0.875rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink400};
  }
`

const getLabelColor = (active: boolean, disabled: boolean, props: any) => {
  if (active) {
    return props.theme.palette.white
  }
  if (disabled) {
    return props.theme.palette.text.disabled
  }
  return props.theme.palette.white
}

const Label = styled(Text)<{ $active: boolean; $disabled: boolean }>`
  font-weight: 600;
  line-height: 1.4rem;
  color: ${(props) => getLabelColor(props.$active, props.$disabled, props)};
  margin-left: 0.75rem;

  ${(props) =>
    props.$active
      ? {
          background: activeGradient(props.theme),
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }
      : ''}
`

const activeGradient = (theme) =>
  `linear-gradient(180deg, ${theme.palette.primary.pink500} 0%, ${theme.palette.primary.pink400} 100%)`

const handleOnClick = (
  stat: string,
  location: string,
  setIsVisible: (open: boolean) => void,
  dispatch: Dispatch
) => {
  SegmentService.track('sidebar-item-click', {
    item: stat,
  })

  if (location === Locations.Login) {
    dispatch(userActions.logout())
  }

  setIsVisible(false)
}

const renderSubMenu = (
  menu: SubMenu[],
  setMenuOpen: (open: boolean) => void,
  path: string,
  dispatch: Dispatch<Action<any>>
) => {
  return menu.map((item: SubMenu, idx: number) => (
    <SubMenuItem
      key={idx}
      to={item.location}
      $active={item.location === path}
      onClick={() =>
        handleOnClick(item.metric, item.location, setMenuOpen, dispatch)
      }
    >
      {item.title}
    </SubMenuItem>
  ))
}

type PositionVariants = 'top' | 'bottom'

interface SidebarItemProps extends LinkProps {
  label: string
  disabled?: boolean
  icon?: React.ReactNode
  metric: string
  menu?: SubMenu[]
  menuposition?: PositionVariants
  path: string
  to: string
  isCollapsed: boolean
}

const SidebarItem = (props: SidebarItemProps) => {
  const {
    disabled = false,
    icon,
    label,
    metric,
    menu,
    path,
    to,
    menuposition = 'top',
    isCollapsed = false,
    ...rest
  } = props
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef(null)
  const dispatch = useDispatch()

  const active = checkActive(to, path, menu)

  return (
    <Container
      onMouseEnter={() => !disabled && menu && setIsVisible(true)}
      onMouseLeave={() => !disabled && menu && setIsVisible(false)}
      ref={ref}
    >
      <LinkContainer
        {...rest}
        to={to}
        disabled={disabled}
        $active={active}
        onClick={() => handleOnClick(metric, to, setIsVisible, dispatch)}
        onMouseEnter={() => !disabled && !menu && setIsVisible(true)}
        onMouseLeave={() => !disabled && !menu && setIsVisible(false)}
        $isCollapsed={isCollapsed}
      >
        {icon && (
          <IconContainer $active={active} $disabled={disabled}>
            {icon}
          </IconContainer>
        )}
        {!isCollapsed && (
          <Label $active={active} variant='h5' $disabled={disabled}>
            {label}
          </Label>
        )}
      </LinkContainer>
      {menu ? (
        <SubMenuContainer position={menuposition} isVisible={isVisible}>
          <SubMenuItems>
            {renderSubMenu(menu, setIsVisible, path, dispatch)}
          </SubMenuItems>
        </SubMenuContainer>
      ) : (
        isCollapsed && (
          <Tooltip
            target={ref}
            show={isVisible}
            position={'right'}
            marginLeft={'0.25rem'}
          >
            {label}
          </Tooltip>
        )
      )}
    </Container>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SidebarItem.displayName = 'SidebarItem'
}

export default SidebarItem
