import { incentivesActions } from 'redux/incentives/incentivesSlice'
import FlexContainer from 'core-system/FlexContainer'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import CalendarIcon from 'core-system/Icons/Misc/Calendar'
import EmployeeIcon from 'core-system/Icons/Misc/Employees'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import MyCommuterDetailsModal from 'features/Segments/MyCommuters/MyCommuterDetailsModal'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import styled from 'styled-components'
import TripsLogged from './components/TripsLogged'
import TripsParticipation from './components/TripsParticipation'
import TripsRewards from './components/TripsRewards'
import TripsCalendar from './TripsCalendar'
import TripsOverview from './TripsOverview'

const Header = styled.div`
  display: flex;
  margin-bottom: 1.125rem;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  padding: 2.5rem 20.25rem 5rem 3.25rem;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: 2.5rem 2.5rem 5rem;
  }
`

const Content = styled.div`
  flex: 1;
  max-width: 75rem;
  margin: 0 auto;
`

const Chevrons = styled.div`
  display: flex;
  margin-left: 1.5rem;
`

const StyledChevron = styled.div<{ isLeft?: boolean; isDisabled?: boolean }>`
  transform: rotate(${(props) => (props.isLeft ? '90deg' : '270deg')});
  color: ${(props) =>
    props.isDisabled
      ? props.theme.palette.text.disabled
      : props.theme.palette.text.primary};
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink500};
  }
`

const MetricsContainer = styled.div`
  display: grid;
  gap: 0.625rem;
  margin-bottom: 2.5rem;

  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.theme.pxToRem(475)}, 1fr)
  );

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-template-columns: repeat(auto-fill, 1fr);
  }
`

const TripsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.625rem;
`

const StyledCalendarIcon = styled(CalendarIcon)`
  margin-bottom: 0.125rem;
`

const TripsView = React.memo(() => {
  const { segmentId } = useParams()

  const today = moment()
  const dispatch = useDispatch()

  const { month, day, activeMonthSegmentId } = useSelector(
    (state: AppState) => state.incentives
  )
  const { segmentsMap } = useSelector((state: AppState) => state.employer)

  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM'))
  const [daySelected, setDaySelected] = useState(null)
  const [activeEmployeeId, setActiveEmployeeId] = useState(null)

  const handleClick = (isAdd: boolean) => {
    const currentMoment = moment(currentDate)

    setDaySelected(null)
    let newDate
    if (isAdd) {
      newDate = currentMoment.add(1, 'month').format('YYYY-MM')
    } else {
      newDate = currentMoment.subtract(1, 'month').format('YYYY-MM')
    }
    setCurrentDate(newDate)
    dispatch(incentivesActions.resetTripsData('month'))
    dispatch(
      incentivesActions.getTrips({
        segmentId: segmentId,
        date: newDate,
      })
    )
    SegmentService.track('incentives-date-toggle', {
      action: isAdd ? 'next' : 'previous',
    })
  }

  const handleSetActiveTripStats = (dayNumber: number) => {
    if (dayNumber !== daySelected) {
      setDaySelected(dayNumber)
      const newDate = `${currentDate}-${
        dayNumber <= 9 ? `0${dayNumber}` : dayNumber
      }`

      //if same-day for the same segment is selected, don't make a new api call
      if (!day || day.day !== newDate) {
        dispatch(
          incentivesActions.getTrips({
            segmentId: segmentId,
            date: newDate,
          })
        )
      }
    } else {
      setDaySelected(null)
    }
  }

  useEffect(() => {
    if (activeMonthSegmentId !== segmentId) {
      dispatch(
        incentivesActions.getTrips({
          segmentId: segmentId,
          date: currentDate,
        })
      )
    }
  }, [segmentId, currentDate, dispatch, activeMonthSegmentId])

  const isLoading = (!month || activeMonthSegmentId !== segmentId) && true

  return (
    <Container>
      <Content>
        <Header>
          <Text variant='h3'>
            {segmentsMap[segmentId].name === 'All Employees'
              ? 'All Commuters'
              : segmentsMap[segmentId].name}
          </Text>
          <Chevrons>
            <StyledChevron isLeft={true} onClick={() => handleClick(false)}>
              <ChevronIcon />
            </StyledChevron>
            <FlexContainer alignItems='center' margin='0 1rem'>
              <StyledCalendarIcon color={palette.text.primary} />
              <Text variant='action4' marginLeft='0.5rem' hideOverflow>
                {moment(currentDate).format('MMMM, YYYY')}
              </Text>
            </FlexContainer>
            <StyledChevron
              isDisabled={moment(currentDate).add(1, 'month').isAfter(today)}
              onClick={() => handleClick(true)}
            >
              <ChevronIcon />
            </StyledChevron>
          </Chevrons>
          <FlexContainer marginLeft='auto' alignItems='center'>
            <EmployeeIcon color={palette.text.primary} />
            <Text variant='action4' marginLeft='0.5rem'>
              {segmentsMap[segmentId].employees.length} Commuters
            </Text>
          </FlexContainer>
        </Header>
        <MetricsContainer>
          <TripsParticipation
            isLoading={isLoading}
            employeeEngaged={month && month.employeeEngaged}
            activeSegmentId={segmentId}
          />
          <TripsContainer>
            <TripsRewards
              isLoading={isLoading}
              rewardsGained={month && month.rewardsGained}
              rewardsPercentDiff={month && month.rewardsPercentDiff}
              rewardsSpent={month && month.rewardsSpent}
              activeSegmentId={segmentId}
            />
            <TripsLogged
              isLoading={isLoading}
              totalJourneys={month && month.totalTrips}
              fleetDetected={month && month.fleetDetected}
              tripsPercentDiff={month && month.tripsPercentDiff}
            />
          </TripsContainer>
        </MetricsContainer>
        <TripsCalendar
          isLoading={isLoading}
          tripsMonth={month && month}
          daySelected={daySelected}
          firstDayOfMonth={moment(currentDate).startOf('month')}
          setActiveTripStats={handleSetActiveTripStats}
        />
      </Content>
      <TripsOverview
        date={currentDate}
        activeTripStats={daySelected ? day : month}
        setDaySelected={setDaySelected}
        activeSegmentId={segmentId}
        onEmployeeClick={(id: string) => {
          setActiveEmployeeId(id)
        }}
      />
      <MyCommuterDetailsModal
        employeeId={activeEmployeeId}
        open={activeEmployeeId && true}
        closeModal={() => setActiveEmployeeId(null)}
      />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  TripsView.displayName = 'TripsView'
}

export default TripsView
