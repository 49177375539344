import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import fileDownload from 'js-file-download'
import {
  AllEmployeesSuccessPayload,
  ChangeWorksitePayload,
  Commuter,
  CreateEmployeeApiType,
  Employee,
  EmployeeIdParams,
  EmployeeSuccessPayload,
  MyCommuter,
  MyCommuterArchived,
  MyCommuterDeclined,
  MyCommuterDetails,
  MyCommutersParams,
  MyCommuterTransaction,
  MyCommuterTransactionsParams,
} from 'redux/employee/employeeTypes'
import { employerActions } from 'redux/employer/employerSlice'
import { userActions } from 'redux/user/userSlice'
import { UserApiType } from 'redux/user/userTypes'

export interface EmployeesState {
  list: Employee[]
  myCommuters: Dictionary<MyCommuter[]>
  allCommuters: Commuter[]
  allCommutersMap: Dictionary<Commuter>
  myCommuterDetails: MyCommuterDetails
  myCommuterTransactions: Dictionary<MyCommuterTransaction[]>
  archivedEmployees: MyCommuterArchived[]
  declinedEmployees: MyCommuterDeclined[]
  employeeUpdateFlag: boolean
  employeeWorksiteChangeFlag: string
}

const initialState: EmployeesState = {
  list: null,
  myCommuters: null,
  allCommuters: null,
  allCommutersMap: null,
  myCommuterDetails: null,
  myCommuterTransactions: null,
  archivedEmployees: null,
  declinedEmployees: null,
  employeeUpdateFlag: false,
  employeeWorksiteChangeFlag: null,
}

// get a random number between 1 - 12 as default profile icon
const getRandom = () => {
  return Math.floor(Math.random() * 12 + 1)
}

const getEmployees = createAction('employee/getEmployees')
const updateEmployee = createAction<EmployeeSuccessPayload>(
  'employee/updateEmployee'
)
const createEmployee = createAction<CreateEmployeeApiType>(
  'employee/createEmployee'
)
const inviteEmployees = createAction<string[]>('employee/inviteEmployees')
const getCommuters = createAction<string | null>('employee/getCommuters')
const getMyCommuters = createAction<MyCommutersParams>(
  'employee/getMyCommuters'
)
const resendEmployeeInvite = createAction<string>(
  'employee/resendEmployeeInvite'
)
const resendEmployeeInviteSuccess = createAction(
  'employee/resendEmployeeInviteSuccess'
)
const getArchivedEmployees = createAction('employee/getArchivedEmployees')
const archiveEmployee = createAction<EmployeeIdParams>(
  'employee/archiveEmployee'
)
const reactivateEmployee = createAction<EmployeeIdParams>(
  'employee/reactivateEmployee'
)
const getDeclinedEmployees = createAction('employee/getDeclinedEmployees')
const deleteEmployeeSuccess = createAction('employee/deleteEmployeeSuccess')
const inviteEmployeesSuccess = createAction('employee/inviteEmployeesSuccess')

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    toggleEmployeeWorksiteChangeFlagOff(state) {
      state.employeeWorksiteChangeFlag = null
    },
    getEmployeesSuccess(
      state,
      action: PayloadAction<AllEmployeesSuccessPayload>
    ) {
      state.list = action.payload.map((emp) => {
        return {
          ...emp,
          role: emp.groups[0],
          avatarUrl: emp.avatar,
          iconId: getRandom(),
        }
      })
    },

    updateEmployeeSuccess(
      state,
      action: PayloadAction<EmployeeSuccessPayload>
    ) {
      const f = {
        id: action.payload.id,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        worksiteId: action.payload.worksiteId,
        role: action.payload.groups[0],
        avatarUrl: action.payload.avatar,
        employer: action.payload.employer,
        hasRegistered: action.payload.hasRegistered,
      }

      state.list = state.list.map((emp) =>
        emp.id === f.id ? { ...f, iconId: emp.iconId } : emp
      )
    },
    deleteEmployee(state, action: PayloadAction<string>) {
      state.list = state.list.filter((e) => e.id !== action.payload)
    },
    createEmployeeSuccess(
      state,
      action: PayloadAction<EmployeeSuccessPayload>
    ) {
      const newUser = {
        id: action.payload.id,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        worksiteId: action.payload.worksiteId,
        role: action.payload.groups[0],
        avatarUrl: action.payload.avatar,
        employer: action.payload.employer,
        hasRegistered: action.payload.hasRegistered,
        iconId: getRandom(),
      }
      const updated = [...state.list, newUser]

      state.list = updated
    },
    getMyCommutersSuccess(state, action: any) {
      if (action.meta && action.meta.csv) {
        fileDownload(action.payload, 'my_commuters.csv')
        return { ...state }
      } else {
        return {
          ...state,
          myCommuters: {
            ...state.myCommuters,
            [action.meta.segment]: action.payload,
          },
          employeeUpdateFlag: state.employeeUpdateFlag
            ? false
            : state.employeeUpdateFlag,
        }
      }
    },
    getCommutersSuccess(state, action: PayloadAction<Commuter[]>) {
      state.allCommuters = action.payload
      state.allCommutersMap = action.payload.reduce((agg, commuter) => {
        return { ...agg, [commuter.id]: commuter }
      }, {})
    },
    getMyCommuterDetails(state, action: PayloadAction<any>) {
      const employeeId = action.payload.id

      if (state.myCommuterDetails && state.myCommuterDetails[employeeId]) {
        state.myCommuterDetails = {
          ...state.myCommuterDetails,
          [employeeId]: null,
        }
      }
    },
    getMyCommuterDetailsSuccess(state, action: PayloadAction<any>) {
      const employeeId = action.payload.id

      state.myCommuterDetails = {
        ...state.myCommuterDetails,
        [employeeId]: action.payload,
      }
    },
    resetMyCommuterDetails(state, action: PayloadAction<EmployeeIdParams>) {
      const { employeeId } = action.payload

      state.myCommuterDetails = {
        ...state.myCommuterDetails,
        [employeeId]: null,
      }
    },
    getMyCommuterTransactions(
      state,
      action: PayloadAction<MyCommuterTransactionsParams>
    ) {
      const employeeId = action.payload.id

      if (
        state.myCommuterTransactions &&
        state.myCommuterTransactions[employeeId]
      ) {
        state.myCommuterTransactions = {
          ...state.myCommuterTransactions,
          [employeeId]: null,
        }
      }
    },
    getMyCommuterTransactionsSuccess(state, action: any) {
      const employeeId = action.meta.id

      state.myCommuterTransactions = {
        ...state.myCommuterTransactions,
        [employeeId]: action.payload,
      }
    },
    getArchivedEmployeesSuccess(
      state,
      action: PayloadAction<MyCommuterArchived[]>
    ) {
      state.archivedEmployees = action.payload
    },
    archiveEmployeeSuccess(state, action: PayloadAction<any>) {
      state.myCommuters =
        action.type === 'employee/archiveEmployeeSuccess' ? null : {}
      state.employeeUpdateFlag = !state.employeeUpdateFlag
    },
    reactivateEmployeeSuccess(state, action: PayloadAction<any>) {
      state.myCommuters =
        action.type === 'employee/archiveEmployeeSuccess' ? null : {}
      state.employeeUpdateFlag = !state.employeeUpdateFlag
    },
    toggleEmployeeFlag(state) {
      state.employeeUpdateFlag = !state.employeeUpdateFlag
    },
    getDeclinedEmployeesSuccess(
      state,
      action: PayloadAction<MyCommuterDeclined[]>
    ) {
      state.declinedEmployees = action.payload
    },
    changeEmployeeWorksite(
      state,
      _action: PayloadAction<ChangeWorksitePayload>
    ) {
      state.employeeWorksiteChangeFlag = 'loading'
    },
    changeEmployeeWorksiteSuccess(state) {
      state.employeeWorksiteChangeFlag = 'success'
    },
    changeEmployeeWorksiteFailed(state) {
      state.employeeWorksiteChangeFlag = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        userActions.updateUserSuccess,
        (state, action: PayloadAction<UserApiType>) => {
          state.list = state.list.map((emp) =>
            emp.id === action.payload.id
              ? ({
                  ...action.payload,
                  employer: emp.employer,
                  iconId: emp.iconId,
                  role: emp.role,
                  avatarUrl: emp.avatarUrl,
                } as Employee)
              : emp
          )
        }
      )
      .addCase(employerActions.updateSegmentSuccess, (state, action: any) => {
        if (action.meta.shippingDetails) {
          return {
            ...state,
          }
        } else {
          return {
            ...state,
            myCommuters: {},
          }
        }
      })
      .addCase(employerActions.deleteSegmentSuccess, (state) => {
        state.myCommuters = null
      })
  },
})

export const employeeReducer = employeeSlice.reducer
export const employeeActions = {
  ...employeeSlice.actions,
  getEmployees,
  updateEmployee,
  createEmployee,
  inviteEmployees,
  getCommuters,
  getMyCommuters,
  resendEmployeeInvite,
  resendEmployeeInviteSuccess,
  getArchivedEmployees,
  archiveEmployee,
  reactivateEmployee,
  getDeclinedEmployees,
  deleteEmployeeSuccess,
  inviteEmployeesSuccess,
}
