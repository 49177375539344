import Card, { CardRow } from 'core-system/Card'
import Chip from 'core-system/Chip'
import FleetError from 'core-system/Error'
import Loading from 'core-system/Loading'
import Table, {
  TableLabelRow,
  TableRow,
  TableRowCell,
  TableRowContents,
} from 'core-system/Table'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import moment from 'moment'
import React from 'react'
import { EmployerInvoice } from 'redux/employer/employerTypes'
import FormattingUtils from 'shared/FormattingUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'
import styled, { css } from 'styled-components'

const StyledCard = styled.div`
  ${(props) => props.theme.baseCard}
  ${(props) => props.theme.typography.h4};
  margin-bottom: 2.5rem;
  width: 100%;
  padding: 2.5rem;
`

const InvoiceLink = styled.a<{ hasLink: boolean }>`
  ${(props) => props.theme.typography.hyperlink};
  color: ${(props) => props.theme.palette.primary.pink400};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }

  ${(props) =>
    !props.hasLink &&
    css`
      color: ${(props) => props.theme.palette.text.disabled};
      cursor: default;
      &:hover {
        color: ${(props) => props.theme.palette.text.disabled};
      }
    `};
`

const StyledTable = styled(Table)`
  box-shadow: unset;
`

const getChipType = (paid: boolean) => {
  return paid ? 'green' : 'red'
}

const getTableRows = (isTablet: boolean, invoices: EmployerInvoice[]) => {
  return invoices.map((bill, idx) => {
    return (
      <TableRow key={idx}>
        <TableRowContents padding={'0.5rem'}>
          <TableRowCell
            textColor={palette.text.primary}
            fontType={'action4'}
            label={isTablet && 'Invoice Date'}
            minWidth={pxToRem(140)}
          >
            {moment(bill.invoiceDate).format('MMMM DD, YYYY')}
          </TableRowCell>
          <TableRowCell
            fontType={'action4'}
            label={isTablet && 'Amount'}
            minWidth={pxToRem(100)}
          >
            {FormattingUtils.formatDollar(bill.amount)}
          </TableRowCell>
          <TableRowCell
            fontType={'action4'}
            label={isTablet && 'Status'}
            minWidth={pxToRem(80)}
          >
            <Chip variant={getChipType(bill.paid)}>
              {bill.paid ? 'PAID' : 'UNPAID'}
            </Chip>
          </TableRowCell>
          <TableRowCell label={isTablet && 'Invoice Link'}>
            <InvoiceLink
              href={bill.link}
              hasLink={bill.link ? true : false}
              target='_blank'
            >
              View Invoice
            </InvoiceLink>
          </TableRowCell>
        </TableRowContents>
      </TableRow>
    )
  })
}

const getCards = (invoices: EmployerInvoice[]) => {
  return invoices.map((bill, idx) => {
    return (
      <Card key={idx} padding={'0 0.25rem 1rem'}>
        <CardRow textColor={palette.text.primary} label={'Invoice Date'}>
          {moment(bill.invoiceDate).format('MMM DD, YYYY')}
        </CardRow>
        <CardRow label={'Amount'}>
          {FormattingUtils.formatDollar(bill.amount)}
        </CardRow>
        <CardRow label={'Status'}>
          <Chip variant={getChipType(bill.paid)}>
            {bill.paid ? 'PAID' : 'UNPAID'}
          </Chip>
        </CardRow>
        <CardRow label={'Invoice Link'}>
          <InvoiceLink
            href={bill.link}
            hasLink={bill.link ? true : false}
            target='_blank'
          >
            View Invoice
          </InvoiceLink>
        </CardRow>
      </Card>
    )
  })
}

interface InvoicesProps {
  invoices: EmployerInvoice[]
}

const Invoices = (props: InvoicesProps) => {
  const { invoices } = props
  const [isTablet, setIsTablet] = React.useState(window.innerWidth <= 768)
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 576)
  const handleResize = React.useCallback(
    (width: number) => {
      setIsTablet(width <= 768)
      setIsMobile(width <= 576)
    },
    [setIsTablet, setIsMobile]
  )

  useResizeListener(() => handleResize(window.innerWidth), [])

  const renderTable = () => {
    if (!invoices) {
      return <Loading marginTop='1.5rem' height={pxToRem(177)} />
    } else if (invoices.length === 0) {
      return <FleetError description={'No invoices'} padding='1.5rem 0' />
    } else if (isMobile) {
      return getCards(invoices)
    } else {
      return (
        <StyledTable mt='1.5rem'>
          <TableLabelRow hasHeader={false}>
            <TableRowCell fontType={'body2'} minWidth={pxToRem(140)}>
              Invoice Date
            </TableRowCell>
            <TableRowCell fontType={'body2'} minWidth={pxToRem(100)}>
              Amount
            </TableRowCell>
            <TableRowCell fontType={'body2'} minWidth={pxToRem(80)}>
              Status
            </TableRowCell>
            <TableRowCell fontType={'body2'}>Invoice Link</TableRowCell>
          </TableLabelRow>
          {getTableRows(isTablet, invoices)}
        </StyledTable>
      )
    }
  }

  return (
    <StyledCard>
      Invoices
      {renderTable()}
    </StyledCard>
  )
}

export default React.memo(Invoices)
