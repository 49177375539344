import { map } from 'lodash-es'

export const countNumberOfUnseenMessages = (chats: Object) => {
  let unseenMessages = 0
  map(chats, (chat) => {
    if (chat.read === false && chat.sentBy === 'tutor') {
      unseenMessages += 1
    }
  })
  return unseenMessages
}

export const arrangeChatsById = (chats: Object) => {
  const arrangedChats = {}
  map(chats, (chat) => {
    arrangedChats[chat.id] = chat
  })
  return arrangedChats
}
