import React, { useState } from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  PositionProps,
  variant,
} from 'styled-system'
import moment, { Moment } from 'moment'
import Divider from 'core-system/Divider'
import palette from 'core-system/Themes/palette'

const Container = styled.div<
  FlexboxProps & SpaceProps & LayoutProps & { top: number; left: number }
>`
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-top: 0.5rem;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0 0 0.5rem 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.text.secondary};
  width: 100%;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  z-index: 1;

  ${flexbox}
  ${space}
  ${layout}
`

const YearSelector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
`

const MonthsSelector = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
  margin: 0 0.5rem;
`

type MonthState = 'unselected' | 'hover' | 'selected'
const Month = styled.div<{
  monthState: MonthState
  isDisabled?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border-radius: 4px;
  background-color: ${(props) => getMonthBgColor(props.monthState)};
  color: ${(props) => getMonthTextColor(props.monthState)};
  color: ${(props) =>
    props.isDisabled ? props.theme.palette.text.disabled : null};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};

  &:hover {
    color: ${(props) =>
      props.monthState === 'selected'
        ? null
        : props.theme.palette.text.primary};
    background-color: ${(props) =>
      props.monthState === 'selected'
        ? null
        : props.theme.palette.primary.pink200};
    cursor: pointer;
  }
`

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey300};
  height: 3rem;
`

const Button = styled.div<{ variant: string }>`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.palette.grey.grey100};
    cursor: pointer;
  }

  ${(props) =>
    variant({
      variants: {
        apply: {
          color: props.theme.palette.primary.pink800,
          borderRadius: '0 0 0.5rem 0',
        },
        clear: {
          color: props.theme.palette.text.secondary,
          borderRadius: '0 0 0 0.5rem',
        },
      },
    })}
`

const StyledChevron = styled.div<{ isLeft?: boolean; isDisabled?: boolean }>`
  transform: rotate(${(props) => (props.isLeft ? '90deg' : '270deg')});
  color: ${(props) =>
    props.isDisabled
      ? props.theme.palette.text.disabled
      : props.theme.palette.text.primary};
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const getMonthState = (
  isSelected: boolean,
  isBetweenStartAndHovered: boolean
) => {
  if (isSelected) {
    return 'selected'
  } else if (isBetweenStartAndHovered) {
    return 'hover'
  } else {
    return 'unselected'
  }
}

const getMonthBgColor = (monthState: MonthState) => {
  switch (monthState) {
    case 'selected':
      return palette.primary.pink800
    case 'hover':
      return palette.primary.pink200
    default:
      return palette.white
  }
}

const getMonthTextColor = (monthState: MonthState) => {
  switch (monthState) {
    case 'selected':
      return palette.white
    case 'hover':
      return palette.primary.pink800
    default:
      return palette.text.primary
  }
}

const getMoment = (yearString: string, monthNo: number) => {
  return moment(
    yearString +
      monthNo.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
  )
}

export type YearMonth = { year: string; month: number }

interface DropdownCalendarProps
  extends SpaceProps,
    LayoutProps,
    FlexboxProps,
    PositionProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  top: number
  activeYear: string
  activeYearSetter: React.Dispatch<React.SetStateAction<string>>
  selectedStartDate: YearMonth
  startDateSetter: React.Dispatch<React.SetStateAction<YearMonth>>
  selectedEndDate: YearMonth
  endDateSetter: React.Dispatch<React.SetStateAction<YearMonth>>
  onClear: () => void
  onApply: () => void
}

const DropdownCalendar = React.memo((props: DropdownCalendarProps) => {
  const {
    top,
    activeYear,
    activeYearSetter: setActiveYear,
    selectedStartDate,
    startDateSetter: setStartDate,
    selectedEndDate,
    endDateSetter: setEndDate,
    onClear,
    onApply,
  } = props

  const [hoveredMonth, setHoveredMonth] = useState<number>(null)
  const today = moment()

  const handleChevronClick = (isAdd: boolean) => {
    const activeMoment = moment(activeYear)
    if (isAdd) {
      setActiveYear(activeMoment.add(1, 'years').format('YYYY'))
    } else {
      setActiveYear(activeMoment.subtract(1, 'years').format('YYYY'))
    }
  }

  // if date pressed is before existing start date, change that
  // to be the new start date, otherwise set it as new end date
  const handleMonthClick = (
    startMoment: Moment,
    thisMoment: Moment,
    monthNo: number
  ) => {
    if (!startMoment || (startMoment && thisMoment < startMoment)) {
      setStartDate({ year: activeYear, month: monthNo })
    } else if (startMoment.isSame(thisMoment)) {
      onApply()
    } else {
      setEndDate({ year: activeYear, month: monthNo })
    }
  }

  const renderMonths = () => {
    return moment.monthsShort().map((monthName: string, idx: number) => {
      const monthNo = idx + 1
      const monthStr = monthNo.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
      const isSelected =
        (selectedStartDate &&
          selectedStartDate.year === activeYear &&
          selectedStartDate.month === monthNo) ||
        (selectedEndDate &&
          selectedEndDate.year === activeYear &&
          selectedEndDate.month === monthNo)
      const startMoment =
        selectedStartDate &&
        getMoment(selectedStartDate.year, selectedStartDate.month)
      const endMoment =
        selectedEndDate &&
        getMoment(selectedEndDate.year, selectedEndDate.month)
      const hoveredMoment = hoveredMonth && getMoment(activeYear, hoveredMonth)
      const thisMoment = moment(activeYear + monthStr, 'YYYYMM')
      const isBetweenStartAndHovered =
        startMoment &&
        hoveredMoment &&
        startMoment < thisMoment &&
        thisMoment < hoveredMoment
      const isBetweenStartAndEnd =
        startMoment &&
        endMoment &&
        startMoment < thisMoment &&
        thisMoment < endMoment
      const monthState = getMonthState(
        isSelected,
        isBetweenStartAndHovered || isBetweenStartAndEnd
      )
      return (
        <Month
          key={idx}
          onClick={() => handleMonthClick(startMoment, thisMoment, monthNo)}
          monthState={monthState}
          isDisabled={
            activeYear >= today.format('YYYY') &&
            idx >= parseInt(today.format('MM'))
          }
          onMouseEnter={() => setHoveredMonth(idx + 1)}
          onMouseLeave={() => setHoveredMonth(null)}
        >
          <Text variant='action4' textColor='inherit'>
            {monthName}
          </Text>
        </Month>
      )
    })
  }

  return (
    <Container top={top} left={0}>
      <YearSelector>
        <StyledChevron isLeft={true} onClick={() => handleChevronClick(false)}>
          <ChevronIcon />
        </StyledChevron>
        <Text variant='action1' marginTop='0.25rem'>
          {activeYear}
        </Text>
        <StyledChevron
          isLeft={false}
          onClick={() => handleChevronClick(true)}
          isDisabled={activeYear >= today.format('YYYY')}
        >
          <ChevronIcon />
        </StyledChevron>
      </YearSelector>
      <MonthsSelector>{renderMonths()}</MonthsSelector>
      <ButtonsRow>
        <Button variant='clear' onClick={() => onClear()}>
          <Text variant='action4' textColor='inherit'>
            Clear
          </Text>
        </Button>
        <Divider direction='vertical' size='100%' />
        <Button variant='apply' onClick={() => onApply()}>
          <Text variant='action4' textColor='inherit'>
            Apply
          </Text>
        </Button>
      </ButtonsRow>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DropdownCalendar.displayName = 'DropdownCalendar'
}

export default DropdownCalendar
