import { mapIntelligenceActions } from 'redux/mapIntelligence/mapIntelligenceSlice'
import Loading from 'core-system/Loading'
import Map from 'core-system/Map/Map'
import MapNavigationControl from 'core-system/Map/MapNavigationControl'
import MapPopupWorksite from 'core-system/Map/Popups/MapPopupWorksite'
import pxToRem from 'core-system/utils/pxToRem'
import KeyMetricsAgony from 'features/MapIntelligence/KeyMetrics/KeyMetricsAgony'
import KeyMetricsModes from 'features/MapIntelligence/KeyMetrics/KeyMetricsModes'
import {
  agonyOutlineStyle,
  agonyStyle,
  employeeAgonyStyle,
  generateCurrentCommuteModesStyle,
} from 'features/MapIntelligence/MapIntelligenceStyles'
import PopupAgony from 'features/MapIntelligence/Popup/PopupAgony'
import PopupEmployees from 'features/MapIntelligence/Popup/PopupEmployees'
import { FeatureCollection } from 'geojson'
import React, { useEffect, useMemo, useState } from 'react'
import { Layer, Source } from 'react-map-gl/mapbox'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import RandomUtils from 'shared/RandomUtils'
import styled, { css } from 'styled-components'

const popupMap = {
  worksites: MapPopupWorksite,
  currentCommuteModes: PopupEmployees,
  agony: PopupAgony,
  employees: PopupEmployees,
}

const MapContainer = styled.div`
  ${(props) => props.theme.baseCard}
  overflow: hidden;
  position: relative;
  padding: 0;
  margin-top: 1rem;
`

const StatsContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.palette.white};
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey300};
  display: flex;
  padding: 1.5rem 2.5rem;
`

const FilterContainer = styled.div`
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  display: flex;
`

const Filter = styled.div<{ active: boolean }>`
  ${(props) => props.theme.typography.action4}
  padding: 0.375rem 1rem;
  color: ${(props) => props.theme.palette.text.secondary};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  background-color: ${(props) => props.theme.palette.white};
  cursor: pointer;
  border-radius: 1rem;
  margin-right: 0.5rem;

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.pink400};
    background-color: ${(props) => props.theme.palette.primary.pink400};
    color: ${(props) => props.theme.palette.white};
  }

  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${(props) => props.theme.palette.primary.pink400};
      background-color: ${(props) => props.theme.palette.primary.pink400};
      color: ${(props) => props.theme.palette.white};
    `}
`

const DashboardOnboardingMap = React.memo(() => {
  const dispatch = useDispatch()

  const { agonyGeojson, statistics, employeesGeojson, worksiteGeojson } =
    useSelector((state: AppState) => state.mapIntelligence)
  const { allEmployeeSegmentId } = useSelector(
    (state: AppState) => state.employer
  )

  const [activeLayer, setActiveLayer] = useState('commuteModes')

  useEffect(() => {
    dispatch(
      mapIntelligenceActions.getAgonyData({ segmentId: allEmployeeSegmentId })
    )
    dispatch(
      mapIntelligenceActions.getBaseCommuters({
        segmentId: allEmployeeSegmentId,
      })
    )
  }, [dispatch, allEmployeeSegmentId])

  const currentCommuteModeStyles = useMemo(
    () => statistics && generateCurrentCommuteModesStyle(statistics.modes),
    [statistics]
  )

  if (!employeesGeojson || !agonyGeojson || !statistics) {
    return <Loading height={pxToRem(691)} />
  }

  const commuteModeData = Object.keys(statistics.modes).reduce(
    (agg, key) => {
      agg.percentages.push(
        Math.round((statistics.modes[key] / statistics.totalEmployees) * 100)
      )
      agg.labels.push(RandomUtils.capitalize(key))
      return agg
    },
    { percentages: [], labels: [] }
  )

  const handleLayerChange = (
    activeLayer: string,
    updateBbox: (featureCollection: FeatureCollection) => void
  ) => {
    setActiveLayer(activeLayer)
    updateBbox(employeesGeojson)
  }

  return (
    <>
      <MapContainer>
        <Map
          style={{
            height: '500px',
            width: '100%',
          }}
          validInteractiveGeoJSONLayers={
            activeLayer === 'agony'
              ? ['agony', 'employees']
              : ['currentCommuteModes']
          }
          worksites={worksiteGeojson}
          loadLayer={employeesGeojson}
          popupMap={popupMap}
        >
          {(mapProps) => (
            <>
              {activeLayer === 'commuteModes' && (
                <Source
                  type='geojson'
                  id='currentCommuteModes'
                  data={employeesGeojson}
                >
                  <Layer {...currentCommuteModeStyles} />
                </Source>
              )}
              {activeLayer === 'agony' && (
                <>
                  <Source type='geojson' id='agony' data={agonyGeojson}>
                    <Layer {...agonyStyle} />
                  </Source>
                  <Source type='geojson' id='agonyOutline' data={agonyGeojson}>
                    <Layer {...agonyOutlineStyle} />
                  </Source>
                  <Source type='geojson' id='employees' data={employeesGeojson}>
                    <Layer {...employeeAgonyStyle} />
                  </Source>
                </>
              )}
              <MapNavigationControl
                setViewport={mapProps.setViewport}
                featureCollection={employeesGeojson}
                updateBbox={mapProps.updateBbox}
              />
              <FilterContainer>
                <Filter
                  active={activeLayer === 'commuteModes'}
                  onClick={() =>
                    handleLayerChange('commuteModes', mapProps.updateBbox)
                  }
                >
                  Commute Modes
                </Filter>
                <Filter
                  active={activeLayer === 'agony'}
                  onClick={() =>
                    handleLayerChange('agony', mapProps.updateBbox)
                  }
                >
                  Commuter Agony
                </Filter>
              </FilterContainer>
            </>
          )}
        </Map>
        <StatsContainer>
          <>
            {activeLayer === 'commuteModes' && (
              <KeyMetricsModes
                data={commuteModeData.percentages}
                labels={commuteModeData.labels}
              />
            )}
            {activeLayer === 'agony' && (
              <KeyMetricsAgony
                agonyStats={statistics.agony}
                totalEmployees={statistics.totalEmployees}
              />
            )}
          </>
        </StatsContainer>
      </MapContainer>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  DashboardOnboardingMap.displayName = 'DashboardOnboardingMap'
}

export default DashboardOnboardingMap
