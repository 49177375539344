import Button from 'core-system/Button'
import Chip from 'core-system/Chip/Chip'
import FlexContainer from 'core-system/FlexContainer'
import CloseIcon from 'core-system/Icons/Actions/Close'
import BonusMedal from 'core-system/Icons/Leaderboards/BonusMedal'
import RewardsIcons from 'core-system/Icons/Sidebar/Leaderboards'
import Loading from 'core-system/Loading'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import LeaderboardUtils from 'features/Leaderboards/LeaderboardUtils'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import {
  Leaderboard,
  LeaderboardsGroup,
} from 'redux/leaderboards/leaderboardsTypes'
import styled, { css } from 'styled-components'

const Container = styled.div`
  height: 100%;
`

const IconContainer = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink500};
  }
`

const ProgramRow = styled.div`
  ${(props) => props.theme.baseCard}
  cursor: pointer;
  margin-bottom: 0.5rem;
  width: ${(props) => props.theme.pxToRem(368)};
  margin-right: 0.375rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const PurpleContainer = styled(FlexContainer)`
  padding: 0.5rem;
  border-radius: 0.3125rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1rem;
`

const Footer = styled(FlexContainer)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: ${(props) => props.theme.dropShadows.top};
  min-width: ${(props) => props.theme.pxToRem(442)};
`

const Programs = styled.div`
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 100%;
  padding-bottom: 6.25rem;

  /* width */
  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 1rem;
    opacity: 0.8;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    opacity: 1;
  }
`

const LeaderboardIcon = styled.div<{ isPending: boolean }>`
  position: relative;
  padding: 0 0.25rem 0 0.125rem;
  display: flex;

  ${(props) =>
    props.isPending &&
    css`
      opacity: 0.65;
    `};
`

const BonusContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const renderLeaderboardIcons = (leaderboards: Leaderboard[]) => {
  return leaderboards.map((leaderboard) => {
    return (
      <LeaderboardIcon
        key={leaderboard.type}
        isPending={leaderboard.status === 'PENDING'}
      >
        {LeaderboardUtils.leaderboardData[leaderboard.type].icon}
        {leaderboard.bonuses.some((bonus) => bonus) && (
          <BonusContainer>
            <BonusMedal width={16} height={16} />
          </BonusContainer>
        )}
      </LeaderboardIcon>
    )
  })
}

const renderProgramRows = (
  allSegments: Segment[],
  leaderboardsMap: Dictionary<LeaderboardsGroup>,
  handleOnClick: (segmentId: string) => void
) => {
  return [...allSegments]
    .sort(
      (a, b) =>
        (leaderboardsMap[b.id] ? leaderboardsMap[b.id].active : 0) -
        (leaderboardsMap[a.id] ? leaderboardsMap[a.id].active : 0)
    )
    .map((segment) => {
      const isActive = leaderboardsMap[segment.id]
      const activeLeaderboards =
        isActive &&
        LeaderboardUtils.getActiveLeaderboards(
          leaderboardsMap[segment.id].programGroups
        )
      return (
        <ProgramRow key={segment.id} onClick={() => handleOnClick(segment.id)}>
          <FlexContainer alignItems='center' marginBottom='1rem'>
            <FlexContainer
              width={pxToRem(300)}
              alignItems='center'
              justifyContent='space-between'
            >
              <FlexContainer>
                <PurpleContainer center>
                  <RewardsIcons color={palette.text.secondary} />
                </PurpleContainer>
                <div>
                  <Text variant='action2'>{segment.name}</Text>
                  <Text variant='body2' textColor={palette.text.placeholder}>
                    {isActive ? leaderboardsMap[segment.id].active : 0} Active
                    Competitions
                  </Text>
                </div>
              </FlexContainer>
              <div>
                <Text variant='body2' textColor={palette.text.placeholder}>
                  Commuters
                </Text>
                <Text variant='action2'>{segment.commuters}</Text>
              </div>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            {isActive ? (
              <FlexContainer>
                {renderLeaderboardIcons(activeLeaderboards)}
              </FlexContainer>
            ) : (
              <Chip variant={'disabled'}>off</Chip>
            )}
          </FlexContainer>
        </ProgramRow>
      )
    })
}

interface NavigationBarLeaderboardsProps {
  onClose: () => void
  toggleLeaderboardsModal: () => void
  toggleNoPaymentModal: () => void
  segmentId: string
}

const NavigationBarLeaderboards = React.memo(
  (props: NavigationBarLeaderboardsProps) => {
    const {
      onClose,
      toggleLeaderboardsModal,
      toggleNoPaymentModal,
      segmentId,
    } = props

    const navigate = useNavigate()

    const { leaderboardsMap } = useSelector(
      (state: AppState) => state.leaderboards
    )
    const { allSegments, segmentsMap, hasValidPaymentMethod, profile } =
      useSelector((state: AppState) => state.employer)

    const handleOnClick = useCallback(
      (nextSegmentId: string) => {
        navigate(`/leaderboards/${nextSegmentId}`)
        onClose()
        SegmentService.track('segmentNav-action-click', {
          action: `segment-${segmentsMap[nextSegmentId].name}`,
          locationAt: `leaderboards-${segmentsMap[segmentId].name}`,
        })
      },
      [navigate, onClose, segmentsMap, segmentId]
    )

    const handleStartNewProgram = useCallback(() => {
      if (hasValidPaymentMethod || !profile?.openRegistration) {
        toggleLeaderboardsModal()
        onClose()
        SegmentService.track('segmentNav-action-click', {
          action: 'add',
          locationAt: `leaderboards-${segmentsMap[segmentId].name}`,
        })
      } else {
        toggleNoPaymentModal()
        onClose()
      }
    }, [
      toggleLeaderboardsModal,
      toggleNoPaymentModal,
      hasValidPaymentMethod,
      profile,
      onClose,
      segmentsMap,
      segmentId,
    ])

    if (!allSegments || !segmentsMap || !leaderboardsMap) {
      return <Loading />
    }

    return (
      <Container>
        <FlexContainer
          alignItems='center'
          justifyContent='space-between'
          marginBottom='1.5rem'
        >
          <Text variant='h3'>All Leaderboards</Text>
          <IconContainer
            onClick={() => {
              onClose()
              SegmentService.track('segmentNav-action-click', {
                action: 'close',
                locationAt: `leaderboards-${segmentsMap[segmentId].name}`,
              })
            }}
          >
            <CloseIcon />
          </IconContainer>
        </FlexContainer>
        <Programs>
          {renderProgramRows(allSegments, leaderboardsMap, handleOnClick)}
        </Programs>
        <Footer center>
          <Button width='83%' onClick={handleStartNewProgram}>
            Start New Competition
          </Button>
        </Footer>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  NavigationBarLeaderboards.displayName = 'NavigationBarLeaderboards'
}

export default NavigationBarLeaderboards
