import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import { QtfProgramGroup } from 'redux/qtf/qtfTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'
import Button from 'core-system/Button'
import Chip from 'core-system/Chip/Chip'
import FlexContainer from 'core-system/FlexContainer'
import CloseIcon from 'core-system/Icons/Actions/Close'
import { QTF as QTFIcon } from 'core-system/Icons/Sidebar'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'

const Container = styled.div`
  height: 100%;
`

const IconContainer = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink500};
  }
`

const ProgramRow = styled.div`
  ${(props) => props.theme.baseCard}
  cursor: pointer;
  margin-bottom: 0.5rem;
  width: ${(props) => props.theme.pxToRem(368)};
  margin-right: 0.375rem;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const PurpleContainer = styled(FlexContainer)`
  padding: 0.5rem;
  border-radius: 0.3125rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  height: 2.5rem;
  width: 2.5rem;
`

const Spend = styled.div`
  width: ${(props) => props.theme.pxToRem(250)};
  padding-right: 2.25rem;
`

const ProgramContainer = styled.div`
  margin-bottom: 1rem;
`

const Footer = styled(FlexContainer)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: ${(props) => props.theme.dropShadows.top};
  min-width: ${(props) => props.theme.pxToRem(442)};
`

const Programs = styled.div`
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 100%;
  padding-bottom: 6.25rem;

  /* width */
  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 1rem;
    opacity: 0.8;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    opacity: 1;
  }
`

const getActiveRow = (qtfProgramGroup: QtfProgramGroup) => {
  if (qtfProgramGroup.active) {
    return qtfProgramGroup.active
  } else if (qtfProgramGroup.nextMonth) {
    return qtfProgramGroup.nextMonth
  } else {
    return null
  }
}

const getActiveQTFBuckets = (data: string[]) => {
  if (data && data.length === 2) return 600
  else if (data && data.length === 1) return 300
  return null
}

const renderProgramRows = (
  allSegments: Segment[],
  qtfProgram: Dictionary<QtfProgramGroup>,
  handleOnClick: (segmentId: string) => void,
  handleOnClickStart?: (e: React.MouseEvent, segmentId: string) => void
) => {
  return allSegments.map((segment) => {
    const isActive = qtfProgram[segment.id]
    const activeRow = isActive && getActiveRow(qtfProgram[segment.id])
    const emb = getActiveQTFBuckets(activeRow ? activeRow?.type : null)
    const transitActive = activeRow?.typeMap['COMMUTER_TRANSIT'] || false
    const parkingActive = activeRow?.typeMap['COMMUTER_PARKING'] || false

    return (
      <ProgramRow key={segment.id} onClick={() => handleOnClick(segment.id)}>
        <FlexContainer alignItems='center' marginBottom='1rem'>
          <FlexContainer width={pxToRem(250)} alignItems='center'>
            <PurpleContainer center>
              <QTFIcon color={palette.text.secondary} />
            </PurpleContainer>
            <Text variant='action2' marginLeft='1rem'>
              {segment.name}
            </Text>
          </FlexContainer>
          <div>
            <Text variant='body2' textColor={palette.text.placeholder}>
              EMB
            </Text>
            <Text
              variant='action2'
              textColor={
                isActive ? palette.text.primary : palette.text.disabled
              }
            >
              {isActive ? FormattingUtils.formatDollar(emb, 0) : '-'}
            </Text>
          </div>
        </FlexContainer>
        <FlexContainer>
          <Spend>
            {isActive ? (
              <>
                <FlexContainer
                  centerApart={true}
                  paddingY={'0.5rem'}
                  alignItems={'center'}
                >
                  <Text variant='action3'>Transit</Text>
                  <Chip variant={transitActive ? 'green' : 'grey'}>
                    {transitActive ? 'Active' : 'Disabled'}
                  </Chip>
                </FlexContainer>
                <FlexContainer
                  centerApart={true}
                  paddingY={'0.5rem'}
                  alignItems={'center'}
                >
                  <Text variant='action3'>Parking</Text>
                  <Chip variant={parkingActive ? 'green' : 'grey'}>
                    {parkingActive ? 'Active' : 'Disabled'}
                  </Chip>
                </FlexContainer>
              </>
            ) : (
              <Button
                size='small'
                onClick={(e) => handleOnClickStart(e, segment.id)}
              >
                Start Program
              </Button>
            )}
          </Spend>
          <div>
            <Text variant='body2' textColor={palette.text.placeholder}>
              Commuters
            </Text>
            <Text variant='action2'>{segment.commuters}</Text>
          </div>
        </FlexContainer>
      </ProgramRow>
    )
  })
}

interface NavigationBarOTFProps {
  onClose: () => void
  toggleQTFModal: () => void
  toggleNoPaymentModal: () => void
  segmentId: string
}

const NavigationBarQTF = React.memo((props: NavigationBarOTFProps) => {
  const { onClose, toggleQTFModal, toggleNoPaymentModal, segmentId } = props
  const navigate = useNavigate()

  const { allSegments, segmentsMap, hasValidPaymentMethod, profile } =
    useSelector((state: AppState) => state.employer)
  const qtfPrograms = useSelector((state: AppState) => state.qtf.qtfPrograms)

  const handleOnClick = useCallback(
    (nextSegmentId: string) => {
      navigate(`/tax-savings/${nextSegmentId}`)
      onClose()
      SegmentService.track('segmentNav-action-click', {
        action: `segment-${segmentsMap[nextSegmentId].name}`,
        locationAt: `qtf-${segmentsMap[segmentId].name}`,
      })
    },
    [navigate, onClose, segmentsMap, segmentId]
  )

  const handleOnClickStart = useCallback(
    (e: React.MouseEvent, segmentId: string) => {
      e.stopPropagation()
      if (hasValidPaymentMethod || !profile?.openRegistration) {
        navigate(`/tax-savings/${segmentId}/activation`)
        onClose()
      } else {
        toggleNoPaymentModal()
        onClose()
      }
    },
    [navigate, toggleNoPaymentModal, hasValidPaymentMethod, profile, onClose]
  )

  const handleStartNewProgram = useCallback(() => {
    if (hasValidPaymentMethod || !profile?.openRegistration) {
      toggleQTFModal()
      onClose()
      SegmentService.track('segmentNav-action-click', {
        action: 'add',
        locationAt: `qtf-${segmentsMap[segmentId].name}`,
      })
    } else {
      toggleNoPaymentModal()
      onClose()
    }
  }, [
    toggleQTFModal,
    toggleNoPaymentModal,
    hasValidPaymentMethod,
    profile,
    onClose,
    segmentsMap,
    segmentId,
  ])

  const sortedSegments = allSegments.reduce(
    (agg, segment) => {
      const isActive = qtfPrograms[segment.id]
      const activeRow = isActive && getActiveRow(qtfPrograms[segment.id])
      if (segment.type === 'WORKSITE') {
        if (!isActive) {
          agg.off.push(segment)
        } else {
          agg[activeRow.status.toLowerCase()].push(segment)
        }
      }

      return agg
    },
    { updating: [], active: [], pending: [], cancelling: [], off: [] }
  )

  return (
    <Container>
      <FlexContainer
        alignItems='center'
        justifyContent='space-between'
        marginBottom='1.5rem'
      >
        <Text variant='h3'>All Programs</Text>
        <IconContainer
          onClick={() => {
            onClose()
            SegmentService.track('segmentNav-action-click', {
              action: 'close',
              locationAt: `qtf-${segmentsMap[segmentId].name}`,
            })
          }}
        >
          <CloseIcon />
        </IconContainer>
      </FlexContainer>
      <Programs>
        {sortedSegments.updating.length > 0 && (
          <ProgramContainer>
            <Chip marginBottom='1rem' variant='blue'>
              Updating
            </Chip>
            {renderProgramRows(
              sortedSegments.updating,
              qtfPrograms,
              handleOnClick
            )}
          </ProgramContainer>
        )}
        {sortedSegments.active.length > 0 && (
          <ProgramContainer>
            <Chip marginBottom='1rem' variant='green'>
              Active
            </Chip>
            {renderProgramRows(
              sortedSegments.active,
              qtfPrograms,
              handleOnClick
            )}
          </ProgramContainer>
        )}
        {sortedSegments.pending.length > 0 && (
          <ProgramContainer>
            <Chip marginBottom='1rem' variant='purple'>
              Pending
            </Chip>
            {renderProgramRows(
              sortedSegments.pending,
              qtfPrograms,
              handleOnClick
            )}
          </ProgramContainer>
        )}
        {sortedSegments.cancelling.length > 0 && (
          <ProgramContainer>
            <Chip marginBottom='1rem' variant='red'>
              Cancelling
            </Chip>
            {renderProgramRows(
              sortedSegments.cancelling,
              qtfPrograms,
              handleOnClick
            )}
          </ProgramContainer>
        )}
        {sortedSegments.off.length > 0 && (
          <ProgramContainer>
            <Chip marginBottom='1rem' variant='grey'>
              off
            </Chip>
            {renderProgramRows(
              sortedSegments.off,
              qtfPrograms,
              handleOnClick,
              handleOnClickStart
            )}
          </ProgramContainer>
        )}
      </Programs>
      <Footer center>
        <Button width='83%' onClick={handleStartNewProgram}>
          Start New Program
        </Button>
      </Footer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  NavigationBarQTF.displayName = 'NavigationBarQTF'
}

export default NavigationBarQTF
