import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Modal, { ModalBody, ModalHeader } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import { useLocation, useNavigate } from 'react-router-dom'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import { AppState } from 'redux/config/store'
import { Segment } from 'redux/employer/employerTypes'
import styled from 'styled-components'
import UploadDataComponent from './UploadDataComponent'

const DropArea = styled.div<{ mouseOver: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-flow: column;
  width: 100%;
  height: 70%;
  border-radius: 0.25rem;
  border: ${pxToRem(1)} dashed ${(props) => props.theme.palette.primary.pink400};
  padding: 2.5rem 1.5rem;
  background-color: ${(props) =>
    props.mouseOver
      ? props.theme.palette.primary.pink300
      : props.theme.palette.white};
`

const LinkContainer = styled.div`
  margin: 1.5rem 0 2rem;
`

const StyledLink = styled.div`
  ${(props) => props.theme.typography.hyperlink};
  color: ${(props) => props.theme.palette.primary.pink400};
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    color: ${(props) => props.theme.palette.primary.pink100};
  }
`

const StyledHref = styled.a`
  ${(props) => props.theme.typography.hyperlink};
  color: ${(props) => props.theme.palette.primary.pink400};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.primary.pink100};
  }
`

interface UploadDataModalProps {
  closeModal: (status?: number) => void
  open: boolean
  isOnboarding?: boolean
  segment?: Segment
}

const UploadDataModal = React.memo((props: UploadDataModalProps) => {
  const { open, closeModal, isOnboarding = false, segment } = props

  const isFileLoading = useSelector(
    (state: AppState) => state.employer.isUploading
  )

  const navigate = useNavigate()
  const location = useLocation()

  // 0 ready to upload, 1=loading, 2=Success -1=error
  const [fileAdded, setFileAdded] = useState<number>(isFileLoading || 0)
  const [response, setResponse] = useState<string>('')

  const handleUploadHistoryClick = () => {
    navigate(`/segments/upload-history`, {
      state: { from: location.pathname },
    })
  }

  useEffect(() => {
    if (open) {
      setFileAdded(0)
    }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={() => closeModal(segment && isFileLoading)}
      width={pxToRem(750)}
    >
      <ModalHeader
        title={`Upload Commuter Data ${segment ? `(${segment.name})` : ''}`}
        borderBottom={'none'}
      />
      <ModalBody>
        <UploadDataComponent
          segment={segment}
          fileAdded={fileAdded}
          setFileAdded={setFileAdded}
          response={response}
          setResponse={setResponse}
          DropArea={DropArea}
        />
        <LinkContainer>
          <StyledHref
            href={`${StaticAssetsUrl}/onboarding/employee_data_template.csv`}
            download
          >
            Download a sample spreadsheet
          </StyledHref>
          {!isOnboarding && (
            <StyledLink onClick={handleUploadHistoryClick}>
              Click here to view your upload history
            </StyledLink>
          )}
        </LinkContainer>
      </ModalBody>
    </Modal>
  )
})

if (process.env.NODE_ENV !== 'production') {
  UploadDataModal.displayName = 'UploadDataModal'
}

export default UploadDataModal
