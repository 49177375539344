import Button from 'core-system/Button'
import Text from 'core-system/Text'
import Alert from 'core-system/Icons/Misc/Alert'
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useMemo, useState } from 'react'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import styled from 'styled-components'
import FinchWorksiteSelectMenu from './FinchWorksiteSelectMenu'
import { useDispatch } from 'react-redux'
import employerService from 'redux/employer/employerService'
import { employerActions } from 'redux/employer/employerSlice'
import { employeeActions } from 'redux/employee/employeeSlice'
import { Success } from 'core-system/Icons/Misc'
import palette from 'core-system/Themes/palette'
import RandomUtils from 'shared/RandomUtils'
const Container = styled.div`
  padding: 1.5rem;
  border: 0.0625rem solid ${(props) => props.theme.palette.grey.grey300};
  border-radius: ${(props) => props.theme.pxToRem(15)};
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.primary};
`

const ImgContainer = styled.div<{ imgUrl?: string }>`
  background-image: url(${(props) => props.imgUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  width: ${(props) => props.theme.pxToRem(142)};
  height: ${(props) => props.theme.pxToRem(142)};
  margin-bottom: 1.5rem;
`

const Heading = styled.div`
  ${(props) => props.theme.typography.action2};
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 0.5rem;
  text-transform: capitalize;
`

const SubText = styled.div`
  ${(props) => props.theme.typography.body2};
  color: ${(props) => props.theme.palette.text.placeholder};
`

const SyncResultMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
`

interface ManageHrisModalProps {
  providerName: string
  reAuth: boolean
  closeModal: (e: React.MouseEvent<any>) => void
  open: boolean
  openFinchConnect: () => void
  disconnectHris: (e: React.MouseEvent<any>) => void
}

const ManageHrisModal = (props: ManageHrisModalProps) => {
  const {
    providerName,
    reAuth,
    closeModal,
    open,
    openFinchConnect,
    disconnectHris,
  } = props

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [modalView, setModalView] = useState<
    'manage' | 'worksiteSelect' | 'syncSuccess' | 'syncFailure'
  >('manage')

  const [selectedFinchWorksites, setSelectedFinchWorksites] = useState([])

  const providerImgUrl = providerName && reAuth ? 'reconnect' : providerName

  const formattedProviderName =
    RandomUtils.formatFinchProviderName(providerName)

  const modalHeader = useMemo(() => {
    switch (modalView) {
      case 'manage':
        return `Manage ${formattedProviderName} Integration`
      case 'worksiteSelect':
        return `Sync ${formattedProviderName} Worksites`
      case 'syncSuccess':
        return `${formattedProviderName} Sync Success`
      case 'syncFailure':
        return `${formattedProviderName} Sync Failed`
    }
  }, [modalView, formattedProviderName])

  const handleClose = useCallback(
    (e: React.MouseEvent<any>) => {
      setModalView('manage')
      setLoading(false)
      setSelectedFinchWorksites([])
      closeModal(e)
    },
    [closeModal, setSelectedFinchWorksites]
  )

  const handleReconnect = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      openFinchConnect()
      handleClose(e)
    },
    [openFinchConnect, handleClose]
  )

  const handleSyncWorksites = useCallback(() => {
    setLoading(true)
    employerService
      .bulkSyncFinchWorksiteEmployees(selectedFinchWorksites)
      .then((res) => {
        if (res.data === 'Success') {
          dispatch(
            employerActions.bulkSyncFinchWorksiteEmployeesSuccess(res.data)
          )
          setModalView('syncSuccess')
        } else {
          setModalView('syncFailure')
        }
      })
      .then(() => dispatch(employeeActions.getCommuters()))
      .then(() => setLoading(false))
  }, [selectedFinchWorksites, dispatch])

  const handleBackPress = useCallback(() => {
    setModalView(modalView === 'worksiteSelect' ? 'manage' : 'worksiteSelect')
    setSelectedFinchWorksites([])
  }, [modalView])

  return (
    <Modal open={open} onClose={handleClose} width={pxToRem(514)}>
      <ModalHeader
        title={modalHeader}
        style={{ textTransform: 'capitalize' }}
      />
      <ModalBody paddingBottom='3.25rem' paddingTop='1.5rem'>
        {providerName && reAuth && (
          <Container>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ImgContainer
                imgUrl={`${StaticAssetsUrl}/hris_provider_logos/${providerImgUrl}.png`}
              />
            </div>
            <div>
              <Heading>Please Reconnect</Heading>
              <div>
                Something went wrong please reconnect your Fleet account with{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {formattedProviderName}.
                </span>
              </div>
            </div>
            <Button
              onClick={handleReconnect}
              marginBottom='1rem'
              marginTop='1.5rem'
              width='100%'
              iconLeft={<Alert />}
            >
              Reconnect
            </Button>
            <SubText>
              Your information is handled securely with end-to-end encryption.
            </SubText>
          </Container>
        )}
        {providerName && !reAuth && modalView === 'manage' && (
          <Container>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ImgContainer
                imgUrl={`${StaticAssetsUrl}/hris_provider_logos/${providerImgUrl}.png`}
              />
            </div>
            <div>
              <Heading>{formattedProviderName}</Heading>
              <div>
                Your Fleet account is currently connected to{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {formattedProviderName}.
                </span>
              </div>
            </div>
            <Button
              variant='primary'
              onClick={() => setModalView('worksiteSelect')}
              marginBottom='1rem'
              marginTop='1.5rem'
              width='100%'
            >
              Select Worksites to Sync
            </Button>
            <Button
              variant='secondary'
              onClick={disconnectHris}
              marginBottom='1rem'
              marginTop='1.5rem'
              width='100%'
            >
              Disconnect
            </Button>
            <SubText>
              Your information is handled securely with end-to-end encryption.
            </SubText>
          </Container>
        )}
        {providerName && modalView === 'worksiteSelect' && (
          <FinchWorksiteSelectMenu
            loading={loading}
            selectedFinchWorksites={selectedFinchWorksites}
            setSelectedFinchWorksites={setSelectedFinchWorksites}
            providerName={formattedProviderName}
          />
        )}
        {(modalView === 'syncSuccess' || modalView === 'syncFailure') && (
          <SyncResultMessageContainer>
            {modalView === 'syncSuccess' ? (
              <Success
                color={palette.success.green600}
                height={50}
                width={50}
              />
            ) : (
              <Alert color={palette.error.red500} height={50} width={50} />
            )}
            <Text variant='h3' textAlign='center'>
              {modalView === 'syncSuccess'
                ? 'Congratulations!'
                : 'Whoops! Something went wrong.'}
            </Text>
            <Text variant='body1' textAlign='center'>
              {modalView === 'syncSuccess'
                ? 'You have successfully synced your worksites.'
                : 'Please try syncing your worksites again. If the issue persists, please contact us at support@movewithfleet.com'}
            </Text>
          </SyncResultMessageContainer>
        )}
      </ModalBody>
      {(modalView === 'worksiteSelect' || modalView === 'syncFailure') && (
        <ModalFooter justifyContent={'space-between'}>
          <Button
            disabled={loading}
            variant='tertiary'
            onClick={handleBackPress}
          >
            Back
          </Button>
          {modalView === 'worksiteSelect' && (
            <Button
              onClick={handleSyncWorksites}
              disabled={selectedFinchWorksites.length === 0 || loading}
            >
              {`Sync Worksite${selectedFinchWorksites.length == 1 ? '' : 's'}`}
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ManageHrisModal.displayName = 'ManageHrisModal'
}

export default ManageHrisModal
