import Modal, { ModalBody, ModalFooter, ModalHeader } from 'core-system/Modal'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { QtfPerEmployeeAllocation } from 'redux/qtf/qtfTypes'
import Button from 'core-system/Button'
import { NumberFormatter, NumberInput } from 'core-system/Input/NumberInput'
import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import FormattingUtils from 'shared/FormattingUtils'
import Divider from 'core-system/Divider'
import { qtfActions } from 'redux/qtf/qtfSlice'
import palette from 'core-system/Themes/palette'
import Loading from 'core-system/Loading'
import {
  Alert as AlertIcon,
  Success as SuccessIcon,
} from 'core-system/Icons/Misc'
import qtfService from 'redux/qtf/qtfService'
import { AppState } from 'redux/config/store'

const allocationHasChanged = (
  originalAmount: number | null,
  currentAmount: number
) => {
  if (
    (!originalAmount && currentAmount > 0) ||
    originalAmount !== currentAmount
  ) {
    return true
  }
  return false
}

const renderEmployeeAllocation = (
  type: 'Transit' | 'Parking',
  allocationAmount: number,
  onAllocationChange: (amount: number) => void,
  maxAllocation: number
) => {
  return (
    <FlexContainer flexDirection='column' marginBottom='1.5rem'>
      <Text variant='body1'>{`${type} Allocation (max. ${FormattingUtils.formatDollar(
        maxAllocation,
        0
      )})`}</Text>
      <NumberInput
        padding='1rem'
        onValueChange={onAllocationChange}
        value={allocationAmount / 100}
        iconSize={24}
        incrementVal={5}
        maxWidth='460px'
        maxHeight='3rem'
      >
        <NumberFormatter decimalScale={0} fontVariant='h3' />
      </NumberInput>
      {allocationAmount > maxAllocation ? (
        <FlexContainer marginTop='0.5rem'>
          <Text variant='body2' textColor={palette.error.red500}>
            Please enter a value equal to or less than{' '}
            {FormattingUtils.formatDollar(maxAllocation, 0)}
          </Text>
        </FlexContainer>
      ) : null}
    </FlexContainer>
  )
}

const renderStatusMessage = (
  status: 'success' | 'error',
  type: 'Transit' | 'Parking',
  originalAmount: number | null,
  newAmount: number
) => {
  if (!allocationHasChanged(originalAmount, newAmount)) return null

  return (
    <FlexContainer flexDirection='row' alignItems='flex-start' marginY='1rem'>
      {status === 'success' ? (
        <SuccessIcon color={palette.success.green600} />
      ) : (
        <AlertIcon color={palette.error.red500} />
      )}
      <FlexContainer flexDirection='column' marginLeft='1rem'>
        <Text variant='action1'>{`${type} Allocation Update ${
          status === 'success' ? 'Successful' : 'Failed'
        }`}</Text>
        {status === 'success' ? (
          <div>
            <Text
              variant='action3'
              textColor={palette.text.secondary}
            >{`Original Amount: ${
              originalAmount
                ? FormattingUtils.formatDollar(originalAmount, 0)
                : '$0'
            }`}</Text>
            <Text
              variant='action3'
              textColor={palette.text.secondary}
            >{`New Amount: ${FormattingUtils.formatDollar(
              newAmount,
              0
            )}`}</Text>
          </div>
        ) : null}
      </FlexContainer>
    </FlexContainer>
  )
}

interface EditEmployeeAllocationModalProps {
  allocation: QtfPerEmployeeAllocation
  closeModal: () => void
  open: boolean
}

const EditEmployeeAllocationModal = React.memo(
  (props: EditEmployeeAllocationModalProps) => {
    const { allocation, closeModal, open } = props
    const dispatch = useDispatch()

    const { irsTaxLimit } = useSelector(
      (state: AppState) => state.application.properties
    )

    const maxEmployeeAllocation = {
      transit:
        irsTaxLimit -
        (allocation.deductions.find(
          (deduction) => deduction.type === 'COMMUTER_TRANSIT'
        )?.employerContribution || 0),
      parking:
        irsTaxLimit -
        (allocation.deductions.find(
          (deduction) => deduction.type === 'COMMUTER_PARKING'
        )?.employerContribution || 0),
    }

    const transitAllocation = allocation.deductions.find(
      (deduction) => deduction.type === 'COMMUTER_TRANSIT'
    )
    const parkingAllocation = allocation.deductions.find(
      (deduction) => deduction.type === 'COMMUTER_PARKING'
    )

    const [currentTransitAmount, setCurrentTransitAmount] = useState(
      transitAllocation?.employeeDeduction
        ? transitAllocation.employeeDeduction
        : 0
    )
    const [currentParkingAmount, setCurrentParkingAmount] = useState(
      parkingAllocation?.employeeDeduction
        ? parkingAllocation.employeeDeduction
        : 0
    )
    const [currentView, setCurrentView] = useState('edit')
    const [parkingStatus, setParkingStatus] = useState<
      null | 'success' | 'error'
    >(null)
    const [transitStatus, setTransitStatus] = useState<
      null | 'success' | 'error'
    >(null)

    const originalAllocation = {
      transit: transitAllocation?.employeeDeduction,
      parking: parkingAllocation?.employeeDeduction,
    }

    const handleTransitAllocationChange = (amount: number) => {
      setCurrentTransitAmount(amount * 100)
    }

    const handleParkingAllocationChange = (amount: number) => {
      setCurrentParkingAmount(amount * 100)
    }

    const createAllocation = async (
      currentAmount: number,
      benefitId: string,
      employeeId: string,
      setStatus: (status: null | 'success' | 'error') => void
    ) => {
      try {
        const result = await qtfService.createEmployeeAllocation({
          employeeDeduction: currentAmount,
          benefit: benefitId,
          employee: employeeId,
        })
        setStatus('success')
        dispatch(qtfActions.createEmployeeAllocationSuccess(result.data))
      } catch (_error) {
        setStatus('error')
      }
    }

    const updateAllocation = async (
      allocationId: string,
      currentAmount: number,
      benefitId: string,
      employeeId: string,
      setStatus: (status: null | 'success' | 'error') => void
    ) => {
      try {
        const result = await qtfService.updateEmployeeAllocation({
          allocationId: allocationId,
          updatedAllocation: {
            employeeDeduction: currentAmount,
            benefit: benefitId,
            employee: employeeId,
          },
        })
        setStatus('success')
        dispatch(qtfActions.updateEmployeeAllocationSuccess(result.data))
      } catch (_error) {
        setStatus('error')
      }
    }

    const handleSubmit = async () => {
      setCurrentView('loading')
      if (
        allocationHasChanged(originalAllocation.transit, currentTransitAmount)
      ) {
        if (originalAllocation.transit) {
          await updateAllocation(
            transitAllocation.id,
            currentTransitAmount,
            transitAllocation.benefitId,
            allocation.employees_id,
            setTransitStatus
          )
        } else {
          await createAllocation(
            currentTransitAmount,
            transitAllocation.benefitId,
            allocation.employees_id,
            setTransitStatus
          )
        }
      }
      if (
        allocationHasChanged(originalAllocation.parking, currentParkingAmount)
      ) {
        if (originalAllocation.parking) {
          await updateAllocation(
            parkingAllocation.id,
            currentParkingAmount,
            parkingAllocation.benefitId,
            allocation.employees_id,
            setParkingStatus
          )
        } else {
          await createAllocation(
            currentParkingAmount,
            parkingAllocation.benefitId,
            allocation.employees_id,
            setParkingStatus
          )
        }
      }
      setCurrentView('status')
    }

    return (
      <Modal open={open} onClose={() => closeModal()} width={pxToRem(450)}>
        {currentView === 'loading' ? (
          <Loading />
        ) : (
          <div>
            <ModalHeader
              title={
                currentView === 'status'
                  ? 'Edit Summary'
                  : 'Edit Upcoming Allocation'
              }
              onClose={() => closeModal()}
            />
            <ModalBody>
              <Text variant='body1'>Commuter</Text>
              <Text variant='h3'>{allocation.name}</Text>
              <Divider marginTop='1rem' marginBottom='1.5rem' />
              {currentView === 'edit' ? (
                <div>
                  {renderEmployeeAllocation(
                    'Transit',
                    currentTransitAmount,
                    handleTransitAllocationChange,
                    maxEmployeeAllocation.transit
                  )}
                  {renderEmployeeAllocation(
                    'Parking',
                    currentParkingAmount,
                    handleParkingAllocationChange,
                    maxEmployeeAllocation.parking
                  )}
                </div>
              ) : null}
              {currentView === 'status' ? (
                <div>
                  {renderStatusMessage(
                    transitStatus,
                    'Transit',
                    originalAllocation.transit,
                    currentTransitAmount
                  )}
                  {renderStatusMessage(
                    parkingStatus,
                    'Parking',
                    originalAllocation.parking,
                    currentParkingAmount
                  )}
                </div>
              ) : null}
            </ModalBody>
            <ModalFooter>
              {currentView === 'edit' ? (
                <FlexContainer
                  flexDirection='row'
                  justifyContent='space-between'
                >
                  <Button variant='tertiary' onClick={() => closeModal()}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleSubmit()}
                    disabled={
                      (!allocationHasChanged(
                        originalAllocation.transit,
                        currentTransitAmount
                      ) &&
                        !allocationHasChanged(
                          originalAllocation.parking,
                          currentParkingAmount
                        )) ||
                      currentTransitAmount > maxEmployeeAllocation.transit ||
                      currentParkingAmount > maxEmployeeAllocation.parking
                    }
                  >
                    Update
                  </Button>
                </FlexContainer>
              ) : (
                <Button onClick={() => closeModal()}>Done</Button>
              )}
            </ModalFooter>
          </div>
        )}
      </Modal>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EditEmployeeAllocationModal.displayName = 'EditEmployeeAllocationModal'
}

export default EditEmployeeAllocationModal
