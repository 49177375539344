import React, { useState } from 'react'
import styled from 'styled-components'
import GridCard from 'employee-platform/shared/components/GridCard'
import { BaseProgram } from 'redux/employeePlatform/employeePlatformTypes'
import GRHRequestModal from './GRHRequestModal'
import Button from 'core-system/Button'
import Text from 'core-system/Text'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { useDispatch } from 'react-redux'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import CarIcon from 'core-system/Icons/modeTypes/Car'
import palette from 'core-system/Themes/palette'
import Divider from 'core-system/Divider'
import pxToRem from 'core-system/utils/pxToRem'
import FormattingUtils from 'shared/FormattingUtils'
import moment from 'moment'
import { GRHCopy } from '../EmployeeGRHUtils'
import AlertIcon from 'core-system/Icons/Misc/Alert'

const RemainingUsesContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  ${(props) => (props.isMobile ? '' : 'padding: 0 2rem;')}
`

const CircularProgressContainer = styled.div<{ isMobile: boolean }>`
  height: ${(props) => props.theme.pxToRem(props.isMobile ? 100 : 125)};
  width: ${(props) => props.theme.pxToRem(props.isMobile ? 100 : 125)};
`

const GRHSummaryStatsContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ${(props) => (props.isMobile ? '' : 'padding-top: 1rem;')}
`

const GRHSummaryStat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.25rem;
`

const PerTransactionLimitContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const NoGRHProgramContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`

const getGRHRemainingUsesProgressStyles = () => {
  return buildStyles({
    strokeLinecap: 'round',
    pathColor: palette.primary.pink400,
    trailColor: palette.primary.pink500,
  })
}

interface GRHSummmaryCardProps {
  grhProgram: BaseProgram | null
  isMobile: boolean
}

const GRHSummmaryCard = React.memo((props: GRHSummmaryCardProps) => {
  const { grhProgram, isMobile } = props

  const dispatch = useDispatch()

  const { allProgramRequests } = useSelector(
    (state: AppState) => state.employeePlatform.programs
  )

  const [grhModalOpen, setGrhModalOpen] = useState(false)

  const openRequest =
    allProgramRequests && grhProgram && allProgramRequests[grhProgram.id]
      ? Object.values(allProgramRequests[grhProgram.id]).find(
          (request) => request.isOpen
        )
      : null

  if (!grhProgram) {
    return (
      <GridCard>
        <NoGRHProgramContainer>
          <AlertIcon
            height={pxToRem(75)}
            width={pxToRem(75)}
            color={palette.primary.pink300}
          />
          <Text variant='action4' textAlign='center'>
            {GRHCopy.noGRHProgram}
          </Text>
        </NoGRHProgramContainer>
      </GridCard>
    )
  }

  return (
    <GridCard>
      <RemainingUsesContainer isMobile={isMobile}>
        <CircularProgressContainer isMobile={isMobile}>
          <CircularProgressbarWithChildren
            styles={getGRHRemainingUsesProgressStyles()}
            strokeWidth={10}
            counterClockwise={true}
            value={Math.round(
              ((grhProgram.remainingUses || 0) / (grhProgram.numUses || 0)) *
                100
            )}
          >
            <CarIcon
              height={pxToRem(isMobile ? 50 : 70)}
              width={pxToRem(isMobile ? 50 : 70)}
              color={palette.primary.pink800}
            />
          </CircularProgressbarWithChildren>
        </CircularProgressContainer>
        <GRHSummaryStatsContainer isMobile={isMobile}>
          <GRHSummaryStat>
            <Text variant='h1'>
              {`${grhProgram.remainingUses || 0} / ${grhProgram.numUses || 0}`}
            </Text>
            <Text variant='action1' textColor={palette.text.secondary}>
              Remaining Uses
            </Text>
            <Text variant='action3' textColor={palette.text.placeholder}>
              {`Resets on ${moment(grhProgram.cycleEnd).format('MMM D, YYYY')}`}
            </Text>
          </GRHSummaryStat>
        </GRHSummaryStatsContainer>
      </RemainingUsesContainer>
      <Divider marginTop='0.5rem' marginBottom='1rem' />
      <PerTransactionLimitContainer>
        <Text variant={isMobile ? 'action1' : 'h3'}>
          Per Transaction Limit:
        </Text>
        <Text
          variant={isMobile ? 'h2' : 'h1'}
          textColor={palette.primary.pink400}
        >
          {FormattingUtils.formatDollar(grhProgram.budget)}
        </Text>
      </PerTransactionLimitContainer>
      <Divider marginTop='0.5rem' marginBottom='1rem' />
      <Button
        disabled={
          openRequest ||
          !grhProgram?.remainingUses ||
          grhProgram.remainingUses === 0
            ? true
            : false
        }
        onClick={() => setGrhModalOpen(true)}
        style={{ marginBottom: '1rem' }}
      >
        Request GRH
      </Button>
      <Button
        variant='secondary'
        disabled={!openRequest || !openRequest.canCancel}
        onClick={() =>
          dispatch(employeePlatformActions.cancelProgramRequest(openRequest.id))
        }
      >
        Cancel Request
      </Button>
      <GRHRequestModal
        open={grhModalOpen}
        closeModal={() => setGrhModalOpen(false)}
        programId={grhProgram?.id || null}
      />
    </GridCard>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GRHSummmaryCard.displayName = 'GRHSummmaryCard'
}

export default GRHSummmaryCard
