import React from 'react'
import styled from 'styled-components'
import Alert from '../Icons/Misc/Alert'
import Success from '../Icons/Misc/Success'
import Info from '../Icons/Misc/Info'
import { buildForwardingComponent } from '../utils/buildComponent'

const Container = styled.div`
  ${(props) => props.theme.typography.action4};
  width: 100%;
  max-width: ${(props) => props.theme.pxToRem(320)};
  min-height: ${(props) => props.theme.pxToRem(60)};
  padding: 1rem 1.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink600};
  color: ${(props) => props.theme.palette.white};
  display: flex;
  justify-content: space-between;
  border-radius: ${(props) => props.theme.pxToRem(15)};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  svg {
    color: ${(props) => props.theme.palette.white};
    background-color: ${(props) => props.theme.palette.primary.pink600};
    margin-left: -0.5rem;
  }
`

const CopyContainer = styled.div`
  padding-left: 1rem;
  width: 95%;
`

const Title = styled.div`
  padding-bottom: 0.25rem;
  text-transform: capitalize;
`

const Message = styled.div`
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.white};
  padding-top: 0.25rem;
`

const getIcon = (type: string) => {
  if (type === 'alert') {
    return <Alert />
  }
  if (type === 'info') {
    return <Info />
  }
  return <Success />
}

const Notification = React.forwardRef<HTMLDivElement, any>(
  (props: any, ref: React.Ref<HTMLDivElement>) => {
    return (
      <Container ref={ref} {...props}>
        {getIcon(props.message.type)}
        <CopyContainer>
          <Title>{props.message.title}</Title>
          <Message>{props.message.subtitle}</Message>
        </CopyContainer>
      </Container>
    )
  }
)

export default buildForwardingComponent(Notification)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  Notification.displayName = 'Notification'
}
