import React from 'react'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import palette from 'core-system/Themes/palette'
import { TabsProps } from '@mui/material'

const TabsContainer = styled(Tabs)(() => ({
  borderBottom: `1px solid ${palette.grey.grey300}`,
  '& .MuiTabs-indicator': {
    backgroundColor: palette.secondary.purple600,
    height: '3px',
  },
}))

const TabItem = styled(Tab)(() => ({
  fontFamily: 'PolySans',
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 600,
  color: palette.text.primary,
  '&.Mui-selected': {
    color: palette.text.primary,
  },
  '&:hover': {
    color: palette.secondary.purple700,
  },
  '&.MuiTab-root': {
    marginRight: '1.25rem',
    padding: '1.25rem 0',
  },
}))

interface TabsPropsPrimary extends TabsProps {
  tabs: {
    label: string
    value: number
  }[]
}

const TabsPrimary = React.memo((props: TabsPropsPrimary) => {
  const { tabs, ...rest } = props

  return (
    <TabsContainer {...rest}>
      {tabs.map((tab) => (
        <TabItem key={tab.value} label={tab.label} value={tab.value} />
      ))}
    </TabsContainer>
  )
})

export default TabsPrimary
