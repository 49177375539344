import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import pxToRem from 'core-system/utils/pxToRem'
import Text from 'core-system/Text'
import { extraOnboardingCopy } from './OnboardingUtils'
import palette from 'core-system/Themes/palette'
import { employerActions } from 'redux/employer/employerSlice'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Step = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
`

const StepText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(40)};
  padding-bottom: ${pxToRem(40)};
  .numbered-bullet {
    color: ${(props) => props.theme.palette.white};
  }
`

const NumberedBullet = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.primary.pink500};
  margin-right: 0.5rem;
  // padding-top: ${pxToRem(2)};
`

const successStep = (stepNumber: number, stepText: string) => {
  return (
    <Step>
      <strong>
        <NumberedBullet className='numbered-bullet'>
          {stepNumber}
        </NumberedBullet>
      </strong>
      <StepText>
        {/* <Text variant='h4'>{stepHeader}</Text> */}
        <Text variant='action2' color={palette.black}>
          {stepText}
        </Text>
      </StepText>
    </Step>
  )
}

const OnboardingSuccess = React.memo(() => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(employerActions.updateOnboardingStep('finchSync'))
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <Steps>
        {successStep(1, extraOnboardingCopy.success.stripeEmail.text)}
        {successStep(2, extraOnboardingCopy.success.syncEmployees.text)}
        {successStep(3, extraOnboardingCopy.success.emailStep.text)}
      </Steps>
      <Text variant='action2' color={palette.black}>
        Sit back and relax - we&apos;re taking care of the details and will be
        in touch soon!
      </Text>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OnboardingSuccess.displayName = 'OnboardingSuccess'
}

export default OnboardingSuccess
