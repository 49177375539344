import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import SidebarMenu from './SidebarMenu'
import Collapse from 'core-system/Icons/Actions/Collapse'
import theme from 'core-system/Themes/Main'

const SidebarContainer = styled.div<{ $isCollapsed: boolean }>`
  height: 100%;
  width: ${(props) =>
    props.$isCollapsed ? props.theme.pxToRem(100) : props.theme.pxToRem(280)};
  transition: width 0.3s ease;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    display: none;
  }
`

//https://medium.com/@justicart/overflow-x-scroll-overflow-y-visible-c1a98238e002
//solves overflox-x: visible, overflow-y: scroll
const LabelContainer = styled.div<{ $isCollapsed: boolean }>`
  position: absolute;
  width: ${(props) =>
    props.$isCollapsed ? props.theme.pxToRem(100) : props.theme.pxToRem(280)};
  transition: width 0.3s ease;
  height: 100%;
  left: 0;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const ChevronContainer = styled.div<{ $isCollapsed: boolean }>`
  position: absolute;
  right: ${(props) => (props.$isCollapsed ? '-12px' : '20px')};
  top: ${(props) => (props.$isCollapsed ? '24px' : '32px')};
  cursor: pointer;
  transform: ${(props) => (props.$isCollapsed ? 'rotate(180deg)' : 'none')};
  transition: transform 0.3s ease;
  z-index: 9999 !important;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    display: none;
  }
`

interface SidebarViewProps {
  platformType: 'employee' | 'employer'
}

const SidebarView = React.memo((props: SidebarViewProps) => {
  const { platformType } = props
  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleCollapse = () => {
    setIsCollapsed((prev) => !prev)
  }

  useEffect(() => {
    const handleResize = () => {
      const breakPoint = parseInt(theme.breakpoints[3]) * 16
      if (window.innerWidth < breakPoint) {
        setIsCollapsed(true)
      } else {
        setIsCollapsed(false)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <SidebarContainer id='page-sidebar' $isCollapsed={isCollapsed}>
      <LabelContainer $isCollapsed={isCollapsed}>
        <SidebarMenu platformType={platformType} isCollapsed={isCollapsed} />
      </LabelContainer>
      <ChevronContainer onClick={handleCollapse} $isCollapsed={isCollapsed}>
        <Collapse height={32} width={32} />
      </ChevronContainer>
    </SidebarContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  SidebarView.displayName = 'SidebarView'
}

export default SidebarView
