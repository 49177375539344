import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'
import { buildForwardingComponent } from '../utils/buildComponent'
import pxToRem from '../utils/pxToRem'
import ChevronIcon from '../Icons/Actions/Chevron'

const Container = styled.div<{
  isChild: boolean
  isAnimate: boolean
  isActive: boolean
  allowHoverEffect: boolean
}>`
  display: flex;
  align-items: center;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  max-height: ${(props) =>
    props.isAnimate || !props.isChild ? pxToRem(1000) : '0'};
  opacity: ${(props) => (!props.isAnimate && props.isChild ? 0 : 1)};
  overflow: hidden;
  color: ${(props) => props.theme.palette.text.primary};
  cursor: ${(props) => (props.isActive ? 'pointer' : 'default')};
  background-color: ${(props) => props.theme.palette.white};
  border-top: ${(props) =>
    props.isChild && props.isAnimate
      ? `1px solid ${props.theme.palette.grey.grey300}`
      : '0 solid transparent'};

  ${flexbox}
  ${space} 
  ${layout}

  &:hover {
    background-color: ${(props) =>
      props.allowHoverEffect && props.theme.palette.primary.pink600};
  }
`

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
`

const StyledChevronIcon = styled(ChevronIcon)<{
  open: boolean
  $active: boolean
}>`
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0)')};
  transition: all 0.2s ease-in-out;
  border-radius: 0.4375rem;
  color: ${(props) =>
    props.$active ? 'inherit' : props.theme.palette.text.disabled};
  width: ${(props) => props.theme.pxToRem(32)};
  height: ${(props) => props.theme.pxToRem(32)};
  padding: 0.25rem;

  &:hover {
    color: ${(props) =>
      props.$active ? 'inherit' : props.theme.palette.text.disabled};
  }
`

const handleClick = (
  e: SyntheticEvent,
  setIsActive: (isActive: boolean) => void,
  isActive: boolean
) => {
  e.stopPropagation()
  setIsActive(!isActive)
}

export interface AccordionCardRowProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  isChild?: boolean
  isActive?: boolean
  isAnimate?: boolean
  hasDropdown?: boolean
  allowHoverEffect?: boolean
  setIsActive?: (isActive: boolean) => void
}

const AccordionCardRow = React.forwardRef<
  HTMLDivElement,
  AccordionCardRowProps
>((props: AccordionCardRowProps, ref: React.Ref<HTMLDivElement>) => {
  const {
    children,
    isChild = false,
    isActive,
    isAnimate = true,
    setIsActive = null,
    hasDropdown = false,
    allowHoverEffect = true,
  } = props

  return (
    <Container
      ref={ref}
      {...props}
      isChild={isChild}
      isAnimate={isAnimate}
      isActive={setIsActive && true}
      allowHoverEffect={allowHoverEffect}
      onClick={(e) => setIsActive && handleClick(e, setIsActive, isActive)}
    >
      {hasDropdown && (
        <DropdownContainer>
          <StyledChevronIcon
            $active={setIsActive && true}
            open={isActive}
            height={24}
            width={24}
          />
        </DropdownContainer>
      )}
      {children}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  AccordionCardRow.displayName = 'AccordionCardRow'
}

export default buildForwardingComponent(AccordionCardRow)
