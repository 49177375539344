import * as React from 'react'
import createSvgIcon from '../createSvgIcon'

export default createSvgIcon(
  <>
    <path
      id='Vector'
      d='M2.25 16.5H21.75'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      id='Vector_2'
      d='M2.25 19.5H21.75'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      id='Vector_3'
      d='M12 6V3'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      id='Vector_4'
      d='M9.75 3H14.25'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      id='Vector_5'
      d='M3.75 16.5V14.25C3.75 12.062 4.61919 9.96354 6.16637 8.41637C7.71354 6.86919 9.81196 6 12 6C14.188 6 16.2865 6.86919 17.8336 8.41637C19.3808 9.96354 20.25 12.062 20.25 14.25V16.5'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </>,
  'Chatbox'
)
