import Chip from 'core-system/Chip'
import { statusMap } from 'core-system/Chip/Chip'
import MonthToggle from 'core-system/DatePicker/MonthToggle'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import {
  Flex as FlexIcon,
  Incentives,
  Micromobility as MicromobilityIcon,
} from 'core-system/Icons/Sidebar'
import { Car as CarIcon } from 'core-system/Icons/modeTypes'
import { OneTimePurchase as OTPIcon } from 'core-system/Icons/Micromobility'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import LeaderboardUtils from 'features/Leaderboards/LeaderboardUtils'
import ProgramItem from 'features/Segments/components/ProgramItem'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import {
  CompetitionData,
  IncentiveData,
  MyCommuterDetails,
  ProgramData,
} from 'redux/employee/employeeTypes'
import { Segment } from 'redux/employer/employerTypes'
import { OneTimeReward } from 'redux/incentives/incentivesTypes'
import { LeaderboardsGroup } from 'redux/leaderboards/leaderboardsTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'
import MyCommuterDetailsOverviewSection from './components/MyCommuterDetailsOverviewSection'

const StyledFlexContainer = styled(FlexContainer)`
  overflow: auto;
  height: ${(props) => props.theme.pxToRem(430)};
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const MetricRow = styled(FlexContainer)`
  justify-content: space-between;
  margin-top: 1rem;
`

const GroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  pointer-events: none;
`

const ItemContainer = styled.div`
  margin-top: -0.125rem;
`

const PurpleContainer = styled(FlexContainer)`
  border-radius: 0.3125rem;
  background-color: ${(props) => props.theme.palette.primary.pink400};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  height: 2rem;
  width: 2rem;
`

const formatPosition = (position: number) => {
  if (position === 1) {
    return '1st'
  } else if (position === 2) {
    return '2nd'
  } else {
    return '3rd'
  }
}

const getStatus = (status: string, startDate: string, currentDate: string) => {
  const startDateMoment = moment(startDate, 'YYYY-MM-DD').format('YYYY-MM')
  const currentDateMoment = moment(currentDate)

  const monthDiff = currentDateMoment.diff(startDateMoment, 'month')

  if (monthDiff >= 0) {
    return status
  } else if (monthDiff === -1) {
    return 'PENDING'
  } else {
    return 'DISABLED'
  }
}

const getProgramIconAndName = (programType: string) => {
  if (programType === 'MICROMOBILITY') {
    return {
      programIcon: <MicromobilityIcon />,
      programName: 'Micromobility',
    }
  } else if (programType === 'FLEX') {
    return {
      programIcon: <FlexIcon />,
      programName: 'Flex',
    }
  } else if (programType === 'GRH') {
    return {
      programIcon: <CarIcon />,
      programName: 'Guaranteed Ride Home',
    }
  } else if (programType === 'OTP') {
    return {
      programIcon: <OTPIcon />,
      programName: 'One Time Purchase',
    }
  } else {
    return {
      programIcon: <FlexIcon />,
      programName: 'Other',
    }
  }
}

const renderProgramDetails = (
  programDetails: ProgramData[],
  segmentsMap: Dictionary<Segment>,
  currentDate: string,
  products: Set<string>
) => {
  return programDetails
    .filter((program) => products.has(program.type))
    .map((details, idx) => {
      const totalSpend = details.totalSpend || 0
      const status = getStatus(details.status, details.startDate, currentDate)
      const { programIcon, programName } = getProgramIconAndName(details.type)

      return (
        <FlexContainer
          key={idx}
          marginBottom='0.5rem'
          alignItems='center'
          justifyContent='space-between'
        >
          <FlexContainer>
            <ProgramItem
              itemClass='program'
              itemType={details.type}
              itemIcon={programIcon}
              itemStatus={details.status}
              showRewardBadge={false}
              segmentName={segmentsMap[details.segmentId].name}
            />
            <FlexContainer alignItems='center' marginLeft='0.5rem'>
              <Text variant='action4'>{programName}</Text>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer alignItems='center'>
            {status === 'ACTIVE' ? (
              <Text variant='action4'>
                {FormattingUtils.formatDollar(totalSpend)}
              </Text>
            ) : (
              <Chip variant={statusMap[status].variant} marginLeft='2rem'>
                {statusMap[status].text}
              </Chip>
            )}
          </FlexContainer>
        </FlexContainer>
      )
    })
}

const renderIncentiveDetails = (
  incentiveDetails: IncentiveData[],
  segmentsMap: Dictionary<Segment>,
  currentDate: string
) => {
  return incentiveDetails.map((details, idx) => {
    const status = getStatus(details.status, details.startDate, currentDate)
    const activeSegment = segmentsMap[details.segmentId]

    return (
      <FlexContainer
        key={idx}
        marginBottom='0.5rem'
        alignItems='center'
        justifyContent='space-between'
      >
        <FlexContainer>
          <PurpleContainer center>
            <Incentives color={palette.text.secondary} />
          </PurpleContainer>
          <FlexContainer alignItems='center' marginLeft='0.5rem'>
            <Text variant='action4'>{activeSegment.name}</Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer alignItems='center'>
          {status === 'ACTIVE' ? (
            <Text variant='action4'>
              {FormattingUtils.formatDollar(details.totalRewarded || 0)}
            </Text>
          ) : (
            <Chip variant={statusMap[status].variant} marginLeft='2rem'>
              {statusMap[status].text}
            </Chip>
          )}
        </FlexContainer>
      </FlexContainer>
    )
  })
}

const getCompetitionDetailsText = (
  winningPosition: number,
  isCurrentMonth: boolean,
  status: string
) => {
  if (['PENDING', 'DISABLED'].includes(status)) {
    return '-'
  } else if (isCurrentMonth) {
    return 'Competing'
  } else if (winningPosition) {
    return formatPosition(winningPosition)
  } else {
    return "Didn't win"
  }
}

const getCompetitionRewards = (
  winningPosition: number,
  winningReward: number,
  isCurrentMonth: boolean,
  status: string
) => {
  if (
    !['PENDING', 'DISABLED'].includes(status) &&
    !isCurrentMonth &&
    winningPosition
  ) {
    return FormattingUtils.formatDollar(winningReward || 0)
  } else {
    return FormattingUtils.formatDollar(0)
  }
}

const renderCompetitionDetails = (
  competitionDetails: CompetitionData[],
  segmentsMap: Dictionary<Segment>,
  currentDate: string,
  leaderboardsMap: Dictionary<LeaderboardsGroup>
) => {
  return competitionDetails.map((details, idx) => {
    const status = getStatus(details.status, details.startDate, currentDate)
    const competitionData = LeaderboardUtils.leaderboardData[details.queryType]
    const activeLeaderboard = leaderboardsMap[details.segmentId]?.programs.find(
      (competition) => competition.type === details.queryType
    )
    const hasRewards = activeLeaderboard?.bonuses.some((bonus) => bonus > 0)
    const isCurrentMonth = moment(currentDate).isSame(new Date(), 'month')

    return (
      <FlexContainer
        key={idx}
        alignItems='center'
        justifyContent='space-between'
      >
        <FlexContainer alignItems='center'>
          <ItemContainer>
            <ProgramItem
              itemClass='competition'
              itemType={details.queryType}
              itemIcon={competitionData.icon}
              itemStatus={details.status}
              showRewardBadge={hasRewards}
              segmentName={segmentsMap[details.segmentId].name}
            />
          </ItemContainer>
          <FlexContainer flexDirection='column' marginLeft='0.5rem'>
            <Text variant='action4'>{competitionData.smallTitle}</Text>
            <Text variant='body2' textColor={palette.text.placeholder}>
              {getCompetitionDetailsText(
                details.winningPosition,
                isCurrentMonth,
                status
              )}
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer alignItems='center'>
          {status === 'ACTIVE' ? (
            <Text variant='action4'>
              {getCompetitionRewards(
                details.winningPosition,
                details.winningReward,
                isCurrentMonth,
                status
              )}
            </Text>
          ) : (
            <Chip variant={statusMap[status].variant} marginLeft='2rem'>
              {statusMap[status].text}
            </Chip>
          )}
        </FlexContainer>
      </FlexContainer>
    )
  })
}

const getCurrMonthOtrs = (
  allOneTimeRewards: OneTimeReward[],
  currentDate: string,
  employeeId: string
) => {
  return allOneTimeRewards
    ?.filter(
      (otr: OneTimeReward) =>
        otr.employeeId === employeeId &&
        moment(otr.createdAt).format('YYYY-MM') === currentDate
    )
    .reduce((agg, otr) => (agg += otr.amount), 0)
}

interface MyCommuterDetailsOverviewProps {
  currentDate: string
  onIncreaseClick: () => void
  onDecreaseClick: () => void
  commuterDetails: MyCommuterDetails
}

const MyCommuterDetailsOverview = React.memo(
  (props: MyCommuterDetailsOverviewProps) => {
    const { currentDate, commuterDetails, onIncreaseClick, onDecreaseClick } =
      props

    const { segmentsMap, profile } = useSelector(
      (state: AppState) => state.employer
    )
    const { leaderboardsMap } = useSelector(
      (state: AppState) => state.leaderboards
    )
    const { allOneTimeRewards } = useSelector(
      (state: AppState) => state.incentives
    )

    const products = new Set(profile.products)

    const hasPrograms =
      products.has('GRH') ||
      products.has('MICROMOBILITY') ||
      products.has('FLEX') ||
      products.has('OTP')

    const currMonthOneTimeRewards = useMemo(() => {
      return getCurrMonthOtrs(
        allOneTimeRewards,
        currentDate,
        commuterDetails.id
      )
    }, [allOneTimeRewards, currentDate, commuterDetails])

    //filter out all employees and worksite from the worksite segments
    const filteredSegmentNames = commuterDetails.segmentsEnrolled.flatMap(
      (id) => {
        const matchingSegment = segmentsMap[id]

        if (!matchingSegment) {
          return []
        } else if (
          matchingSegment.name === 'All Employees' ||
          matchingSegment.archived
        ) {
          return []
        } else {
          return matchingSegment.name.includes('Worksite')
            ? [
                matchingSegment.name.split(',')[0].replace('Worksite ', '') ||
                  matchingSegment.name,
              ]
            : [matchingSegment.name]
        }
      }
    )

    const budgetSpend = commuterDetails.programData.reduce(
      (agg, program) => (agg += program.totalSpend ? program.totalSpend : 0),
      0
    )

    return (
      <FlexContainer>
        <FlexContainer width='35%' flexDirection='column'>
          <MonthToggle
            currentDate={currentDate}
            onIncreaseClick={onIncreaseClick}
            onDecreaseClick={onDecreaseClick}
          />
          <MetricRow marginTop='1.5rem'>
            <Text variant='action4'>Trips Logged</Text>
            <Text variant='action4'>
              {FormattingUtils.formatNumber(commuterDetails.tripsThisMonth)}
            </Text>
          </MetricRow>
          <MetricRow>
            <Text variant='action4'>Budget Spend</Text>
            <Text variant='action4'>
              {FormattingUtils.formatDollar(budgetSpend || 0)}
            </Text>
          </MetricRow>
          <MetricRow>
            <Text variant='action4'>Rewards Spent</Text>
            <Text variant='action4'>
              {FormattingUtils.formatDollar(
                commuterDetails.rewardsUtilization || 0
              )}
            </Text>
          </MetricRow>
          <Divider margin='1.5rem 0' />
          <Text variant='action2' marginBottom='0.5rem'>
            Groups
          </Text>
          <GroupContainer>
            {filteredSegmentNames.map((name) => (
              <Chip variant='filter' key={name} margin='0.5rem 0.5rem 0 0'>
                {name}
              </Chip>
            ))}
          </GroupContainer>
        </FlexContainer>
        <Divider
          direction='vertical'
          size={pxToRem(428)}
          margin='0.5rem 1.5rem 0.5rem 2rem'
        />
        <StyledFlexContainer width='65%' flexDirection='column'>
          {hasPrograms ? (
            <MyCommuterDetailsOverviewSection
              title='Programs'
              spendHeading='Spend'
              items={renderProgramDetails(
                commuterDetails.programData,
                segmentsMap,
                currentDate,
                products
              )}
            />
          ) : null}
          {products.has('INCENTIVES') || products.has('OTR') ? (
            <MyCommuterDetailsOverviewSection
              title='Incentives'
              spendHeading='Rewards Earned'
              items={
                products.has('INCENTIVES')
                  ? renderIncentiveDetails(
                      commuterDetails.incentiveData,
                      segmentsMap,
                      currentDate
                    )
                  : null
              }
              additionalItems={
                products.has('OTR') ? (
                  <FlexContainer
                    marginTop='0.5rem'
                    marginBottom='0.5rem'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <FlexContainer>
                      <PurpleContainer center>
                        <Incentives color={palette.text.secondary} />
                      </PurpleContainer>
                      <FlexContainer alignItems='center' marginLeft='0.5rem'>
                        <Text variant='action4'>One Time Rewards</Text>
                      </FlexContainer>
                    </FlexContainer>
                    <FlexContainer alignItems='center'>
                      <Text variant='action4'>
                        {FormattingUtils.formatDollar(currMonthOneTimeRewards)}
                      </Text>
                    </FlexContainer>
                  </FlexContainer>
                ) : null
              }
            />
          ) : null}
          {products.has('COMPETITIONS') ? (
            <MyCommuterDetailsOverviewSection
              title='Competitions'
              spendHeading='Rewards Earned'
              items={renderCompetitionDetails(
                commuterDetails.competitionData,
                segmentsMap,
                currentDate,
                leaderboardsMap
              )}
            />
          ) : null}
        </StyledFlexContainer>
      </FlexContainer>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MyCommuterDetailsOverview.displayName = 'MyCommuterDetailsOverview'
}

export default MyCommuterDetailsOverview
