import Checkbox from 'core-system/Checkbox'
import PartnerIcon from 'core-system/Icons/Misc/Partner'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import TransitLogo from 'core-system/TransitLogo'
import React from 'react'
import { Merchant, ServiceModeType } from 'redux/programs/programsTypes'
import styled from 'styled-components'

const Container = styled.div<{
  active?: boolean
  canEdit?: boolean
  height: number
}>`
  padding: 1rem;
  border-radius: 0.9375rem;
  border: 1px solid
    ${(props) =>
      props.active
        ? props.theme.palette.primary.pink200
        : props.theme.palette.grey.grey300};
  position: relative;
  height: ${(props) => props.theme.pxToRem(props.height)};
  cursor: ${(props) => (props.canEdit ? 'pointer' : 'default')};
  user-select: none;
  position: relative;

  &:hover {
    .checkBoxContainer {
      border-color: ${(props) => props.theme.palette.primary.pink300};
      & > svg {
        display: block;
      }
    }
  }
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const PartnerContainer = styled.div`
  position: absolute;
  top: ${(props) => props.theme.pxToRem(-1)};
  left: 64%;
  svg {
    fill: none;
  }
`

const DisabledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.9375rem;
  background-color: ${(props) => props.theme.palette.grey.grey300};
  opacity: 0.4;
`

const StyledLink = styled.a`
  cursor: pointer;
  font-weight: normal;
  line-height: normal;
  color: ${(props) => props.theme.palette.text.primary};
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.palette.primary.pink700};
  }
`

interface ServiceProviderCardProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  merchant: Merchant
  active: boolean
  handleActiveToggle: (provider: string, isActive: boolean) => void
  canEdit?: boolean
  height: number
  selectedMerchant: Merchant
  setSelectedMerchant: (merchant: Merchant) => void
}

const ServiceProviderCard = React.memo((props: ServiceProviderCardProps) => {
  const {
    merchant,
    active,
    handleActiveToggle,
    canEdit = true,
    children,
    height = 165,
    selectedMerchant,
    setSelectedMerchant,
  } = props

  const modeTypes = merchant.modeTypes
    .map((modeType) =>
      ProgramUtils.formatElectricModeTypes(modeType).toLowerCase()
    )
    .join(', ')

  return (
    <Container
      active={active}
      canEdit={canEdit}
      height={height}
      onClick={() => canEdit && handleActiveToggle(merchant.id, !active)}
    >
      {!canEdit && !active && <DisabledContainer />}
      <FlexContainer>
        <TransitLogo
          modeType={
            merchant.iconUrl
              ? 'custom'
              : (merchant.modeTypes[0] as ServiceModeType)
          }
          src={merchant.iconUrl || merchant.modeTypes[0]}
          size='large'
        />
        {canEdit && <Checkbox checked={active} />}
      </FlexContainer>
      <Text variant='h5' marginBottom='0.1875rem' capitalize hideOverflow>
        {merchant.name.toLowerCase()}
      </Text>
      <Text
        variant='action4'
        textColor={palette.text.secondary}
        capitalize
        hideOverflow
      >
        {modeTypes}
      </Text>
      <Text variant='body1'>
        <StyledLink
          onClick={(e) => {
            e.stopPropagation()
            if (merchant.id === selectedMerchant?.id) {
              setSelectedMerchant(null)
              return
            }
            setSelectedMerchant(merchant)
          }}
        >
          {merchant.id === selectedMerchant?.id ? 'Hide Info' : 'Learn More'}
        </StyledLink>
      </Text>
      {merchant.fleetPartner && (
        <PartnerContainer>
          <PartnerIcon />
        </PartnerContainer>
      )}
      {children && children}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ServiceProviderCard.displayName = 'ServiceProviderCard'
}

export default ServiceProviderCard
