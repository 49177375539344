import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import Loading from 'core-system/Loading'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import Divider from 'core-system/Divider'
import GridCard from 'employee-platform/shared/components/GridCard'
import AccountCircleIcon from 'core-system/Icons/Accounts/AccountCircle'
import { useNavigate } from 'react-router-dom'
import { Locations } from 'shared/Router/Locations'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const HeaderContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  gap: ${(props) => (props.isMobile ? '0.5rem' : '1rem')};
`

const HeaderTextContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isMobile ? 'center' : 'flex-start')};
  gap: 0.25rem;
`

const HeaderBottomTextContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  gap: ${(props) => (props.isMobile ? '0.25rem' : '1rem')};
`

const InfoFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const infoField = (label: string, value: string) => {
  return (
    <InfoFieldContainer>
      <Text variant='action3' textColor={palette.text.secondary}>
        {label}
      </Text>
      <Text variant='action2'>{value}</Text>
    </InfoFieldContainer>
  )
}

interface ProfileDashboardCardProps {
  isMobile: boolean
}

const ProfileDashboardCard = React.memo((props: ProfileDashboardCardProps) => {
  const { isMobile } = props

  const navigate = useNavigate()

  const { profileData } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  if (!profileData) {
    return <Loading />
  }

  return (
    <GridCard
      headerTitle='My Info'
      headerIcon={<AccountCircleIcon color={palette.primary.pink800} />}
      clickable={true}
      onClick={() => navigate(Locations.EmployeeSettings)}
      tooltipText='Go to Profile Overview'
    >
      {!profileData ? (
        <Loading />
      ) : (
        <Container>
          <HeaderContainer isMobile={isMobile}>
            {profileData.avatar ? (
              <img
                alt={`${profileData.firstName} ${profileData.lastName}`}
                src={profileData.avatar}
                style={{
                  borderRadius: '50%',
                }}
                height={50}
              />
            ) : (
              <PlatformSvg
                folder='users'
                variant='userBasic'
                width={50}
                height={50}
              />
            )}
            <HeaderTextContainer isMobile={isMobile}>
              <Text
                variant='h3'
                hideOverflow
              >{`${profileData.firstName} ${profileData.lastName}`}</Text>
              <HeaderBottomTextContainer isMobile={isMobile}>
                {profileData.employeeCorpId ? (
                  <>
                    <Text variant='action2' textColor={palette.text.secondary}>
                      {`ID #: ${profileData.employeeCorpId}`}
                    </Text>
                    {!isMobile ? (
                      <Divider direction='vertical' size='1.25rem' />
                    ) : null}
                  </>
                ) : null}
                <Text
                  variant='action2'
                  textColor={palette.primary.pink700}
                  hideOverflow
                >
                  {profileData.email}
                </Text>
              </HeaderBottomTextContainer>
            </HeaderTextContainer>
          </HeaderContainer>
          <Divider />
          {infoField('Home Address', profileData.homeAddress)}
          {infoField('Work Address', profileData.worksiteAddress)}
        </Container>
      )}
    </GridCard>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProfileDashboardCard.displayName = 'ProfileDashboardCard'
}

export default ProfileDashboardCard
