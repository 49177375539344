import { InteractionMode } from 'chart.js'
import { Expression } from 'mapbox-gl'
import palette from 'core-system/Themes/palette'
import { LayerProps } from 'react-map-gl/mapbox'

export const colorArray = [
  '#C30078',
  '#4EC7C4',
  palette.primary.pink400,
  palette.primary.pink800,
  '#5FCD6A',
  '#3A78FF',
  '#C19CFF',
  '#FF7076',
  '#7ADBF8',
]

const pointOpacity = 0.8
const pointStrokeWidth = 1
const pointStrokeOpacity = 0.8
const pointStrokeColor = palette.white

export const defaultViewport = {
  latitude: 37.7577,
  longitude: -122.4376,
  zoom: 10,
  width: 100,
  height: 100,
  maxZoom: 20,
  minZoom: 2.5,
  maxPitch: 60,
  minPitch: 0,
}

const pointSize = {
  largeNormal: 7,
  largeHidden: 6,
  smallNormal: 4.5,
  smallHidden: 3.5,
}

const shapeOutlineSize = [
  'interpolate',
  ['linear'],
  ['zoom'],
  3,
  0.5,
  10,
  1.5,
  15,
  3,
] as Expression

// -------------- COVID-LAYER --------------

export const covidColors = [
  '#C6C6C6',
  '#FFF285',
  '#FFD79E',
  '#FFAD85',
  '#FF6C6C',
  '#B54040',
]

export const generateCovidStyle = (covidBuckets: string[]): LayerProps => {
  const covidBucketsExpression: any[] = covidBuckets.flatMap(
    (bucket: string, idx: number) => {
      const bucketLimit = parseInt(bucket.split('-')[1]) + 1
      return [covidColors[idx], bucketLimit]
    }
  )

  covidBucketsExpression.unshift('step', [
    'to-number',
    ['get', 'covid_last_7_days'],
  ])
  covidBucketsExpression.push(palette.primary.pink800)

  return {
    id: 'covidData',
    type: 'fill',
    beforeId: 'settlement-minor-label',
    paint: {
      'fill-color': covidBucketsExpression as Expression,
      'fill-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        3,
        0.6,
        10,
        0.4,
        15,
        0.25,
      ] as Expression,
    },
  }
}

export const employeeStyle: LayerProps = {
  id: 'employees',
  type: 'circle',
  beforeId: 'settlement-major-label',
  paint: {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10,
      pointSize.smallNormal,
      15,
      pointSize.largeNormal,
    ] as Expression,
    'circle-color': palette.primary.pink400,
    'circle-stroke-width': pointStrokeWidth,
    'circle-stroke-color': pointStrokeColor,
    'circle-opacity': pointOpacity,
    'circle-stroke-opacity': pointStrokeOpacity,
  },
}

export const micromobilityStyle: LayerProps = {
  id: 'micromobility',
  type: 'circle',
  beforeId: 'settlement-major-label',
  paint: {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10,
      [
        'case',
        [
          'all',
          ['==', ['get', 'withinWorksite'], false],
          ['==', ['get', 'withinTransit'], false],
        ],
        pointSize.smallHidden,
        pointSize.smallNormal,
      ],
      15,
      [
        'case',
        [
          'all',
          ['==', ['get', 'withinWorksite'], false],
          ['==', ['get', 'withinTransit'], false],
        ],
        pointSize.largeHidden,
        pointSize.largeNormal,
      ],
    ] as Expression,
    'circle-color': [
      'case',
      ['==', ['get', 'withinWorksite'], true],
      palette.primary.pink400,
      ['==', ['get', 'withinTransit'], true],
      '#C30078',
      palette.primary.pink500,
    ] as Expression,
    'circle-stroke-width': pointStrokeWidth,
    'circle-stroke-color': pointStrokeColor,
    'circle-opacity': pointOpacity,
    'circle-stroke-opacity': pointStrokeOpacity,
  },
}

export const worksiteGeojsonStyle: LayerProps = {
  id: 'worksiteGeojsonStyle',
  type: 'fill',
  beforeId: 'settlement-major-label',
  paint: {
    'fill-color': palette.primary.pink400,
    'fill-opacity': 0.25,
  },
}

// to test worksite SVG locations
// export const worksiteStyle = {
//   id: 'worksites',
//   type: 'circle',
//   beforeId: 'settlement-major-label',
//   paint: {
//     'circle-radius': [
//       'interpolate',
//       ['linear'],
//       ['zoom'],
//       10,
//       pointSize.smallNormal,
//       15,
//       pointSize.largeNormal,
//     ] as Expression,
//     'circle-color': '#5a5a5d',
//     'circle-stroke-width': pointStrokeWidth,
//     'circle-stroke-color': '#fff',
//     'circle-opacity': pointOpacity,
//     'circle-stroke-opacity': pointStrokeOpacity,
//   },
// }

// -------------- AGONY-LAYER --------------

export const employeeAgonyStyle: LayerProps = {
  id: 'employees',
  type: 'circle',
  beforeId: 'settlement-major-label',
  paint: {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10,
      [
        'case',
        ['==', ['get', 'commuterType'], null],
        pointSize.smallHidden,
        pointSize.smallNormal,
      ],
      15,
      [
        'case',
        ['==', ['get', 'commuterType'], null],
        pointSize.largeHidden,
        pointSize.largeNormal,
      ],
    ] as Expression,
    'circle-color': [
      'case',
      ['==', ['get', 'commuterType'], 'mega_commuter'],
      '#C30078',
      ['==', ['get', 'commuterType'], 'super_commuter'],
      '#4EC7C4',
      ['==', ['get', 'commuterType'], 'long_distance_commuter'],
      palette.primary.pink400,
      palette.primary.pink500,
    ] as Expression,
    'circle-stroke-width': pointStrokeWidth,
    'circle-stroke-color': pointStrokeColor,
    'circle-opacity': [
      'case',
      ['==', ['get', 'commuterType'], null],
      0.7,
      0.85,
    ] as Expression,
    'circle-stroke-opacity': pointStrokeOpacity,
  },
}

export const agonyStyle: LayerProps = {
  id: 'agony',
  type: 'fill',
  beforeId: 'settlement-minor-label',
  paint: {
    'fill-color': [
      'case',
      ['==', ['get', 'commuterType'], 'mega_commuter'],
      '#C30078',
      ['==', ['get', 'commuterType'], 'super_commuter'],
      '#28AFAB',
      ['==', ['get', 'commuterType'], 'long_distance_commuter'],
      palette.primary.pink400,
      palette.primary.pink800,
    ],
    'fill-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      3,
      0.4,
      10,
      0.2,
      15,
      0.15,
    ],
  },
}

export const agonyOutlineStyle: LayerProps = {
  id: 'agonyStyleOutline',
  type: 'line',
  beforeId: 'settlement-minor-label',
  paint: {
    'line-color': [
      'case',
      ['==', ['get', 'commuterType'], 'mega_commuter'],
      '#C30078',
      ['==', ['get', 'commuterType'], 'super_commuter'],
      '#28AFAB',
      ['==', ['get', 'commuterType'], 'long_distance_commuter'],
      palette.primary.pink400,
      palette.primary.pink800,
    ] as Expression,
    'line-opacity': 0.8,
    'line-width': shapeOutlineSize,
  },
}

// -------------- CARPOOL-LAYER --------------

// export const generateCarpoolStyle = (
//   type: 'polygon' | 'line' | 'point'
// ): LayerProps => {
//   if (type === 'polygon') {
//     return {
//       id: 'carpool',
//       type: 'fill',
//       beforeId: 'settlement-minor-label',
//       paint: {
//         'fill-color': '#C30078',
//         'fill-opacity': [
//           'interpolate',
//           ['linear'],
//           ['zoom'],
//           3,
//           0.5,
//           10,
//           0.3,
//           15,
//           0.15,
//         ] as Expression,
//       },
//     }
//   } else if (type === 'line') {
//     return {
//       id: 'carpoolStyleOutline',
//       type: 'line',
//       beforeId: 'settlement-minor-label',
//       paint: {
//         'line-color': '#C30078',
//         'line-opacity': 0.7,
//         'line-width': shapeOutlineSize,
//       },
//     }
//   } else {
//     return {
//       id: 'employees',
//       type: 'circle',
//       beforeId: 'settlement-major-label',
//       paint: {
//         'circle-radius': [
//           'interpolate',
//           ['linear'],
//           ['zoom'],
//           10,
//           [
//             'case',
//             ['>', ['get', 'clusterSz'], 1],
//             pointSize.smallNormal,
//             pointSize.smallHidden,
//           ],
//           15,
//           [
//             'case',
//             ['>', ['get', 'clusterSz'], 1],
//             pointSize.largeNormal,
//             pointSize.largeHidden,
//           ],
//         ] as Expression,
//         'circle-color': [
//           'case',
//           ['>', ['get', 'clusterSz'], 1],
//           '#C30078',
//           palette.primary.pink500,
//         ] as Expression,
//         'circle-stroke-width': pointStrokeWidth,
//         'circle-stroke-color': pointStrokeColor,
//         'circle-opacity': pointOpacity,
//         'circle-stroke-opacity': pointStrokeOpacity,
//       },
//     }
//   }
// }

// -------------- CURRENT-COMMUTE-LAYER --------------

export const generateCurrentCommuteModesStyle = (layer: any): LayerProps => {
  let commuteModeStyles
  if (layer && Object.keys(layer).length > 0) {
    commuteModeStyles = Object.keys(layer).flatMap(
      (mode: string, idx: number) => [
        ['==', ['get', 'modeType'], mode],
        colorArray[idx],
      ]
    )

    commuteModeStyles.unshift('case')
    commuteModeStyles.push(palette.white)
  }

  return {
    id: 'currentCommuteModes',
    type: 'circle',
    beforeId: 'settlement-major-label',
    paint: {
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        pointSize.smallNormal,
        15,
        pointSize.largeNormal,
      ] as Expression,
      'circle-color':
        layer && Object.keys(layer).length > 0
          ? (commuteModeStyles as Expression)
          : palette.primary.pink400,
      'circle-stroke-width': pointStrokeWidth,
      'circle-stroke-color': pointStrokeColor,
      'circle-opacity': pointOpacity,
      'circle-stroke-opacity': pointStrokeOpacity,
    },
  }
}

// -------------- CONTROL-PANEL --------------

export const controlPanelLayerName = {
  agony: {
    title: 'Commuter Agony',
  },
  currentCommuteModes: {
    title: 'Commute Modes',
  },
  carpool: {
    title: 'Carpool Cluster',
  },
}

//  For Analytics
export const metricLayerNames = {
  covidData: 'covid',
  carpool: 'carpool-cluster',
  agony: 'commute-agony',
  currentCommuteModes: 'commute-modes',
  employees: 'employee',
  worksites: 'worksite',
}

// -------------- CHARTS --------------

export const defaultModesChartColors = [
  palette.primary.pink400,
  palette.primary.pink800,
  '#61DBD8',
  '#BEB3FF',
  '#C30078',
  '#28AFAB',
  palette.text.secondary,
]

export const covidBarChartOptions = {
  layout: {
    padding: {
      top: 23,
    },
  },
  scales: {
    x: {
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      type: 'linear',
      beginAtZero: true,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          return data.labels[tooltipItem.index] as string
        },
        labelTextColor: () => 'fff',
      },
      titleSpacing: 3,
      backgroundColor: palette.primary.pink400,
      displayColors: false,
      caretSize: 0,
      mode: 'single' as InteractionMode,
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  animation: {
    duration: 0,
  },
}

// -------------- STYLE-MAP --------------

export const mapIntelligenceEmployeeStyles = {
  covidData: employeeStyle,
  agony: employeeAgonyStyle,
}
