import React from 'react'
import styled from 'styled-components'
import { EmployeeOptInChoice } from 'redux/optin/optInTypes'
import Text from 'core-system/Text'
import ChevronIcon from 'core-system/Icons/Actions/Chevron'
import palette from 'core-system/Themes/palette'

const ResponseContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  background-color: ${(props) => props.theme.palette.white};
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.pink300};
  }
`

const ResponseHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

interface OptInQuestionResponseProps {
  choice: string
  responses: EmployeeOptInChoice[]
  isMobile: boolean
  onClick: () => void
}

const OptInQuestionResponse = React.memo(
  (props: OptInQuestionResponseProps) => {
    const { choice, responses, isMobile, onClick } = props

    return (
      <ResponseContainer isMobile={isMobile} onClick={() => onClick()}>
        <ResponseHeader>
          <Text variant='h4'>{`${choice} (${responses.length})`}</Text>
          <ChevronIcon
            color={palette.primary.pink800}
            style={{ transform: 'rotate(-90deg)' }}
          />
        </ResponseHeader>
      </ResponseContainer>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OptInQuestionResponse.displayName = 'OptInQuestionResponse'
}

export default OptInQuestionResponse
