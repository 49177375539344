import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import employeePlatformService from 'redux/employeePlatform/employeePlatformService'
import styled from 'styled-components'
import { EmployeeOnboardingContent } from './EmployeeOnboardingUtils'
import Text from 'core-system/Text'
import FlexContainer from 'core-system/FlexContainer'
import EmployeeInfoForm from './components/EmployeeInfoForm'
import TermsAndConditionsAccept from './components/TermsAndConditionsAccept'
import palette from 'core-system/Themes/palette'
import Divider from 'core-system/Divider'
import Button from 'core-system/Button'
import { Navigate, useNavigate } from 'react-router-dom'
import { Locations } from 'shared/Router/Locations'
import moment from 'moment'
import Loading from 'core-system/Loading'
import ActionStatusModal from 'employee-platform/shared/components/ActionStatusModal'
import { Alert as AlertIcon } from 'core-system/Icons/Misc'

const Container = styled.div`
  display: flex;
  height: calc(100vh - 80px);
`

const LeftContainer = styled.div`
  width: 50%;
  padding: 2.5rem 4rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 100%;
  }
`

const RightContainer = styled.div<{ backgroundColor: string }>`
  width: 50%;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2.5rem 4rem;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    display: none;
  }
`

const Img = styled.img`
  align-self: center;
  width: 100%;
  max-width: 400px;
  object-fit: contain;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    right: 0;
  }
`

const EmployeeOnboardingView = React.memo(() => {
  const { profileData, hasDOB } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<'extraInfo' | 'acceptTerms'>(
    'extraInfo'
  )
  const [homeAddress, setHomeAddress] = useState<string | null>(
    profileData?.homeAddress || null
  )
  const [unitNumber, setUnitNumber] = useState<string | null>(
    profileData?.unitNumber || null
  )
  const [referredBy, setReferredBy] = useState<string | null>(
    profileData?.referredBy || null
  )
  const [referredColleagues, setReferredColleagues] = useState<string[]>(
    profileData?.referredColleagues || []
  )
  const [employeeCorpID, setEmployeeCorpID] = useState<string | null>(
    profileData?.employeeCorpId || null
  )

  const [dob, setDOB] = useState({
    day: null,
    month: null,
    year: null,
  })
  const [commutePreference, setCommutePreference] = useState(
    profileData?.currentTransport
  )
  const [updateUserInfoFailMsg, setUpdateUserInfoFailMsg] = useState<
    string | null
  >(null)
  const [acceptTermsFailMsg, setAcceptTermsFailMsg] = useState<string | null>(
    null
  )

  const showDOBPrompt =
    profileData?.approved && !profileData?.cardholderVerified && !hasDOB
      ? true
      : false
  const [termsAcceptedDate, setTermsAcceptedDate] = useState<number | null>(
    null
  )

  const invalidDOB = useMemo(() => {
    return !dob?.day || !dob?.month || !dob?.year
  }, [dob])

  const disableNextButton =
    !homeAddress ||
    (!profileData?.cardholderVerified && !hasDOB && invalidDOB) ||
    (!hasDOB &&
      !invalidDOB &&
      moment().diff(
        moment(`${dob.day}/${dob.month}/${dob.year}`, 'DD/MM/YYYY'),
        'years'
      ) < 18) ||
    !employeeCorpID

  const nextButtonText = useMemo(() => {
    if (currentStep === 'extraInfo') {
      return profileData?.cardholderVerified ? 'Submit' : 'Next'
    } else {
      return 'Accept'
    }
  }, [currentStep, profileData?.cardholderVerified])

  const resetInfoForm = () => {
    setHomeAddress(profileData?.homeAddress ? profileData.homeAddress : null)
    setUnitNumber(profileData?.unitNumber ? profileData.unitNumber : null)
    setReferredBy(profileData?.referredBy ? profileData.referredBy : null)
    setDOB({
      day: null,
      month: null,
      year: null,
    })
    setCommutePreference(
      profileData?.currentTransport ? profileData.currentTransport : null
    )
  }

  const handleNextButtonPress = () => {
    if (currentStep === 'extraInfo') {
      if (!profileData?.cardholderVerified) {
        if (profileData.approved) {
          setCurrentStep('acceptTerms')
          setTermsAcceptedDate(moment().unix())
        } else {
          finishOnboarding()
        }
      } else {
        finishOnboarding()
      }
    } else {
      finishOnboarding()
    }
  }

  const updateEmployeeProfile = async () => {
    try {
      const updateProfileReqData = {
        ...profileData,
        homeAddress:
          homeAddress && homeAddress !== ''
            ? homeAddress
            : profileData?.homeAddress,
        unitNumber:
          unitNumber && unitNumber !== ''
            ? unitNumber
            : profileData?.unitNumber,
        currentTransport:
          commutePreference && commutePreference !== ''
            ? commutePreference.toUpperCase()
            : profileData?.currentTransport,
        referredBy:
          referredBy && referredBy !== ''
            ? referredBy
            : profileData?.referredBy,
        referredColleagues:
          referredColleagues && referredColleagues.length > 0
            ? referredColleagues
            : profileData?.referredColleagues,
        employeeCorpId:
          employeeCorpID && employeeCorpID !== ''
            ? employeeCorpID
            : profileData?.employeeCorpId,
        onboardingComplete: true,
      }
      // Remove avatar from the request data b/c it can cause a crash when onboarding after social login
      delete updateProfileReqData.avatar
      const updateProfileDataResponse =
        await employeePlatformService.updateProfileData(updateProfileReqData)
      if (updateProfileDataResponse.status === 200) {
        return 'Success'
      } else {
        setLoading(false)
        setUpdateUserInfoFailMsg(
          'Failed to update your profile information. Please try again or contact support@movewithfleet.com'
        )
        setCurrentStep('extraInfo')
        return 'Failed'
      }
    } catch (e: any) {
      console.log(e)
      setLoading(false)
      resetInfoForm()
      setUpdateUserInfoFailMsg(
        'Failed to update your profile information. Please try again or contact support@movewithfleet.com'
      )
      setCurrentStep('extraInfo')
      return 'Failed'
    }
  }

  const acceptTerms = async () => {
    try {
      const cardholderDetailsRequestData = {
        dob: moment(`${dob.day}/${dob.month}/${dob.year}`, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        ),
        user_terms_acceptance: termsAcceptedDate,
        billing_address:
          profileData?.homeAddress && profileData.homeAddress !== ''
            ? profileData.homeAddress
            : homeAddress,
      }
      const cardholderDetailsRequestResponse =
        await employeePlatformService.updateCardholderDetails(
          cardholderDetailsRequestData
        )
      if (cardholderDetailsRequestResponse.status === 200) {
        return 'Success'
      } else {
        return 'Failed'
      }
    } catch (_e: any) {
      setLoading(false)
      resetInfoForm()
      setAcceptTermsFailMsg(
        'Something went wrong when trying to verify your details. Please try again or contact support@movewithfleet.com'
      )
      setCurrentStep('extraInfo')
      return 'Failed'
    }
  }

  const finishOnboarding = async () => {
    setLoading(true)
    const updateProfileResult = await updateEmployeeProfile()
    if (updateProfileResult === 'Failed') return
    if (profileData?.approved && !profileData?.cardholderVerified) {
      const acceptTermsResult = await acceptTerms()
      if (acceptTermsResult === 'Failed') return
      if (
        updateProfileResult === 'Success' &&
        acceptTermsResult === 'Success'
      ) {
        setLoading(false)
        navigate(Locations.EmployeeDashboard, { replace: true })
      }
    }
    if (updateProfileResult === 'Success') {
      setLoading(false)
      navigate(Locations.EmployeeDashboard, { replace: true })
    }
  }

  if (loading) return <Loading />

  // If the employee has completed onboarding, navigate directly to the dashboard
  if (profileData?.onboardingComplete && profileData?.cardholderVerified)
    return <Navigate to={Locations.EmployeeDashboard} replace={true} />

  return (
    <Container>
      <LeftContainer>
        <FlexContainer flexDirection='column'>
          <Text variant='h2'>
            {EmployeeOnboardingContent[currentStep].leftHeader}
          </Text>
          {currentStep === 'extraInfo' ? (
            <Text
              variant='action3'
              textColor={palette.text.placeholder}
              style={{ marginTop: '1rem' }}
            >
              * indicates a required field
            </Text>
          ) : null}
          <Divider margin='1.5rem 0' />
          {currentStep === 'extraInfo' ? (
            <EmployeeInfoForm
              missingHomeAddress={
                !profileData?.homeAddress || profileData?.homeAddress === ''
              }
              setHomeAddress={(homeAddress: string) =>
                setHomeAddress(homeAddress)
              }
              unitNumber={unitNumber}
              setUnitNumber={(unitNumber: string) => setUnitNumber(unitNumber)}
              missingReferredBy={!profileData?.referredBy}
              referredBy={referredBy}
              setReferredBy={(referredBy: string) => setReferredBy(referredBy)}
              referredColleagues={referredColleagues}
              setReferredColleagues={(referredColleagues: string[]) =>
                setReferredColleagues(referredColleagues)
              }
              missingEmployeeCorpID={!profileData?.employeeCorpId}
              employeeCorpID={employeeCorpID}
              setEmployeeCorpID={(employeeCorpId: string) =>
                setEmployeeCorpID(employeeCorpId)
              }
              showDOBPrompt={showDOBPrompt}
              dob={dob}
              setDOB={(dob: any) => setDOB(dob)}
              missingCommutePreference={!profileData?.currentTransport}
              setCommutePreference={(commutePreference: string) =>
                setCommutePreference(commutePreference)
              }
            />
          ) : null}
          {currentStep === 'acceptTerms' ? <TermsAndConditionsAccept /> : null}
        </FlexContainer>
        <FlexContainer justifyContent='space-between' marginTop='1.5rem'>
          <Button
            variant='tertiary'
            disabled={currentStep === 'extraInfo'}
            onClick={() => setCurrentStep('extraInfo')}
          >
            Back
          </Button>
          <Button
            disabled={disableNextButton}
            onClick={() => handleNextButtonPress()}
          >
            {nextButtonText}
          </Button>
        </FlexContainer>
      </LeftContainer>
      <RightContainer
        backgroundColor={EmployeeOnboardingContent[currentStep].backgroundColor}
      >
        <FlexContainer marginBottom='5rem'>
          <Text variant='h2' textAlign='end'>
            {EmployeeOnboardingContent[currentStep].rightHeader}
          </Text>
        </FlexContainer>
        <Img src={EmployeeOnboardingContent[currentStep].imageUri} />
      </RightContainer>

      {/* MODAL EXPLAINING WHICH STEP WENT WRONG IF FAILURE */}
      <ActionStatusModal
        open={updateUserInfoFailMsg || acceptTermsFailMsg ? true : false}
        closeModal={() => {
          if (updateUserInfoFailMsg) {
            setUpdateUserInfoFailMsg(null)
          }
          if (acceptTermsFailMsg) {
            setAcceptTermsFailMsg(null)
          }
        }}
        text={updateUserInfoFailMsg || acceptTermsFailMsg}
        icon={
          <AlertIcon
            color={palette.error.red500}
            width={75}
            height={75}
            style={{ marginBottom: '1rem' }}
          />
        }
        buttonText='Try Again'
      />
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployeeOnboardingView.displayName = 'EmployeeOnboardingView'
}

export default EmployeeOnboardingView
