import React from 'react'
import styled from 'styled-components'
import { NavigationControl, ViewStateChangeEvent } from 'react-map-gl/mapbox'
import SearchIcon from 'core-system/Icons/Actions/Search'
import { FeatureCollection } from 'geojson'
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system'

const Container = styled.div<FlexboxProps & SpaceProps & LayoutProps>`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  display: flex;
  flex-direction: column;

  ${flexbox}
  ${space}
  ${layout}
`

const NavContainer = styled.div`
  position: relative;
  height: ${(props) => props.theme.pxToRem(87)};

  & > .mapboxgl-ctrl {
    box-shadow: ${(props) => props.theme.dropShadows.normal};
  }
`

const EmployeeZoom = styled.div`
  margin-top: 0.5rem;
  box-shadow: ${(props) => props.theme.dropShadows.normal};
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.text.primary};
  width: ${(props) => props.theme.pxToRem(29)};
  height: ${(props) => props.theme.pxToRem(29)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

interface MapNavigationControlProps
  extends FlexboxProps,
    SpaceProps,
    LayoutProps,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  setViewport: (viewport: ViewStateChangeEvent) => void
  updateBbox?: (featureCollection: FeatureCollection) => void
  featureCollection?: FeatureCollection
}

const MapNavigationControl = React.memo((props: MapNavigationControlProps) => {
  const { updateBbox, featureCollection, ...rest } = props
  return (
    <Container {...rest}>
      <NavContainer>
        <NavigationControl />
      </NavContainer>
      {featureCollection && (
        <EmployeeZoom onClick={() => updateBbox(featureCollection)}>
          <SearchIcon width={20} height={20} />
        </EmployeeZoom>
      )}
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MapNavigationControl.displayName = 'MapNavigationControl'
}

export default MapNavigationControl
