import Button from 'core-system/Button'
import * as CategoryIcons from 'core-system/Icons/Incentives/Categories'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useState } from 'react'
import SegmentService from 'redux/config/services/SegmentService'
import styled from 'styled-components'

interface Category {
  icon: React.ReactElement
  type: string
  title: string
  description: string
  comingSoon: boolean
}

const categories: Category[] = [
  {
    icon: <CategoryIcons.Anything width={32} height={32} />,
    type: 'all',
    title: 'Anything',
    description: 'Commuters can spend their rewards anywhere',
    comingSoon: false,
  },
  {
    icon: <CategoryIcons.Mobility width={32} height={32} />,
    type: 'mobility',
    title: 'Mobility Credits',
    description: "Specific service, adds to commuter's regular mobility budget",
    comingSoon: true,
  },
  {
    icon: <CategoryIcons.Categories width={32} height={32} />,
    type: 'specific',
    title: 'Specific Categories',
    description: 'Food, entertainment etc (visa/mcc categories)',
    comingSoon: true,
  },
]

const Container = styled.div`
  ${(props) => props.theme.baseCard};
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;
`

const Rewards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
`

const Category = styled.div<{ isActive: boolean; isDisabled: boolean }>`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: center;
  align-items: center;
  border: 1px solid
    ${(props) =>
      props.isActive
        ? props.theme.palette.primary.pink400
        : props.theme.palette.grey.grey300};
  background-color: ${(props) =>
    props.isActive ? props.theme.palette.primary.pink300 : 'transparent'};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};
`

const IconContainer = styled.div<{ isActive: boolean }>`
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isActive
      ? props.theme.palette.primary.pink200
      : props.theme.palette.primary.pink200};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.isActive
      ? props.theme.palette.primary.pink500
      : props.theme.palette.text.secondary};
`

const ActivateIncentiveSpending = React.memo(() => {
  const [activeCategory, setActiveCategory] = useState('all')

  const handleCategoryClick = (category: string) => {
    setActiveCategory(category)
    SegmentService.track('rewards-category-click', { category: category })
  }

  return (
    <Container>
      <Text variant='h4' marginBottom='1.5rem'>
        Spending Categories
      </Text>
      <Text variant='body1' marginBottom='1.5rem' maxWidth={pxToRem(480)}>
        Rewards are automatically added to a commuter&apos;s Fleet Card balance.
        Choose how commuters use their rewards.
      </Text>
      <Rewards>
        {categories.map((category) => (
          <Category
            key={category.type}
            isActive={activeCategory === category.type}
            isDisabled={category.comingSoon}
            onClick={() => {
              if (!category.comingSoon) {
                handleCategoryClick(category.type)
              }
              SegmentService.track('rewards-category-click', {
                category: category.type,
              })
            }}
          >
            <IconContainer isActive={activeCategory === category.type}>
              {category.icon}
            </IconContainer>
            <Text variant='action3' margin='0.6875rem 0 0.5rem'>
              {category.title}
            </Text>
            <Text variant='body2' marginBottom='1rem' height={pxToRem(42)}>
              {category.description}
            </Text>
            {category.comingSoon && (
              <Button
                variant='secondary'
                disabled={true}
                size='small'
                width='100%'
              >
                Coming Soon!
              </Button>
            )}
          </Category>
        ))}
      </Rewards>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ActivateIncentiveSpending.displayName = 'ActivateIncentiveSpending'
}

export default ActivateIncentiveSpending
