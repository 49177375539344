import axios, { AxiosResponse } from 'axios'
import { getClientEnvironment } from 'redux/config/services/Constants'
import {
  AgonyDataParams,
  BaseCommuterParams,
  RecentTripDetailsParams,
  RecentTripOriginsParams,
} from 'redux/mapIntelligence/mapIntelligenceTypes'
import DateUtils from 'shared/DateUtils'
import { mapboxConfig } from 'shared/MapboxConfig'
import Request from '../config/services/Request'

const getAgonyData = (params: AgonyDataParams): Promise<AxiosResponse> => {
  return Request.post('/hr/maps/intelligence/agony/', {
    bufferDist: 500,
    segment: params.segmentId,
  })
}

const getBaseCommuters = (
  params: BaseCommuterParams
): Promise<AxiosResponse> => {
  return Request.post('/hr/maps/intelligence/base_commutes/', {
    segment: params.segmentId,
  })
}

const getRecentTripOrigins = (
  params: RecentTripOriginsParams
): Promise<AxiosResponse> => {
  const lastWeek = DateUtils.getLastWeek()
  return Request.get(
    `/hr/maps/recent_trips/?date__gte=${lastWeek}&data__lt=${null}&employee__segments=${
      params.segment
    }&modes__mode_type__in=${params.modeTypes}`
  )
}

const getRecentTripDetails = (
  params: RecentTripDetailsParams
): Promise<AxiosResponse> => {
  return Request.get(`/hr/maps/recent_trips/details/?id__in=${params.tripIds}`)
}

const getAddressAutocomplete = (query: string): Promise<AxiosResponse> => {
  const apiKey =
    getClientEnvironment() === 'development'
      ? mapboxConfig.apiKey
      : mapboxConfig.placesApiKey
  return axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${apiKey}&autocomplete=true&types=address&limit=3&language=en&country=US,CA`,
    { withCredentials: false }
  )
}

const getRoute = (start: number[], end: number[]): Promise<AxiosResponse> => {
  return axios.get(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${start.join(',')};${end.join(',')}?geometries=geojson&access_token=${mapboxConfig.apiKey}`,
    {
      withCredentials: false,
    }
  )
}

export default {
  getAgonyData,
  getBaseCommuters,
  getRecentTripOrigins,
  getRecentTripDetails,
  getAddressAutocomplete,
  getRoute,
}
