import React from 'react'
import styled from 'styled-components'
import { RawDraftContentState } from '../../../core-system/TextEditor'
import draftToHtml from 'draftjs-to-html'
import FleetLogoShortWhite from '../../../core-system/FleetLogos/FleetLogoShortWhite'

const Container = styled.div`
  width: 100%;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink300};
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink600};
  color: ${(props) => props.theme.palette.white};
  margin-right: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TitleText = styled.div`
  color: ${(props) => props.theme.palette.text.secondary};
  margin-top: 0.0625rem;
`

const Body = styled.div`
  color: ${(props) => props.theme.palette.black};
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
`

const Title = styled.div`
  ${(props) => props.theme.typography.action2};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Description = styled.div`
  ${(props) => props.theme.typography.body1};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const rawDraftToString = (raw: RawDraftContentState) => {
  return draftToHtml(raw)
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/&nbsp;/g, ' ')
    .trim()
}

interface MobileAppPreviewProps {
  title: string
  description: RawDraftContentState
}

const MobileAppPreview = React.memo((props: MobileAppPreviewProps) => {
  const { title, description } = props
  const strippedDescription = rawDraftToString(description)

  return (
    <Container>
      <Header>
        <TitleContainer>
          <IconContainer>
            <FleetLogoShortWhite width={20} height={20} />
          </IconContainer>
          <TitleText>FLEET</TitleText>
        </TitleContainer>
        <TitleText>Now</TitleText>
      </Header>
      <Body>
        <Title>{title ? title : 'Sample Announcement Title'}</Title>
        <Description>
          {strippedDescription
            ? strippedDescription
            : 'Your description goes here...'}
        </Description>
      </Body>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MobileAppPreview.displayName = 'MobileAppPreview'
}

export default MobileAppPreview
