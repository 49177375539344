import { TextButton } from 'core-system/Button'
import Divider from 'core-system/Divider'
import { SelectItemProps } from 'core-system/Dropdown'
import FlexContainer from 'core-system/FlexContainer'
import HorizontalScroller from 'core-system/HorizontalScroller'
import { Add } from 'core-system/Icons/Actions'
import InfoIcon from 'core-system/Icons/Misc/Info'
import * as CombinedModeIcons from 'core-system/Icons/modeTypes/Combined'
import { NumberFormatter, NumberInput } from 'core-system/Input/NumberInput'
import SwitchToggle from 'core-system/SwitchToggle'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import SvgTooltip from 'core-system/Tooltip/SvgTooltip'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useState } from 'react'
import SegmentService from 'redux/config/services/SegmentService'
import { ModeType } from 'redux/incentives/incentivesTypes'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'
import { RewardsCallbackItem } from '../ActivateIncentiveView'
import ActivateIncentiveRewardFilter from './ActivateIncentiveRewardFilter'
import ActivateIncentiveRewardRow from './ActivateIncentiveRewardRow'

export interface CombinedModeTypeFilter {
  type: string
  title: string
  icon: React.ReactElement
  description: string[]
  modes: string[]
}

const combinedModes: CombinedModeTypeFilter[] = [
  {
    type: 'greenModes',
    title: 'Green Modes',
    icon: <CombinedModeIcons.GreenCommute width={52} height={52} />,
    description: [
      'Walking',
      'All Bikes',
      'All Scooters',
      'Electric Mopeds',
      'Carpool',
      'Vanpool',
      'Shuttles',
      'Transit',
    ],
    modes: [
      'walkVal',
      'bikeVal',
      'bikeshareVal',
      'carpoolVal',
      'scooterVal',
      'scootershareVal',
      'mopedVal',
      'transitVal',
      'vanpoolVal',
      'shuttleVal',
    ],
  },
  {
    type: 'transit',
    title: 'Transit',
    icon: <CombinedModeIcons.Transit width={52} height={52} />,
    description: [
      'Subway',
      'Bus',
      'Streetcar',
      'Light Rail',
      'Heavy Rail',
      'Ferry',
      'Monorail',
    ],
    modes: ['transitVal'],
  },
  {
    type: 'micromobility',
    title: 'Micromobility',
    icon: <CombinedModeIcons.Micromobility width={52} height={52} />,
    description: ['All Bikes', 'All Scooters', 'All Mopeds'],
    modes: [
      'bikeVal',
      'bikeshareVal',
      'scooterVal',
      'scootershareVal',
      'mopedVal',
      'mopedshareVal',
    ],
  },
  {
    type: 'activeModes',
    title: 'Active Modes',
    icon: <CombinedModeIcons.ActiveModes width={52} height={52} />,
    description: ['Walking', 'All Bikes', 'All Scooters'],
    modes: [
      'walkVal',
      'bikeVal',
      'bikeshareVal',
      'scooterVal',
      'scootershareVal',
    ],
  },
  {
    type: 'alternativeModes',
    title: 'Alternative Modes',
    icon: <CombinedModeIcons.AlternativeModes width={52} height={52} />,
    description: [
      'All Bikes',
      'All Scooters',
      'All Mopeds',
      'Carpool',
      'Vanpool',
      'Shuttles',
    ],
    modes: [
      'bikeVal',
      'bikeshareVal',
      'scooterVal',
      'scootershareVal',
      'mopedVal',
      'mopedshareVal',
      'carpoolVal',
      'vanpoolVal',
      'shuttleVal',
    ],
  },
]

const Container = styled.div`
  ${(props) => props.theme.baseCard};
  padding: 2.5rem 3.25rem;
  margin-bottom: 1rem;
`

const RewardsContainer = styled.div``

const RewardHeader = styled.div`
  ${(props) => props.theme.typography.body2}
  color: ${(props) => props.theme.palette.text.placeholder};
  display: flex;
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey.grey300};
`

const Mode = styled.div`
  flex: 3;
`

const Reward = styled.div`
  flex: 2;
`

const Delete = styled.div`
  width: 3rem;
`

const Rewards = styled.div`
  transition: all 0.2s ease;
  margin-bottom: 1.5rem;
`

const Filters = styled.div`
  margin-bottom: 1.5rem;
`

const ErrorContainer = styled.div`
  margin: 0.25rem 0 0 0.125rem;
`

const Label = styled.div`
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.secondary};
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`

const ExtrasHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const StaticContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  border-radius: 0.5rem;
  max-width: ${(props) => props.theme.pxToRem(350)};
`

const FlexSizeContainer = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
`

const StaticModeContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.25rem 0.5rem 1rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border: 1px solid ${(props) => props.theme.palette.grey.grey300};
  border-radius: 0.5rem;
  width: 80%;
`

const RewardRow = styled.div`
  display: flex;
  margin-top: 1rem;
`

const EmptyDeleteContainer = styled.div`
  width: 3rem;
`

interface ActivateIncentiveRewardsProps {
  modeTypes: ModeType[]
  modeTypeErrors: string[]
  budgetError: boolean
  monthlyMax: number
  canEdit?: boolean
  setMonthlyMax: (value: number) => void
  handleModeTypeChange: (mode: SelectItemProps) => void
  handleModeTypeDelete: (idx: number) => void
  handleModeStatChange: (
    value: number,
    callbackItem: RewardsCallbackItem,
    monthlyMax?: boolean
  ) => void
  handleModeTypeAdd: (modeTypes?: string[]) => void
}

const ActivateIncentiveRewards = React.memo(
  (props: ActivateIncentiveRewardsProps) => {
    const {
      modeTypes,
      modeTypeErrors,
      budgetError,
      monthlyMax,
      canEdit,
      setMonthlyMax,
      handleModeTypeChange,
      handleModeStatChange,
      handleModeTypeDelete,
      handleModeTypeAdd,
    } = props

    const [showExtras, setShowExtras] = useState(false)
    const [extras, setExtras] = useState({
      bonus: 0,
      peak: 0,
    })

    const getRewards = (canEdit: boolean) => {
      return modeTypes.map((modeType, idx) => {
        return canEdit ? (
          <div key={idx}>
            <ActivateIncentiveRewardRow
              idx={idx}
              modeType={modeType}
              modeTypes={modeTypes}
              handleModeTypeDelete={handleModeTypeDelete}
              handleModeTypeChange={handleModeTypeChange}
              handleModeStatChange={handleModeStatChange}
              disableRemove={modeTypes.length === 1}
            />
            {modeTypeErrors.includes(modeType.id) && (
              <ErrorContainer>
                <Text variant='body1' textColor={palette.error.red500}>
                  Please fill out all fields correctly
                </Text>
              </ErrorContainer>
            )}
          </div>
        ) : (
          <RewardRow key={idx}>
            <FlexSizeContainer flex={3}>
              <StaticModeContainer>
                <Text variant='action4' capitalize>
                  {modeType.text}
                </Text>
              </StaticModeContainer>
            </FlexSizeContainer>
            <FlexSizeContainer flex={2}>
              <StaticModeContainer>
                <Text variant='action4'>
                  {FormattingUtils.formatDollar(modeType.perTrip * 100)}
                </Text>
              </StaticModeContainer>
            </FlexSizeContainer>
            <FlexSizeContainer flex={2}>
              <StaticModeContainer>
                <Text variant='action4'>
                  {modeType.max
                    ? FormattingUtils.formatDollar(modeType.max * 100)
                    : 'No max'}
                </Text>
              </StaticModeContainer>
            </FlexSizeContainer>
            <EmptyDeleteContainer />
          </RewardRow>
        )
      })
    }

    const handleFilterClick = (selectedFilter: CombinedModeTypeFilter) => {
      handleModeTypeAdd(selectedFilter.modes)
      SegmentService.track('incentive-modePills-add', {
        pillName: selectedFilter.title.toLowerCase().replaceAll(' ', '-'),
      })
    }

    const getModeFilters = () => {
      return combinedModes.map((mode) => {
        return (
          <ActivateIncentiveRewardFilter
            key={mode.type}
            mode={mode}
            handleFilterClick={handleFilterClick}
          />
        )
      })
    }

    return (
      <Container>
        <Text variant='h4' marginBottom='1rem'>
          Incentives
        </Text>
        <Text
          variant='body1'
          textColor={palette.text.secondary}
          marginBottom='0.25rem'
        >
          Monthly Max Per Commuter
        </Text>
        {canEdit ? (
          <NumberInput
            padding='1rem'
            maxWidth={pxToRem(350)}
            onValueChange={setMonthlyMax}
            value={monthlyMax / 100}
            iconSize={32}
            incrementVal={5}
          >
            <NumberFormatter fontVariant='h1' />
          </NumberInput>
        ) : (
          <StaticContainer>
            <Text variant='h1'>
              {FormattingUtils.formatDollar(monthlyMax, 0)}
            </Text>
          </StaticContainer>
        )}
        {budgetError && !monthlyMax && (
          <ErrorContainer>
            <Text variant='body1' textColor={palette.error.red500}>
              Please enter a valid amount
            </Text>
          </ErrorContainer>
        )}
        <Divider marginY='1.5rem' />
        <Text variant='h4' marginBottom='1.25rem'>
          {canEdit ? 'Set Rewards' : 'Rewards'}
        </Text>
        <RewardsContainer>
          {canEdit && (
            <Filters>
              <HorizontalScroller
                translationPerClick={115}
                opacityWidth={pxToRem(100)}
              >
                {getModeFilters()}
              </HorizontalScroller>
            </Filters>
          )}
          <RewardHeader>
            <Mode>Mode</Mode>
            <Reward>Reward (Per Trip)</Reward>
            <FlexContainer flex={2}>
              Monthly Max{' '}
              <SvgTooltip
                svg={<InfoIcon width={20} height={20} />}
                description='This is a monthly max for the specified mode type. Must be lower than commuters total incentive max and more than reward per trip.'
                marginLeft='0.25rem'
                marginTop='-0.175rem'
                isMultiLine
              />
            </FlexContainer>
            <Delete />
          </RewardHeader>
          <Rewards data-cy='incentive-modes'>{getRewards(canEdit)}</Rewards>
          {modeTypes.length < 13 && canEdit && (
            <TextButton iconLeft={<Add />} onClick={() => handleModeTypeAdd()}>
              Add New Mode
            </TextButton>
          )}
          {false && ( //show in demo
            <>
              <Divider marginY='1.5rem' />
              <ExtrasHeading>
                <Text variant='h4'>Extras</Text>
                <SwitchToggle
                  active={showExtras}
                  onClick={() => setShowExtras(!showExtras)}
                />
              </ExtrasHeading>
              {showExtras && (
                <FlexContainer>
                  <FlexContainer flexDirection='column' marginRight='2.5rem'>
                    <Label>Bonus For The First 10 Trips</Label>
                    <NumberInput
                      padding='0.625rem 0.5rem'
                      maxWidth={pxToRem(190)}
                      onValueChange={(value) =>
                        setExtras({ ...extras, bonus: value * 100 })
                      }
                      value={extras.bonus / 100}
                      iconSize={16}
                      incrementVal={0.5}
                    >
                      <NumberFormatter />
                    </NumberInput>
                  </FlexContainer>
                  <FlexContainer flexDirection='column'>
                    <Label style={{ display: 'flex' }}>
                      Off Peak Commute Time Bonus{' '}
                      <SvgTooltip
                        svg={<InfoIcon height={16} width={16} />}
                        height='1rem'
                        description='Arrival between 7-9am, Departure between 3-5pm'
                        marginLeft='0.5rem'
                      />
                    </Label>
                    <NumberInput
                      padding='0.625rem 0.5rem'
                      maxWidth={pxToRem(190)}
                      onValueChange={(value) =>
                        setExtras({ ...extras, peak: value * 100 })
                      }
                      value={extras.peak / 100}
                      iconSize={16}
                      incrementVal={0.5}
                    >
                      <NumberFormatter />
                    </NumberInput>
                  </FlexContainer>
                </FlexContainer>
              )}
            </>
          )}
        </RewardsContainer>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ActivateIncentiveRewards.displayName = 'ActivateIncentiveRewards'
}

export default ActivateIncentiveRewards
