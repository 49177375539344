import PlatformSvg from 'core-system/Icons/PlatformSvg'
import React from 'react'
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { AgonyStats } from 'redux/mapIntelligence/mapIntelligenceTypes'
import styled from 'styled-components'

const MetricsContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: PolySans;
  color: ${(props) => props.theme.palette.text.primary};
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.75rem 0;
`

const AgonyContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0.375rem 0.5rem 0;
`

const ChartContainer = styled.div`
  height: ${(props) => props.theme.pxToRem(90)};
  width: ${(props) => props.theme.pxToRem(90)};
  margin-right: 1rem;
`

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StatTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0.75rem 0 0.25rem;
  text-align: left;
  max-width: ${(props) => props.theme.pxToRem(75)};
`

const StatDesc = styled.div`
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #8483c9;
  text-align: left;
`

const Percentage = styled.div`
  font-family: 'PolySans, sans-serif';
  font-weight: 700;
  font-size: 1.75rem;
  text-align: center;
  display: flex;
  line-height: 2rem;
  margin-left: 0.1875rem;
`

const Span = styled.span`
  font-family: PolySans;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
`

const Employees = styled.div`
  font-family: PolySans;
  font-size: 0.75rem;
`

const getChartStyles = (color: string) => {
  return buildStyles({
    strokeLinecap: 'round',
    pathColor: color,
    trailColor: '#FFEBF8',
  })
}

interface KeyMetricsAgonyProps {
  agonyStats: AgonyStats
  totalEmployees: number
}

const KeyMetricsAgony = React.memo((props: KeyMetricsAgonyProps) => {
  const { agonyStats, totalEmployees } = props

  const superCommuters = agonyStats.superCommuter ? agonyStats.superCommuter : 0
  const megaCommuters = agonyStats.megaCommuter ? agonyStats.megaCommuter : 0
  const longDistanceCommuters = agonyStats.longDistanceCommuter
    ? agonyStats.longDistanceCommuter
    : 0

  const superCommutersPerc =
    Math.round((superCommuters / totalEmployees) * 100) || 0
  const megaCommutersPerc =
    Math.round((megaCommuters / totalEmployees) * 100) || 0
  const longDistanceCommutersPerc =
    Math.round((longDistanceCommuters / totalEmployees) * 100) || 0

  return (
    <MetricsContainer>
      <AgonyContainer>
        <ChartContainer>
          <CircularProgressbarWithChildren
            styles={getChartStyles('#28AFAB')}
            strokeWidth={6.5}
            counterClockwise={true}
            value={superCommutersPerc}
          >
            <Percentage>
              {superCommutersPerc}
              <Span>%</Span>
            </Percentage>
            <Employees>{superCommuters} Emp.</Employees>
          </CircularProgressbarWithChildren>
        </ChartContainer>
        <StatContainer>
          <PlatformSvg
            folder='metrics'
            variant='superCommuterTransit'
            width={38}
            height={38}
          />
          <StatTitle>Super Commuters</StatTitle>
          <StatDesc>1.5+ hrs one way</StatDesc>
        </StatContainer>
      </AgonyContainer>
      <AgonyContainer>
        <ChartContainer>
          <CircularProgressbarWithChildren
            styles={getChartStyles('#947BFF')}
            strokeWidth={6.5}
            counterClockwise={true}
            value={longDistanceCommutersPerc}
          >
            <Percentage>
              {longDistanceCommutersPerc}
              <Span>%</Span>
            </Percentage>
            <Employees>{longDistanceCommuters} Emp.</Employees>
          </CircularProgressbarWithChildren>
        </ChartContainer>
        <StatContainer>
          <PlatformSvg
            folder='metrics'
            variant='longDistancePurple'
            width={38}
            height={38}
          />
          <StatTitle>Long Distance</StatTitle>
          <StatDesc>50+ miles one way</StatDesc>
        </StatContainer>
      </AgonyContainer>
      <AgonyContainer>
        <ChartContainer>
          <CircularProgressbarWithChildren
            styles={getChartStyles('#C30078')}
            strokeWidth={6.5}
            counterClockwise={true}
            value={megaCommutersPerc}
          >
            <Percentage>
              {megaCommutersPerc}
              <Span>%</Span>
            </Percentage>
            <Employees>{megaCommuters} Emp.</Employees>
          </CircularProgressbarWithChildren>
        </ChartContainer>
        <StatContainer>
          <PlatformSvg
            folder='metrics'
            variant='megaCommuterDriving'
            width={38}
            height={38}
          />
          <StatTitle>Mega Commuters</StatTitle>
          <StatDesc>Super + Long</StatDesc>
        </StatContainer>
      </AgonyContainer>
    </MetricsContainer>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  KeyMetricsAgony.displayName = 'KeyMetricsAgony'
}

export default KeyMetricsAgony
