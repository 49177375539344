export default {
  50: '#FEFBFA',
  100: '#FEF8F6',
  200: '#FCEAE9',
  300: '#FACFCD',
  400: '#F6AAA3',
  500: '#F27668',
  600: '#DB3730',
  700: '#992621',
  800: '#591310',
  900: '#1E0303',
}
