import React from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import RadialChart from './RadialChart'
import pxToRem from 'core-system/utils/pxToRem'
import RadialChartUtils from './RadialChartUtils'
import FormattingUtils from 'shared/FormattingUtils'

const chartOptions = {
  ...RadialChartUtils.defaultChartOptions,
  plugins: {
    ...RadialChartUtils.defaultChartOptions.plugins,
    legend: {
      display: false,
    },
  },
  cutout: '69%',
}

export const colors = [
  palette.primary.pink800,
  palette.primary.pink500,
  palette.accent.teal500,
  palette.primary.pink500,
  palette.accent.blue500,
  palette.primary.pink500,
  palette.error.red500,
  palette.accent.blue500,
  palette.success.green500,
]

const ChartContainer = styled.div<{ size: number }>`
  position: relative;
  width: ${(props) => props.theme.pxToRem(props.size)};
  height: ${(props) => props.theme.pxToRem(props.size)};
`

const CenterText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
`

const getChartData = (
  data: number[],
  labels: string[],
  errorMsg: string,
  colors: string[]
) => {
  const hasData = data.length > 0 && data.some((data) => data)
  return {
    datasets: [
      {
        data: hasData ? data : [100],
        backgroundColor: hasData ? colors : [palette.grey.grey200],
        hoverBackgroundColor: hasData ? colors : [palette.grey.grey200],
        borderWidth: 0,
      },
    ],
    labels: hasData ? labels : [errorMsg],
  }
}

interface RadialChartCenterTextProps {
  value: number
  label?: string
  data: number[]
  labels: string[]
  size?: number
  errorMsg?: string
  customColors?: string[]
  isCurrency?: boolean
}

const RadialChartCenterText = React.memo(
  (props: RadialChartCenterTextProps) => {
    const {
      value,
      label,
      data,
      labels,
      size = 200,
      customColors,
      errorMsg = 'No Data',
      isCurrency,
    } = props
    return (
      <ChartContainer size={size}>
        <CenterText>
          <Text variant='h2'>
            {isCurrency ? FormattingUtils.formatDollar(value, 0) : value}
          </Text>
          {label ? (
            <Text
              variant='caption'
              captionTitle
              textColor={palette.text.secondary}
            >
              {label}
            </Text>
          ) : null}
        </CenterText>
        <RadialChart
          data={getChartData(data, labels, errorMsg, customColors || colors)}
          options={chartOptions}
          width={pxToRem(size)}
          height={pxToRem(size)}
        />
      </ChartContainer>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  RadialChartCenterText.displayName = 'RadialChartCenterText'
}

export default RadialChartCenterText
