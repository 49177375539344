import React from 'react'
import styled from 'styled-components'
import { Popup } from 'react-map-gl/mapbox'
import { Point } from 'geojson'

const StyledPopup = styled(Popup)`
  z-index: ${(props) => props.theme.zIndex.tooltip};
  cursor: pointer;

  & > .mapboxgl-popup-content {
    border-radius: 0.9375rem;
    box-shadow: ${(props) => props.theme.dropShadows.selected};
    padding: 0.625rem;

    & > .mapboxgl-popup-close-button {
      top: 0.4375rem;
      right: 0.5rem;
      color: ${(props) => props.theme.palette.text.primary};
      font-size: 1.75rem;
      font-family: PolySans;
      line-height: 2rem;

      &:hover {
        background-color: transparent;
      }

      &:focus {
        outline: none;
      }
    }
  }
`

interface MapPopupProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  point: Point
  onCloseCallback: () => void
}

const MapPopup = React.memo((props: MapPopupProps) => {
  const { point, children, onCloseCallback } = props

  return (
    <StyledPopup
      anchor='top'
      longitude={point.coordinates[0]}
      latitude={point.coordinates[1]}
      closeOnMove={true}
      closeOnClick={false}
      onClose={onCloseCallback}
    >
      {children}
    </StyledPopup>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MapPopup.displayName = 'MapPopup'
}

export default MapPopup
