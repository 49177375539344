import axios, { AxiosResponse, ResponseType } from 'axios'
import camelize from 'camelize'
import Cookies from 'js-cookie'
import snakeize from 'snakeize'
import { ApiServerUrl, WebSocketUrl } from './Constants'

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true
axios.defaults.xsrfCookieName = 'csrftoken'

const csrfToken = Cookies.get('csrftoken')

const defaultConfig = {
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': csrfToken,
  },
}

const formDataConfig = {
  headers: {
    'X-CSRFToken': csrfToken,
    'Content-Type': 'multipart/form-data',
  },
}

const getConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
}

const pdfConfig = {
  responseType: 'arraybuffer' as ResponseType,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': csrfToken,
  },
}

const camelizedResponse = (response: AxiosResponse): AxiosResponse => {
  if (Array.isArray(response.data)) {
    return {
      ...response,
      data:
        response.data.length === 0 ? [] : response.data.map((i) => camelize(i)),
    }
  }
  return {
    ...response,
    data: camelize(response.data),
  }
}

const snakizeData = (data: any) => {
  if (Array.isArray(data)) {
    return data.length === 0 ? [] : data.map((i) => snakeize(i))
  }
  return snakeize(data)
}

function get(url: string, disableCamelcase?: boolean): Promise<AxiosResponse> {
  return axios.get(ApiServerUrl + url, getConfig).then((res) => {
    return disableCamelcase ? res : camelizedResponse(res)
  })
}

function post(
  url: string,
  data: any,
  dataType?: 'formData' | 'pdf',
  disableCamelcase?: boolean
): Promise<AxiosResponse> {
  const configs = {
    formData: formDataConfig,
    pdf: pdfConfig,
  }

  const formattedData = dataType === 'formData' ? data : snakizeData(data)

  if (dataType) {
    return axios
      .post(ApiServerUrl + url, formattedData, configs[dataType])
      .then((res) => {
        return disableCamelcase ? res : camelizedResponse(res)
      })
  }
  return axios
    .post(ApiServerUrl + url, JSON.stringify(formattedData), defaultConfig)
    .then((res) => {
      return disableCamelcase ? res : camelizedResponse(res)
    })
}

function put(
  url: string,
  data: any,
  isFormData?: boolean,
  disableCamelcase?: boolean
): Promise<AxiosResponse> {
  if (isFormData) {
    return axios.put(ApiServerUrl + url, data, formDataConfig).then((res) => {
      return disableCamelcase ? res : camelizedResponse(res)
    })
  }

  const snakeCasedData = snakizeData(data)
  return axios
    .put(ApiServerUrl + url, JSON.stringify(snakeCasedData), defaultConfig)
    .then((res) => {
      return disableCamelcase ? res : camelizedResponse(res)
    })
}

function del(url: string): Promise<AxiosResponse> {
  return axios.delete(ApiServerUrl + url, defaultConfig)
}

function patch(
  url: string,
  data: any,
  disableCamelcase?: boolean
): Promise<AxiosResponse> {
  const snakeCasedData = snakizeData(data)
  return axios
    .patch(ApiServerUrl + url, JSON.stringify(snakeCasedData), defaultConfig)
    .then((res) => {
      return disableCamelcase ? res : camelizedResponse(res)
    })
}

async function openWebSocket(url: string) {
  return new Promise((resolve, reject) => {
    const ws = new WebSocket(WebSocketUrl(url))
    ws.onopen = () => {
      resolve(ws)
    }
    ws.onerror = (error) => {
      reject(error)
    }
  })
}

function closeWebSocket(ws: WebSocket) {
  ws.close()
}

export default { get, post, put, del, patch, openWebSocket, closeWebSocket }
