import RadialChartCenterText from 'core-system/charts/Radial/RadialChartCenterText'
import Divider from 'core-system/Divider'
import FlexContainer from 'core-system/FlexContainer'
import HorizontalScroller from 'core-system/HorizontalScroller'
import LegendColor from 'core-system/Legend'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'
import SvgTooltip from 'core-system/Tooltip/SvgTooltip'
import TransitLogo, { TransitLogoModeTypes } from 'core-system/TransitLogo'
import pxToRem from 'core-system/utils/pxToRem'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { RecentTripDetails } from 'redux/mapIntelligence/mapIntelligenceTypes'
import { ServiceProviderMap } from 'redux/programs/programsTypes'
import DateUtils from 'shared/DateUtils'
import FormattingUtils from 'shared/FormattingUtils'
import NumberUtils from 'shared/NumberUtils'
import RandomUtils from 'shared/RandomUtils'
import styled from 'styled-components'
import { ModeTypeGroupings } from '../ProgramOverviewMap'

const colorArray = [
  '#C30078',
  '#4EC7C4',
  palette.primary.pink400,
  palette.primary.pink800,
  '#5FCD6A',
  '#3A78FF',
  '#C19CFF',
  '#FF7076',
  '#7ADBF8',
]

const StatsContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.palette.white};
  border-top: 1px solid ${(props) => props.theme.palette.grey.grey300};
  display: flex;
  padding: 1.5rem;
`

const HalfContainer = styled.div`
  display: flex;
  flex: 0.6;
  align-items: center;
`

const SmallHalfContainer = styled.div`
  display: flex;
  flex: 0.35;
  align-items: center;
`

const DividerContainer = styled.div`
  flex: 0.05;
  display: flex;
  justify-content: center;
`

const LegendContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.25rem;
`

const MetricContainer = styled.div`
  margin-right: 1.5rem;
`

const ServiceContainer = styled.div<{ noMargin?: boolean }>`
  margin-right: ${(props) => (!props.noMargin ? '1.5rem' : '0')};
  max-width: ${(props) => props.theme.pxToRem(126)};
`

const getModeTypes = (modeTypes: string[]) => {
  return [...Array.from(new Set(modeTypes))].map((modeType) => (
    <SvgTooltip
      key={modeType}
      marginRight='0.25rem'
      svg={
        <TransitLogo
          modeType={modeType as TransitLogoModeTypes}
          src={modeType}
          size='small'
        />
      }
      description={ProgramUtils.formatElectricModeTypes(
        RandomUtils.capitalize(modeType)
      )}
      position='left'
    />
  ))
}

const getServiceProviders = (
  services: string[],
  serviceProviderMap: ServiceProviderMap
) => {
  return [...Array.from(new Set(services))].map((service) => {
    const serviceData = serviceProviderMap[service]
    return (
      <SvgTooltip
        key={serviceData.name}
        marginRight='0.25rem'
        svg={
          <TransitLogo
            modeType={serviceData.iconUrl ? 'custom' : serviceData.modeType}
            src={serviceData.iconUrl || serviceData.modeType}
            size='small'
          />
        }
        description={RandomUtils.capitalize(serviceData.name)}
        position='left'
      />
    )
  })
}

const formatChartLabels = (labels: string[]) => {
  return labels.map((label) => ProgramUtils.formatElectricModeTypes(label))
}

interface ProgramOverviewMapStatsProps {
  selectedTrip: RecentTripDetails
  modeTypeGroupings: ModeTypeGroupings
}

const ProgramOverviewMapStats = React.memo(
  (props: ProgramOverviewMapStatsProps) => {
    const { selectedTrip, modeTypeGroupings } = props

    const serviceProviderMap = useSelector(
      (state: AppState) => state.programs.serviceProviderMap
    )

    const chartData = modeTypeGroupings.groupings.reduce(
      (agg: { data: number[]; labels: string[] }, modeType) => {
        agg.data.push(modeType.numTrips)
        agg.labels.push(
          `${RandomUtils.capitalize(modeType.modeType)} (${
            modeType.numTrips
          } Trip${RandomUtils.pluralCheck(modeType.numTrips)})`
        )
        return agg
      },
      { data: [], labels: [] }
    )
    return (
      <StatsContainer>
        <HalfContainer>
          <RadialChartCenterText
            value={modeTypeGroupings.total}
            label='Total Trips'
            data={chartData.data}
            labels={formatChartLabels(chartData.labels)}
            errorMsg='No Trips This Week'
            size={140}
            customColors={colorArray}
          />
          <FlexContainer flexDirection='column' marginLeft='1.5rem'>
            <Text variant='h5' marginBottom='0.5rem'>
              Trip Origins (Last 7 Days)
            </Text>
            <Text variant='body1' marginBottom='1rem'>
              {chartData.data.length === 0
                ? 'No trips recorded yet this week.'
                : "Here's how your commuters have been getting to work."}
            </Text>
            <LegendContainer>
              {modeTypeGroupings.groupings.map((trips, idx) => (
                <FlexContainer
                  key={idx}
                  alignItems='center'
                  marginBottom='0.25rem'
                >
                  <LegendColor color={colorArray[idx]} marginRight='0.5rem' />
                  <Text variant='action4' capitalize>
                    {ProgramUtils.formatElectricModeTypes(
                      trips.modeType.toLowerCase()
                    )}
                  </Text>
                  <Text
                    variant='body2'
                    marginLeft='auto'
                    textColor={palette.text.secondary}
                  >
                    {trips.numTrips} Trip
                    {RandomUtils.pluralCheck(trips.numTrips)}
                  </Text>
                </FlexContainer>
              ))}
            </LegendContainer>
          </FlexContainer>
        </HalfContainer>
        <DividerContainer>
          <Divider direction='vertical' margin=' 0 1rem' />
        </DividerContainer>
        <SmallHalfContainer>
          {selectedTrip ? (
            <FlexContainer
              flexDirection='column'
              justifyContent='center'
              width='100%'
            >
              <FlexContainer>
                <MetricContainer>
                  <Text variant='caption' captionTitle marginBottom='0.25rem'>
                    Origin
                  </Text>
                  <Text variant='body1' marginBottom='1rem' hideOverflow>
                    {selectedTrip.startAddress.split(',')[0]}
                  </Text>
                </MetricContainer>
                <div>
                  <Text variant='caption' captionTitle marginBottom='0.25rem'>
                    Destination
                  </Text>
                  <Text variant='body1' marginBottom='1rem' hideOverflow>
                    {selectedTrip.endAddress.split(',')[0]}
                  </Text>
                </div>
              </FlexContainer>
              <FlexContainer>
                <MetricContainer>
                  <Text variant='caption' captionTitle marginBottom='0.25rem'>
                    Duration
                  </Text>
                  <Text variant='body1' marginBottom='1rem' hideOverflow>
                    {DateUtils.formatTime(selectedTrip.duration)}
                  </Text>
                </MetricContainer>
                <MetricContainer>
                  <Text variant='caption' captionTitle marginBottom='0.25rem'>
                    Cost
                  </Text>
                  <Text variant='body1' marginBottom='1rem' hideOverflow>
                    {FormattingUtils.formatDollar(selectedTrip.cost, 2)}
                  </Text>
                </MetricContainer>
                <MetricContainer>
                  <Text
                    variant='caption'
                    captionTitle
                    marginBottom='0.25rem'
                    hideOverflow
                  >
                    Environmental (C02)
                  </Text>
                  <Text variant='body1' marginBottom='1rem' hideOverflow>
                    {NumberUtils.formatNumber(selectedTrip.env / 454, 0)} lb
                    {RandomUtils.pluralCheck(selectedTrip.env / 454)}
                  </Text>
                </MetricContainer>
              </FlexContainer>
              <FlexContainer>
                <ServiceContainer>
                  <Text variant='caption' captionTitle marginBottom='0.25rem'>
                    Mode Types
                  </Text>
                  <HorizontalScroller
                    translationPerClick={50}
                    opacityWidth={pxToRem(60)}
                    resetOnChildrenUpdate
                  >
                    {getModeTypes(selectedTrip.modeTypes)}
                  </HorizontalScroller>
                </ServiceContainer>
                <ServiceContainer noMargin>
                  <Text variant='caption' captionTitle marginBottom='0.25rem'>
                    Service Providers
                  </Text>
                  <HorizontalScroller
                    translationPerClick={50}
                    opacityWidth={pxToRem(60)}
                    resetOnChildrenUpdate
                  >
                    {getServiceProviders(
                      selectedTrip.modes,
                      serviceProviderMap
                    )}
                  </HorizontalScroller>
                </ServiceContainer>
              </FlexContainer>
            </FlexContainer>
          ) : (
            <FlexContainer center>
              <Text variant='body1' textAlign='center'>
                {chartData.data.length > 0 &&
                  'Select a trip origin to see detailed trip information.'}
              </Text>
            </FlexContainer>
          )}
        </SmallHalfContainer>
      </StatsContainer>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProgramOverviewMapStats.displayName = 'ProgramOverviewMapStats'
}

export default ProgramOverviewMapStats
