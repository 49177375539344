import FlexContainer from 'core-system/FlexContainer'
import Text from 'core-system/Text'
import React from 'react'
import styled from 'styled-components'
import Divider from 'core-system/Divider'
import FormattingUtils from 'shared/FormattingUtils'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import palette from 'core-system/Themes/palette'
import Chip from 'core-system/Chip'
import RadialChartCenterText from 'core-system/charts/Radial/RadialChartCenterText'
import { AppState } from 'redux/config/store'
import { useSelector } from 'react-redux'

const Container = styled.div`
  ${(props) => props.theme.baseCard};
`

const AllocationBody = styled(FlexContainer)`
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`

const AllocationBodySection = styled(FlexContainer)`
  flex-direction: column;
`

const AllocationSectionHeader = styled(FlexContainer)`
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const IconContainer = styled(FlexContainer)`
  width: 2rem;
  height: 2rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  border-radius: 50%;
  margin-right: 0.5rem;
`

const renderAllocationSection = (
  title: string,
  icon: React.ReactNode,
  active: boolean,
  employerContribution: number,
  perEmployeeLimit: number
) => {
  return (
    <AllocationBodySection>
      <AllocationSectionHeader>
        <FlexContainer
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
        >
          <IconContainer center>{icon}</IconContainer>
          {/* <AllocationSectionHeaderText> */}
          <Text variant='h5'>{title}</Text>
        </FlexContainer>
        <Chip marginLeft='1.5rem' variant={active ? 'green' : 'grey'}>
          {active ? 'Active' : 'Disabled'}
        </Chip>
        {/* </AllocationSectionHeaderText> */}
      </AllocationSectionHeader>
      <FlexContainer center marginBottom='1.5rem'>
        <RadialChartCenterText
          value={employerContribution + perEmployeeLimit}
          data={[employerContribution, perEmployeeLimit]}
          labels={[
            `Employer Contribution (${FormattingUtils.formatDollar(
              employerContribution,
              0
            )})`,
            `Per Commuter Limit (${FormattingUtils.formatDollar(
              perEmployeeLimit,
              0
            )})`,
          ]}
          size={170}
          isCurrency
        />
      </FlexContainer>
      <Text variant='action3'>Employer Contribution</Text>
      <Text variant='h4' textColor={palette.primary.pink400}>
        {FormattingUtils.formatDollar(employerContribution, 0)}
      </Text>
      <Divider size='0' marginY='0.25rem' />
      <Text variant='action3'>Commuter Allocation Limit</Text>
      <Text variant='h4' textColor={palette.primary.pink400}>
        {FormattingUtils.formatDollar(perEmployeeLimit, 0)}
      </Text>
    </AllocationBodySection>
  )
}

interface QTFAllocationDetailsCardProps {
  transitActive?: boolean
  parkingActive?: boolean
  employerParkingContribution?: number
  employerTransitContribution?: number
}

const QTFAllocationDetailsCard = React.memo(
  (props: QTFAllocationDetailsCardProps) => {
    const {
      transitActive,
      parkingActive,
      employerParkingContribution,
      employerTransitContribution,
    } = props
    const { irsTaxLimit } = useSelector(
      (state: AppState) => state.application.properties
    )

    const employeeParkingAllocationLimit = parkingActive
      ? irsTaxLimit - (employerParkingContribution || 0)
      : 0
    const employeeTransitAllocationLimit = transitActive
      ? irsTaxLimit - (employerTransitContribution || 0)
      : 0

    return (
      <Container>
        <Text variant='h5' marginBottom='1rem'>
          Per Commuter Allocation Details
        </Text>
        <AllocationBody>
          {renderAllocationSection(
            ProgramUtils.optionsCopy.transit.title,
            ProgramUtils.optionsCopy.transit.icon,
            transitActive,
            employerTransitContribution || 0,
            employeeTransitAllocationLimit
          )}
          <Divider size='20rem' direction='vertical' margin='0 1.5rem' />
          {renderAllocationSection(
            ProgramUtils.optionsCopy.parking.title,
            ProgramUtils.optionsCopy.parking.icon,
            parkingActive,
            employerParkingContribution || 0,
            employeeParkingAllocationLimit
          )}
        </AllocationBody>
      </Container>
    )
  }
)

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFAllocationDetailsCard.displayName = 'QTFAllocationDetailsCard'
}

export default QTFAllocationDetailsCard
