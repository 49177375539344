import Button from 'core-system/Button'
import ColumnView, { Column } from 'core-system/ColumnView'
import Loading from 'core-system/Loading'
import * as Program from 'core-system/Program'
import Footer from 'core-system/Program/Footer'
import ProgramModal from 'core-system/Program/ProgramModal'
import ProgramOption from 'core-system/Program/ProgramOption'
import ProgramPriceCoverage from 'core-system/Program/ProgramPriceCoverage'
import ProgramUtils from 'core-system/Program/ProgramUtils'
import ServiceProviderSelectorModal from 'core-system/ServiceProvider/ServiceProviderSelectorModal'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { micromobilityActions } from 'redux/micromobility/micromobilitySlice'
import {
  MicromobilityActiveOptions,
  MicromobilityManageOptionState,
  MicromobilityManageState,
  MicromobilityType,
} from 'redux/micromobility/micromobilityTypes'
import { notificationsActions } from 'redux/notifications/notificationsSlice'
import {
  CardProgram as ProgramType,
  Merchant,
} from 'redux/programs/programsTypes'
import useQueryParam from 'shared/Hooks/useQueryParam'
import { Locations } from 'shared/Router/Locations'
import styled from 'styled-components'
import MicromobilityLeasingTrips from '../Shared/components/MicromobilityLeasingTrips'
import MicromobilityOneTimePurchase from '../Shared/components/MicromobilityOneTimePurchase'
import MicromobilityUtils from '../Shared/MicromobilityUtils'
import MicromobilityManagePending from './components/MicromobilityManagePending'
import MicromobilityManageSummary from './components/MicromobilityManageSummary'
import FormattingUtils from 'shared/FormattingUtils'

const StyledColumnView = styled(ColumnView)`
  margin-bottom: 10rem;
  grid-template-columns: 64.5% 34.5%;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 100%;
    max-width: ${(props) => props.theme.pxToRem(700)};
    grid-gap: unset;
  }
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`

const getDefaultOptions = (program: ProgramType) => {
  return {
    shared: program.shared,
    leasing: program.leasing,
    oneTime: program.yearlyPool,
  }
}

const formatMerchantIds = (
  activeMerchantIds: string[],
  availableMerchants: Merchant[]
) => {
  return {
    activeMerchantIds,
    availableMerchantIds: availableMerchants.map((m) => m.id),
  }
}

const getDefaultState = (
  program: ProgramType,
  merchantsMap: Dictionary<Merchant>,
  availableMerchants: Dictionary<Merchant[]>
): MicromobilityManageOptionState => {
  const sortedMerchantIds = ProgramUtils.sortMicroMerchantIds(
    program.merchants,
    merchantsMap
  )

  return {
    ...program,
    leasingMerchants: formatMerchantIds(
      sortedMerchantIds.leasing,
      availableMerchants.leasing
    ),
    sharedMerchants: formatMerchantIds(
      sortedMerchantIds.shared,
      availableMerchants.shared
    ),
    oneTimeMerchants: formatMerchantIds(
      sortedMerchantIds.oneTime,
      availableMerchants.oneTime
    ),
  }
}

const MicromobilityManageView = React.memo(() => {
  const segmentId = useQueryParam('segmentId')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const segmentsMap = useSelector(
    (state: AppState) => state.employer.segmentsMap
  )
  const merchantsMap = useSelector(
    (state: AppState) => state.programs.merchantsMap
  )
  const micromobilityProgramsLoaded = useSelector(
    (state: AppState) => state.micromobility.micromobilityProgramsLoaded
  )
  const micromobilityProgramUpdated = useSelector(
    (state: AppState) => state.micromobility.micromobilityProgramUpdated
  )
  const availableMerchants = useSelector(
    (state: AppState) => state.micromobility.availableMerchants
  )
  const allPrograms = useSelector(
    (state: AppState) => state.micromobility.micromobilityPrograms
  )

  const [isEdit, setIsEdit] = useState(false)
  const [errorState, setErrorState] = useState(
    MicromobilityUtils.defaultErrorState
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [activeOption, setActiveOption] = useState<MicromobilityType>('shared')
  const [programState, setProgramState] = useState<MicromobilityManageState>({
    active: null,
    nextMonth: null,
  })
  const [pendingProgram, setPendingProgram] = useState({
    program: null,
    activeOptions: null,
  })
  const [activeState, setActiveState] = useState('active')
  const [activeOptions, setActiveOptions] =
    useState<Dictionary<MicromobilityActiveOptions>>(null)

  useEffect(() => {
    //ensure all providers are loaded so proper options groupings can be done
    if (segmentId && merchantsMap && !availableMerchants) {
      dispatch(
        micromobilityActions.getMicromobilityRecommendations({
          segmentId,
        })
      )
    }
  }, [segmentId, merchantsMap, availableMerchants, dispatch])

  useEffect(() => {
    if (merchantsMap && availableMerchants && allPrograms) {
      const activeProgramGroup = allPrograms[segmentId]
      if (!activeProgramGroup.active && activeProgramGroup.nextMonth) {
        const nextMonthState = getDefaultState(
          activeProgramGroup.nextMonth,
          merchantsMap,
          availableMerchants
        )
        const nextMonthOptions = getDefaultOptions(activeProgramGroup.nextMonth)
        setProgramState((prevState) => {
          return {
            ...prevState,
            nextMonth: nextMonthState,
          }
        })
        setActiveOptions({
          active: null,
          nextMonth: nextMonthOptions,
        })
        setIsEdit(true)
        setActiveState('nextMonth')
        setPendingProgram({
          program: nextMonthState,
          activeOptions: nextMonthOptions,
        })
      } else if (activeProgramGroup.active) {
        const activeProgramState = getDefaultState(
          activeProgramGroup.active,
          merchantsMap,
          availableMerchants
        )
        setProgramState({
          active: activeProgramState,
          nextMonth: activeProgramGroup.nextMonth
            ? getDefaultState(
                activeProgramGroup.nextMonth,
                merchantsMap,
                availableMerchants
              )
            : {
                ...activeProgramState,
                startDate: moment()
                  .add(1, 'M')
                  .startOf('month')
                  .format('YYYY-MM-DDTHH:mm:ss'),
              },
        })

        const defaultActiveOptions = getDefaultOptions(
          activeProgramGroup.active
        )
        setActiveOptions({
          active: defaultActiveOptions,
          nextMonth: activeProgramGroup.nextMonth
            ? getDefaultOptions(activeProgramGroup.nextMonth)
            : defaultActiveOptions,
        })
      }
    }
  }, [allPrograms, merchantsMap, availableMerchants, segmentId])

  useEffect(() => {
    if (micromobilityProgramUpdated && micromobilityProgramsLoaded) {
      dispatch(micromobilityActions.toggleMicromobilityProgramUpdated())
      if (!allPrograms[segmentId].active) {
        navigate(`/micromobility/${segmentId}`)
      } else {
        setIsEdit(false)
        setActiveState('active')
        setIsUpdateModalOpen(false)
      }
    }
  }, [
    micromobilityProgramUpdated,
    dispatch,
    navigate,
    micromobilityProgramsLoaded,
    segmentId,
    allPrograms,
  ])

  const toggleEdit = () => {
    if (isEdit) {
      const activeProgramGroup = allPrograms[segmentId]
      setProgramState((prevState) => {
        return {
          ...prevState,
          nextMonth: activeProgramGroup.nextMonth
            ? getDefaultState(
                activeProgramGroup.nextMonth,
                merchantsMap,
                availableMerchants
              )
            : prevState.active,
        }
      })
    }

    setIsEdit((prevState) => !prevState)
    setActiveState((prevState) => {
      return prevState === 'active' ? 'nextMonth' : 'active'
    })

    SegmentService.track('program-manage-action', {
      action: isEdit ? 'close' : 'edit',
      programType: 'micromobility',
      segmentName: segmentsMap[segmentId].name,
    })
  }

  const handleOptionToggle = (type: string) => {
    const merchantsType = `${type}Merchants`

    //activate all providers when turning on a blank option
    if (
      !activeOptions.nextMonth[type] &&
      programState.nextMonth[merchantsType].activeMerchantIds.length === 0
    ) {
      setProgramState((prevState) => {
        return {
          ...prevState,
          nextMonth: {
            ...prevState.nextMonth,
            [merchantsType]: {
              ...prevState.nextMonth[merchantsType],
              activeMerchantIds:
                prevState.nextMonth[merchantsType].availableMerchantIds,
            },
          },
        }
      })
    }

    setActiveOptions((prevState) => {
      return {
        ...prevState,
        nextMonth: {
          ...prevState.nextMonth,
          [type]: !prevState.nextMonth[type],
        },
      }
    })
  }

  const handleProviderChange = (
    type: MicromobilityType,
    newActiveProviders: string[]
  ) => {
    const merchantsType = `${type}Merchants`
    setProgramState((prevState) => {
      return {
        ...prevState,
        nextMonth: {
          ...prevState.nextMonth,
          [merchantsType]: {
            ...prevState.nextMonth[merchantsType],
            activeMerchantIds: newActiveProviders,
          },
        },
      }
    })
  }

  const handleOpenModal = (type: string) => {
    setActiveOption(type as MicromobilityType)
    setIsModalOpen(true)
    SegmentService.track('modes-modal-toggle', {
      action: 'open',
      state: isEdit ? 'edit' : 'view-all',
      location: type,
    })
  }

  const handleUpdate = () => {
    const nextMonthProgram = programState.nextMonth
    const checkErrors = {
      budget: !budget && true,
      leasing:
        nextMonthProgram.leasingMerchants.activeMerchantIds.length === 0 &&
        activeOptions.nextMonth.leasing &&
        true,
      shared:
        nextMonthProgram.sharedMerchants.activeMerchantIds.length === 0 &&
        activeOptions.nextMonth.shared &&
        true,
      oneTime:
        nextMonthProgram.oneTimeMerchants.activeMerchantIds.length === 0 &&
        activeOptions.nextMonth.oneTime &&
        true,
    }

    const hasNoErrors = Object.keys(checkErrors).every(
      (key) => !checkErrors[key]
    )

    const merchants = []
    if (activeOptions.nextMonth.leasing) {
      merchants.push(...nextMonthProgram.leasingMerchants.activeMerchantIds)
    }
    if (activeOptions.nextMonth.shared) {
      merchants.push(...nextMonthProgram.sharedMerchants.activeMerchantIds)
    }
    if (activeOptions.nextMonth.oneTime) {
      merchants.push(...nextMonthProgram.oneTimeMerchants.activeMerchantIds)
    }

    if (hasNoErrors) {
      dispatch(
        micromobilityActions.updateMicromobilityProgram({
          programId: nextMonthProgram.id,
          budget: nextMonthProgram.budget,
          type: nextMonthProgram.type,
          name: nextMonthProgram.name,
          segment: segmentId,
          startDate: nextMonthProgram.startDate,
          endDate: nextMonthProgram.endDate,
          merchants: [...Array.from(new Set(merchants))],
          yearlyPool: activeOptions.nextMonth.oneTime,
          leasing: activeOptions.nextMonth.leasing,
          shared: activeOptions.nextMonth.shared,
        })
      )
      SegmentService.track('programs-action-click', {
        action: 'update',
        programType: 'micromobility',
        segmentName: segmentsMap[segmentId].name,
        startDate: moment(nextMonthProgram.startDate).format('DD-MM-YYYY'),
        budget: nextMonthProgram.budget,
        flexProviders: null,
        gas: null,
        parking: null,
        transit: null,
        oneTime:
          nextMonthProgram.oneTimeMerchants.activeMerchantIds.length > 0 &&
          activeOptions.nextMonth.oneTime
            ? nextMonthProgram.oneTimeMerchants.activeMerchantIds.map(
                (id) => merchantsMap[id]?.name
              )
            : false,
        shared:
          nextMonthProgram.sharedMerchants.activeMerchantIds.length > 0 &&
          activeOptions.nextMonth.shared
            ? nextMonthProgram.sharedMerchants.activeMerchantIds.map(
                (id) => merchantsMap[id]?.name
              )
            : false,
        leasing:
          nextMonthProgram.leasingMerchants.activeMerchantIds.length > 0 &&
          activeOptions.nextMonth.leasing
            ? nextMonthProgram.leasingMerchants.activeMerchantIds.map(
                (id) => merchantsMap[id]?.name
              )
            : false,
      })
    } else {
      setErrorState(checkErrors)
      dispatch(
        notificationsActions.generalPageError(
          'Make sure a valid budget exists and there is at least one service provider selected for each active option'
        )
      )
    }
  }

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false)
  }

  const handleUpdateModalToggle = () => {
    setIsUpdateModalOpen((prevState) => !prevState)
  }

  const handleProgramCancel = (programGroup: 'active' | 'nextMonth') => {
    const nextMonthProgram = programState.nextMonth
    dispatch(
      micromobilityActions.cancelMicromobilityProgram(
        programState[programGroup].id
      )
    )
    SegmentService.track('programs-action-click', {
      action: programGroup === 'active' ? 'cancel' : 'reactivate',
      programType: 'micromobility',
      segmentName: segmentsMap[segmentId].name,
      startDate: moment(nextMonthProgram.startDate).format('DD-MM-YYYY'),
      budget: nextMonthProgram.budget,
      flexProviders: null,
      gas: null,
      parking: null,
      transit: null,
      oneTime:
        nextMonthProgram.oneTimeMerchants.activeMerchantIds.length > 0 &&
        activeOptions.nextMonth.oneTime
          ? nextMonthProgram.oneTimeMerchants.activeMerchantIds.map(
              (id) => merchantsMap[id]?.name
            )
          : false,
      shared:
        nextMonthProgram.sharedMerchants.activeMerchantIds.length > 0 &&
        activeOptions.nextMonth.shared
          ? nextMonthProgram.sharedMerchants.activeMerchantIds.map(
              (id) => merchantsMap[id]?.name
            )
          : false,
      leasing:
        nextMonthProgram.leasingMerchants.activeMerchantIds.length > 0 &&
        activeOptions.nextMonth.leasing
          ? nextMonthProgram.leasingMerchants.activeMerchantIds.map(
              (id) => merchantsMap[id]?.name
            )
          : false,
    })
    setIsCancelModalOpen(false)
  }

  const handleReActivate = () => {
    //account for if there are changes
    const hasChanges = MicromobilityUtils.programHasChanges(
      programState.active
        ? programState
        : { ...programState, active: pendingProgram.program },
      programState.active
        ? activeOptions
        : { ...activeOptions, active: pendingProgram.activeOptions }
    )
    if (!programState.active || hasChanges) {
      handleUpdate()
    } else {
      handleProgramCancel('nextMonth')
    }
  }

  if (micromobilityProgramsLoaded && !allPrograms[segmentId]) {
    return <Navigate to={Locations.Micromobility.Programs} replace />
  }

  if (
    !micromobilityProgramsLoaded ||
    (!programState.active && !programState.nextMonth) ||
    !merchantsMap ||
    !availableMerchants
  ) {
    return <Loading fullPage />
  }

  const noActiveOptions = Object.keys(activeOptions.nextMonth).every(
    (option) => !activeOptions.nextMonth[option]
  )
  const budget = programState[activeState].budget
  const leaseCoverage = Math.min(
    Math.round(
      ((budget ? budget : 0) / MicromobilityUtils.avgMonthlyLeasing) * 100
    ),
    100
  )
  const annualCoverage = Math.min(
    Math.round(
      (((budget ? budget : 0) * MicromobilityUtils.monthsLeft) /
        MicromobilityUtils.avgAnnualLeasing) *
        100
    ),
    100
  )

  return (
    <>
      <Helmet>
        <title>Manage | Micromobility</title>
      </Helmet>
      <StyledColumnView>
        <Column>
          <Program.BudgetSelector
            canEdit={isEdit}
            budget={budget}
            recBudget={MicromobilityUtils.recBudget}
            onChange={(newBudget) =>
              setProgramState((prevState) => {
                const newBudgetInCents = newBudget ? newBudget * 100 : 0
                return {
                  ...prevState,
                  nextMonth: {
                    ...prevState.nextMonth,
                    budget: newBudgetInCents,
                  },
                }
              })
            }
          />
          <ProgramOption
            type='shared'
            active={activeOptions[activeState].shared}
            activeMerchantIds={
              programState[activeState].sharedMerchants.activeMerchantIds
            }
            availableMerchantIds={
              programState[activeState].sharedMerchants.availableMerchantIds
            }
            handleToggle={handleOptionToggle}
            handleOpenModal={handleOpenModal}
            error={errorState.shared}
            canEdit={isEdit}
          >
            <MicromobilityLeasingTrips budget={budget} />
          </ProgramOption>
          <ProgramOption
            type='leasing'
            active={activeOptions[activeState].leasing}
            activeMerchantIds={
              programState[activeState].leasingMerchants.activeMerchantIds
            }
            availableMerchantIds={
              programState[activeState].leasingMerchants.availableMerchantIds
            }
            handleToggle={handleOptionToggle}
            handleOpenModal={handleOpenModal}
            error={errorState.leasing}
            canEdit={isEdit}
          >
            <ProgramPriceCoverage
              percentage={leaseCoverage}
              title='Monthly Membership Price Covered'
              description={`Your budget will cover ${leaseCoverage}% of the average monthly
                  membership price (${FormattingUtils.formatDollar(
                    MicromobilityUtils.avgMonthlyLeasing,
                    0
                  )}) for your commuters.`}
            />
          </ProgramOption>
          <ProgramOption
            type='oneTime'
            active={activeOptions[activeState].oneTime}
            activeMerchantIds={
              programState[activeState].oneTimeMerchants.activeMerchantIds
            }
            availableMerchantIds={
              programState[activeState].oneTimeMerchants.availableMerchantIds
            }
            handleToggle={handleOptionToggle}
            handleOpenModal={handleOpenModal}
            error={errorState.oneTime}
            canEdit={isEdit}
          >
            <MicromobilityOneTimePurchase
              percentage={annualCoverage}
              budget={budget}
            />
          </ProgramOption>
        </Column>
        <Column>
          <MicromobilityManageSummary
            canEdit={isEdit}
            toggleEdit={toggleEdit}
            program={programState[activeState]}
            currentSegment={segmentsMap[segmentId]}
            activeOptions={activeOptions[activeState]}
            handleOpenModal={handleOpenModal}
          />
          <MicromobilityManagePending
            isPending={!programState.active && true}
            programState={
              programState.active
                ? programState
                : { ...programState, active: pendingProgram.program }
            }
            activeOptions={
              programState.active
                ? activeOptions
                : { ...activeOptions, active: pendingProgram.activeOptions }
            }
            canEdit={isEdit}
            openUpdateModal={handleUpdateModalToggle}
          />
        </Column>
      </StyledColumnView>
      {isEdit && (
        <Footer>
          <FooterContainer>
            {programState.nextMonth.status !== 'CANCELLING' && (
              <Button
                variant='cancel'
                onClick={() => {
                  setIsCancelModalOpen(true)
                  SegmentService.track('program-manage-action', {
                    action: 'cancel',
                    programType: 'micromobility',
                    segmentName: segmentsMap[segmentId].name,
                  })
                }}
              >
                End Program
              </Button>
            )}
            <ButtonContainer>
              <Button
                variant='tertiary'
                marginRight='1rem'
                onClick={() => {
                  if (programState.active) {
                    toggleEdit()
                  } else {
                    navigate(`/micromobility/${segmentId}`)
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={noActiveOptions}
                onClick={() =>
                  programState.nextMonth.status === 'CANCELLING'
                    ? handleUpdateModalToggle()
                    : handleUpdate()
                }
              >
                Update Program
              </Button>
            </ButtonContainer>
          </FooterContainer>
        </Footer>
      )}
      <ServiceProviderSelectorModal
        open={isModalOpen}
        title={`${MicromobilityUtils.optionsCopy[activeOption].title} Service Providers`}
        closeModal={() => {
          setIsModalOpen(false)
          SegmentService.track('modes-modal-toggle', {
            action: 'close',
            state: isEdit ? 'edit' : 'view-all',
            location: activeOption,
          })
        }}
        canEdit={isEdit}
        activeProviders={
          programState[activeState][`${activeOption}Merchants`]
            .activeMerchantIds
        }
        availableProviders={
          programState[activeState][`${activeOption}Merchants`]
            .availableMerchantIds
        }
        onSave={(newActiveProviders: string[]) =>
          handleProviderChange(activeOption, newActiveProviders)
        }
      />
      <ProgramModal
        title='Cancel Program'
        description='Are you sure you want to cancel this program? Your commuters will lose
        all access to this programs benefits.'
        buttonText='Cancel Program'
        open={isCancelModalOpen}
        closeModal={handleCancelModalClose}
        buttonCallback={() =>
          handleProgramCancel(programState.active ? 'active' : 'nextMonth')
        }
      />
      <ProgramModal
        title='Reactivate Program'
        description='Do you want to reactive this program? All previous benefits will be available again next month.'
        buttonText='Reactivate Program'
        open={isUpdateModalOpen}
        closeModal={handleUpdateModalToggle}
        buttonCallback={handleReActivate}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  MicromobilityManageView.displayName = 'MicromobilityManageView'
}

export default MicromobilityManageView
