import React from 'react'
import PlatformSvg from 'core-system/Icons/PlatformSvg'
import FormattingUtils from 'shared/FormattingUtils'
import styled from 'styled-components'
import Text from 'core-system/Text'
import palette from 'core-system/Themes/palette'

const PurpleContainer = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.primary.pink200};
  margin-bottom: 1.5rem;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
`

const BudgetContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

interface OTPSummaryBudgetProps {
  transactionLimit: number
  totalEmployees: number
}

const OTPSummaryBudget = React.memo((props: OTPSummaryBudgetProps) => {
  const { transactionLimit, totalEmployees } = props
  return (
    <>
      <PurpleContainer>
        <HeaderContainer>
          <PlatformSvg folder='metrics' variant='money' />
          <Text variant='action3' marginLeft='0.5rem'>
            Total Annual Budget
          </Text>
        </HeaderContainer>
        <BudgetContainer>
          <Text variant='h2'>
            {FormattingUtils.formatDollar(transactionLimit, 0)}
          </Text>
          <Text variant='action3' margin='0.625rem 0 0 0.375rem'>
            per Commuter
          </Text>
        </BudgetContainer>
        <Text
          variant='caption'
          marginBottom='0.25rem'
          textColor={palette.text.secondary}
          captionTitle
        >
          Maximum Spend:
        </Text>
        <Text variant='body2' textColor={palette.text.secondary}>
          {FormattingUtils.formatDollar(transactionLimit * totalEmployees, 0)}{' '}
          for {totalEmployees} Commuters
        </Text>
      </PurpleContainer>
    </>
  )
})
// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OTPSummaryBudget.displayName = 'OTPSummaryBudget'
}

export default OTPSummaryBudget
