import SelectItemV2 from 'core-system/Dropdown/SelectItemV2'
import TextField from 'core-system/TextField/TextField'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export interface Result {
  key: string
  value: string
}

const Container = styled.div`
  width: 100%;
  position: relative;
`

const StyledSelectItem = styled(SelectItemV2)`
  text-align: start;
`

const ResultsContainer = styled.div`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.dropdowns};
  box-shadow: ${(props) => props.theme.dropShadows.selected};
  padding: 0.5rem 0;
  border-radius: 0 0 ${pxToRem('4')} ${pxToRem('4')};
  background-color: ${(props) => props.theme.palette.white};
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.grey.grey10};
  > div {
    color: ${(props) => props.theme.palette.secondary.maroon1};
  }
`

export interface TextAutocompleteProps {
  hasError: boolean
  onActiveChange: (item: Result) => void
  customPlaceholder?: string
  options: Result[]
}

const TextAutocompleteV2 = React.memo((props: TextAutocompleteProps) => {
  const { hasError, onActiveChange, customPlaceholder, options } = props
  const [text, setText] = useState('')
  const [filteredResults, setFilteredResults] = useState<Result[]>([])
  const [isOpen, setIsOpen] = useState(false)

  const containerRef = useRef(null)

  const clickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        containerRef &&
        containerRef.current &&
        containerRef.current.contains(e.target)
      ) {
        return
      }
      setIsOpen(false)
    },
    [containerRef]
  )

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside, false)
    return () => {
      document.removeEventListener('mousedown', clickOutside, false)
    }
  }, [clickOutside])

  const handleOnClick = (result: Result) => {
    setText(`${result.value} (${result.key})`)
    setFilteredResults([])
    setIsOpen(false)
    onActiveChange(result)
  }

  const handleOnInputClick = () => {
    if (!isOpen && filteredResults.length > 0) {
      setIsOpen(true)
    }
  }

  const renderResults = (results: Result[]) => {
    return results.map((result, idx) => {
      return (
        <StyledSelectItem
          active={true}
          key={idx}
          onClick={() => handleOnClick(result)}
        >
          {result.value} ({result.key})
        </StyledSelectItem>
      )
    })
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value

    setText(newValue)

    if (newValue.length <= 4 && filteredResults.length > 0) {
      setIsOpen(false)
      setFilteredResults([])
    } else if (newValue.length > 4) {
      const filtered = options.filter((option) =>
        option.value.toLowerCase().includes(newValue.toLowerCase())
      )
      setFilteredResults(filtered)
      setIsOpen(true)
    }

    if (newValue.trim().length === 0) {
      onActiveChange({ key: '', value: '' })
    }
  }

  return (
    <Container ref={containerRef}>
      <TextField
        onChange={(e) => handleOnChange(e)}
        onClick={handleOnInputClick}
        value={text}
        autoComplete='off'
        type='text'
        name='hidden'
        role='presentation'
        placeholder={customPlaceholder ? customPlaceholder : 'Enter text *'}
        invalid={hasError}
        helpText={'Please select an option'}
      />
      {isOpen && (
        <ResultsContainer>{renderResults(filteredResults)}</ResultsContainer>
      )}
    </Container>
  )
})

if (process.env.NODE_ENV !== 'production') {
  TextAutocompleteV2.displayName = 'TextAutocompleteV2'
}

export default TextAutocompleteV2
