import React from 'react'
import styled from 'styled-components'
import { buildForwardingComponent } from '../utils/buildComponent'
import CoreInput, { InputProps } from '../InputV2'
import Show from '../Icons/Actions/Show'
import Hide from '../Icons/Actions/Hide'

const Label = styled.div<{ darkBg: boolean }>`
  ${(props) => props.theme.typography.body1};
  color: ${(props) =>
    props.darkBg
      ? props.theme.palette.white
      : props.theme.palette.primary.pink800};
  margin-bottom: 0.25rem;
`
const Input = styled(CoreInput)<{ invalid?: boolean; isPwd?: boolean }>`
  ${(props) =>
    props.invalid
      ? {
          borderColor: props.theme.palette.error.red500,
          backgroundColor: props.theme.palette.error.red100,
        }
      : ''}
  padding-right: ${(props) => (props.isPwd ? '3rem' : '')};
  border-radius: ${(props) => props.borderRadius};
`

const HelpText = styled.div`
  ${(props) => props.theme.typography.body2};
  color: ${(props) => props.theme.palette.error.red500};
  margin-top: 0.25rem;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: space-between;
  align-items: center;

  svg {
    color: ${(props) => props.theme.palette.grey.grey400};
    cursor: pointer;
    margin-left: -2.375rem;
  }
`
export interface TextFieldProps extends InputProps {
  helpText?: string
  invalid?: boolean
  label?: string
  darkBg?: boolean
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (props: TextFieldProps, ref: React.Ref<HTMLInputElement>) => {
    const { helpText, invalid, label, darkBg = false, ...inputProps } = props
    const isPwdField = inputProps.type === 'password'
    const [showPwd, setShowPwd] = React.useState<boolean>(
      isPwdField ? false : true
    )

    const onTogglePwd = React.useCallback(() => {
      setShowPwd(!showPwd)
    }, [showPwd])

    if (isPwdField) {
      return (
        <div>
          {label && label.length > 0 && <Label darkBg={darkBg}>{label}</Label>}
          <InputContainer>
            <Input
              {...inputProps}
              invalid={invalid}
              isPwd={true}
              type={showPwd ? 'text' : inputProps.type}
              ref={ref}
            />
            {showPwd ? (
              <Hide onClick={onTogglePwd} />
            ) : (
              <Show onClick={onTogglePwd} />
            )}
          </InputContainer>
          {invalid && helpText && <HelpText>{helpText}</HelpText>}
        </div>
      )
    }

    return (
      <div>
        {label && label.length > 0 && <Label darkBg={darkBg}>{label}</Label>}
        <Input {...inputProps} invalid={invalid} ref={ref} />
        {helpText && invalid && <HelpText>{helpText}</HelpText>}
      </div>
    )
  }
)

TextField.displayName = 'TextField'
export default buildForwardingComponent<HTMLInputElement, TextFieldProps>(
  TextField
)
