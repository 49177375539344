import React, { useCallback } from 'react'
import styled from 'styled-components'
import palette from 'core-system/Themes/palette'

const pwdMeterColor = {
  Weak: '#F25F5C',
  'So-so': '#FFE066',
  Good: '#247BA0',
  Great: palette.primary.pink400,
}

const PwdMeterContainer = styled.div<{ password: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.2rem 0;
  visibility: ${(props) => (props.password ? 'visible' : 'hidden')};
`

const PwdMeterScore = styled.progress<{ color: string }>`
  appearance: none;
  -webkit-appearance: none;
  margin-right: 0.5rem;
  height: 0.5rem;
  flex: 1;
  border-radius: 0.75rem;

  &::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 0.75rem;
  }

  &::-webkit-progress-value {
    background-color: ${(props) => props.color};
  }

  &::-webkit-progress-value {
    border-radius: 0.75rem;
  }

  &::-moz-progress-bar {
    background-color: ${(props) => props.color};
    border-radius: 0.75rem;
  }
`

const PwdMeterTitle = styled.label`
  width: 2.5rem;
  color: ${(props) => props.theme.palette.primary.pink500};
  font-weight: 700;
`

const pwdScore = (password: string) => {
  //eslint-disable-next-line
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  const noNumber = /\d/
  if (password.length < 5) {
    return 0
  } else if (
    password.length >= 15 &&
    specialChars.test(password) &&
    noNumber.test(password)
  ) {
    return 4
  } else if (
    password.length >= 10 &&
    specialChars.test(password) &&
    noNumber.test(password)
  ) {
    return 3
  } else if (
    password.length >= 10 &&
    (specialChars.test(password) || noNumber.test(password))
  ) {
    return 2
  } else {
    return 0
  }
}

interface PasswordStrengthMeterProps {
  password: string
}

const PasswordStrengthMeter = (props: PasswordStrengthMeterProps) => {
  const { password } = props

  const createPasswordLabel = useCallback((result: number): string => {
    switch (result) {
      case 0:
        return 'Weak'
      case 1:
        return 'Weak'
      case 2:
        return 'So-so'
      case 3:
        return 'Good'
      case 4:
        return 'Great'
      default:
        return 'Weak'
    }
  }, [])

  const pwdTested = pwdScore(password)

  const pwdLabel = createPasswordLabel(pwdTested)

  return (
    <PwdMeterContainer password={password}>
      <PwdMeterScore
        value={pwdTested}
        max={4}
        color={pwdMeterColor[pwdLabel]}
      />
      <PwdMeterTitle>{pwdLabel}</PwdMeterTitle>
    </PwdMeterContainer>
  )
}

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  PasswordStrengthMeter.displayName = 'PasswordStrengthMeter'
}

export default PasswordStrengthMeter
